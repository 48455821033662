import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink,  Link
} from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CreateIcon from '@material-ui/icons/Create';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {withRouter} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from "react-switch";
import MuiAlert from '@material-ui/lab/Alert';

import { loadTossPayments } from '@tosspayments/sdk';

import Modal from '@material-ui/core/Modal';

import shortid from 'shortid';

import { Image, Col, Row, Container} from 'react-bootstrap';


import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';
import roundIcon from '../roundIcon.png';

import moment from 'moment';

import * as firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let QRCode = require('qrcode.react');



class JustPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      loadingStart: false,
      firstGetData: true,
      setSconeDeliveryAlarm: false,
      paymentId: this.props.match.params.paymentId,
      paymentInfo: null,
      completePayment: false
    }
  }


  componentWillMount() {
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)
    firebase.database().ref(`justPayment/${this.state.paymentId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({paymentInfo: snapshot.val()})
        if (snapshot.val()['completePayment']) {
          this.setState({completePayment: true})
        }
        setTimeout(() => {
          this.setState({isLoading: false})
        }, 1000)
      }
    })
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  async onPaying(amount, paymentId, orderName, customerName) {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)


    tossPayments.requestPayment('카드', {
      amount: amount,
      orderId: paymentId,
      orderName: orderName,
      customerName: customerName,
      successUrl: window.location.origin + '/just_payment_result/' + paymentId + '/success',
      failUrl: window.location.origin + '/just_payment_result/' + paymentId + '/fail',
    }).catch((error) => {
      if (error.code === 'USER_CANCEL') {
      }
    })
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
          <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        </div>
      )
    }

    return (
      <Grid container className="auto-width-100">
        <Grid container justify="center">
          <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>주문결제</p>
          { !this.state.paymentInfo
            ?<Grid container className="auto-width-100">
            <div className="center-div direction-column-div">
              <span style={{fontWeight: 'bold'}}>결제 정보가 없습니다.</span>
            </div>
            </Grid>
            :<div className="auto-width-100">
            { !this.state.completePayment
              ?<Grid className="auto-width-100">
                <Grid container className="auto-width-100 relative-position  direction-column-div">
                  <div className="direction-column-div" style={{padding: 20, paddingBottom: 10}}>
                    <span style={{fontWeight: 'bold', marginBottom: 5}}>이름</span>
                    <span style={{fontSize: 14}}>{this.state.paymentInfo.name}</span>
                  </div>
                  <div className="direction-column-div" style={{padding: 20, paddingBottom: 10}}>
                    <span style={{fontWeight: 'bold', marginBottom: 5}}>연락처</span>
                    <span style={{fontSize: 14}}>{this.state.paymentInfo.phoneNumber}</span>
                  </div>
                  <div className="direction-column-div" style={{padding: 20, paddingBottom: 10}}>
                    <span style={{fontWeight: 'bold', marginBottom: 5}}>구매내역</span>
                    { Object.values(this.state.paymentInfo.orderItems).map((item, index) => {
                      return (
                        <div key={index + 1} style={{marginBottom: 5}} className="direction-column-div">
                          <span style={{fontSize: 14}}>{item.name}</span>
                          <div className="direction-row-div">
                            <span style={{fontSize: 13}}>{item.amount}개 /</span>
                            <span style={{fontSize: 13, marginLeft: 3}}>{this.onChangeCurrencyFormat(item.amount * item.price)}원</span>
                          </div>
                        </div>
                      )
                    })

                    }

                  </div>
                  <div className="direction-column-div" style={{padding: 20, paddingBottom: 10}}>
                    <span style={{fontWeight: 'bold', marginBottom: 5}}>총 결제 금액</span>
                    { this.state.paymentInfo.discountAmount
                      ?<div className="direction-column-div">
                        <span className="strike" style={{fontSize: 14}}>{this.onChangeCurrencyFormat(this.state.paymentInfo.totalPrice)}원</span>
                        <span style={{fontSize: 14}}>{this.onChangeCurrencyFormat(this.state.paymentInfo.totalPrice - this.state.paymentInfo.discountAmount)}원</span>
                        <span style={{fontSize: 12}}>({this.onChangeCurrencyFormat(this.state.paymentInfo.discountAmount)}원 할인)</span>
                      </div>
                      :<span style={{fontSize: 14}}>{this.onChangeCurrencyFormat(this.state.paymentInfo.totalPrice)}원</span>
                    }

                  </div>
                </Grid>
              </Grid>
              :<Grid container justify="center" className="auto-width-100 center-div">
              <div className="direction-column-div  center-div">
                <span style={{fontWeight: 'bold', marginBottom: 5}}>결제가 완료되었습니다.</span>
                { this.state.paymentInfo['card'] &&
                  <a className="normal-very-small-button none-underline-text  center-div" style={{width: 60, marginTop: 40}} href={this.state.paymentInfo['card'].receiptUrl} target="_blank">
                    <span style={{fontSize: 11, color: 'white'}}>영수증 출력</span>
                  </a>
                }
                </div>
              </Grid>
            }
            </div>
          }
        </Grid>
        { !this.state.completePayment &&
          <div onClick={() => {
            if (!this.state.paymentInfo.discountAmount) {
              this.onPaying(parseInt(this.state.paymentInfo.totalPrice), this.state.paymentId, '디저트', this.state.paymentInfo.name)
            } else {
              this.onPaying(parseInt(this.state.paymentInfo.totalPrice - this.state.paymentInfo.discountAmount), this.state.paymentId, '디저트', this.state.paymentInfo.name)
            }

          }} className="btn-very-bottom-big-center">
            { this.state.paymentInfo.discountAmount
              ?<p style={{color: 'white', fontSize: 15}}>결제하기 ({this.onChangeCurrencyFormat(this.state.paymentInfo.totalPrice - this.state.paymentInfo.discountAmount)}원)</p>
              :<p style={{color: 'white', fontSize: 15}}>결제하기 ({this.onChangeCurrencyFormat(this.state.paymentInfo.totalPrice)}원)</p>
            }

          </div>
        }

      </Grid>
    )
  }
}

export default withRouter(JustPayment);
