import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

import { Image, Col, Row, Container} from 'react-bootstrap';

import * as firebase from 'firebase';
import queryString from 'query-string'
import { loadTossPayments } from '@tosspayments/sdk';

import moment from 'moment';

import Postcode from 'react-daum-postcode';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CreateIcon from '@material-ui/icons/Create';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CloseIcon from '@material-ui/icons/CloseOutlined';


import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import nunettineSconeIcon from '../NunettineScone.png';
import eggSconeIcon from '../EggScone.png';
import buildingWeb from '../buildingWeb.jpg';
import noConcern from '../noConcern.jpg';
import cookieBox from '../cookieBox.png';
import compoteAndCream from '../compoteAndCream.png';
import giftBox from '../GiftBox.png';
import cardPayment from '../CardPayment.png';
import freeShipping from '../FreeShipping.png';
import discount from '../Discount.png';
import compoteAndCreamJars from '../compoteAndCreamJars.png';

class RegularStart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,

      regular: null,
      totalPrice: 0,
      payPrice: 0,

      showPostModal: false,
      isChangingAddress: false,
      detailAddressInputError: false,
      receiverInputError: false,
      phoneNumberInputError: false,
      tempFullAddress: '',
      tempDetailAddress: '',
      tempReceiver: '',
      tempPhoneNumber: '',
      tempPostCode: '',
      doneEnterAddress: false,
    }
  }


  componentWillMount() {
    firebase.database().ref(`regular`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let regular = snapshot.val()
        regular['selections']['eggScone']['selected'] = false
        regular['selections']['nunettineScone']['selected'] = false
        regular['selections']['plainSauce1']['selected'] = false
        regular['selections']['plainSauce2']['selected'] = false
        let totalPrice = regular.selections.basicPackage.price
        let payPrice = regular.selections.basicPackage.price
        this.setState({regular: regular, totalPrice: totalPrice, payPrice: payPrice})
      }
    })
  }

  componentDidMount() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);

    setTimeout(() => {
      if (isEdge || isIE) {
          window.scrollTo(0, 0)
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
          })
        }
    }, 10)
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.setState({ userInfo: null, isLoading: false });
      }

    } else {
      this.setState({ isLoading: true });
    }
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onStringNumberToYYYYMMDD(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "- " + stringNumber.substring(4, 6) + "- " + stringNumber.substring(6, 8)
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  async onStartingRegular() {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)

    let addition = null
    if (this.state.regular.selections.eggScone.selected) {
      addition = {}
      addition['twoEggScones'] = true
    }
    if (this.state.regular.selections.nunettineScone.selected) {
      if (!addition) {
        addition = {}
      }
      addition['twoNunettineScones'] = true
    }

    if (this.state.regular.selections.plainSauce1.selected) {
      if (!addition) {
        addition = {}
      }
      addition['plainSauce'] = true
    }

    if (this.state.regular.selections.plainSauce2.selected) {
      if (!addition) {
        addition = {}
      }
      addition['twoPlainSauces'] = true
    }

    let regularInfo = {
      addition: addition,
      deliveryAddress: {
        detailAddress: this.state.tempDetailAddress,
        fullAddress: this.state.tempFullAddress,
        phoneNumber: this.state.tempPhoneNumber,
        postCode: this.state.tempPostCode,
        receiver: this.state.tempReceiver
      },
      pause: false,
      uid: this.state.userInfo.uid
    }

    firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}`).set(regularInfo)
    firebase.database().ref(`users/${this.state.userInfo.uid}/regular`).set(regularInfo)

    tossPayments.requestBillingAuth('카드', {
      customerKey: this.state.userInfo.uid,
      successUrl: window.location.origin + '/regular_result/success',
      failUrl: window.location.origin + '/regular_result/fail',
    }).catch((error) => {
    })
  }

  calendarArr(today) {
    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;

    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 25}} >
                  </td>
              )
            }
            if (this.state.regular.nextPayDate.toString() === days.format('YYYYMMDD')) {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 25, borderRadius: 50}} >
                  <span style={{color: 'white', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            } else if ((this.state.regular.nextDeliveryDate).toString() === days.format('YYYYMMDD')) {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'rgba(0, 113, 212, 0.95)', height: 25, borderRadius: 50}} >
                  <span style={{color: 'white', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            } else {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'wthie', height: 25}} >
                  <span style={{color: 'black', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    if (!this.state.regular) {
      return <div></div>
    }
    return (
      <div style={{marginBottom: 80}}>
        <ButtonBase
          to={`/`}
          component={Link}
          style={{ color: "black", textDecoration: 'none', marginLeft: 10 }}
          onClick={() => {
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
          }}
        >
        <Image
          className="logo-style"
          src={MainLogo}/>
        </ButtonBase>
        <Grid container justify="center" className="auto-width-100 align-center">
          <div className="direction-column-div">
            <Image src={cookieBox} width={200} height={180} style={{margin: 50, marginBottom: 20}} fluid />
            <div style={{marginBottom: 30}} className="direction-column-div auto-width-100 align-center">
              <span style={{fontSize: 18, fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(this.state.regular.selections.basicPackage.price)}원</span>
              <span style={{fontSize: 14, fontWeight: 'bold', color: 'red'}}>배송비 무료</span>
            </div>
          </div>
          <Grid container justify="center" style={{paddingBottom: 80}}>
            <div className="auto-width-100 direction-column-div align-center">
              <span style={{fontSize: 14, fontWeight: 'bold'}}>플레인 스콘 6개</span>
              <span style={{fontSize: 12}}>최고급 버터의 풍미를 느낄 수 있는</span>
              <span style={{fontSize: 12}}>위나의 대표 고메스콘</span>
            </div>
          </Grid>


          <span style={{fontSize: 22, fontWeight: 'bold'}}>함께 하면 더 저렴해요.</span>
          <div onClick={() => {

            if (this.state.userInfo) {
              let eggScone = this.state.regular.selections.eggScone
              let totalPrice = this.state.totalPrice
              let payPrice = this.state.payPrice
              if (eggScone['selected']) {
                eggScone['selected'] = false
                payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount))
                totalPrice = totalPrice - this.state.regular.selections.eggScone.price
              } else {
                eggScone['selected'] = true
                payPrice = payPrice + Number(this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount))
                totalPrice = totalPrice + this.state.regular.selections.eggScone.price
              }

              this.setState({eggScone: eggScone, totalPrice: totalPrice, payPrice: payPrice})
            } else {
              this.props.history.replace(`/login?redirect=/regular_start`);

              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }


          }} className={this.state.regular.selections.eggScone.selected ? "big-add-btn-selected direction-row-div align-center" : "big-add-btn direction-row-div align-center"}>
            <Grid container justify="center" className="auto-width-45 align-center">
              <Image style={{marginRight: 30}} src={eggSconeIcon} width={70} height={70} fluid />
            </Grid>
            <Grid container justify="center" className="auto-width-45 direction-column-div align-center">
              <span style={{fontSize: 14, fontWeight: 'bold', marginBottom: 5}}>에그 스콘 2개</span>
              <span style={{fontSize: 11}}>고급스러운 수제 커스터드 크림이</span>
              <span style={{fontSize: 11, marginBottom: 20}}>들어간 위드어나드 인기 스콘</span>
              <div className="direction-row-div">
                <span className="background-signiture-color-30" style={{fontSize: 12, marginRight: 5, padding: 2}}>{this.state.regular.selections.eggScone.discount * 100}%</span>
                <span style={{fontSize: 12, marginRight: 5, marginTop: 2}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount))}원</span>
                <span className="strike" style={{fontSize: 12, marginTop: 2}}>{this.onChangeCurrencyFormat(this.state.regular.selections.eggScone.price)}원</span>
              </div>
            </Grid>
          </div>
          <div onClick={() => {
            if (this.state.userInfo) {
              let nunettineScone = this.state.regular.selections.nunettineScone
              let totalPrice = this.state.totalPrice
              let payPrice = this.state.payPrice
              if (nunettineScone['selected']) {
                nunettineScone['selected'] = false
                payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount))
                totalPrice = totalPrice - this.state.regular.selections.nunettineScone.price
              } else {
                nunettineScone['selected'] = true
                payPrice = payPrice + Number(this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount))
                totalPrice = totalPrice + this.state.regular.selections.nunettineScone.price
              }

              this.setState({nunettineScone: nunettineScone, totalPrice: totalPrice, payPrice: payPrice})
            } else {
              this.props.history.replace(`/login?redirect=/regular_start`);

              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }


          }} className={this.state.regular.selections.nunettineScone.selected ? "big-add-btn-selected direction-row-div align-center" : "big-add-btn direction-row-div align-center"}>
            <Grid container justify="center" className="auto-width-45 align-center">
              <Image style={{marginRight: 30}} src={nunettineSconeIcon} width={70} height={70} fluid />
            </Grid>
            <Grid container justify="center" className="auto-width-45 direction-column-div align-center">
              <span style={{fontSize: 14, fontWeight: 'bold', marginBottom: 5}}>누네띠네 스콘 2개</span>
              <span style={{fontSize: 11}}>추억의 과자 누네띠네를 닮은</span>
              <span style={{fontSize: 11, marginBottom: 20}}>바삭하고 달달한 맛있는 스콘</span>
              <div className="direction-row-div">
                  <span className="background-signiture-color-30" style={{fontSize: 12, marginRight: 5, padding: 2}}>{this.state.regular.selections.nunettineScone.discount * 100}%</span>
                <span style={{fontSize: 12, marginRight: 5, marginTop: 2}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount))}원</span>
                <span className="strike" style={{fontSize: 12, marginTop: 2}}>{this.onChangeCurrencyFormat(this.state.regular.selections.nunettineScone.price)}원</span>
              </div>
            </Grid>
          </div>

          <div className="width-100-image max-height-100">
            <Image src={compoteAndCream} className="width-100-image max-height-100" fluid/>
          </div>

          <div className="add-btn-row-between">

          <div onClick={() => {
            if (this.state.userInfo) {
              let plainSauce1 = this.state.regular.selections.plainSauce1
              let plainSauce2 = this.state.regular.selections.plainSauce2
              let totalPrice = this.state.totalPrice
              let payPrice = this.state.payPrice
              if (plainSauce1['selected']) {
                plainSauce1['selected'] = false
                payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount))
                totalPrice = totalPrice - this.state.regular.selections.plainSauce1.price
              } else {
                plainSauce1['selected'] = true
                payPrice = payPrice + Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount))
                if (plainSauce2['selected']) {
                  payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount))
                  totalPrice = totalPrice - this.state.regular.selections.plainSauce2.price
                  plainSauce2['selected'] = false
                }
                totalPrice = totalPrice + this.state.regular.selections.plainSauce1.price
              }

              this.setState({plainSauce1: plainSauce1, totalPrice: totalPrice, payPrice: payPrice})
            } else {
              this.props.history.replace(`/login?redirect=/regular_start`);

              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }


          }} className={this.state.regular.selections.plainSauce1.selected ? "add-btn-selected direction-row-div align-center" : "add-btn direction-row-div align-center"}>

            <Grid container justify="center" className="direction-column-div align-center">
              <Image style={{marginBottom: 10}} src={compoteAndCreamJars} width={45} height={45} fluid />
              <span style={{fontSize: 12, fontWeight: 'bold'}}>클로티드 크림</span>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>+ 딸기 콩포트</span>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>1 세트</span>
              <span style={{fontSize: 11, marginBottom: 10}}>(각 100ml)</span>
              <div className="direction-row-div">
                <span className="background-signiture-color-30" style={{fontSize: 12, marginRight: 5, padding: 2}}>{this.state.regular.selections.plainSauce1.discount * 100}%</span>
                <span style={{fontSize: 12, marginRight: 5, marginTop: 2}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount))}원</span>
                <span className="strike" style={{fontSize: 12, marginTop: 2}}>{this.onChangeCurrencyFormat(this.state.regular.selections.plainSauce1.price)}원</span>
              </div>
            </Grid>
          </div>

          <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 90}}>OR</span>

          <div onClick={() => {
            if (this.state.userInfo) {
              let plainSauce1 = this.state.regular.selections.plainSauce1
              let plainSauce2 = this.state.regular.selections.plainSauce2
              let totalPrice = this.state.totalPrice
              let payPrice = this.state.payPrice
              if (plainSauce2['selected']) {
                plainSauce2['selected'] = false
                payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount))
                totalPrice = totalPrice - this.state.regular.selections.plainSauce2.price
              } else {
                plainSauce2['selected'] = true
                payPrice = payPrice + Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount))
                if (plainSauce1['selected']) {
                  payPrice = payPrice - Number(this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount))
                  totalPrice = totalPrice - this.state.regular.selections.plainSauce1.price
                  plainSauce1['selected'] = false
                }
                totalPrice = totalPrice + this.state.regular.selections.plainSauce2.price
              }

              this.setState({plainSauce2: plainSauce2, totalPrice: totalPrice, payPrice: payPrice})
            } else {
              this.props.history.replace(`/login?redirect=/regular_start`);

              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }


          }} className={this.state.regular.selections.plainSauce2.selected ? "add-btn-selected direction-row-div align-center" : "add-btn direction-row-div align-center"}>
            <Grid container justify="center" className="direction-column-div align-center">
              <Image style={{marginBottom: 10}} src={compoteAndCreamJars} width={45} height={45} fluid />
              <span style={{fontSize: 12, fontWeight: 'bold'}}>클로티드 크림</span>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>+ 딸기 콩포트</span>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>2 세트</span>
              <span style={{fontSize: 11, marginBottom: 10}}>(각 100ml)</span>
              <div className="direction-row-div">
                <span className="background-signiture-color-30" style={{fontSize: 12, marginRight: 5, padding: 2}}>{this.state.regular.selections.plainSauce2.discount * 100}%</span>
                <span style={{fontSize: 12, marginRight: 5, marginTop: 2}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount))}원</span>
                <span className="strike" style={{fontSize: 12, marginTop: 2}}>{this.onChangeCurrencyFormat(this.state.regular.selections.plainSauce2.price)}원</span>
              </div>
            </Grid>
          </div>

          </div>
          <Divider className="auto-width-100" style={{marginTop: 30, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>정기 결제 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 20, color: '#006042'}}>결제금액</span>
            <div style={{padding: 10, marginTop: 10}} className="auto-width-95 direction-row-div justify-between">
              <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>상품금액</span>
              <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
            </div>
            { this.state.totalPrice - this.state.payPrice > 0 &&
              <div style={{padding: 10}} className="auto-width-95 direction-row-div justify-between">
                <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>할인금액</span>
                <span style={{fontSize: 14, color: 'red', marginRight: 5}}>-{this.onChangeCurrencyFormat(this.state.totalPrice - this.state.payPrice)}원</span>
              </div>
            }
            <div style={{padding: 10}} className="auto-width-95 direction-row-div justify-between">
              <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>배송비</span>
              <span style={{fontSize: 14, marginRight: 5}}>무료</span>
            </div>
            <div style={{padding: 10}} className="auto-width-95 direction-row-div justify-between">
              <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>총 결제 예정 금액</span>
              <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.state.payPrice)}원</span>
            </div>
            <Grid container justify="center" className="auto-width-100 align-center">
              <Divider className="auto-width-95" style={{marginTop: 10, marginBottom: 20}} />
            </Grid>
            <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 20, color: '#006042'}}>결제일 및 발송일</span>
            <div style={{padding: 10, marginTop: 10}} className="auto-width-95 direction-row-div justify-between">
              <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>결제일</span>
              <span style={{fontSize: 14, marginRight: 5}}>{this.onStringNumberToDate(this.state.regular.nextPayDate)}</span>
            </div>
            <div style={{padding: 10}} className="auto-width-95 direction-row-div justify-between">
              <span style={{fontSize: 14, fontWeight: 'bold', marginLeft: 10}}>택배 발송일</span>
              <span style={{fontSize: 14, marginRight: 5}}>{this.onStringNumberToDate(this.state.regular.nextDeliveryDate)}</span>
            </div>
          </div>
          <Grid container justify="center" className="auto-width-100 align-center direction-column-div">
          <span style={{fontSize: 14, fontWeight: 'bold', marginBottom: 10}}>{this.onStringNumberToDate(this.state.regular.nextPayDate).substring(0, 9)}</span>
          <table style={{width: 230}}>
            <tbody>
              { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                  return (
                    <td className="day-table-style" key={index} style={{height: 25}} >
                      { index === 0
                        ?<span style={{fontSize: 12, marginLeft: 7, color: 'red'}}>{day}</span>
                        :<span style={{fontSize: 12, marginLeft: 7}}>{day}</span>
                      }

                    </td>
                  )
                })
              }
              {this.calendarArr(moment(this.onStringNumberToYYYYMMDD(this.state.regular.nextPayDate.toString())))}
            </tbody>
          </table>
          </Grid>
          <Grid className="auto-width-100 center-align" container justify="center" direction="row" style={{marginBottom: 20, marginTop: 10}} >
            <div className="auto-width-100 direction-row-div" style={{width: 230, paddingLeft: 15}}>
              <div style={{backgroundColor: 'rgba(0, 135, 117, 0.95)', width: 13, height: 13}}>
              </div>
              <span style={{fontSize: 10, marginLeft: 5}}>결제일</span>
              <div style={{backgroundColor: 'rgba(0, 113, 212, 0.95)', width: 13, height: 13, marginLeft: 20}}>
              </div>
              <span style={{fontSize: 10, marginLeft: 5}}>택배 발송일</span>
            </div>
          </Grid>
          <div onClick={() => {
            if (this.state.userInfo) {
              if (!this.state.userInfo.regular) {
                this.setState({showPostModal: true})
              } else {
                if (!this.state.userInfo.regular.paymentsInfo) {
                  this.setState({showPostModal: true})
                }
              }
            } else {
              this.props.history.replace(`/login?redirect=/regular_start`);

              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }
          }} className={this.state.userInfo && this.state.userInfo.regular ? this.state.userInfo.regular.paymentsInfo ? "btn-very-big-disabled" : "btn-very-big" : "btn-very-big"} style={{marginTop: 30}}>
            <span style={{fontSize: 14, color: 'white'}}>{this.state.userInfo && this.state.userInfo.regular ? this.state.userInfo.regular.paymentsInfo ? '이미 정기구독을 신청하셨습니다.' : '정기구독 시작하기 (배송지 및 카드 등록)' : '정기구독 시작하기 (배송지 및 카드 등록)'}</span>
          </div>
        </Grid>
        <Modal
          open={this.state.showPostModal}
          onClose={() => {
            this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.tempFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 등록</span>
            <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, phoneNumberInputError: false, receiverInputError: false,  doneEnterAddress: true})
                    this.onStartingRegular()
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
            }}
          />
        }

        </Modal>
      </div>
    )
  }
}

export default withRouter(RegularStart);
