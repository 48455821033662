import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';

import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';

class TermsOfService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo
    }
  }


  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }


  render() {
    return (
      <Grid container justify="center" style={{padding: 20}}>
        <span style={{fontWeight: 'bold'}}>이용약관</span>
        <p style={{fontSize: 13}}>
        제1조(목적)<br/>
        이 약관은 위드어나드(이하 ‘회사’)가 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 위드어나드와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.<br/><br/>
        제2조(정의)<br/>
        ① "사이트" 란 사업자가 상품을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.<br/>
        ② "이용자"란 "사이트"에 접속하여 이 약관에 따라 "사이트"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
        ③ "회원"이라 함은 "사이트"에 개인정보를 제공하여 회원등록을 한 자로서, "사이트"의 정보를 지속적으로 제공받으며, "사이트"가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
        ④ "비회원"이라 함은 회원에 가입하지 않고 "사이트"가 제공하는 서비스를 이용하는 자를 말합니다.<br/><br/>
        제3조 (약관의 명시와 개정)<br/>
        ① "회사"는 이 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처(전화, 전자우편 등) 등을 이용자가 알 수 있도록 사이트의 초기 서비스화면(전면)에 게시합니다.<br/>
        ② "회사"는 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률, 방문판매 등에 관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
        ③ "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 홈페이지의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.<br/>
        ④ "회사"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 "사이트"에 송신하여 "사이트"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br/>
        ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br/><br/>
        제4조(서비스의 제공 및 변경)<br/>
        ① "회사"는 다음과 같은 업무를 수행합니다.<br/>
        1. 상품에 대한 정보 제공 및 구매계약의 체결<br/>
        2. 구매계약이 체결된 상품 제공<br/>
        3. 기타 "사이트"이 정하는 업무<br/>
        ② "회사"는 상품의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품의 내용 및 제공일자를 명시하여 현재의상품의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.<br/>
        ③ "회사"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 상품의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 "회사"는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, "회사"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br/><br/>
        제5조(서비스의 중단)<br/>
        ① "회사"는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
        ② 제1항에 의한 서비스 중단의 경우에는 "회사"는 제8조에 정한 방법으로 이용자에게 통지합니다.<br/>
        ③ "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단 "회사"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br/><br/>
        제6조(회원가입)<br/>
        ① 이용자는 "사이트"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br/>
        ② "사이트"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br/>
        1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "사이트"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.<br/>
        2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
        3. 기타 회원으로 등록하는 것이 "사이트"의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
        ③ 회원가입계약의 성립 시기는 "사이트"의 승낙이 회원에게 도달한 시점으로 합니다.<br/>
        ④ 회원은 제15조 제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "사이트"에 대하여 그 변경사항을 알려야 합니다.<br/><br/>
        제7조(회원 탈퇴 및 자격 상실 등)<br/>
        ① 회원은 "사이트"에 언제든지 탈퇴를 요청할 수 있으며 "사이트"는 즉시 회원탈퇴를 처리합니다.<br/>
        ② 회원이 다음 각 호의 사유에 해당하는 경우, "사이트"는 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
        1. 가입 신청 시에 허위 내용을 등록한 경우<br/>
        2. "사이트"를 이용하여 구입한 상품의 대금, 기타 "사이트" 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/>
        3. 다른 사람의 "사이트" 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br/>
        4. "사이트"를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
        ③ "사이트"가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "사이트"는 회원자격을 상실시킬 수 있습니다.<br/>
        ④ "사이트"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.<br/><br/>
        제8조(회원에 대한 통지)<br/>
        ① "사이트"가 회원에 대한 통지를 하는 경우, 회원이 "사이트"에 제출한 전자우편 주소로 할 수 있습니다.<br/>
        ② "사이트"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "사이트" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.<br/><br/>
        제9조(구매신청)<br/>
        "사이트" 이용자는 "사이트"상에서 이하의 방법에 의하여 구매를 신청합니다.<br/>
        1. 성명, 비밀번호, 전화번호, 핸드폰번호, 주소, E-MAIL 입력<br/>
        2. 상품의 선택<br/>
        3. 결제방법의 선택<br/>
        4. 이 약관에 동의한다는 표시(예, 마우스 클릭)<br/><br/>
        제10조 (계약의 성립)<br/>
        ① "회사"는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하지 않는 한 승낙합니다.<br/>
        1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
        2. 기타 구매신청에 승낙하는 것이 "회사" 기술상 현저히 지장이 있다고 판단하는 경우<br/>
        ② "회사"의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/><br/>
        "사이트"에서 구매한 상품에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.<br/>
        1. 계좌이체<br/>
        2. 신용카드결제<br/>
        3. 온라인무통장입금<br/><br/>
        제12조(수신확인통지·구매신청 변경 및 취소)<br/>
        ① "회사"는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br/>
        ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.<br/>
        ③ "회사"는 배송 전 이용자의 구매신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.<br/><br/>
        제13조(환급, 반품 및 교환)<br/>
        "회사"는 이용자가 구매 신청한 상품이 품절 등의 사유로 상품의 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고, 사전에 상품의 대금을 받은 경우에는 대금을 받은 날부터 3일 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 3일 이내에 환급절차를 취합니다.<br/><br/>
        제14조(개인정보보호)<br/>
        ① "사이트"는 이용자의 정보 수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.<br/>
        다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br/>
        1. 희망ID(회원의 경우)<br/>
        2. 비밀번호(회원의 경우)<br/>
        3. 이름<br/>
        4. 휴대번호<br/>
        5. E-MAIL<br/>
        ② "사이트"가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.<br/>
        ③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 "사이트"이 집니다. 다만, 다음의 경우에는 예외로 합니다.<br/>
        1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br/>
        ④ "사이트"가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받는자, 제공목적 및 제공할 정보의 내용)등 정보통신망이용촉진 등에 관한 법률 제16조 제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.<br/>
        ⑤ 이용자는 언제든지 "사이트"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "사이트"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "사이트"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br/>
        ⑥ "사이트"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.<br/>
        ⑦ "사이트" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.<br/><br/>
        제15조("회사"의 의무)<br/>
        ① "회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품을 제공하는 데 최선을 다하여야 합니다.<br/>
        ② "회사"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
        ③ "회사"가 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br/>
        ④ "회사"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.<br/><br/>
        제16조( 회원의 ID 및 비밀번호에 대한 의무)<br/>
        ① 제14조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br/>
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.<br/>
        ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.<br/><br/>
        제17조(이용자의 의무)<br/>
        이용자는 다음 행위를 하여서는 안 됩니다.<br/>
        1. 신청 또는 변경 시 허위내용의 등록<br/>
        2. "사이트"에 게시된 정보의 변경<br/>
        3. "사이트"가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
        4. "사이트" 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
        5. "사이트" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
        6. 외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를 홈페이지에 공개 또는 게시하는 행위<br/><br/>
        제18조(저작권의 귀속 및 이용제한)<br/>
        ① "회사"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "회사"에 귀속합니다.<br/>
        ② 이용자는 "사이트"를 이용함으로써 얻은 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br/><br/>
        제19조(분쟁해결)<br/>
        ① "회사"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.<br/>
        ② "회사"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.<br/>
        ③ "회사"와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.<br/><br/>
        제20조(재판권 및 준거법)<br/>
        ① "회사"와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.<br/>
        ② "회사"와 이용자간에 제기된 전자거래 소송에는 한국법을 적용합니다.<br/><br/>
        부칙 (2021. 10. 01)<br/><br/>
        이 약관은 2021년 10월 01일부터 시행합니다.
        </p>
      </Grid>
    )
  }
}

export default TermsOfService;
