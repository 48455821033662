import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import BeatLoader from "react-spinners/BeatLoader";

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import queryString from 'query-string'

import Delivery from './Delivery';
import Regular from './Regular';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';

import * as firebase from 'firebase';

const axios = require("axios");

class RegularCardChangeResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      paymentCompleted: false,
      result: this.props.match.params.result,
      pickupId: '',
      isChanging: true,
      requestError: false,
      isRequestingPayment: false
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (!this.state.isRequestingPayment && nextProps.userInfo) {
        this.setState({isRequestingPayment: true}, () => {
          setTimeout(() => {
            this.onRequestChangeCardInfo(nextProps.userInfo)
          }, 10)
        })
      }
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  async onRequestChangeCardInfo(userInfo) {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { customerKey, authKey } = queryObj;

    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')

    axios({
      url: `https://api.tosspayments.com/v1/billing/authorizations/${authKey}`,
      method: "post",
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Basic " + encodingAuth
      },
      data: {
        customerKey: customerKey
      }
    }).then((data) => {
      if (data.status === 200) {
        firebase.database().ref(`regular/subscribers/${userInfo.uid}/paymentsInfo`).set(data.data)
        firebase.database().ref(`users/${userInfo.uid}/regular/paymentsInfo`).set(data.data)
        .then(() => {
          if (this.props.onRefreshUserInfo) {
            this.props.onRefreshUserInfo()
          }
          this.setState({isChanging: false, paymentCompleted: true}, () => {
            setTimeout(() => {
              this.props.history.replace(`/regular_detail?from=regular_card_change_result`);
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }, 2000)
          })
        })
      } else {
        this.setState({requestError: true}, () => {
          setTimeout(() => {
            this.props.history.replace('/regular_detail');
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
          }, 2000)
        })
      }
    }).catch((err) => {
      this.setState({requestError: true}, () => {
        setTimeout(() => {
          this.props.history.replace('/regular_detail');
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
        }, 2000)
      })
    })
  }



  render() {
    if (this.state.isLoading) {
      return (<div></div>)
    }
    if (this.state.result === 'fail') {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>카드 정보 변경이 실패했습니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>정기 구독 상세페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>카드 정보를 다시 확인하고 정확히 입력해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isChanging) {
      if (this.state.requestError) {
        return (
          <div>
            <div className="center-div">
              <div className="vertical-center-align direction-column-div auto-width-100">
              <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
              <span style={{fontSize: 14, marginTop: 15}}>카드 정보 변경이 실패했습니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>정기 구독 상세페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>카드 정보를 다시 확인하고 정확히 입력해주세요.</span>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>카드 정보를 확인 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.paymentCompleted) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>카드 변경 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>정기 구독 상세페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default RegularCardChangeResult;
