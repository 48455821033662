import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CreateIcon from '@material-ui/icons/Create';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ScheduleIcon from '@material-ui/icons/Schedule';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTicketAlt, faBell } from '@fortawesome/free-solid-svg-icons'

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

const { Kakao } = window;

class MyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo
    }
  }


  componentWillMount() {
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {

        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onLogout() {
    if (localStorage.getItem('kakao_login_uid')) {
      localStorage.removeItem('kakao_login_uid')
      firebase.auth().signOut()
      Kakao.Auth.logout()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    } else if (localStorage.getItem('naver_login_uid')) {
      localStorage.removeItem('naver_login_uid')
      firebase.auth().signOut()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    } else {
      firebase.auth().signOut()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    }
  }


  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <Grid container justify="center" style={{paddingBottom: 50}}>
        <Grid container className="relative-position" justify="center">
          <Grid container className="back-button-container" justify="center"  >
          <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>My위나</p>
          </Grid>
          <div style={{marginTop: 70}} className="auto-width-90 direction-column-div mypage-card">
            <div className="auto-width-100 direction-row-div" style={{margin: 25, marginLeft: 60}}>
            <Image
              style={{width: 40, height: 40, borderRadius: 50}}
              src={this.state.userInfo.photoURL}/>
            <Link className="none-underline-text"  onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    })
                  }
              }, 10)
            }} to={'/member'}>
            <div className="direction-column-div align-left" style={{marginLeft: 10, cursor: 'pointer'}}>
              <div className="direction-row-div">
                <span style={{marginTop: 1, marginBottom: 1, fontWeight: 'bold'}}>{this.state.userInfo.username}</span>
                { this.state.userInfo.isPartner &&
                  <div className="mini-badge-style">
                    <span style={{fontSize: 10, color: 'white'}}>사업자</span>
                  </div>
                }

              </div>
              <div className="direction-row-div">
                <span style={{fontSize: 11}}>내 정보 관리</span>
                <ArrowForwardIosIcon  style={{fontSize: 12, padding: 2}} />
              </div>
            </div>
            </Link>
            </div>
            <Divider className="auto-width-100" />
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }} to={'/order_list'}>
            <div className="direction-row-div">
            <LocalShippingOutlinedIcon  style={{color: '#5e5e5e', fontSize: 20, marginLeft: 20}} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>주문/배송</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>

            <Divider className="auto-width-100" />
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }} to={'/purchased_list'}>
            <div className="direction-row-div">
            <CreateIcon  style={{color: '#5e5e5e', fontSize: 20, marginLeft: 20}} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>후기 작성</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>
            <Divider className="auto-width-100" />
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }} to={'/pickup_list'}>
            <div className="direction-row-div">
            <EventNoteIcon  style={{color: '#5e5e5e', fontSize: 20, marginLeft: 20}} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>픽업 예약</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>
            <Divider className="auto-width-100" />
            { false &&
              <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {

                setTimeout(() => {
                  this.props.routeChanges()
                  if (isEdge || isIE) {
                      window.scrollTo(0, 0)
                    } else {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                      })
                    }
                }, 10)
              }} to={'/regular_detail'}>
              <div className="direction-row-div">
              <FontAwesomeIcon style={{color: '#5e5e5e', fontSize: 18, marginLeft: 22}} icon={faGift} />
              <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>정기 구독</span>
              </div>
              <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
              </Link>
            }

          </div>
          <div className="auto-width-90 direction-column-div mypage-card" style={{marginTop: 20}}>
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }} to={'/delivery_location'}>
            <div className="direction-row-div">
            <PinDropIcon  style={{color: '#5e5e5e', fontSize: 20, marginLeft: 20}} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>배송지 관리</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>
            <Divider className="auto-width-100" />
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {

              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    })
                  }
              }, 10)
            }} to={'/coupon_list'}>
            <div className="direction-row-div">
            <FontAwesomeIcon style={{color: '#5e5e5e', fontSize: 16, marginLeft: 22, marginTop: 2}} icon={faTicketAlt} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>보유 쿠폰</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>
            <Divider className="auto-width-100" />
            <Link className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}}  onClick={() => {

              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    })
                  }
              }, 10)
            }} to={'/alarm'}>
            <div className="direction-row-div">
            <FontAwesomeIcon style={{color: '#5e5e5e', fontSize: 18, marginLeft: 22, marginTop: 2}} icon={faBell} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 14}}>알림 설정</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </Link>
            <Divider className="auto-width-100" />
            <a className="none-underline-text auto-width-100 direction-row-div justify-between" style={{padding: 15, cursor: 'pointer'}} href={'https://open.kakao.com/o/skcPkwDd'} target="_blank">
            <div className="direction-row-div">
            <ContactSupportIcon  style={{color: '#5e5e5e', fontSize: 20, marginLeft: 20}} />
            <span style={{fontSize: 12, margin: 2, marginLeft: 10}}>카톡 1:1 문의</span>
            </div>
            <ArrowForwardIosIcon  style={{color: '#5e5e5e', fontSize: 13, marginRight: 15, marginTop: 5}} />
            </a>
          </div>
          <span onClick={() => {
            this.onLogout()
          }} style={{fontSize: 12, cursor: 'pointer', margin: 30, textDecoration: 'underline', color: 'gray'}}>로그 아웃</span>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(MyPage);
