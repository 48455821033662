import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import sconeClassIcon from '../sconeClassIcon.jpg';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import deliveryInWinaIcon from '../deliveryInWinaIcon.jpg';
import giftSetPickupIcon from '../giftSetPickupIcon.jpg';
import giftSetDeliveryIcon from '../giftSetDeliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';


class Landing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo
    }
  }


  componentWillMount() {

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }


  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <Grid container justify="center">
        <div className="logo-tilt max-height-100">
          <Image src={logoTilt} className="logo-tilt max-height-100" fluid style={{marginTop: 30}} />
        </div>
        <div className="direction-column-div" style={{marginTop: 10, marginBottom: 50}}>
          <a href="https://withanod.com" >
            <div className="landing-page-button-big  direction-columnl-div">
              <Image src={deliveryInWinaIcon} style={{width: 180, height: 120, marginTop: 10}} fluid />
            </div>
          </a>
          <a style={{marginTop: 20}} href="https://withanod.com/home/scone_class" >
            <div className="landing-page-button-big  direction-columnl-div">
              <Image src={sconeClassIcon} style={{width: 180, height: 120, marginTop: 10}} fluid />
            </div>
          </a>
          <a style={{marginTop: 20}} href="https://withanod.com/home/pickup" >
            <div className="landing-page-button-big  direction-columnl-div">
              <Image src={pickupIcon} style={{width: 120, height: 120, marginTop: 10}} fluid />
            </div>
          </a>
          
        </div>


        <Footer/>
      </Grid>
    )
  }
}

export default Landing;
