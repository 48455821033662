import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Delivery from './Delivery';
import Regular from './Regular';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      navIndex: 0,
      bottomNavIndex: 0
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }





  render() {
    return (
      <Container maxWidth="sm">
        <Grid className="main-view">
          <NavBar/>
          <Grid container justify="center" >
            { this.state.navIndex === 0 &&
              <Delivery/>
            }
            { this.state.navIndex === 1 &&
              <Regular/>
            }
            <Footer/>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default Home;
