import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import BeatLoader from "react-spinners/BeatLoader";

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import queryString from 'query-string'

import Delivery from './Delivery';
import Regular from './Regular';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';

import * as firebase from 'firebase';

const axios = require("axios");

class PaymentResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      paymentCompleted: false,
      result: this.props.match.params.result,
      pickupId: '',
      isPaying: true,
      requestError: false,
      isRequestingPayment: false
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (!this.state.isRequestingPayment && nextProps.userInfo) {
        this.setState({isRequestingPayment: true}, () => {
          setTimeout(() => {
            this.onRequestPayment(nextProps.userInfo)
          }, 10)
        })
      }
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  async onRequestPayment(userInfo) {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { paymentKey, orderId, amount } = queryObj;

    this.setState({pickupId: orderId})


    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')

    firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}/usingCoupon`).once('value', (snapshot) => {
          if (snapshot.val()) {
            let coupon = snapshot.val()
            firebase.database().ref(`coupons/${coupon.couponId}/usedAt`).once('value', (snapshot) => {
              if (snapshot.val()) {
                this.setState({requestError: true}, () => {
                  setTimeout(() => {
                    this.props.history.push('/home/pickup');
                    setTimeout(() => {
                      firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).remove()
                      this.props.routeChanges()
                    }, 10)
                  }, 2000)
                })
              } else {
                axios({
                  url: `https://api.tosspayments.com/v1/payments/${paymentKey}`,
                  method: "post",
                  headers: {
                    "Content-Type":"application/json",
                    "Authorization": "Basic " + encodingAuth
                  },
                  data: {
                    amount: amount, orderId: orderId
                  }
                }).then((data) => {
                  if (data.status === 200) {
                    if (data.data.status === 'DONE') {
                      if (sessionStorage.getItem('pickupData')) {
                        sessionStorage.removeItem('pickupData')
                      }
                      firebase.database().ref(`coupons/${coupon.couponId}`).set(coupon)
                      firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).once('value', (snapshot) => {
                        if (snapshot.val()) {
                          let purchaseInfo = snapshot.val()
                          purchaseInfo['paid'] = true
                          purchaseInfo['paidAt'] = Date.now()
                          purchaseInfo['paymentKey'] = paymentKey
                          if (data.data.card) {
                            purchaseInfo['card'] = data.data.card
                          }

                          firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/pickups/${orderId}`).set(purchaseInfo)
                          firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).set(purchaseInfo)
                          .then(() => {
                            axios({
                              url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendPickupMessage`,
                              method: "post",
                              headers: {
                                "Content-Type":"application/json"
                              },
                              data: {
                                pickupId: orderId,
                                uid: userInfo.uid
                              }
                            })
                            if (this.props.onRefreshUserInfo) {
                              this.props.onRefreshUserInfo()
                            }
                            if (purchaseInfo.additionalItems) {
                              Object.values(purchaseInfo.additionalItems).map((item, index) => {
                                firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    let remainAmount = snapshot.val()
                                    firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).set(Math.max(remainAmount - item.amount, 0))
                                  }
                                })

                              })
                            }
                            firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/menu`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                let menuList = Object.values(snapshot.val())
                                menuList = menuList.filter(menuList => menuList.amount)
                                menuList.map((menuItem, index) => {
                                  if (purchaseInfo['items'][menuItem.id]) {
                                    firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/menu/${menuItem.id}/amount`).set(Math.max(menuItem.amount - purchaseInfo['items'][menuItem.id].amount, 0))
                                  }
                                })
                              }
                            })
                            firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                let remainAmount = snapshot.val() - purchaseInfo['totalAmount']
                                if (remainAmount < 0) {
                                  remainAmount = 0
                                }
                                firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).set(remainAmount)
                              }
                            })
                            this.setState({isPaying: false, paymentCompleted: true}, () => {
                              setTimeout(() => {
                                this.props.history.replace(`/pickup_detail/${orderId}?from=payment_result`);
                                setTimeout(() => {
                                  this.props.routeChanges()
                                }, 10)
                              }, 2000)
                            })
                          })
                        }
                      })
                    }
                  } else {
                    this.setState({isPaying: false, paymentCompleted: false}, () => {
                      setTimeout(() => {
                        this.props.history.push('/home/pickup');
                        setTimeout(() => {
                          firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).remove()
                          this.props.routeChanges()
                        }, 10)
                      }, 2000)
                    })
                  }
                }).catch((err) => {
                  this.setState({requestError: true}, () => {
                    setTimeout(() => {
                      this.props.history.push('/home/pickup');
                      setTimeout(() => {
                        firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).remove()
                        this.props.routeChanges()
                      }, 10)
                    }, 2000)
                  })
                })
              }
            })
          } else {
            axios({
              url: `https://api.tosspayments.com/v1/payments/${paymentKey}`,
              method: "post",
              headers: {
                "Content-Type":"application/json",
                "Authorization": "Basic " + encodingAuth
              },
              data: {
                amount: amount, orderId: orderId
              }
            }).then((data) => {
              if (data.status === 200) {
                if (data.data.status === 'DONE') {
                  if (sessionStorage.getItem('pickupData')) {
                    sessionStorage.removeItem('pickupData')
                  }
                  firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let purchaseInfo = snapshot.val()
                      purchaseInfo['paid'] = true
                      purchaseInfo['paidAt'] = Date.now()
                      purchaseInfo['paymentKey'] = paymentKey
                      if (data.data.card) {
                        purchaseInfo['card'] = data.data.card
                      }

                      firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/pickups/${orderId}`).set(purchaseInfo)
                      firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).set(purchaseInfo)
                      .then(() => {
                        axios({
                          url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendPickupMessage`,
                          method: "post",
                          headers: {
                            "Content-Type":"application/json"
                          },
                          data: {
                            pickupId: orderId,
                            uid: userInfo.uid
                          }
                        })
                        if (this.props.onRefreshUserInfo) {
                          this.props.onRefreshUserInfo()
                        }
                        if (purchaseInfo.additionalItems) {
                          Object.values(purchaseInfo.additionalItems).map((item, index) => {
                            firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                let remainAmount = snapshot.val()
                                firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).set(Math.max(remainAmount - item.amount, 0))
                              }
                            })

                          })
                        }
                        firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            let remainAmount = snapshot.val() - purchaseInfo['totalAmount']
                            if (remainAmount < 0) {
                              remainAmount = 0
                            }
                            firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).set(remainAmount)
                          }
                        })
                        this.setState({isPaying: false, paymentCompleted: true}, () => {
                          setTimeout(() => {
                            this.props.history.replace(`/pickup_detail/${orderId}?from=payment_result`);
                            setTimeout(() => {
                              this.props.routeChanges()
                            }, 10)
                          }, 2000)
                        })
                      })
                    }
                  })
                }
              } else {
                this.setState({isPaying: false, paymentCompleted: false}, () => {
                  setTimeout(() => {
                    this.props.history.push('/home/pickup');
                    setTimeout(() => {
                      firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).remove()
                      this.props.routeChanges()
                    }, 10)
                  }, 2000)
                })
              }
            }).catch((err) => {
              this.setState({requestError: true}, () => {
                setTimeout(() => {
                  this.props.history.push('/home/pickup');
                  setTimeout(() => {
                    firebase.database().ref(`users/${userInfo.uid}/sconePickups/${orderId}`).remove()
                    this.props.routeChanges()
                  }, 10)
                }, 2000)
              })
            })
          }
        })
      }
    })


  }



  render() {
    if (this.state.isLoading) {
      return (<div></div>)
    }
    if (this.state.result === 'fail') {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isPaying) {
      if (this.state.requestError) {
        return (
          <div>
            <div className="center-div">
              <div className="vertical-center-align direction-column-div auto-width-100">
              <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.paymentCompleted) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>예약 목록 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default PaymentResult;
