import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Link
} from 'react-router-dom';
import Input from '@material-ui/core/Input';
import Modal from '@material-ui/core/Modal';

import Select from 'react-select';

import Sheet from 'react-modal-sheet';

import PulseLoader from "react-spinners/PulseLoader";

import {withRouter} from 'react-router-dom';

import Switch from "react-switch";

import shortid from 'shortid';

import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import * as firebase from 'firebase';
import { Row, Image } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck, faChevronRight, faChevronLeft, faCaretDown, faCaretUp, faSearch, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faHandPointLeft, faClone, faIdCard } from '@fortawesome/free-regular-svg-icons';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CancelIcon from '@material-ui/icons/Cancel';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import QrReader from 'react-qr-reader'

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";

import moment from 'moment';

import chatIcon from '../coffeeIcon.jpg';
import admin from '../admin.png';
import roundIcon from '../roundIcon.png';

import { CSVLink, CSVDownload } from "react-csv";

import { withStyles } from '@material-ui/core/styles';
let QRCode = require('qrcode.react');
const axios = require("axios");
const { Kakao } = window;
const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" size="small" {...props} />);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,

      openOrderSheet: null,

      startGetDeliveryDataSnack: false,

      today: moment(),
      pickupDateArr: [],
      pickupOpen: {},
      sconeMenu: {},
      pickupMenu: {},
      pickupAdditionalMenu: {},

      onSitePickupDate: null,
      isOpenSheet: false,
      request: '',
      giftBox: 0,
      onSitePickupPhoneNumber: '',
      onSitePickupDiscount: '',
      onSitePickupName: '',
      hourSelectedOption: '',
      minuteSelectedOption: '',
      hour: '',
      minute: '',

      totalAmount: 0,
      totalPrice: 0,
      selectedPickupScones: {},

      additionalPickups: null,
      selectedAdditiionalItems: {},
      additionalItemsPrice: 0,
      additionalItemsAmount: 0,

      showPickupOpen: false,

      amount: 50,

      selectedPickupOpenObj: {},

      isOpeningPickup: false,

      isOpenPickupList: false,
      openedDate: null,
      prevPosition: 0,

      selectedPickupId: null,

      applyDiscount: true,
      discountTitle: '픽업할인',
      discountRate: '5',
      minDiscountPrice : 20000,
      beforeOpenNotice: '1월 6일 목요일 오전 10시에 오픈 예정입니다.',

      editApplyDiscount: false,
      editDiscountTitle: '할인 이름',
      editDiscountRate: '5',
      editMinDiscountPrice : 20000,
      editPickupDiscountSnack: false,
      editRemainAmount: 0,

      eachAmount: {

      },

      showCancelPickupModal: false,
      isPickupCanceling: false,
      cancelPickupData: null,

      numberOfGiftBoxes: 0,
      giftBoxes: {},

      numberOfAdditaionalItems: 0,
      additionalItems: {},
      closingDate: '20220101',

      totalPickupScones: 0,

      totalIncome: 0,

      users: null,
      completingPickup: false,
      completePickupSnack: false,
      completePickupBuyerName: '',

      giftSetToday: moment(),
      isOpenGiftSetPickupList: false,
      openedGiftSetDate: null,
      prevGiftSetPosition: 0,

      selectedGiftSetPickupId: null,

      sentConfirmMessageSnack: false,
      showSendPickupMessageModal: false,

      regular: null,

      selectedScone: null,

      selectedAdditiionalItem: null,

      invoiceNumbers: {},
      isInsertingInvoiceNumbers: false,

      showRegularDetailInfo: {},

      orderSheets: {},
      totalDeliveryScones: 0,
      isOpenDeliveryList: false,
      openedDeliveryDate: null,
      selectedDeliveryOpenObj: {},
      deliveryToday: moment(),
      isOpeningDelivery: false,
      deliveryMenu: {},
      deliveryCharge: 4500,
      isStartingDeliveryOrder: false,
      showStartDeliveryOrderModal: false,
      showDeliveryReviewMessageModal: false,

      deliveryOrderData: [],

      addAmount: {},

      eachDeliveryAmount: {},
      totalDeliveryItems: 0,

      eachAdditioanlAmount: {},
      totalAdditioanlItems: 0,

      selectedDeliveryOrderId: {},
      selectedItem: null,
      deliveryInvoiceNumbers: {},
      isInsertingDeliveryInvoiceNumbers: false,

      completeInsertingInvoiceNumberSnack: false,
      completeInsertingItemsInvoiceNumberSnack: false,

      excelData: [],

      showDeliveryOpen: false,

      items: {},
      itemOrders: {},
      isOpenItemDeliveryList: false,
      openedItemDeliveryDate: null,
      selectedItemDeliveryOpenObj: {},
      itemDeliveryInvoiceNumbers: {},
      isInsertingitemDeliveryInvoiceNumbers: false,
      itemsDeliveryToday: moment(),
      selectedOrderId: {},
      selectedOrderItem: null,
      eachOrderAmount: {},
      totalOrderIncome: 0,
      totalOrderItems: 0,

      isOpeningItemDelivery: false,
      itemMenu: {},
      isStartingItemDeliveryOrder: false,
      showStartItemDeliveryOrderModal: false,

      addItemsAmount: {},

      excelItemData: [],

      showItemDeliveryOpen: false,

      adminPassword: '',
      completePickupCancelSnack: false,
      completeDeliveryCancelSnack: false,

      couponTo: '',
      couponToArray: [],
      couponAmount: 1000,
      showUseCouponModal: false,
      couponId: null,
      couponInfo: null,
      completeUsingCouponSnack: false,
      offlineCouponExpireDate: 45,

      showIssueCouponModal: false,
      completeIssueCouponSnack: false,
      isIssuingCoupons: false,

      onlineCouponTo: '',
      onlineCouponToArray: [],
      onlineCouponAmount: 1000,
      onlineCouponId: null,
      onlineCouponInfo: null,
      completeUsingonlineCouponSnack: false,
      onlineCouponMinimumAmount: 0,

      onlineCouponCategory: "scone",
      onlineCouponName: '스콘 택배 할인',
      onlineCouponEventName: null,
      onlineCouponExpireDate: 90,

      openHour: '',


      showIssueOnlineCouponModal: false,
      completeIssueOnlineCouponSnack: false,
      isIssuingOnlineCoupons: false,

      messageTo: '',
      messageToArray: [],

      showSendMessageModal: false,
      completeSendMessageSnack: false,
      isSendingMessage: false,

      messageContent: null,

      qrScanning: false,
      hasFoundQRCode: false,
      messageForScanning: '',
      showScanMessageModal: false,



      itemDeliveryMemo: {},
      completeMemoSnack: false,

      deliveryMemo: {},
      completeDeliveryMemoSnack: false,

      pickupMemo: {},
      completePickupMemoSnack: false,

      gotInvoiceData: false,
      gotItemInvoiceData: false,


      deliveryOrderList: [],
      deliveryItemOrderList: [],

      showCloseOrderModal: false,
      finishOrdersSnack: false,

      isOpenReviews: false,
      isOpenItemReviews: false,
      reviews: [],
      itemReviews: [],
      showBestRevieModal: false,
      selectedReview: null,
      selectedItemReview: null,
      completeSelectBestReviewSnack: false,
      completeSelectWinaPickReviewSnack: false,
      completeSelectBestItemReviewSnack: false,
      completeSelectWinaPickItemReviewSnack: false,

      copiedEntirePhoneNumbersSnack: false,
      copiedPhoneNumberSnack: false,
      copiedOrderIdSnack: false,
      copiedPickupIdSnack: false,

      tossPaymentsOrderId: null,
      completeCancelPaymentSnack: false,
      completePartialllyCancelPaymentSnack: false,

      providerCode: '06',
      deliveryProvidersArray: [],
      selectedDeliveryProvider: {},
      orderArray: [
        { value: 'none', label: '정렬 없음' },
        { value: 'numberOfOrders', label: '주문상품수 순' },
        { value: 'numberOfPurchases', label: '주문횟수 순' },
        { value: 'orderTimeLate', label: '늦은 주문시간 순' },
        { value: 'orderTimeEarly', label: '빠른 주문시간 순' }
      ],
      selectedOrder: { value: 'none', label: '정렬 없음' },

      paymentCancel: 'entire',
      partiallyCancelAmount: 0,

      searchTerm: '',
      notDeliveredChecked: false,
      numberOfOrdersChecked: false,
      numberOfPurchasesChecked: false,
      orderTimeLateChecked: false,
      orderTimeEarlyChecked: false,

      sconeDelivery: [],

      isOpenOnlineCouponList: false,
      onlineCouponList: [],

      isOpenOfflineCouponList: false,
      offlineCouponList: [],

      searchUserTerm: '',
      filteredUsers: [],
      tempOverOneOrdersChecked: false,
      tempNotGet1CouponChecked: false,
      tempOverThreeOrdersChecked: false,
      tempNotGet3CouponChecked: false,
      tempNotGet6CouponChecked: false,
      tempOverSixOrdersChecked: false,
      tempOverNineOrdersChecked: false,
      tempOverFivechecked: false,
      tempVipChecked: false,
      tempGoldChecked: false,

      overOneOrdersChecked: false,
      overThreeOrdersChecked: false,
      overSixOrdersChecked: false,
      notGet1CouponChecked: false,
      notGet3CouponChecked: false,
      notGet6CouponChecked: false,
      notGet9CouponChecked: false,
      overFivechecked: false,

      overFiveUsers: {},

      searchedUsersNumbers: [],

      selectedSconesObj: {},
      totalSelectedSconesAmount: 0,

      firstPurchaseFree: false,

      applyDeliveryDiscount: false,
      deliveryDiscountTitle: '이벤트 이름',
      deliveryDiscountRate: '5',
      deliveryDiscountUntil: Date.now(),
      setEndTimeOfEvent: true,

      editFirstPurchaseFree: false,

      editApplyDeliveryDiscount: false,
      editDeliveryDiscountTitle: '이벤트 이름',
      editDeliveryDiscountRate: '5',
      editDeliveryDiscountUntil: Date.now(),
      editSetEndTimeOfEvent: true,
      editDiscountSnack: false,
      editNotice: '',

      showCancelDeliveryModal: false,
      isDeliveryCanceling: false,
      cancelDeliveryData: null,

      showChangeDeliveryOpenDate: false,
      changedOrderOpenDateSnack: false,

      notSelectedChecked: {},
      userInfoWithClass: {},

      userInfos: {},
      selectedUserInfo: null,

      tempMemo: '',
      isChangingMemo: false,

      isSendingExpiredDateMessage: false,
      showSendingExpiredDateCouponModal: false,

      isCheckedSendExpiringOnlineCoupon: false,

      smartDeliveryApiKey: 'apikey'

    }
    this.adminRef = React.createRef()
  }


  componentWillMount() {
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)

    firebase.database().ref(`smartDeliveryApiKey`).once('value', (snapshot) => {
      this.setState({smartDeliveryApiKey: snapshot.val()})
    })

    firebase.database().ref(`openOrderSheet`).on('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({openOrderSheet: snapshot.val()})
      }
    })

    // firebase.database().ref(`alarms/sconeDelivery`).once('value', (snapshot) => {
    //   if (snapshot.val()) {
    //     let userListSetAlarm = Object.values(snapshot.val())
    //     userListSetAlarm = userListSetAlarm.filter(userListSetAlarm => userListSetAlarm.set === true)
    //
    //     let usersPhoneNumbers = []
    //     userListSetAlarm.map((alramInfo, index) => {
    //       usersPhoneNumbers.push(alramInfo.phoneNumber)
    //       if (userListSetAlarm.length === index + 1) {
    //         console.log(index + 1)
    //         console.log(usersPhoneNumbers)
    //         navigator.clipboard.writeText(usersPhoneNumbers)
    //       }
    //     })
    //   }
    // })

    // firebase.database().ref(`pickupOpen/20221008/pickups/PG3HQ6d84`).once('value', (snapshot) => {
    //   if (snapshot.val()) {
    //     firebase.database().ref(`pickupOpen/20221007/pickups/PG3HQ6d84`).set(snapshot.val())
    //   }
    // })

    firebase.database().ref(`alarms`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let sconeDelivery = Object.values(snapshot.val()['sconeDelivery'])
        sconeDelivery = sconeDelivery.filter(sconeDelivery => sconeDelivery.set === true)
        this.setState({sconeDelivery: sconeDelivery})
      }
    })
    firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let arrayData = []
        let selectedDeliveryProvider = {}
        Object.values(snapshot.val()).map((provider, index) => {
          let objData = {
            value: provider.providerCode,
            label: provider.provider
          }
          if (provider.providerCode === '06') {
            selectedDeliveryProvider = objData
          }
          arrayData.push(objData)
          if (Object.values(snapshot.val()).length === index + 1) {
            arrayData.sort((a, b) => {
              return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            })
            this.setState({deliveryProvidersArray: arrayData, selectedDeliveryProvider: selectedDeliveryProvider})
          }
        })

      }
    })
    firebase.database().ref(`users`).on('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({users: snapshot.val()})
      }
    })
    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({userInfos: snapshot.val()})
      }
    })
    firebase.database().ref(`regular`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let regular = snapshot.val()
        this.setState({regular: regular})
        if (regular.deliveries) {
          if (regular.deliveries[regular.nextPayDate]) {
            let lengthOfDeliveries = Object.keys(regular.deliveries[regular.nextPayDate]).length
            let invoiceNumbers = {}
            let loopIndex = 0
            Object.values(regular.deliveries[regular.nextPayDate]).map((delivery, index) => {
              if (delivery.invoiceNumber) {
                invoiceNumbers[delivery.uid] = {
                  invoiceNumber: delivery.invoiceNumber,
                  uid: delivery.uid
                }
                loopIndex = loopIndex + 1
                if (lengthOfDeliveries === loopIndex) {
                  this.setState({invoiceNumbers: invoiceNumbers})
                }
              }
            })
          }
        }
      }
    })

    firebase.database().ref(`items`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let itemsObj = snapshot.val()
        let itemReviews = []
        let itemMenu = {}
        Object.values(itemsObj).map((item, index) => {
          if (item.reviews) {
            itemReviews = itemReviews.concat(Object.values(item.reviews))
          }

          if (item.discountedPrice) {
            itemMenu[item.id] = {
              id: item.id,
              amount: item.amount,
              discountedPrice: item.discountedPrice
            }
          } else {
            itemMenu[item.id] = {
              id: item.id,
              amount: item.amount,
              discountedPrice: item.price
            }
          }


          if (index + 1 === Object.keys(itemsObj).length) {
            this.setState({itemReviews: itemReviews, itemMenu: itemMenu})
          }
        })
      }
    })

      firebase.database().ref(`items`).once('value', (snapshot) => {
        if (snapshot.val()) {

          let items = snapshot.val()
          let pickupAdditionalMenu = items

          Object.values(pickupAdditionalMenu).map((menu, index) => {
            let menuObj = menu
            menuObj['amount'] = 0
            delete menuObj['open']
            if (menuObj['reviews']) {
              delete menuObj['reviews']
            }
            pickupAdditionalMenu[menu.id] = menuObj
            this.setState({pickupAdditionalMenu: pickupAdditionalMenu})
          })



        }
      })

    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let sconeMenu = snapshot.val()
        let pickupMenu = {}
        let pickupMenuObj = {}
        let deliveryMenuObj = {}
        let reviews = []
        Object.keys(sconeMenu).map((menu, index) => {
          if (menu !== 'ENXex55N4') {
            pickupMenuObj[menu] = {
              id: menu
            }
          }

          if (menu !== 'JMdm6RMZO') {
            deliveryMenuObj[menu] = {
              id: menu,
              amount: 0
            }
          }

          if (sconeMenu[menu].reviews) {
            reviews = reviews.concat(Object.values(sconeMenu[menu].reviews))
          }




          if (index + 1 === Object.keys(sconeMenu).length) {

            reviews.sort((a, b) => {
              return b.createdAt - a.createdAt
            })

            this.setState({sconeMenu: sconeMenu, pickupMenu: pickupMenuObj, deliveryMenu: deliveryMenuObj, reviews: reviews})
          }
        })
      }
    }).then(() => {
      firebase.database().ref(`orderSheets`).on('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({orderSheets: snapshot.val()})

        }
      })

      firebase.database().ref(`pickupOpen`).on('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({pickupOpen: snapshot.val()})

        }
      })
      firebase.database().ref(`itemOrders`).on('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({itemOrders: snapshot.val()})

        }
      })
      firebase.database().ref(`items`).on('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({items: snapshot.val()})

        }
      })
      firebase.database().ref(`coupons`).once('value', (snapshot) => {
        if (snapshot.val()) {
          let offlineCouponList = Object.values(snapshot.val())
          offlineCouponList.sort((a, b) => {
            return b.issuedAt - a.issuedAt
          })
          this.setState({offlineCouponList: offlineCouponList})

        }
      })
      firebase.database().ref(`onlineCoupons`).once('value', (snapshot) => {
        if (snapshot.val()) {
          let onlineCouponList = Object.values(snapshot.val())
          onlineCouponList.sort((a, b) => {
            return b.issuedAt - a.issuedAt
          })
          this.setState({onlineCouponList: onlineCouponList})

        }
      })
    })
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function() {
      window.history.go(1);
    };
  }

  componentDidUpdate(){
  }

  componentWillUnmount() {
    firebase.database().ref(`pickupOpen`).off('value')
    firebase.database().ref(`orderSheets`).off('value')
    firebase.database().ref(`items`).off('value')
    firebase.database().ref(`itemOrders`).off('value')
  }


  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/admin');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }

    } else {
      this.setState({ isLoading: true });
    }
  }

  onPutOrderDataIntoUser() {
    firebase.database().ref(`orderSheets/20220227/orders/mF3kLDavX`).once('value', (snapshot) => {
      if (snapshot.val()) {
        firebase.database().ref(`users/naver:3z40pmBTRLnOiNKB4uU-FQuYLMCgyOKLj7_LrjMjbGY/sconeOrders/mF3kLDavX`).set(snapshot.val())
      }
    })
  }

  onGetDeliveryInfoData(order) {
    if (order.firstPurchaseFree) {
      this.setState({editFirstPurchaseFree: true})
    } else {
      this.setState({editFirstPurchaseFree: false})
    }

    if (order.notice) {
      this.setState({editNotice: order.notice})
    } else {
      this.setState({editNotice: ''})
    }

    if (order.discount) {
      if (order.discount['until']) {
        this.setState({
          editApplyDeliveryDiscount: true,
          editDeliveryDiscountTitle: order.discount['title'],
          editDeliveryDiscountRate: order.discount['rate'] * 100,
          editDeliveryDiscountUntil: order.discount['until'],
          editSetEndTimeOfEvent: true,
        })
      } else {
        this.setState({
          editApplyDeliveryDiscount: true,
          editDeliveryDiscountTitle: order.discount['title'],
          editDeliveryDiscountRate: order.discount['rate'] * 100,
          editSetEndTimeOfEvent: false,
        })
      }
    } else {
      this.setState({
        editApplyDeliveryDiscount: false,
        editDeliveryDiscountTitle: '이벤트 이름',
        editDeliveryDiscountRate: '5',
        editDeliveryDiscountUntil: Date.now(),
        editSetEndTimeOfEvent: true,
      })
    }
  }

  onGetPickupDiscountEventData(pickup) {
    this.setState({
      editRemainAmount: pickup.amount
    })
    if (pickup.discount) {
      this.setState({
        editApplyDiscount: true,
        editDiscountTitle: pickup.discount['title'],
        editDiscountRate: (pickup.discount['rate'] * 100).toString(),
        editMinDiscountPrice : 20000,
      })
    } else {
      this.setState({
        editApplyDiscount: false,
        editDiscountTitle: '할인 이름',
        editDiscountRate: '5',
        editMinDiscountPrice : 20000
      })

    }
  }

  onCheckCouponValidation(issuedAt) {
    return Number(moment(Date.now()).format('YYYYMMDD').toString()) <= Number(moment(issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())
  }

  onGetDeliveryInvoiceNumber(orders) {
    if (orders) {
      let lengthOfOrders = Object.keys(orders).length
      let invoiceNumbers = {}
      let loopIndex = 0
      Object.values(orders).map((order, index) => {
        if (order.invoiceNumber) {
          invoiceNumbers[order.orderId] = {
            invoiceNumber: order.invoiceNumber,
            uid: order.buyerUid,
            orderId: order.orderId
          }
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({deliveryInvoiceNumbers: invoiceNumbers})
          }
        } else {
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({deliveryInvoiceNumbers: invoiceNumbers})
          }
        }
      })
    }
  }

  onGetItemDeliveryInvoiceNumber(orders) {
    if (orders) {
      let lengthOfOrders = Object.keys(orders).length
      let invoiceNumbers = {}
      let loopIndex = 0
      Object.values(orders).map((order, index) => {
        if (order.invoiceNumber) {
          invoiceNumbers[order.orderId] = {
            invoiceNumber: order.invoiceNumber,
            uid: order.buyerUid,
            orderId: order.orderId
          }
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({itemDeliveryInvoiceNumbers: invoiceNumbers})
          }
        } else {
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({itemDeliveryInvoiceNumbers: invoiceNumbers})
          }
        }
      })
    }
  }

  onGetPickupMemo(pickups) {
    if (pickups) {
      let lengthOfPickups = Object.keys(pickups).length
      let memo = {}
      let loopIndex = 0
      Object.values(pickups).map((pickup, index) => {
        if (pickup.memo) {

          memo[pickup.pickupId] = pickup.memo
          loopIndex = loopIndex + 1
          if (lengthOfPickups === loopIndex) {

            this.setState({pickupMemo: memo})
          }
        } else {
          loopIndex = loopIndex + 1
          if (lengthOfPickups === loopIndex) {

            this.setState({pickupMemo: memo})
          }
        }
      })
    }
  }

  onGetDeliveryMemo(orders) {
    if (orders) {
      let lengthOfOrders = Object.keys(orders).length
      let memo = {}
      let loopIndex = 0
      Object.values(orders).map((order, index) => {
        if (order.memo) {
          memo[order.orderId] = order.memo
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({deliveryMemo: memo})
          }
        } else {
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({deliveryMemo: memo})
          }
        }
      })
    }
  }

  onGetItemDeliveryMemo(orders) {
    if (orders) {
      let lengthOfOrders = Object.keys(orders).length
      let memo = {}
      let loopIndex = 0
      Object.values(orders).map((order, index) => {
        if (order.memo) {
          memo[order.orderId] = order.memo
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({itemDeliveryMemo: memo})
          }
        } else {
          loopIndex = loopIndex + 1
          if (lengthOfOrders === loopIndex) {
            this.setState({itemDeliveryMemo: memo})
          }
        }
      })
    }
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {
      if (a.id) {
        return sconeMenu[a.id].order - sconeMenu[b.id].order
      } else if (a.itemId) {
        return sconeMenu[a.itemId].order - sconeMenu[b.itemId].order
      }

    })
    return returnValue
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
}

  onGetCurrentDateWithDay(day) {
    let today = new Date()
    let dd = day.toString().padStart(2, '0')
    let mm = (today.getMonth() + 1).toString().padStart(2, '0')
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`)
    return result
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day)/7);
    return weekOfMonth
  }

  onGetMonth(time) {
    return time.getMonth()
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    return lastDay
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  calendarArr(today) {

    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;
    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (this.state.pickupOpen[days.format('YYYYMMDD')]) {
              if (this.state.pickupOpen[days.format('YYYYMMDD')].amount <= 2) {
                return (
                    <td className="table-style" onClick={() => {
                      if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                        this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                        this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                        this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                        this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      } else {
                        this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                      }
                    }} key={index} style={{backgroundColor:'gray', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                      }
                    </td>
                )
              } else {
                if (this.onDateToTimeStamp(days.format('YYYYMMDD')) < Date.now()) {
                  return (
                    <td className="table-style" onClick={() => {
                      if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                        this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                        this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                        this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                        this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      } else {
                        this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                      }
                    }} key={index} style={{backgroundColor:'gray', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                      }
                    </td>
                  )
                } else {
                  if (days.format('MM') !== today.format('MM')) {
                    return (
                      <td className="table-style" key={index} style={{height: 45}} >
                      </td>
                    )
                  }
                  if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) {
                      return (
                          <td className={"table-style"} onClick={() => {
                            if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                              this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                              this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                              this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                              this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                              setTimeout(() => {
                                if (isEdge || isIE) {
                                  window.scrollTo(0,  0)
                                } else {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                }
                              }, 10)
                            } else {
                              this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                            }
                          }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            { this.state.pickupOpen[days.format('YYYYMMDD')].pickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                            }

                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1 || this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 2) {
                        return (
                            <td className={"table-style"} onClick={() => {
                              if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                                this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                                this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                                this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                                this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                    window.scrollTo(0,  0)
                                  } else {
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }
                                }, 10)
                              } else {
                                this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                              }
                            }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            { this.state.pickupOpen[days.format('YYYYMMDD')].pickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                            }
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                      return (
                          <td className={"table-style"} onClick={() => {
                            if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                              this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                              this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                              this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                              this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                              setTimeout(() => {
                                if (isEdge || isIE) {
                                  window.scrollTo(0,  0)
                                } else {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                }
                              }, 10)
                            } else {
                              this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                            }
                          }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                          { this.state.pickupOpen[days.format('YYYYMMDD')].pickups &&
                            <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                          }
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1) {
                        return (
                            <td className={"table-style"} onClick={() => {
                              if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                                this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                                this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                                this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                                this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                    window.scrollTo(0,  0)
                                  } else {
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }
                                }, 10)
                              } else {
                                this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                              }
                            }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            { this.state.pickupOpen[days.format('YYYYMMDD')].pickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                            }
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else {
                    return (
                        <td className={"table-style"} onClick={() => {
                          if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].pickups) {
                            this.setState({isOpenPickupList: true, openedDate: days.format('YYYYMMDD'), prevPosition: window.scrollY})
                            this.onGetPickupDiscountEventData(this.state.pickupOpen[days.format('YYYYMMDD')])
                            this.onGetEachAmount(this.state.pickupOpen[days.format('YYYYMMDD')])
                            this.onGetPickupMemo(this.state.pickupOpen[days.format('YYYYMMDD')].pickups)
                            setTimeout(() => {
                              if (isEdge || isIE) {
                                window.scrollTo(0,  0)
                              } else {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                            }, 10)
                          } else {
                            this.setState({onSitePickupDate: this.state.pickupOpen[days.format('YYYYMMDD').toString()], isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                          }
                        }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        { this.state.pickupOpen[days.format('YYYYMMDD')].pickups &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].pickups).length}건</span>
                        }
                          <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        </td>
                    )
                  }

                }
              }
            } else if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
              )
            } else {
              return (
                  <td onClick={() => {
                    let selectedPickupOpenObj = this.state.selectedPickupOpenObj
                    if (selectedPickupOpenObj[days.format('YYYYMMDD')]) {
                      delete selectedPickupOpenObj[days.format('YYYYMMDD')]
                    } else {
                      selectedPickupOpenObj[days.format('YYYYMMDD')] = true
                    }

                    this.setState({selectedPickupOpenObj: selectedPickupOpenObj})
                  }} className={this.state.selectedPickupOpenObj[days.format('YYYYMMDD')] ? "table-style selected-table-style" : "table-style"} key={index} style={{height: 45, cursor: 'pointer'}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  giftSetCalendarArr(today) {

    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;
    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (this.state.pickupOpen[days.format('YYYYMMDD')]) {
              if (this.state.pickupOpen[days.format('YYYYMMDD')].amount <= 2) {
                return (
                    <td className="table-style" onClick={() => {
                      if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                        this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      }
                    }} key={index} style={{backgroundColor:'gray', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                      }
                    </td>
                )
              } else {
                if (this.onDateToTimeStamp(days.format('YYYYMMDD')) < Date.now()) {
                  return (
                    <td className="table-style" onClick={() => {
                      if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                        this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      }
                    }} key={index} style={{backgroundColor:'gray', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                      }
                    </td>
                  )
                } else {
                  if (days.format('MM') !== today.format('MM')) {
                    return (
                      <td className="table-style" key={index} style={{height: 45}} >
                      </td>
                    )
                  }
                  if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) {
                      return (
                          <td className={"table-style"} onClick={() => {
                            if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                              this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                              setTimeout(() => {
                                if (isEdge || isIE) {
                                  window.scrollTo(0,  0)
                                } else {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                }
                              }, 10)
                            }
                          }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            { this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                            }

                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1 || this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 2) {
                        return (
                            <td className={"table-style"} onClick={() => {
                              if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                                this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                    window.scrollTo(0,  0)
                                  } else {
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }
                                }, 10)
                              }
                            }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            { this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                            }
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                      return (
                          <td className={"table-style"} onClick={() => {
                            if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                              this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                              setTimeout(() => {
                                if (isEdge || isIE) {
                                  window.scrollTo(0,  0)
                                } else {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                }
                              }, 10)
                            }
                          }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                          { this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups &&
                            <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                          }
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1) {
                        return (
                            <td className={"table-style"} onClick={() => {
                              if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                                this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                    window.scrollTo(0,  0)
                                  } else {
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }
                                }, 10)
                              }
                            }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                            { this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups &&
                              <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                            }
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else {
                    return (
                        <td className={"table-style"} onClick={() => {
                          if (this.state.pickupOpen[days.format('YYYYMMDD').toString()].giftSetPickups) {
                            this.setState({isOpenGiftSetPickupList: true, openedGiftSetDate: days.format('YYYYMMDD'), prevGiftSetPosition: window.scrollY})
                            setTimeout(() => {
                              if (isEdge || isIE) {
                                window.scrollTo(0,  0)
                              } else {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                            }, 10)
                          }
                        }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        { this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>예약 {Object.keys(this.state.pickupOpen[days.format('YYYYMMDD')].giftSetPickups).length}건</span>
                        }
                          <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        </td>
                    )
                  }

                }
              }
            } else if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
              )
            } else {
              return (
                  <td onClick={() => {
                    let selectedPickupOpenObj = this.state.selectedPickupOpenObj
                    if (selectedPickupOpenObj[days.format('YYYYMMDD')]) {
                      delete selectedPickupOpenObj[days.format('YYYYMMDD')]
                    } else {
                      selectedPickupOpenObj[days.format('YYYYMMDD')] = true
                    }

                    this.setState({selectedPickupOpenObj: selectedPickupOpenObj})
                  }} className={this.state.selectedPickupOpenObj[days.format('YYYYMMDD')] ? "table-style selected-table-style" : "table-style"} key={index} style={{height: 45, cursor: 'pointer'}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  sconeDeliveryCalendarArr(today) {

    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;
    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (this.state.orderSheets[days.format('YYYYMMDD')]) {
              if (days.format('MM') !== today.format('MM')) {
                return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
                )
              }
              if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                return (
                    <td className={"table-style"} onClick={() => {
                      if (this.state.showChangeDeliveryOpenDate) {
                        let orderSheetsList = Object.values(this.state.orderSheets)
                        orderSheetsList = orderSheetsList.filter(orderSheetsList => orderSheetsList.open === true)
                        if (orderSheetsList.length !== 1) {
                          if (this.state.openOrderSheet) {
                            if (Number(days.format('YYYYMMDD')) >= Number(this.state.openOrderSheet)) {
                              firebase.database().ref(`openOrderSheet`).set(Number(days.format('YYYYMMDD')))
                              .then(() => {
                                this.setState({showChangeDeliveryOpenDate: false, changedOrderOpenDateSnack: true})
                              })
                            } else {
                              alert('과거는 선택할 수 없습니다.')
                            }
                          }
                        } else {
                          alert('아직 택배 주문서가 오픈되어 있습니다.')
                        }
                      } else {
                        if (this.state.orderSheets[days.format('YYYYMMDD').toString()].orders) {
                          this.onGetDeliveryInfoData(this.state.orderSheets[days.format('YYYYMMDD')])
                          this.onGetDeliveryInvoiceNumber(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.onGetEachDeliveryAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                          this.onGetEachAdditionalItemsAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                          this.onGetDeliveryMemo(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.onGetExcelData(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.setState({isOpenDeliveryList: true, openedDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryOrderList: Object.values(this.state.orderSheets[days.format('YYYYMMDD')].orders)})
                          setTimeout(() => {
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }, 10)
                        } else {
                          alert('해당일에는 택배 주문이 없습니다.')
                        }
                      }

                    }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.orderSheets[days.format('YYYYMMDD').toString()].orders &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.orderSheets[days.format('YYYYMMDD')].orders).length}건</span>
                      }
                    </td>
                )
              } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                  return (
                      <td className={"table-style"} onClick={() => {
                        if (this.state.showChangeDeliveryOpenDate) {
                          let orderSheetsList = Object.values(this.state.orderSheets)
                          orderSheetsList = orderSheetsList.filter(orderSheetsList => orderSheetsList.open === true)
                          if (orderSheetsList.length !== 1) {
                            if (this.state.openOrderSheet) {
                              if (Number(days.format('YYYYMMDD')) >= Number(this.state.openOrderSheet)) {
                                firebase.database().ref(`openOrderSheet`).set(Number(days.format('YYYYMMDD')))
                                .then(() => {
                                  this.setState({showChangeDeliveryOpenDate: false, changedOrderOpenDateSnack: true})
                                })
                              } else {
                                alert('과거는 선택할 수 없습니다.')
                              }
                            }
                          } else {
                            alert('아직 택배 주문서가 오픈되어 있습니다.')
                          }
                        } else {
                          if (this.state.orderSheets[days.format('YYYYMMDD').toString()].orders) {
                            this.onGetDeliveryInfoData(this.state.orderSheets[days.format('YYYYMMDD')])
                            this.onGetDeliveryInvoiceNumber(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.onGetEachDeliveryAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                            this.onGetEachAdditionalItemsAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                            this.onGetDeliveryMemo(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.onGetExcelData(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.setState({isOpenDeliveryList: true, openedDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryOrderList: Object.values(this.state.orderSheets[days.format('YYYYMMDD')].orders)})
                            setTimeout(() => {
                              if (isEdge || isIE) {
                                window.scrollTo(0,  0)
                              } else {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                            }, 10)
                          } else {
                            alert('해당일에는 택배 주문이 없습니다.')
                          }
                        }

                      }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        { this.state.orderSheets[days.format('YYYYMMDD').toString()].orders &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.orderSheets[days.format('YYYYMMDD')].orders).length}건</span>
                        }
                      </td>
                  )
                } else {
                  return (
                      <td className={"table-style"} onClick={() => {
                        if (this.state.showChangeDeliveryOpenDate) {
                          let orderSheetsList = Object.values(this.state.orderSheets)
                          orderSheetsList = orderSheetsList.filter(orderSheetsList => orderSheetsList.open === true)
                          if (orderSheetsList.length !== 1) {
                            if (this.state.openOrderSheet) {
                              if (Number(days.format('YYYYMMDD')) >= Number(this.state.openOrderSheet)) {
                                firebase.database().ref(`openOrderSheet`).set(Number(days.format('YYYYMMDD')))
                                .then(() => {
                                  this.setState({showChangeDeliveryOpenDate: false, changedOrderOpenDateSnack: true})
                                })
                              } else {
                                alert('과거는 선택할 수 없습니다.')
                              }
                            }
                          } else {
                            alert('아직 택배 주문서가 오픈되어 있습니다.')
                          }
                        } else {
                          if (this.state.orderSheets[days.format('YYYYMMDD').toString()].orders) {
                            this.onGetDeliveryInfoData(this.state.orderSheets[days.format('YYYYMMDD')])
                            this.onGetDeliveryInvoiceNumber(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.onGetEachDeliveryAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                            this.onGetEachAdditionalItemsAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                            this.onGetDeliveryMemo(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.onGetExcelData(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                            this.setState({isOpenDeliveryList: true, openedDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryOrderList: Object.values(this.state.orderSheets[days.format('YYYYMMDD')].orders)})
                            setTimeout(() => {
                              if (isEdge || isIE) {
                                window.scrollTo(0,  0)
                              } else {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                            }, 10)
                          } else {
                            alert('해당일에는 택배 주문이 없습니다.')
                          }
                        }

                      }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        { this.state.orderSheets[days.format('YYYYMMDD').toString()].orders &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.orderSheets[days.format('YYYYMMDD')].orders).length}건</span>
                        }
                      </td>
                  )
                }
              } else {
                return (
                    <td className={"table-style"} onClick={() => {
                      if (this.state.showChangeDeliveryOpenDate) {
                        let orderSheetsList = Object.values(this.state.orderSheets)
                        orderSheetsList = orderSheetsList.filter(orderSheetsList => orderSheetsList.open === true)
                        if (orderSheetsList.length !== 1) {
                          if (this.state.openOrderSheet) {
                            if (Number(days.format('YYYYMMDD')) >= Number(this.state.openOrderSheet)) {
                              firebase.database().ref(`openOrderSheet`).set(Number(days.format('YYYYMMDD')))
                              .then(() => {
                                this.setState({showChangeDeliveryOpenDate: false, changedOrderOpenDateSnack: true})
                              })
                            } else {
                              alert('과거는 선택할 수 없습니다.')
                            }
                          }
                        } else {
                          alert('아직 택배 주문서가 오픈되어 있습니다.')
                        }
                      } else {
                        if (this.state.orderSheets[days.format('YYYYMMDD').toString()].orders) {
                          this.onGetDeliveryInfoData(this.state.orderSheets[days.format('YYYYMMDD')])
                          this.onGetDeliveryInvoiceNumber(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.onGetEachDeliveryAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                          this.onGetEachAdditionalItemsAmount(this.state.orderSheets[days.format('YYYYMMDD')].orders)
                          this.onGetDeliveryMemo(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.onGetExcelData(this.state.orderSheets[days.format('YYYYMMDD').toString()].orders)
                          this.setState({isOpenDeliveryList: true, openedDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryOrderList: Object.values(this.state.orderSheets[days.format('YYYYMMDD')].orders)})
                          setTimeout(() => {
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }, 10)
                        } else {
                          alert('해당일에는 택배 주문이 없습니다.')
                        }
                      }

                    }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.orderSheets[days.format('YYYYMMDD').toString()].orders &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.orderSheets[days.format('YYYYMMDD')].orders).length}건</span>
                      }
                    </td>
                )
              }
            } else if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
              )
            } else {
              return (
                  <td onClick={() => {
                    let selectedDeliveryOpenObj = this.state.selectedDeliveryOpenObj
                    if (selectedDeliveryOpenObj[days.format('YYYYMMDD')]) {
                      delete selectedDeliveryOpenObj[days.format('YYYYMMDD')]
                    } else {
                      selectedDeliveryOpenObj[days.format('YYYYMMDD')] = true
                    }

                    this.setState({selectedDeliveryOpenObj: selectedDeliveryOpenObj})
                  }} className={this.state.selectedDeliveryOpenObj[days.format('YYYYMMDD')] ? "table-style selected-table-style" : "table-style"} key={index} style={{height: 45, cursor: 'pointer'}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  itemsDeliveryCalendarArr(today) {
    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;
    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (this.state.itemOrders[days.format('YYYYMMDD')]) {
              if (days.format('MM') !== today.format('MM')) {
                return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
                )
              }
              if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                return (
                    <td className={"table-style"} onClick={() => {
                      if (this.state.itemOrders[days.format('YYYYMMDD').toString()]) {
                        this.onGetItemDeliveryInvoiceNumber(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.onGetEachOrderAmount(this.state.itemOrders[days.format('YYYYMMDD')])
                        this.onGetItemDeliveryMemo(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.onGetItemOrderExcelData(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.setState({isOpenItemDeliveryList: true, openedItemDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryItemOrderList: Object.values(this.state.itemOrders[days.format('YYYYMMDD').toString()])})
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      } else {
                        alert('해당일에는 택배 주문이 없습니다.')
                      }
                    }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.itemOrders[days.format('YYYYMMDD').toString()] &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.itemOrders[days.format('YYYYMMDD')]).length}건</span>
                      }
                    </td>
                )
              } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                  return (
                      <td className={"table-style"} onClick={() => {
                        if (this.state.itemOrders[days.format('YYYYMMDD').toString()]) {
                          this.onGetItemDeliveryInvoiceNumber(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.onGetEachOrderAmount(this.state.itemOrders[days.format('YYYYMMDD')])
                          this.onGetItemDeliveryMemo(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.onGetItemOrderExcelData(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.setState({isOpenItemDeliveryList: true, openedItemDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryItemOrderList: Object.values(this.state.itemOrders[days.format('YYYYMMDD').toString()])})
                          setTimeout(() => {
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }, 10)
                        } else {
                          alert('해당일에는 택배 주문이 없습니다.')
                        }
                      }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        { this.state.itemOrders[days.format('YYYYMMDD').toString()] &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.itemOrders[days.format('YYYYMMDD')]).length}건</span>
                        }
                      </td>
                  )
                } else {
                  return (
                      <td className={"table-style"} onClick={() => {
                        if (this.state.itemOrders[days.format('YYYYMMDD').toString()]) {
                          this.onGetItemDeliveryInvoiceNumber(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.onGetEachOrderAmount(this.state.itemOrders[days.format('YYYYMMDD')])
                          this.onGetItemDeliveryMemo(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.onGetItemOrderExcelData(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                          this.setState({isOpenItemDeliveryList: true, openedItemDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryItemOrderList: Object.values(this.state.itemOrders[days.format('YYYYMMDD').toString()])})
                          setTimeout(() => {
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }, 10)
                        } else {
                          alert('해당일에는 택배 주문이 없습니다.')
                        }
                      }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                        <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        { this.state.itemOrders[days.format('YYYYMMDD').toString()] &&
                          <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.itemOrders[days.format('YYYYMMDD')]).length}건</span>
                        }
                      </td>
                  )
                }
              } else {
                return (
                    <td className={"table-style"} onClick={() => {
                      if (this.state.itemOrders[days.format('YYYYMMDD').toString()]) {
                        this.onGetItemDeliveryInvoiceNumber(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.onGetEachOrderAmount(this.state.itemOrders[days.format('YYYYMMDD')])
                        this.onGetItemDeliveryMemo(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.onGetItemOrderExcelData(this.state.itemOrders[days.format('YYYYMMDD').toString()])
                        this.setState({isOpenItemDeliveryList: true, openedItemDeliveryDate: days.format('YYYYMMDD'), prevPosition: window.scrollY, deliveryItemOrderList: Object.values(this.state.itemOrders[days.format('YYYYMMDD').toString()])})
                        setTimeout(() => {
                          if (isEdge || isIE) {
                            window.scrollTo(0,  0)
                          } else {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }
                        }, 10)
                      } else {
                        alert('해당일에는 택배 주문이 없습니다.')
                      }
                    }} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer', position: 'relative'}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                      { this.state.itemOrders[days.format('YYYYMMDD').toString()] &&
                        <span style={{color: 'white', fontSize: 10, position: 'absolute', right: 4, bottom: 4}}>택배 {Object.keys(this.state.itemOrders[days.format('YYYYMMDD')]).length}건</span>
                      }
                    </td>
                )
              }
            } else if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                  </td>
              )
            } else {
              return (
                  <td onClick={() => {
                    let selectedItemDeliveryOpenObj = this.state.selectedItemDeliveryOpenObj
                    if (selectedItemDeliveryOpenObj[days.format('YYYYMMDD')]) {
                      delete selectedItemDeliveryOpenObj[days.format('YYYYMMDD')]
                    } else {
                      selectedItemDeliveryOpenObj[days.format('YYYYMMDD')] = true
                    }

                    this.setState({selectedItemDeliveryOpenObj: selectedItemDeliveryOpenObj})
                  }} className={this.state.selectedItemDeliveryOpenObj[days.format('YYYYMMDD')] ? "table-style selected-table-style" : "table-style"} key={index} style={{height: 45, cursor: 'pointer'}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  onDateNumberToDate(dateNumber) {
    let stringNumber = dateNumber.toString()
    return stringNumber.substring(0, 4) + "년" + stringNumber.substring(4, 6) + "월" + stringNumber.substring(6, 8) + '일 ' + this.onGetDay(dateNumber) + '요일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일`
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onGetEachAmount(pickupData) {
    let eachAmount = {}
    let pickups = Object.values(pickupData.pickups)
    let totalPickupScones = 0
    let numberOfGiftBoxes = 0
    let numberOfAdditaionalItems = 0
    let additionalItems = {}
    let giftBoxes = {}
    this.setState({numberOfGiftBoxes: 0, giftBoxes: {}, numberOfAdditaionalItems: 0})

    pickups.map((pickup, index) => {
      if (pickup.giftBox) {
        numberOfGiftBoxes = numberOfGiftBoxes + pickup.giftBox
        giftBoxes[pickup.pickupId] = pickup.giftBox
        this.setState({numberOfGiftBoxes: numberOfGiftBoxes, giftBoxes: giftBoxes})
      }
      if (pickup.additionalItems) {
        Object.values(pickup.additionalItems).map((each, innerIndex) => {
          if (additionalItems[each.itemId]) {
            additionalItems[each.itemId] = {
              amount: additionalItems[each.itemId].amount + each.amount,
              name: each.name,
              id: each.itemId
            }
          } else {
            additionalItems[each.itemId] = {
              amount: each.amount,
              name: each.name,
              id: each.itemId
            }
          }
          this.setState({additionalItems: additionalItems})
        })
      }
      Object.values(pickup.items).map((each, innerIndex) => {
        if (eachAmount[each.itemId]) {
          eachAmount[each.itemId] = {
            amount: eachAmount[each.itemId].amount + each.amount,
            name: each.name,
            id: each.itemId
          }
          totalPickupScones = totalPickupScones + each.amount
        } else {
          eachAmount[each.itemId] = {
            amount: each.amount,
            name: each.name,
            id: each.itemId
          }
          totalPickupScones = totalPickupScones + each.amount
        }
        this.setState({eachAmount: eachAmount, totalPickupScones: totalPickupScones})
      })
    })
  }

  onGetEachOrderAmount(orderData) {
    let eachAmount = {}
    let orders = Object.values(orderData)
    let totalOrderItems = 0
    let eachOrderAmount = 0

    orders.map((order, index) => {
      Object.values(order.items).map((each, innerIndex) => {
        if (eachAmount[each.itemId]) {
          eachAmount[each.itemId] = {
            amount: eachAmount[each.itemId].amount + each.amount,
            name: each.name,
            id: each.itemId
          }
          totalOrderItems = totalOrderItems + each.amount
        } else {
          eachAmount[each.itemId] = {
            amount: each.amount,
            name: each.name,
            id: each.itemId
          }
          totalOrderItems = totalOrderItems + each.amount
        }
        this.setState({eachOrderAmount: eachAmount, totalOrderItems: totalOrderItems})
      })
    })
  }

  onGetEachDeliveryAmount(orderData) {
    let eachAmount = {}
    let orders = Object.values(orderData)
    let totalDeliveryItems = 0
    let eachDeliveryAmount = 0

    orders.map((order, index) => {
      Object.values(order.items).map((each, innerIndex) => {
        if (eachAmount[each.itemId]) {
          eachAmount[each.itemId] = {
            amount: eachAmount[each.itemId].amount + each.amount,
            name: each.name,
            id: each.itemId
          }
          totalDeliveryItems = totalDeliveryItems + each.amount
        } else {
          eachAmount[each.itemId] = {
            amount: each.amount,
            name: each.name,
            id: each.itemId
          }
          totalDeliveryItems = totalDeliveryItems + each.amount
        }
        this.setState({eachDeliveryAmount: eachAmount, totalDeliveryItems: totalDeliveryItems})
      })
    })
  }

  onGetEachAdditionalItemsAmount(orderData) {
    let eachAmount = {}
    let orders = Object.values(orderData)
    let totalAdditioanlItems = 0
    let eachAdditioanlAmount = 0

    orders.map((order, index) => {
      if (order.additionalItems) {
        Object.values(order.additionalItems).map((each, innerIndex) => {
          if (eachAmount[each.itemId]) {
            eachAmount[each.itemId] = {
              amount: eachAmount[each.itemId].amount + each.amount,
              name: each.name,
              id: each.itemId
            }
            totalAdditioanlItems = totalAdditioanlItems + each.amount
          } else {
            eachAmount[each.itemId] = {
              amount: each.amount,
              name: each.name,
              id: each.itemId
            }
            totalAdditioanlItems = totalAdditioanlItems + each.amount
          }
          this.setState({eachAdditioanlAmount: eachAmount, totalAdditioanlItems: totalAdditioanlItems})
        })
      } else {
        this.setState({eachAdditioanlAmount: eachAmount, totalAdditioanlItems: totalAdditioanlItems})
      }

    })
  }


  onGetTime(now) {
      let time = ''
      if (now.getHours() < 10) {
        time = `오전${now.getHours()}`
      } else if (now.getHours() > 9 && now.getHours() < 12) {
        time = `오전${now.getHours()}`
      } else if (now.getHours() == 12) {
        time = '오후12'
      } else if (now.getHours() > 12) {
        time = `오후${now.getHours() - 12}`
      }
      if (now.getMinutes() < 10) {
        time += `:0${now.getMinutes()}`
      } else {
        time += `:${now.getMinutes()}`
      }

      return `${now.getFullYear()}년${now.getMonth() + 1}월${now.getDate()}일 ${time}`
    }

  onCountPickups(uid) {
    let users = this.state.users
    let sconePickups = []
    if (users[uid].sconePickups) {
      sconePickups = Object.values(users[uid].sconePickups)
      sconePickups = sconePickups.filter(sconePickups => sconePickups.paid === true)
    }
    return sconePickups.length
  }

  onStringNumberToYYYYMM(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월"
  }

  onStringNumberToYYYYMMDD(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년" + stringNumber.substring(4, 6) + "월" + stringNumber.substring(6, 8) + "일"
  }

  async onPay() {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    firebase.database().ref(`regular/subscribers`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let subscribers = snapshot.val()
        let subscribersArr = Object.values(subscribers)
        subscribersArr.map((subscriber, index) => {
          if (subscriber.paymentsInfo) {
            this.onMakingPayment(subscriber, encodingAuth)
          }
        })
      }
    })
  }

  onGetItemDeliveryInfo(order, userInfo) {
    if (order.invoiceNumber && !this.state.gotItemInvoiceData && userInfo) {
      this.setState({gotItemInvoiceData: true})
      if (!order.hasDelivered) {
        if (order.trackingTime) {
          if (order.trackingTime + (1000 * 60 * 30) < Date.now()) {
            let providerCode = '06'
            if (order['providerCode']) {
              providerCode = order['providerCode']
            }
            axios({
              url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=${this.state.smartDeliveryApiKey}&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
              method: "get",
              headers: {
                "Content-Type":"application/json"
              }
            }).then((data) => {
              let deliveries = userInfo.itemOrders
              if (data.data.status !== false) {
                userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
              }
              if (data.data.level === 6) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                }

                userInfo.itemOrders[order.orderId]['hasDelivered'] = true
              } else {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())

                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                }
              }
            })
          }
        } else {
          let providerCode = '06'
          if (order['providerCode']) {
            providerCode = order['providerCode']
          }
          axios({
            url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=${this.state.smartDeliveryApiKey}&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
            method: "get",
            headers: {
              "Content-Type":"application/json"
            }
          }).then((data) => {
            let deliveries = userInfo.itemOrders
            if (data.data.status !== false) {
              userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
            }
            if (data.data.level === 6) {
              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
              }

              userInfo.itemOrders[order.orderId]['hasDelivered'] = true
            } else {
              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())
              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
              }

            }
          })
        }

      }
    }
  }

  onGetDeliveryInfo(order, userInfo) {
    if (order.invoiceNumber && !this.state.gotInvoiceData && userInfo) {
      this.setState({gotInvoiceData: true})
      if (!order.hasDelivered) {
        if (order.trackingTime) {
          if (order.trackingTime + (1000 * 60 * 30) < Date.now()) {
            let providerCode = '06'
            if (order['providerCode']) {
              providerCode = order['providerCode']
            }
            axios({
              url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=${this.state.smartDeliveryApiKey}&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
              method: "get",
              headers: {
                "Content-Type":"application/json"
              }
            }).then((data) => {
              let deliveries = userInfo.sconeOrders
              if (data.data.status !== false) {
                userInfo.sconeOrders[order.orderId]['deiliveryInfo'] = data.data
              }
              if (data.data.level === 6) {
                firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/hasDelivered`).set(true)
                firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/hasDelivered`).set(true)
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                }

                userInfo.sconeOrders[order.orderId]['hasDelivered'] = true
              } else {
                firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/trackingTime`).set(Date.now())

                firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/trackingTime`).set(Date.now())
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                }

              }
            })
          }
        } else {
          let providerCode = '06'
          if (order['providerCode']) {
            providerCode = order['providerCode']
          }
          axios({
            url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=${this.state.smartDeliveryApiKey}&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
            method: "get",
            headers: {
              "Content-Type":"application/json"
            }
          }).then((data) => {
            let deliveries = userInfo.sconeOrders
            if (data.data.status !== false) {
              userInfo.sconeOrders[order.orderId]['deiliveryInfo'] = data.data
            }
            if (data.data.level === 6) {
              firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/hasDelivered`).set(true)
              firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/hasDelivered`).set(true)
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
              }

              userInfo.sconeOrders[order.orderId]['hasDelivered'] = true
            } else {
              firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/trackingTime`).set(Date.now())
              firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/trackingTime`).set(Date.now())
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
              }

            }
          })
        }

      }
    }
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onMakingPayment(subscriber, encodingAuth) {
    let orderId = shortid.generate()
    let regular = this.state.regular
    let totalPrice = regular.selections.basicPackage.price
    let payPrice = regular.selections.basicPackage.price
    let addition = {}
    if (subscriber.addition) {
      if (subscriber.addition['twoEggScones']) {
        payPrice = payPrice + this.onCalculateDiscount(regular.selections.eggScone.price, regular.selections.eggScone.discount)
        totalPrice = totalPrice + regular.selections.eggScone.price
        addition['twoEggScones'] = regular.selections.eggScone
      }
      if (subscriber.addition['twoNunettineScones']) {
        payPrice = payPrice + this.onCalculateDiscount(regular.selections.nunettineScone.price, regular.selections.nunettineScone.discount)
        totalPrice = totalPrice + regular.selections.nunettineScone.price
        addition['twoNunettineScones'] = regular.selections.nunettineScone
      }
      if (subscriber.addition['plainSauce']) {
        payPrice = payPrice + this.onCalculateDiscount(regular.selections.plainSauce1.price, regular.selections.plainSauce1.discount)
        totalPrice = totalPrice + regular.selections.plainSauce1.price
        addition['plainSauce'] = regular.selections.plainSauce1
      }
      if (subscriber.addition['twoPlainSauces']) {
        payPrice = payPrice + this.onCalculateDiscount(regular.selections.plainSauce2.price, regular.selections.plainSauce2.discount)
        totalPrice = totalPrice + regular.selections.plainSauce2.price
        addition['twoPlainSauces'] = regular.selections.plainSauce2
      }
    }
    firebase.database().ref(`regular/deliveries/${regular.nextPayDate}/${subscriber.uid}`).once('value', (snapshot) => {
      if (!snapshot.val()) {
        firebase.database().ref(`regular/subscribers/${subscriber.uid}/pause`).once('value', (snapshot) => {
          if (snapshot.val() === false) {
            axios({
              url: `https://api.tosspayments.com/v1/billing/${subscriber.paymentsInfo.billingKey}`,
              method: "post",
              headers: {
                "Content-Type":"application/json",
                "Authorization": "Basic " + encodingAuth
              },
              data: {
                customerKey: subscriber.uid,
                amount: payPrice,
                orderId: orderId,
                orderName: '위드어나드 정기구독 결제'
              }
            }).then((data) => {
              if (data.status === 200) {
                if (data.data.status === 'DONE') {
                  firebase.database().ref(`regular/subscribers/${subscriber.uid}`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let regularInfo = snapshot.val()
                      if (!regularInfo.pause) {
                        if (Object.keys(addition).length) {
                          regularInfo['addition'] = addition
                        }
                        regularInfo['basicPackage'] = regular.selections['basicPackage']
                        regularInfo['card'] = data.data.card
                        regularInfo['deliveryDate'] = regular.nextDeliveryDate
                        regularInfo['paidAt'] = Date.now()
                        regularInfo['paidPrice'] = payPrice
                        regularInfo['payDate'] = regular.nextPayDate
                        regularInfo['totalPrice'] = totalPrice
                        regularInfo['orderId'] = orderId

                        firebase.database().ref(`regular/deliveries/${regular.nextPayDate}/${subscriber.uid}`).set(regularInfo)
                        firebase.database().ref(`users/${subscriber.uid}/regular/deliveries/${regular.nextPayDate}`).set(regularInfo)
                      }
                    }
                  })
                }
              }
            })
          }
        })
      }
    })

  }

  onGetItemOrderExcelData(orders) {
    let totalAmount = 0
    let eachAmount = {}
    Object.values(orders).map((order, index) => {
      let excelItemData = this.state.excelItemData
      let amount = 0
      if (!excelItemData[index]) {
        excelItemData[index] = {
          number: index + 1, buyerName: order.buyerName, receiver: order.addressTo.receiver, phoneNumber: order.addressTo.phoneNumber + String.fromCharCode(8203), address1: order.addressTo.fullAddress, address2: order.addressTo.detailAddress
        }
      }
      if (order.items['2E3gXD-i1']) {
        excelItemData[index]['strawberry_compote'] = order.items['2E3gXD-i1'].amount
        amount = amount + order.items['2E3gXD-i1'].amount
        if (!eachAmount['2E3gXD-i1']) {
          eachAmount['2E3gXD-i1'] = order.items['2E3gXD-i1'].amount
        } else {
          eachAmount['2E3gXD-i1'] = eachAmount['2E3gXD-i1'] + order.items['2E3gXD-i1'].amount
        }

        totalAmount = totalAmount + order.items['2E3gXD-i1'].amount
      }
      if (order.items['eXuir_o5k']) {
        excelItemData[index]['clotted_cream'] = order.items['eXuir_o5k'].amount
        amount = amount + order.items['eXuir_o5k'].amount
        if (!eachAmount['eXuir_o5k']) {
          eachAmount['eXuir_o5k'] = order.items['eXuir_o5k'].amount
        } else {
          eachAmount['eXuir_o5k'] = eachAmount['eXuir_o5k'] + order.items['eXuir_o5k'].amount
        }
        totalAmount = totalAmount + order.items['eXuir_o5k'].amount
      }
      if (order.items['2roIAqZkqi']) {
        excelItemData[index]['pistachio_clotted_cream'] = order.items['2roIAqZkqi'].amount
        amount = amount + order.items['2roIAqZkqi'].amount
        if (!eachAmount['2roIAqZkqi']) {
          eachAmount['2roIAqZkqi'] = order.items['2roIAqZkqi'].amount
        } else {
          eachAmount['2roIAqZkqi'] = eachAmount['2roIAqZkqi'] + order.items['2roIAqZkqi'].amount
        }
        totalAmount = totalAmount + order.items['2roIAqZkqi'].amount
      }
      if (order.items['hXo2RQU_w']) {
        excelItemData[index]['assam_milkteabase'] = order.items['hXo2RQU_w'].amount
        amount = amount + order.items['hXo2RQU_w'].amount
        if (!eachAmount['hXo2RQU_w']) {
          eachAmount['hXo2RQU_w'] = order.items['hXo2RQU_w'].amount
        } else {
          eachAmount['hXo2RQU_w'] = eachAmount['hXo2RQU_w'] + order.items['hXo2RQU_w'].amount
        }
        totalAmount = totalAmount + order.items['hXo2RQU_w'].amount
      }
      if (order.items['ACkU0pIH9']) {
        excelItemData[index]['scone_mix'] = order.items['ACkU0pIH9'].amount
        amount = amount + order.items['ACkU0pIH9'].amount
        if (!eachAmount['ACkU0pIH9']) {
          eachAmount['ACkU0pIH9'] = order.items['ACkU0pIH9'].amount
        } else {
          eachAmount['ACkU0pIH9'] = eachAmount['ACkU0pIH9'] + order.items['ACkU0pIH9'].amount
        }
        totalAmount = totalAmount + order.items['ACkU0pIH9'].amount
      }
      if (order.items['BgzeeCnZd2']) {
        excelItemData[index]['wina_mug_cup_green_330ml'] = order.items['BgzeeCnZd2'].amount
        amount = amount + order.items['BgzeeCnZd2'].amount
        if (!eachAmount['BgzeeCnZd2']) {
          eachAmount['BgzeeCnZd2'] = order.items['BgzeeCnZd2'].amount
        } else {
          eachAmount['BgzeeCnZd2'] = eachAmount['BgzeeCnZd2'] + order.items['BgzeeCnZd2'].amount
        }
        totalAmount = totalAmount + order.items['BgzeeCnZd2'].amount
      }
      if (order.items['BkMuBT9_rM']) {
        excelItemData[index]['wina_mug_cup_orange_330ml'] = order.items['BkMuBT9_rM'].amount
        amount = amount + order.items['BkMuBT9_rM'].amount
        if (!eachAmount['BkMuBT9_rM']) {
          eachAmount['BkMuBT9_rM'] = order.items['BkMuBT9_rM'].amount
        } else {
          eachAmount['BkMuBT9_rM'] = eachAmount['BkMuBT9_rM'] + order.items['BkMuBT9_rM'].amount
        }
        totalAmount = totalAmount + order.items['BkMuBT9_rM'].amount
      }
      if (order.items['DSZYSKZqr']) {
        excelItemData[index]['wina_mug_cup_green_200ml'] = order.items['DSZYSKZqr'].amount
        amount = amount + order.items['DSZYSKZqr'].amount
        if (!eachAmount['DSZYSKZqr']) {
          eachAmount['DSZYSKZqr'] = order.items['DSZYSKZqr'].amount
        } else {
          eachAmount['DSZYSKZqr'] = eachAmount['DSZYSKZqr'] + order.items['DSZYSKZqr'].amount
        }
        totalAmount = totalAmount + order.items['DSZYSKZqr'].amount
      }
      if (order.items['D0vZqaBEdW']) {
        excelItemData[index]['wina_mug_cup_orange_200ml'] = order.items['D0vZqaBEdW'].amount
        amount = amount + order.items['D0vZqaBEdW'].amount
        if (!eachAmount['D0vZqaBEdW']) {
          eachAmount['D0vZqaBEdW'] = order.items['D0vZqaBEdW'].amount
        } else {
          eachAmount['D0vZqaBEdW'] = eachAmount['D0vZqaBEdW'] + order.items['D0vZqaBEdW'].amount
        }
        totalAmount = totalAmount + order.items['D0vZqaBEdW'].amount
      }
      if (order.items['EXXCAMXKn']) {
        excelItemData[index]['wina_wood_tea_coaster'] = order.items['EXXCAMXKn'].amount
        amount = amount + order.items['EXXCAMXKn'].amount
        if (!eachAmount['EXXCAMXKn']) {
          eachAmount['EXXCAMXKn'] = order.items['EXXCAMXKn'].amount
        } else {
          eachAmount['EXXCAMXKn'] = eachAmount['EXXCAMXKn'] + order.items['EXXCAMXKn'].amount
        }
        totalAmount = totalAmount + order.items['EXXCAMXKn'].amount
      }
      if (order.memo) {
        excelItemData[index]['memo'] = order.memo
      }
      excelItemData[index]['total_amount'] = amount
      if (Object.values(orders).length === index + 1) {
        excelItemData[index + 1] = {
          number: '', buyerName: '', receiver: '총 수량', phoneNumber: '', address1: '', address2: ''
        }
        excelItemData[index + 1]['strawberry_compote'] = eachAmount['2E3gXD-i1']
        excelItemData[index + 1]['clotted_cream'] = eachAmount['eXuir_o5k']
        excelItemData[index + 1]['pistachio_clotted_cream'] = eachAmount['2roIAqZkqi']
        excelItemData[index + 1]['assam_milkteabase'] = eachAmount['hXo2RQU_w']
        excelItemData[index + 1]['scone_mix'] = eachAmount['ACkU0pIH9']
        excelItemData[index + 1]['wina_mug_cup_green_330ml'] = eachAmount['BgzeeCnZd2']
        excelItemData[index + 1]['wina_mug_cup_orange_330ml'] = eachAmount['BkMuBT9_rM']
        excelItemData[index + 1]['wina_mug_cup_green_200ml'] = eachAmount['DSZYSKZqr']
        excelItemData[index + 1]['wina_mug_cup_orange_200ml'] = eachAmount['D0vZqaBEdW']
        excelItemData[index + 1]['wina_wood_tea_coaster'] = eachAmount['EXXCAMXKn']
        excelItemData[index + 1]['total_amount'] = totalAmount
        this.setState({excelItemData: excelItemData})
      }
    })
  }

  onGetExcelData(orders) {
    let totalAmount = 0
    let eachAmount = {}
    Object.values(orders).map((order, index) => {
      let excelData = this.state.excelData
      let amount = 0
      if (!excelData[index]) {
        excelData[index] = {
          number: index + 1, buyerName: order.buyerName, receiver: order.addressTo.receiver, phoneNumber: order.addressTo.phoneNumber + String.fromCharCode(8203), address1: order.addressTo.fullAddress, address2: order.addressTo.detailAddress
        }
      }
      if (order.items['ENXex55N4']) {
        excelData[index]['plain_scone'] = order.items['ENXex55N4'].amount
        amount = amount + order.items['ENXex55N4'].amount
        if (!eachAmount['ENXex55N4']) {
          eachAmount['ENXex55N4'] = order.items['ENXex55N4'].amount
        } else {
          eachAmount['ENXex55N4'] = eachAmount['ENXex55N4'] + order.items['ENXex55N4'].amount
        }

        totalAmount = totalAmount + order.items['ENXex55N4'].amount
      }
      if (order.items['SW8g84Fi7']) {
        excelData[index]['crumble_scone'] = order.items['SW8g84Fi7'].amount
        amount = amount + order.items['SW8g84Fi7'].amount
        if (!eachAmount['SW8g84Fi7']) {
          eachAmount['SW8g84Fi7'] = order.items['SW8g84Fi7'].amount
        } else {
          eachAmount['SW8g84Fi7'] = eachAmount['SW8g84Fi7'] + order.items['SW8g84Fi7'].amount
        }
        totalAmount = totalAmount + order.items['SW8g84Fi7'].amount
      }
      if (order.items['JY2XRab1j']) {
        excelData[index]['cinnamon_scone'] = order.items['JY2XRab1j'].amount
        amount = amount + order.items['JY2XRab1j'].amount
        if (!eachAmount['JY2XRab1j']) {
          eachAmount['JY2XRab1j'] = order.items['JY2XRab1j'].amount
        } else {
          eachAmount['JY2XRab1j'] = eachAmount['JY2XRab1j'] + order.items['JY2XRab1j'].amount
        }
        totalAmount = totalAmount + order.items['JY2XRab1j'].amount
      }
      if (order.items['EgE29AfJ98']) {
        excelData[index]['lemon_scone'] = order.items['EgE29AfJ98'].amount
        amount = amount + order.items['EgE29AfJ98'].amount
        if (!eachAmount['EgE29AfJ98']) {
          eachAmount['EgE29AfJ98'] = order.items['EgE29AfJ98'].amount
        } else {
          eachAmount['EgE29AfJ98'] = eachAmount['EgE29AfJ98'] + order.items['EgE29AfJ98'].amount
        }
        totalAmount = totalAmount + order.items['EgE29AfJ98'].amount
      }

      if (order.items['1Dyy5MmBuz2']) {
        excelData[index]['cinamon_roll'] = order.items['1Dyy5MmBuz2'].amount
        amount = amount + order.items['1Dyy5MmBuz2'].amount
        if (!eachAmount['1Dyy5MmBuz2']) {
          eachAmount['1Dyy5MmBuz2'] = order.items['1Dyy5MmBuz2'].amount
        } else {
          eachAmount['1Dyy5MmBuz2'] = eachAmount['1Dyy5MmBuz2'] + order.items['1Dyy5MmBuz2'].amount
        }
        totalAmount = totalAmount + order.items['1Dyy5MmBuz2'].amount
      }
      if (order.items['1AxrE_CQ1N']) {
        excelData[index]['choco_roll'] = order.items['1AxrE_CQ1N'].amount
        amount = amount + order.items['1AxrE_CQ1N'].amount
        if (!eachAmount['1AxrE_CQ1N']) {
          eachAmount['1AxrE_CQ1N'] = order.items['1AxrE_CQ1N'].amount
        } else {
          eachAmount['1AxrE_CQ1N'] = eachAmount['1AxrE_CQ1N'] + order.items['1AxrE_CQ1N'].amount
        }
        totalAmount = totalAmount + order.items['1AxrE_CQ1N'].amount
      }
      if (order.items['1Dzd38Df90']) {
        excelData[index]['caramel_pecan_roll'] = order.items['1Dzd38Df90'].amount
        amount = amount + order.items['1Dzd38Df90'].amount
        if (!eachAmount['1Dzd38Df90']) {
          eachAmount['1Dzd38Df90'] = order.items['1Dzd38Df90'].amount
        } else {
          eachAmount['1Dzd38Df90'] = eachAmount['1Dzd38Df90'] + order.items['1Dzd38Df90'].amount
        }
        totalAmount = totalAmount + order.items['1Dzd38Df90'].amount
      }
      if (order.items['9svZrVSuC']) {
        excelData[index]['maple_pecan_scone'] = order.items['9svZrVSuC'].amount
        amount = amount + order.items['9svZrVSuC'].amount
        if (!eachAmount['9svZrVSuC']) {
          eachAmount['9svZrVSuC'] = order.items['9svZrVSuC'].amount
        } else {
          eachAmount['9svZrVSuC'] = eachAmount['9svZrVSuC'] + order.items['9svZrVSuC'].amount
        }
        totalAmount = totalAmount + order.items['9svZrVSuC'].amount
      }
      if (order.items['74NG9m1j7']) {
        excelData[index]['mugwort_scone'] = order.items['74NG9m1j7'].amount
        amount = amount + order.items['74NG9m1j7'].amount
        if (!eachAmount['74NG9m1j7']) {
          eachAmount['74NG9m1j7'] = order.items['74NG9m1j7'].amount
        } else {
          eachAmount['74NG9m1j7'] = eachAmount['74NG9m1j7'] + order.items['74NG9m1j7'].amount
        }
        totalAmount = totalAmount + order.items['74NG9m1j7'].amount
      }
      if (order.items['NYkkc1ryd']) {
        excelData[index]['egg_scone'] = order.items['NYkkc1ryd'].amount
        amount = amount + order.items['NYkkc1ryd'].amount
        if (!eachAmount['NYkkc1ryd']) {
          eachAmount['NYkkc1ryd'] = order.items['NYkkc1ryd'].amount
        } else {
          eachAmount['NYkkc1ryd'] = eachAmount['NYkkc1ryd'] + order.items['NYkkc1ryd'].amount
        }
        totalAmount = totalAmount + order.items['NYkkc1ryd'].amount
      }
      if (order.items['N31Dqd40Fe']) {
        excelData[index]['raspberry_egg_scone'] = order.items['N31Dqd40Fe'].amount
        amount = amount + order.items['N31Dqd40Fe'].amount
        if (!eachAmount['N31Dqd40Fe']) {
          eachAmount['N31Dqd40Fe'] = order.items['N31Dqd40Fe'].amount
        } else {
          eachAmount['N31Dqd40Fe'] = eachAmount['N31Dqd40Fe'] + order.items['N31Dqd40Fe'].amount
        }
        totalAmount = totalAmount + order.items['N31Dqd40Fe'].amount
      }
      if (order.items['1r2QwejR40']) {
        excelData[index]['choco_egg_scone'] = order.items['1r2QwejR40'].amount
        amount = amount + order.items['1r2QwejR40'].amount
        if (!eachAmount['1r2QwejR40']) {
          eachAmount['1r2QwejR40'] = order.items['1r2QwejR40'].amount
        } else {
          eachAmount['1r2QwejR40'] = eachAmount['1r2QwejR40'] + order.items['1r2QwejR40'].amount
        }
        totalAmount = totalAmount + order.items['1r2QwejR40'].amount
      }
      if (order.items['Nd345dFq20']) {
        excelData[index]['apple_cinnamon_egg_scone'] = order.items['Nd345dFq20'].amount
        amount = amount + order.items['Nd345dFq20'].amount
        if (!eachAmount['Nd345dFq20']) {
          eachAmount['Nd345dFq20'] = order.items['Nd345dFq20'].amount
        } else {
          eachAmount['Nd345dFq20'] = eachAmount['Nd345dFq20'] + order.items['Nd345dFq20'].amount
        }
        totalAmount = totalAmount + order.items['Nd345dFq20'].amount
      }
      if (order.items['N4dDse24Sa']) {
        excelData[index]['blueberry_egg_scone'] = order.items['N4dDse24Sa'].amount
        amount = amount + order.items['N4dDse24Sa'].amount
        if (!eachAmount['N4dDse24Sa']) {
          eachAmount['N4dDse24Sa'] = order.items['N4dDse24Sa'].amount
        } else {
          eachAmount['N4dDse24Sa'] = eachAmount['N4dDse24Sa'] + order.items['N4dDse24Sa'].amount
        }
        totalAmount = totalAmount + order.items['N4dDse24Sa'].amount
      }
      if (order.items['Po-QQGXmU']) {
        excelData[index]['corn_scone'] = order.items['Po-QQGXmU'].amount
        amount = amount + order.items['Po-QQGXmU'].amount
        if (!eachAmount['Po-QQGXmU']) {
          eachAmount['Po-QQGXmU'] = order.items['Po-QQGXmU'].amount
        } else {
          eachAmount['Po-QQGXmU'] = eachAmount['Po-QQGXmU'] + order.items['Po-QQGXmU'].amount
        }
        totalAmount = totalAmount + order.items['Po-QQGXmU'].amount
      }
      if (order.items['A8MaVd-JU']) {
        excelData[index]['nunettine_scone'] = order.items['A8MaVd-JU'].amount
        amount = amount + order.items['A8MaVd-JU'].amount
        if (!eachAmount['A8MaVd-JU']) {
          eachAmount['A8MaVd-JU'] = order.items['A8MaVd-JU'].amount
        } else {
          eachAmount['A8MaVd-JU'] = eachAmount['A8MaVd-JU'] + order.items['A8MaVd-JU'].amount
        }
        totalAmount = totalAmount + order.items['A8MaVd-JU'].amount
      }
      if (order.items['3FE3Qdf9ab']) {
        excelData[index]['raspberry_cream_bun'] = order.items['3FE3Qdf9ab'].amount
        amount = amount + order.items['3FE3Qdf9ab'].amount
        if (!eachAmount['3FE3Qdf9ab']) {
          eachAmount['3FE3Qdf9ab'] = order.items['3FE3Qdf9ab'].amount
        } else {
          eachAmount['3FE3Qdf9ab'] = eachAmount['3FE3Qdf9ab'] + order.items['3FE3Qdf9ab'].amount
        }
        totalAmount = totalAmount + order.items['3FE3Qdf9ab'].amount
      }

      if (order.items['3rEf5F09Fq']) {
        excelData[index]['raspberry_cream_bun'] = order.items['3rEf5F09Fq'].amount
        amount = amount + order.items['3rEf5F09Fq'].amount
        if (!eachAmount['3rEf5F09Fq']) {
          eachAmount['3rEf5F09Fq'] = order.items['3rEf5F09Fq'].amount
        } else {
          eachAmount['3rEf5F09Fq'] = eachAmount['3rEf5F09Fq'] + order.items['3rEf5F09Fq'].amount
        }
        totalAmount = totalAmount + order.items['3rEf5F09Fq'].amount
      }
      if (order.items['zfEr2E45f0']) {
        excelData[index]['tart_scone_set'] = order.items['zfEr2E45f0'].amount
        amount = amount + order.items['zfEr2E45f0'].amount
        if (!eachAmount['zfEr2E45f0']) {
          eachAmount['zfEr2E45f0'] = order.items['zfEr2E45f0'].amount
        } else {
          eachAmount['zfEr2E45f0'] = eachAmount['zfEr2E45f0'] + order.items['zfEr2E45f0'].amount
        }
        totalAmount = totalAmount + order.items['zfEr2E45f0'].amount
      }
      if (order.items['JMdm6RMZO']) {
        excelData[index]['plain_scone_set'] = order.items['JMdm6RMZO'].amount
        amount = amount + order.items['JMdm6RMZO'].amount
        if (!eachAmount['JMdm6RMZO']) {
          eachAmount['JMdm6RMZO'] = order.items['JMdm6RMZO'].amount
        } else {
          eachAmount['JMdm6RMZO'] = eachAmount['JMdm6RMZO'] + order.items['JMdm6RMZO'].amount
        }
        totalAmount = totalAmount + order.items['JMdm6RMZO'].amount
      }

      if (order.items['Ag23Df1t6b']) {
        excelData[index]['plain_financier'] = order.items['Ag23Df1t6b'].amount
        amount = amount + order.items['Ag23Df1t6b'].amount
        if (!eachAmount['Ag23Df1t6b']) {
          eachAmount['Ag23Df1t6b'] = order.items['Ag23Df1t6b'].amount
        } else {
          eachAmount['Ag23Df1t6b'] = eachAmount['Ag23Df1t6b'] + order.items['Ag23Df1t6b'].amount
        }
        totalAmount = totalAmount + order.items['Ag23Df1t6b'].amount
      }
      if (order.items['A4edFs23gW']) {
        excelData[index]['salted_choco_financier'] = order.items['A4edFs23gW'].amount
        amount = amount + order.items['A4edFs23gW'].amount
        if (!eachAmount['A4edFs23gW']) {
          eachAmount['A4edFs23gW'] = order.items['A4edFs23gW'].amount
        } else {
          eachAmount['A4edFs23gW'] = eachAmount['A4edFs23gW'] + order.items['A4edFs23gW'].amount
        }
        totalAmount = totalAmount + order.items['A4edFs23gW'].amount
      }
      if (order.items['Adf356daA0']) {
        excelData[index]['garlic_financier'] = order.items['Adf356daA0'].amount
        amount = amount + order.items['Adf356daA0'].amount
        if (!eachAmount['Adf356daA0']) {
          eachAmount['Adf356daA0'] = order.items['Adf356daA0'].amount
        } else {
          eachAmount['Adf356daA0'] = eachAmount['Adf356daA0'] + order.items['Adf356daA0'].amount
        }
        totalAmount = totalAmount + order.items['Adf356daA0'].amount
      }
      if (order.items['A5tSd09e2JH']) {
        excelData[index]['mugwort_crumble_financier'] = order.items['A5tSd09e2JH'].amount
        amount = amount + order.items['A5tSd09e2JH'].amount
        if (!eachAmount['A5tSd09e2JH']) {
          eachAmount['A5tSd09e2JH'] = order.items['A5tSd09e2JH'].amount
        } else {
          eachAmount['A5tSd09e2JH'] = eachAmount['A5tSd09e2JH'] + order.items['A5tSd09e2JH'].amount
        }
        totalAmount = totalAmount + order.items['A5tSd09e2JH'].amount
      }
      if (order.items['A93DsadR3h']) {
        excelData[index]['caramel_pecan_financier'] = order.items['A93DsadR3h'].amount
        amount = amount + order.items['A93DsadR3h'].amount
        if (!eachAmount['A93DsadR3h']) {
          eachAmount['A93DsadR3h'] = order.items['A93DsadR3h'].amount
        } else {
          eachAmount['A93DsadR3h'] = eachAmount['A93DsadR3h'] + order.items['A93DsadR3h'].amount
        }
        totalAmount = totalAmount + order.items['A93DsadR3h'].amount
      }
      if (order.items['z3fj2dF12f']) {
        excelData[index]['chocosongi_egg_scone_set'] = order.items['z3fj2dF12f'].amount
        amount = amount + order.items['z3fj2dF12f'].amount
        if (!eachAmount['z3fj2dF12f']) {
          eachAmount['z3fj2dF12f'] = order.items['z3fj2dF12f'].amount
        } else {
          eachAmount['z3fj2dF12f'] = eachAmount['z3fj2dF12f'] + order.items['z3fj2dF12f'].amount
        }
        totalAmount = totalAmount + order.items['z3fj2dF12f'].amount
      }
      if (order.items['X4wIGoivO']) {
        excelData[index]['yellow_cheese_cookie'] = order.items['X4wIGoivO'].amount
        amount = amount + order.items['X4wIGoivO'].amount
        if (!eachAmount['X4wIGoivO']) {
          eachAmount['X4wIGoivO'] = order.items['X4wIGoivO'].amount
        } else {
          eachAmount['X4wIGoivO'] = eachAmount['X4wIGoivO'] + order.items['X4wIGoivO'].amount
        }
        totalAmount = totalAmount + order.items['X4wIGoivO'].amount
      }

      if (order.items['LmBisFaKZ']) {
        excelData[index]['wina_cookie'] = order.items['LmBisFaKZ'].amount
        amount = amount + order.items['LmBisFaKZ'].amount
        if (!eachAmount['LmBisFaKZ']) {
          eachAmount['LmBisFaKZ'] = order.items['LmBisFaKZ'].amount
        } else {
          eachAmount['LmBisFaKZ'] = eachAmount['LmBisFaKZ'] + order.items['LmBisFaKZ'].amount
        }
        totalAmount = totalAmount + order.items['LmBisFaKZ'].amount
      }
      if (order.items['OD6_3hJKi']) {
        excelData[index]['choco_cookie'] = order.items['OD6_3hJKi'].amount
        amount = amount + order.items['OD6_3hJKi'].amount
        if (!eachAmount['OD6_3hJKi']) {
          eachAmount['OD6_3hJKi'] = order.items['OD6_3hJKi'].amount
        } else {
          eachAmount['OD6_3hJKi'] = eachAmount['OD6_3hJKi'] + order.items['OD6_3hJKi'].amount
        }
        totalAmount = totalAmount + order.items['OD6_3hJKi'].amount
      }
      if (order.giftBoxAmount) {
        excelData[index]['gift_box'] = order.giftBoxAmount
        amount = amount + order.giftBoxAmount
        if (!eachAmount['yUcEOQHrz']) {
          eachAmount['yUcEOQHrz'] = order.giftBoxAmount
        } else {
          eachAmount['yUcEOQHrz'] = eachAmount['yUcEOQHrz'] + order.giftBoxAmount
        }
        totalAmount = totalAmount + order.giftBoxAmount
      }
      if (order.additionalItems) {
        if (order.additionalItems['2E3gXD-i1']) {
          excelData[index]['strawberry_compote'] = order.additionalItems['2E3gXD-i1'].amount
          amount = amount + order.additionalItems['2E3gXD-i1'].amount
          if (!eachAmount['2E3gXD-i1']) {
            eachAmount['2E3gXD-i1'] = order.additionalItems['2E3gXD-i1'].amount
          } else {
            eachAmount['2E3gXD-i1'] = eachAmount['2E3gXD-i1'] + order.additionalItems['2E3gXD-i1'].amount
          }
          totalAmount = totalAmount + order.additionalItems['2E3gXD-i1'].amount
        }
        if (order.additionalItems['eXuir_o5k']) {
          excelData[index]['clotted_cream'] = order.additionalItems['eXuir_o5k'].amount
          amount = amount + order.additionalItems['eXuir_o5k'].amount
          if (!eachAmount['eXuir_o5k']) {
            eachAmount['eXuir_o5k'] = order.additionalItems['eXuir_o5k'].amount
          } else {
            eachAmount['eXuir_o5k'] = eachAmount['eXuir_o5k'] + order.additionalItems['eXuir_o5k'].amount
          }
          totalAmount = totalAmount + order.additionalItems['eXuir_o5k'].amount
        }
        if (order.additionalItems['2roIAqZkqi']) {
          excelData[index]['pistachio_clotted_cream'] = order.additionalItems['2roIAqZkqi'].amount
          amount = amount + order.additionalItems['2roIAqZkqi'].amount
          if (!eachAmount['2roIAqZkqi']) {
            eachAmount['2roIAqZkqi'] = order.additionalItems['2roIAqZkqi'].amount
          } else {
            eachAmount['2roIAqZkqi'] = eachAmount['2roIAqZkqi'] + order.additionalItems['2roIAqZkqi'].amount
          }
          totalAmount = totalAmount + order.additionalItems['2roIAqZkqi'].amount
        }
        if (order.additionalItems['hXo2RQU_w']) {
          excelData[index]['assam_milkteabase'] = order.additionalItems['hXo2RQU_w'].amount
          amount = amount + order.additionalItems['hXo2RQU_w'].amount
          if (!eachAmount['hXo2RQU_w']) {
            eachAmount['hXo2RQU_w'] = order.additionalItems['hXo2RQU_w'].amount
          } else {
            eachAmount['hXo2RQU_w'] = eachAmount['hXo2RQU_w'] + order.additionalItems['hXo2RQU_w'].amount
          }
          totalAmount = totalAmount + order.additionalItems['hXo2RQU_w'].amount
        }
        if (order.additionalItems['ACkU0pIH9']) {
          excelData[index]['scone_mix'] = order.additionalItems['ACkU0pIH9'].amount
          amount = amount + order.additionalItems['ACkU0pIH9'].amount
          if (!eachAmount['ACkU0pIH9']) {
            eachAmount['ACkU0pIH9'] = order.additionalItems['ACkU0pIH9'].amount
          } else {
            eachAmount['ACkU0pIH9'] = eachAmount['ACkU0pIH9'] + order.additionalItems['ACkU0pIH9'].amount
          }
          totalAmount = totalAmount + order.additionalItems['ACkU0pIH9'].amount
        }
        if (order.additionalItems['BgzeeCnZd2']) {
          excelData[index]['wina_mug_cup_green_330ml'] = order.additionalItems['BgzeeCnZd2'].amount
          amount = amount + order.additionalItems['BgzeeCnZd2'].amount
          if (!eachAmount['BgzeeCnZd2']) {
            eachAmount['BgzeeCnZd2'] = order.additionalItems['BgzeeCnZd2'].amount
          } else {
            eachAmount['BgzeeCnZd2'] = eachAmount['BgzeeCnZd2'] + order.additionalItems['BgzeeCnZd2'].amount
          }
          totalAmount = totalAmount + order.additionalItems['BgzeeCnZd2'].amount
        }
        if (order.additionalItems['BkMuBT9_rM']) {
          excelData[index]['wina_mug_cup_orange_330ml'] = order.additionalItems['BkMuBT9_rM'].amount
          amount = amount + order.additionalItems['BkMuBT9_rM'].amount
          if (!eachAmount['BkMuBT9_rM']) {
            eachAmount['BkMuBT9_rM'] = order.additionalItems['BkMuBT9_rM'].amount
          } else {
            eachAmount['BkMuBT9_rM'] = eachAmount['BkMuBT9_rM'] + order.additionalItems['BkMuBT9_rM'].amount
          }
          totalAmount = totalAmount + order.additionalItems['BkMuBT9_rM'].amount
        }
        if (order.additionalItems['DSZYSKZqr']) {
          excelData[index]['wina_mug_cup_green_200ml'] = order.additionalItems['DSZYSKZqr'].amount
          amount = amount + order.additionalItems['DSZYSKZqr'].amount
          if (!eachAmount['DSZYSKZqr']) {
            eachAmount['DSZYSKZqr'] = order.additionalItems['DSZYSKZqr'].amount
          } else {
            eachAmount['DSZYSKZqr'] = eachAmount['DSZYSKZqr'] + order.additionalItems['DSZYSKZqr'].amount
          }
          totalAmount = totalAmount + order.additionalItems['DSZYSKZqr'].amount
        }
        if (order.additionalItems['D0vZqaBEdW']) {
          excelData[index]['wina_mug_cup_orange_200ml'] = order.additionalItems['D0vZqaBEdW'].amount
          amount = amount + order.additionalItems['D0vZqaBEdW'].amount
          if (!eachAmount['D0vZqaBEdW']) {
            eachAmount['D0vZqaBEdW'] = order.additionalItems['D0vZqaBEdW'].amount
          } else {
            eachAmount['D0vZqaBEdW'] = eachAmount['D0vZqaBEdW'] + order.additionalItems['D0vZqaBEdW'].amount
          }
          totalAmount = totalAmount + order.additionalItems['D0vZqaBEdW'].amount
        }
        if (order.additionalItems['EXXCAMXKn']) {
          excelData[index]['wina_wood_tea_coaster'] = order.additionalItems['EXXCAMXKn'].amount
          amount = amount + order.additionalItems['EXXCAMXKn'].amount
          if (!eachAmount['EXXCAMXKn']) {
            eachAmount['EXXCAMXKn'] = order.additionalItems['EXXCAMXKn'].amount
          } else {
            eachAmount['EXXCAMXKn'] = eachAmount['EXXCAMXKn'] + order.additionalItems['EXXCAMXKn'].amount
          }
          totalAmount = totalAmount + order.additionalItems['EXXCAMXKn'].amount
        }
      }


      if (order.memo) {
        excelData[index]['memo'] = order.memo
      }
      if (order.deliveryMessage) {
        excelData[index]['delivery_message'] = order.deliveryMessage
      }
      excelData[index]['total_amount'] = amount
      if (Object.values(orders).length === index + 1) {
        excelData[index + 1] = {
          number: '', buyerName: '', receiver: '총 수량', phoneNumber: '', address1: '', address2: ''
        }
        excelData[index + 1]['plain_scone'] = eachAmount['ENXex55N4']
        excelData[index + 1]['crumble_scone'] = eachAmount['SW8g84Fi7']
        excelData[index + 1]['cinnamon_scone'] = eachAmount['JY2XRab1j']
        excelData[index + 1]['lemon_scone'] = eachAmount['EgE29AfJ98']
        excelData[index + 1]['cinamon_roll'] = eachAmount['1Dyy5MmBuz2']
        excelData[index + 1]['choco_roll'] = eachAmount['1AxrE_CQ1N']
        excelData[index + 1]['caramel_pecan_roll'] = eachAmount['1Dzd38Df90']
        excelData[index + 1]['maple_pecan_scone'] = eachAmount['9svZrVSuC']
        excelData[index + 1]['egg_scone'] = eachAmount['NYkkc1ryd']
        excelData[index + 1]['raspberry_egg_scone'] = eachAmount['N31Dqd40Fe']
        excelData[index + 1]['choco_egg_scone'] = eachAmount['1r2QwejR40']
        excelData[index + 1]['apple_cinnamon_egg_scone'] = eachAmount['Nd345dFq20']
        excelData[index + 1]['blueberry_egg_scone'] = eachAmount['N4dDse24Sa']
        excelData[index + 1]['corn_scone'] = eachAmount['Po-QQGXmU']
        excelData[index + 1]['nunettine_scone'] = eachAmount['A8MaVd-JU']
        excelData[index + 1]['raspberry_cream_bun'] = eachAmount['3FE3Qdf9ab']
        excelData[index + 1]['corn_cream_bun'] = eachAmount['3rEf5F09Fq']
        excelData[index + 1]['tart_scone_set'] = eachAmount['zfEr2E45f0']
        excelData[index + 1]['plain_scone_set'] = eachAmount['JMdm6RMZO']

        excelData[index + 1]['plain_financier'] = eachAmount['Ag23Df1t6b']
        excelData[index + 1]['salted_choco_financier'] = eachAmount['A4edFs23gW']
        excelData[index + 1]['garlic_financier'] = eachAmount['Adf356daA0']
        excelData[index + 1]['mugwort_crumble_financier'] = eachAmount['A5tSd09e2JH']
        excelData[index + 1]['caramel_pecan_financier'] = eachAmount['A93DsadR3h']

        excelData[index + 1]['chocosongi_egg_scone_set'] = eachAmount['z3fj2dF12f']
        excelData[index + 1]['yellow_cheese_cookie'] = eachAmount['X4wIGoivO']
        excelData[index + 1]['wina_cookie'] = eachAmount['LmBisFaKZ']
        excelData[index + 1]['choco_cookie'] = eachAmount['OD6_3hJKi']
        excelData[index + 1]['total_amount'] = totalAmount
        excelData[index + 1]['gift_box'] = eachAmount['yUcEOQHrz']
        excelData[index + 1]['strawberry_compote'] = eachAmount['2E3gXD-i1']
        excelData[index + 1]['clotted_cream'] = eachAmount['eXuir_o5k']
        excelData[index + 1]['pistachio_clotted_cream'] = eachAmount['2roIAqZkqi']
        excelData[index + 1]['assam_milkteabase'] = eachAmount['hXo2RQU_w']
        excelData[index + 1]['scone_mix'] = eachAmount['ACkU0pIH9']
        excelData[index + 1]['wina_mug_cup_green_330ml'] = eachAmount['BgzeeCnZd2']
        excelData[index + 1]['wina_mug_cup_orange_330ml'] = eachAmount['BkMuBT9_rM']
        excelData[index + 1]['wina_mug_cup_green_200ml'] = eachAmount['DSZYSKZqr']
        excelData[index + 1]['wina_mug_cup_orange_200ml'] = eachAmount['D0vZqaBEdW']
        excelData[index + 1]['wina_wood_tea_coaster'] = eachAmount['EXXCAMXKn']
        this.setState({excelData: excelData})
      }
    })

  }

  onSendPaymentMessage() {

  }

  onSendingReviewMessage() {
    let orders = this.state.orderSheets[this.state.openedDeliveryDate].orders
    let i = 0
    firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/sentReviewAlarmMessage`).once('value', (snapshot) => {
      if (!snapshot.val()) {
        Object.values(orders).map((order, index) => {
          if (order.hasDelivered) {
            i = i + 1
            if (!this.state.notSelectedChecked[order.orderId]) {
              axios({
                url: `https://sendsconedeliveryreviewmessage-v2-xv3aig33rq-uc.a.run.app`,
                method: "post",
                headers: {
                  "Content-Type":"application/json"
                },
                data: {
                  phoneNumber: order['addressTo'].phoneNumber,
                  username: order['addressTo'].receiver
                }
              })
            }
          } else {
            i = i + 1
          }
          if (Object.values(orders).length === index + 1) {
            alert(`${i}명 알림 전송 완료`)
          }
        })
        firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/sentReviewAlarmMessage`).set(true)
      }
    })





  }

  async onSendExpieredCouponMessage() {
    const today = Number(moment(Date.now()).format('YYYYMMDD').toString());
    await Object.values(this.state.users).map(async(user, index) => {
      if (user.coupons) {
        let closestExpiryDate = null;
        let closestExpiryCoupon = null;
        let couponsLength = Object.keys(user.coupons).length;

        await Object.values(user.coupons).map(async (coupon, index) => {
          // 쿠폰이 사용되었는지 여부를 확인합니다.
          if (!coupon.usedAt) {
            const expiryDate = Number(coupon.expireDate)
          
            // 만료일이 오늘 이전이고, 유효기간이 가장 짧은 쿠폰을 찾습니다.

            if (expiryDate >= today && (closestExpiryDate === null || expiryDate < closestExpiryDate)) {
              closestExpiryDate = expiryDate;
              closestExpiryCoupon = coupon;
            }
          }

          if (index + 1 === couponsLength) {
            if (closestExpiryCoupon) {
            if (closestExpiryCoupon.eventName) {
              if (Number(closestExpiryCoupon.expireDate) < Number(today) + 5) {
                console.log(user.uid + ': ' + closestExpiryCoupon.eventName + '-' + this.onDateNumberToDate(closestExpiryCoupon.expireDate) + '/' + closestExpiryCoupon.amount + '원')
              }
              
            } else {
              if (Number(closestExpiryCoupon.expireDate) < Number(today) + 5) {
                console.log(user.uid + ': ' + '고객 감사' + '-' + this.onDateNumberToDate(closestExpiryCoupon.expireDate) + '/' + closestExpiryCoupon.amount + '원')
              }
              
            }
            }
          }
        });
        
        
        
      }
    })
    this.setState({isSendingExpiredDateMessage: false})
  }

  onSendExpiringOnlineCoupon(couponTo, eventName, index) {

    let usersDataArray =  Object.values(this.state.users)

    usersDataArray = usersDataArray.filter(usersDataArray => usersDataArray.phoneNumber === couponTo)

    if (usersDataArray.length !== 0) {
      axios({
        url: `https://sendexpiringonlinecouponmessage-v2-xv3aig33rq-uc.a.run.app`,
        method: "post",
        headers: {
          "Content-Type":"application/json"
        },
        data: {
          couponTo: couponTo.toString(),
          username: usersDataArray[0].username,
          couponAmount: `${this.onChangeCurrencyFormat(Number(this.state.onlineCouponAmount))}원`,
          eventName: eventName
        }
      }).then((data) => {
        console.log(data.data)
      }).catch((e) => {
        console.log(e.message)
      })
      if (index + 1 === this.state.onlineCouponToArray.length) {
        this.setState({completeIssueOnlineCouponSnack: true, onlineCouponTo: '', onlineCouponToArray: [], onlineCouponAmount: 1000, isIssuingOnlineCoupons: false, onlineCouponExpireDate: 90})
      }
    }

    
  }

  onIssueOnlineCoupon(couponId, couponTo, index, category, expireDate, name, eventName, type) {
    let couponInfo = {}
    if (category === 'scone') {
      if (this.state.onlineCouponMinimumAmount) {
        couponInfo = {
          couponId: couponId,
          issuedAt: Date.now(),
          category: category,
          expireDate: Number(expireDate),
          name: name,
          eventName: eventName,
          type: type,
          phoneNumber: couponTo.toString(),
          amount: Number(this.state.onlineCouponAmount),
          minPrice: Number(this.state.onlineCouponMinimumAmount)
        }
      } else {
        couponInfo = {
          couponId: couponId,
          issuedAt: Date.now(),
          category: category,
          expireDate: Number(expireDate),
          name: name,
          eventName: eventName,
          type: type,
          phoneNumber: couponTo.toString(),
          amount: Number(this.state.onlineCouponAmount)
        }
      }
      
    }

    firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
    .then(() => {
      axios({
        url: `https://sendonlinecouponmessage-v2-xv3aig33rq-uc.a.run.app`,
        method: "post",
        headers: {
          "Content-Type":"application/json"
        },
        data: {
          couponAmount: `${this.onChangeCurrencyFormat(Number(this.state.onlineCouponAmount))}원`,
          couponTo: couponTo.toString(),
          name: eventName ? eventName + ' ' + name : name
        }
      }).then((data) => {
        console.log(data.data)
      }).catch((e) => {
        console.log(e.message)
      })
      if (index + 1 === this.state.onlineCouponToArray.length) {
        this.setState({completeIssueOnlineCouponSnack: true, onlineCouponTo: '', onlineCouponToArray: [], onlineCouponAmount: 1000, isIssuingOnlineCoupons: false, onlineCouponExpireDate: 90})
      }
    })
  }

  onSendMessage(messageTo, index) {
    axios({
      url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendSMSMessage`,
      method: "post",
      headers: {
        "Content-Type":"application/json"
      },
      data: {
        content: this.state.messageContent,
        messageTo: messageTo.toString()
      }
    })
    if (index + 1 === this.state.messageToArray.length) {
      this.setState({completeSendMessageSnack: true, messageTo: '', messageToArray: [], isSendingMessage: false})
    }
  }

  onIssueCoupon(couponId, couponTo, index) {
    let couponInfo = {
      couponId: couponId,
      issuedAt: Date.now() - (24 * 1000 * 60 * 60 * (180 - Number(this.state.offlineCouponExpireDate))),
      phoneNumber: couponTo.toString(),
      amount: Number(this.state.couponAmount)
    }
    firebase.database().ref(`coupons/${couponId}`).set(couponInfo)
    .then(() => {
      axios({
        url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendCouponMessage`,
        method: "post",
        headers: {
          "Content-Type":"application/json"
        },
        data: {
          couponAmount: `${this.onChangeCurrencyFormat(this.state.couponAmount)}원`,
          couponTo: couponTo.toString(),
          link: 'https://withanod.com/coupon/' + couponId
        }
      })
      if (index + 1 === this.state.couponToArray.length) {
        this.setState({completeIssueCouponSnack: true, couponTo: '', couponToArray: [], couponAmount: 1000, isIssuingCoupons: false})
      }
    })
  }

  onGetTotalAmount(items) {
    let result = Object.values(items).reduce((a, b) => a + b.amount, 0)
    return result
  }

  async onCancelDelivery(userInfo, deliveryData) {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')

    firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${deliveryData.orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        if (snapshot.val().paidPrice === 0) {
          firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}/additionalItems`).once('value', (snapshot) => {
            if (snapshot.val()) {
              Object.values(snapshot.val()).map((item, index) => {
                firebase.database().ref(`items/${item.itemId}`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    firebase.database().ref(`items/${item.itemId}/amount`).set(snapshot.val()['amount'] + item.amount)
                  }
                })
              })
            }
          })

          firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}/items`).once('value', (snapshot) => {
            if (snapshot.val()) {
              let purchaseItems = Object.values(snapshot.val())
              purchaseItems.map((item, index) => {
                firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/menu/${item.itemId}/amount`).once('value', (snapshot) => {
                  if (typeof(snapshot.val()) === 'number') {
                    let remainAmount = snapshot.val()
                    firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/menu/${item.itemId}/amount`).set(remainAmount + item.amount)
                  }
                })
                if (purchaseItems.length === index + 1) {
                  firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}`).remove()
                  firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${deliveryData.orderId}/usingCoupon`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let coupon = snapshot.val()
                      delete coupon['usedAt']
                      firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).set(coupon)
                      firebase.database().ref(`onlineCoupons/${coupon.couponId}`).set(coupon)
                    }
                  }).then(() => {
                    firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${deliveryData.orderId}`).remove()
                  })
                  this.setState({isDeliveryCanceling: false, completeDeliveryCancelSnack: true})
                }
              })
            }
          })
        } else {
          firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}`).once('value', (snapshot) => {
            if (snapshot.val()) {
              axios({
                url: `https://api.tosspayments.com/v1/payments/${deliveryData.paymentKey}/cancel`,
                method: "post",
                headers: {
                  "Content-Type":"application/json",
                  "Authorization": "Basic " + encodingAuth
                },
                data: {
                  cancelReason: '고객이 취소를 원함'
                }
              }).then((data) => {
                firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}/additionalItems`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    Object.values(snapshot.val()).map((item, index) => {
                      firebase.database().ref(`items/${item.itemId}`).once('value', (snapshot) => {
                        if (snapshot.val()) {
                          firebase.database().ref(`items/${item.itemId}/amount`).set(snapshot.val()['amount'] + item.amount)
                        }
                      })
                    })
                  }
                })

                firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}/items`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    let purchaseItems = Object.values(snapshot.val())
                    purchaseItems.map((item, index) => {
                      firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/menu/${item.itemId}/amount`).once('value', (snapshot) => {
                        if (typeof(snapshot.val()) === 'number') {
                          let remainAmount = snapshot.val()
                          firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/menu/${item.itemId}/amount`).set(remainAmount + item.amount)
                        }
                      })
                      if (purchaseItems.length === index + 1) {
                        firebase.database().ref(`orderSheets/${deliveryData.orderSheet.toString()}/orders/${deliveryData.orderId}`).remove()
                        firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${deliveryData.orderId}/usingCoupon`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            let coupon = snapshot.val()
                            delete coupon['usedAt']
                            firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).set(coupon)
                            firebase.database().ref(`onlineCoupons/${coupon.couponId}`).set(coupon)
                          }
                        }).then(() => {
                          firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${deliveryData.orderId}`).remove()
                        })
                        this.setState({isDeliveryCanceling: false, completeDeliveryCancelSnack: true})
                      }
                    })
                  }
                })
              })
            }
          })
        }

      }
    })


  }

  async onGetUserClass(orders, itemOrders, uid) {
    let ordersList = []
    if (orders) {
      ordersList = Object.values(orders)
    }
    let itemOrdersList = []
    if (itemOrders) {
      itemOrdersList = Object.values(itemOrders)
    }
    let userClassDiscountRate = 1
    let totalAmount = 0
    let hasPurchasedLastMonthAmount = 0
    let userClass = 0
    let lastMonthUserClass = 0
    let userInfos = this.state.userInfos


    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    if (ordersList.length > 0) {
      await ordersList.map(async(order, index) => {

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((order['orderSheet']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((order['orderSheet']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + order.paidPrice
            }
          }

        }

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 1 <= Number((order['orderSheet']).toString().slice(4, 6)))) {
              hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
            }
          }

        }

      })
    }

    itemOrdersList = itemOrdersList.filter(itemOrdersList => itemOrdersList.paid === true)
    if (itemOrdersList.length > 0) {
      await itemOrdersList.map(async(itemOrder, index) => {
        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + itemOrder.paidPrice
            }
          }

        }

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 1 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
            }
          }

        }

      })
    }




    if (totalAmount >= 50000) {
      userClass = 1
      userClassDiscountRate = 0.97
    }
    if (totalAmount >= 100000) {
      userClass = 2
      userClassDiscountRate = 0.95
    }
    if (totalAmount >= 200000) {
      userClass = 3
      userClassDiscountRate = 0.90
    }

    if (hasPurchasedLastMonthAmount >= 50000) {
      lastMonthUserClass = 1
    }
    if (hasPurchasedLastMonthAmount >= 100000) {
      lastMonthUserClass = 2
    }
    if (hasPurchasedLastMonthAmount >= 200000) {
      lastMonthUserClass = 3
    }

    return userClass

  }

  async onCancelPickup(userInfo, pickupData) {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/pickups/${pickupData.pickupId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        axios({
          url: `https://api.tosspayments.com/v1/payments/${pickupData.paymentKey}/cancel`,
          method: "post",
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Basic " + encodingAuth
          },
          data: {
            cancelReason: '고객이 취소를 원함'
          }
        }).then((data) => {
          firebase.database().ref(`users/${userInfo.uid}/sconePickups/${pickupData.pickupId}`).remove()
          firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/pickups/${pickupData.pickupId}`).remove()
          firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).once('value', (snapshot) => {
            firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).set(snapshot.val() + pickupData.totalAmount)
          })
          this.setState({isPickupCanceling: false, completePickupCancelSnack: true})
        })
      }
    })
  }

  onCountTotalOpenDelivery(deliveryMenu) {
    let result = Object.values(deliveryMenu).reduce((a, b) => a + b.amount, 0)
    return result
  }

  async onCancelPayment() {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    this.setState({showCancelPaymenteModal: false})
    axios({
      url: `https://api.tosspayments.com/v1/payments/orders/${this.state.tossPaymentsOrderId}`,
      method: "get",
      headers: {
        "Authorization": "Basic " + encodingAuth
      }
    }).then((data) => {
      if (data.data) {
        axios({
          url: `https://api.tosspayments.com/v1/payments/${data.data.paymentKey}/cancel`,
          method: "post",
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Basic " + encodingAuth
          },
          data: {
            cancelReason: '고객이 취소를 원함'
          }
        }).then((data) => {
          this.setState({completeCancelPaymentSnack: true})
        })
      }
    })
  }

  async onCancelPaymentPartially() {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    this.setState({showParttiallyCancelPaymenteModal: false})
    axios({
      url: `https://api.tosspayments.com/v1/payments/orders/${this.state.tossPaymentsOrderId}`,
      method: "get",
      headers: {
        "Authorization": "Basic " + encodingAuth
      }
    }).then((data) => {
      if (data.data) {
        axios({
          url: `https://api.tosspayments.com/v1/payments/${data.data.paymentKey}/cancel`,
          method: "post",
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Basic " + encodingAuth
          },
          data: {
            cancelReason: '고객이 취소를 원함',
            cancelAmount: this.state.partiallyCancelAmount
          }
        }).then((data) => {
          this.setState({completePartialllyCancelPaymentSnack: true})
        })
      }
    })
  }

  onGetPurchasedOrders(orders) {
    if (!orders) {
      return 0
    }
    let ordersList = Object.values(orders)
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    ordersList = ordersList.sort((a, b) => {
      return b.purchasedAt - a.purchasedAt
    })
    return ordersList
  }

  onGetNumberOfPurchase(orders) {
    if (!orders) {
      return 0
    }
    let ordersList = Object.values(orders)
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    return ordersList.length
  }

  onGetFirstPurchaseDate(orders) {
    if (!orders) {
      return 0
    }
    let ordersList = Object.values(orders)
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    return Date.now() - ordersList[0]['paidAt']
  }

  async onGetAmountOffPurchase(orders, uid) {
    let ordersList = Object.values(orders)
    let overFiveUsers = this.state.overFiveUsers
    let totalAmount = 0
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    await ordersList.map(async(order, index) => {
      totalAmount = totalAmount + order.paidPrice
    })
    if (totalAmount > 500000) {
      overFiveUsers[uid] = this.state.users[uid]
      this.setState({overFiveUsers: overFiveUsers})
    }
  }

  onLogout() {
    if (localStorage.getItem('kakao_login_uid')) {
      localStorage.removeItem('kakao_login_uid')
      firebase.auth().signOut()
      Kakao.Auth.logout()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    } else if (localStorage.getItem('naver_login_uid')) {
      localStorage.removeItem('naver_login_uid')
      firebase.auth().signOut()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    } else {
      firebase.auth().signOut()
      setTimeout(() => {
        window.location.reload()
      }, 10)
    }
  }

  onRemoveAdditionalItem(item) {
    if (this.onDateToTimeStamp(this.state.onSitePickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {

    } else {
      let selectedAdditiionalItems = this.state.selectedAdditiionalItems
      let additionalItemsPrice = this.state.additionalItemsPrice
      let additionalItemsAmount = this.state.additionalItemsAmount

      if (selectedAdditiionalItems[item.id]) {
        if (selectedAdditiionalItems[item.id].amount === 1) {
          delete selectedAdditiionalItems[item.id]
          if (item.discountedPrice) {
            additionalItemsPrice = additionalItemsPrice - item.discountedPrice
          } else {
            additionalItemsPrice = additionalItemsPrice - item.price
          }

          additionalItemsAmount = additionalItemsAmount - 1
        } else {
          selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount - 1
          if (item.discountedPrice) {
            additionalItemsPrice = additionalItemsPrice - item.discountedPrice
          } else {
            additionalItemsPrice = additionalItemsPrice - item.price
          }

          additionalItemsAmount = additionalItemsAmount - 1
        }
      }


      this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
    }
  }

  onAddAdditionalItem(item) {
    if (this.onDateToTimeStamp(this.state.onSitePickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {

      this.setState({overTimeItemsSnack: false}, () => {
        setTimeout(() => {
          this.setState({overTimeItemsSnack: true})
        }, 10)
      })
    } else {
      let selectedAdditiionalItems = this.state.selectedAdditiionalItems
      let additionalItemsPrice = this.state.additionalItemsPrice
      let additionalItemsAmount = this.state.additionalItemsAmount
      let additionalPickups = this.state.additionalPickups


      if (!selectedAdditiionalItems[item.id]) {
        if (this.state.onSitePickupDate.additionalPickups[item.id].amount === 0) {
          this.setState({noMoreItemsSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreItemsSnack: true})
            }, 10)
          })
        } else {
          if (selectedAdditiionalItems[item.id]) {
            selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount + 1
            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          } else {
            if (item.discountedPrice) {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.discountedPrice
              }
            } else {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.price
              }
            }

            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }


            additionalItemsAmount = additionalItemsAmount + 1
          }


          this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
        }
      } else {
        if (this.state.onSitePickupDate.additionalPickups[item.id].amount <= selectedAdditiionalItems[item.id].amount) {
          this.setState({noMoreItemsSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreItemsSnack: true})
            }, 10)
          })
        } else {
          if (selectedAdditiionalItems[item.id]) {
            selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount + 1
            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          } else {
            if (item.discountedPrice) {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.discountedPrice
              }
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.price
              }
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          }


          this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
        }
      }
    }
  }

  onRemoveScone(scone) {
    let selectedPickupScones = this.state.selectedPickupScones
    let sconeMenu = this.state.sconeMenu
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice

    if (selectedPickupScones[scone.id]) {
      if (totalAmount && selectedPickupScones[scone.id].amount !== 0) {
        totalAmount = totalAmount - 1
        totalPrice = totalPrice - scone.price
      }
      selectedPickupScones[scone.id] = {
        itemId: scone.id,
        name: sconeMenu[scone.id].name,
        image: sconeMenu[scone.id].image,
        amount: selectedPickupScones[scone.id].amount - 1,
        price: scone.price
      }
      if (selectedPickupScones[scone.id].amount === 0) {
        delete selectedPickupScones[scone.id]
      }
    }
    if (totalAmount < 1) {
      this.setState({showPickupScones: false})
    }
    this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
  }

  onAddScone(scone, item) {
    let selectedPickupScones = this.state.selectedPickupScones
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice
    let sconeMenu = this.state.sconeMenu

    if (item.amount || item.amount === 0) {
      if (item.amount !== 0) {
        if (selectedPickupScones[scone.id]) {
          if (item.amount > selectedPickupScones[scone.id].amount) {
            if (item.limit) {
              if (item.limit > selectedPickupScones[scone.id].amount) {
                if (this.state.onSitePickupDate.amount > totalAmount) {
                  selectedPickupScones[scone.id] = {
                    itemId: scone.id,
                    name: sconeMenu[scone.id].name,
                    image: sconeMenu[scone.id].image,
                    amount: selectedPickupScones[scone.id].amount + 1,
                    price: scone.price
                  }
                  if (totalAmount) {
                    totalAmount = totalAmount + 1
                    totalPrice = totalPrice + scone.price
                  } else {
                    totalAmount = 1
                    totalPrice = scone.price + (this.state.giftBox * 1000)
                  }

                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
                } else {
                  this.setState({noMoreSconesSnack: false}, () => {
                    setTimeout(() => {
                      this.setState({noMoreSconesSnack: true})
                    }, 10)
                  })
                }

              }
            } else {
              if (this.state.onSitePickupDate.amount > totalAmount) {
                selectedPickupScones[scone.id] = {
                  itemId: scone.id,
                  name: sconeMenu[scone.id].name,
                  image: sconeMenu[scone.id].image,
                  amount: selectedPickupScones[scone.id].amount + 1,
                  price: scone.price
                }
                if (totalAmount) {
                  totalAmount = totalAmount + 1
                  totalPrice = totalPrice + scone.price
                } else {
                  totalAmount = 1
                  totalPrice = scone.price + (this.state.giftBox * 1000)
                }

                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
              } else {
                this.setState({noMoreSconesSnack: false}, () => {
                  setTimeout(() => {
                    this.setState({noMoreSconesSnack: true})
                  }, 10)
                })
              }
            }
          } else {
            this.setState({noMoreItemsSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreItemsSnack: true})
              }, 10)
            })
          }
        } else {
          if (this.state.onSitePickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              totalPrice = scone.price + (this.state.giftBox * 1000)
            }
            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        this.setState({noMoreItemsSnack: false}, () => {
          setTimeout(() => {
            this.setState({noMoreItemsSnack: true})
          }, 10)
        })
      }
    } else {
      if (selectedPickupScones[scone.id]) {
        if (item.limit) {
          if (item.limit > selectedPickupScones[scone.id].amount) {
            if (this.state.onSitePickupDate.amount > totalAmount) {
              selectedPickupScones[scone.id] = {
                itemId: scone.id,
                name: sconeMenu[scone.id].name,
                image: sconeMenu[scone.id].image,
                amount: selectedPickupScones[scone.id].amount + 1,
                price: scone.price
              }
              if (totalAmount) {
                totalAmount = totalAmount + 1
                totalPrice = totalPrice + scone.price
              } else {
                totalAmount = 1
                totalPrice = scone.price + (this.state.giftBox * 1000)
              }

              this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
            } else {
              this.setState({noMoreSconesSnack: false}, () => {
                setTimeout(() => {
                  this.setState({noMoreSconesSnack: true})
                }, 10)
              })
            }

          }
        } else {
          if (this.state.onSitePickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: selectedPickupScones[scone.id].amount + 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              totalPrice = scone.price + (this.state.giftBox * 1000)
            }

            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        if (this.state.onSitePickupDate.amount > totalAmount) {
          selectedPickupScones[scone.id] = {
            itemId: scone.id,
            name: sconeMenu[scone.id].name,
            image: sconeMenu[scone.id].image,
            amount: 1,
            price: scone.price
          }
          if (totalAmount) {
            totalAmount = totalAmount + 1
            totalPrice = totalPrice + scone.price
          } else {
            totalAmount = 1
            totalPrice = scone.price + (this.state.giftBox * 1000)
          }
          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
        } else {
          this.setState({noMoreSconesSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreSconesSnack: true})
            }, 10)
          })
        }

      }
    }
  }


  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      })
    }
    let hourOption = []
    let minuteOption = []
    if (this.state.onSitePickupDate) {
      if (this.onGetDay(this.state.onSitePickupDate['date']) === '토' || this.onGetDay(this.state.onSitePickupDate['date']) === '일') {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' }
        ]

        if (this.state.hour === '5') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      } else {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
          { value: '6', label: '6시' }
        ]
        if (this.state.hour === '6') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      }
    }
    let headers = [
      { label: "No.", key: "number" },
      { label: "주문자", key: "buyerName" },
      { label: "수령자", key: "receiver" },
      { label: "연락처", key: "phoneNumber" },
      { label: "주소지1", key: "address1" },
      { label: "주소지2", key: "address2" },
      { label: "플레인", key: "plain_scone" },
      { label: "크럼블", key: "crumble_scone" },
      { label: "시나몬 스콘", key: "cinnamon_scone" },
      { label: "레몬 스콘", key: "lemon_scone" },
      { label: "시나몬 롤", key: "cinamon_roll" },
      { label: "초코 롤", key: "choco_roll" },
      { label: "카라멜피칸 롤", key: "caramel_pecan_roll" },
      { label: "메이플 피칸", key: "maple_pecan_scone" },
      { label: "에그스콘", key: "egg_scone" },
      { label: "라즈베리에그스콘", key: "raspberry_egg_scone" },
      { label: "초코송이에그스콘", key: "choco_egg_scone" },
      { label: "애플시나몬에그스콘", key: "apple_cinnamon_egg_scone" },
      { label: "블루베리에그스콘", key: "blueberry_egg_scone" },
      { label: "콘스콘", key: "corn_scone" },
      { label: "누네띠네 스콘", key: "nunettine_scone" },
      { label: "라즈베리 크림번", key: "raspberry_cream_bun" },
      { label: "4구 타르트 스콘 세트", key: "tart_scone_set" },
      { label: "플레인 스콘 세트", key: "plain_scone_set" },
      
        { label: "플레인 휘낭시에", key: "plain_financier" },
        { label: "솔티드 초코 휘낭시에", key: "salted_choco_financier" },
        { label: "갈릭 휘낭시에", key: "garlic_financier" },
        { label: "쑥크럼블 휘낭시에", key: "mugwort_crumble_financier" },
        { label: "카라멜피칸 휘낭시에", key: "caramel_pecan_financier" },


      { label: "초코송이 스콘 세트", key: "chocosongi_egg_scone_set" },
      { label: "황치즈 쿠키", key: "yellow_cheese_cookie" },
      { label: "위나 쿠키", key: "wina_cookie" },
      { label: "초코마약 쿠키", key: "choco_cookie" },
      { label: "총 수량", key: "total_amount" },
      { label: "디저트 박스", key: "gift_box" },
      { label: "딸기 콩포트", key: "strawberry_compote" },
      { label: "클로티드 크림", key: "clotted_cream" },
      { label: "피스타치오 클로티드 크림", key: "pistachio_clotted_cream" },
      { label: "아쌈", key: "assam_milkteabase" },
      { label: "스콘믹스", key: "scone_mix" },
      { label: "머그컵330ml(그린)", key: "wina_mug_cup_green_330ml" },
      { label: "머그컵330ml(오렌지)", key: "wina_mug_cup_orange_330ml" },
      { label: "머그컵200ml(그린)", key: "wina_mug_cup_green_200ml" },
      { label: "머그컵200ml(오렌지)", key: "wina_mug_cup_orange_200ml" },
      { label: "우드티코스터", key: "wina_wood_tea_coaster" },
      { label: "배송메세지", key: "delivery_message" },
      { label: "요청사항", key: "memo" }
    ];

    let itemHeaders = [
      { label: "No.", key: "number" },
      { label: "주문자", key: "buyerName" },
      { label: "수령자", key: "receiver" },
      { label: "연락처", key: "phoneNumber" },
      { label: "주소지1", key: "address1" },
      { label: "주소지2", key: "address2" },
      { label: "딸기 콩포트", key: "strawberry_compote" },
      { label: "클로티드 크림", key: "clotted_cream" },
      { label: "피스타치오 클로티드 크림", key: "pistachio_clotted_cream" },
      { label: "아쌈", key: "assam_milkteabase" },
      { label: "스콘믹스", key: "scone_mix" },
      { label: "머그컵330ml(그린)", key: "wina_mug_cup_green_330ml" },
      { label: "머그컵330ml(오렌지)", key: "wina_mug_cup_orange_330ml" },
      { label: "머그컵200ml(그린)", key: "wina_mug_cup_green_200ml" },
      { label: "머그컵200ml(오렌지)", key: "wina_mug_cup_orange_200ml" },
      { label: "우드티코스터", key: "wina_wood_tea_coaster" },
      { label: "총 수량", key: "total_amount" },
      { label: "요청사항", key: "memo" }
    ];



    if (this.state.isLoading) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
          <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        </div>
      )
    }
    if (this.state.userInfo.uid !== 'IbiO5VCmt9e9fURCQEwhMTA8xnJ3') {
      return (
        <Grid container justify="center" className="auto-width-100 direction-column-div align-center" style={{paddingTop: 50}}>
          <span style={{fontWeight: 'bold'}}>관리자 계정으로 로그인 해주시기 바랍니다.</span>
          <span onClick={() => {
            this.onLogout()
          }} style={{fontSize: 13, cursor: 'pointer', margin: 20, textDecoration: 'underline', color: 'gray'}}>로그 아웃</span>
        </Grid>
      )
    }

    if (this.state.qrScanning) {
      return (
        <Grid container justify="center" className="auto-width-100 relative-position direction-column-div align-center">
          <div onClick={() => {

            navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then((stream) => {
              if (stream) {
                stream.getVideoTracks()[0].stop();
              }
              setTimeout(() => {
                this.setState({qrScanning: false})
              }, 10)

            })

          }} className="direction-row-div" style={{position: 'absolute', top: 20, right: 30, cursor: 'pointer'}}>
            <CloseIcon  style={{fontSize: 18}} />
            <span>닫기</span>
          </div>
          { !this.state.hasFoundQRCode
            ?<div className="qr-reader-container">
            <QrReader
              delay={100}
              className="qr-reader-container"
              onScan={(data) => {
                if (data) {
                  var urlRegex = /(https?:\/\/[^\s]+)/g;
                  if (data.match(urlRegex)) {
                    this.setState({messageForScanning: '존재하지 않는 쿠폰입니다.', showScanMessageModal: true})
                  } else {
                    firebase.database().ref(`coupons/${data}`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        if (snapshot.val().usedAt) {
                          this.setState({messageForScanning: '이미 사용된 쿠폰입니다.', showScanMessageModal: true})
                        } else {
                          if (Number(moment(snapshot.val().issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString()) < Number(moment(Date.now()).format('YYYYMMDD').toString())) {
                            this.setState({messageForScanning: '유효기간이 지난 쿠폰입니다.', showScanMessageModal: true})
                          } else {
                            this.setState({showUseCouponModal: true, couponId: data, couponInfo: snapshot.val(), hasFoundQRCode: true})
                          }

                        }
                      } else {
                        this.setState({messageForScanning: '존재하지 않는 쿠폰입니다.', showScanMessageModal: true})
                      }
                    })
                  }
                }
              }}
            />
            </div>
            :<div className="qr-reader-container">
            <QRCode className="qr-reader-container" value={this.state.couponInfo.couponId} renderAs={'svg'} level={'H'} size={500} bgColor={'#00615c'} includeMargin={true} fgColor='white' />
            </div>
          }
          { this.state.hasFoundQRCode
            ?<span style={{marginTop: 20}}>QR코드 스캔 완료.</span>
            :<span style={{marginTop: 20}}>QR코드를 올려주세요.</span>
          }

          <Modal
            open={this.state.showUseCouponModal}
            onClose={() => this.setState({showUseCouponModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 쿠폰을 사용하시겠습니까?</span>
            { this.state.couponInfo &&
              <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
                <span style={{fontSize: 14}}>금액: {this.onChangeCurrencyFormat(this.state.couponInfo.amount)}원</span>
                <span style={{fontSize: 14}}>유효기간: {this.onStringNumberToDate(moment(this.state.couponInfo.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())}</span>
              </div>
            }
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showUseCouponModal: false, hasFoundQRCode: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              this.setState({showUseCouponModal: false})
              firebase.database().ref(`coupons/${this.state.couponId}`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  firebase.database().ref(`coupons/${this.state.couponId}/usedAt`).set(Date.now())

                  this.setState({
                    completeUsingCouponSnack: true,
                    showUseCouponModal: false,
                    couponId: null,
                    couponInfo: null,

                    qrScanning: false,
                    hasFoundQRCode: false,
                    messageForScanning: '',
                    showScanMessageModal: false
                  })
                }
              })
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>사용</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showScanMessageModal}
            onClose={() => this.setState({showScanMessageModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>{this.state.messageForScanning}</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-one-button-filled" onClick={() => {
              this.setState({showScanMessageModal: false, messageForScanning: ''})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>확인</span>
            </div>
            </Grid>
            </div>
          </Modal>
        </Grid>
      )
    }




    if (this.state.isOpenItemDeliveryList) {
      let deliveryData = this.state.itemOrders[this.state.openedItemDeliveryDate]
      if (!deliveryData) {
        this.setState({isOpenItemDeliveryList: false})
      }
      let orders = Object.values(deliveryData)

      let totalOrderIncome = 0
      orders.map((order, index) => {
        totalOrderIncome = totalOrderIncome + order.paidPrice
      })

      let deliveryItemOrderList = orders

      let searchTerm = this.state.searchTerm

      if (searchTerm) {
        deliveryItemOrderList = orders.filter(orders => orders.addressTo['receiver'].indexOf(searchTerm) > -1 )
        deliveryItemOrderList = deliveryItemOrderList.concat(orders.filter(orders => orders.addressTo['fullAddress'].indexOf(searchTerm) > -1 ))
        deliveryItemOrderList = deliveryItemOrderList.concat(orders.filter(orders => orders['buyerName'].indexOf(searchTerm) > -1 ))
        deliveryItemOrderList = deliveryItemOrderList.concat(orders.filter(orders => orders.addressTo['phoneNumber'].indexOf(searchTerm) > -1 ))
        deliveryItemOrderList = deliveryItemOrderList.concat(orders.filter(orders => this.state.users[orders.buyerUid].phoneNumber.indexOf(searchTerm) > -1 ))
        deliveryItemOrderList = [...new Set(deliveryItemOrderList)];



        let pattern = /([^가-힣\x20\d])/i;
        if (!pattern.test(searchTerm)) {
          deliveryItemOrderList = deliveryItemOrderList
        }
      }



      if (this.state.notDeliveredChecked) {
        deliveryItemOrderList = deliveryItemOrderList.filter(deliveryItemOrderList => !deliveryItemOrderList.hasDelivered)
      }



      let levelDesc = [
        '상품인수', '상품이동', '지점도착', '배송출발', '배송완료'
      ]
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="big-back-button-container z-index-900" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>일반 택배 주문 리스트</span>
                <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 5}}>{this.onDateNumberToDate(this.state.openedItemDeliveryDate)} 주문건</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenItemDeliveryList: false, openedItemDeliveryDate: null, searchTerm: '', notDeliveredChecked: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 120}} className="auto-width-100 direction-column-div">
              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
                <FontAwesomeIcon style={{color: '#6b6b6b', fontSize: 15, marginRight: 10, marginTop: 5}} icon={faSearch} />
                <Input autoComplete="off" style={{width: 150}} onClick={(e) => {
                  e.stopPropagation();
                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                  let searchTerm = event.target.value
                  this.setState({searchTerm: searchTerm})

                }}
                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="검색"  />

              </div>
              { this.state.deliveryProvidersArray.length !== 0 &&
                <Select inputProps={{readOnly:true}} className="select-component-style" onChange={values => {
                  this.setState({selectedDeliveryProvider: values, providerCode: values.value.toString()})
                }} value={this.state.selectedDeliveryProvider} options={this.state.deliveryProvidersArray} />
              }
              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
                <div onClick={() => {
                  this.setState({startGetDeliveryDataSnack: true})

                  deliveryItemOrderList.map((order, index) => {
                    this.onGetItemDeliveryInfo(order, this.state.users[order.buyerUid])
                  })

                }} style={{marginTop: 10, width: 150}} className="normal-very-small-button justify-content-center direction-row-div">
                  <span style={{color: 'white', fontSize: 13}}>배송 조회</span>
                </div>
              </div>
              <div style={{marginLeft: 20}} className="auto-width-90 direction-row-div">
              <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.notDeliveredChecked}  onChange={() => {
                      this.setState({notDeliveredChecked: !this.state.notDeliveredChecked})
                    }} value={this.state.notDeliveredChecked} />
                  }
                  label={<span className="one-line" style={{ fontSize: 12 }}>미배송 주문</span>}
                />
              </FormGroup>
              </div>
              { deliveryItemOrderList.map((order, index) => {

                  return (
                    <Grid className="auto-width-100 direction-column-div relative-position">
                      <Grid onClick={() => {
                        let selectedOrderId = this.state.selectedOrderId
                        if (selectedOrderId[order.orderId]) {
                          delete selectedOrderId[order.orderId]
                        } else {
                          selectedOrderId[order.orderId] = true
                        }
                        this.setState({selectedOrderId: selectedOrderId})

                      }} className="direction-row-div auto-width-60" style={{padding: 20, paddingTop: 10, paddingBottom: 10, cursor: 'pointer'}}>
                        <Grid className="auto-width-80 direction-column-div">
                          <div className="direction-row-div">
                          { order.items[this.state.selectedOrderItem] &&
                            <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginRight: 5, marginTop: 2}} icon={faCheck} />
                          }
                          <span style={{fontSize: 15, fontWeight: 'bold'}}>[{order.addressTo.receiver}님]</span>
                          <span style={{fontSize: 11, marginTop: 3, marginLeft: 5}}>상품 {order.totalAmount}개</span>
                          { this.state.itemDeliveryMemo[order.orderId] &&
                            <span style={{fontSize: 11, marginTop: 3, marginLeft: 5, color: 'red', fontWeight: 'bold'}}>메모</span>
                          }
                          </div>
                          { isMobileOnly
                            ?<div  className="direction-row-div">
                              <span className="break-word" style={{fontSize: 12, marginTop: 3, width: 150}}>{order.addressTo.fullAddress} {order.addressTo.detailAddress}</span>
                            </div>
                            :<div className="direction-row-div">
                              <span className="break-word" style={{fontSize: 12, marginTop: 3}}>{order.addressTo.fullAddress} {order.addressTo.detailAddress}</span>
                            </div>
                          }

                          <div className="direction-row-div">
                            <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(order.addressTo.phoneNumber)}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(order.addressTo.phoneNumber)
                              this.setState({copiedPhoneNumberSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          { order['deiliveryInfo'] &&
                          <div style={{marginTop: 0, marginBottom: 20}}>
                            <div className="align-center justify-center relative-position row-between-space-86" style={{height: 40, marginTop: 20}}>
                              { order['deiliveryInfo']['level'] > 2
                                ?<Divider style={{backgroundColor: '#006042'}} className="first-delivery-process-line" />
                                :<Divider className="first-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 3
                                ?<Divider style={{backgroundColor: '#006042'}} className="second-delivery-process-line" />
                                :<Divider className="second-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 4
                                ?<Divider style={{backgroundColor: '#006042'}} className="third-delivery-process-line" />
                                :<Divider className="third-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 5
                                ?<Divider style={{backgroundColor: '#006042'}} className="fourth-delivery-process-line" />
                                :<Divider className="fourth-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 1
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 11}} icon={faBox} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 11}} icon={faBox} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 2
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruckMoving} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruckMoving} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 3
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faWarehouse} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faWarehouse} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 4
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruck} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruck} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 5
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 12}} icon={faCheck} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 12}} icon={faCheck} />
                                </div>
                              }
                            </div>
                          </div>
                          }
                          { this.state.selectedOrderId[order.orderId] &&
                            <div className="auto-width-100 direction-column-div">

                              <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>주문자 정보</span>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>이름</span>
                                <span style={{fontSize: 12}}>{this.state.users[order.buyerUid] && this.state.users[order.buyerUid].username}</span>
                              </div>
                              { this.state.users[order.buyerUid] &&
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>휴대폰 번호</span>
                                  <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(this.state.users[order.buyerUid].phoneNumber)}</span>
                                  <FontAwesomeIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(this.state.users[order.buyerUid].phoneNumber)
                                    this.setState({copiedPhoneNumberSnack: true})
                                  }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                                </div>
                              }

                              <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>주문 상세</span>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>주문 번호</span>
                                <span style={{fontSize: 12}}>{order.orderId}</span>
                                <FontAwesomeIcon onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(order.orderId)
                                  this.setState({copiedOrderIdSnack: true})
                                }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                              </div>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>결제 시간</span>
                                <span style={{fontSize: 12}}>{this.onGetTime(new Date(order.paidAt))}</span>
                              </div>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>결제 금액</span>
                                <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.paidPrice)}원</span>

                              </div>
                              { order.usingCoupon &&
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>쿠폰 금액</span>
                                  <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.usingCoupon.amount)}원 할인</span>

                                </div>
                              }
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span className="word-break" style={{fontSize: 12, width: 90}}>상품 종류</span>
                                <div className="auto-width-100 direction-column-div">
                                  { Object.values(order.items).map((item, index) => {
                                    if (this.state.selectedOrderItem === item.itemId) {
                                      return (
                                        <div className="direction-row-div">
                                          <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                          <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className="direction-row-div">
                                          <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                        </div>
                                      )
                                    }

                                  })
                                  }
                                  <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {order.totalAmount}개]</span>
                                </div>
                              </div>
                              { isMobileOnly
                                ?<Input autoComplete="off" multiline={true} rows={2} style={{width: 150, marginLeft: 75}} onClick={(e) => {
                                  e.stopPropagation();
                                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                                  let memo = this.state.itemDeliveryMemo
                                  if (event.target.value) {
                                    memo[order.orderId] = event.target.value
                                  } else {
                                    delete memo[order.orderId]
                                  }
                                  this.setState({itemDeliveryMemo: memo})
                                }}
                                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.itemDeliveryMemo[order.orderId] ? this.state.itemDeliveryMemo[order.orderId] : ''} />
                                :<Input autoComplete="off" multiline={true} rows={2} style={{width: 300, marginLeft: 75}} onClick={(e) => {
                                  e.stopPropagation();
                                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                                  let memo = this.state.itemDeliveryMemo
                                  if (event.target.value) {
                                    memo[order.orderId] = event.target.value
                                  } else {
                                    delete memo[order.orderId]
                                  }
                                  this.setState({itemDeliveryMemo: memo})
                                }}
                                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.itemDeliveryMemo[order.orderId] ? this.state.itemDeliveryMemo[order.orderId] : ''} />
                              }

                              <div onClick={(e) => {
                                e.stopPropagation();
                                let memo = this.state.itemDeliveryMemo
                                if (memo[order.orderId]) {
                                  firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${order.orderId}/memo`).set(memo[order.orderId])
                                  .then(() => {
                                    this.setState({completeMemoSnack: true})
                                  })
                                } else {
                                  firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${order.orderId}/memo`).remove()
                                  .then(() => {
                                    this.setState({completeMemoSnack: true})
                                  })
                                }


                              }} className="normal-very-small-button none-underline-text align-center" style={{width: 48, marginLeft: 75, marginTop: 10}}>
                                <span style={{fontSize: 11, color: 'white'}}>메모 저장</span>
                              </div>
                            </div>
                          }
                        </Grid>
                        { this.state.selectedOrderId[order.orderId]
                          ?<KeyboardArrowUpIcon style={{marginTop: 17}}/>
                          :<KeyboardArrowDownIcon style={{marginTop: 17}}/>
                        }
                        { this.state.itemDeliveryInvoiceNumbers[order.orderId]
                          ?<Input autoComplete="off" style={{width: 100, position: 'absolute', top: 25, right: 35}} onClick={(e) => {
                            e.stopPropagation();
                          }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                            let itemDeliveryInvoiceNumbers = this.state.itemDeliveryInvoiceNumbers
                            if (event.target.value) {
                              itemDeliveryInvoiceNumbers[order.orderId] = {
                                invoiceNumber: event.target.value,
                                uid: order.buyerUid,
                                orderId: order.orderId
                              }
                            } else {
                              delete itemDeliveryInvoiceNumbers[order.orderId]
                            }
                            this.setState({itemDeliveryInvoiceNumbers: itemDeliveryInvoiceNumbers})
                          }}
                          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={this.state.itemDeliveryInvoiceNumbers[order.orderId].invoiceNumber} />
                          :<Input onClick={(e) => {
                            e.stopPropagation();
                          }} autoComplete="off" style={{width: 100, position: 'absolute', top: 25, right: 35}}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                            let itemDeliveryInvoiceNumbers = this.state.itemDeliveryInvoiceNumbers
                            if (event.target.value) {
                              itemDeliveryInvoiceNumbers[order.orderId] = {
                                invoiceNumber: event.target.value,
                                uid: order.buyerUid,
                                orderId: order.orderId
                              }
                            } else {
                              delete itemDeliveryInvoiceNumbers[order.orderId]
                            }
                            this.setState({itemDeliveryInvoiceNumbers: itemDeliveryInvoiceNumbers})
                          }}
                          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={''} />
                        }
                      </Grid>
                      { this.state.deliveryItemOrderList.length !== index + 1 &&
                        <Divider style={{marginTop: 5}}/>
                      }
                    </Grid>
                  )
                })
              }
            </Grid>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <div className="direction-row-div">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>총 수량</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, }} />
              <div style={{margin: 20}}>
              { Object.values(this.state.eachOrderAmount).length !== 0 &&
                <div>
                  { Object.values(this.state.eachOrderAmount).map((each, index) => {
                    return (
                      <div className="direction-row-div" style={{marginBottom: 0}}>
                        <div className="direction-row-div" style={{marginTop: 5}}>
                          <span className="one-line" style={{fontSize: 13, width: 150, marginRight: 10}}>{each.name}</span>
                          <span style={{fontSize: 13, fontWeight: 'bold', width: 40}}>{each.amount}개</span>
                          <span style={{fontSize: 13, width: 80}}>({this.state.items[each.id].amount}개 남음)</span>
                        </div>
                        <Input autoComplete="off" style={{width: 50}} onChange={(event) => {
                          let addItemsAmount = this.state.addItemsAmount
                          if (event.target.value) {
                            addItemsAmount[each.id] = {
                              amount: event.target.value,
                              id: each.id
                            }
                          } else {
                            delete addItemsAmount[each.id]
                          }
                          this.setState({addItemsAmount: addItemsAmount})
                        }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}}
                        InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="추가 수량"  value={this.state.addItemsAmount[each.id] ? this.state.addItemsAmount[each.id].amount : ''} />
                      </div>
                    )
                  })
                  }
                </div>
              }
              <div onClick={() => {
                let addItemsAmount = this.state.addItemsAmount

                Object.values(addItemsAmount).map((item, index) => {
                  if (Number(item.amount) > -1) {
                    firebase.database().ref(`items/${item.id}/amount`).set(Number(item.amount))
                  }
                  if (index + 1 === Object.values(addItemsAmount).length) {
                    this.setState({addItemsAmount: {}})
                  }
                })
              }} style={{marginTop: 10}} className="normal-very-small-button direction-column-div">
                <span style={{color: 'white', fontSize: 13}}>잔여수량변경</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20 }} />
              <div className="direction-row-div" style={{marginBottom: 5}}>
                <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>택배 주문 총 수량</span>
                <span style={{fontSize: 14, fontWeight: 'bold'}}>총 {this.state.totalOrderItems}개</span>
              </div>
              <div className="direction-row-div" style={{marginBottom: 5}}>
                <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>총 주문 매출</span>
                <span style={{fontSize: 14, fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(totalOrderIncome)}원</span>
              </div>
              </div>
            </div>
            <Grid container justify="center">
            { this.state.itemOrders &&
              <div className="auto-width-100">
                { this.state.itemOrders[this.state.openedItemDeliveryDate] &&
                  <div onClick={() => {
                    if (this.state.itemOrders) {
                      if (this.state.itemOrders[this.state.openedItemDeliveryDate]) {
                        this.setState({isInsertingitemDeliveryInvoiceNumbers: true})
                        let itemDeliveryInvoiceNumbers = this.state.itemDeliveryInvoiceNumbers
                        let invoiceNumbersLength = Object.keys(itemDeliveryInvoiceNumbers).length
                        let loopIndex = 0
                        Object.values(itemDeliveryInvoiceNumbers).map((invoiceNumber, index) => {
                          firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}`).once('value', (snapshot) => {
                            if (snapshot.val()) {
                              firebase.database().ref(`users/${invoiceNumber.uid}/itemOrders/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                              .then(() => {

                                firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/invoiceNumber`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    if (snapshot.val() !== Number(invoiceNumber.invoiceNumber)) {
                                      if (this.state.providerCode !== '06') {
                                        firebase.database().ref(`users/${invoiceNumber.uid}/itemOrders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                      }
                                    }
                                  } else {
                                    if (this.state.providerCode !== '06') {
                                      firebase.database().ref(`users/${invoiceNumber.uid}/itemOrders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                    }
                                  }
                                })


                                firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/invoiceNumber`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    if (snapshot.val() !== Number(invoiceNumber.invoiceNumber)) {
                                      firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                      .then(() => {
                                        if (this.state.providerCode !== '06') {
                                          firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                        }

                                        firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
                                          if (snapshot.val()) {
                                            let provider = snapshot.val()[this.state.providerCode].provider
                                            axios({
                                              url: `https://senddeliverytrackingmessage-v2-xv3aig33rq-uc.a.run.app`,
                                              method: "post",
                                              headers: {
                                                "Content-Type":"application/json"
                                              },
                                              data: {
                                                orderId: invoiceNumber.orderId,
                                                uid: invoiceNumber.uid,
                                                invoiceNumber: invoiceNumber.invoiceNumber,
                                                path: 'item_order',
                                                provider: provider
                                              }
                                            })
                                          }
                                        })

                                        loopIndex = loopIndex + 1
                                        if (invoiceNumbersLength === loopIndex) {
                                          this.setState({isInsertingitemDeliveryInvoiceNumbers: false, completeInsertingItemsInvoiceNumberSnack: true})
                                        }
                                      })
                                    } else {
                                      loopIndex = loopIndex + 1
                                      if (invoiceNumbersLength === loopIndex) {
                                        this.setState({isInsertingitemDeliveryInvoiceNumbers: false, completeInsertingItemsInvoiceNumberSnack: true})
                                      }
                                    }
                                  } else {
                                    firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                    .then(() => {
                                      if (this.state.providerCode !== '06') {
                                        firebase.database().ref(`itemOrders/${this.state.openedItemDeliveryDate}/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                      }
                                      firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
                                        if (snapshot.val()) {
                                          let provider = snapshot.val()[this.state.providerCode].provider
                                          axios({
                                            url: `https://senddeliverytrackingmessage-v2-xv3aig33rq-uc.a.run.app`,
                                            method: "post",
                                            headers: {
                                              "Content-Type":"application/json"
                                            },
                                            data: {
                                              orderId: invoiceNumber.orderId,
                                              uid: invoiceNumber.uid,
                                              invoiceNumber: invoiceNumber.invoiceNumber,
                                              path: 'item_order',
                                              provider: provider
                                            }
                                          })
                                        }
                                      })
                                      loopIndex = loopIndex + 1
                                      if (invoiceNumbersLength === loopIndex) {
                                        this.setState({isInsertingitemDeliveryInvoiceNumbers: false, completeInsertingItemsInvoiceNumberSnack: true})
                                      }
                                    })
                                  }
                                })


                              })
                            }
                          })
                        })
                      }
                    }
                  }} className="btn-big" style={{margin: 15}}>
                    { this.state.isInsertingitemDeliveryInvoiceNumbers
                      ?<PulseLoader  color={'white'} loading={true} size={5} />
                      :<span style={{fontSize: 13, color: 'white'}}>송장번호 입력 완료</span>
                    }
                  </div>
                }
                <CSVLink data={this.state.excelItemData} style={{marginLeft: 20}} headers={itemHeaders}>
                  <span>주문서 다운로드</span>
                </CSVLink>
                <div style={{marginLeft: 20, marginTop: 5}}>
                <span  onClick={() => {
                  this.props.history.push('/print_item_orders/' + this.state.openedItemDeliveryDate);
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} style={{cursor: 'pointer', textDecoration: 'underline'}}>주문서 출력</span>
                </div>
                <input type="file" onChange={(event) => {
                  let fileObj = event.target.files[0];

                  //just pass the fileObj as parameter
                  ExcelRenderer(fileObj, (err, resp) => {
                    if(err){
                    } else {
                      let deliveryOrderData = []
                      let itemDeliveryInvoiceNumbers = {}
                      let dataArr = resp.rows
                      const startIndex = (element) => element[3] === '운송장번호'

                      dataArr.splice(0, dataArr.findIndex(startIndex) + 1);

                      let i = 0

                      orders.map((order, index) => {
                        if (order['addressTo']['receiver'].trim() === dataArr[i][6].trim()) {
                          itemDeliveryInvoiceNumbers[order.orderId] = {
                            invoiceNumber: Number(dataArr[i][3]),
                            uid: order.buyerUid,
                            orderId: order.orderId
                          }
                          i = i + 1
                        }

                        if (orders.length === index + 1) {
                          this.setState({itemDeliveryInvoiceNumbers: itemDeliveryInvoiceNumbers})
                        }
                      })
                    }
                  });

                }} style={{"padding":"10px"}} />
              </div>
            }
            </Grid>
            <div className="going-up-down-container">
              <div onClick={() => {
                if (isEdge || isIE) {
                    window.scrollTo(0,  0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
              }} className="going-up-button">
              <ArrowUpwardIcon style={{fontSize: 18}}/>
              </div>
              <div onClick={() => {
                if (isEdge || isIE) {
                    window.scrollTo(0,  document.body.scrollHeight)
                  } else {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
              }} className="going-down-button">
              <ArrowDownwardIcon style={{fontSize: 18}}/>
              </div>
            </div>
          </Grid>
          <Snackbar open={this.state.copiedPhoneNumberSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPhoneNumberSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPhoneNumberSnack: false})} severity="success">
              전화번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedOrderIdSnack} autoHideDuration={4000} onClose={() => this.setState({copiedOrderIdSnack: false})}>
            <Alert  onClose={() => this.setState({copiedOrderIdSnack: false})} severity="success">
              주문번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeInsertingItemsInvoiceNumberSnack} autoHideDuration={4000} onClose={() => this.setState({completeInsertingItemsInvoiceNumberSnack: false})}>
            <Alert  onClose={() => this.setState({completeInsertingItemsInvoiceNumberSnack: false})} severity="success">
              송장번호 입력을 완료했습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeMemoSnack} autoHideDuration={4000} onClose={() => this.setState({completeMemoSnack: false})}>
            <Alert  onClose={() => this.setState({completeMemoSnack: false})} severity="success">
              메모가 저장 되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.startGetDeliveryDataSnack} autoHideDuration={4000} onClose={() => this.setState({startGetDeliveryDataSnack: false})}>
            <Alert  onClose={() => this.setState({startGetDeliveryDataSnack: false})} severity="success">
              배송 조회가 시작되었습니다.
            </Alert>
          </Snackbar>
        </Grid>
      )
    }



    if (this.state.isOpenDeliveryList) {
      let deliveryData = this.state.orderSheets[this.state.openedDeliveryDate]
      if (!deliveryData.orders) {
        this.setState({isOpenDeliveryList: false})
      }
      let orders = Object.values(deliveryData.orders)


      let totalOrderIncome = 0
      orders.map((order, index) => {
        totalOrderIncome = totalOrderIncome + order.paidPrice
      })

      let deliveryOrderList = orders

      let searchTerm = this.state.searchTerm

      if (searchTerm) {
        deliveryOrderList = orders.filter(orders => orders.addressTo['receiver'].indexOf(searchTerm) > -1 )
        deliveryOrderList = deliveryOrderList.concat(orders.filter(orders => orders.addressTo['fullAddress'].indexOf(searchTerm) > -1 ))
        deliveryOrderList = deliveryOrderList.concat(orders.filter(orders => orders.addressTo['phoneNumber'].indexOf(searchTerm) > -1 ))
        deliveryOrderList = deliveryOrderList.concat(orders.filter(orders => orders['buyerName'].indexOf(searchTerm) > -1 ))
        deliveryOrderList = deliveryOrderList.concat(orders.filter(orders => this.state.users[orders.buyerUid].phoneNumber.indexOf(searchTerm) > -1 ))

        deliveryOrderList = [...new Set(deliveryOrderList)];

        let pattern = /([^가-힣\x20\d])/i;
        if (!pattern.test(searchTerm)) {
          deliveryOrderList = deliveryOrderList
        }
      }



      if (this.state.notDeliveredChecked) {
        deliveryOrderList = deliveryOrderList.filter(deliveryOrderList => !deliveryOrderList.hasDelivered)
      }

      if (this.state.numberOfOrdersChecked) {
        deliveryOrderList.sort((a, b) => {
          if (a.additionalItems && b.additionalItems) {
            return (b.totalAmount + this.onGetTotalAmount(b.additionalItems)) - (a.totalAmount + this.onGetTotalAmount(a.additionalItems))
          } else if (a.additionalItems) {
            return b.totalAmount - (a.totalAmount + this.onGetTotalAmount(a.additionalItems))
          } else if (b.additionalItems) {
            return (b.totalAmount + this.onGetTotalAmount(b.additionalItems)) - a.totalAmount
          } else {
            return b.totalAmount - a.totalAmount
          }
        })
      }

      if (this.state.orderTimeLateChecked) {
        deliveryOrderList.sort((a, b) => {
          return b.purchasedAt - a.purchasedAt
        })
      }

      if (this.state.orderTimeEarlyChecked) {
        deliveryOrderList.sort((a, b) => {
          return a.purchasedAt - b.purchasedAt
        })
      }

      if (this.state.numberOfPurchasesChecked) {
        deliveryOrderList.sort((a, b) => {
          if (this.state.users[a.buyerUid] && this.state.users[b.buyerUid]) {
            return this.onGetNumberOfPurchase(this.state.users[b.buyerUid].sconeOrders) - this.onGetNumberOfPurchase(this.state.users[a.buyerUid].sconeOrders)
          } else if (this.state.users[a.buyerUid]) {
            return 0 - this.onGetNumberOfPurchase(this.state.users[a.buyerUid].sconeOrders)
          } else if (this.state.users[b.buyerUid]) {
            return this.onGetNumberOfPurchase(this.state.users[b.buyerUid].sconeOrders) - 0
          } else {
            return 0
          }
        })
      }

      let levelDesc = [
        '상품인수', '상품이동', '지점도착', '배송출발', '배송완료'
      ]

      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="big-back-button-container  z-index-900" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>스콘 택배 주문 리스트</span>
                <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 5}}>{this.onDateNumberToDate(this.state.openedDeliveryDate)} 주문건</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({editApplyDeliveryDiscount: false, editDeliveryDiscountTitle: '이벤트 이름', editDeliveryDiscountRate: '5', editDeliveryDiscountUntil: Date.now(), editSetEndTimeOfEvent: true, isOpenDeliveryList: false, openedDeliveryDate: null, searchTerm: '', notDeliveredChecked: false, selectedSconesObj: {}})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 120}} className="auto-width-100 direction-column-div">
              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
              { this.state.orderSheets[this.state.openedDeliveryDate].open &&
                <div onClick={() => {
                  this.setState({showCloseOrderModal: true})
                }} style={{marginBottom: 10}} className="normal-very-small-button direction-column-div">
                  <span style={{color: 'white', fontSize: 13}}>택배 주문 닫기</span>
                </div>
              }

              </div>

              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
                <FontAwesomeIcon style={{color: '#6b6b6b', fontSize: 15, marginRight: 10, marginTop: 5}} icon={faSearch} />
                <Input autoComplete="off" style={{width: 150}} onClick={(e) => {
                  e.stopPropagation();
                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                  let searchTerm = event.target.value
                  this.setState({searchTerm: searchTerm})

                }}
                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="검색"  />

              </div>

              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
                <div onClick={() => {
                  let phoneNumbers = []
                  Object.values(this.state.orderSheets[this.state.openedDeliveryDate].orders).map((order, index) => {
                    if (this.state.users[order.buyerUid]) {
                      if (!this.state.notSelectedChecked[order.orderId]) {
                        phoneNumbers.push(this.state.users[order.buyerUid].phoneNumber)
                      }
                    }
                    if (Object.values(this.state.orderSheets[this.state.openedDeliveryDate].orders).length === index + 1) {
                      navigator.clipboard.writeText(phoneNumbers)
                      this.setState({copiedEntirePhoneNumbersSnack: true})
                    }
                  })

                }} style={{marginTop: 10, width: 150}} className="normal-very-small-button justify-content-center direction-row-div">
                  <span style={{color: 'white', fontSize: 13}}>선택된 전화번호 복사</span>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faCopy} />
                </div>
              </div>
              { this.state.deliveryProvidersArray.length !== 0 &&
                <Select inputProps={{readOnly:true}} className="select-component-style" onChange={values => {
                  this.setState({selectedDeliveryProvider: values, providerCode: values.value.toString()})
                }} value={this.state.selectedDeliveryProvider} options={this.state.deliveryProvidersArray} />
              }
              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
                <div onClick={() => {
                  this.setState({startGetDeliveryDataSnack: true})
                  deliveryOrderList.map((order, index) => {
                    this.onGetDeliveryInfo(order, this.state.users[order.buyerUid])
                  })

                }} style={{marginTop: 10, width: 150}} className="normal-very-small-button justify-content-center direction-row-div">
                  <span style={{color: 'white', fontSize: 13}}>배송 조회</span>
                </div>
              </div>
              <div style={{marginLeft: 20}} className="auto-width-90 direction-row-div">
              <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.notDeliveredChecked}  onChange={() => {
                      this.setState({notDeliveredChecked: !this.state.notDeliveredChecked})
                    }} value={this.state.notDeliveredChecked} />
                  }
                  label={<span className="one-line" style={{ fontSize: 12 }}>미배송 주문</span>}
                />
              </FormGroup>
              </div>
              { this.state.orderArray.length !== 0 &&
                <Select inputProps={{readOnly:true}} className="select-order-component-style" onChange={values => {
                  this.setState({selectedOrder: values})
                  if (values.value.toString() === 'numberOfOrders') {
                    this.setState({
                      numberOfOrdersChecked: true,
                      numberOfPurchasesChecked: false,
                      orderTimeLateChecked: false,
                      orderTimeEarlyChecked: false
                    })
                  } else if (values.value.toString() === 'numberOfPurchases') {
                    this.setState({
                      numberOfOrdersChecked: false,
                      numberOfPurchasesChecked: true,
                      orderTimeLateChecked: false,
                      orderTimeEarlyChecked: false
                    })
                  } else if (values.value.toString() === 'orderTimeLate') {
                    this.setState({
                      numberOfOrdersChecked: false,
                      numberOfPurchasesChecked: false,
                      orderTimeLateChecked: true,
                      orderTimeEarlyChecked: false
                    })
                  } else if (values.value.toString() === 'orderTimeEarly') {
                    this.setState({
                      numberOfOrdersChecked: false,
                      numberOfPurchasesChecked: false,
                      orderTimeLateChecked: false,
                      orderTimeEarlyChecked: true
                    })
                  } else {
                    this.setState({
                      numberOfOrdersChecked: false,
                      numberOfPurchasesChecked: false,
                      orderTimeLateChecked: false,
                      orderTimeEarlyChecked: false
                    })
                  }
                }} value={this.state.selectedOrder} options={this.state.orderArray} />
              }
              { deliveryOrderList.map((order, index) => {

                  return (
                    <Grid className="auto-width-100 direction-column-div relative-position">
                      <Grid onClick={() => {
                        let selectedDeliveryOrderId = this.state.selectedDeliveryOrderId
                        if (selectedDeliveryOrderId[order.orderId]) {
                          delete selectedDeliveryOrderId[order.orderId]
                        } else {
                          selectedDeliveryOrderId[order.orderId] = true
                        }
                        this.setState({selectedDeliveryOrderId: selectedDeliveryOrderId})

                      }} className="direction-row-div auto-width-60" style={{padding: 20, paddingTop: 10, paddingBottom: 10, cursor: 'pointer'}}>
                        <Grid className="auto-width-80 direction-column-div">
                        { this.state.users[order.buyerUid] &&
                          <div>
                          { this.state.users[order.buyerUid].sconeOrders ?
                            this.state.users[order.buyerUid].sconeOrders[order.orderId] ?
                            <div className="direction-row-div">
                              { this.onGetNumberOfPurchase(this.state.users[order.buyerUid].sconeOrders) > 2 &&
                                <span style={{fontSize: 12, marginTop: 0, marginLeft: 0, color: 'red', fontWeight: 'bold'}}>총 {this.state.users[order.buyerUid] && this.onGetNumberOfPurchase(this.state.users[order.buyerUid].sconeOrders)}회 주문</span>
                              }
                            </div>
                            :<div onClick={() => {
                              firebase.database().ref(`users/${order.buyerUid}`).once('value', (snapshot) => {
                                if (snapshot.val()) {
                                  firebase.database().ref(`users/${order.buyerUid}/sconeOrders/${order.orderId}`).set(order)
                                }
                              })
                            }}>
                              <span style={{fontSize: 12, marginTop: 0, marginLeft: 0, color: 'red', fontWeight: 'bold'}}>데이터 복구</span>
                            </div>
                            :<div onClick={() => {
                              firebase.database().ref(`users/${order.buyerUid}`).once('value', (snapshot) => {
                                if (snapshot.val()) {
                                  firebase.database().ref(`users/${order.buyerUid}/sconeOrders/${order.orderId}`).set(order)
                                }
                              })
                            }}>
                              <span style={{fontSize: 12, marginTop: 0, marginLeft: 0, color: 'red', fontWeight: 'bold'}}>데이터 복구</span>
                            </div>
                          }
                          </div>

                        }
                        <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                          <FormControlLabel
                            control={
                              <GreenCheckbox checked={!this.state.notSelectedChecked[order.orderId]}  onChange={(e) => {
                                e.stopPropagation();
                                let notSelectedChecked = this.state.notSelectedChecked
                                if (notSelectedChecked[order.orderId]) {
                                  delete notSelectedChecked[order.orderId]
                                } else {
                                  notSelectedChecked[order.orderId] = true
                                }
                                this.setState({notSelectedChecked: notSelectedChecked})
                              }}  />
                            }
                            label={
                              <div className="direction-row-div">
                              { order.items[this.state.selectedItem] &&
                                <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginRight: 5, marginTop: 2}} icon={faCheck} />
                              }

                              <span style={{fontSize: 15, fontWeight: 'bold'}}>[{order.addressTo.receiver}님]</span>
                              <span style={{fontSize: 11, marginTop: 3, marginLeft: 5}}>스콘 {order.totalAmount}개</span>
                              { order.additionalItems &&
                                 <span style={{fontSize: 11, marginTop: 3, marginLeft: 4}}>+ 추가 상품 {this.onGetTotalAmount(order.additionalItems)}개</span>
                              }
                              { this.state.deliveryMemo[order.orderId] &&
                                <span style={{fontSize: 11, marginTop: 3, marginLeft: 5, color: 'red', fontWeight: 'bold'}}>메모</span>
                              }
                              { this.state.users[order.buyerUid]['memo'] &&
                                <span style={{fontSize: 11, marginTop: 3, marginLeft: 5, color: 'blue', fontWeight: 'bold'}}>회원 메모</span>
                              }
                              </div>
                            }
                          />
                        </FormGroup>
                          { isMobileOnly
                            ?<div className="direction-row-div">
                              <span className="break-word" style={{fontSize: 12, marginTop: 3, width: 150}}>{order.addressTo.fullAddress} {order.addressTo.detailAddress}</span>
                            </div>
                            :<div className="direction-row-div">
                              <span className="break-word" style={{fontSize: 12, marginTop: 3}}>{order.addressTo.fullAddress} {order.addressTo.detailAddress}</span>
                            </div>
                          }

                          <div className="direction-row-div">
                            <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(order.addressTo.phoneNumber)}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(order.addressTo.phoneNumber)
                              this.setState({copiedPhoneNumberSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          { order['deiliveryInfo'] &&
                          <div style={{marginTop: 0, marginBottom: 20}}>
                            <div className="align-center justify-center relative-position row-between-space-86" style={{height: 40, marginTop: 20}}>
                              { order['deiliveryInfo']['level'] > 2
                                ?<Divider style={{backgroundColor: '#006042'}} className="first-delivery-process-line" />
                                :<Divider className="first-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 3
                                ?<Divider style={{backgroundColor: '#006042'}} className="second-delivery-process-line" />
                                :<Divider className="second-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 4
                                ?<Divider style={{backgroundColor: '#006042'}} className="third-delivery-process-line" />
                                :<Divider className="third-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 5
                                ?<Divider style={{backgroundColor: '#006042'}} className="fourth-delivery-process-line" />
                                :<Divider className="fourth-delivery-process-line" />
                              }
                              { order['deiliveryInfo']['level'] > 1
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 11}} icon={faBox} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 11}} icon={faBox} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 2
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruckMoving} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                  <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruckMoving} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 3
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faWarehouse} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faWarehouse} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 4
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruck} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 10}} icon={faTruck} />
                                </div>
                              }
                              { order['deiliveryInfo']['level'] > 5
                                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 12}} icon={faCheck} />
                                </div>
                                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 30, height: 30, borderRadius: 35}}>
                                <FontAwesomeIcon style={{color: 'white', fontSize: 12}} icon={faCheck} />
                                </div>
                              }
                            </div>
                          </div>
                          }
                          { this.state.selectedDeliveryOrderId[order.orderId] &&
                            <div className="auto-width-100 direction-column-div">
                              <div className="direction-row-div">
                                <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>주문자 정보</span>
                                <FontAwesomeIcon onClick={(e) => {
                                  e.stopPropagation();
                                  if (this.state.users[order.buyerUid]['memo']) {
                                    this.setState({isOpenUserDetailSheet: true, selectedUserInfo: this.state.users[order.buyerUid], tempMemo: this.state.users[order.buyerUid]['memo']})
                                  } else {
                                    this.setState({isOpenUserDetailSheet: true, selectedUserInfo: this.state.users[order.buyerUid], tempMemo: ''})
                                  }
                                  
                                }} style={{color: 'black', fontSize: 12, marginTop: 7, marginLeft: 3}} icon={faIdCard} />
                              </div>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>이름</span>
                                <span style={{fontSize: 12}}>{this.state.users[order.buyerUid] && this.state.users[order.buyerUid].username}</span>
                              </div>
                              { this.state.users[order.buyerUid] &&
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>휴대폰 번호</span>
                                  <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(this.state.users[order.buyerUid].phoneNumber)}</span>
                                  <FontAwesomeIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(this.state.users[order.buyerUid].phoneNumber)
                                    this.setState({copiedPhoneNumberSnack: true})
                                  }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                                </div>
                              }
                              <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>주문 상세</span>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>주문 번호</span>
                                <span style={{fontSize: 12}}>{order.orderId}</span>
                                <FontAwesomeIcon onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(order.orderId)
                                  this.setState({copiedOrderIdSnack: true})
                                }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                              </div>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>결제 시간</span>
                                <span style={{fontSize: 12}}>{this.onGetTime(new Date(order.paidAt))}</span>
                              </div>
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span style={{fontSize: 12, width: 75}}>결제 금액</span>
                                <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.paidPrice)}원</span>

                              </div>
                              { order.usingCoupon &&
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>쿠폰 금액</span>
                                  <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.usingCoupon.amount)}원 할인</span>

                                </div>
                              }
                              { order.giftBoxAmount &&
                                <div className="auto-width-100 direction-row-div">
                                  <span className="word-break" style={{fontSize: 12, width: 90}}>디저트 박스</span>
                                  <div className="auto-width-100 direction-column-div">
                                  <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: 'red'}}>디저트 박스{order.giftBoxAmount}개</span>
                                  </div>
                                </div>
                              }
                              <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                <span className="word-break" style={{fontSize: 12, width: 90}}>스콘 종류</span>
                                <div className="auto-width-100 direction-column-div">
                                  { this.sortByOrder(Object.values(order.items)).map((item, index) => {
                                    if (this.state.selectedItem === item.itemId) {
                                      return (
                                        <div className="direction-row-div">
                                          <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                          <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className="direction-row-div">
                                          <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                        </div>
                                      )
                                    }

                                  })
                                  }
                                  <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {order.totalAmount}개]</span>
                                </div>
                              </div>
                              { order.additionalItems &&
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span className="word-break" style={{fontSize: 12, width: 90}}>추가 상품</span>
                                  <div className="auto-width-100 direction-column-div">
                                    { Object.values(order.additionalItems).map((item, index) => {
                                      if (this.state.selectedItem === item.itemId) {
                                        return (
                                          <div className="direction-row-div">
                                            <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                            <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div className="direction-row-div">
                                            <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                          </div>
                                        )
                                      }

                                    })
                                    }
                                    <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {this.onGetTotalAmount(order.additionalItems)}개]</span>
                                  </div>
                                </div>
                              }
                              { isMobileOnly
                                ?<Input autoComplete="off" multiline={true} rows={2} style={{width: 150, marginLeft: 75}} onClick={(e) => {
                                  e.stopPropagation();
                                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                                  let memo = this.state.deliveryMemo
                                  if (event.target.value) {
                                    memo[order.orderId] = event.target.value
                                  } else {
                                    delete memo[order.orderId]
                                  }
                                  this.setState({deliveryMemo: memo})
                                }}
                                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.deliveryMemo[order.orderId] ? this.state.deliveryMemo[order.orderId] : ''} />
                                :<Input autoComplete="off" multiline={true} rows={2} style={{width: 300, marginLeft: 75}} onClick={(e) => {
                                  e.stopPropagation();
                                }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                                  let memo = this.state.deliveryMemo
                                  if (event.target.value) {
                                    memo[order.orderId] = event.target.value
                                  } else {
                                    delete memo[order.orderId]
                                  }
                                  this.setState({deliveryMemo: memo})
                                }}
                                InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.deliveryMemo[order.orderId] ? this.state.deliveryMemo[order.orderId] : ''} />
                              }

                              <div onClick={(e) => {
                                e.stopPropagation();
                                let memo = this.state.deliveryMemo
                                if (memo[order.orderId]) {
                                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${order.orderId}/memo`).set(memo[order.orderId])
                                  .then(() => {
                                    this.setState({completeDeliveryMemoSnack: true})
                                  })
                                } else {
                                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${order.orderId}/memo`).remove()
                                  .then(() => {
                                    this.setState({completeDeliveryMemoSnack: true})
                                  })
                                }


                              }} className="normal-very-small-button none-underline-text align-center" style={{width: 48, marginLeft: 75, marginTop: 10}}>
                                <span style={{fontSize: 11, color: 'white'}}>메모 저장</span>
                              </div>
                              <div
                                item
                                className="no-selection horiaontal-center-align"
                                style={{marginLeft: 70, marginTop: 10, cursor: 'pointer'}}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({showCancelDeliveryModal: true, cancelDeliveryData: order})
                                }}
                              >
                                <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
                                <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>주문 취소</span>
                              </div>
                            </div>
                          }

                        </Grid>
                        { this.state.selectedDeliveryOrderId[order.orderId]
                          ?<KeyboardArrowUpIcon style={{marginTop: 17}}/>
                          :<KeyboardArrowDownIcon style={{marginTop: 17}}/>
                        }
                        { this.state.deliveryInvoiceNumbers[order.orderId]
                          ?<Input autoComplete="off" style={{width: 100, position: 'absolute', top: 25, right: 35}} onClick={(e) => {
                            e.stopPropagation();
                          }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                            let deliveryInvoiceNumbers = this.state.deliveryInvoiceNumbers
                            if (event.target.value) {
                              deliveryInvoiceNumbers[order.orderId] = {
                                invoiceNumber: event.target.value,
                                uid: order.buyerUid,
                                orderId: order.orderId
                              }
                            } else {
                              delete deliveryInvoiceNumbers[order.orderId]
                            }
                            this.setState({deliveryInvoiceNumbers: deliveryInvoiceNumbers})
                          }}
                          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={this.state.deliveryInvoiceNumbers[order.orderId].invoiceNumber} />
                          :<Input onClick={(e) => {
                            e.stopPropagation();
                          }} autoComplete="off" style={{width: 100, position: 'absolute', top: 25, right: 35}}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                            let deliveryInvoiceNumbers = this.state.deliveryInvoiceNumbers
                            if (event.target.value) {
                              deliveryInvoiceNumbers[order.orderId] = {
                                invoiceNumber: event.target.value,
                                uid: order.buyerUid,
                                orderId: order.orderId
                              }
                            } else {
                              delete deliveryInvoiceNumbers[order.orderId]
                            }
                            this.setState({deliveryInvoiceNumbers: deliveryInvoiceNumbers})
                          }}
                          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={''} />
                        }
                      </Grid>
                      { this.state.deliveryOrderList.length !== index + 1 &&
                        <Divider style={{marginTop: 5}}/>
                      }
                    </Grid>
                  )
                })
              }
            </Grid>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <div className="direction-row-div">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>총 수량</span>
                <span style={{marginLeft: 10}}>(잔여 수량 {this.onGetTotalAmount(this.state.orderSheets[this.state.openedDeliveryDate].menu)}개)</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, }} />
              <div style={{margin: 20}}>
              { Object.values(this.state.eachDeliveryAmount).length !== 0 &&
                <div>
                  { Object.values(this.state.eachDeliveryAmount).map((each, index) => {
                    return (
                      <div className="direction-row-div" style={{marginBottom: 0}}>
                        <div onClick={() => {
                          let selectedSconesObj = this.state.selectedSconesObj
                          if (selectedSconesObj[each.id]) {
                            delete selectedSconesObj[each.id]
                          } else {
                            selectedSconesObj[each.id] = {
                              id: each.id,
                              name: each.name,
                              amount: each.amount
                            }
                          }
                          this.setState({selectedSconesObj: selectedSconesObj})
                        }} className="direction-row-div" style={{marginTop: 5, cursor: 'pointer'}}>
                          <span className={this.state.selectedSconesObj[each.id] ? "one-line underline-text" : "one-line"} style={{fontSize: 13, width: 150, marginRight: 10}}>{each.name}</span>
                          <span style={{fontSize: 13, fontWeight: 'bold', width: 40}}>{each.amount}개</span>
                          <span style={{fontSize: 13, width: 80}}>({this.state.orderSheets[this.state.openedDeliveryDate].menu[each.id].amount}개 남음)</span>
                        </div>

                        <Input autoComplete="off" style={{width: 50}} onChange={(event) => {
                          let addAmount = this.state.addAmount
                          if (event.target.value) {
                            addAmount[each.id] = {
                              amount: event.target.value,
                              id: each.id
                            }
                          } else {
                            delete addAmount[each.id]
                          }
                          this.setState({addAmount: addAmount})
                        }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}}
                        InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="추가 수량"  value={this.state.addAmount[each.id] ? this.state.addAmount[each.id].amount : ''} />
                      </div>
                    )
                  })
                  }
                </div>
              }
              { Object.keys(this.state.selectedSconesObj).length !== 0 &&
                <div style={{marginTop: 10, marginBottom: 10}}>
                  { Object.values(this.state.selectedSconesObj).map((item, index) => {
                    if (index + 1 === Object.keys(this.state.selectedSconesObj).length) {
                      return (
                        <span style={{fontSize: 13, marginRight: 5}}>{item.name}({item.amount}) =</span>
                      )
                    } else {
                      return (
                        <span style={{fontSize: 13, marginRight: 5}}>{item.name}({item.amount}) +</span>
                      )
                    }

                  })

                  }
                  <span style={{fontSize: 13, fontWeight: 'bold'}}>총 {this.onGetTotalAmount(this.state.selectedSconesObj)}개</span>
                </div>
              }
              <div onClick={() => {
                let addAmount = this.state.addAmount
                let orderSheets = this.state.orderSheets

                Object.values(addAmount).map((item, index) => {
                  if (Number(item.amount) > -1) {
                    firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/menu/${item.id}/amount`).set(Number(item.amount))
                  }
                  if (index + 1 === Object.values(addAmount).length) {
                    this.setState({addAmount: {}})
                  }
                })
              }} style={{marginTop: 10}} className="normal-very-small-button direction-column-div">
                <span style={{color: 'white', fontSize: 13}}>잔여수량변경</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20 }} />
              { Object.values(this.state.eachAdditioanlAmount).length !== 0 &&
                <div>
                  { Object.values(this.state.eachAdditioanlAmount).map((each, index) => {
                    return (
                      <div className="direction-row-div" style={{marginBottom: 0}}>
                        <div className="direction-row-div" style={{marginTop: 5}}>
                          <span className={this.state.selectedSconesObj[each.id] ? "one-line underline-text" : "one-line"} style={{fontSize: 13, width: 150, marginRight: 10}}>{each.name}</span>
                          <span style={{fontSize: 13, fontWeight: 'bold', width: 40}}>{each.amount}개</span>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              }
              { this.state.totalAdditioanlItems !== 0 &&
                <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20 }} />
              }
              <div className="direction-row-div" style={{marginBottom: 5}}>
                <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>스콘 택배 주문 총 수량</span>
                <span style={{fontSize: 14, fontWeight: 'bold'}}>총 {this.state.totalDeliveryItems}개</span>
              </div>
              { this.state.totalAdditioanlItems !== 0 &&
                <div className="direction-row-div" style={{marginBottom: 5}}>
                  <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>추가 상품 주문 총 수량</span>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>총 {this.state.totalAdditioanlItems}개</span>
                </div>
              }

              <div className="direction-row-div" style={{marginBottom: 5}}>
                <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>총 주문 매출</span>
                <span style={{fontSize: 14, fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(totalOrderIncome)}원</span>
              </div>
              <div onClick={() => {
                if (!this.state.orderSheets[this.state.openedDeliveryDate].sentReviewAlarmMessage) {
                  this.setState({showDeliveryReviewMessageModal: true})
                }

              }} style={{marginTop: 10}} className={this.state.orderSheets[this.state.openedDeliveryDate].sentReviewAlarmMessage ? "normal-very-small-button-disabled direction-column-div" : "normal-very-small-button direction-column-div"}>
                <span style={{color: 'white', fontSize: 13}}>리뷰작성알림</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20 }} />
              <span style={{fontSize: 12, width: 200, color: 'gray', paddingTop: 10, paddingBottom: 10}}>추가 옵션</span>
              <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.editFirstPurchaseFree}  onChange={() => {
                      this.setState({editFirstPurchaseFree: !this.state.editFirstPurchaseFree})
                    }} value="defaultAddress" />
                  }
                  label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>첫 구매 무료배송</span>}
                />
              </FormGroup>
              <TextField style={{width: 300, marginTop: 5, marginBottom: 5}} onChange={(event) => {
                this.setState({editNotice: event.target.value})
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 전 공지"  value={this.state.editNotice} />
              <div className="justify-center" style={{padding: 20, paddingLeft: 0, paddingTop: 10}}>

              <label>
                <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                        onChange={() => this.setState({editApplyDeliveryDiscount: !this.state.editApplyDeliveryDiscount})} checked={this.state.editApplyDeliveryDiscount} />
              </label>
              <span style={{fontSize: 12, marginLeft: 10, marginTop: 2}}>할인 적용</span>
              </div>

              { this.state.editApplyDeliveryDiscount &&
                <div style={{padding: 20, paddingTop: 0, paddingLeft: 0, paddingBottom: 10}} className="direction-column-div">
                  <TextField style={{width: 200}} onChange={(event) => {
                    this.setState({editDeliveryDiscountTitle: event.target.value})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이벤트 이름"  value={this.state.editDeliveryDiscountTitle} />
                  <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                    this.setState({editDeliveryDiscountRate: Number(event.target.value.replace(/[^0-9]/g, ''))})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인율 (%)"  value={this.state.editDeliveryDiscountRate} />
                  <FormGroup  style={{alignItems: 'center', margin: 0, marginTop: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.editSetEndTimeOfEvent}  onChange={() => {
                          this.setState({editSetEndTimeOfEvent: !this.state.editSetEndTimeOfEvent})
                        }} value="defaultAddress" />
                      }
                      label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>이벤트 종료 시간 설정</span>}
                    />
                  </FormGroup>
                  <TextField
                    style={{marginTop: 5}}
                    disabled={!this.state.editSetEndTimeOfEvent}
                    id="datetime-local"
                    label="이벤트 종료 시간"
                    type="datetime-local"
                    onChange={(event) => {
                       this.setState({editDeliveryDiscountUntil: Number(moment(new Date(event.target.value)).format('x'))})
                     }}
                    defaultValue={this.state.editDeliveryDiscountUntil ? moment(this.state.editDeliveryDiscountUntil).format("YYYY-MM-DD[T]HH:mm") : moment().format("YYYY-MM-DD[T]HH:mm")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              }
              </div>

              <div onClick={() => {
                if (this.state.editFirstPurchaseFree) {
                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/firstPurchaseFree`).set(true)
                } else {
                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/firstPurchaseFree`).set(false)
                }
                if (this.state.editNotice) {
                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/notice`).set(this.state.editNotice)
                } else {
                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/notice`).remove()
                }
                if (this.state.editApplyDeliveryDiscount) {
                  if (this.state.editSetEndTimeOfEvent) {
                    firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/discount`).set({
                      rate: Number(this.state.editDeliveryDiscountRate) * 0.01,
                      title: this.state.editDeliveryDiscountTitle,
                      until: this.state.editDeliveryDiscountUntil
                    }).then(() => {
                      this.setState({editDiscountSnack: true})
                    })
                  } else {
                    firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/discount`).set({
                      rate: Number(this.state.editDeliveryDiscountRate) * 0.01,
                      title: this.state.editDeliveryDiscountTitle
                    }).then(() => {
                      this.setState({editDiscountSnack: true})
                    })
                  }

                } else {
                  firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/discount`).remove()
                  .then(() => {
                    this.setState({editDiscountSnack: true})
                  })
                }
              }} style={{marginLeft: 20}} className={"normal-very-small-button direction-column-div"}>
                <span style={{color: 'white', fontSize: 13}}>정보수정</span>
              </div>
            </div>
            <Grid container justify="center">
            { this.state.orderSheets &&
              <div className="auto-width-100">
                { this.state.orderSheets[this.state.openedDeliveryDate] &&
                  <div onClick={() => {
                    if (this.state.orderSheets) {
                      if (this.state.orderSheets[this.state.openedDeliveryDate]) {
                        this.setState({isInsertingDeliveryInvoiceNumbers: true})
                        let deliveryInvoiceNumbers = this.state.deliveryInvoiceNumbers
                        let invoiceNumbersLength = Object.keys(deliveryInvoiceNumbers).length
                        let loopIndex = 0
                        Object.values(deliveryInvoiceNumbers).map((invoiceNumber, index) => {
                          firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}`).once('value', (snapshot) => {
                            if (snapshot.val()) {
                              firebase.database().ref(`users/${invoiceNumber.uid}/sconeOrders/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                              .then(() => {
                                firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/invoiceNumber`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    if (snapshot.val() !== Number(invoiceNumber.invoiceNumber)) {
                                      if (this.state.providerCode !== '06') {
                                        firebase.database().ref(`users/${invoiceNumber.uid}/sconeOrders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                      }
                                    }
                                  } else {
                                    if (this.state.providerCode !== '06') {
                                      firebase.database().ref(`users/${invoiceNumber.uid}/sconeOrders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                    }
                                  }
                                })


                                firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/invoiceNumber`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    if (snapshot.val() !== Number(invoiceNumber.invoiceNumber)) {
                                      firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                      .then(() => {
                                        if (this.state.providerCode !== '06') {
                                          firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                        }
                                        firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
                                          if (snapshot.val()) {
                                            let provider = snapshot.val()[this.state.providerCode].provider
                                            axios({
                                              url: `https://senddeliverytrackingmessage-v2-xv3aig33rq-uc.a.run.app`,
                                              method: "post",
                                              headers: {
                                                "Content-Type":"application/json"
                                              },
                                              data: {
                                                orderId: invoiceNumber.orderId,
                                                uid: invoiceNumber.uid,
                                                invoiceNumber: invoiceNumber.invoiceNumber,
                                                path: 'order',
                                                provider: provider
                                              }
                                            })
                                          }
                                        })
                                        loopIndex = loopIndex + 1
                                        if (invoiceNumbersLength === loopIndex) {
                                          this.setState({isInsertingDeliveryInvoiceNumbers: false, completeInsertingInvoiceNumberSnack: true})
                                        }
                                      })
                                    } else {
                                      loopIndex = loopIndex + 1
                                      if (invoiceNumbersLength === loopIndex) {
                                        this.setState({isInsertingDeliveryInvoiceNumbers: false, completeInsertingInvoiceNumberSnack: true})
                                      }
                                    }
                                  } else {
                                    firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                    .then(() => {
                                      if (this.state.providerCode !== '06') {
                                        firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/orders/${invoiceNumber.orderId}/providerCode`).set(this.state.providerCode)
                                      }

                                      firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
                                        if (snapshot.val()) {
                                          let provider = snapshot.val()[this.state.providerCode].provider
                                          axios({
                                            url: `https://senddeliverytrackingmessage-v2-xv3aig33rq-uc.a.run.app`,
                                            method: "post",
                                            headers: {
                                              "Content-Type":"application/json"
                                            },
                                            data: {
                                              orderId: invoiceNumber.orderId,
                                              uid: invoiceNumber.uid,
                                              invoiceNumber: invoiceNumber.invoiceNumber,
                                              path: 'order',
                                              provider: provider
                                            }
                                          })
                                        }
                                      })
                                      loopIndex = loopIndex + 1
                                      if (invoiceNumbersLength === loopIndex) {
                                        this.setState({isInsertingDeliveryInvoiceNumbers: false, completeInsertingInvoiceNumberSnack: true})
                                      }
                                    })
                                  }
                                })
                              })
                            }
                          })
                        })
                      }
                    }
                  }} className="btn-big" style={{margin: 15}}>
                    { this.state.isInsertingDeliveryInvoiceNumbers
                      ?<PulseLoader  color={'white'} loading={true} size={5} />
                      :<span style={{fontSize: 13, color: 'white'}}>송장번호 입력 완료</span>
                    }
                  </div>
                }
                <div className="direction-column-div">
                  <CSVLink data={this.state.excelData} style={{marginLeft: 20}} headers={headers}>
                    <span>주문서 다운로드</span>
                  </CSVLink>
                  <div style={{marginLeft: 20, marginTop: 5}}>
                  <span  onClick={() => {
                    this.props.history.push('/print_orders/' + this.state.openedDeliveryDate);
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  }} style={{cursor: 'pointer', textDecoration: 'underline'}}>주문서 출력</span>
                  </div>
                  <input type="file" onChange={(event) => {
                    let fileObj = event.target.files[0];

                    //just pass the fileObj as parameter
                    ExcelRenderer(fileObj, (err, resp) => {
                      if(err){
                      } else{
                        let deliveryOrderData = []
                        let deliveryInvoiceNumbers = {}
                        let dataArr = resp.rows
                        const startIndex = (element) => element[3] === '운송장번호'

                        dataArr.splice(0, dataArr.findIndex(startIndex) + 1);

                        let i = 0

                        orders.map((order, index) => {
                          if (order['addressTo']['receiver'].trim() === dataArr[i][6].trim()) {
                            deliveryInvoiceNumbers[order.orderId] = {
                              invoiceNumber: Number(dataArr[i][3]),
                              uid: order.buyerUid,
                              orderId: order.orderId
                            }
                            i = i + 1
                          }

                          if (orders.length === index + 1) {
                            this.setState({deliveryInvoiceNumbers: deliveryInvoiceNumbers})
                          }
                        })
                      }
                    });

                  }} style={{"padding":"10px"}} />
                </div>

              </div>
            }
            </Grid>
            <div className="going-up-down-container">
              <div onClick={() => {
                if (isEdge || isIE) {
                    window.scrollTo(0,  0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
              }} className="going-up-button">
              <ArrowUpwardIcon style={{fontSize: 18}}/>
              </div>
              <div onClick={() => {
                if (isEdge || isIE) {
                    window.scrollTo(0,  document.body.scrollHeight)
                  } else {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
              }} className="going-down-button">
              <ArrowDownwardIcon style={{fontSize: 18}}/>
              </div>
            </div>
          </Grid>
          <Modal
            open={this.state.showDeliveryReviewMessageModal}
            onClose={() => this.setState({showDeliveryReviewMessageModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>리뷰작성 알림을 보내시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showDeliveryReviewMessageModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              this.setState({showDeliveryReviewMessageModal: false})
              this.onSendingReviewMessage()
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>보내기</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showCloseOrderModal}
            onClose={() => this.setState({showCloseOrderModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>정말로 택배를 닫겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showCloseOrderModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              this.setState({showCloseOrderModal: false})
              firebase.database().ref(`orderSheets/${this.state.openedDeliveryDate}/open`).set(false)
              .then(() => {
                this.setState({finishOrdersSnack: true})
              })
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>닫기</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Snackbar open={this.state.startGetDeliveryDataSnack} autoHideDuration={4000} onClose={() => this.setState({startGetDeliveryDataSnack: false})}>
            <Alert  onClose={() => this.setState({startGetDeliveryDataSnack: false})} severity="success">
              배송 조회가 시작되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedPhoneNumberSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPhoneNumberSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPhoneNumberSnack: false})} severity="success">
              전화번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.editDiscountSnack} autoHideDuration={4000} onClose={() => this.setState({editDiscountSnack: false})}>
            <Alert  onClose={() => this.setState({editDiscountSnack: false})} severity="success">
              추가 옵션 내용이 수정되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedOrderIdSnack} autoHideDuration={4000} onClose={() => this.setState({copiedOrderIdSnack: false})}>
            <Alert  onClose={() => this.setState({copiedOrderIdSnack: false})} severity="success">
              주문번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeInsertingInvoiceNumberSnack} autoHideDuration={4000} onClose={() => this.setState({completeInsertingInvoiceNumberSnack: false})}>
            <Alert  onClose={() => this.setState({completeInsertingInvoiceNumberSnack: false})} severity="success">
              송장번호 입력을 완료했습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedEntirePhoneNumbersSnack} autoHideDuration={4000} onClose={() => this.setState({copiedEntirePhoneNumbersSnack: false})}>
            <Alert  onClose={() => this.setState({copiedEntirePhoneNumbersSnack: false})} severity="success">
              전체 전화번호 복사 완료
            </Alert>
          </Snackbar>

          <Snackbar open={this.state.finishOrdersSnack} autoHideDuration={4000} onClose={() => this.setState({finishOrdersSnack: false})}>
            <Alert  onClose={() => this.setState({finishOrdersSnack: false})} severity="success">
              택배 주문이 종료되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeDeliveryMemoSnack} autoHideDuration={4000} onClose={() => this.setState({completeDeliveryMemoSnack: false})}>
            <Alert  onClose={() => this.setState({completeDeliveryMemoSnack: false})} severity="success">
              메모가 저장 되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeDeliveryCancelSnack} autoHideDuration={4000} onClose={() => this.setState({completeDeliveryCancelSnack: false})}>
            <Alert  onClose={() => this.setState({completeDeliveryCancelSnack: false})} severity="success">
              주문 취소 완료
            </Alert>
          </Snackbar>
          <Modal
            open={this.state.showCancelDeliveryModal}
            onClose={() => this.setState({showCancelDeliveryModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 주문을 취소하시겠습니까?</span>
            <Grid container direction="row"  justify="center" style={{marginTop: 40}}>
            <span style={{fontSize: 14, marginTop: 5}}>비밀번호</span>
            <Input autoComplete="off" style={{width: 40,  marginLeft: 20}} className="auto-width-100" onChange={(e) => this.setState({adminPassword: e.target.value})}  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
            InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호"  value={this.state.adminPassword} />
            </Grid>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showCancelDeliveryModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              if (this.state.adminPassword.toString() === '1234') {
                this.setState({showCancelDeliveryModal: false, isDeliveryCanceling: true})
                if (this.state.cancelDeliveryData.buyerUid) {
                  this.onCancelDelivery(this.state.users[this.state.cancelDeliveryData.buyerUid], this.state.cancelDeliveryData)
                } else {
                  firebase.database().ref(`orderSheets/${this.state.cancelDeliveryData.orderSheet.toString()}/orders/${this.state.cancelDeliveryData.orderId}`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      firebase.database().ref(`orderSheets/${this.state.cancelDeliveryData.orderSheet.toString()}/orders/${this.state.cancelDeliveryData.orderId}`).remove()
                      this.setState({isDeliveryCanceling: false, completeDeliveryCancelSnack: true})
                    }
                  })
                }

              }

            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>예약 취소</span>
            </div>
            </Grid>
            </div>
          </Modal>
          { this.state.isOpenUserDetailSheet &&
            <Sheet disableDrag={true} className="sheet-style" isOpen={this.state.isOpenUserDetailSheet} onClose={() => this.setState({isOpenUserDetailSheet: false})}>
            <Sheet.Container className="sheet-style" >
            <Sheet.Header className="sheet-header">
              <div className="text-center">
                <span style={{fontWeight: 'bold', fontSize: 18}}>회원 정보</span>
              </div>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20}}
                onClick={() => {
                  this.setState({
                    isOpenUserDetailSheet: false
                  })
                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
              </div>
            </Sheet.Header>
              <Sheet.Content className="auto-width-100 relative-position">
              <Grid className="auto-width-100 center-align" container justify="center" direction="column" style={{marginTop: 20, marginBottom: 20}}>
                <Image style={{width: 100, height: 100, borderRadius: 100}} src={this.state.selectedUserInfo['photoURL']} />
              </Grid>
              <Grid className="auto-width-100 center-align" container justify="center" direction="column">
                <span style={{marginTop: 5, fontSize: 13, fontWeight: 'bold'}}>{this.state.selectedUserInfo['username']}</span>
              </Grid>
              <Grid className="auto-width-100 center-align" container justify="center" direction="column">
                <span style={{marginTop: 5, fontSize: 12}}>{this.state.selectedUserInfo['email']}</span>
              </Grid>
              <Grid className="auto-width-100 center-align" container justify="center" direction="column" style={{marginTop: 20, marginBottom: 10}}>
                <span style={{fontWeight: 'bold'}}>주문 내역</span>
              </Grid>
              { this.state.selectedUserInfo['sconeOrders'] &&
                <div style={{padding: 20}}>
                  { this.onGetNumberOfPurchase(this.state.selectedUserInfo['sconeOrders']) &&
                    <div className="direction-column-div">
                      { this.onGetPurchasedOrders(this.state.selectedUserInfo['sconeOrders']).map((order, index) => {
                        return (
                          <div>
                          <span onClick={() => {
                            let selectedUserInfo = this.state.selectedUserInfo
                            if (selectedUserInfo['showOrderId']) {
                              if (selectedUserInfo['showOrderId'][order.orderId]) {
                                delete selectedUserInfo['showOrderId'][order.orderId]
                              } else {
                                selectedUserInfo['showOrderId'][order.orderId] = true
                              }
                              this.setState({selectedUserInfo: selectedUserInfo})
                              
                            } else {
                              selectedUserInfo['showOrderId'] = {}
                              selectedUserInfo['showOrderId'][order.orderId] = true
                              
                              this.setState({selectedUserInfo: selectedUserInfo})
                            }
                          }} style={{marginTop: 5, fontSize: 13, fontWeight: 'bold', marginBottom: 15, cursor: 'pointer'}}>{this.onStringNumberToDate(order.orderSheet.toString())}</span>
                          { this.state.selectedUserInfo['showOrderId'] &&
                            <div>
                              { this.state.selectedUserInfo['showOrderId'][order.orderId] &&
                                <div>
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>주문 번호</span>
                                  <span style={{fontSize: 12}}>{order.orderId}</span>
                                  <FontAwesomeIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(order.orderId)
                                    alert('주문번호 복사 완료')
                                  }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3, cursor: 'pointer'}} icon={faClone} />
                                </div>
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>결제 시간</span>
                                  <span style={{fontSize: 12}}>{this.onGetTime(new Date(order.paidAt))}</span>
                                </div>
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span style={{fontSize: 12, width: 75}}>결제 금액</span>
                                  <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.paidPrice)}원</span>
                                </div>
                                { order.usingCoupon &&
                                  <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                    <span style={{fontSize: 12, width: 75}}>쿠폰 금액</span>
                                    <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(order.usingCoupon.amount)}원 할인</span>
                                  </div>
                                }
                                <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                  <span className="word-break" style={{fontSize: 12, width: 90}}>스콘 종류</span>
                                  <div className="auto-width-100 direction-column-div">
                                    { this.sortByOrder(Object.values(order.items)).map((item, index) => {
                                      if (this.state.selectedItem === item.itemId) {
                                        return (
                                          <div className="direction-row-div">
                                            <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                            <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div className="direction-row-div">
                                            <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                          </div>
                                        )
                                      }

                                    })
                                    }
                                    <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {order.totalAmount}개]</span>
                                  </div>
                                </div>
                                { order.additionalItems &&
                                  <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                                    <span className="word-break" style={{fontSize: 12, width: 90}}>추가 상품</span>
                                    <div className="auto-width-100 direction-column-div">
                                      { Object.values(order.additionalItems).map((item, index) => {
                                        if (this.state.selectedItem === item.itemId) {
                                          return (
                                            <div className="direction-row-div">
                                              <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                              <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                            </div>
                                          )
                                        } else {
                                          return (
                                            <div className="direction-row-div">
                                              <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                            </div>
                                          )
                                        }

                                      })
                                      }
                                      <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {this.onGetTotalAmount(order.additionalItems)}개]</span>
                                    </div>
                                  </div>
                                }
                                </div>
                              }
                            </div>
                          }

                          </div>
                        )
                      })
                      }
                    </div>
                  }
                </div>
              }
              <Grid className="auto-width-100" style={{padding: 20}} container direction="column">
                <div style={{flexDirection: 'row'}}>
                  <span style={{marginTop: 5, fontSize: 16, fontWeight: 'bold'}}>회원 메모</span>
                </div>

                { isMobileOnly
                  ?<Input autoComplete="off" multiline={true} rows={2} style={{width: 150}} onClick={(e) => {
                    e.stopPropagation();
                  }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                    if (event.target.value) {
                      this.setState({tempMemo: event.target.value})
                    } else {
                      this.setState({tempMemo: ''})
                    }
                  }}
                  InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.tempMemo} />
                  :<Input autoComplete="off" multiline={true} rows={2} style={{width: 300}} onClick={(e) => {
                    e.stopPropagation();
                  }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                    if (event.target.value) {
                      this.setState({tempMemo: event.target.value})
                    } else {
                      this.setState({tempMemo: ''})
                    }
                  }}
                  InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.tempMemo} />
                }

                <div onClick={(e) => {
                  e.stopPropagation();
                  if (this.state.tempMemo) {
                    firebase.database().ref(`users/${this.state.selectedUserInfo['uid']}/memo`).set(this.state.tempMemo)
                    .then(() => {
                      alert('메모가 저장되었습니다.')
                    })
                  } else {
                    firebase.database().ref(`users/${this.state.selectedUserInfo['uid']}/memo`).remove()
                    .then(() => {
                      alert('메모가 제거되었습니다.')
                    })
                  }
                }} className="normal-very-small-button none-underline-text align-center" style={{width: 48, marginTop: 10}}>
                  <span style={{fontSize: 11, color: 'white'}}>메모 저장</span>
                </div>

                </Grid>
              
              </Sheet.Content>
              </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
          }

        </Grid>
      )
    }

    if (this.state.isOpenGiftSetPickupList) {
      let pickupData = this.state.pickupOpen[this.state.openedGiftSetDate]
      if (!pickupData.giftSetPickups) {
        this.setState({isOpenGiftSetPickupList: false})
      }
      let pickups = Object.values(pickupData.giftSetPickups)

      pickups.sort((a, b) => {
        return Number(a.pickupTime['minute']) - Number(b.pickupTime['minute'])
      })
      pickups.sort((a, b) => {
        return Number(a.pickupTime['hour']) - Number(b.pickupTime['hour'])
      })

      let totalIncome = 0
      pickups.map((pickup, index) => {
        totalIncome = totalIncome + pickup.paidPrice
      })


      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="big-back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>선물세트 픽업 예약 리스트</span>
                <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 5}}>{this.onDateNumberToDate(this.state.openedGiftSetDate)} 예약건</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenGiftSetPickupList: false, openedGiftSetDate: null, additionalItems: {}})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevGiftSetPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevGiftSetPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 120}} className="auto-width-100 direction-column-div">
              { pickups.map((pickup, index) => {

                return (
                  <Grid className="auto-width-100 direction-column-div">
                    <Grid onClick={() => {
                      let selectedGiftSetPickupId = this.state.selectedGiftSetPickupId
                      if (selectedGiftSetPickupId === pickup.pickupId) {
                        selectedGiftSetPickupId = null
                      } else {
                        selectedGiftSetPickupId = pickup.pickupId
                      }
                      this.setState({selectedGiftSetPickupId: selectedGiftSetPickupId})

                    }} className="direction-row-div" style={{padding: 20, paddingTop: 10, paddingBottom: 10, cursor: 'pointer'}}>
                      <Grid className="auto-width-100 direction-column-div">
                      <div className="direction-row-div">
                      <span style={{fontSize: 15, fontWeight: 'bold'}}>[{pickup.buyerName}님]</span>
                      { pickup.buyerUid &&
                        <span style={{fontSize: 14, marginLeft: 3}}>{this.onCountPickups(pickup.buyerUid)}</span>
                      }
                      { pickup['done'] &&
                        <span style={{fontSize: 11, marginLeft: 3, color: 'red', marginTop: 4}}>픽업 완료</span>
                      }
                      { pickup.buyerUid &&
                        <div>
                        { this.state.users[pickup.buyerUid].giftSetPickups
                          ?!this.state.users[pickup.buyerUid].giftSetPickups[pickup.pickupId] &&
                          <span onClick={() => {
                            firebase.database().ref(`users/${pickup.buyerUid}`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.database().ref(`users/${pickup.buyerUid}/giftSetPickups/${pickup.pickupId}`).set(pickup)
                              }
                            })
                          }} style={{fontSize: 11, marginLeft: 3, color: 'blue', marginTop: 4, cursor: 'pointer'}}>데이터 복구</span>
                          :<span onClick={() => {
                            firebase.database().ref(`users/${pickup.buyerUid}`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.database().ref(`users/${pickup.buyerUid}/giftSetPickups/${pickup.pickupId}`).set(pickup)
                              }
                            })
                          }} style={{fontSize: 11, marginLeft: 3, color: 'blue', marginTop: 4, cursor: 'pointer'}}>데이터 복구</span>
                        }
                        </div>
                      }


                      </div>
                      <span style={{fontSize: 13, marginTop: 2, marginBottom: 5}}>{pickup.pickupTime.hour}시 {pickup.pickupTime.minute}분 픽업</span>
                      <span style={{fontSize: 12}}>선물세트 {pickup.totalAmount}개</span>
                      { this.state.selectedGiftSetPickupId === pickup.pickupId &&
                        <div className="auto-width-100 direction-column-div">
                          <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>예약 상세</span>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>휴대폰 번호</span>
                            <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(pickup.phoneNumber)}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(pickup.phoneNumber)
                              this.setState({copiedPhoneNumberSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>주문 번호</span>
                            <span style={{fontSize: 12}}>{pickup.pickupId}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(pickup.pickupId)
                              this.setState({copiedPickupIdSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>결제 시간</span>
                            <span style={{fontSize: 12}}>{this.onGetTime(new Date(pickup.paidAt))}</span>
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>결제 금액</span>
                            <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(pickup.paidPrice)}원</span>
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>요청 사항</span>
                            { pickup.request
                              ?<span className="content-style" style={{fontSize: 12}}>{pickup.request}</span>
                              :<span style={{fontSize: 12}}>없음</span>
                            }

                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>보냉백 추가</span>
                            { pickup.numberOfCoolings
                              ?<div className="direction-row-div">
                              { pickup.numberOfCoolings === '0'
                                ?<span className="content-style" style={{fontSize: 12}}>추가 안함</span>
                                :<span style={{fontSize: 12}}>{pickup.numberOfCoolings}개 추가</span>
                              }
                              </div>
                              :<div className="direction-row-div">
                              <span className="content-style" style={{fontSize: 12}}>추가 안함</span>
                              </div>
                            }

                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span className="word-break" style={{fontSize: 12, width: 90}}>세트 종류</span>
                            <div className="auto-width-100 direction-column-div">
                              { Object.values(pickup.items).map((item, index) => {
                                return (
                                  <div className="direction-row-div">
                                    <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                  </div>
                                )
                              })
                              }
                              <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {pickup.totalAmount}개]</span>
                            </div>
                          </div>
                        </div>
                      }
                      </Grid>
                      { this.state.selectedGiftSetPickupId === pickup.pickupId
                        ?<KeyboardArrowUpIcon style={{marginTop: 17}}/>
                        :<KeyboardArrowDownIcon style={{marginTop: 17}}/>
                      }

                    </Grid>
                    { pickups.length !== index + 1 &&
                      <Divider style={{marginTop: 5}}/>
                    }

                  </Grid>
                )
              })
              }
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            </Grid>
          </Grid>
          <Snackbar open={this.state.completePickupCancelSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupCancelSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupCancelSnack: false})} severity="success">
              픽업 취소 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedPickupIdSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPickupIdSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPickupIdSnack: false})} severity="success">
              주문번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completePickupSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupSnack: false})} severity="success">
              {this.state.completePickupBuyerName}님의 픽업이 완료되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.editPickupDiscountSnack} autoHideDuration={4000} onClose={() => this.setState({editPickupDiscountSnack: false})}>
            <Alert  onClose={() => this.setState({editPickupDiscountSnack: false})} severity="success">
              추가 옵션 내용이 수정되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.sentConfirmMessageSnack} autoHideDuration={4000} onClose={() => this.setState({sentConfirmMessageSnack: false})}>
            <Alert  onClose={() => this.setState({sentConfirmMessageSnack: false})} severity="success">
              예약 확인 카톡 전송 완료!
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedPhoneNumberSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPhoneNumberSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPhoneNumberSnack: false})} severity="success">
              전화번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completePickupMemoSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupMemoSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupMemoSnack: false})} severity="success">
              메모가 저장 되었습니다.
            </Alert>
          </Snackbar>
          <Modal
            open={this.state.showCancelPickupModal}
            onClose={() => this.setState({showCancelPickupModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 픽업 예약을 취소하시겠습니까?</span>
            <Grid container direction="row"  justify="center" style={{marginTop: 40}}>
            <span style={{fontSize: 14, marginTop: 5}}>비밀번호</span>
            <Input autoComplete="off" style={{width: 40,  marginLeft: 20}} className="auto-width-100" onChange={(e) => this.setState({adminPassword: e.target.value})}  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
            InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호"  value={this.state.adminPassword} />
            </Grid>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showCancelPickupModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              if (this.state.adminPassword.toString() === '1234') {
                this.setState({showCancelPickupModal: false, isPickupCanceling: true})
                if (this.state.cancelPickupData.buyerUid) {
                  this.onCancelPickup(this.state.users[this.state.cancelPickupData.buyerUid], this.state.cancelPickupData)
                } else {
                  firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/pickups/${this.state.cancelPickupData.pickupId}`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/pickups/${this.state.cancelPickupData.pickupId}`).remove()
                      firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/amount`).once('value', (snapshot) => {
                        firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/amount`).set(snapshot.val() + this.state.cancelPickupData.totalAmount)
                      })
                      this.setState({isPickupCanceling: false, completePickupCancelSnack: true})
                    }
                  })
                }

              }

            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>예약 취소</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showSendPickupMessageModal}
            onClose={() => this.setState({showSendPickupMessageModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>픽업 예약 확인 카톡을 전송하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showSendPickupMessageModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              this.setState({showSendPickupMessageModal: false})
              firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/sentConfirmMessage`).set(true)
              pickups.map((pickup, index) => {
                if (pickup.buyerUid) {
                  axios({
                    url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendPickupConfirmMessage`,
                    method: "post",
                    headers: {
                      "Content-Type":"application/json"
                    },
                    data: {
                      pickupId: pickup.pickupId,
                      uid: pickup.buyerUid
                    }
                  })
                }

                if (pickups.length === index + 1) {
                  this.setState({sentConfirmMessageSnack: true})
                }
              })

            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>전송</span>
            </div>
            </Grid>
            </div>
          </Modal>
        </Grid>
      )

    }

    if (this.state.isOpenPickupList) {
      let pickupData = this.state.pickupOpen[this.state.openedDate]
      if (!pickupData.pickups) {
        this.setState({isOpenPickupList: false})
      }
      let pickups = Object.values(pickupData.pickups)

      pickups.sort((a, b) => {
        return Number(a.pickupTime['minute']) - Number(b.pickupTime['minute'])
      })
      pickups.sort((a, b) => {
        return Number(a.pickupTime['hour']) - Number(b.pickupTime['hour'])
      })

      let totalIncome = 0
      pickups.map((pickup, index) => {
        totalIncome = totalIncome + pickup.paidPrice
      })


      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="big-back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>픽업 예약 리스트</span>
                <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 5}}>{this.onDateNumberToDate(this.state.openedDate)} 예약건</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenPickupList: false, openedDate: null, additionalItems: {}})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 120}} className="auto-width-100 direction-column-div">
              { pickups.map((pickup, index) => {

                return (
                  <Grid className="auto-width-100 direction-column-div">
                    <Grid onClick={() => {
                      let selectedPickupId = this.state.selectedPickupId
                      if (selectedPickupId === pickup.pickupId) {
                        selectedPickupId = null
                      } else {
                        selectedPickupId = pickup.pickupId
                      }
                      this.setState({selectedPickupId: selectedPickupId})

                    }} className="direction-row-div" style={{padding: 20, paddingTop: 10, paddingBottom: 10, cursor: 'pointer'}}>
                      <Grid className="auto-width-100 direction-column-div">
                      <div className="direction-row-div">
                      { pickup.items[this.state.selectedScone]
                        ?<FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginRight: 5, marginTop: 2}} icon={faCheck} />
                        :pickup.additionalItems &&
                        pickup.additionalItems[this.state.selectedAdditiionalItem] &&
                        <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginRight: 5, marginTop: 2}} icon={faCheck} />

                      }

                      <span style={{fontSize: 15, fontWeight: 'bold'}}>[{pickup.buyerName}님]</span>
                      { pickup.buyerUid &&
                        <span style={{fontSize: 14, marginLeft: 3}}>{this.onCountPickups(pickup.buyerUid)}</span>
                      }

                      { pickup['done'] &&
                        <span style={{fontSize: 11, marginLeft: 3, color: 'red', marginTop: 4}}>픽업 완료</span>
                      }
                      { this.state.pickupMemo[pickup.pickupId] &&
                        <span style={{fontSize: 11, marginTop: 3, marginLeft: 5, color: 'red', fontWeight: 'bold'}}>메모</span>
                      }
                      
                      { pickup.buyerUid &&
                        <div>
                        { this.state.users[pickup.buyerUid].sconePickups
                          ?!this.state.users[pickup.buyerUid].sconePickups[pickup.pickupId] &&
                          <span onClick={() => {
                            firebase.database().ref(`users/${pickup.buyerUid}`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.database().ref(`users/${pickup.buyerUid}/sconePickups/${pickup.pickupId}`).set(pickup)
                              }
                            })
                          }} style={{fontSize: 11, marginLeft: 3, color: 'blue', marginTop: 4, cursor: 'pointer'}}>데이터 복구</span>
                          :<span onClick={() => {
                            firebase.database().ref(`users/${pickup.buyerUid}`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.database().ref(`users/${pickup.buyerUid}/sconePickups/${pickup.pickupId}`).set(pickup)
                              }
                            })
                          }} style={{fontSize: 11, marginLeft: 3, color: 'blue', marginTop: 4, cursor: 'pointer'}}>데이터 복구</span>
                        }
                        </div>
                      }


                      </div>
                      <span style={{fontSize: 13, marginTop: 2, marginBottom: 5}}>{pickup.pickupTime.hour}시 {pickup.pickupTime.minute}분 픽업</span>
                      <span style={{fontSize: 12}}>스콘 {pickup.totalAmount}개</span>
                      { this.state.giftBoxes[pickup.pickupId] &&
                        <span style={{fontSize: 12, marginTop: 2, fontWeight: 'bold', color: '#4287f5'}}>디저트박스 {this.state.giftBoxes[pickup.pickupId]}개</span>
                      }
                      { this.state.selectedPickupId === pickup.pickupId &&
                        <div className="auto-width-100 direction-column-div">
                          <span style={{fontSize: 14, fontWeight: 'bold', marginTop: 4, marginBottom: 5}}>예약 상세</span>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>휴대폰 번호</span>
                            <span style={{fontSize: 12}}>{this.onChangePhoneNumberFormat(pickup.phoneNumber)}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(pickup.phoneNumber)
                              this.setState({copiedPhoneNumberSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>주문 번호</span>
                            <span style={{fontSize: 12}}>{pickup.pickupId}</span>
                            <FontAwesomeIcon onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(pickup.pickupId)
                              this.setState({copiedPickupIdSnack: true})
                            }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faClone} />
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>결제 시간</span>
                            <span style={{fontSize: 12}}>{this.onGetTime(new Date(pickup.paidAt))}</span>
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>결제 금액</span>
                            <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(pickup.paidPrice)}원</span>
                          </div>

                          { pickup.staffDiscount &&
                            <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>직원 할인</span>
                            <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(parseInt(pickup.paidPrice * (pickup.staffDiscount * 0.01)))}원 할인 ({pickup.staffDiscount}%)</span>
                            </div>
                          }
                          { pickup.usingCoupon &&
                            <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                              <span style={{fontSize: 12, width: 75}}>쿠폰 금액</span>
                              <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(pickup.usingCoupon.amount)}원 할인</span>

                            </div>
                          }
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>요청 사항</span>
                            { pickup.request
                              ?<span className="content-style" style={{fontSize: 12}}>{pickup.request}</span>
                              :<span style={{fontSize: 12}}>없음</span>
                            }

                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span style={{fontSize: 12, width: 75}}>선물 포장</span>
                            { pickup.giftBox
                              ?<span style={{fontSize: 12}}>디저트 박스 {pickup.giftBox}개</span>
                              :<span style={{fontSize: 12}}>없음</span>
                            }
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                            <span className="word-break" style={{fontSize: 12, width: 90}}>스콘 종류</span>
                            <div className="auto-width-100 direction-column-div">
                              { this.sortByOrder(Object.values(pickup.items)).map((item, index) => {
                                if (this.state.selectedScone === item.itemId) {
                                  return (
                                    <div className="direction-row-div">
                                      <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                      <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                    </div>
                                  )
                                } else {
                                  return (
                                    <div className="direction-row-div">
                                      <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                    </div>
                                  )
                                }

                              })
                              }
                              <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {pickup.totalAmount}개]</span>
                            </div>
                          </div>
                          <div className="auto-width-100 direction-row-div" style={{marginBottom: 15}}>
                            <span className="word-break" style={{fontSize: 12, width: 90}}>추가 상품</span>
                            { pickup.additionalItems
                              ?<div className="auto-width-100 direction-column-div">
                                { Object.values(pickup.additionalItems).map((item, index) => {
                                  if (this.state.selectedAdditiionalItem === item.itemId) {
                                    return (
                                      <div className="direction-row-div">
                                        <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold', color: '#006042'}}>{item.name} {item.amount}개</span>
                                        <FontAwesomeIcon style={{color: '#006042', fontSize: 15, marginLeft: 10, marginTop: 1}} icon={faHandPointLeft} />
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div className="direction-row-div">
                                        <span style={{fontSize: 12, marginBottom: 5, fontWeight: 'bold'}}>{item.name} {item.amount}개</span>
                                      </div>
                                    )
                                  }

                                })
                                }
                                <span style={{fontSize: 13, marginBottom: 5, fontWeight: 'bold'}}>[총 {pickup.additionalItemsAmount}개]</span>
                              </div>
                              :<div className="auto-width-100 direction-column-div">
                              <span style={{fontSize: 12}}>없음</span>
                              </div>
                            }

                          </div>
                          { isMobileOnly
                            ?<Input autoComplete="off" multiline={true} rows={2} style={{width: 150, marginLeft: 75}} onClick={(e) => {
                              e.stopPropagation();
                            }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                              let memo = this.state.pickupMemo
                              if (event.target.value) {
                                memo[pickup.pickupId] = event.target.value
                              } else {
                                delete memo[pickup.pickupId]
                              }
                              this.setState({pickupMemo: memo})
                            }}
                            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.pickupMemo[pickup.pickupId] ? this.state.pickupMemo[pickup.pickupId] : ''} />
                            :<Input autoComplete="off" multiline={true} rows={2} style={{width: 300, marginLeft: 75}} onClick={(e) => {
                              e.stopPropagation();
                            }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                              let memo = this.state.pickupMemo
                              if (event.target.value) {
                                memo[pickup.pickupId] = event.target.value
                              } else {
                                delete memo[pickup.pickupId]
                              }
                              this.setState({pickupMemo: memo})
                            }}
                            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="메모"  value={this.state.pickupMemo[pickup.pickupId] ? this.state.pickupMemo[pickup.pickupId] : ''} />
                          }
                          <div onClick={(e) => {
                            e.stopPropagation();
                            let memo = this.state.pickupMemo
                            if (memo[pickup.pickupId]) {
                              firebase.database().ref(`pickupOpen/${this.state.openedDate}/pickups/${pickup.pickupId}/memo`).set(memo[pickup.pickupId])
                              .then(() => {
                                this.setState({completePickupMemoSnack: true})
                              })
                            } else {
                              firebase.database().ref(`pickupOpen/${this.state.openedDate}/pickups/${pickup.pickupId}/memo`).remove()
                              .then(() => {
                                this.setState({completePickupMemoSnack: true})
                              })
                            }


                          }} className="normal-very-small-button none-underline-text align-center" style={{width: 48, marginLeft: 75, marginTop: 10, marginBottom: 15}}>
                            <span style={{fontSize: 11, color: 'white'}}>메모 저장</span>
                          </div>
                          <div onClick={(e) => {
                            e.stopPropagation();
                            if (this.state.pickupOpen[this.state.openedDate].pickups[pickup.pickupId]['done']) {
                              firebase.database().ref(`pickupOpen/${pickup.pickupDate}/pickups/${pickup.pickupId}/done`).remove()
                              if (pickup.buyerUid) {
                                firebase.database().ref(`/users/${pickup.buyerUid}/sconePickups/${pickup.pickupId}/done`).remove()
                              }

                              let pickupOpenObj = this.state.pickupOpen
                              delete pickupOpenObj[this.state.openedDate].pickups[pickup.pickupId]['done']
                              this.setState({pickupOpen: pickupOpenObj})
                            } else {
                              firebase.database().ref(`pickupOpen/${pickup.pickupDate}/pickups/${pickup.pickupId}/done`).set(true)
                              if (pickup.buyerUid) {
                                firebase.database().ref(`/users/${pickup.buyerUid}/sconePickups/${pickup.pickupId}/done`).set(true)
                              }

                              let pickupOpenObj = this.state.pickupOpen
                              pickupOpenObj[this.state.openedDate].pickups[pickup.pickupId]['done'] = true
                              this.setState({pickupOpen: pickupOpenObj, completePickupBuyerName: pickup.buyerName, completePickupSnack: true})
                            }

                          }} style={{marginLeft: 75}} className={pickup['done'] ? "normal-very-small-button-outlined" : "normal-very-small-button"}>
                            { pickup['done']
                              ?<span style={{color: '#006042', fontSize: 12}}>픽업 완료 취소</span>
                              :<span style={{color: 'white', fontSize: 12}}>픽업 완료</span>
                            }

                          </div>
                          <div
                            item
                            className="no-selection horiaontal-center-align"
                            style={{marginLeft: 70, marginTop: 10, cursor: 'pointer'}}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({showCancelPickupModal: true, cancelPickupData: pickup})
                            }}
                          >
                            <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
                            <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>예약 취소</span>
                          </div>
                        </div>
                      }
                      </Grid>
                      { this.state.selectedPickupId === pickup.pickupId
                        ?<KeyboardArrowUpIcon style={{marginTop: 17}}/>
                        :<KeyboardArrowDownIcon style={{marginTop: 17}}/>
                      }

                    </Grid>
                    { pickups.length !== index + 1 &&
                      <Divider style={{marginTop: 5}}/>
                    }

                  </Grid>
                )
              })
              }
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <div className="direction-row-div">
                  <span style={{fontWeight: 'bold', marginLeft: 20}}>총 수량</span>
                  { this.state.numberOfGiftBoxes !== 0 &&
                    <span style={{marginLeft: 10, fontSize: 13, marginTop: 2, fontWeight: 'bold'}}>(디저트박스 {this.state.numberOfGiftBoxes}개)</span>
                  }

                </div>
                <Divider className="auto-width-100" style={{marginTop: 20, }} />
                <div style={{margin: 20}}>
                { Object.values(this.state.eachAmount).length !== 0 &&
                  <div>
                    { this.sortByOrder(Object.values(this.state.eachAmount)).map((each, index) => {
                      return (
                        <div onClick={() => {
                          if (this.state.selectedScone === each.id) {
                            this.setState({selectedScone: null})
                          } else {
                            this.setState({selectedScone: each.id})
                          }
                        }} className="direction-row-div" style={{marginBottom: 5, cursor: 'pointer'}}>
                          { this.state.selectedScone === each.id
                            ?<span className="one-line" style={{fontSize: 13, width: 200, marginRight: 10, fontWeight: 'bold', color: '#006042'}}>{each.name}</span>
                            :<span className="one-line" style={{fontSize: 13, width: 200, marginRight: 10}}>{each.name}</span>
                          }

                          <span style={{fontSize: 13, fontWeight: 'bold'}}>{each.amount}개</span>
                        </div>
                      )
                    })
                    }
                  </div>
                }
                { Object.values(this.state.additionalItems).length !== 0 &&
                  <div style={{marginTop: 20}}>
                    { Object.values(this.state.additionalItems).map((each, index) => {
                      return (
                        <div onClick={() => {
                          if (this.state.selectedAdditiionalItem === each.id) {
                            this.setState({selectedAdditiionalItem: null})
                          } else {
                            this.setState({selectedAdditiionalItem: each.id})
                          }
                        }} className="direction-row-div" style={{marginBottom: 5, cursor: 'pointer'}}>
                          { this.state.selectedAdditiionalItem === each.id
                            ?<span className="one-line" style={{fontSize: 13, width: 200, marginRight: 10, fontWeight: 'bold', color: '#006042'}}>{each.name}</span>
                            :<span className="one-line" style={{fontSize: 13, width: 200, marginRight: 10}}>{each.name}</span>
                          }

                          <span style={{fontSize: 13, fontWeight: 'bold'}}>{each.amount}개</span>
                        </div>
                      )
                    })
                    }
                  </div>
                }
                <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10 }} />
                <div className="direction-row-div" style={{marginBottom: 5}}>
                  <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>픽업 스콘 총 수량</span>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>총 {this.state.totalPickupScones}개</span>
                </div>
                <div className="direction-row-div" style={{marginBottom: 5}}>
                  <span className="one-line" style={{fontSize: 14, width: 200, marginRight: 10}}>픽업 매출</span>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(totalIncome)}원</span>
                </div>

                <div style={{marginTop: 20}} onClick={(e) => {
                  e.stopPropagation();
                  firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/sentConfirmMessage`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                    } else {
                      this.setState({showSendPickupMessageModal: true})
                    }
                  })

                }} className={pickupData.sentConfirmMessage ? "normal-very-small-button-disabled direction-column-div" : "normal-very-small-button direction-column-div"}>
                  <span style={{color: 'white', fontSize: 12}}>예약 확인</span>
                  <span style={{color: 'white', fontSize: 12}}>카톡 전송</span>
                </div>
                <div style={{marginTop: 20}} onClick={(e) => {
                  e.stopPropagation();
                  this.setState({isOpenSheet: true, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: '', onSitePickupDate: this.state.pickupOpen[this.state.openedDate]})
                  this.setState({isOpenPickupList: false, openedDate: null, additionalItems: {}})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)
                }} className={"normal-very-small-button direction-column-div"}>
                  <span style={{color: 'white', fontSize: 12}}>오프라인</span>
                  <span style={{color: 'white', fontSize: 12}}>픽업예약</span>
                </div>
                </div>
                <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10 }} />
                <div style={{padding: 20}} className="direction-column-div">
                <span style={{fontSize: 12, width: 200, color: 'gray', paddingTop: 10, paddingBottom: 10}}>추가 옵션</span>
                <TextField style={{width: 200, marginBottom: 15}} onChange={(event) => {
                  this.setState({editRemainAmount: Number(event.target.value.replace(/[^0-9]/g, ''))})
                }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="잔여 수량"  value={this.state.editRemainAmount} />
                <div style={{marginBottom: 10}}>
                  <label>
                    <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                            onChange={() => this.setState({editApplyDiscount: !this.state.editApplyDiscount})} checked={this.state.editApplyDiscount} />
                  </label>
                  <span style={{fontSize: 12, marginLeft: 10}}>할인 적용</span>
                </div>
                { this.state.editApplyDiscount &&
                  <div style={{paddingBottom: 10}} className="direction-column-div">
                    <TextField style={{width: 200}} onChange={(event) => {
                      this.setState({editDiscountTitle: event.target.value})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인 이름"  value={this.state.editDiscountTitle} />
                    <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                      this.setState({editDiscountRate: Number(event.target.value.replace(/[^0-9]/g, ''))})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인율 (%)"  value={this.state.editDiscountRate} />
                    <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                      this.setState({editMinDiscountPrice: Number(event.target.value.replace(/[^0-9]/g, ''))})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="최소 금액"  value={this.state.editMinDiscountPrice} />
                  </div>
                }
                <div onClick={() => {
                  if (this.state.editRemainAmount) {
                    firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/amount`).set(Number(this.state.editRemainAmount))
                  }
                  if (this.state.editApplyDiscount) {
                    firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/discount`).set({
                      minDiscountPrice: Number(this.state.editMinDiscountPrice),
                      rate: Number(this.state.editDiscountRate) * 0.01,
                      title: this.state.editDiscountTitle
                    })
                  } else {
                    firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/discount`).remove()
                  }
                  this.setState({editPickupDiscountSnack: true})

                }} className={"normal-very-small-button direction-column-div"}>
                  <span style={{color: 'white', fontSize: 13}}>정보수정</span>
                </div>
                </div>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            </Grid>
          </Grid>
          <Snackbar open={this.state.completePickupCancelSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupCancelSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupCancelSnack: false})} severity="success">
              픽업 취소 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedPickupIdSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPickupIdSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPickupIdSnack: false})} severity="success">
              주문번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completePickupSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupSnack: false})} severity="success">
              {this.state.completePickupBuyerName}님의 픽업이 완료되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.editPickupDiscountSnack} autoHideDuration={4000} onClose={() => this.setState({editPickupDiscountSnack: false})}>
            <Alert  onClose={() => this.setState({editPickupDiscountSnack: false})} severity="success">
              추가 옵션 내용이 수정되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.sentConfirmMessageSnack} autoHideDuration={4000} onClose={() => this.setState({sentConfirmMessageSnack: false})}>
            <Alert  onClose={() => this.setState({sentConfirmMessageSnack: false})} severity="success">
              예약 확인 카톡 전송 완료!
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.copiedPhoneNumberSnack} autoHideDuration={4000} onClose={() => this.setState({copiedPhoneNumberSnack: false})}>
            <Alert  onClose={() => this.setState({copiedPhoneNumberSnack: false})} severity="success">
              전화번호 복사 완료
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completePickupMemoSnack} autoHideDuration={4000} onClose={() => this.setState({completePickupMemoSnack: false})}>
            <Alert  onClose={() => this.setState({completePickupMemoSnack: false})} severity="success">
              메모가 저장 되었습니다.
            </Alert>
          </Snackbar>
          <Modal
            open={this.state.showCancelPickupModal}
            onClose={() => this.setState({showCancelPickupModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 픽업 예약을 취소하시겠습니까?</span>
            <Grid container direction="row"  justify="center" style={{marginTop: 40}}>
            <span style={{fontSize: 14, marginTop: 5}}>비밀번호</span>
            <Input autoComplete="off" style={{width: 40,  marginLeft: 20}} className="auto-width-100" onChange={(e) => this.setState({adminPassword: e.target.value})}  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
            InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호"  value={this.state.adminPassword} />
            </Grid>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showCancelPickupModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              if (this.state.adminPassword.toString() === '1234') {
                this.setState({showCancelPickupModal: false, isPickupCanceling: true})
                if (this.state.cancelPickupData.buyerUid) {
                  this.onCancelPickup(this.state.users[this.state.cancelPickupData.buyerUid], this.state.cancelPickupData)
                } else {
                  firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/pickups/${this.state.cancelPickupData.pickupId}`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/pickups/${this.state.cancelPickupData.pickupId}`).remove()
                      firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/amount`).once('value', (snapshot) => {
                        firebase.database().ref(`pickupOpen/${this.state.cancelPickupData.pickupDate.toString()}/amount`).set(snapshot.val() + this.state.cancelPickupData.totalAmount)
                      })
                      this.setState({isPickupCanceling: false, completePickupCancelSnack: true})
                    }
                  })
                }

              }

            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>예약 취소</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showSendPickupMessageModal}
            onClose={() => this.setState({showSendPickupMessageModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>픽업 예약 확인 카톡을 전송하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showSendPickupMessageModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              this.setState({showSendPickupMessageModal: false})
              firebase.database().ref(`/pickupOpen/${pickupData.date.toString()}/sentConfirmMessage`).set(true)
              pickups.map((pickup, index) => {
                if (pickup.buyerUid) {
                  axios({
                    url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendPickupConfirmMessage`,
                    method: "post",
                    headers: {
                      "Content-Type":"application/json"
                    },
                    data: {
                      pickupId: pickup.pickupId,
                      uid: pickup.buyerUid
                    }
                  })
                }

                if (pickups.length === index + 1) {
                  this.setState({sentConfirmMessageSnack: true})
                }
              })

            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>전송</span>
            </div>
            </Grid>
            </div>
          </Modal>
        </Grid>
      )

    }
    if (this.state.showUserDetail) {
      let userDetailInfo = this.state.userDetailInfo
      let sconeOrders = []
      let itemOrders = []
      let sconePickups = []

      if (userDetailInfo.sconeOrders) {
        sconeOrders = Object.values(userDetailInfo.sconeOrders)
        sconeOrders = sconeOrders.filter(sconeOrders => sconeOrders.card)
      }
      if (userDetailInfo.itemOrders) {
        itemOrders = Object.values(userDetailInfo.itemOrders)
        itemOrders = itemOrders.filter(itemOrders => itemOrders.card)
      }
      if (userDetailInfo.sconePickups) {
        sconePickups = Object.values(userDetailInfo.sconePickups)
        sconePickups = sconePickups.filter(sconePickups => sconePickups.card)
      }
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>회원 상세 정보</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({showUserDetail: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>

          </Grid>
          <Grid style={{paddingTop: 80, paddingLeft: 20, paddingRight: 20, paddingBottom: 20}} className="auto-width-100 direction-column-div">
            <span style={{fontWeight: 'bold', marginBottom: 10}}>{userDetailInfo.username}</span>
            <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
              <span style={{fontSize: 13, width: 85}}>가입일</span>
              <span style={{fontSize: 13}}>{this.onGetTimeDetail(userDetailInfo.signinDate)}</span>
            </div>
            <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
              <span style={{fontSize: 13, width: 85}}>이메일</span>
              <span style={{fontSize: 13}}>{userDetailInfo.email}</span>
            </div>
            <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
              <span style={{fontSize: 13, width: 85}}>휴대폰 번호</span>
              <span style={{fontSize: 13}}>{this.onChangePhoneNumberFormat(userDetailInfo.phoneNumber)}</span>
            </div>
            { sconePickups.length
              ?<div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                <span style={{fontSize: 13, width: 85}}>스콘 예약</span>
                <div className="direction-column-div">
                { sconePickups.map((order, index) => {
                  return (
                    <div className="auto-width-100 direction-column-div" style={{marginBottom: 5}}>
                      <span style={{fontSize: 13}}>스콘 {order.totalAmount}개</span>
                      <span style={{fontSize: 13}}>{this.onChangeCurrencyFormat(order.paidPrice)}원 결제</span>
                      <span style={{fontSize: 13}}>{this.onGetTimeDetail(order.paidAt)} 결제</span>
                    </div>
                  )
                })}
                </div>
              </div>
              :<div>
              </div>
            }
            { sconeOrders.length
              ?<div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                <span style={{fontSize: 13, width: 85}}>스콘 주문</span>
                <div className="direction-column-div">
                { sconeOrders.map((order, index) => {
                  return (
                    <div className="auto-width-100 direction-column-div" style={{marginBottom: 5}}>
                      <span style={{fontSize: 13}}>스콘 {order.totalAmount}개</span>
                      <span style={{fontSize: 13}}>{this.onChangeCurrencyFormat(order.paidPrice)}원 결제</span>
                      <span style={{fontSize: 13}}>{this.onGetTimeDetail(order.paidAt)} 결제</span>
                    </div>
                  )
                })}
              </div>
              </div>
              :<div>
              </div>
            }
            { itemOrders.length
              ?<div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                <span style={{fontSize: 13, width: 85}}>상품 주문</span>
                <div className="direction-column-div">
                { itemOrders.map((order, index) => {
                  return (
                    <div className="auto-width-100 direction-column-div" style={{marginBottom: 5}}>
                      <span style={{fontSize: 13}}>상품 {order.totalAmount}개</span>
                      <span style={{fontSize: 13}}>{this.onChangeCurrencyFormat(order.paidPrice)}원 결제</span>
                      <span style={{fontSize: 13}}>{this.onGetTimeDetail(order.paidAt)} 결제</span>
                    </div>
                  )
                })}
              </div>
              </div>
              :<div>
              </div>
            }
          </Grid>
        </Grid>
      )
    }
    if (this.state.isOpenOfflineCouponList) {
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>오프라인 쿠폰 발급 내역</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenOfflineCouponList: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 60}} className="auto-width-100 direction-column-div">
            { this.state.offlineCouponList.map((coupon, index) => {
              return (
                <div>
                  <div className="direction-column-div" style={{padding: 20}}>
                    <span style={{fontSize: 12}}>유효기간: {this.onGetTimeDetail(coupon.issuedAt)}까지</span>
                    <span style={{fontSize: 12}}>금액: {this.onChangeCurrencyFormat(coupon.amount)}원</span>
                    <span style={{fontSize: 12}}>전화번호: {coupon.phoneNumber}</span>
                  </div>
                  <Divider className="auto-width-100"/>
                </div>
              )
            })

            }
            </Grid>
          </Grid>
        </Grid>
      )
    }
    if (this.state.isOpenOnlineCouponList) {
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>온라인 쿠폰 발급 내역</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenOnlineCouponList: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 60}} className="auto-width-100 direction-column-div">
            { this.state.onlineCouponList.map((coupon, index) => {
              return (
                <div>
                  <div className="direction-column-div" style={{padding: 20}}>
                    <span style={{fontSize: 12}}>발급일: {this.onGetTimeDetail(coupon.issuedAt)}</span>
                    <span style={{fontSize: 12}}>금액: {this.onChangeCurrencyFormat(coupon.amount)}원</span>
                    <span style={{fontSize: 12}}>전화번호: {coupon.phoneNumber}</span>
                  </div>
                  <Divider className="auto-width-100"/>
                </div>
              )
            })

            }
            </Grid>
          </Grid>
        </Grid>
      )
    }
    if (this.state.isOpenItemReviews) {
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>상품 리뷰</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenItemReviews: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 60}} className="auto-width-100 direction-column-div">
              { this.state.itemReviews.map((review, index) => {
                return (
                  <div>
                    <div className="direction-column-div" style={{padding: 20}}>
                      <div className="direction-row-div">
                        <span  style={{fontSize: 12, marginBottom: 5}}>{this.onGetTimeDetail(review.createdAt)}</span>
                        { this.state.users[review.uid] &&
                          <span style={{fontSize: 12, marginBottom: 5, marginLeft: 5}}>- {this.state.users[review.uid].username}</span>
                        }
                        <span  style={{fontSize: 12, marginBottom: 5, marginLeft: 5}}>- 평점 {review.rating}점</span>
                        { review.images &&
                          <span  style={{fontSize: 12, marginBottom: 5, marginLeft: 5, fontWeight: 'bold'}}>- 사진</span>
                        }

                      </div>

                      { review.itemId &&
                        <span style={{fontSize: 11, color: 'gray', marginBottom: 3}}>[{this.state.items[review.itemId].name}]</span>
                      }

                      <span  style={{fontSize: 12}}>{review.review}</span>
                      <div style={{marginTop: 20, width: 90, }} onClick={(e) => {
                        e.stopPropagation();
                        if (!review.best && !review.winaPick) {
                          this.setState({showBestItemRevieModal: true, selectedItemReview: review})
                        }

                      }} className={!review.best && !review.winaPick ? "normal-very-small-button-red direction-column-div" : "normal-very-small-button-disabled direction-column-div"}>
                        <span style={{color: 'white', fontSize: 11}}>베스트 리뷰 선정</span>
                      </div>
                      <div style={{marginTop: 10, width: 90, }} onClick={(e) => {
                        e.stopPropagation();
                        if (!review.best && !review.winaPick) {
                          this.setState({showWinaPickItemRevieModal: true, selectedItemReview: review})
                        }

                      }} className={!review.best && !review.winaPick ? "normal-very-small-button direction-column-div" : "normal-very-small-button-disabled direction-column-div"}>
                        <span style={{color: 'white', fontSize: 11}}>위나픽 리뷰 선정</span>
                      </div>
                    </div>
                    <Divider className="auto-width-100"/>
                  </div>
                )
              })

              }
            </Grid>
          </Grid>
          <Modal
            open={this.state.showBestItemRevieModal}
            onClose={() => this.setState({showBestItemRevieModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>베스트 리뷰로 선정하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showBestItemRevieModal: false, selectedItemReview: null})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let selectedItemReview = this.state.selectedItemReview
              let uid = selectedItemReview.uid
              let couponId = shortid.generate()
              let userInfo = this.state.users[uid]
              let couponInfo = {
                couponId: couponId,
                issuedAt: Date.now(),
                category: 'scone',
                expireDate: Number(moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString()),
                name: '스콘 택배 할인',
                eventName: '베스트 리뷰 선정',
                type: 'discount',
                phoneNumber: userInfo.phoneNumber,
                amount: 10000
              }

              firebase.database().ref(`items/${selectedItemReview.itemId}/reviews/${selectedItemReview.reviewId}/best`).set(true)
              firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
              .then(() => {
                axios({
                  url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendBestReviewerMessage`,
                  method: "post",
                  headers: {
                    "Content-Type":"application/json"
                  },
                  data: {
                    amount: `10,000원`,
                    phoneNumber: userInfo.phoneNumber,
                    username: userInfo.username,
                    createdDate: this.onGetTimeDetail(selectedItemReview.createdAt),
                    couponName: '스콘 택배 할인'
                  }
                })
              })
              this.setState({showBestItemRevieModal: false, selectedItemReview: null, completeSelectBestItemReviewSnack: true})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>선정</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showWinaPickItemRevieModal}
            onClose={() => this.setState({showWinaPickItemRevieModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>위나픽 리뷰로 선정하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showWinaPickItemRevieModal: false, selectedItemReview: null})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let selectedItemReview = this.state.selectedItemReview
              let uid = selectedItemReview.uid
              let couponId = shortid.generate()
              let userInfo = this.state.users[uid]
              let couponInfo = {
                couponId: couponId,
                issuedAt: Date.now(),
                category: 'scone',
                expireDate: Number(moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString()),
                name: '스콘 택배 할인',
                eventName: '위나픽 리뷰 선정',
                type: 'discount',
                phoneNumber: userInfo.phoneNumber,
                amount: 5000
              }

              firebase.database().ref(`items/${selectedItemReview.itemId}/reviews/${selectedItemReview.reviewId}/winaPick`).set(true)
              firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
              .then(() => {
                axios({
                  url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendWinaPickReviewerMessage`,
                  method: "post",
                  headers: {
                    "Content-Type":"application/json"
                  },
                  data: {
                    amount: `5,000원`,
                    phoneNumber: userInfo.phoneNumber,
                    username: userInfo.username,
                    createdDate: this.onGetTimeDetail(selectedItemReview.createdAt),
                    couponName: '스콘 택배 할인'
                  }
                })
              })
              this.setState({showWinaPickItemRevieModal: false, selectedItemReview: null, completeSelectWinaPickItemReviewSnack: true})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>선정</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Snackbar open={this.state.completeSelectBestItemReviewSnack} autoHideDuration={4000} onClose={() => this.setState({completeSelectBestItemReviewSnack: false})}>
            <Alert  onClose={() => this.setState({completeSelectBestItemReviewSnack: false})} severity="success">
              베스트 리뷰가 선정되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeSelectWinaPickItemReviewSnack} autoHideDuration={4000} onClose={() => this.setState({completeSelectWinaPickItemReviewSnack: false})}>
            <Alert  onClose={() => this.setState({completeSelectWinaPickItemReviewSnack: false})} severity="success">
              위나픽 리뷰가 선정되었습니다.
            </Alert>
          </Snackbar>
        </Grid>
      )
    }
    if (this.state.isOpenReviews) {
      return (
        <Grid container justify="center" style={{paddingBottom: 60}}>
          <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center">
              <Grid className="direction-column-div align-center" style={{marginBottom: 15}} justify="center">
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>스콘  리뷰</span>
              </Grid>
              <div
                item
                className="no-selection back-button"
                style={{position: 'absolute', right: 20, top: 20,}}
                onClick={() => {
                  this.setState({isOpenReviews: false})
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0,  this.state.prevPosition)
                      } else {
                        window.scrollTo({
                          top: this.state.prevPosition,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 50)

                }}
              >
                <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
              </div>
            </Grid>
            <Grid style={{marginTop: 60}} className="auto-width-100 direction-column-div">
              { this.state.reviews.map((review, index) => {
                return (
                  <div>
                    <div className="direction-column-div" style={{padding: 20}}>
                      <div className="direction-row-div">
                        <span  style={{fontSize: 12, marginBottom: 5}}>{this.onGetTimeDetail(review.createdAt)}</span>
                        { this.state.users[review.uid] &&
                          <span style={{fontSize: 12, marginBottom: 5, marginLeft: 5}}>- {this.state.users[review.uid].username}</span>
                        }
                        <span  style={{fontSize: 12, marginBottom: 5, marginLeft: 5}}>- 평점 {review.rating}점</span>
                        { review.images &&
                          <span  style={{fontSize: 12, marginBottom: 5, marginLeft: 5, fontWeight: 'bold'}}>- 사진</span>
                        }
                      </div>

                      { review.itemId &&
                        <span style={{fontSize: 11, color: 'gray', marginBottom: 3}}>[{this.state.sconeMenu[review.itemId].name}]</span>
                      }

                      <span  style={{fontSize: 12}}>{review.review}</span>
                      <div style={{marginTop: 20, width: 90, }} onClick={(e) => {
                        e.stopPropagation();
                        if (!review.best && !review.winaPick) {
                          this.setState({showBestRevieModal: true, selectedReview: review})
                        }

                      }} className={!review.best && !review.winaPick ? "normal-very-small-button-red direction-column-div" : "normal-very-small-button-disabled direction-column-div"}>
                        <span style={{color: 'white', fontSize: 11}}>베스트 리뷰 선정</span>
                      </div>
                      <div style={{marginTop: 10, width: 90, }} onClick={(e) => {
                        e.stopPropagation();
                        if (!review.best && !review.winaPick) {
                          this.setState({showWinaPickRevieModal: true, selectedReview: review})
                        }

                      }} className={!review.best && !review.winaPick ? "normal-very-small-button direction-column-div" : "normal-very-small-button-disabled direction-column-div"}>
                        <span style={{color: 'white', fontSize: 11}}>위나픽 리뷰 선정</span>
                      </div>
                    </div>
                    <Divider className="auto-width-100"/>
                  </div>
                )
              })

              }
            </Grid>
          </Grid>
          <Modal
            open={this.state.showBestRevieModal}
            onClose={() => this.setState({showBestRevieModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>베스트 리뷰로 선정하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showBestRevieModal: false, selectedReview: null})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let selectedReview = this.state.selectedReview
              let uid = selectedReview.uid
              let couponId = shortid.generate()
              let userInfo = this.state.users[uid]
              let couponInfo = {
                couponId: couponId,
                issuedAt: Date.now(),
                category: 'scone',
                expireDate: Number(moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString()),
                name: '스콘 택배 할인',
                eventName: '베스트 리뷰 선정',
                type: 'discount',
                phoneNumber: userInfo.phoneNumber,
                amount: 10000
              }

              firebase.database().ref(`sconeMenu/${selectedReview.itemId}/reviews/${selectedReview.reviewId}/best`).set(true)
              firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
              .then(() => {
                axios({
                  url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendBestReviewerMessage`,
                  method: "post",
                  headers: {
                    "Content-Type":"application/json"
                  },
                  data: {
                    amount: `10,000원`,
                    phoneNumber: userInfo.phoneNumber,
                    username: userInfo.username,
                    createdDate: this.onGetTimeDetail(selectedReview.createdAt),
                    couponName: '스콘 택배 할인'
                  }
                })
              })
              this.setState({showBestRevieModal: false, selectedReview: null, completeSelectBestReviewSnack: true})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>선정</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showWinaPickRevieModal}
            onClose={() => this.setState({showWinaPickRevieModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>위나픽 리뷰로 선정하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showWinaPickRevieModal: false, selectedReview: null})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let selectedReview = this.state.selectedReview
              let uid = selectedReview.uid
              let couponId = shortid.generate()
              let userInfo = this.state.users[uid]
              let couponInfo = {
                couponId: couponId,
                issuedAt: Date.now(),
                category: 'scone',
                expireDate: Number(moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString()),
                name: '스콘 택배 할인',
                eventName: '위나픽 리뷰 선정',
                type: 'discount',
                phoneNumber: userInfo.phoneNumber,
                amount: 5000
              }

              firebase.database().ref(`sconeMenu/${selectedReview.itemId}/reviews/${selectedReview.reviewId}/winaPick`).set(true)
              firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
              .then(() => {
                axios({
                  url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendWinaPickReviewerMessage`,
                  method: "post",
                  headers: {
                    "Content-Type":"application/json"
                  },
                  data: {
                    amount: `5,000원`,
                    phoneNumber: userInfo.phoneNumber,
                    username: userInfo.username,
                    createdDate: this.onGetTimeDetail(selectedReview.createdAt),
                    couponName: '스콘 택배 할인'
                  }
                })
              })
              this.setState({showWinaPickRevieModal: false, selectedReview: null, completeSelectWinaPickReviewSnack: true})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>선정</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Snackbar open={this.state.completeSelectBestReviewSnack} autoHideDuration={4000} onClose={() => this.setState({completeSelectBestReviewSnack: false})}>
            <Alert  onClose={() => this.setState({completeSelectBestReviewSnack: false})} severity="success">
              베스트 리뷰가 선정되었습니다.
            </Alert>
          </Snackbar>
          <Snackbar open={this.state.completeSelectWinaPickReviewSnack} autoHideDuration={4000} onClose={() => this.setState({completeSelectWinaPickReviewSnack: false})}>
            <Alert  onClose={() => this.setState({completeSelectWinaPickReviewSnack: false})} severity="success">
              위나픽 리뷰가 선정되었습니다.
            </Alert>
          </Snackbar>
        </Grid>
      )
    }
    return (
      <Grid ref={this.adminRef} container>
      <div className="auto-width-100 relative-position">
        <div onClick={() => this.setState({qrScanning: true})} className="direction-row-div" style={{position: 'absolute', top: 20, right: 20, cursor: 'pointer'}}>
          <CropFreeIcon  style={{fontSize: 20, marginRight: 5}} />
          <span>QR스캔</span>
        </div>
      </div>
      <span style={{fontWeight: 'bold', padding: 20, paddingBottom: 0}}>관리자 페이지</span>
        <Grid container justify="center">
          <Image style={{width: 100, height: 100, padding: 30}} src={admin} />
          <Divider className="auto-width-100" />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>픽업 예약 오픈 및 확인</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            <div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
              <div onClick={()=>{ this.setState({today: this.state.today.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 60}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
              <span style={{fontWeight: 'bold'}}>{this.state.today.format('YYYY년 MM월')}</span>
              <div onClick={()=>{ this.setState({today: this.state.today.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 60, marginRight: 0}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
            </div>
            <table className="auto-width-90">
              <tbody>
                { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                    return (
                      <td className="day-table-style" key={index} style={{height: 35}} >
                        { index === 0
                          ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                          :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                        }

                      </td>
                    )
                  })
                }
                { Object.keys(this.state.pickupOpen).length !== 0 &&
                  this.calendarArr(this.state.today)
                }
              </tbody>
            </table>
            </Grid>
            <Grid onClick={() => {
              this.setState({showPickupOpen: !this.state.showPickupOpen})
            }} style={{cursor: 'pointer'}} className="auto-width-100 center-align" container justify="center" direction="column">
            <span style={{fontSize: 12, marginTop: 10}}>예약 오픈</span>
            { this.state.showPickupOpen
              ?<KeyboardArrowUpIcon style={{marginTop: 5, marginLeft: 13}}/>
              :<KeyboardArrowDownIcon style={{marginTop: 5, marginLeft: 13}}/>
            }
            </Grid>
            { this.state.showPickupOpen &&
              <Grid>
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 20, marginBottom: 20, width: 200}} onChange={(event) => {
                this.setState({amount: Number(event.target.value.replace(/[^0-9]/g, ''))})
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="총 예약 가능 스콘 개수"  value={this.state.amount} />
              <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15}} className="direction-column-div">
                <div className="direction-row-div">
                  <span style={{fontSize: 12, width: 200, color: 'gray'}}>스콘 종류</span>
                  <span style={{fontSize: 12, width: 70, marginLeft: 63, color: 'gray'}}>개수 제한</span>
                </div>
              </div>
              <div style={{paddingLeft: 20, paddingRight: 20}} className="direction-column-div">
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((scone, index) => {
                return (
                  <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.pickupMenu[scone.id]}  onChange={() => {
                          let pickupMenu = this.state.pickupMenu
                          if (pickupMenu[scone.id]) {
                            delete pickupMenu[scone.id]
                          } else {
                            pickupMenu[scone.id] = {
                              id: scone.id
                            }
                          }
                          this.setState({pickupMenu: pickupMenu})
                        }} value="defaultAddress" />
                      }
                      label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>{scone.name}</span>}
                    />
                  </FormGroup>
                  { this.state.pickupMenu[scone.id]
                    ?<Input autoComplete="off" style={{width: 60, marginLeft: 20}} onChange={(event) => {
                      let pickupMenu = this.state.pickupMenu
                      if (Number(event.target.value.replace(/[^0-9]/g, ''))) {
                        pickupMenu[scone.id]['limit'] = Number(event.target.value.replace(/[^0-9]/g, ''))
                      } else {
                        delete pickupMenu[scone.id]['limit']
                      }

                      this.setState({pickupMenu: pickupMenu})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="개수 제한"  value={this.state.pickupMenu[scone.id]['limit'] ? this.state.pickupMenu[scone.id]['limit'] : '제한없음'} />
                    :<Input autoComplete="off" disabled={!this.state.pickupMenu[scone.id]} style={{width: 60,  marginLeft: 20}} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="개수 제한"  value={''} />
                  }

                  </div>
                )
              })

              }
              </div>
              { Object.values(this.state.items).length &&
                <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 20}} className="direction-column-div">
                { Object.values(this.state.items).map((item, index) => {
                  return (
                    <div className="direction-column-div">
                    <div className="direction-row-div">
                    <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                      <FormControlLabel
                        control={
                          <GreenCheckbox checked={this.state.pickupAdditionalMenu[item.id]}  onChange={() => {
                            let pickupAdditionalMenu = this.state.pickupAdditionalMenu
                            let items = this.state.items
                            let menuObj = items[item.id]
                            if (pickupAdditionalMenu[item.id]) {
                              delete pickupAdditionalMenu[item.id]
                            } else {
                              menuObj['amount'] = 0
                              delete menuObj['open']
                              if (menuObj['reviews']) {
                                delete menuObj['reviews']
                              }
                              pickupAdditionalMenu[item.id] = menuObj
                            }
                            this.setState({pickupAdditionalMenu: pickupAdditionalMenu})
                          }} value="defaultAddress" />
                        }
                        label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>{item.name}</span>}
                      />
                    </FormGroup>
                    { this.state.pickupAdditionalMenu[item.id]
                      ?<Input autoComplete="off" style={{width: 60, marginLeft: 20}} onChange={(event) => {
                        let pickupAdditionalMenu = this.state.pickupAdditionalMenu
                        if (Number(event.target.value.replace(/[^0-9]/g, ''))) {
                          pickupAdditionalMenu[item.id]['amount'] = Number(event.target.value.replace(/[^0-9]/g, ''))
                        } else {
                          pickupAdditionalMenu[item.id]['amount'] = 0
                        }

                        this.setState({pickupAdditionalMenu: pickupAdditionalMenu})
                      }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                      InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="수량"  value={this.state.pickupAdditionalMenu[item.id]['amount'] ? this.state.pickupAdditionalMenu[item.id]['amount'] : '0'} />
                      :<Input autoComplete="off" disabled={!this.state.pickupAdditionalMenu[item.id]} style={{width: 60,  marginLeft: 20}} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                      InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="수량"  value={'0'} />
                    }
                    </div>
                    { this.state.pickupAdditionalMenu[item.id] &&
                      <div style={{marginBottom: 3}}>
                        { item.discountedPrice
                          ?<div className="direction-row-div" style={{marginLeft: 27}}>
                            <span style={{fontSize: 13, color: '#6b6b6b'}}>판매가 {this.onChangeCurrencyFormat(item.discountedPrice)}원</span>
                            <span style={{fontSize: 13, fontWeight: 'bold', color: 'red', marginLeft: 3}}>{Math.round((1 - (item.discountedPrice / item.price)) * 100)}% 할인</span>
                          </div>
                          :<div className="direction-row-div" style={{marginLeft: 27}}>
                            <span style={{fontSize: 13, color: '#6b6b6b'}}>판매가 {this.onChangeCurrencyFormat(item.price)}원</span>
                          </div>
                        }

                      </div>

                    }

                    </div>
                  )
                })

                }
                </div>
              }
              <span style={{fontSize: 11, color: 'gray', marginLeft: 20, marginTop: 5}}>* 판매가 수정은 [일반택배 오픈 및 수정]에서 가능</span>
              <div className="justify-center" style={{padding: 20}}>
              <label>
                <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                        onChange={() => this.setState({applyDiscount: !this.state.applyDiscount})} checked={this.state.applyDiscount} />
              </label>
              <span style={{fontSize: 12, marginLeft: 10, marginTop: 2}}>할인 적용</span>
              </div>
              { this.state.applyDiscount &&
                <div style={{padding: 20, paddingTop: 0}} className="direction-column-div">
                  <TextField style={{width: 200}} onChange={(event) => {
                    this.setState({discountTitle: event.target.value})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인 이름"  value={this.state.discountTitle} />
                  <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                    this.setState({discountRate: Number(event.target.value.replace(/[^0-9]/g, ''))})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인율 (%)"  value={this.state.discountRate} />
                  <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                    this.setState({minDiscountPrice: Number(event.target.value.replace(/[^0-9]/g, ''))})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="최소 금액"  value={this.state.minDiscountPrice} />
                </div>
              }
              <div onClick={() => {
                let pickupAdditionalMenu = null
                if (Object.keys(this.state.pickupAdditionalMenu).length) {
                  pickupAdditionalMenu = this.state.pickupAdditionalMenu
                }
                if (this.state.applyDiscount) {
                  if (this.state.discountRate && this.state.discountTitle) {
                    if (Object.keys(this.state.selectedPickupOpenObj).length) {
                      let i = 0
                      this.setState({isOpeningPickup: true})
                      Object.keys(this.state.selectedPickupOpenObj).map((openDate, index) => {

                        if (Object.keys(this.state.pickupAdditionalMenu).length) {
                          firebase.database().ref(`/pickupOpen/${openDate.toString()}`).set({
                            amount: this.state.amount,
                            additionalPickups: pickupAdditionalMenu,
                            date: openDate,
                            menu: this.state.pickupMenu,
                            discount: {
                              rate: Number(this.state.discountRate) / 100,
                              title: this.state.discountTitle,
                              minDiscountPrice: Number(this.state.minDiscountPrice)
                            }
                          }).then(() => {
                            i = i + 1
                            if (i === Object.keys(this.state.selectedPickupOpenObj).length) {
                              window.location.reload()
                            }
                          })
                        } else {
                          firebase.database().ref(`/pickupOpen/${openDate.toString()}`).set({
                            amount: this.state.amount,
                            date: openDate,
                            menu: this.state.pickupMenu,
                            discount: {
                              rate: Number(this.state.discountRate) / 100,
                              title: this.state.discountTitle,
                              minDiscountPrice: Number(this.state.minDiscountPrice)
                            }
                          }).then(() => {
                            i = i + 1
                            if (i === Object.keys(this.state.selectedPickupOpenObj).length) {
                              window.location.reload()
                            }
                          })
                        }


                      })
                    }
                  }
                } else {
                  if (Object.keys(this.state.selectedPickupOpenObj).length) {
                    let i = 0
                    this.setState({isOpeningPickup: true})
                    Object.keys(this.state.selectedPickupOpenObj).map((openDate, index) => {
                      if (Object.keys(this.state.pickupAdditionalMenu).length) {
                        firebase.database().ref(`/pickupOpen/${openDate.toString()}`).set({
                          amount: this.state.amount,
                          additionalPickups: pickupAdditionalMenu,
                          date: openDate,
                          menu: this.state.pickupMenu
                        }).then(() => {
                          i = i + 1
                          if (i === Object.keys(this.state.selectedPickupOpenObj).length) {
                            window.location.reload()
                          }
                        })
                      } else {
                        firebase.database().ref(`/pickupOpen/${openDate.toString()}`).set({
                          amount: this.state.amount,
                          date: openDate,
                          menu: this.state.pickupMenu
                        }).then(() => {
                          i = i + 1
                          if (i === Object.keys(this.state.selectedPickupOpenObj).length) {
                            window.location.reload()
                          }
                        })
                      }

                    })
                  }
                }

              }} className="btn-big" style={{margin: 15}}>
                { this.state.isOpeningPickup
                  ?<PulseLoader  color={'white'} loading={true} size={5} />
                  :<span style={{fontSize: 13, color: 'white'}}>예약 오픈</span>
                }
              </div>
              </Grid>
            }
          </div>
          { false &&
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>선물세트 픽업 예약 확인</span>
              <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
              <Grid className="auto-width-100 center-align" container justify="center" direction="column">
              <div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
                <div onClick={()=>{ this.setState({giftSetToday: this.state.giftSetToday.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 60}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
                <span style={{fontWeight: 'bold'}}>{this.state.today.format('YYYY년 MM월')}</span>
                <div onClick={()=>{ this.setState({giftSetToday: this.state.giftSetToday.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 60, marginRight: 0}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
              </div>
              <table className="auto-width-90">
                <tbody>
                  { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                      return (
                        <td className="day-table-style" key={index} style={{height: 35}} >
                          { index === 0
                            ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                            :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                          }

                        </td>
                      )
                    })
                  }
                  { Object.keys(this.state.pickupOpen).length !== 0 &&
                    this.giftSetCalendarArr(this.state.giftSetToday)
                  }
                </tbody>
              </table>
              </Grid>
            </div>
          }

          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>스콘 택배 오픈 및 확인</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            <div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
              <div onClick={()=>{ this.setState({deliveryToday: this.state.deliveryToday.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 60}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
              <span style={{fontWeight: 'bold'}}>{this.state.deliveryToday.format('YYYY년 MM월')}</span>
              <div onClick={()=>{ this.setState({deliveryToday: this.state.deliveryToday.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 60, marginRight: 0}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
            </div>
            <table className="auto-width-90">
              <tbody>
                { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                    return (
                      <td className="day-table-style" key={index} style={{height: 35}} >
                        { index === 0
                          ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                          :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                        }

                      </td>
                    )
                  })
                }
                { Object.keys(this.state.pickupOpen).length !== 0 &&
                  this.sconeDeliveryCalendarArr(this.state.deliveryToday)
                }
              </tbody>
            </table>
            </Grid>
            { this.state.openOrderSheet &&
              <div style={{paddingLeft: 20, marginTop: 15, marginBottom: 10}} className="direction-row-div">
                <span style={{fontSize: 11, marginTop: 3}}>택배 오픈 (예정) 날짜</span>
                <span style={{fontSize: 11, marginTop: 3, marginLeft: 4}}>{this.onStringNumberToDate(this.state.openOrderSheet.toString())}</span>
                <div onClick={() => {
                  this.setState({showChangeDeliveryOpenDate: !this.state.showChangeDeliveryOpenDate})
                }} style={{marginLeft: 10}} className={this.state.showChangeDeliveryOpenDate ? "normal-very-small-button-outlined direction-column-div" : "normal-very-small-button direction-column-div"}>
                  { this.state.showChangeDeliveryOpenDate
                    ?<span style={{color: '#006042', fontSize: 13}}>변경 취소</span>
                    :<span style={{color: 'white', fontSize: 13}}>날짜 변경</span>
                  }
                </div>
              </div>
            }
            <div onClick={() => {
              this.setState({showStartDeliveryOrderModal: true})
            }} className="btn-big" style={{margin: 15}}>
              { this.state.isStartingDeliveryOrder
                ?<PulseLoader  color={'white'} loading={true} size={5} />
                :<span style={{fontSize: 13, color: 'white'}}>{this.onStringNumberToDate(moment(Date.now()).format('YYYYMMDD').toString())} 택배 주문 시작 [알림 설정 {this.state.sconeDelivery.length && this.state.sconeDelivery.length}명]</span>
              }
            </div>
            <div className="auto-width-100 center-align">
            { this.state.showChangeDeliveryOpenDate &&
              <div className={"none-underline-text btn-bottom-admin-notice-fixed no-selection direction-column-div"}  >
                <span style={{color: 'white', fontSize: 11, margin: 15}}>다음 택배 오픈 예정일을 선택해주세요.</span>
              </div>
            }
            </div>




            <Grid onClick={() => {
              this.setState({showDeliveryOpen: !this.state.showDeliveryOpen})
            }} style={{cursor: 'pointer'}} className="auto-width-100 center-align" container justify="center" direction="column">
            <span style={{fontSize: 12, marginTop: 10}}>택배 오픈</span>
            { this.state.showDeliveryOpen
              ?<KeyboardArrowUpIcon style={{marginTop: 5, marginLeft: 13}}/>
              :<KeyboardArrowDownIcon style={{marginTop: 5, marginLeft: 13}}/>
            }
            </Grid>

            { this.state.showDeliveryOpen &&
              <Grid>
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 20, marginBottom: 20, width: 200}} onChange={(event) => {
                this.setState({amount: Number(event.target.value.replace(/[^0-9]/g, ''))})
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="택배비"  value={this.state.deliveryCharge} />
              <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15}} className="direction-column-div">
                <div className="direction-row-div">
                  <span style={{fontSize: 12, width: 200, color: 'gray'}}>스콘 종류</span>
                  <span style={{fontSize: 12, width: 70, marginLeft: 63, color: 'gray'}}>오픈 수량</span>
                </div>
              </div>
              <div style={{paddingLeft: 20, paddingRight: 20}} className="direction-column-div">
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((scone, index) => {
                return (
                  <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.deliveryMenu[scone.id]}  onChange={() => {
                          let deliveryMenu = this.state.deliveryMenu
                          if (deliveryMenu[scone.id]) {
                            delete deliveryMenu[scone.id]
                          } else {
                            deliveryMenu[scone.id] = {
                              id: scone.id,
                              amount: 0
                            }
                          }
                          this.setState({deliveryMenu: deliveryMenu})
                        }} value="defaultAddress" />
                      }
                      label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>{scone.name}</span>}
                    />
                  </FormGroup>
                  { this.state.deliveryMenu[scone.id]
                    ?<Input autoComplete="off" style={{width: 60, marginLeft: 20}} onChange={(event) => {
                      let deliveryMenu = this.state.deliveryMenu
                      if (Number(event.target.value.replace(/[^0-9]/g, ''))) {
                        deliveryMenu[scone.id]['amount'] = Number(event.target.value.replace(/[^0-9]/g, ''))
                      } else {
                        deliveryMenu[scone.id]['amount'] = 0
                      }

                      this.setState({deliveryMenu: deliveryMenu})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 수량"  value={this.state.deliveryMenu[scone.id]['amount'] ? this.state.deliveryMenu[scone.id]['amount'] : 0} />
                    :<Input autoComplete="off" disabled={!this.state.deliveryMenu[scone.id]} style={{width: 60,  marginLeft: 20}} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 수량"  value={''} />
                  }

                  </div>
                )
              })

              }
              <span style={{marginTop: 20, fontSize: 14, fontWeight: 'bold', marginBottom: 10}}>총 택배 오픈 수량: {this.onCountTotalOpenDelivery(this.state.deliveryMenu)}개</span>
                <span style={{fontSize: 12, width: 200, color: 'gray', paddingTop: 10, paddingBottom: 10}}>추가 옵션</span>
              <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.firstPurchaseFree}  onChange={() => {
                      this.setState({firstPurchaseFree: !this.state.firstPurchaseFree})
                    }} value="defaultAddress" />
                  }
                  label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>첫 구매 무료배송</span>}
                />
              </FormGroup>
              <TextField style={{width: 300, marginTop: 5, marginBottom: 5}} onChange={(event) => {
                this.setState({beforeOpenNotice: event.target.value})
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 전 공지"  value={this.state.beforeOpenNotice} />
              <div className="justify-center" style={{padding: 20, paddingLeft: 0}}>
              <label>
                <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                        onChange={() => this.setState({applyDeliveryDiscount: !this.state.applyDeliveryDiscount})} checked={this.state.applyDeliveryDiscount} />
              </label>

              <span style={{fontSize: 12, marginLeft: 10, marginTop: 2}}>할인 적용</span>
              </div>

              { this.state.applyDeliveryDiscount &&
                <div style={{padding: 20, paddingTop: 0, paddingLeft: 0}} className="direction-column-div">
                  <TextField style={{width: 200}} onChange={(event) => {
                    this.setState({deliveryDiscountTitle: event.target.value})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이벤트 이름"  value={this.state.deliveryDiscountTitle} />
                  <TextField style={{width: 200, marginTop: 15}} onChange={(event) => {
                    this.setState({deliveryDiscountRate: Number(event.target.value.replace(/[^0-9]/g, ''))})
                  }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="할인율 (%)"  value={this.state.deliveryDiscountRate} />
                  <FormGroup  style={{alignItems: 'center', margin: 0, marginTop: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.setEndTimeOfEvent}  onChange={() => {
                          this.setState({setEndTimeOfEvent: !this.state.setEndTimeOfEvent})
                        }} value="defaultAddress" />
                      }
                      label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>이벤트 종료 시간 설정</span>}
                    />
                  </FormGroup>
                  <TextField
                    style={{marginTop: 5}}
                    disabled={!this.state.setEndTimeOfEvent}
                    id="datetime-local"
                    label="이벤트 종료 시간"
                    type="datetime-local"
                    onChange={(event) => {
                       this.setState({deliveryDiscountUntil: Number(moment(new Date(event.target.value)).format('x'))})
                     }}
                    defaultValue={moment().format("YYYY-MM-DD[T]HH:mm")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              }

              </div>
              <div onClick={() => {
                if (Object.keys(this.state.selectedDeliveryOpenObj).length) {
                  let i = 0
                  this.setState({isOpeningDelivery: true})
                  let discount = null
                  let beforeOpenNotice = null
                  if (this.state.applyDeliveryDiscount) {
                    if (this.state.setEndTimeOfEvent) {
                      discount = {
                        rate: Number(this.state.deliveryDiscountRate) * 0.01,
                        title: this.state.deliveryDiscountTitle,
                        until: this.state.deliveryDiscountUntil
                      }
                    } else {
                      discount = {
                        rate: Number(this.state.deliveryDiscountRate) * 0.01,
                        title: this.state.deliveryDiscountTitle
                      }
                    }
                  }

                  if (this.state.beforeOpenNotice) {
                    beforeOpenNotice = this.state.beforeOpenNotice
                  }

                  Object.keys(this.state.selectedDeliveryOpenObj).map((openDate, index) => {
                    firebase.database().ref(`orderSheets/${openDate.toString()}`).set({
                      deliveryCharge: this.state.deliveryCharge,
                      date: openDate,
                      menu: this.state.deliveryMenu,
                      open: false,
                      firstPurchaseFree: this.state.firstPurchaseFree,
                      discount: discount,
                      notice: beforeOpenNotice
                    }).then(() => {
                      i = i + 1
                      if (i === Object.keys(this.state.selectedDeliveryOpenObj).length) {
                        window.location.reload()
                      }
                    })
                  })
                }
              }} className="btn-big" style={{margin: 15}}>
                { this.state.isOpeningDelivery
                  ?<PulseLoader  color={'white'} loading={true} size={5} />
                  :<span style={{fontSize: 13, color: 'white'}}>택배 오픈</span>
                }
              </div>


              </Grid>
            }

            <Divider className="auto-width-100" style={{marginTop: 0, height: 10, marginTop: 10}} />
          </div>
          <div className="auto-width-100" style={{paddingTop: 0, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>일반 택배 확인</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            <div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
              <div onClick={()=>{ this.setState({itemsDeliveryToday: this.state.itemsDeliveryToday.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 60}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
              <span style={{fontWeight: 'bold'}}>{this.state.itemsDeliveryToday.format('YYYY년 MM월')}</span>
              <div onClick={()=>{ this.setState({itemsDeliveryToday: this.state.itemsDeliveryToday.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 60, marginRight: 0}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
            </div>
            <table className="auto-width-90">
              <tbody>
                { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                    return (
                      <td className="day-table-style" key={index} style={{height: 35}} >
                        { index === 0
                          ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                          :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                        }

                      </td>
                    )
                  })
                }
                { Object.keys(this.state.itemOrders).length !== 0 &&
                  this.itemsDeliveryCalendarArr(this.state.itemsDeliveryToday)
                }
              </tbody>
            </table>

            </Grid>
            <Grid onClick={() => {
              this.setState({showItemDeliveryOpen: !this.state.showItemDeliveryOpen})
            }} style={{cursor: 'pointer'}} className="auto-width-100 center-align" container justify="center" direction="column">
            <span style={{fontSize: 12, marginTop: 10}}>택배 오픈</span>
            { this.state.showItemDeliveryOpen
              ?<KeyboardArrowUpIcon style={{marginTop: 5, marginLeft: 13}}/>
              :<KeyboardArrowDownIcon style={{marginTop: 5, marginLeft: 13}}/>
            }
            </Grid>
            { this.state.showItemDeliveryOpen &&
              <Grid>
              <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15}} className="direction-column-div">
                <div className="direction-row-div">
                  <span style={{fontSize: 12, width: 200, color: 'gray'}}>상품 종류</span>
                  <span style={{fontSize: 12, width: 70, marginLeft: 63, color: 'gray'}}>오픈 수량</span>
                </div>
              </div>
              <div style={{paddingLeft: 20, paddingRight: 20}} className="direction-column-div">
              { Object.values(this.state.items).map((item, index) => {
                return (
                  <div className="direction-column-div">
                  <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', margin: 0}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.itemMenu[item.id]}  onChange={() => {
                          let itemMenu = this.state.itemMenu
                          if (itemMenu[item.id]) {
                            delete itemMenu[item.id]
                          } else {
                            if (item.discountedPrice) {
                              itemMenu[item.id] = {
                                id: item.id,
                                amount: 0,
                                discountedPrice: item.discountedPrice
                              }
                            } else {
                              itemMenu[item.id] = {
                                id: item.id,
                                amount: 0,
                                discountedPrice: item.price
                              }
                            }

                          }
                          this.setState({itemMenu: itemMenu})
                        }} value="defaultAddress" />
                      }
                      label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>{item.name}</span>}
                    />
                  </FormGroup>
                  { this.state.itemMenu[item.id]
                    ?<Input autoComplete="off" style={{width: 60, marginLeft: 20}} onChange={(event) => {
                      let itemMenu = this.state.itemMenu
                      if (Number(event.target.value.replace(/[^0-9]/g, ''))) {
                        itemMenu[item.id]['amount'] = Number(event.target.value.replace(/[^0-9]/g, ''))
                      } else {
                        itemMenu[item.id]['amount'] = 0
                      }

                      this.setState({itemMenu: itemMenu})
                    }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 수량"  value={this.state.itemMenu[item.id]['amount'] ? this.state.itemMenu[item.id]['amount'] : 0} />
                    :<Input autoComplete="off" disabled={!this.state.itemMenu[item.id]} style={{width: 60,  marginLeft: 20}} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="오픈 수량"  value={''} />
                  }

                  </div>
                  { this.state.itemMenu[item.id] &&
                    <div>
                    <div className="direction-row-div" style={{marginLeft: 27}}>
                      <span style={{fontSize: 13, color: '#6b6b6b', marginTop: 5}}>정가 {this.onChangeCurrencyFormat(item.price)}원 / 할인가</span>
                    <Input autoComplete="off" style={{width: 65, marginLeft: 5}} className="auto-width-100" onChange={(event) => {
                      let itemMenu = this.state.itemMenu
                      if (Number(event.target.value.replace(/[^0-9]/g, ''))) {
                        itemMenu[item.id]['discountedPrice'] = Number(event.target.value.replace(/[^0-9]/g, ''))
                      } else {
                        itemMenu[item.id]['discountedPrice'] = 0
                      }

                      this.setState({itemMenu: itemMenu})
                    }} value={this.state.itemMenu[item.id].discountedPrice} inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
                    InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="할인 가격" />
                    <span style={{fontSize: 13, color: '#6b6b6b', marginTop: 5}}>원</span>
                  </div>
                    </div>

                  }

                  </div>
                )
              })

              }
              </div>
              <div onClick={() => {
                Object.values(this.state.itemMenu).map((item, index) => {
                  this.setState({isOpeningItemDelivery: true})
                  firebase.database().ref(`items/${item.id}/amount`).set(item.amount)
                  if (item.discountedPrice) {
                    if (this.state.items[item.id]['price'] > item.discountedPrice) {
                      if (item.discountedPrice === 0) {
                        firebase.database().ref(`items/${item.id}/discountedPrice`).remove()
                      } else {
                        firebase.database().ref(`items/${item.id}/discountedPrice`).set(item.discountedPrice)
                      }

                    } else {
                      firebase.database().ref(`items/${item.id}/discountedPrice`).remove()
                    }

                  } else {
                    firebase.database().ref(`items/${item.id}/discountedPrice`).remove()
                  }
                  if (item.amount === 0) {
                    firebase.database().ref(`items/${item.id}/open`).set(false)
                  } else {
                    firebase.database().ref(`items/${item.id}/open`).set(true)
                  }
                  if (Object.values(this.state.itemMenu).length === index + 1) {
                    window.location.reload()
                  }
                })
              }} className="btn-big" style={{margin: 15}}>
                { this.state.isOpeningItemDelivery
                  ?<PulseLoader  color={'white'} loading={true} size={5} />
                  :<span style={{fontSize: 13, color: 'white'}}>일반 택배 오픈 및 수정</span>
                }
              </div>


              </Grid>
            }
          </div>
          { false &&
            <div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>정기 구독 결제</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
            </div>
            { this.state.regular &&
              <div className="auto-width-100" style={{paddingLeft: 20, marginBottom: 20}}>
                { this.state.regular.nextPayDate.toString() === moment().format('YYYYMMDD').toString()
                  ?<div onClick={() => {
                    this.onPay()
                  }} className="normal-small-button" style={{width: 150}}>
                    <span style={{color: 'white', fontSize: 13}}>{this.onStringNumberToYYYYMM(this.state.regular.nextPayDate)} 배송건 결제</span>
                  </div>
                  :<div className="normal-small-button-disabled" style={{width: 150}}>
                    <span style={{color: 'white', fontSize: 13}}>{this.onStringNumberToYYYYMM(this.state.regular.nextPayDate)} 배송건 결제</span>
                  </div>
                }
                { this.state.regular.nextPayDate.toString() !== moment().format('YYYYMMDD').toString() &&
                  <span style={{fontSize: 12}}>{this.onStringNumberToYYYYMMDD(this.state.regular.nextPayDate)}에 결제 가능합니다.</span>
                }
              </div>
            }
            { this.state.regular &&
              <div className="auto-width-100" style={{paddingLeft: 20, marginBottom: 20}}>
                { this.state.regular.nextNoticeDate.toString() === moment().format('YYYYMMDD').toString()
                  ?<div onClick={() => {
                    Object.values(this.state.regular.subscribers).map((subscriber, index) => {
                      if (subscriber.pause === false) {
                        axios({
                          url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendRegularPaymentNotice`,
                          method: "post",
                          headers: {
                            "Content-Type":"application/json"
                          },
                          data: {
                            uid: subscriber.uid
                          }
                        })
                        if (Object.values(this.state.regular.subscribers).length === index + 1) {

                        }
                      }
                    })
                  }} className="normal-small-button" style={{width: 200}}>
                    <span style={{color: 'white', fontSize: 13}}>{this.onStringNumberToYYYYMM(this.state.regular.nextNoticeDate)} 배송건 카톡 보내기</span>
                  </div>
                  :<div className="normal-small-button-disabled" style={{width: 200}}>
                    <span style={{color: 'white', fontSize: 13}}>{this.onStringNumberToYYYYMM(this.state.regular.nextNoticeDate)} 배송건 카톡 보내기</span>
                  </div>
                }
                { this.state.regular.nextNoticeDate.toString() !== moment().format('YYYYMMDD').toString() &&
                  <span style={{fontSize: 12}}>{this.onStringNumberToYYYYMMDD(this.state.regular.nextNoticeDate)}에 카톡 전송이 가능합니다.</span>
                }
              </div>
            }
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>정기 구독 송장번호 입력</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
            </div>

            { this.state.regular &&
              <div className="auto-width-100">
              { this.state.regular.deliveries &&
                this.state.regular.deliveries[this.state.regular.nextPayDate]
                ?<div style={{padding: 20, paddingTop: 10}}>
                {Object.values(Object.values(this.state.regular.deliveries)[Object.keys(this.state.regular.deliveries).length - 1]).map((delivery, index) => {
                  return (
                    <div className="auto-width-100 direction-column-div relative-position">
                      <div className="auto-width-100 direction-row-div">
                        <span style={{fontSize: 13}}>{delivery.deliveryAddress['receiver']}</span>
                        <span style={{fontSize: 13, marginLeft: 10}}>{delivery.deliveryAddress['phoneNumber']}</span>
                      </div>
                      <span style={{fontSize: 13}}>{delivery.deliveryAddress['fullAddress']} {delivery.deliveryAddress['detailAddress']}</span>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                          let showRegularDetailInfo = this.state.showRegularDetailInfo
                          if (showRegularDetailInfo[delivery.uid]) {
                            delete showRegularDetailInfo[delivery.uid]
                          } else {
                            showRegularDetailInfo[delivery.uid] = true
                          }

                          this.setState({showRegularDetailInfo: showRegularDetailInfo})
                        }} className="auto-width-100 direction-column-div">
                          <div className="auto-width-100 direction-row-div">
                            <span style={{fontSize: 12}}>상세 정보 보기</span>
                            { this.state.showRegularDetailInfo[delivery.uid]
                              ?<FontAwesomeIcon style={{color: 'black', fontSize: 15, marginLeft: 4}} icon={faCaretUp} />
                                :<FontAwesomeIcon style={{color: 'black', fontSize: 15, marginLeft: 4}} icon={faCaretDown} />
                            }
                          </div>
                          { this.state.showRegularDetailInfo[delivery.uid] &&
                            <div className="auto-width-100 direction-column-div">
                              <span style={{fontSize: 12, fontWeight: 'bold', marginTop: 5}}>[상품 구성]</span>
                              <span style={{fontSize: 12}}>기본패키지</span>
                              { delivery.addition &&
                                <div className="auto-width-100 direction-column-div">
                                  { delivery.addition['twoEggScones'] &&
                                    <span style={{fontSize: 12}}>에그스콘 2개</span>
                                  }
                                  { delivery.addition['twoNunettineScones'] &&
                                    <span style={{fontSize: 12}}>누네띠네 스콘 2개</span>
                                  }
                                  { delivery.addition['plainSauce'] &&
                                    <span style={{fontSize: 12}}>플레인 소스 1세트</span>
                                  }
                                  { delivery.addition['twoPlainSauces'] &&
                                    <span style={{fontSize: 12}}>플레인 소스 2세트</span>
                                  }
                                </div>
                              }
                              <span style={{fontSize: 12, fontWeight: 'bold', marginTop: 5}}>[결제 금액]</span>
                              <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(delivery.paidPrice)}</span>
                            </div>
                          }
                        </div>
                      <div>
                      { this.state.invoiceNumbers[delivery.uid]
                        ?<Input autoComplete="off" style={{width: 100, position: 'absolute', top: 0, right: 0}}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                          let invoiceNumbers = this.state.invoiceNumbers
                          if (event.target.value) {
                            invoiceNumbers[delivery.uid] = {
                              invoiceNumber: event.target.value,
                              uid: delivery.uid
                            }
                          } else {
                            delete invoiceNumbers[delivery.uid]
                          }
                          this.setState({invoiceNumbers: invoiceNumbers})
                        }}
                        InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={this.state.invoiceNumbers[delivery.uid].invoiceNumber} />
                        :<Input autoComplete="off" style={{width: 100, position: 'absolute', top: 0, right: 0}}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                          let invoiceNumbers = this.state.invoiceNumbers
                          if (event.target.value) {
                            invoiceNumbers[delivery.uid] = {
                              invoiceNumber: event.target.value,
                              uid: delivery.uid
                            }
                          } else {
                            delete invoiceNumbers[delivery.uid]
                          }
                          this.setState({invoiceNumbers: invoiceNumbers})
                        }}
                        InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="송장 번호"  value={''} />
                      }

                      </div>
                    </div>
                  )
                })}
                </div>
                :<div style={{padding: 20, paddingTop: 0}}>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>현재 결제된 정기배송 건이 없습니다.</span>
                </div>
              }
              </div>

            }
            { this.state.regular &&
              <div className="auto-width-100">
                { this.state.regular.deliveries &&
                  <div className="auto-width-100">
                    { this.state.regular.deliveries[this.state.regular.nextPayDate] &&
                      <div onClick={() => {
                        if (this.state.regular) {
                          if (this.state.regular.deliveries) {
                            if (this.state.regular.deliveries[this.state.regular.nextPayDate]) {
                              this.setState({isInsertingInvoiceNumbers: true})
                              let invoiceNumbers = this.state.invoiceNumbers
                              let invoiceNumbersLength = Object.keys(invoiceNumbers).length
                              let loopIndex = 0
                              Object.values(invoiceNumbers).map((invoiceNumber, index) => {
                                firebase.database().ref(`regular/deliveries/${this.state.regular.nextPayDate}`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    firebase.database().ref(`users/${invoiceNumber.uid}/regular/deliveries/${this.state.regular.nextPayDate}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                    .then(() => {
                                      if (this.state.providerCode !== '06') {
                                        firebase.database().ref(`users/${invoiceNumber.uid}/regular/deliveries/${this.state.regular.nextPayDate}/providerCode`).set(this.state.providerCode)
                                      }
                                      firebase.database().ref(`regular/deliveries/${this.state.regular.nextPayDate}/${invoiceNumber.uid}/invoiceNumber`).set(Number(invoiceNumber.invoiceNumber))
                                      .then(() => {
                                        if (this.state.providerCode !== '06') {
                                          firebase.database().ref(`regular/deliveries/${this.state.regular.nextPayDate}/${invoiceNumber.uid}/providerCode`).set(this.state.providerCode)
                                        }
                                        loopIndex = loopIndex + 1
                                        if (invoiceNumbersLength === loopIndex) {
                                          this.setState({isInsertingInvoiceNumbers: false})
                                        }
                                      })
                                    })
                                  }
                                })
                              })
                            }
                          }
                        }
                      }} className="btn-big" style={{margin: 15}}>
                        { this.state.isInsertingInvoiceNumbers
                          ?<PulseLoader  color={'white'} loading={true} size={5} />
                          :<span style={{fontSize: 13, color: 'white'}}>송장번호 입력 완료</span>
                        }
                      </div>
                    }
                    <div>

                    </div>
                  </div>
                }

              </div>
            }
            </div>
          }


          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>문자 전송</span>
            <div onClick={() => {

              let users = Object.values(this.state.users)
              let filteredUsers = []
              let i = 0

              filteredUsers = users.filter(users => users['marketing'] === true )
              const uniqueUsers = filteredUsers.reduce((accumulator, current) => {
                if (!accumulator.find((item) => item.phoneNumber === current.phoneNumber)) {
                  accumulator.push(current);
                }
                return accumulator;
              }, []);

              let searchedUsersNumbers = []

              uniqueUsers.map((user, index) => {
                searchedUsersNumbers.push(user['phoneNumber'])

                i = i + 1
                if (uniqueUsers.length === i) {
                  navigator.clipboard.writeText(searchedUsersNumbers)
                  this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                }
              })

            }} className="normal-very-small-button none-underline-text align-center" style={{width: 'auto', marginRight: 20}}>
              <span style={{fontSize: 11, color: 'white'}}>광고 동의 고객</span>
            </div>

          </div>

          <Divider className="auto-width-100"  />
          <div className="auto-width-100 direction-column-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            문자 전송 고객 휴대폰 번호(Enter나 ,(콤마)로 번호 구분)
          </span>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            style={{width: 300}}
            inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                           let messageTo = this.state.messageTo
                           if (event.target.value) {
                             this.setState({messageTo: event.target.value, messageToArray: event.target.value.split(/[ ,]+/).join(',').split('\n').join(',').split(',')})
                           } else {
                             this.setState({messageTo: event.target.value, messageToArray: []})
                           }


                         }} margin={"none"} id="standard-basic"  value={this.state.messageTo}
          />


            { this.state.messageToArray[this.state.messageToArray.length - 1] !== ''
              ?<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.messageToArray.length}명에게 전송 예정
              </span>
              :<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.messageToArray.length - 1}명에게 전송 예정
              </span>
            }
            <span style={{fontSize: 13, marginTop: 20}}>
              보낼 문자 내용
            </span>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              style={{width: 300}}
              inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                             let messageContent = this.state.messageContent
                             if (event.target.value) {
                               this.setState({messageContent: event.target.value})
                             } else {
                               this.setState({messageContent: null})
                             }
                           }} margin={"none"} id="standard-basic"  value={this.state.messageContent}
            />

          </div>
          <div onClick={() => {
            if (this.state.messageToArray.length && this.state.messageContent) {
              this.setState({showSendMessageModal: true})
            }

          }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
            { this.state.isSendingMessage
              ?<PulseLoader  color={'white'} loading={true} size={5} />
              :<span style={{fontSize: 13, color: 'white'}}>문자 전송</span>
            }
          </div>

          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>오프라인 할인 쿠폰 발급</span>
            <div onClick={() => {
              this.setState({isOpenOfflineCouponList: true, prevPosition: window.scrollY})
              if (isEdge || isIE) {
                window.scrollTo(0,  0)
              } else {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }} className="normal-very-small-button none-underline-text align-center" style={{width: 'auto', marginRight: 20}}>
              <span style={{fontSize: 11, color: 'white'}}>쿠폰 발급 내역</span>
            </div>

          </div>
          <Divider className="auto-width-100"  />
          <div className="auto-width-100 direction-column-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            발급 고객 휴대폰 번호(Enter나 ,(콤마)로 번호 구분)
          </span>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            style={{width: 300}}
            inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                           let couponTo = this.state.couponTo
                           if (event.target.value) {
                             this.setState({couponTo: event.target.value, couponToArray: event.target.value.split(/[ ,]+/).join(',').split('\n').join(',').split(',')})
                           } else {
                             this.setState({couponTo: event.target.value, couponToArray: []})
                           }


                         }} margin={"none"} id="standard-basic"  value={this.state.couponTo}
          />

            { this.state.couponToArray[this.state.couponToArray.length - 1] !== ''
              ?<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.couponToArray.length}명에게 전송 예정
              </span>
              :<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.couponToArray.length - 1}명에게 전송 예정
              </span>
            }


          </div>
          <div className="auto-width-100 direction-column-div" style={{margin: 20, marginTop: 10}}>
            <span style={{fontSize: 13, marginTop: 5}}>
              유효기간
            </span>
            <Input autoComplete="off" style={{width: 100}} onChange={(event) => {
              let offlineCouponExpireDate = event.target.value
              this.setState({offlineCouponExpireDate: offlineCouponExpireDate})
            }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}}
            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="쿠폰명"  value={this.state.offlineCouponExpireDate} />
          </div>
          <div className="auto-width-100 direction-row-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            금액
          </span>
          <Input autoComplete="off" style={{width: 90, marginLeft: 15}} multiline={true}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
            let couponAmount = this.state.couponAmount
            this.setState({couponAmount: event.target.value.replace(/[^0-9]/g, '')})
          }}
          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="금액"  value={this.onChangeCurrencyFormat(this.state.couponAmount)} />
          <span style={{fontSize: 13, marginTop: 5}}>
            원
          </span>

          </div>
          <div onClick={() => {
            if (this.state.couponToArray.length) {
              this.setState({showIssueCouponModal: true})
            }

          }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
            { this.state.isIssuingCoupons
              ?<PulseLoader  color={'white'} loading={true} size={5} />
              :<span style={{fontSize: 13, color: 'white'}}>쿠폰 발급</span>
            }
          </div>
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />

          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>온라인 할인 쿠폰 발급</span>
            <div onClick={() => {
              this.setState({isOpenOnlineCouponList: true, prevPosition: window.scrollY})
              if (isEdge || isIE) {
                window.scrollTo(0,  0)
              } else {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }} className="normal-very-small-button none-underline-text align-center" style={{width: 'auto', marginRight: 20}}>
              <span style={{fontSize: 11, color: 'white'}}>온라인 쿠폰 발급 내역</span>
            </div>
          </div>
          <Divider className="auto-width-100" />
          <div className="auto-width-100 direction-column-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            발급 고객 휴대폰 번호(Enter나 ,(콤마)로 번호 구분)
          </span>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            style={{width: 300}}
            inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
                           if (event.target.value) {
                             this.setState({onlineCouponTo: event.target.value, onlineCouponToArray: event.target.value.split(/[ ,]+/).join(',').split('\n').join(',').split(',')})
                           } else {
                             this.setState({onlineCouponTo: event.target.value, onlineCouponToArray: []})
                           }


                         }} margin={"none"} id="standard-basic"  value={this.state.onlineCouponTo}
          />

            { this.state.onlineCouponToArray[this.state.onlineCouponToArray.length - 1] !== ''
              ?<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.onlineCouponToArray.length}명에게 전송 예정
              </span>
              :<span style={{fontSize: 13, marginTop: 10}}>
              {this.state.onlineCouponToArray.length - 1}명에게 전송 예정
              </span>
            }
            <span style={{fontSize: 13, marginTop: 25}}>
              쿠폰 종류
            </span>
            <FormControl component="fieldset">
              <RadioGroup
                onChange={(event) => {
                  this.setState({onlineCouponCategory: event.target.value})
                }}
                defaultValue="scone"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel value="scone" control={<Radio size="small"  color="primary" />} style={{width: 150}} label={<span style={{fontSize: 13}}>스콘 택배</span>} />
              </RadioGroup>
            </FormControl>
            <span style={{fontSize: 13, marginTop: 25}}>
              쿠폰명
            </span>
            <Input autoComplete="off" style={{width: 100}} onChange={(event) => {
              if (event.target.value) {
                this.setState({onlineCouponName: event.target.value})
              } else {
                this.setState({onlineCouponName: null})
              }

            }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}}
            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="쿠폰명"  value={this.state.onlineCouponName} />
            <span style={{fontSize: 13, marginTop: 25}}>
              이벤트 이름
            </span>
            <Input autoComplete="off" style={{width: 100}} onChange={(event) => {
              if (event.target.value) {
                this.setState({onlineCouponEventName: event.target.value})
              } else {
                this.setState({onlineCouponEventName: null})
              }
            }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}}
            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="이벤트 이름"  value={this.state.onlineCouponEventName} />
            <div className="auto-width-100 direction-column-div">
              <span style={{fontSize: 13, marginTop: 25}}>
                유효기간
              </span>
              <Input autoComplete="off" style={{width: 100}} onChange={(event) => {
                let onlineCouponExpireDate = event.target.value
                this.setState({onlineCouponExpireDate: onlineCouponExpireDate})
              }} className="auto-width-100"  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}}
              InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="쿠폰명"  value={this.state.onlineCouponExpireDate} />
            </div>
          </div>

          <div className="auto-width-100 direction-row-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            금액
          </span>
          <Input autoComplete="off" style={{width: 90, marginLeft: 15}} multiline={true}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
            let onlineCouponAmount = this.state.onlineCouponAmount
            this.setState({onlineCouponAmount: event.target.value.replace(/[^0-9]/g, '')})
          }}
          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="금액"  value={this.onChangeCurrencyFormat(this.state.onlineCouponAmount)} />
          <span style={{fontSize: 13, marginTop: 5}}>
            원
          </span>
          
          </div>
          <div className="auto-width-100 direction-row-div" style={{margin: 20, marginTop: 10}}>
          <span style={{fontSize: 13, marginTop: 5}}>
            최소주문금액
          </span>
          <Input autoComplete="off" style={{width: 90, marginLeft: 15}} multiline={true}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}, inputMode: 'numeric'}} onChange={(event) => {
            let onlineCouponMinimumAmount = this.state.onlineCouponMinimumAmount
            this.setState({onlineCouponMinimumAmount: event.target.value.replace(/[^0-9]/g, '')})
          }}
          InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="금액"  value={this.onChangeCurrencyFormat(this.state.onlineCouponMinimumAmount)} />
          <span style={{fontSize: 13, marginTop: 5}}>
            원
          </span>
          
          </div>
          <div className='auto-width-100'>
          <FormGroup  style={{margin: 20}} >
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.isCheckedSendExpiringOnlineCoupon}  onChange={() => {
                  this.setState({isCheckedSendExpiringOnlineCoupon: !this.state.isCheckedSendExpiringOnlineCoupon})
                }} />
              }
              label={<span className="one-line" style={{ fontSize: 12, width: 200 }}>유효기간 만료 알림 전송</span>}
            />
          </FormGroup>
          </div>
          <div onClick={() => {
            if (this.state.onlineCouponToArray.length) {
              this.setState({showIssueOnlineCouponModal: true})
            }

          }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
            { this.state.isIssuingOnlineCoupons
              ?<PulseLoader  color={'white'} loading={true} size={5} />
              :<span style={{fontSize: 13, color: 'white'}}>온라인 쿠폰 발급</span>
            }
          </div>
          <div onClick={() => {
            this.setState({showSendingExpiredDateCouponModal: true})

          }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
            { this.state.isSendingExpiredDateMessage
              ?<PulseLoader  color={'white'} loading={true} size={5} />
              :<span style={{fontSize: 13, color: 'white'}}>유효기간 카톡 전송</span>
            }
          </div>
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />

          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>리뷰 관리</span>
            <Divider className="auto-width-100" style={{marginTop: 20}} />
            <div className="auto-width-100 direction-row-div justify-content-center" style={{marginTop: 10}}>
              <div onClick={() => {
                firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    let reviews = []
                    let sconeMenu = snapshot.val()
                    Object.keys(sconeMenu).map((menu, index) => {
                      if (sconeMenu[menu].reviews) {
                        reviews = reviews.concat(Object.values(sconeMenu[menu].reviews))
                      }
                      if (index + 1 === Object.keys(sconeMenu).length) {
                        reviews.sort((a, b) => {
                          return b.createdAt - a.createdAt
                        })
                        this.setState({ reviews: reviews})
                      }
                    })
                  }
                }).then(() => {
                  this.setState({isOpenReviews: true, prevPosition: window.scrollY})
                  if (isEdge || isIE) {
                    window.scrollTo(0,  0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
                })

              }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
                <span style={{fontSize: 13, color: 'white'}}>스콘 리뷰 보기</span>
              </div>
            </div>
            <div className="auto-width-100 direction-row-div justify-content-center" style={{marginTop: 10}}>
              <div onClick={() => {
                firebase.database().ref(`items`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    let itemReviews = []
                    let items = snapshot.val()
                    Object.keys(items).map((item, index) => {
                      if (items[item].reviews) {
                        itemReviews = itemReviews.concat(Object.values(items[item].reviews))
                      }
                      if (index + 1 === Object.keys(items).length) {
                        itemReviews.sort((a, b) => {
                          return b.createdAt - a.createdAt
                        })
                        this.setState({ itemReviews: itemReviews})
                      }
                    })
                  }
                }).then(() => {
                  this.setState({isOpenItemReviews: true, prevPosition: window.scrollY})
                  if (isEdge || isIE) {
                    window.scrollTo(0,  0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
                })

              }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
                <span style={{fontSize: 13, color: 'white'}}>상품 리뷰 보기</span>
              </div>
            </div>
          </div>
        </Grid>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />

        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>회원 정보 관리</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />

          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempOverOneOrdersChecked}  onChange={() => {
                  this.setState({tempOverOneOrdersChecked: !this.state.tempOverOneOrdersChecked})
                }} value={this.state.tempOverOneOrdersChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>첫 주문자(3달 이내)</span>}
            />
          </FormGroup>

          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempNotGet1CouponChecked}  onChange={() => {
                  this.setState({tempNotGet1CouponChecked: !this.state.tempNotGet1CouponChecked})
                }} value={this.state.tempNotGet1CouponChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>첫 주문자 쿠폰 미지급자(3달 이내)</span>}
            />
          </FormGroup>

          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempOverThreeOrdersChecked}  onChange={() => {
                  this.setState({tempOverThreeOrdersChecked: !this.state.tempOverThreeOrdersChecked})
                }} value={this.state.tempOverThreeOrdersChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>3회 이상 주문자</span>}
            />
          </FormGroup>

          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempNotGet3CouponChecked}  onChange={() => {
                  this.setState({tempNotGet3CouponChecked: !this.state.tempNotGet3CouponChecked})
                }} value={this.state.tempNotGet3CouponChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>3회 재주문 쿠폰 미지급자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempOverSixOrdersChecked}  onChange={() => {
                  this.setState({tempOverSixOrdersChecked: !this.state.tempOverSixOrdersChecked})
                }} value={this.state.tempOverSixOrdersChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>6회 이상 주문자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempNotGet6CouponChecked}  onChange={() => {
                  this.setState({tempNotGet6CouponChecked: !this.state.tempNotGet6CouponChecked})
                }} value={this.state.tempNotGet6CouponChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>6회 재주문 쿠폰 미지급자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempOverNineOrdersChecked}  onChange={() => {
                  this.setState({tempOverNineOrdersChecked: !this.state.tempOverNineOrdersChecked})
                }} value={this.state.tempOverNineOrdersChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>9회 이상 주문자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempNotGet9CouponChecked}  onChange={() => {
                  this.setState({tempNotGet9CouponChecked: !this.state.tempNotGet9CouponChecked})
                }} value={this.state.tempNotGet9CouponChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>9회 재주문 쿠폰 미지급자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempOverFivechecked}  onChange={() => {
                  this.setState({tempOverFivechecked: !this.state.tempOverFivechecked})
                }} value={this.state.tempOverFivechecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>총 50만원 이상 주문자</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempGoldChecked}  onChange={() => {
                  this.setState({tempGoldChecked: !this.state.tempGoldChecked})
                  if (this.state.tempVipChecked) {
                    this.setState({tempVipChecked: false})
                  }
                }} value={this.state.tempGoldChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>GOLD</span>}
            />
          </FormGroup>
          <FormGroup  style={{alignItems: 'center', margin: 0, marginLeft: 20}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.tempVipChecked}  onChange={() => {
                  this.setState({tempVipChecked: !this.state.tempVipChecked})
                  if (this.state.tempGoldChecked) {
                    this.setState({tempGoldChecked: false})
                  }
                }} value={this.state.tempVipChecked} />
              }
              label={<span className="one-line" style={{ fontSize: 12 }}>VIP</span>}
            />
          </FormGroup>
          <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
            <div onClick={() => {
              let searchedUsersNumbers = []

              let i = 0

              if (this.state.filteredUsers.length) {
                this.state.filteredUsers.map((user, index) => {
                  i = i + 1
                  if (this.state.overFivechecked) {
                    if (user['sconeOrders']) {
                      if (this.state.overFiveUsers[user.uid]) {
                        if (user['phoneNumber']) {
                          searchedUsersNumbers.push(user['phoneNumber'])
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        } else {
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        }
                      } else {
                        if (this.state.filteredUsers.length === i) {
                          navigator.clipboard.writeText(searchedUsersNumbers)
                          this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                        }
                      }
                    } else {
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    }
                  } else if (this.state.overSixOrdersChecked) {
                    if (user['sconeOrders']) {
                      if (this.onGetNumberOfPurchase(user['sconeOrders']) > 5) {
                        if (user['phoneNumber']) {
                          searchedUsersNumbers.push(user['phoneNumber'])
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        } else {
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        }
                      } else {
                        if (this.state.filteredUsers.length === i) {
                          navigator.clipboard.writeText(searchedUsersNumbers)
                          this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                        }
                      }
                    } else {
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    }
                  } else if (this.state.overThreeOrdersChecked) {
                    if (user['sconeOrders']) {
                      if (this.onGetNumberOfPurchase(user['sconeOrders']) > 2) {
                        if (user['phoneNumber']) {
                          searchedUsersNumbers.push(user['phoneNumber'])
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        } else {
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        }
                      } else {
                        if (this.state.filteredUsers.length === i) {
                          navigator.clipboard.writeText(searchedUsersNumbers)
                          this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                        }
                      }
                    } else {
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    }
                  } else if (this.state.overOneOrdersChecked) {
                    if (user['sconeOrders']) {
                      if (this.onGetNumberOfPurchase(user['sconeOrders']) === 1) {
                        if (user['phoneNumber']) {
                          searchedUsersNumbers.push(user['phoneNumber'])
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        } else {
                          if (this.state.filteredUsers.length === i) {
                            navigator.clipboard.writeText(searchedUsersNumbers)
                            this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                          }
                        }
                      } else {
                        if (this.state.filteredUsers.length === i) {
                          navigator.clipboard.writeText(searchedUsersNumbers)
                          this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                        }
                      }
                    } else {
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    }
                  } else {
                    if (user['phoneNumber']) {
                      searchedUsersNumbers.push(user['phoneNumber'])
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    } else {
                      if (this.state.filteredUsers.length === i) {
                        navigator.clipboard.writeText(searchedUsersNumbers)
                        this.setState({copiedEntirePhoneNumbersSnack: true, searchedUsersNumbers: searchedUsersNumbers})
                      }
                    }
                  }
                })
              }


            }} style={{marginTop: 10, width: 150}} className={"normal-very-small-button justify-content-center direction-row-div"}>
              <span style={{color: 'white', fontSize: 13}}>전체 전화번호 복사</span>
              <FontAwesomeIcon style={{color: 'white', fontSize: 12, marginTop: 3, marginLeft: 3}} icon={faCopy} />
            </div>
          </div>
          { this.state.filteredUsers.length ?
            <div style={{marginTop: 20, marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
              <div className="auto-width-100 direction-column-div" style={{paddingLeft: 20, paddingRight: 20}}>
              { this.state.filteredUsers.map((user, index) => {
                if (this.state.overFivechecked) {
                  if (user['sconeOrders']) {
                    if (this.state.overFiveUsers[user.uid]) {
                      return (
                        <div style={{marginBottom: 5}}>
                          <span onClick={() => {
                            this.setState({showUserDetail: true, userDetailInfo: user, prevPosition: window.scrollY})
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }} style={{fontSize: 13, cursor: 'pointer'}}>{user.username} {user.email} {user.phoneNumber}</span>
                        </div>
                      )
                    }

                  }
                } else if (this.state.overSixOrdersChecked) {
                  if (user['sconeOrders']) {
                    if (this.onGetNumberOfPurchase(user['sconeOrders']) > 5) {
                      return (
                        <div style={{marginBottom: 5}}>
                          <span onClick={() => {
                            this.setState({showUserDetail: true, userDetailInfo: user, prevPosition: window.scrollY})
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }} style={{fontSize: 13, cursor: 'pointer'}}>{user.username} {user.email} {user.phoneNumber}</span>
                        </div>
                      )
                    }
                  }
                } else if (this.state.overThreeOrdersChecked) {
                  if (user['sconeOrders']) {
                    if (this.onGetNumberOfPurchase(user['sconeOrders']) > 2) {
                      return (
                        <div style={{marginBottom: 5}}>
                          <span onClick={() => {
                            this.setState({showUserDetail: true, userDetailInfo: user, prevPosition: window.scrollY})
                            if (isEdge || isIE) {
                              window.scrollTo(0,  0)
                            } else {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }} style={{fontSize: 13, cursor: 'pointer'}}>{user.username} {user.email} {user.phoneNumber}</span>
                        </div>
                      )
                    }
                  }
                } else {
                  return (
                    <div style={{marginBottom: 5}}>
                      <span onClick={() => {
                        this.setState({showUserDetail: true, userDetailInfo: user, prevPosition: window.scrollY})
                        if (isEdge || isIE) {
                          window.scrollTo(0,  0)
                        } else {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })
                        }
                      }} style={{fontSize: 13, cursor: 'pointer'}}>{user.username} {user.email} {user.phoneNumber}</span>
                    </div>
                  )
                }



              })
              }
              </div>
            </div>
            :<div>
            </div>
          }

          <div style={{marginTop: 20, marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">

            <Input autoComplete="off" style={{width: 170, marginRight: 10}} onClick={(e) => {
              e.stopPropagation();
            }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
              if (event.target.value) {
                this.setState({searchUserTerm: event.target.value})
              } else {
                this.setState({searchUserTerm: ''})
              }
            }}
            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="검색"  />
          </div>
          <div style={{marginBottom: 20}} className="auto-width-100 justify-content-center direction-row-div">
          <span style={{fontSize: 12}}>이름, 전화번호, 이메일 주소 등을 입력해주세요.</span>
          </div>
          <div className="auto-width-100 direction-row-div justify-content-center" style={{marginTop: 10}}>
            <div onClick={() => {
              let users = Object.values(this.state.users)
              let filteredUsers = []

              filteredUsers = users.filter(users => users['email'].indexOf(this.state.searchUserTerm) > -1 )
              filteredUsers = filteredUsers.concat(users.filter(users => users['username'].indexOf(this.state.searchUserTerm) > -1 ))
              filteredUsers = filteredUsers.concat(users.filter(users => users['phoneNumber'].indexOf(this.state.searchUserTerm) > -1 ))

              filteredUsers = [...new Set(filteredUsers)];

              if (this.state.tempOverOneOrdersChecked || this.state.tempOverThreeOrdersChecked || this.state.tempOverSixOrdersChecked || this.state.tempOverFivechecked) {
                filteredUsers = filteredUsers.filter(filteredUsers => filteredUsers['sconeOrders'] )
              }

              this.setState({overFiveUsers: {}})
              setTimeout(() => {
                if (this.state.tempOverFivechecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => filteredUsers['sconeOrders'] )
                  filteredUsers.map((user, index) => {
                    if (this.onGetNumberOfPurchase(user['sconeOrders']) >= 1) {
                      if (this.state.tempOverNineOrdersChecked) {
                        if (this.onGetNumberOfPurchase(user['sconeOrders']) > 8) {
                          this.onGetAmountOffPurchase(user['sconeOrders'], user.uid)
                        }
                      } else if (this.state.tempOverSixOrdersChecked) {
                        if (this.onGetNumberOfPurchase(user['sconeOrders']) > 5) {
                          this.onGetAmountOffPurchase(user['sconeOrders'], user.uid)
                        }
                      } else if (this.state.tempOverThreeOrdersChecked) {
                        if (this.onGetNumberOfPurchase(user['sconeOrders']) > 2) {
                          this.onGetAmountOffPurchase(user['sconeOrders'], user.uid)
                        }
                      } else if (this.state.tempOverOneOrdersChecked) {
                        if (this.onGetNumberOfPurchase(user['sconeOrders']) === 1) {
                          this.onGetAmountOffPurchase(user['sconeOrders'], user.uid)
                        }
                      } else {
                        this.onGetAmountOffPurchase(user['sconeOrders'], user.uid)
                      }
                    }
                  })
                }
              }, 100)

              if (this.state.tempNotGet1CouponChecked) {

                let filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => filteredUsers['coupons'] )
                let onlineCouponList = this.state.onlineCouponList

                filteredUsersWithCoupons = filteredUsersWithCoupons.map(value => Object.values(value.coupons));

                filteredUsersWithCoupons = filteredUsersWithCoupons.map((value, index) => {
                  return value.find(objectValue => (objectValue.eventName === '첫 주문 감사 쿠폰' || objectValue.eventName === '첫 주문 감사'));
                })

                onlineCouponList = onlineCouponList.filter(onlineCouponList => onlineCouponList['eventName'] === '첫 주문 감사 쿠폰' || onlineCouponList['eventName'] === '첫 주문 감사')

                let filteredOnlineCouponListNumbers = onlineCouponList.map(onlineCouponList => onlineCouponList['phoneNumber'] )

                let onlineCouponsObj = filteredOnlineCouponListNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);
                let filteredUsersWithCouponsPhoneNumbers = filteredUsersWithCoupons.map(filteredUsersWithCoupons => filteredUsersWithCoupons['phoneNumber'] )

                let obj = filteredUsersWithCouponsPhoneNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                if (this.state.tempOverOneOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) === 1 )
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetFirstPurchaseDate(filteredUsers['sconeOrders']) < (1000 * 60 * 60 * 24 * 90) )
                } else if (this.state.tempOverThreeOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 2 )
                } else if (this.state.tempOverSixOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 5 )
                } else if (this.state.tempOverNineOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 8 )
                }



                filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => !obj[filteredUsers['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => !onlineCouponsObj[filteredUsersWithCoupons['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsers = filteredUsersWithCoupons
              }

              if (this.state.tempNotGet3CouponChecked) {

                let filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => filteredUsers['coupons'] )
                let onlineCouponList = this.state.onlineCouponList

                filteredUsersWithCoupons = filteredUsersWithCoupons.map(value => Object.values(value.coupons));

                filteredUsersWithCoupons = filteredUsersWithCoupons.map((value, index) => {
                  return value.find(objectValue => (objectValue.eventName === '3회이상 재주문 감사 쿠폰' || objectValue.eventName === '3회이상 재주문 감사' || objectValue.eventName === '3회이상 재주문 감' || objectValue.eventName === '단골고객감사'));
                })

                onlineCouponList = onlineCouponList.filter(onlineCouponList => onlineCouponList['eventName'] === '3회이상 재주문 감사 쿠폰' || onlineCouponList['eventName'] === '3회이상 재주문 감사' || onlineCouponList['eventName'] === '3회이상 재주문 감' || onlineCouponList['eventName'] === '단골고객감사')

                let filteredOnlineCouponListNumbers = onlineCouponList.map(onlineCouponList => onlineCouponList['phoneNumber'] )

                let onlineCouponsObj = filteredOnlineCouponListNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);
                let filteredUsersWithCouponsPhoneNumbers = filteredUsersWithCoupons.map(filteredUsersWithCoupons => filteredUsersWithCoupons['phoneNumber'] )

                let obj = filteredUsersWithCouponsPhoneNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                if (this.state.tempOverOneOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) === 1 )
                } else if (this.state.tempOverThreeOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 2 )
                } else if (this.state.tempOverSixOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 5 )
                } else if (this.state.tempOverNineOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 8 )
                }



                filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => !obj[filteredUsers['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => !onlineCouponsObj[filteredUsersWithCoupons['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsers = filteredUsersWithCoupons
              }

              if (this.state.tempNotGet6CouponChecked) {

                let filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => filteredUsers['coupons'] )
                let onlineCouponList = this.state.onlineCouponList

                filteredUsersWithCoupons = filteredUsersWithCoupons.map(value => Object.values(value.coupons));

                filteredUsersWithCoupons = filteredUsersWithCoupons.map((value, index) => {
                  return value.find(objectValue => (objectValue.eventName === '6회이상 재주문 감사 쿠폰' || objectValue.eventName === '6회이상 재주문 감사'));
                })

                onlineCouponList = onlineCouponList.filter(onlineCouponList => onlineCouponList['eventName'] === '6회이상 재주문 감사 쿠폰' || onlineCouponList['eventName'] === '6회이상 재주문 감사')

                let filteredOnlineCouponListNumbers = onlineCouponList.map(onlineCouponList => onlineCouponList['phoneNumber'] )

                let onlineCouponsObj = filteredOnlineCouponListNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);
                let filteredUsersWithCouponsPhoneNumbers = filteredUsersWithCoupons.map(filteredUsersWithCoupons => filteredUsersWithCoupons['phoneNumber'] )

                let obj = filteredUsersWithCouponsPhoneNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                if (this.state.tempOverOneOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) === 1 )
                } else if (this.state.tempOverThreeOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 2 )
                } else if (this.state.tempOverSixOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 5 )
                } else if (this.state.tempOverNineOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 8 )
                }



                filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => !obj[filteredUsers['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => !onlineCouponsObj[filteredUsersWithCoupons['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsers = filteredUsersWithCoupons
              }

              if (this.state.tempNotGet9CouponChecked) {

                let filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => filteredUsers['coupons'] )
                let onlineCouponList = this.state.onlineCouponList

                filteredUsersWithCoupons = filteredUsersWithCoupons.map(value => Object.values(value.coupons));

                filteredUsersWithCoupons = filteredUsersWithCoupons.map((value, index) => {
                  return value.find(objectValue => (objectValue.eventName === '9회이상 재주문 감사 쿠폰' || objectValue.eventName === '9회이상 재주문 감사'));
                })

                onlineCouponList = onlineCouponList.filter(onlineCouponList => onlineCouponList['eventName'] === '9회이상 재주문 감사 쿠폰' || onlineCouponList['eventName'] === '9회이상 재주문 감사')

                let filteredOnlineCouponListNumbers = onlineCouponList.map(onlineCouponList => onlineCouponList['phoneNumber'] )

                let onlineCouponsObj = filteredOnlineCouponListNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);
                let filteredUsersWithCouponsPhoneNumbers = filteredUsersWithCoupons.map(filteredUsersWithCoupons => filteredUsersWithCoupons['phoneNumber'] )

                let obj = filteredUsersWithCouponsPhoneNumbers.reduce(function(o, val) { o[val] = val; return o; }, {});

                if (this.state.tempOverOneOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) === 1 )
                } else if (this.state.tempOverThreeOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 2 )
                } else if (this.state.tempOverSixOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 5 )
                } else if (this.state.tempOverNineOrdersChecked) {
                  filteredUsers = filteredUsers.filter(filteredUsers => this.onGetNumberOfPurchase(filteredUsers['sconeOrders']) > 8 )
                }



                filteredUsersWithCoupons = filteredUsers.filter(filteredUsers => !obj[filteredUsers['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => !onlineCouponsObj[filteredUsersWithCoupons['phoneNumber']]);
                filteredUsersWithCoupons = filteredUsersWithCoupons.filter(filteredUsersWithCoupons => filteredUsersWithCoupons !== undefined);

                filteredUsers = filteredUsersWithCoupons
              }





              if (this.state.tempGoldChecked) {
                let userClassIndex = 0
                let userInfos = this.state.userInfos
                let userClassFilteredUsers = {}

                filteredUsers.map(async(filteredUsersInfo, index) => {
                  if (userInfos[filteredUsersInfo['uid']]) {
                    userInfos[filteredUsersInfo['uid']]['userClass'] = await this.onGetUserClass(userInfos[filteredUsersInfo['uid']]['sconeOrders'], userInfos[filteredUsersInfo['uid']]['itemOrders'], filteredUsersInfo['uid'])
                    userClassFilteredUsers[filteredUsersInfo['uid']] = userInfos[filteredUsersInfo['uid']]
                    userClassIndex = userClassIndex + 1
                    if (filteredUsers.length === userClassIndex) {
                      filteredUsers = Object.values(userClassFilteredUsers).filter(userClassFilteredUsers => userClassFilteredUsers['userClass'] === 2 )
                      this.setState({filteredUsers: filteredUsers})
                    }
                  }

                } );
              }

              if (this.state.tempVipChecked) {
                let userClassIndex = 0
                let userInfos = this.state.userInfos
                let userClassFilteredUsers = {}

                filteredUsers.map(async(filteredUsersInfo, index) => {
                  if (userInfos[filteredUsersInfo['uid']]) {
                    userInfos[filteredUsersInfo['uid']]['userClass'] = await this.onGetUserClass(userInfos[filteredUsersInfo['uid']]['sconeOrders'], userInfos[filteredUsersInfo['uid']]['itemOrders'], filteredUsersInfo['uid'])
                    userClassFilteredUsers[filteredUsersInfo['uid']] = userInfos[filteredUsersInfo['uid']]
                    userClassIndex = userClassIndex + 1
                    if (filteredUsers.length === userClassIndex) {
                      filteredUsers = Object.values(userClassFilteredUsers).filter(userClassFilteredUsers => userClassFilteredUsers['userClass'] === 3 )
                      this.setState({filteredUsers: filteredUsers})
                    }
                  }

                } );
              }
              this.setState({notGet1CouponChecked: this.state.tempNotGet1CouponChecked})
              this.setState({overOneOrdersChecked: this.state.tempOverOneOrdersChecked})
              this.setState({overThreeOrdersChecked: this.state.tempOverThreeOrdersChecked})
              this.setState({notGet3CouponChecked: this.state.tempNotGet3CouponChecked})
              this.setState({notGet6CouponChecked: this.state.tempNotGet6CouponChecked})
              this.setState({notGet6CouponChecked: this.state.tempNotGet9CouponChecked})
              this.setState({overSixOrdersChecked: this.state.tempOverSixOrdersChecked})
              this.setState({overFivechecked: this.state.tempOverFivechecked})

              this.setState({filteredUsers: filteredUsers})
            }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
              <span style={{fontSize: 13, color: 'white'}}>검색</span>
            </div>
          </div>
        </div>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />

        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>토스 페이먼츠 결제 취소</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <Grid style={{marginTop: 30, paddingLeft: 20}} className="auto-width-90 direction-column-div">
            <FormControl component="fieldset">
              <RadioGroup
                onChange={(event) => {
                  this.setState({paymentCancel: event.target.value})
                }}
                defaultValue="entire"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel value="entire" control={<Radio size="small"  color="primary" />} style={{width: 150}} label={<span style={{fontSize: 13}}>전체 취소</span>} />
                <FormControlLabel value="partly" control={<Radio size="small"  color="primary" />} style={{width: 150}} label={<span style={{fontSize: 13}}>부분 취소</span>} />
              </RadioGroup>
            </FormControl>
            <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
            <span style={{fontSize: 13, marginRight: 10, marginTop: 5}}>주문 번호</span>
            <Input autoComplete="off" style={{width: 150}} onClick={(e) => {
              e.stopPropagation();
            }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
              if (event.target.value) {
                this.setState({tossPaymentsOrderId: event.target.value})
              } else {
                this.setState({tossPaymentsOrderId: null})
              }
            }}
            InputLabelProps={{style: {fontSize: 13}}} margin={"none"} id="standard-basic" label="주문 번호"  />

            </div>
            { this.state.paymentCancel === 'partly' &&
              <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
              <span style={{fontSize: 13, marginRight: 10, marginTop: 5}}>취소 금액</span>
              <Input autoComplete="off" style={{width: 150}} onClick={(e) => {
                e.stopPropagation();
              }}  inputProps={{style: {fontSize: 13, color: '#6b6b6b'}}} onChange={(event) => {
                if (event.target.value) {
                  this.setState({partiallyCancelAmount: Number(event.target.value.replace(/[^0-9]/g, ''))})
                } else {
                  this.setState({partiallyCancelAmount: 0})
                }
              }}
              InputLabelProps={{style: {fontSize: 13} , inputMode: 'numeric'}} margin={"none"} id="standard-basic" label="주문 번호"  />
              </div>
            }
            <div style={{marginBottom: 10}} className="auto-width-100 justify-content-center direction-row-div">
            <div className="auto-width-100 direction-row-div justify-content-center" style={{marginTop: 10}}>
              <div onClick={() => {
                if (this.state.tossPaymentsOrderId) {
                  if (this.state.paymentCancel === 'entire') {
                    this.setState({showCancelPaymenteModal: true})
                  } else {
                    if (this.state.partiallyCancelAmount !== 0) {
                      this.setState({showParttiallyCancelPaymenteModal: true})
                    } else {
                      alert('부분 취소할 금액을 입력해주세요.')
                    }

                  }

                }
              }} className="normal-small-button" style={{width: 150, marginBottom: 15}}>
                <span style={{fontSize: 13, color: 'white'}}>결제 취소</span>
              </div>
            </div>
            </div>
          </Grid>
        </div>
        <Snackbar open={this.state.copiedEntirePhoneNumbersSnack} autoHideDuration={4000} onClose={() => this.setState({copiedEntirePhoneNumbersSnack: false})}>
          <Alert  onClose={() => this.setState({copiedEntirePhoneNumbersSnack: false})} severity="success">
            전체 전화번호 복사 완료
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.changedOrderOpenDateSnack} autoHideDuration={4000} onClose={() => this.setState({changedOrderOpenDateSnack: false})}>
          <Alert  onClose={() => this.setState({changedOrderOpenDateSnack: false})} severity="success">
            택배 오픈 예정일이 변경되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completeUsingCouponSnack} autoHideDuration={4000} onClose={() => this.setState({completeUsingCouponSnack: false})}>
          <Alert  onClose={() => this.setState({completeUsingCouponSnack: false})} severity="success">
            쿠폰이 사용처리 되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completeIssueCouponSnack} autoHideDuration={4000} onClose={() => this.setState({completeIssueCouponSnack: false})}>
          <Alert  onClose={() => this.setState({completeIssueCouponSnack: false})} severity="success">
            쿠폰이 발급 되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completeIssueOnlineCouponSnack} autoHideDuration={4000} onClose={() => this.setState({completeIssueOnlineCouponSnack: false})}>
          <Alert  onClose={() => this.setState({completeIssueOnlineCouponSnack: false})} severity="success">
            온라인 쿠폰이 발급 되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completeSendMessageSnack} autoHideDuration={4000} onClose={() => this.setState({completeSendMessageSnack: false})}>
          <Alert  onClose={() => this.setState({completeSendMessageSnack: false})} severity="success">
            메세지가 전송 되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completeCancelPaymentSnack} autoHideDuration={4000} onClose={() => this.setState({completeCancelPaymentSnack: false})}>
          <Alert  onClose={() => this.setState({completeCancelPaymentSnack: false})} severity="success">
            결제가 취소되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.completePartialllyCancelPaymentSnack} autoHideDuration={4000} onClose={() => this.setState({completePartialllyCancelPaymentSnack: false})}>
          <Alert  onClose={() => this.setState({completePartialllyCancelPaymentSnack: false})} severity="success">
            결제가 부분 취소되었습니다.
          </Alert>
        </Snackbar>
        <Modal
          open={this.state.showCancelPaymenteModal}
          onClose={() => this.setState({showCancelPaymenteModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>해당 결제를 취소하시겠습니까?</span>
          { this.state.tossPaymentsOrderId &&
            <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
              <span style={{fontSize: 14}}>주문 번호: {this.state.tossPaymentsOrderId}</span>
            </div>
          }
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showCancelPaymenteModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.onCancelPayment()
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>결제 취소</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showParttiallyCancelPaymenteModal}
          onClose={() => this.setState({showParttiallyCancelPaymenteModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>해당 결제를 {this.state.partiallyCancelAmount}원 부분 취소하시겠습니까?</span>
          { this.state.tossPaymentsOrderId &&
            <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
              <span style={{fontSize: 14}}>주문 번호: {this.state.tossPaymentsOrderId}</span>
            </div>
          }
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showParttiallyCancelPaymenteModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.onCancelPaymentPartially()
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>결제 취소</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showStartDeliveryOrderModal}
          onClose={() => this.setState({showStartDeliveryOrderModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>택배 주문을 시작하시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showStartDeliveryOrderModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            let orderSheets = this.state.orderSheets
            this.setState({showStartDeliveryOrderModal: false})

            if (orderSheets[moment(Date.now()).format('YYYYMMDD').toString()]) {
              firebase.database().ref(`orderSheets/${moment(Date.now()).format('YYYYMMDD').toString()}/sentAlarmMessage`).once('value', (snapshot) => {
                if (!snapshot.val()) {
                  firebase.database().ref(`orderSheets/${moment(Date.now()).format('YYYYMMDD').toString()}/open`).set(true)
                  firebase.database().ref(`orderSheets/${moment(Date.now()).format('YYYYMMDD').toString()}/sentAlarmMessage`).set(true)
                  firebase.database().ref(`alarms/sconeDelivery`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let userListSetAlarm = Object.values(snapshot.val())
                      userListSetAlarm = userListSetAlarm.filter(userListSetAlarm => userListSetAlarm.set === true)
                      if (userListSetAlarm.length) {
                        this.setState({isStartingDeliveryOrder: true})
                        userListSetAlarm.map((user, index) => {
                          axios({
                            url: `https://sendsconedeliveryopenalarmmessage-v2-xv3aig33rq-uc.a.run.app`,
                            method: "post",
                            headers: {
                              "Content-Type":"application/json"
                            },
                            data: {
                              phoneNumber: user.phoneNumber,
                              username: this.state.users[user.uid].username,
                              openHour: this.state.openHour
                            }
                          })
                          if (userListSetAlarm.length === index + 1) {
                            this.setState({isStartingDeliveryOrder: false})
                          }
                        })
                      }
                    }
                  })
                }
              })

            }
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>시작</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showSendMessageModal}
          onClose={() => this.setState({showSendMessageModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>문자 메세지를 전송하시겠습니까?</span>
          { this.state.couponTo &&
            <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
              <span style={{fontSize: 14}}>인원: 총 {this.state.messageToArray.length}명</span>
            </div>
          }
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showSendMessageModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({showSendMessageModal: false, isSendingMessage: true})
            let sentSMS = {}
            let date = Date.now()
            sentSMS = {
              sentAt: date,
              message: this.state.messageContent,
              to: this.state.messageToArray
            }
            firebase.database().ref(`sentSMS/${date}`).set(sentSMS)
            this.state.messageToArray.map((messageTo, index) => {
              this.onSendMessage(messageTo, index)
            })

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>전송</span>
          </div>
          </Grid>
          </div>
        </Modal>

        <Modal
          open={this.state.showSendingExpiredDateCouponModal}
          onClose={() => this.setState({showSendingExpiredDateCouponModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>쿠폰 만료 알림을 보내시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showSendingExpiredDateCouponModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({showSendingExpiredDateCouponModal: false, isSendingExpiredDateMessage: true})
            this.onSendExpieredCouponMessage()

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>전송</span>
          </div>
          </Grid>
          </div>
        </Modal>

        <Modal
          open={this.state.showIssueOnlineCouponModal}
          onClose={() => this.setState({showIssueOnlineCouponModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>온라인 쿠폰을 발급하시겠습니까?</span>
          { this.state.onlineCouponTo &&
            <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
              <span style={{fontSize: 14}}>인원: 총 {this.state.onlineCouponToArray.length}명</span>
              <span style={{fontSize: 14}}>금액: 각 {this.onChangeCurrencyFormat(this.state.onlineCouponAmount)}원</span>
              { this.state.onlineCouponMinimumAmount &&
                <span style={{fontSize: 14}}>최소주문금액: 각 {this.onChangeCurrencyFormat(this.state.onlineCouponMinimumAmount)}원</span>
              }
            </div>
          }
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showIssueOnlineCouponModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({showIssueOnlineCouponModal: false, isIssuingOnlineCoupons: true})
            this.state.onlineCouponToArray.map((couponTo, index) => {
              let couponId = shortid.generate()
              if (this.state.isCheckedSendExpiringOnlineCoupon) {
                this.onSendExpiringOnlineCoupon(couponTo, this.state.onlineCouponEventName, index)
              } else {
                this.onIssueOnlineCoupon(couponId, couponTo, index, this.state.onlineCouponCategory, moment(Date.now() + (24 * 1000 * 60 * 60 * this.state.onlineCouponExpireDate)).format('YYYYMMDD').toString(), this.state.onlineCouponName, this.state.onlineCouponEventName, 'discount')
              }
            })
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>발급</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showIssueCouponModal}
          onClose={() => this.setState({showIssueCouponModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>쿠폰을 발급하시겠습니까?</span>
          { this.state.couponTo &&
            <div className="auto-width-100 direction-column-div" style={{marginTop: 10}}>
              <span style={{fontSize: 14}}>인원: 총 {this.state.couponToArray.length}명</span>
              <span style={{fontSize: 14}}>금액: 각 {this.onChangeCurrencyFormat(this.state.couponAmount)}원</span>
            </div>
          }
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showIssueCouponModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({showIssueCouponModal: false, isIssuingCoupons: true})
            this.state.couponToArray.map((couponTo, index) => {
              let couponId = shortid.generate()
              this.onIssueCoupon(couponId, couponTo, index)
            })

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>발급</span>
          </div>
          </Grid>
          </div>
        </Modal>

        { this.state.onSitePickupDate &&
          <Sheet disableDrag={true} className="sheet-style" isOpen={this.state.isOpenSheet} onClose={() => this.setState({isOpenSheet: false})}>
          <Sheet.Container className="sheet-style" >
          <Sheet.Header className="sheet-header">
            <div className="text-center">
              <span style={{fontWeight: 'bold', fontSize: 18}}>오프라인 픽업예약</span>
            </div>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20}}
              onClick={() => {
                this.setState({
                  isOpenSheet: false
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
            </div>
          </Sheet.Header>
            <Sheet.Content style={{marginBottom: 70}} className="auto-width-100 relative-position">

              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약시간</span>
                <span style={{fontSize: 14}}>{this.onStringNumberToDate(this.state.onSitePickupDate['date'])}</span>
                <div className="direction-column-div" style={{marginTop: 15}}>
                  <div className="direction-row-div" style={{margin: 0}}>
                  <span style={{marginTop: 8, marginRight: 10}}>오후</span>
                  <Select isSearchable={false} value={this.state.hourSelectedOption} onChange={(selectedOption) => {
                    if (this.onGetDay(this.state.onSitePickupDate['date']) === '토' || this.onGetDay(this.state.onSitePickupDate['date']) === '일') {
                      if (selectedOption.value === '5') {
                        if (this.state.minute === '40' || this.state.minute === '50') {
                          this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, lastPickupTimeSnack: true})
                        } else {
                          this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                        }
                      } else {
                        this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                      }
                    } else {
                      if (selectedOption.value === '6') {
                        if (this.state.minute === '40' || this.state.minute === '50') {
                          this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, weekdaysLastPickupTimeSnack: true})
                        } else {
                          this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                        }
                      } else {
                        this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                      }
                    }

                  }} className="select-style" styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                      borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                      "&:hover": {
                        borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                      }
                    }),
                    menu: (base, state) => ({
                       ...base,
                       marginBottom: 80,
                    }),
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      cursor: 'pointer',
                      ':active': {
                        ...styles[':active'],
                      backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                      backgroundColor: isDisabled
                      ? null
                      : isSelected
                      ? 'rgba(0, 135, 117, 0.95)'
                      : isFocused
                      ? 'rgba(0, 135, 117, 0.2)'
                      : null,
                  }}
                  }}
                  placeholder='시' options={hourOption} />
                  <Select isSearchable={false} value={this.state.minuteSelectedOption} onChange={(selectedOption) => {
                    this.setState({minute: selectedOption.value, minuteSelectedOption: selectedOption})
                  }} className="select-style" styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                      borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                      "&:hover": {
                        borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                      }
                    }),
                    menu: (base, state) => ({
                       ...base,
                       marginBottom: 80,
                    }),
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      cursor: 'pointer',
                      ':active': {
                        ...styles[':active'],
                      backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                      backgroundColor: isDisabled
                      ? null
                      : isSelected
                      ? 'rgba(0, 135, 117, 0.95)'
                      : isFocused
                      ? 'rgba(0, 135, 117, 0.2)'
                      : null,
                  }}
                }} placeholder='분' options={minuteOption} />
                  </div>
              </div>
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약자</span>
                <Input style={{marginTop: 10, width: 120}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  onChange={(event) => {
                    this.setState({onSitePickupName: event.target.value})
                  }}
                InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic"placeholder={"예약자 이름"} label="예약자 이름" value={this.state.onSitePickupName} />
                <Input style={{marginTop: 15, width: 120}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  onChange={(event) => {
                    if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                      this.setState({onSitePickupPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                    }
                  }}
                InputLabelProps={{style: {fontSize: 14}, inputMode: 'numeric'}} hiddenLabel margin={"none"} id="standard-basic" placeholder={"휴대폰 번호"} label="휴대폰 번호" value={this.onChangePhoneNumberFormat(this.state.onSitePickupPhoneNumber)} />
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold'}}>포장</span>
                  <div>
                  <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">
                    <Grid style={{position: 'relative'}} item xs={7}>
                      <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>선물 포장</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(1000)}원</p>
                    </Grid>
                    <Grid
                      item
                      className="direction-row-div no-selection"
                      style={{position: 'absolute', right: 20, bottom: 0}}
                    >
                    { this.state.giftBox
                      ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.giftBox * 1000)}원</p>
                      :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                    }
                    <div className="counter-button-div">
                      <div onClick={() => {
                        let giftBox = this.state.giftBox

                        if (this.state.giftBox !== 0) {
                          giftBox = giftBox - 1
                          this.setState({giftBox: giftBox, totalPrice: this.state.totalPrice - 1000})
                        }
                      }} className="counter-button">
                        <RemoveIcon fontSize={"small"}/>
                      </div>
                      <div className="counter-style">
                        <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.giftBox ? this.state.giftBox : 0}</p>
                      </div>
                      <div onClick={() => {
                        let giftBox = this.state.giftBox
                        giftBox = giftBox + 1
                        this.setState({giftBox: giftBox, totalPrice: this.state.totalPrice + 1000})
                      }} className="counter-button">
                      <AddIcon fontSize={"small"}/>
                      </div>
                    </div>
                    </Grid>
                  </Grid>
                  </div>
              </div>
              <div className="direction-column-div" style={{padding: 20, paddingTop: 0}}>
                <span style={{fontWeight: 'bold'}}>예약상품</span>
                { this.state.onSitePickupDate &&
                  <div>
                    { this.sortByOrder(Object.values(this.state.onSitePickupDate.menu)).map((item, index) => {
                        if (Object.keys(this.state.sconeMenu).length !== 0) {

                          if (this.state.sconeMenu[item.id]) {
                            let scone = this.state.sconeMenu[item.id]
                            return (
                              <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">
                                <Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                </Grid>
                                <Grid
                                  item
                                  className="direction-row-div no-selection"
                                  style={{position: 'absolute', right: 20, bottom: 0}}
                                >
                                { this.state.selectedPickupScones[scone.id]
                                  ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                  :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                                }
                                <div className="counter-button-div">
                                  <div onClick={() => {
                                    this.onRemoveScone(scone)
                                  }} className="counter-button">
                                    <RemoveIcon fontSize={"small"}/>
                                  </div>
                                  <div className="counter-style">
                                    <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                  </div>
                                  <div onClick={() => {
                                    this.onAddScone(scone, item)
                                  }} className="counter-button">
                                  <AddIcon fontSize={"small"}/>
                                  </div>
                                </div>
                                </Grid>
                              </Grid>
                            )
                          }
                        }
                      })
                    }
                  </div>

                }
              </div>
              <div className="direction-column-div" style={{padding: 20, paddingTop: 0}}>
                <span style={{fontWeight: 'bold'}}>추가상품</span>
                { this.state.onSitePickupDate &&
                  <div>
                  { Object.values(this.state.onSitePickupDate.additionalPickups).map((item, index) => {
                    return (
                      <div>
                      <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">
                        <Grid item xs={7}>
                          <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                          { item.discountedPrice
                            ?<div className="direction-column-div">
                              <div className="direction-row-div">
                                <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                                <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.discountedPrice)}원</p>
                              </div>
                            </div>
                            :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                          }
                        </Grid>
                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { Object.keys(this.state.selectedAdditiionalItems).length
                          ?this.state.selectedAdditiionalItems[item.id] &&
                          <div>
                            { item.discountedPrice
                              ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedAdditiionalItems[item.id].amount * item.discountedPrice)}원</p>
                              :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedAdditiionalItems[item.id].amount * item.price)}원</p>
                            }
                          </div>
                          :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                        }
                        <div className="counter-button-div">
                          <div onClick={() => {
                            this.onRemoveAdditionalItem(item)
                          }} className="counter-button">
                            <RemoveIcon fontSize={"small"}/>
                          </div>
                          <div className="counter-style">
                            <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedAdditiionalItems[item.id] ? this.state.selectedAdditiionalItems[item.id].amount : 0}</p>
                          </div>
                          <div onClick={() => {
                            this.onAddAdditionalItem(item)
                          }} className="counter-button">
                          <AddIcon fontSize={"small"}/>
                          </div>
                        </div>
                        </Grid>
                      </Grid>
                      </div>
                    )
                  })

                  }
                  </div>
                }
              </div>
              <div style={{position: 'relative', paddingTop: 15}}>
                <p style={{fontSize: 14, fontWeight: 'bold', margin: 0, padding: 0, position: 'absolute', right: 20, bottom: 0}}>총 {this.onChangeCurrencyFormat(this.state.totalPrice + this.state.additionalItemsPrice)}원</p>
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
              <span style={{fontWeight: 'bold'}}>요청사항</span>
              <Input style={{marginTop: 10}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({request: event.target.value})
                }}
              InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic" multiline={true} placeholder={"예) 플레인스콘 4개는 디저트박스 포장 부탁드립니다."} label="요청사항" value={this.state.request} />
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginTop: 10}}>결제정보</span>
                <span style={{fontSize: 14, marginTop: 15}}>스콘 할인</span>
                <div className="direction-row-div">
                <Input style={{width: 120}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                  onChange={(event) => {
                    if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                      this.setState({onSitePickupDiscount: event.target.value.replace(/[^0-9]/g, '')})
                    }
                  }}
                InputLabelProps={{style: {fontSize: 14}, inputMode: 'numeric'}} hiddenLabel margin={"none"} id="standard-basic" placeholder={"할인율"} label="할인율" value={this.state.onSitePickupDiscount} />
                <span style={{marginLeft: 3, fontSize: 14, marginTop: 2}}>%</span>
                </div>
              </div>
              <p style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20, color: '#006042'}}>총 {this.state.onSitePickupDiscount ? this.onChangeCurrencyFormat((this.state.totalPrice - (this.state.giftBox * 1000)) * (1 - (this.state.onSitePickupDiscount * 0.01)) + this.state.additionalItemsPrice + (this.state.giftBox * 1000))  : this.onChangeCurrencyFormat((this.state.totalPrice + this.state.additionalItemsPrice))}원 결제</p>
              <a onClick={() => {
                let pickupId = shortid.generate()

                let pickupData = {}
                let request = null
                let giftBox = null
                let discount = null
                let discountRate = 1
                if (this.state.request) {
                  request = this.state.request
                }
                if (this.state.giftBox) {
                  giftBox = this.state.giftBox
                }
                if (this.state.onSitePickupDiscount) {
                  discount = {
                    rate: this.state.onSitePickupDiscount * 0.01,
                    title: '픽업 할인'
                  }
                  discountRate = 1 - this.state.onSitePickupDiscount * 0.01
                }

                if (!this.state.hour) {
                  return alert('예약 시간을 선택해주세요.')
                }
                if (!this.state.minute) {
                  return alert('예약 시간을 선택해주세요.')
                }
                if (!this.state.onSitePickupName) {
                  return alert('예약자 이름을 적어주세요.')
                }
                if (!this.state.onSitePickupPhoneNumber) {
                  return alert('예약자 휴대폰 번호를 적어주세요.')
                }
                if (Object.keys(this.state.selectedPickupScones).length === 0) {
                  return alert('예약할 상품을 선택해주세요.')
                }
                this.setState({isOpenSheet: false, pickupId: pickupId})
                if (giftBox) {
                  pickupData = {
                    pickupId: pickupId,
                    buyerName: this.state.onSitePickupName,
                    phoneNumber: this.state.onSitePickupPhoneNumber,
                    paidPrice: (Math.max(((this.state.totalPrice - (1000 * giftBox)) * discountRate) + (1000 * giftBox) + this.state.additionalItemsPrice, 0)),
                    paidAt: Date.now(),
                    items: this.state.selectedPickupScones,
                    additionalItems: this.state.selectedAdditiionalItems,
                    additionalItemsAmount: this.state.additionalItemsAmount,
                    additionalItemsPrice: this.state.additionalItemsPrice,
                    totalAmount: this.state.totalAmount,
                    totalPrice: this.state.totalPrice + this.state.additionalItemsPrice,
                    request: request,
                    giftBox: giftBox,
                    discount: discount,
                    pickupDate: this.state.onSitePickupDate['date'],
                    pickupTime: {
                      hour: this.state.hour,
                      minute: this.state.minute
                    },
                    purchasedAt: Date.now()
                  }
                } else {
                  pickupData = {
                    pickupId: pickupId,
                    buyerName: this.state.onSitePickupName,
                    phoneNumber: this.state.onSitePickupPhoneNumber,
                    paidPrice: (Math.max((this.state.totalPrice * discountRate) + this.state.additionalItemsPrice, 0)),
                    paidAt: Date.now(),
                    items: this.state.selectedPickupScones,
                    additionalItems: this.state.selectedAdditiionalItems,
                    additionalItemsAmount: this.state.additionalItemsAmount,
                    additionalItemsPrice: this.state.additionalItemsPrice,
                    totalAmount: this.state.totalAmount,
                    totalPrice: this.state.totalPrice + this.state.additionalItemsPrice,
                    request: request,
                    giftBox: giftBox,
                    discount: discount,
                    pickupDate: this.state.onSitePickupDate['date'],
                    pickupTime: {
                      hour: this.state.hour,
                      minute: this.state.minute
                    },
                    purchasedAt: Date.now()
                  }
                }

                firebase.database().ref(`pickupOpen/${this.state.onSitePickupDate['date'].toString()}/pickups/${pickupId}`).set(pickupData)
                .then(() => {
                  firebase.database().ref(`pickupOpen/${this.state.onSitePickupDate['date'].toString()}/amount`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      firebase.database().ref(`pickupOpen/${this.state.onSitePickupDate['date'].toString()}/amount`).set(Math.max(snapshot.val() - this.state.totalAmount, 0))
                      .then(() => {
                        this.setState({
                          onSitePickupDate: null,
                          isOpenSheet: false,
                          request: '',
                          giftBox: 0,
                          onSitePickupPhoneNumber: '',
                          onSitePickupDiscount: '',
                          onSitePickupName: '',
                          hourSelectedOption: '',
                          minuteSelectedOption: '',
                          hour: '',
                          minute: '',

                          totalAmount: 0,
                          totalPrice: 0,
                          selectedPickupScones: {},

                          additionalPickups: null,
                          selectedAdditiionalItems: {},
                          additionalItemsPrice: 0,
                          additionalItemsAmount: 0
                        })
                      })
                    }
                  })
                })
              }} className="btn-very-bottom-big no-selection">
              <p style={{color: 'white', fontSize: 15}}>예약하기</p>
              </a>
            </Sheet.Content>
            </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
        }

      </Grid>
    )
  }
}

export default withRouter(Admin);
