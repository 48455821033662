import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';
import shortid from 'shortid';
import DotLoader from "react-spinners/DotLoader";

import * as firebase from 'firebase';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string'
import axios from 'axios'
import fetch from 'fetch'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';
import naverIcon from '../naverIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const { Kakao } = window;

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      oneSecondLock: false,
      tempId: shortid.generate()
    }
  }


  componentWillMount() {
    if (this.state.userInfo) {
      this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
      setTimeout(() => {
        this.props.routeChanges()
      }, 10)
    }
    if (new URLSearchParams(this.props.location.search).get('code') && new URLSearchParams(this.props.location.search).get('state')) {
      let code = new URLSearchParams(this.props.location.search).get('code')
      let state = new URLSearchParams(this.props.location.search).get('state')
      this.setState({isLogingIn: true})

      firebase.database().ref(`tempAuth/${this.state.tempId}`).on('value', (snapshot) => {
        if (snapshot.val()) {
          let userData = snapshot.val()
          firebase.database().ref(`users/${userData.uid}`).once('value', (snapshot) => {
            if (snapshot.val()) {
              firebase.database().ref(`users/${userData.uid}/customToken`).on('value', (snapshot) => {
                if (snapshot.val()) {
                  firebase.auth().signInWithCustomToken(snapshot.val())
                  .then((userCredential) => {
                    localStorage.setItem('naver_login_uid', userCredential.user.uid)
                    firebase.database().ref(`users/${userData.uid}/customToken`).off('value')

                  })
                  .catch((error) => {
                    this.setState({isLogingIn: false})
                  })
                }
              })
              axios({
                method: 'post',
                mode: 'no-cors',
                url: `https://us-central1-withanod-c20f8.cloudfunctions.net/setUserData`,
                headers: {
                  'Content-Type':'application/json'
                },
                data: {
                  userData: userData,
                  provider: 'NAVER'
                },
              })
            } else {
              firebase.database().ref(`users`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  let users = snapshot.val()
                  let usersArr = Object.values(users)
                  usersArr = usersArr.filter(usersArr => usersArr.email === userData.email)
                    if (usersArr.length) {
                      if (usersArr[0].provider) {

                        if (userData.provider === 'KAKAO') {
                          this.setState({isLogingIn: false})
                          alert(userData.email + '은 이미 카카오로 가입된 계정입니다.')
                        }
                      } else {
                        this.setState({isLogingIn: false})
                        alert(userData.email + '은 이미 이메일로 가입된 계정입니다.')
                        this.props.history.replace('/login/email?redirect=/mypage');
                        setTimeout(() => {
                          this.props.routeChanges()
                        }, 10)
                      }
                  } else {
                    firebase.database().ref(`users/${userData.uid}/customToken`).on('value', (snapshot) => {
                      if (snapshot.val()) {
                        firebase.auth().signInWithCustomToken(snapshot.val())
                        .then((userCredential) => {
                          localStorage.setItem('naver_login_uid', userCredential.user.uid)
                          this.props.onLoginNaver(userCredential.user.uid)
                          firebase.database().ref(`users/${userData.uid}/customToken`).off('value')
                        })
                        .catch((error) => {
                          firebase.database().ref(`users/${userData.uid}`).off('value')
                          this.setState({isLogingIn: false})
                        })
                    } else {
                      axios({
                        method: 'post',
                        mode: 'no-cors',
                        url: `https://us-central1-withanod-c20f8.cloudfunctions.net/setUserData`,
                        headers: {
                          'Content-Type':'application/json'
                        },
                        data: {
                          userData: userData,
                          provider: 'NAVER'
                        },
                      })
                    }
                    })
                  }
                }
              })
            }
          })
        } else {
          axios({
            method: 'post',
            mode: 'no-cors',
            url: `https://us-central1-withanod-c20f8.cloudfunctions.net/naverLogin`,
            headers: {
              'Content-Type':'application/json'
            },
            data: {
              code: code,
              state: state,
              tempId: this.state.tempId
            },
          }).then((res) => {

          }).catch((err) => {
            this.setState({isLogingIn: false})
          })
        }
      })
    } else if (new URLSearchParams(this.props.location.search).get('error')) {
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    firebase.database().ref(`tempAuth/${this.state.tempId}`).off('value')
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      } else {
        this.setState({userInfo: null, isLoading: false})
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  async loginWithKakao() {

    try {
      return new Promise((resolve, reject) => {
        if (!Kakao) {
          reject('인스턴스 없음');
        }
        Kakao.Auth.login({
          success: res => {

            Kakao.API.request({
              url: '/v2/user/me',
              success: (response) => {
                this.setState({isLogingIn: true})
                Kakao.API.request({
                  url: '/v1/user/service/terms',
                  data: {'extra': 'app_service_terms'},
                  success: (response) => {

                    firebase.database().ref(`tempAuth/${this.state.tempId}`).on('value', (snapshot) => {
                      if (snapshot.val()) {
                        let userData = snapshot.val()

                        firebase.database().ref(`users/${userData.uid}`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            firebase.database().ref(`users/${userData.uid}/customToken`).on('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.auth().signInWithCustomToken(snapshot.val())
                                .then((userCredential) => {
                                  localStorage.setItem('kakao_login_uid', userCredential.user.uid)
                                  firebase.database().ref(`users/${userData.uid}/customToken`).off('value')

                                })
                                .catch((error) => {
                                  this.setState({isLogingIn: false})
                                })


                              }
                            })
                            axios({
                              method: 'post',
                              mode: 'no-cors',
                              url: `https://us-central1-withanod-c20f8.cloudfunctions.net/setUserData`,
                              headers: {
                                'Content-Type':'application/json'
                              },
                              data: {
                                userData: userData,
                                provider: 'KAKAO'
                              },
                            })
                          } else {
                            firebase.database().ref(`users`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                let users = snapshot.val()
                                let usersArr = Object.values(users)
                                usersArr = usersArr.filter(usersArr => usersArr.email === userData.email)
                                  if (usersArr.length) {
                                    if (usersArr[0].provider) {

                                      if (userData.provider === 'NAVER') {
                                        this.setState({isLogingIn: false})
                                        alert(userData.email + '은 이미 네이버로 가입된 계정입니다.')
                                      }
                                    } else {
                                      this.setState({isLogingIn: false})
                                      alert(userData.email + '은 이미 이메일로 가입된 계정입니다.')
                                      this.props.history.replace('/login/email?redirect=/mypage');
                                      setTimeout(() => {
                                        this.props.routeChanges()
                                      }, 10)
                                    }
                                } else {
                                  firebase.database().ref(`users/${userData.uid}/customToken`).on('value', (snapshot) => {
                                    if (snapshot.val()) {
                                      firebase.auth().signInWithCustomToken(snapshot.val())
                                      .then((userCredential) => {
                                        localStorage.setItem('kakao_login_uid', userCredential.user.uid)
                                        this.props.onLoginKakao(userCredential.user.uid)
                                        firebase.database().ref(`users/${userData.uid}/customToken`).off('value')

                                      })
                                      .catch((error) => {
                                        firebase.database().ref(`users/${userData.uid}`).off('value')
                                        this.setState({isLogingIn: false})
                                      })
                                  } else {
                                    axios({
                                      method: 'post',
                                      mode: 'no-cors',
                                      url: `https://us-central1-withanod-c20f8.cloudfunctions.net/setUserData`,
                                      headers: {
                                        'Content-Type':'application/json'
                                      },
                                      data: {
                                        userData: userData,
                                        provider: 'KAKAO'
                                      },
                                    })
                                  }
                                  })
                                }
                              }
                            })
                          }
                        })
                      } else {
                        axios({
                          method: 'post',
                          mode: 'no-cors',
                          url: `https://us-central1-withanod-c20f8.cloudfunctions.net/withanod/kakaoLogin/getAccessToken`,
                          headers: {
                            'Content-Type':'application/json'
                          },
                          data: {
                            access_token: res.access_token,
                            tempId: this.state.tempId
                          },
                        }).then((res) => {

                        }).catch((err) => {
                          this.setState({isLogingIn: false})
                        })
                      }
                    })
                  },
                  fail: (error) => {

                  }
                })


              },
              fail: (error) => {
                console.log(error)
              },
            })

          },
          fail: err => {
            console.error(err);
          },
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  loginWithNaver() {
    let code = shortid.generate()
    if (new URLSearchParams(this.props.location.search).get('redirect')) {
      let redirect_uri = new URLSearchParams(this.props.location.search).get('redirect')
      window.location.assign(`https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=mo2MiIwcz2g7R2Fg_ADM&state=${code}&redirect_uri=https://withanod.com/login?redirect=${redirect_uri}`);
    } else {
      window.location.assign(`https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=mo2MiIwcz2g7R2Fg_ADM&state=${code}&redirect_uri=https://withanod.com/login?redirect=/mypage`);
    }

  }


  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <div style={{paddingTop: 70}}>
      <Grid style={this.state.isLogingIn ? {pointerEvents: "none", opacity: "0.2"} : {}} container justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>로그인</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {
            if (redirect === '/regular_start') {
              this.props.history.push('/regular_start');
            } else {
              this.props.history.goBack()
            }

            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        { !this.state.userInfo &&
          <Grid container justify="center">
            <div className="login-main-view">
              <img
                className="logo-style"
                src={MainLogo}></img>
              <span style={{fontSize: 18, marginTop: 10}}>간편하게 로그인하고</span>
              <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 5}}>최고의 스콘을 만나보세요.</span>
              <div onClick={() => {
                this.loginWithNaver()
              }} className="naver-login-button">
              <img
                className="naver-icon-style"
                src={naverIcon}></img>
                <span style={{fontSize: 15, marginLeft: 8, marginRight: 8, fontWeight: 'bold', color: 'white'}}>네이버로 시작하기</span>
              </div>
              <div onClick={() => {
                this.loginWithKakao()
              }} className="kakao-login-button">
              <img
                className="kakao-icon-style"
                src={kakaoIcon}></img>
                <span style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>카카오로 시작하기</span>
              </div>
              <Link onClick={() => {
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              }} to={'/login/email?redirect=' + redirect}>
              <p className="underline-text" style={{fontSize: 12, color: 'gray'}}>다른 이메일로 시작하기</p>
              </Link>
            </div>
          </Grid>
        }

      </Grid>
      { this.state.isLogingIn &&
        <div className="center-spinner direction-column-div align-center">
        <DotLoader color={'#006042'} loading={true} size={40} />
        <span style={{fontSize: 13, marginTop: 15}}>로그인 중입니다.</span>
        <span style={{fontSize: 13, marginTop: 5}}>잠시만 기다려주세요...</span>
        </div>
      }
      </div>
    )
  }
}

export default withRouter(Login);
