import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './components/Routes';
import { BrowserRouter, Route } from 'react-router-dom'
import * as firebase from 'firebase';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyC_vY0z3dpZPgIiU__TbgmmQ0XiFb7F-ZI",
  authDomain: "withanod-c20f8.firebaseapp.com",
  databaseURL: "https://withanod-c20f8.firebaseio.com",
  projectId: "withanod-c20f8",
  storageBucket: "withanod-c20f8.appspot.com",
  messagingSenderId: "691216829258"
}

class App extends Component {

  componentWillMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
