import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import * as firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const axios = require("axios");

class Member extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,

      editUsername: false,
      username: '',
      validUsername: null,
      isChangingUsername: false,
      usernameSnack: false,

      editPassword: false,

      prevPassword: '',
      password: '',
      confirmPassword: '',
      validPrevPassword: null,
      validPassword: null,
      validConfirmPassword: null,
      isChangingPassword: false,
      passwordSnack: false,

      editPhoneNumber: false,
      phoneNumber: '',
      verificationCode: '',
      receivedVerificationCode: '',
      validPhoneNumber: null,
      validCode: null,
      isCounting: false,
      isChangingPhoneNumber: false,
      isVerifiyng: false,
      activeSendButton: false,
      timer: {
        minutes: 1,
        seconds: 59
      },
      hasSentMeassage: false,
      verificationSnack: false,

      marketingSnack: false,

      userClass: null,
      classArr: [
        'white', 'silver', 'gold', 'vip'
      ]
    }
    this.intervalID = null
  }


  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        let sconeOrders = {}
        let itemOrders = {}
        if (nextProps.userInfo) {
          if (nextProps.userInfo['sconeOrders']) {
            sconeOrders = nextProps.userInfo['sconeOrders']
          }
          if (nextProps.userInfo['itemOrders']) {
            itemOrders = nextProps.userInfo['itemOrders']
          }
          this.onGetUserClass(sconeOrders, itemOrders, nextProps.userInfo.uid)
        }
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  async onGetUserClass(orders, itemOrders, uid) {
    let ordersList = Object.values(orders)
    let itemOrdersList = Object.values(itemOrders)
    let totalAmount = 0
    let userClass = 0

    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    if (ordersList.length > 0) {
      await ordersList.map(async(order, index) => {

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((order['orderSheet']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((order['orderSheet']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + order.paidPrice
            }
          }

        }
      })
    }

    itemOrdersList = itemOrdersList.filter(itemOrdersList => itemOrdersList.paid === true)
    if (itemOrdersList.length > 0) {
      await itemOrdersList.map(async(itemOrder, index) => {
        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + itemOrder.paidPrice
            }
          }

        }
      })
    }


    if (totalAmount >= 50000) {
      userClass = 1
    }
    if (totalAmount >= 100000) {
      userClass = 2
    }
    if (totalAmount >= 200000) {
      userClass = 3
    }
    this.setState({userClass: userClass})
  }

  onStartTimer() {
    this.setState({isCounting: true, hasSentMeassage: true})
    this.intervalID = setInterval(() => {
      let timer = this.state.timer
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(this.intervalID)
          return this.setState({timer: {
            minutes: 1,
            seconds: 59
          }, isCounting: false, verificationCode: '', validCode: null})
        } else {
          timer.minutes = timer.minutes - 1
          timer.seconds = 59
        }
      } else {
        timer.seconds = timer.seconds - 1
      }
      this.setState({timer: timer})
    }, 1000)

  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangePhoneNumber() {
    this.setState({isVerifiyng: true})
    let user = firebase.auth().currentUser
    let userInfo = this.state.userInfo
    firebase.database().ref(`verification/${this.state.phoneNumber}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        setTimeout(() => {
          if (this.state.verificationCode === Object.keys(snapshot.val())[0]) {
            firebase.database().ref(`verification/${this.state.phoneNumber}`).remove()
            .then(() => {
              firebase.database().ref(`users/${this.state.userInfo.uid}/phoneNumber`).set(this.state.phoneNumber)
              .then(() => {
                let user = this.state.user
                userInfo['phoneNumber'] = this.state.phoneNumber
                this.setState({
                  userInfo: userInfo,
                  verificationSnack: true,
                  editPhoneNumber: false,
                  phoneNumber: '',
                  verificationCode: '',
                  receivedVerificationCode: '',
                  validPhoneNumber: null,
                  validCode: null,
                  isCounting: false,
                  isChangingPhoneNumber: false,
                  isVerifiyng: false,
                  activeSendButton: false,
                  timer: {
                    minutes: 1,
                    seconds: 59
                  },
                  hasSentMeassage: false
                })
                clearInterval(this.intervalID)
              })
            })
          } else {
            this.setState({validCode: false, isVerifiyng: false})
          }
        }, 1000)
      }
    })
  }

  onChangeUsername() {
      let user = firebase.auth().currentUser
      let reg = /^[가-힣|a-z|A-Z|]+$/;
      if (reg.test(this.state.username)) {
        this.setState({isChangingUsername: true})
        firebase.database().ref(`users/${this.state.userInfo.uid}/username`).set(this.state.username)
        .then(() => {
          let userInfo = this.state.userInfo
          userInfo['username'] = this.state.username
          user.updateProfile({displayName: this.state.username})
          .then(() => {
            this.setState({userInfo: userInfo, isChangingUsername: false, username: '', validUsername: null, editUsername: false, usernameSnack: true})
          })

        })
      } else {
        this.setState({validUsername: false})
      }

    }

  onChangePassword() {
      let user = firebase.auth().currentUser
      this.setState({isChangingPassword: true})
      firebase.auth().signInWithEmailAndPassword(this.state.userInfo.email, this.state.prevPassword)
      .then(() => {
        if (this.state.password !== this.state.confirmPassword) {
          return this.setState({validConfirmPassword: false, isChangingPassword: false})
        } else {
          let pw = this.state.password;
          let num = pw.search(/[0-9]/g);
          let eng = pw.search(/[a-z]/ig);
          let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

          let result = true

          if(pw.length < 8 || pw.length > 20){
            result = false;
          }

          if(pw.search(/₩s/) != -1){
            result = false;
          }

          if( (num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0) ){
            result = false;
          }
          if (result) {
            user.updatePassword(this.state.password).then(() => {
              this.setState({passwordSnack: true, password: '', prevPassword: '', confirmPassword: '', isChangingPassword: false, editPassword: false})
            }).catch((err) => {
              if (err.code == 'auth/weak-password') {
                this.setState({
                  validPassword: false,
                  isChangingPassword: false
                })
              }
            })
          } else {
            this.setState({
              validPassword: false,
              isChangingPassword: false
            })
          }

        }
      })
      .catch((err) => {
        if (err.code == 'auth/wrong-password') {
          this.setState({
            validPrevPassword: false,
            isChangingPassword: false
          })
        }
      })
    }


  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <Grid container justify="center" style={{marginBottom: 70}}>
        <Grid container className="relative-position" justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>내 정보 관리</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {

            let currentPath = window.location.pathname
            this.props.history.goBack()

            let inervalFunction = setInterval(() => {
              if (currentPath === window.location.pathname) {
                this.props.history.goBack()
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              } else {
                clearInterval(inervalFunction)
              }
            }, 100)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        <Grid container  justify="center" style={{marginTop: 70}}>
            <div className="direction-column-div align-center">
              <Image
              style={{width: 100, height: 100, borderRadius: 50}}
              src={this.state.userInfo.photoURL}/>
              <span style={{fontWeight: 'bold', marginTop: 10}}>{this.state.userInfo.username}</span>
              <span style={{marginTop: 1, color: 'gray', fontSize: 13}}>{this.state.userInfo.email}</span>
              { this.state.userClass !== null &&
                <div className="direction-row-div align-center">
                  <span style={{marginTop: 1, color: 'gray', fontSize: 13, marginTop: 3, color: '#006042', fontWeight: 'bold'}}>회원등급: {this.state.classArr[this.state.userClass]}</span>
                  <a  data-event='click focus' data-tip data-for='userClass'>
                  <HelpOutlineIcon  style={{fontSize: 15, marginLeft: 2, marginTop: 10, cursor: 'pointer'}} />
                  </a>
                  <ReactTooltip  id='userClass' globalEventOff='click'>
                  <div className="direction-column-div align-center">
                  <span style={{color: 'white', fontSize: 12}}>회원등급기준</span>
                  <span style={{color: 'white', fontSize: 10, marginTop: 3}}>(지난 2개월 간의 택배 주문 결제금액 기준)</span>
                  <div className="direction-column-div auto-width-100">
                  <span style={{color: 'white', fontSize: 10, marginTop: 3}}>white: 5만원 미만</span>
                  <span style={{color: 'white', fontSize: 10}}>silver: 5만원 이상 ~ 10만원 미만 (택배 3%할인)</span>
                  <span style={{color: 'white', fontSize: 10}}>gold: 10만원 이상 ~ 20만원 미만 (택배 5%할인, 5천원 쿠폰 1장 지급)</span>
                  <span style={{color: 'white', fontSize: 10}}>vip: 20만원 이상 (택배 10%할인, 5천원 쿠폰 2장 지급)</span>
                  </div>
                  </div>
                  </ReactTooltip>
                </div>
              }
            </div>
            <Divider className="auto-width-100" style={{marginTop: 30}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>개인정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 15, paddingBottom: 15}}>

                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>이름</span>
                { !this.state.editUsername &&
                  <span style={{fontSize: 13, marginLeft: 62}}>{this.state.userInfo.username}</span>
                }
                { this.state.editUsername &&
                  <div className="auto-width-100 direction-column-div" style={{marginLeft: 60}}>
                  <span style={{fontSize: 13, marginLeft: 0, marginBottom: 10, fontWeight: 'bold'}}>변경할 이름</span>
                  <Input style={{marginBottom: 5}} error={this.state.validUsername === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                    onChange={(event) => {
                      this.setState({username: event.target.value, validUsername: null})
                    }}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="변경할 이름" placeholder="변경할 이름 입력" value={this.state.username} />
                  { this.state.validUsername === false &&
                    <span style={{fontSize: 11, color: 'red'}}>이름을 정확히 입력해주세요.</span>
                  }
                  <div className="normal-button-set-div direction-row-div" style={{marginTop: 5}}>
                    <div onClick={() => {
                      this.setState({
                        editUsername: false,
                        username: '',
                        validUsername: null,

                        editPassword: false,

                        prevPassword: '',
                        password: '',
                        confirmPassword: '',
                        validPrevPassword: null,
                        validPassword: null,
                        validConfirmPassword: null,
                        isChangingPassword: false,
                        passwordSnack: false,

                        editPhoneNumber: false,
                        phoneNumber: '',
                        verificationCode: '',
                        receivedVerificationCode: '',
                        validPhoneNumber: null,
                        validCode: null,
                        isCounting: false,
                        isChangingPhoneNumber: false,
                        isVerifiyng: false,
                        activeSendButton: false,
                        timer: {
                          minutes: 1,
                          seconds: 59
                        },
                        hasSentMeassage: false,
                      })
                    clearInterval(this.intervalID)
                  }} className="normal-small-button-outlined">
                      <span style={{fontSize: 14, color: '#006042'}}>취소</span>
                    </div>
                    <div onClick={() => this.onChangeUsername()} className="normal-small-button">
                      { this.state.isChangingUsername
                        ?<PulseLoader  color={'white'} loading={true} size={5} />
                        :<span style={{fontSize: 14, color: 'white'}}>저장</span>
                      }
                    </div>
                  </div>
                  </div>
                }

                { !this.state.editUsername &&
                  <span onClick={() => {
                    this.setState({
                      editUsername: true,
                      username: '',
                      validUsername: null,

                      editPassword: false,

                      prevPassword: '',
                      password: '',
                      confirmPassword: '',
                      validPrevPassword: null,
                      validPassword: null,
                      validConfirmPassword: null,
                      isChangingPassword: false,
                      passwordSnack: false,

                      editPhoneNumber: false,
                      phoneNumber: '',
                      verificationCode: '',
                      receivedVerificationCode: '',
                      validPhoneNumber: null,
                      validCode: null,
                      isCounting: false,
                      isChangingPhoneNumber: false,
                      isVerifiyng: false,
                      activeSendButton: false,
                      timer: {
                        minutes: 1,
                        seconds: 59
                      },
                      hasSentMeassage: false,
                    })
                  }} style={{fontSize: 13, position: 'absolute', right: 20, textDecoration: 'underline', cursor: 'pointer'}}>수정하기</span>
                }
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 15, paddingBottom: 15}}>
                <span style={{fontSize: 13, marginLeft: 20}}>이메일</span>
                <span style={{fontSize: 13, marginLeft: 50}}>{this.state.userInfo.email}</span>
              </div>
              { !localStorage.getItem('kakao_login_uid') && !localStorage.getItem('naver_login_uid') &&
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 15, paddingBottom: 15}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>비밀번호</span>
                  { this.state.editPassword &&
                    <div className="auto-width-80 direction-column-div" style={{marginLeft: 35}}>
                    <span style={{fontSize: 13, marginLeft: 0, marginBottom: 10, fontWeight: 'bold'}}>현재 비밀번호</span>
                    <Input type="password" style={{marginBottom: 5}} error={this.state.validPrevPassword === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                      onChange={(event) => {
                        this.setState({prevPassword: event.target.value, validPrevPassword: null})
                      }}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호" placeholder="현재 비밀번호" value={this.state.prevPassword} />
                    { this.state.validPrevPassword === false &&
                      <span style={{fontSize: 11, color: 'red'}}>잘못된 비밀번호입니다.</span>
                    }
                    <span style={{fontSize: 13, marginLeft: 0, marginBottom: 10, marginTop: 10, fontWeight: 'bold'}}>새 비밀번호</span>
                    <Input type="password" style={{marginBottom: 5}} error={this.state.validPassword === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                      onChange={(event) => {
                        this.setState({password: event.target.value, validPassword: null})
                      }}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="새 비밀번호" placeholder="영문,숫자, 특수문자 중 2가지 이상을 혼합" value={this.state.password} />
                    { this.state.validPassword === false &&
                      <span style={{fontSize: 11, color: 'red'}}>공백 없이 영문,숫자, 특수문자 중 2가지 이상을 혼합하여 입력해주세요.</span>
                    }
                    <Input style={{marginTop: 15, marginBottom: 5}} type="password" error={this.state.validConfirmPassword === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                      onChange={(event) => {
                        this.setState({confirmPassword: event.target.value, validConfirmPassword: null})
                      }}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="새 비밀번호 확인" placeholder="새 비밀번호 확인" value={this.state.confirmPassword} />
                    { this.state.validConfirmPassword === false &&
                      <span style={{fontSize: 11, color: 'red'}}>동일한 비밀번호를 입력해주세요.</span>
                    }
                    <div className="normal-button-set-div direction-row-div" style={{marginTop: 5}}>
                      <div onClick={() => {
                        this.setState({
                          editUsername: false,
                          username: '',
                          validUsername: null,

                          editPassword: false,

                          prevPassword: '',
                          password: '',
                          confirmPassword: '',
                          validPrevPassword: null,
                          validPassword: null,
                          validConfirmPassword: null,
                          isChangingPassword: false,
                          passwordSnack: false,

                          editPhoneNumber: false,
                          phoneNumber: '',
                          verificationCode: '',
                          receivedVerificationCode: '',
                          validPhoneNumber: null,
                          validCode: null,
                          isCounting: false,
                          isChangingPhoneNumber: false,
                          isVerifiyng: false,
                          activeSendButton: false,
                          timer: {
                            minutes: 1,
                            seconds: 59
                          },
                          hasSentMeassage: false,
                        })
                      }} className="normal-small-button-outlined">
                        <span style={{fontSize: 14, color: '#006042'}}>취소</span>
                      </div>
                      <div onClick={() => this.onChangePassword()} className="normal-small-button">
                        { this.state.isChangingPassword
                          ?<PulseLoader  color={'white'} loading={true} size={5} />
                          :<span style={{fontSize: 14, color: 'white'}}>저장</span>
                        }

                      </div>
                    </div>
                    </div>
                  }

                  { !this.state.editPassword &&
                    <div style={{marginLeft: 35}}>
                    {[...Array(10).keys()].map(() => {
                      return <FiberManualRecordIcon style={{fontSize: 16}} />
                    })}
                    </div>
                  }
                  { !this.state.editPassword &&
                    <span onClick={() => {
                      this.setState({
                        editUsername: false,
                        username: '',
                        validUsername: null,

                        editPassword: true,

                        prevPassword: '',
                        password: '',
                        confirmPassword: '',
                        validPrevPassword: null,
                        validPassword: null,
                        validConfirmPassword: null,
                        isChangingPassword: false,
                        passwordSnack: false,

                        editPhoneNumber: false,
                        phoneNumber: '',
                        verificationCode: '',
                        receivedVerificationCode: '',
                        validPhoneNumber: null,
                        validCode: null,
                        isCounting: false,
                        isChangingPhoneNumber: false,
                        isVerifiyng: false,
                        activeSendButton: false,
                        timer: {
                          minutes: 1,
                          seconds: 59
                        },
                        hasSentMeassage: false,
                      })
                    }} style={{fontSize: 13, position: 'absolute', right: 20, textDecoration: 'underline', cursor: 'pointer'}}>수정하기</span>
                  }

                </div>
              }

              <div className="auto-width-100 direction-row-div" style={{paddingTop: 15, paddingBottom: 15}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>휴대폰번호</span>
                { this.state.editPhoneNumber &&
                  <div className="auto-width-100 direction-column-div" style={{marginLeft: 23}}>
                  <span style={{fontSize: 13, marginLeft: 0, marginBottom: 10, fontWeight: 'bold'}}>휴대폰 번호</span>
                  <div className="auto-width-100 direction-row-div">
                    <div className="auto-width-70">
                    <Input disabled={this.state.isCounting} style={{marginBottom: 5}} error={this.state.validPhoneNumber === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                      onChange={(event) => {
                        if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                          this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, ''), validPhoneNumber: null})
                          if (/^[0-9]{10}$/g.test( event.target.value.replace(/[^0-9]/g, '')) || /^[0-9]{11}$/g.test( event.target.value.replace(/[^0-9]/g, ''))) {
                            this.setState({activeSendButton: true})
                          } else {
                            this.setState({activeSendButton: false})
                          }
                        }
                      }}
                    InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="휴대폰 번호" placeholder="휴대폰 번호 입력" value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
                    </div>
                    <div style={{position: 'absolute', right: 10}} onClick={() => {
                      if (this.state.activeSendButton && !this.state.isCounting) {
                        this.onStartTimer()
                        let number = Math.floor(1000 + Math.random() * 9000)
                        this.setState({receivedVerificationCode: number})
                        firebase.database().ref(`verification/${this.state.phoneNumber}`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            firebase.database().ref(`verification/${this.state.phoneNumber}`).remove()
                            .then(() => {
                              firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                              axios({
                                url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                                method: "post",
                                headers: {
                                  "Content-Type":"application/json"
                                },
                                data: {
                                  phoneNumber: this.state.phoneNumber,
                                  verificationNumber: number
                                }
                              })
                              setTimeout(() => {

                                firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                              }, 1000 * 60 * 2)
                            })
                          } else {
                            firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                            axios({
                              url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                              method: "post",
                              headers: {
                                "Content-Type":"application/json"
                              },
                              data: {
                                phoneNumber: this.state.phoneNumber,
                                verificationNumber: number
                              }
                            })
                            setTimeout(() => {
                              firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                            }, 1000 * 60 * 2)
                          }
                        })
                      }
                    }} className={this.state.activeSendButton && !this.state.isCounting ? "normal-very-small-button" : "normal-very-small-button-disabled"}>
                    { this.state.isCounting
                      ?<span style={{color: 'white', fontSize: 12}}>{this.state.timer.minutes}:{this.state.timer.seconds < 10 ? '0' + this.state.timer.seconds.toString() : this.state.timer.seconds}</span>
                      :<span style={{color: 'white', fontSize: 12}}>{this.state.hasSentMeassage ? '재전송' : '인증번호 전송'}</span>
                    }
                    </div>
                  </div>
                  <div className="auto-width-70 direction-column-div">
                  <Input style={{marginTop: 15, marginBottom: 5}} error={this.state.validCode === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                    onChange={(event) => {
                      this.setState({verificationCode: event.target.value, validCode: null})
                    }}
                  InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="인증번호 4자리" placeholder="인증번호 4자리" value={this.state.verificationCode} />
                  { this.state.validCode === false &&
                    <span style={{fontSize: 11, color: 'red'}}>올바른 인증번호를 입력해주세요.</span>
                  }
                  </div>
                  <div className="normal-button-set-div direction-row-div" style={{marginTop: 5}}>
                    <div onClick={() => {
                      this.setState({
                        editUsername: false,
                        username: '',
                        validUsername: null,

                        editPassword: false,

                        prevPassword: '',
                        password: '',
                        confirmPassword: '',
                        validPrevPassword: null,
                        validPassword: null,
                        validConfirmPassword: null,
                        isChangingPassword: false,
                        passwordSnack: false,

                        editPhoneNumber: false,
                        phoneNumber: '',
                        verificationCode: '',
                        receivedVerificationCode: '',
                        validPhoneNumber: null,
                        validCode: null,
                        isCounting: false,
                        isChangingPhoneNumber: false,
                        isVerifiyng: false,
                        activeSendButton: false,
                        timer: {
                          minutes: 1,
                          seconds: 59
                        },
                        hasSentMeassage: false,
                      })
                    clearInterval(this.intervalID)
                  }} className="normal-small-button-outlined">
                      <span style={{fontSize: 14, color: '#006042'}}>취소</span>
                    </div>
                    <div onClick={() => this.onChangePhoneNumber()} className="normal-small-button">
                      { this.state.isVerifiyng
                        ?<PulseLoader  color={'white'} loading={true} size={5} />
                        :<span style={{fontSize: 14, color: 'white'}}>저장</span>
                      }
                    </div>
                  </div>
                  </div>
                }
                { !this.state.editPhoneNumber &&
                  <span style={{fontSize: 13, marginLeft: 25}}>{this.onChangePhoneNumberFormat(this.state.userInfo.phoneNumber)}</span>
                }
                { !this.state.editPhoneNumber &&
                  <span onClick={() => {
                    this.setState({
                      editUsername: false,
                      username: '',
                      validUsername: null,

                      editPassword: false,

                      prevPassword: '',
                      password: '',
                      confirmPassword: '',
                      validPrevPassword: null,
                      validPassword: null,
                      validConfirmPassword: null,
                      isChangingPassword: false,
                      passwordSnack: false,

                      editPhoneNumber: true,
                      phoneNumber: '',
                      verificationCode: '',
                      receivedVerificationCode: '',
                      validPhoneNumber: null,
                      validCode: null,
                      isCounting: false,
                      isChangingPhoneNumber: false,
                      isVerifiyng: false,
                      activeSendButton: false,
                      timer: {
                        minutes: 1,
                        seconds: 59
                      },
                      hasSentMeassage: false,
                    })
                  }} style={{fontSize: 13, position: 'absolute', right: 20, textDecoration: 'underline', cursor: 'pointer'}}>수정하기</span>
                }


              </div>
            </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>광고성 정보 수신 동의</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <FormGroup  style={{alignItems: 'center', margin: 10, marginLeft: 20}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.userInfo.marketing}  onChange={() => {
                      let userInfo = this.state.userInfo
                      if (userInfo.marketing) {
                        userInfo.marketing = false
                        this.setState({marketingSnack: false})
                        setTimeout(() => {
                          this.setState({userInfo: userInfo, marketingSnack: true}, () => {
                            firebase.database().ref(`users/${userInfo.uid}/marketing`).set(false)
                          })
                        }, 10)

                      } else {
                        userInfo.marketing = true
                        this.setState({marketingSnack: false})
                        setTimeout(() => {
                          this.setState({userInfo: userInfo, marketingSnack: true}, () => {
                            firebase.database().ref(`users/${userInfo.uid}/marketing`).set(true)
                          })
                        }, 10)

                      }
                    }} value="marketing" />
                  }
                  label={<span style={{ fontSize: 14 }}>할인 정보 등 마케팅 수신 동의</span>}
                />
              </FormGroup>
            </div>
          </Grid>
        </Grid>
        <Snackbar open={this.state.usernameSnack} autoHideDuration={4000} onClose={() => this.setState({usernameSnack: false})}>
        <Alert  onClose={() => this.setState({usernameSnack: false})} severity="success">
          이름 변경 성공!
        </Alert>
      </Snackbar>
        <Snackbar open={this.state.passwordSnack} autoHideDuration={4000} onClose={() => this.setState({passwordSnack: false})}>
        <Alert  onClose={() => this.setState({passwordSnack: false})} severity="success">
          비밀번호 변경 성공!
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.verificationSnack} autoHideDuration={4000} onClose={() => this.setState({verificationSnack: false})}>
      <Alert  onClose={() => this.setState({verificationSnack: false})} severity="success">
        휴대폰 번호 변경 성공!
      </Alert>
    </Snackbar>
    <Snackbar open={this.state.marketingSnack} autoHideDuration={3000} onClose={() => this.setState({marketingSnack: false})}>
    <Alert  onClose={() => this.setState({marketingSnack: false})} severity="info">
      {this.state.userInfo.marketing ? '할인 정보 등 마케팅 수신 동의 처리되었습니다.' : '할인 정보 등 마케팅 수신 거부 처리되었습니다.'}
    </Alert>
  </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(Member);
