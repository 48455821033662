import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import CloseIcon from '@material-ui/icons/CloseOutlined';

import Modal from '@material-ui/core/Modal';
import {withRouter} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CreateIcon from '@material-ui/icons/Create';
import EventNoteIcon from '@material-ui/icons/EventNote';

import PulseLoader from "react-spinners/PulseLoader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTicketAlt } from '@fortawesome/free-solid-svg-icons'

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';

import moment from 'moment';

import * as firebase from 'firebase';

let QRCode = require('qrcode.react');



class CouponList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      onlineCoupons: null,
      searching: true,
      itmeNavIndex: 0,

      offlineCoupons: [],
      selectedCoupon: null,
      showQRImageModal: false,
      isUsedCoupon: false
    }
  }


  componentWillMount() {

  }

  componentDidMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
  }

  componentWillUnmount() {
    if (this.state.selectedCoupon) {
      firebase.database().ref(`coupons/${this.state.selectedCoupon.couponId}`).off('value')
    }
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.onGetCoupons(nextProps.userInfo)
        this.onGetOfflineCoupons(nextProps.userInfo)
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/coupon_list');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }

    } else {
      this.setState({ isLoading: true });
    }

  }

  onGetOfflineCoupons(userInfo) {
    firebase.database().ref(`coupons`).once('value', (snapshot) => {
      if (snapshot.val()) {

        let offlineCoupons = Object.values(snapshot.val())
        offlineCoupons = offlineCoupons.filter(offlineCoupon => offlineCoupon.phoneNumber === userInfo.phoneNumber)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => !offlineCoupon.usedAt)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => moment(offlineCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString() >=  moment(Date.now()).format('YYYYMMDD').toString())
        this.setState({offlineCoupons: offlineCoupons})
      }
    })
  }

  onGetCoupons(userInfo) {
    firebase.database().ref(`onlineCoupons`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let onlineCoupons = Object.values(snapshot.val())
        onlineCoupons = onlineCoupons.filter(onlineCoupon => onlineCoupon.phoneNumber === userInfo.phoneNumber)
        onlineCoupons = onlineCoupons.filter(onlineCoupon => !onlineCoupon.usedAt)
        onlineCoupons = onlineCoupons.filter(onlineCoupons => onlineCoupons.expireDate.toString() >=  moment(Date.now()).format('YYYYMMDD').toString())

        if (onlineCoupons.length && userInfo) {
          onlineCoupons.map((coupon, index) => {
            firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).once('value', (snapshot) => {
              if (!snapshot.val()) {
                firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).set(coupon)
                .then(() => {
                  if (this.props.onRefreshUserInfo) {
                    this.props.onRefreshUserInfo()
                    if (onlineCoupons.length === index + 1) {
                      setTimeout(() => {
                        this.setState({onlineCoupons: onlineCoupons, searching: false})
                      }, 1000)
                    }
                  }
                })

              } else {
                if (onlineCoupons.length === index + 1) {
                  setTimeout(() => {
                    this.setState({onlineCoupons: onlineCoupons, searching: false})
                  }, 1000)
                }
              }
            })
          })
        } else {
          setTimeout(() => {
            this.setState({onlineCoupons: onlineCoupons, searching: false})
          }, 1000)
        }

      } else {
        setTimeout(() => {
          this.setState({onlineCoupons: [], searching: false})
        }, 1000)
      }
    })
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  render() {
    if (this.state.isLoading || !this.state.userInfo) {
      return <div></div>
    }
    let coupons = null
    let discountCoupons = null
    let offlineCoupons = null
    if (this.state.offlineCoupons.length) {
      offlineCoupons = this.state.offlineCoupons
    }
    if (this.state.userInfo.coupons) {
      coupons = Object.values(this.state.userInfo.coupons)
      if (coupons.length !== 0) {
        discountCoupons = coupons.filter(coupons => coupons.type === 'discount')
        discountCoupons = discountCoupons.filter(discountCoupons => !discountCoupons.usedAt)
        discountCoupons = discountCoupons.filter(discountCoupons => discountCoupons.expireDate.toString() >=  moment(Date.now()).format('YYYYMMDD').toString())
        discountCoupons.sort((a, b) => {
          return b.issuedAt - a.issuedAt
        })
      } else {
        coupons = null
      }
    }
    return (
      <Grid style={{paddingBottom: 60}}>
        { !this.state.selectedCoupon
          ?<Grid container className="back-button-container" justify="center">
            <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>쿠폰 목록</p>
            <Link
              to={'/mypage'}
              className="no-selection back-button"
              style={{position: 'absolute', left: 20, top: 20,}}
              onClick={() => {
                setTimeout(() => {
                  this.props.routeChanges()
                  if (isEdge || isIE) {
                      window.scrollTo(0, 0)
                  } else {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    })
                  }
                }, 10)
              }}
            >
              <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
            </Link>
          </Grid>
          :<Grid container className="back-button-container" justify="center">
          <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>할인 쿠폰</p>
          <div
            item
            className="no-selection back-button"
            style={{position: 'absolute', right: 20, top: 20,}}
            onClick={() => {
              firebase.database().ref(`coupons/${this.state.selectedCoupon.couponId}`).off('value')
              this.setState({selectedCoupon: null})
            }}
          >

            <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
          </Grid>
        }
        <Grid style={{marginBottom: 20, marginTop: 70}}>
        { !this.state.selectedCoupon &&
          <Grid container className="align-center justify-content-center coupon-tabs" direction="row">
            <div onClick={() => {
              this.setState({itmeNavIndex: 0})
            }} className={this.state.itmeNavIndex === 0 ? "coupon-nav-selected-view" : "coupon-nav-view"}>
              <p class={this.state.itmeNavIndex === 0 ? "coupon-nav-selected-text" : "coupon-nav-text"}>
                온라인
              </p>
            </div>
            <div onClick={() => {
              this.setState({itmeNavIndex: 1})
            }} className={this.state.itmeNavIndex === 1 ? "coupon-nav-selected-view" : "coupon-nav-view"}>
              <p class={this.state.itmeNavIndex === 1 ? "coupon-nav-selected-text" : "coupon-nav-text"}>
                오프라인
              </p>
            </div>
          </Grid>
        }
        { this.state.itmeNavIndex === 0
          ?<div style={{marginTop: 130}}>
          { coupons
            ?<div style={{paddingLeft: 20, paddingRight: 20}}>
            <div>
            <div className="align-center auto-width-100 direction-column-div">
              <span style={{fontWeight: 'bold'}}>할인권</span>
              { this.state.searching &&
                <div style={{marginTop: 10}}>
                  <span style={{color: '#a1a1a1', fontSize: 14, margin: 2, marginRight: 5}}>사용 가능한 쿠폰 검색중</span>
                  <PulseLoader  color={'rgba(0, 135, 117, 0.95)'} loading={true} size={5} />
                </div>
              }
              <Divider style={{height: 7, marginTop: 20}} className="small-round-divider" />
            </div>
            <div style={{marginTop: 20}} className="auto-width-100 justify-between direction-row-div">
            <div className="justify-content-center" style={{width: 120}}>
              <span style={{fontWeight: 'bold', fontSize: 14}}>금액</span>
            </div>
            <div className="justify-content-center" style={{width: 120}}>
              <span style={{fontWeight: 'bold', fontSize: 14}}>쿠폰명</span>
            </div>

              <div className="justify-content-center" style={{width: 120}}>
                <span style={{fontWeight: 'bold', fontSize: 14}}>유효기간</span>
              </div>
            </div>
            <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
            {discountCoupons.length
              ?discountCoupons.map((coupon, index) => {
              return (
                <div>
                  <div className="auto-width-100 justify-between direction-row-div">
                  <div className="justify-content-center align-center" style={{width: 120, fontWeight: 'bold'}}>
                    <span style={{fontSize: 12}}>{this.onChangeCurrencyFormat(coupon.amount)}원</span>
                  </div>
                    <div className="justify-content-center align-center direction-column-div" style={{width: 120}}>
                      <span style={{fontSize: 12}}>{coupon.name}</span>
                      { coupon.eventName &&
                        <span style={{fontSize: 12}}>[{coupon.eventName}]</span>
                      }

                    </div>

                    <div className="justify-content-center align-center" style={{width: 120}}>
                      <span style={{fontSize: 12}}>{this.onStringNumberToDate(coupon.expireDate.toString())}</span>
                    </div>

                  </div>
                  <Divider style={{marginTop: 7, marginBottom: 7}} className="auto-width-100" />
                </div>
              )
            })
            :<div className="auto-width-100 justify-content-center direction-row-div">
              <span style={{fontSize: 13}}>사용 가능한 할인권이 없습니다.</span>
            </div>
            }
            </div>
            </div>
            :<div className="no-items-main-view">
              <FontAwesomeIcon style={{color: '#a1a1a1', fontSize: 70}} icon={faTicketAlt} />
              { this.state.searching
                ?<div>
                  <span style={{color: '#a1a1a1', fontSize: 16, margin: 2, marginRight: 5}}>보유 쿠폰 검색중</span>
                  <PulseLoader  color={'rgba(0, 135, 117, 0.95)'} loading={true} size={5} />
                </div>
                :<span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>보유중인 온라인 쿠폰 내역이 없습니다.</span>
              }

            </div>
          }
          </div>
          :this.state.selectedCoupon
          ?<Grid container className="auto-width-100 no-selection" justify="center">
            <Grid container className="auto-width-100" justify="center" style={this.state.selectedCoupon.usedAt ? {pointerEvents: "none", opacity: "0.2"} : {}}>
              <Grid container  justify="center">
              <div className="direction-row-div relative-position" style={{marginTop: 20}}>
                <div className="center-arrow">
                </div>
                <div className="coupon-left-box direction-column-div">
                  <span style={{fontSize: 22, color: 'white', fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(this.state.selectedCoupon.amount)}원</span>
                  <span style={{fontSize: 16, color: 'white', fontWeight: 'bold'}}>할인권</span>
                </div>
                <div onClick={() => {
                  this.setState({showQRImageModal: true})
                }} className="coupon-right-box direction-column-div">
                  <span style={{fontSize: 12, color: 'white'}}>할인 코드</span>
                  <QRCode value={this.state.selectedCoupon.couponId} renderAs={'svg'} level={'H'} size={75} bgColor={'#00615c'} includeMargin={true} fgColor='white' />
                  <span style={{fontSize: 10, color: 'white'}}>withanod.com</span>
                </div>
              </div>
              </Grid>
              <span style={{fontSize: 12, color: 'black', marginTop: 5}}>유효기간: {this.onStringNumberToDate(moment(this.state.selectedCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())}</span>
              <Grid container  justify="center">
                <div className="direction-column-div auto-width-90">
                  <span style={{fontSize: 14, marginTop: 20, fontWeight: 'bold', marginLeft: 8}}>해당 할인권은</span>
                  <span style={{fontSize: 12, marginTop: 5}}>- 현금 교환 및 잔액 환불이 불가합니다.</span>
                  <span style={{fontSize: 12, marginTop: 5}}>- 오프라인 매장 현장 구매와 픽업 예약시 사용 가능한 쿠폰입니다.</span>
                  <span style={{fontSize: 12, marginTop: 5}}>- 무상으로 제공되는 쿠폰으로 현금영수증 발급과 유효기간 연장이 불가합니다.</span>
                </div>
              </Grid>
            </Grid>
            { this.state.selectedCoupon.usedAt &&
              <div>
                { !this.state.isUsedCoupon
                  ?<div className="center-spinner direction-column-div align-center coupon-used-big">
                    <span style={{fontSize: 22, color: '#ff4040', fontWeight: 'bold'}}>사용완료</span>
                  </div>
                  :<div className="center-spinner direction-column-div align-center coupon-used-small">
                    <span style={{fontSize: 22, color: '#ff4040', fontWeight: 'bold'}}>사용완료</span>
                  </div>
                }
              </div>
            }
            <Modal
              open={this.state.showQRImageModal}
              onClose={() => this.setState({showQRImageModal: false})}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.8)'}}}
              style={{display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'}}
              >
              <div className="remove-item-modal">
              <QRCode value={this.state.selectedCoupon.couponId} renderAs={'svg'} level={'H'} size={220} bgColor={'#00615c'} includeMargin={true} fgColor='white' />
              </div>
            </Modal>
          </Grid>
          :<div style={{marginTop: 130}}>
          { offlineCoupons
            ?<div style={{paddingLeft: 20, paddingRight: 20}}>
            <div>
            <div className="align-center auto-width-100 direction-column-div">
              <span style={{fontWeight: 'bold'}}>할인권</span>
              <Divider style={{height: 7, marginTop: 20}} className="small-round-divider" />
            </div>
            {offlineCoupons.length
              ?offlineCoupons.map((coupon, index) => {
              return (
                <div onClick={() => {
                  this.setState({selectedCoupon: coupon})
                  firebase.database().ref(`coupons/${coupon.couponId}`).on('value', (snapshot) => {
                    if (snapshot.val()) {
                      this.onGetOfflineCoupons(this.state.userInfo)
                      this.setState({selectedCoupon: snapshot.val()})
                      if (snapshot.val().usedAt) {
                        this.setState({showQRImageModal: false})
                        setTimeout(() => {
                          this.setState({isUsedCoupon: true})
                        }, 10)
                      }
                    }
                  })

                }} className="coupon-list-card">
                  <div className="auto-width-100 justify-between direction-row-div align-center justify-center">
                  <div className="justify-content-center direction-column-div align-center justify-center" style={{padding: 20, fontWeight: 'bold'}}>
                    <span style={{fontSize: 18}}>{this.onChangeCurrencyFormat(coupon.amount)}원</span>
                    <span style={{fontSize: 14}}>할인권</span>
                  </div>
                  <div className="justify-content-center direction-column-div align-center justify-center" style={{marginRight: 20}}>
                    <span style={{fontSize: 12}}>유효기간</span>
                    <span style={{fontSize: 12}}>{this.onStringNumberToDate(moment(coupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())}</span>
                  </div>
                  </div>
                  <Divider style={{marginTop: 7, marginBottom: 7}} className="auto-width-100" />
                </div>
              )
            })
            :<div className="auto-width-100 justify-content-center direction-row-div">
              <span style={{fontSize: 13}}>사용 가능한 할인권이 없습니다.</span>
            </div>
            }
            </div>
            </div>
            :<div className="no-items-main-view">
              <FontAwesomeIcon style={{color: '#a1a1a1', fontSize: 70}} icon={faTicketAlt} />
              <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>보유중인 오프라인 쿠폰 내역이 없습니다.</span>

            </div>
          }
          </div>
        }

        </Grid>
      </Grid>
    )
  }
}

export default withRouter(CouponList);
