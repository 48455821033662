import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import Slider from "react-slick";

import { Image, Col, Row } from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CreateIcon from '@material-ui/icons/Create';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import ReactStars from 'react-star-ratings';

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';
import Footer from './Footer';

class Reviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo,
      scone: null,
      orderId: this.props.match.params.id,
      users: null,
      showIndex: null,
      imageIndex: 0
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }


  componentWillMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let sconeMenu = snapshot.val()
        let scone = sconeMenu[this.state.orderId]
        this.setState({scone: scone})
      }
    })
    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let users = snapshot.val()
        this.setState({users: users})
      }
    })
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/purchased_list');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}.${months}.${days}`
  }


  render() {
    if (this.state.isLoading || !this.state.scone) {
      return <div></div>
    }
    let reviews = []

    if (this.state.scone['reviews']) {
      reviews = Object.values(this.state.scone['reviews'])
      reviews.sort((a, b) => {
        return b.createdAt - a.createdAt
      })
    }
    let settings = {
      className: "",
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false
    };

    return (
      <Grid style={{paddingBottom: 60}}>
        <Grid container className="back-button-container" justify="center"  >
          <p style={{fontWeight: 'bold', fontSize: 16, margin: 10, marginTop: 18}}>{`${this.state.scone.name} 후기`}</p>
          <div
            item
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              let currentPath = window.location.pathname
              this.props.history.goBack()

              let inervalFunction = setInterval(() => {
                if (currentPath === window.location.pathname) {
                  this.props.history.goBack()
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                } else {
                  clearInterval(inervalFunction)
                }
              }, 100)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
        </Grid>
        <Grid style={{marginBottom: 20, marginTop: 90}}>
        { reviews.length
          ?<div>
            { reviews.map((review, index) => {
              return (
                <Grid style={{marginBottom: 10}} container alignItems="center" justify="center" direction="column" className="auto-width-100 relative-position">
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    if (this.state.showIndex === index) {
                      this.setState({showIndex: null, imageIndex: 0})
                    } else {
                      this.setState({showIndex: index, imageIndex: 0})
                    }
                  }} className="auto-width-90">
                    <div className="direction-row-div align-center">
                    <ReactStars
                      numberOfStars={5}
                      rating={review.rating}
                      edit={false}
                      starRatedColor={'#006042'}
                      starHoverColor={'#006042'}
                      starEmptyColor={'#bababa'}
                      starDimension={'15px'}
                      starSpacing={'0.5px'} />
                      { this.state.users &&
                        <span style={{fontSize: 13, marginLeft: 10, fontWeight: 'bold'}}>{this.state.users[review.uid].username.charAt(0) + '**'}</span>
                      }
                      <span style={{fontSize: 13, marginLeft: 10, color: 'gray'}}>{this.onGetTimeDetail(review.createdAt)}</span>
                      </div>
                      <div className="direction-row-div">
                      { this.state.showIndex === index
                        ?<div className="auto-width-90">
                          <span className="no-line" style={{fontSize: 13}}>{review.review}</span>
                        </div>
                        :<div style={{height: 60}} className="auto-width-70">
                          <span className="two-line" style={{fontSize: 13}}>{review.review}</span>
                        </div>
                      }
                      { this.state.showIndex !== index && review.images &&
                        <div className="auto-width-10" style={{position: 'absolute', top: 20, right: 20}}>
                          <Image className="image-object-fit-50" style={{borderRadius: 5}} src={review.images[0]} />
                        </div>
                      }

                    </div>
                  </div>
                  <div className="direction-row-div align-center">
                  { this.state.showIndex === index &&
                    <div style={{width: 20}}>
                    { this.state.imageIndex !== 0 &&
                      <FontAwesomeIcon onClick={() =>{
                        this.previous()
                      }} style={{color: 'black', fontSize: 20, marginRight: 10, cursor: 'pointer'}} icon={faChevronLeft} />
                    }
                    </div>
                  }

                  { this.state.showIndex === index && review.images &&
                    <div  style={{width: 280, marginTop: 50}}>
                      <Slider ref={c => (this.slider = c)}  beforeChange={(oldIndex, newIndex) => {
                        this.setState({imageIndex: newIndex})
                      }} {...settings}>
                        { review.images.map((image, index) => {
                          return (
                            <Image src={image} />
                          )
                        })

                        }
                      </Slider>
                    </div>
                  }
                  { this.state.showIndex === index && review.images &&
                    <div style={{width: 20}}>
                      { this.state.imageIndex !== review.images.length - 1 &&
                      <FontAwesomeIcon onClick={() =>{
                        this.next()
                      }} style={{color: 'black', fontSize: 20, marginLeft: 10, cursor: 'pointer'}} icon={faChevronRight} />
                      }
                    </div>

                  }

                  </div>
                  { this.state.showIndex === null
                    ?<Divider className="auto-width-100"  />
                    :this.state.showIndex === index
                    ?<Divider className="auto-width-100" style={{marginTop: 40}}  />
                    :<Divider className="auto-width-100"  />
                  }

                </Grid>
              )
            })
            }
          </div>
          :<div className="no-items-main-view">
            <CreateIcon  style={{color: '#a1a1a1', fontSize: 80}} />
            <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>배송 완료된 구매 내역이 없습니다.</span>
          </div>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Reviews);
