import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import { Image, Col, Row } from 'react-bootstrap';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CreateIcon from '@material-ui/icons/Create';
import EventNoteIcon from '@material-ui/icons/EventNote';

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';
import Footer from './Footer';

class PickUpList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo,
      isDelivered: false
    }
  }


  componentWillMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/pickup_list');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }


  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    let pickups = []
    let sconePickups = []
    let giftSetPickups = []
    if (this.state.userInfo.sconePickups) {
      sconePickups = Object.values(this.state.userInfo.sconePickups)
      sconePickups = sconePickups.filter(sconePickups => sconePickups.paid === true)
      if (sconePickups.length !== 0) {
      } else {
        sconePickups = []
      }
    }

    if (this.state.userInfo.giftSetPickups) {
      giftSetPickups = Object.values(this.state.userInfo.giftSetPickups)
      giftSetPickups = giftSetPickups.filter(giftSetPickups => giftSetPickups.paid === true)
      if (giftSetPickups.length !== 0) {
      } else {
        giftSetPickups = []
      }
    }

    if (sconePickups.length !== 0) {
      pickups = pickups.concat(sconePickups)

    }

    if (giftSetPickups.length !== 0) {
      pickups = pickups.concat(giftSetPickups)
    }

    pickups.sort((a, b) => {
      return b.purchasedAt - a.purchasedAt
    })

    return (
      <Grid style={{paddingBottom: 60}}>
        <Grid container className="back-button-container" justify="center">
          <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>픽업 예약 목록</p>
          <Link
            to={'/mypage'}
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                } else {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  })
                }
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </Link>
        </Grid>
        <Grid style={{marginBottom: 20}}>
        { pickups.length !== 0
          ?<div style={{marginTop: 70}}>
            { pickups.map((pickup, index) => {
              if (pickup.giftSet) {
                return (
                  <div className="relative-position">
                  <Link className="none-underline-text"  onClick={() => {
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  }} to={`/gift_set_pickup_detail/${pickup.pickupId}`}>
                  <Grid container  style={{padding: 20, paddingBottom: 10}}  direction="row">
                    <Grid>
                      <Image style={{width: 60, height: 60}} src={Object.values(pickup.items)[0].image} />
                    </Grid>
                    <Grid className="auto-width-45" style={{marginLeft: 10, marginTop: 8}}>
                      <p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold',  marginBottom: 5}}>선물세트 {pickup.totalAmount}건</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0,  marginBottom: 5, color: 'gray', marginLeft: 1}}>{this.onSheetNumberToDate(pickup.pickupDate)} 픽업</p>
                    </Grid>
                  </Grid>
                  </Link>
                  </div>
                )
              } else {
                return (
                  <div className="relative-position">
                  <Link className="none-underline-text"  onClick={() => {
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  }} to={`/pickup_detail/${pickup.pickupId}`}>
                  <Grid container  style={{padding: 20, paddingBottom: 10}}  direction="row">
                    <Grid>
                      <Image style={{width: 60, height: 60}} src={Object.values(pickup.items)[0].image} />
                    </Grid>
                    <Grid className="auto-width-45" style={{marginLeft: 10, marginTop: 8}}>
                      <p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold',  marginBottom: 5}}>{Object.values(pickup.items)[0].name} 외 {pickup.totalAmount - 1}건</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0,  marginBottom: 5, color: 'gray', marginLeft: 1}}>{this.onSheetNumberToDate(pickup.pickupDate)} 픽업</p>
                    </Grid>
                    { pickup.done &&
                      <div onClick={(e) => {
                         e.preventDefault()
                         this.props.history.push(`/pickup_review/${pickup.pickupId}`);
                         setTimeout(() => {
                           this.props.routeChanges()
                         }, 10)
                      }} className="normal-small-button-outlined" style={{position: 'absolute', right: 10, top: 23}}>
                        <span style={{fontSize: 12, color: '#006042'}}>후기작성</span>
                      </div>
                    }

                  </Grid>
                  </Link>
                  </div>
                )
              }

            })
            }
          </div>
          :<div className="no-items-main-view">
            <EventNoteIcon  style={{color: '#a1a1a1', fontSize: 80}} />
            <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>픽업 예약 내역이 없습니다.</span>
          </div>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(PickUpList);
