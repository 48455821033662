import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row} from 'react-bootstrap';

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Delivery from './Delivery';
import Regular from './Regular';

import chatIcon from '../coffeeIcon.jpg';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      navIndex: 0,
      bottomNavIndex: 0
    }
  }


  componentWillMount() {
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }



  render() {
    return (
      <div className="auto-width-100">
        <Divider />
        <div style={{padding: 10, paddingLeft: 20, paddingRight: 20}}>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0}}>상호명</p>
            <p style={{fontSize: 10, marginBottom: 0}}>위드어나드</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0, marginTop: 5}}>대표자</p>
            <p style={{fontSize: 10, marginBottom: 0, marginTop: 5}}>장소영</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0, marginTop: 5}}>사업자등록번호</p>
            <p style={{fontSize: 10, marginBottom: 0, marginTop: 5}}>539-23-01224</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0, marginTop: 5}}>통신판매업신고번호</p>
            <p style={{fontSize: 10, marginBottom: 0, marginTop: 5}}>2020-서울금천-2692</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0, marginTop: 5}}>전화번호</p>
            <p style={{fontSize: 10, marginBottom: 0, marginTop: 5}}>010-4013-5298</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 10, marginRight: 4, color: 'gray', marginBottom: 0, marginTop: 5}}>사업장주소</p>
            <p style={{fontSize: 10, marginBottom: 0, marginTop: 5}}>서울시 금천구 시흥대로30길 12</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 9, marginRight: 4, color: 'gray', marginBottom: 20}}>Copyright © 위드어나드. All rights reserved.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
