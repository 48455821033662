import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import Postcode from 'react-daum-postcode';

import shortid from 'shortid';

import { Image, Col, Row } from 'react-bootstrap';

import {v4 as uuid} from 'uuid';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import moment from 'moment';

import ProgressBar from "@ramonak/react-progress-bar";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Input from '@material-ui/core/Input';

import { loadTossPayments } from '@tosspayments/sdk';

import { withStyles } from '@material-ui/core/styles';

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

import chatIcon from '../coffeeIcon.jpg';
import purchaseWithSconesIcon from '../purchaseWithScones.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';

import * as firebase from 'firebase';

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      isLoadingProducts: false,
      initialLanding: true,
      userInfo: this.props.userInfo,
      cart: null,
      items: null,

      oneSecondLock: false,

      orderSheet: null,
      openOrderSheet: null,
      showPostModal: false,
      showRemoveModal: false,
      showAddModal: false,
      willRemoveItem: {},
      totalAmount: 0,
      totalPrice: 0,
      fullAddress: '',
      postCode: '',
      detailAddress: '',
      receiver: '',
      phoneNumber: '',

      noAddressSnack: false,

      deliveryAddress: {},
      deliveryAddresses: [],

      tempFullAddress: '',
      tempDetailAddress: '',
      tempPostCode: '',
      tempReceiver: '',
      tempPhoneNumber: '',
      tempPhoneNumberValue: '',

      editFullAddress: '',
      editDetailAddress: '',
      editPostCode: '',
      editReceiver: '',
      editPhoneNumber: '',
      editPhoneNumberValue: '',
      editIndex: 0,

      detailAddressInputError: false,
      doneEnterAddress: false,
      phoneNumberValue: '',
      isChangingAddress: false,
      isSelectingAddress: false,
      shoppingAddressIndex: 0,

      defaultAddressIndex: null,
      tempDefaultAddressIndex: null,

      removeIndex: 0,

      isPurchasing: false,
      noMoreItemsSnack: false,
      changeRemainsSnack: false,

      changeRemains: '',

      deliveryCharge: 4500,

      deliveryMessage: null,
      deliveryMessages: [],
      remoingIndex: 0,

      userClass: null,
      classArr: [
        'white', 'silver', 'gold', 'vip'
      ],
      userClassDiscountRate: 1
    }
  }


  componentWillMount() {

  }



  componentDidMount() {
  }



  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.reloadPage) {
      window.location.reload(false)
    }

    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {

        let sconeOrders = {}
        let itemOrders = {}
        if (nextProps.userInfo) {
          if (nextProps.userInfo['sconeOrders']) {
            sconeOrders = nextProps.userInfo['sconeOrders']
          }
          if (nextProps.userInfo['itemOrders']) {
            itemOrders = nextProps.userInfo['itemOrders']
          }
          this.onGetUserClass(sconeOrders, itemOrders, nextProps.userInfo.uid)
        }

        if (nextProps.userInfo.deliveryMessages) {
          this.setState({deliveryMessages: nextProps.userInfo.deliveryMessages, deliveryMessage: nextProps.userInfo.deliveryMessages[0]})
        }

        this.setState({userInfo: nextProps.userInfo, isLoading: false})
        let cart = null
        let items = null
        firebase.database().ref(`users/${nextProps.userInfo.uid}/cart`).once('value', (snapshot) => {
          if (snapshot.val()) {
            cart = snapshot.val()
            this.setState({cart: cart})
          }
        }).then(() => {
          firebase.database().ref(`items`).on('value', (snapshot) => {
            if (snapshot.val()) {
              items = snapshot.val()
              this.setState({items: snapshot.val()})
              this.onCheckNumberOfItems(this.state.cart)
            }
          })
        })
        if (this.state.initialLanding) {
          let deliveryAddress = null

          if (nextProps.userInfo.deliveryAddress) {
            this.setState({deliveryAddresses: nextProps.userInfo.deliveryAddress})
          }

          if (this.props.shoppingAddressIndex || this.props.shoppingAddressIndex === 0) {
            this.setState({shoppingAddressIndex: this.props.shoppingAddressIndex})
            if (nextProps.userInfo.deliveryAddress) {
              deliveryAddress = nextProps.userInfo.deliveryAddress[this.props.shoppingAddressIndex]
            }
            if (nextProps.userInfo.defaultAddressIndex || nextProps.userInfo.defaultAddressIndex === 0) {
              this.setState({defaultAddressIndex: nextProps.userInfo.defaultAddressIndex, tempDefaultAddressIndex: nextProps.userInfo.defaultAddressIndex})
            }
          } else {
            if (nextProps.userInfo.defaultAddressIndex || nextProps.userInfo.defaultAddressIndex === 0) {
              this.setState({defaultAddressIndex: nextProps.userInfo.defaultAddressIndex, tempDefaultAddressIndex: nextProps.userInfo.defaultAddressIndex, shoppingAddressIndex: nextProps.userInfo.defaultAddressIndex})
              if (nextProps.userInfo.deliveryAddress) {
                deliveryAddress = nextProps.userInfo.deliveryAddress[nextProps.userInfo.defaultAddressIndex]
              }
            } else {
              if (nextProps.userInfo.deliveryAddress) {
                deliveryAddress = nextProps.userInfo.deliveryAddress[0]
              }
            }
          }



          if (deliveryAddress) {
            this.setState({
              detailAddress: deliveryAddress.detailAddress,
              fullAddress: deliveryAddress.fullAddress,
              postCode: deliveryAddress.postCode,
              receiver: deliveryAddress.receiver,
              phoneNumber: deliveryAddress.phoneNumber,
              tempFullAddress: deliveryAddress.fullAddress,
              tempPostCode: deliveryAddress.postCode,
              tempDetailAddress: deliveryAddress.detailAddress,
              tempReceiver: deliveryAddress.receiver,
              tempPhoneNumber: deliveryAddress.phoneNumber,
              doneEnterAddress: true,
            })
          }
          this.setState({initialLanding: false})
        }
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      } else {
        this.props.history.replace('/login?redirect=/cart');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  componentWillUnmount() {
    firebase.database().ref(`items`).off('value')
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onCalculateDiscountAmount(price, rate) {
    let totalPrice = price * (1 - rate)
    return price - Number(Math.floor(totalPrice/10) * 10)
  }

  async onGetUserClass(orders, itemOrders, uid) {
    let ordersList = Object.values(orders)
    let itemOrdersList = Object.values(itemOrders)
    let userClassDiscountRate = 1
    let totalAmount = 0
    let userClass = 0

    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    if (ordersList.length > 0) {
      await ordersList.map(async(order, index) => {

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((order['orderSheet']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((order['orderSheet']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + order.paidPrice
            }
          }

        }
      })
    }

    itemOrdersList = itemOrdersList.filter(itemOrdersList => itemOrdersList.paid === true)
    if (itemOrdersList.length > 0) {
      await itemOrdersList.map(async(itemOrder, index) => {
        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + itemOrder.paidPrice
            }
          }

        }
      })
    }




    if (totalAmount >= 50000) {
      userClass = 1
      userClassDiscountRate = 0.97
    }
    if (totalAmount >= 100000) {
      userClass = 2
      userClassDiscountRate = 0.95
    }
    if (totalAmount >= 200000) {
      userClass = 3
      userClassDiscountRate = 0.90
    }
    this.setState({userClass: userClass, userClassDiscountRate: userClassDiscountRate})
  }

  phoneFormat(phone) {
    let value = phone.replace(/[^0-9]/g, '')
    this.setState({
      tempPhoneNumber: value.replace(/[^0-9]/g, ''),
      tempPhoneNumberValue: this.onChangePhoneNumberFormat(value)
    })
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone.substr(7,4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
    } else if (phone.substr(3, 4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
    } else {
      value = phone.substr(0, 3)
    }
    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  async onPaying(amount, orderId, orderName, customerName) {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)
    firebase.database().ref(`items`).off('value')

    tossPayments.requestPayment('카드', {
      amount: amount,
      orderId: orderId,
      orderName: orderName,
      customerName: customerName,
      successUrl: window.location.origin + '/order_payment_result/success',
      failUrl: window.location.origin + '/order_payment_result/fail',
    }).catch((error) => {
      if (error.code === 'USER_CANCEL') {
        firebase.database().ref(`items`).on('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({items: snapshot.val()})
            this.onCheckNumberOfItems(this.state.cart)
          }
        })
        firebase.database().ref(`users/${this.state.userInfo.uid}/itemOrders/${orderId}`).remove()
      }
    })
  }

  onGetTotalAmount(cart) {
    let result = Object.values(cart).reduce((a, b) => a + b.amount, 0)
    return result
  }

  onGetTotalPrice(cart) {
    let result = Object.values(cart).reduce((a, b) => a + b.price * b.amount, 0)
    return result
  }

  onCheckNumberOfItems(cart) {
    if (cart) {
      let selectedItems = cart
      let items = this.state.items
      let totalAmount = 0
      let totalPrice = 0
      let changeRemains = ''
      let locked = false

      Object.values(cart).map((selectedItem, index) => {
        let difference = 0
        if (selectedItem.amount > items[selectedItem.itemId].amount) {
          difference = selectedItem.amount - items[selectedItem.itemId].amount
          if (changeRemains) {
            changeRemains = changeRemains + ', ' + selectedItem.name
          } else {
            changeRemains = changeRemains + selectedItem.name
          }

          selectedItems[selectedItem.itemId].amount = selectedItem.amount - difference
        }

        if (Object.values(cart).length === index + 1) {
          let selectedItemLength = Object.values(selectedItems).length
          Object.values(selectedItems).map((item, innderIndex) => {
            if (item.amount === 0) {
              delete selectedItems[item.itemId]
            }
            if (selectedItemLength === innderIndex + 1) {
              if (changeRemains) {
                changeRemains = changeRemains + ' 잔여수량에 변동이 있습니다.'
                this.setState({changeRemainsSnack: true, changeRemains: changeRemains})
              }
              firebase.database().ref(`users/${this.state.userInfo.uid}/cart`).set(cart)
              if (Object.keys(selectedItems).length === 0) {
                this.setState({cart: null})
              } else {
                this.setState({cart: selectedItems})
              }

            }

          })

        }
      })
    } else {
      firebase.database().ref(`users/${this.state.userInfo.uid}/cart`).remove()
    }

  }


  render() {
    let cart = this.state.cart
    if (this.state.isLoading) {
      return <div></div>
    }
    if (this.state.isSelectingAddress) {
      return (
        <Grid container justify="center" style={{marginBottom: 50, paddingTop: 70}}>
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>배송지 목록</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', right: 20, top: 20,}}
          onClick={() => {
            this.setState({isSelectingAddress: false})
          }}
        >
          <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        <div className="direction-row-div right-top-position">
        <AddIcon  style={{color: 'black', fontSize: 20, margin: 1}} />
          <span onClick={() => {
            this.setState({
              showAddModal: true,
              tempFullAddress: '',
              tempPostCode: '',
              tempDetailAddress: '',
              tempReceiver: '',
              tempPhoneNumber: '',
            })
          }} className="underline-text">배송지 추가</span>
        </div>
          { this.state.deliveryAddresses.map((address, index) => {
            return (
              <Grid onClick={() => {
                this.setState({
                  shoppingAddressIndex: index,
                  detailAddress: address.detailAddress,
                  fullAddress: address.fullAddress,
                  postCode: address.postCode,
                  receiver: address.receiver,
                  phoneNumber: address.phoneNumber,
                  tempFullAddress: address.fullAddress,
                  tempPostCode: address.postCode,
                  tempDetailAddress: address.detailAddress,
                  tempReceiver: address.receiver,
                  tempPhoneNumber: address.phoneNumber,
                  doneEnterAddress: true,
                  isSelectingAddress: false
                })
                if (this.props.onSelectShoppingAddress) {
                  this.props.onSelectShoppingAddress(index)
                }
              }} container direction="column" className={this.state.shoppingAddressIndex === index ? "delivery-selection-selected" : "delivery-selection"}>
                { this.state.defaultAddressIndex === index &&
                  <span className="address-info-content badge-style" style={{fontSize: 12, marginBottom: 3, color: 'white'}}>기본 배송지</span>
                }
                <div className="address-info-wrap direction-row-div">
                  <span className="address-info-content direction-row-div" style={{fontSize: 16, fontWeight: 'bold', marginBottom: 3}}>{address.receiver} [{address.postCode}]</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-content break-word">{address.fullAddress}{' '}{address.detailAddress}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-content" style={{marginLeft: 1}}>{this.onChangePhoneNumberFormat(address.phoneNumber)}</span>
                </div>
                <div className="direction-row-div" style={{marginTop: 5}}>
                  <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      showPostModal: true,
                      editFullAddress: address.fullAddress,
                      editPostCode: address.postCode,
                      editDetailAddress: address.detailAddress,
                      editReceiver: address.receiver,
                      editPhoneNumber: address.phoneNumber,
                      editIndex: index
                    })
                  }} className="normal-small-button-outlined">
                    <span style={{fontSize: 13, color: '#006042'}}>수정</span>
                  </div>
                  <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({removeIndex: index, showRemoveAddressModal: true})

                  }} className="normal-small-button-outlined">
                    <span style={{fontSize: 13, color: '#006042'}}>삭제</span>
                  </div>
                </div>

              </Grid>
            )
          })
          }

          <Modal
            open={this.state.showRemoveAddressModal}
            onClose={() => this.setState({showRemoveAddressModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 배송지를 삭제하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showRemoveAddressModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let deliveryAddresses = this.state.deliveryAddresses
              let userInfo = this.state.userInfo
              deliveryAddresses.splice(this.state.removeIndex, 1);
              userInfo['deliveryAddress'] = deliveryAddresses
              this.setState({deliveryAddresses: deliveryAddresses, userInfo: userInfo})
              firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddresses)
              if (this.state.removeIndex === this.state.defaultAddressIndex) {
                this.setState({defaultAddressIndex: null, tempDefaultAddressIndex: null})
                firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).remove()
              } else {
                if (this.state.removeIndex < this.state.defaultAddressIndex) {
                  this.setState({defaultAddressIndex: this.state.defaultAddressIndex - 1, tempDefaultAddressIndex: this.state.defaultAddressIndex - 1})
                  firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.defaultAddressIndex - 1)
                }
              }

              if (deliveryAddresses.length === 0) {
                delete userInfo.deliveryAddress
                this.setState({
                  detailAddress: '',
                  fullAddress: '',
                  postCode: '',
                  receiver: '',
                  phoneNumber: '',
                  tempFullAddress: '',
                  tempPostCode: '',
                  tempDetailAddress: '',
                  tempReceiver: '',
                  tempPhoneNumber: '',
                  doneEnterAddress: false,
                  isSelectingAddress: false,
                  tempDefaultAddressIndex: null,
                  defaultAddressIndex: null,
                  userInfo: userInfo
                })
              } else {
                if (this.state.removeIndex === this.state.shoppingAddressIndex) {
                  this.setState({
                    shoppingAddressIndex: 0,
                    detailAddress: deliveryAddresses[0].detailAddress,
                    fullAddress: deliveryAddresses[0].fullAddress,
                    postCode: deliveryAddresses[0].postCode,
                    receiver: deliveryAddresses[0].receiver,
                    phoneNumber: deliveryAddresses[0].phoneNumber,
                    tempFullAddress: deliveryAddresses[0].detailAddress,
                    tempPostCode: deliveryAddresses[0].fullAddress,
                    tempDetailAddress: deliveryAddresses[0].postCode,
                    tempReceiver: deliveryAddresses[0].receiver,
                    tempPhoneNumber: deliveryAddresses[0].phoneNumber
                  })
                } else {
                  if (this.state.deliveryAddresses.length === this.state.shoppingAddressIndex) {
                    this.setState({
                      shoppingAddressIndex: this.state.shoppingAddressIndex - 1,
                      detailAddress: deliveryAddresses[this.state.shoppingAddressIndex - 1].detailAddress,
                      fullAddress: deliveryAddresses[this.state.shoppingAddressIndex - 1].fullAddress,
                      postCode: deliveryAddresses[this.state.shoppingAddressIndex - 1].postCode,
                      receiver: deliveryAddresses[this.state.shoppingAddressIndex - 1].receiver,
                      phoneNumber: deliveryAddresses[this.state.shoppingAddressIndex - 1].phoneNumber,
                      tempFullAddress: deliveryAddresses[this.state.shoppingAddressIndex - 1].detailAddress,
                      tempPostCode: deliveryAddresses[this.state.shoppingAddressIndex - 1].fullAddress,
                      tempDetailAddress: deliveryAddresses[this.state.shoppingAddressIndex - 1].postCode,
                      tempReceiver: deliveryAddresses[this.state.shoppingAddressIndex - 1].receiver,
                      tempPhoneNumber: deliveryAddresses[this.state.shoppingAddressIndex - 1].phoneNumber
                    })
                  }
                }
              }
              this.setState({showRemoveAddressModal: false})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showAddModal}
            onClose={() => {
              this.setState({showAddModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
          >
          { this.state.tempFullAddress && !this.state.isChangingAddress
            ?<div className="enter-detail-address">
              <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
              <div className="modal-outside-button" onClick={() => this.setState({showAddModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
              <CloseIcon />
              </div>
              <TextField onClick={() => {
                this.setState({isChangingAddress: true})
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
              <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
                this.setState({tempDetailAddress: event.target.value})
                if (this.state.detailAddressInputError) {
                  this.setState({detailAddressInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

              <TextField error={this.state.receiverInputError} onChange={(event) => {
                this.setState({tempReceiver: event.target.value})
                if (this.state.receiverInputError) {
                  this.setState({receiverInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
              <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
                this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                if (this.state.phoneNumberInputError) {
                  this.setState({phoneNumberInputError: false})
                }
              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
              <a onClick={() => {
                if (this.state.tempDetailAddress) {
                  if (this.state.tempReceiver) {
                    if (this.state.tempPhoneNumber) {
                      this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                      let with_a_nod_delivery_address = {
                        detailAddress: this.state.tempDetailAddress,
                        fullAddress: this.state.tempFullAddress,
                        postCode: this.state.tempPostCode,
                        receiver: this.state.tempReceiver,
                        phoneNumber: this.state.tempPhoneNumber
                      }

                      this.setState({
                        detailAddress: this.state.tempDetailAddress,
                        fullAddress: this.state.tempFullAddress,
                        postCode: this.state.tempPostCode,
                        receiver: this.state.tempReceiver,
                        phoneNumber: this.state.tempPhoneNumber,
                      })
                      let userInfo = this.state.userInfo

                      if (this.state.deliveryAddresses.length !== 0) {
                        let deliveryAddress = this.state.deliveryAddresses
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['deliveryAddress'] = deliveryAddress

                        this.setState({deliveryAddresses: deliveryAddress, shoppingAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                          setTimeout(() => {
                            if (isEdge || isIE) {
                                window.scrollTo(0, document.body.scrollHeight)
                              } else {
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                          }, 10)
                        })
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress.push(with_a_nod_delivery_address)
                        this.setState({deliveryAddresses: deliveryAddress, shoppingAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                          setTimeout(() => {
                            if (isEdge || isIE) {
                                window.scrollTo(0, document.body.scrollHeight)
                              } else {
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                          }, 10)
                        })
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                      }
                    } else {
                      this.setState({phoneNumberInputError: true})
                    }
                  } else {
                    this.setState({receiverInputError: true})
                  }

                } else {
                  this.setState({detailAddressInputError: true})
                }

              }} className="modal-btn-bottom-big">
                <p style={{color: 'white'}}>등록</p>
              </a>
            </div>
            :<Postcode
              style={{ width: 400, height: 400 }}
              jsOptions={{ animated: true, hideMapBtn: true }}
              onComplete={data => {
                let fullAddress = data.address;
                let extraAddress = '';

                if (data.addressType === 'R') {
                  if (data.bname !== '') {
                    extraAddress += data.bname;
                  }
                  if (data.buildingName !== '') {
                    extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                  }
                  fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
                }
                if (fullAddress.indexOf('제주특별자치도') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else {
                  this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
                }

              }}
            />
          }

          </Modal>
          <Modal
            open={this.state.showPostModal}
            onClose={() => {
              this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
              this.setState({tempDefaultAddressIndex: this.state.defaultAddressIndex})
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
          >
          { this.state.editFullAddress && !this.state.isChangingAddress
            ?<div className="enter-detail-address">
              <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
              <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
              <CloseIcon />
              </div>
              <TextField onClick={() => {
                this.setState({isChangingAddress: true})
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.editFullAddress} />
              <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
                this.setState({editDetailAddress: event.target.value})
                if (this.state.detailAddressInputError) {
                  this.setState({detailAddressInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.editDetailAddress} />

              <TextField error={this.state.receiverInputError} onChange={(event) => {
                this.setState({editReceiver: event.target.value})
                if (this.state.receiverInputError) {
                  this.setState({receiverInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.editReceiver} />
              <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
                this.setState({editPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                if (this.state.phoneNumberInputError) {
                  this.setState({phoneNumberInputError: false})
                }
              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.editPhoneNumber)} />
              <div className="auto-width-90">
              <FormGroup  style={{alignItems: 'center', margin: 10}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.tempDefaultAddressIndex === this.state.editIndex}  onChange={() => {
                      if (this.state.tempDefaultAddressIndex === this.state.editIndex) {
                        this.setState({tempDefaultAddressIndex: null})
                      } else {
                        this.setState({tempDefaultAddressIndex: this.state.editIndex})
                      }
                    }} value="defaultAddress" />
                  }
                  label={<span style={{ fontSize: 14 }}>기본 배송지로 선정</span>}
                />
              </FormGroup>
              </div>
              <a onClick={() => {
                if (this.state.editDetailAddress) {
                  if (this.state.editReceiver) {
                    if (this.state.editPhoneNumber) {
                      this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                      let with_a_nod_delivery_address = {
                        detailAddress: this.state.editDetailAddress,
                        fullAddress: this.state.editFullAddress,
                        postCode: this.state.editPostCode,
                        receiver: this.state.editReceiver,
                        phoneNumber: this.state.editPhoneNumber
                      }

                      if (this.state.editIndex === this.state.shoppingAddressIndex) {
                        this.setState({
                          shoppingAddressIndex: this.state.editIndex,
                          detailAddress: this.state.editDetailAddress,
                          fullAddress: this.state.editFullAddress,
                          postCode: this.state.editPostCode,
                          receiver: this.state.editReceiver,
                          phoneNumber: this.state.editPhoneNumber,
                          tempFullAddress: this.state.editFullAddress,
                          tempPostCode: this.state.editPostCode,
                          tempDetailAddress: this.state.editDetailAddress,
                          tempReceiver: this.state.editReceiver,
                          tempPhoneNumber: this.state.editPhoneNumber
                        })
                      }

                      this.setState({defaultAddressIndex: this.state.tempDefaultAddressIndex})

                      let deliveryAddresses = this.state.deliveryAddresses
                      deliveryAddresses[this.state.editIndex] = with_a_nod_delivery_address
                      this.setState({deliveryAddresses: deliveryAddresses})

                      firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                        if (snapshot.val()) {
                          let deliveryAddress = snapshot.val()
                          deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                          firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                        } else {
                          let deliveryAddress = []
                          deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                          firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                        }
                      })
                    } else {
                      this.setState({phoneNumberInputError: true})
                    }
                  } else {
                    this.setState({receiverInputError: true})
                  }

                } else {
                  this.setState({detailAddressInputError: true})
                }

              }} className="modal-btn-bottom-big">
                <p style={{color: 'white'}}>등록</p>
              </a>
            </div>
            :<Postcode
              style={{ width: 400, height: 400 }}
              jsOptions={{ animated: true, hideMapBtn: true }}
              onComplete={data => {
                let fullAddress = data.address;
                let extraAddress = '';

                if (data.addressType === 'R') {
                  if (data.bname !== '') {
                    extraAddress += data.bname;
                  }
                  if (data.buildingName !== '') {
                    extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                  }
                  fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
                }
                if (fullAddress.indexOf('제주특별자치도') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else {
                  this.setState({editFullAddress: fullAddress, isChangingAddress: false, editPostCode: data.zonecode})
                }

              }}
            />
          }

          </Modal>
        </Grid>
      )
    }

    return (
      <Grid container justify="center">
      <Grid container className="relative-position" justify="center">
      <Grid container className="back-button-container" justify="center">
      <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>장바구니</p>
      <div
        item
        className="no-selection back-button"
        style={{position: 'absolute', left: 20, top: 20,}}
        onClick={() => {
          let currentPath = window.location.pathname

          this.props.history.goBack()

          let inervalFunction = setInterval(() => {
            if (currentPath === window.location.pathname) {
              this.props.history.goBack()
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            } else {
              clearInterval(inervalFunction)
            }
          }, 100)
        }}
      >
        <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
      </div>
      </Grid>
      { this.state.cart &&
        <div style={{marginTop: 70}} className="cart-address">
        { this.state.doneEnterAddress
          ?<div onClick={() => {
            this.setState({isSelectingAddress: true})
          }} className="delivery-address relative-position">
              <span style={{fontWeight: 'bold', fontSize: 16, marginBottom: 10}}>배송지 정보</span>
              <Grid container direction="column">
                <div className="address-info-wrap">
                  <span className="address-info-title">받는사람</span>
                  <span className="address-info-content">{this.state.receiver}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-title">배송주소</span>
                  <span className="address-info-content break-word">{this.state.fullAddress}{' '}{this.state.detailAddress}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-title">전화번호</span>
                  <span className="address-info-content">{this.onChangePhoneNumberFormat(this.state.phoneNumber)}</span>
                </div>
              </Grid>
              <Grid
                item
                className="no-selection"
                style={{position: 'absolute', top: 13, right: 13}}
              >
              <EditIcon style={{fontSize: 20}} />
              </Grid>
          </div>
          :<div onClick={() => {
            this.setState({showPostModal: true})
          }} className="enter-address no-selection">
            <span style={{fontSize: 12, color: '#5e5e5e'}}>배송지를 입력해주세요.</span>
            <LocalShippingIcon style={{fontSize: 17, color: '#5e5e5e', marginLeft: 5}}/>
          </div>
        }
        </div>
      }
      { this.state.doneEnterAddress && this.state.cart &&
        <span className="flex-start" style={{fontSize: 10, color: '#006042'}}>작성하신 배송지 정보가 잘못되어 오배송된 경우 환불 및 재발송이 어려울 수 있습니다.</span>
      }
      { this.state.cart
        ?<div className="auto-width-100" style={{marginTop: 15}}>
        { Object.values(this.state.cart).map((item, index) => {
          return (
            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">
              <Grid item className="direction-row-div" justify="center" xs={4}>
                <Image style={{width: 100, height: 100}} src={item.image} />
              </Grid>
              <Grid item xs={7}>
                <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
              </Grid>
              <Grid
                item
                className="direction-row-div no-selection"
                style={{position: 'absolute', right: 20, bottom: 0}}
              >
              <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(item.amount * item.price)}원</p>
              <div className="counter-button-div">
                <div onClick={() => {
                  let cart = this.state.cart
                  let amount = 0
                  if (item.amount !== 1) {
                    amount = item.amount - 1
                    cart[item.itemId].amount = amount
                  } else {
                    delete cart[item.itemId]
                  }

                  if (Object.keys(cart).length === 0) {
                    cart = null
                    this.setState({cart: null})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/cart/${item.itemId}/amount`).set(0)
                  } else {
                    this.setState({cart: cart})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/cart`).set(cart)
                  }




                }} className="counter-button">
                  <RemoveIcon fontSize={"small"}/>
                </div>
                <div className="counter-style">
                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{item.amount ? item.amount : 0}</p>
                </div>
                <div onClick={() => {
                  if (this.state.items[item.itemId].amount > item.amount) {
                    let cart = this.state.cart
                    let amount = 0
                    amount = item.amount + 1
                    cart[item.itemId].amount = amount

                    this.setState({cart: cart})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/cart`).set(cart)
                  } else {
                    this.setState({noMoreItemsSnack: true})
                  }
                }} className="counter-button">
                <AddIcon fontSize={"small"}/>
                </div>
              </div>
              </Grid>
            </Grid>
          )

        })

        }
        <Grid container alignItems="center" justify="center" style={{marginBottom: 15}}>
        <Link  onClick={() => {
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
        }} to={'/delivery_cart'} className={"no-selection none-underline-text"}>
          <div className="purchase-with-scones-button-big  direction-columnl-div">
            <Image src={purchaseWithSconesIcon} style={{width: 190, height: 48}} fluid />
          </div>
        </Link>
        </Grid>
        </div>
        :<Grid container alignItems="center" justify="center" direction="column" style={{marginTop: 100}}>
          <LocalMallIcon style={{fontSize: 100, color: '#adadad'}}/>
          <span style={{fontSize: 12, color: '#adadad'}}>장바구니가 비어있습니다.</span>
          <Link to={'/home/spread'} onClick={() => {
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.props.shoppingTabViewPosition)
                } else {
                  window.scrollTo({
                    top: this.props.shoppingTabViewPosition,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 100)
          }}>
          <p className="underline-text" style={{fontSize: 14, color: '#006042', fontWeight: 'bold'}}>장바구니 채우기.</p>
          </Link>
        </Grid>
      }

      { this.state.cart ?
        <div className="auto-width-100" >
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>배송 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20}} />
          </div>
          <div style={{marginBottom: 10}} className="auto-width-100" >
            <span style={{fontWeight: 'bold', fontSize: 14, marginLeft: 20}}>배송메세지</span>
            <Input style={{marginTop: 10, marginLeft: 20}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
              onChange={(event) => {
                if (event.target.value) {
                  this.setState({deliveryMessage: event.target.value})
                } else {
                  this.setState({deliveryMessage: null})
                }

              }}
            InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic" multiline={true} placeholder={"배송시 참고할 사항이 있으면 적어주세요."} label="배송메세지" value={this.state.deliveryMessage} />
          </div>
          <div onClick={() => {
            if (this.state.deliveryMessage) {
              let deliveryMessages = []
              if (this.state.deliveryMessages) {
                deliveryMessages = this.state.deliveryMessages
              }
              deliveryMessages.unshift(this.state.deliveryMessage)
              this.setState({deliveryMessages: deliveryMessages})
              firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)
            }
          }} style={{marginTop: 0, marginLeft: 20}} className="normal-very-small-button direction-column-div">
            <span style={{color: 'white', fontSize: 13}}>메세지 저장</span>

          </div>
          <div style={{margin: 20}} className="direction-column-div">
          { this.state.deliveryMessages.length ?
            this.state.deliveryMessages.map((message, index) => {
              return (
                <div style={{marginBottom: 15}} className="direction-row-div relative-position">
                  <span style={{marginRight: 5, marginBottom: 1, fontSize: 13}}>{index + 1}.</span>
                  <span onClick={() => {
                    let deliveryMessages = this.state.deliveryMessages
                    let deliveryMessage = deliveryMessages[index]
                    deliveryMessages.splice(index, 1)
                    deliveryMessages.unshift(deliveryMessage)
                    this.setState({deliveryMessage: deliveryMessage, deliveryMessages: deliveryMessages})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)
                  }} style={{fontSize: 13, cursor: 'pointer', paddingRight: 30}}>{message}</span>
                  <CloseIcon onClick={() => {

                    this.setState({showRemoveDeliveryMessageModal: true, remoingIndex: index})

                  }} style={{position: 'absolute', top: 0, right: 10, fontSize: 16, marginLeft: 5, marginTop: 1, cursor: 'pointer'}} />
                </div>
              )
            })
            :<div></div>
          }
          </div>

        </div>
        :<div></div>
      }
      { this.state.cart
        ?<div className="auto-width-100" style={{marginBottom: 80}}>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 정보</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <div style={{padding: 20, paddingBottom: 0}}>
            <div style={{marginBottom: 5}}>
            { 50000 - this.onGetTotalPrice(this.state.cart) > 0
              ?<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(50000 - this.onGetTotalPrice(this.state.cart))}원만 더 담으면 무료 배송!</span>
              :<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>배송비 무료!</span>
            }
            </div>
            <ProgressBar height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={(this.onGetTotalPrice(this.state.cart) / 50000) * 100} />
          </div>
          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 10}}>

            <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품가격</span>
            <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(this.onGetTotalPrice(this.state.cart))}원</span>
          </div>
          { this.state.userClassDiscountRate !== 1 &&
            <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>등급할인</span>
              <span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(this.onCalculateDiscountAmount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate)))}원</span>
            </div>
          }
          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
            <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
            <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onGetTotalPrice(this.state.cart) >= 50000 ? 0 : this.onChangeCurrencyFormat(this.state.deliveryCharge) }원</span>
          </div>
          <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
            <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
            <span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.onGetTotalPrice(this.state.cart) >= 50000 ? this.onChangeCurrencyFormat(this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate))) : this.onChangeCurrencyFormat(this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate)) + this.state.deliveryCharge)}원</span>
          </div>
        </div>
        </div>
        :<div></div>
      }

      </Grid>
      <Modal
        open={this.state.showRemoveDeliveryMessageModal}
        onClose={() => this.setState({showRemoveDeliveryMessageModal: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}
        >
        <div className="remove-item-modal">
        <div className="direction-column-div">
        <span>[{this.state.deliveryMessages[this.state.remoingIndex]}]</span>
        <span>해당 메세지를 삭제하시겠습니까?</span>
        </div>
        <Grid container direction="row" style={{marginTop: 40}}>
        <div className="modal-button-outlined" onClick={() => {
          this.setState({showRemoveDeliveryMessageModal: false})
        }}>
        <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
        </div>
        <div className="modal-button-filled"  onClick={() => {
          let deliveryMessages = this.state.deliveryMessages
          deliveryMessages.splice(this.state.remoingIndex, 1)
          this.setState({deliveryMessages: deliveryMessages})
          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)

          this.setState({showRemoveDeliveryMessageModal: false})
        }}>
        <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
        </div>
        </Grid>
        </div>
      </Modal>
        <Modal
          open={this.state.showPostModal}
          onClose={() => {
            this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.tempFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
            <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    this.setState({
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber,
                    })
                    let userInfo = this.state.userInfo

                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        let deliveryAddress = snapshot.val()
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['deliveryAddress'] = deliveryAddress
                        userInfo['defaultAddressIndex'] = 0

                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['defaultAddressIndex'] = 0
                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      }
                    })
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              if (fullAddress.indexOf('제주특별자치도') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else {
                this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
              }

            }}
          />
        }

        </Modal>
        <Modal
          open={this.state.showRemoveModal}
          onClose={() => this.setState({showRemoveModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        <div className="remove-item-modal">
        <span>해당 상품을 삭제하시겠습니까?</span>
        <Grid container direction="row" style={{marginTop: 40}}>
        <div className="modal-button-outlined" onClick={() => {
          this.setState({showRemoveModal: false})
        }}>
        <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
        </div>
        <div className="modal-button-filled"  onClick={() => {
          let cart = this.state.cart
          let willRemoveItem = this.state.willRemoveItem

          let totalAmount = this.onGetTotalAmount(this.state.cart)
          let totalPrice = this.onGetTotalPrice(this.state.cart)

          totalAmount = totalAmount - cart[willRemoveItem.id].amount
          totalPrice = totalPrice - (willRemoveItem.price * cart[willRemoveItem.id].amount)

          delete cart[willRemoveItem.id]

          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, cart: cart, showRemoveModal: false})
        }}>
        <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
        </div>
        </Grid>
        </div>
      </Modal>
      { this.state.cart &&
        <a onClick={() => {
          if (this.state.fullAddress && this.state.detailAddress && this.state.postCode && this.state.receiver && this.state.phoneNumber) {
            let orderId = shortid.generate()
            let cart = this.state.cart
            this.setState({isPurchasing: true})
            let deliveryCharge = this.state.deliveryCharge
            if (this.onGetTotalPrice(this.state.cart) >= 50000) {
              deliveryCharge = 0
            }
            let userClassDiscountAmount = null

            if (this.state.userClassDiscountRate !== 1) {
              userClassDiscountAmount = this.onCalculateDiscountAmount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate))
            }

            let orderInfo = {
              orderId: orderId,
              buyerUid: this.state.userInfo.uid,
              items: this.state.cart,
              totalAmount: this.onGetTotalAmount(this.state.cart),
              totalPrice: this.onGetTotalPrice(this.state.cart),
              deliveryCharge: deliveryCharge,
              buyerName: this.state.userInfo.username,
              paidPrice: this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate)) + deliveryCharge,
              userClassDiscountAmount: userClassDiscountAmount,
              purchasedDate: Number(moment().format('YYYYMMDD')),
              deliveryMessage: this.state.deliveryMessage,
              addressTo: {
                detailAddress: this.state.detailAddress,
                fullAddress: this.state.fullAddress,
                postCode: this.state.postCode,
                receiver: this.state.receiver,
                phoneNumber: this.state.phoneNumber
              },
              purchasedAt: Date.now()
            }
            firebase.database().ref(`users/${this.state.userInfo.uid}/itemOrders/${orderId}`).set(orderInfo)
            .then(() => {
              this.onPaying(this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate)) + deliveryCharge, orderId, '스콘', this.state.userInfo.username)
            })
          } else {
            this.setState({noAddressSnack: true})
          }

        }} className={"btn-very-bottom-big no-selection"}>
            <p style={{color: 'white', fontSize: 15}}>구매하기 ( {this.onGetTotalPrice(this.state.cart) >= 50000 ? this.onChangeCurrencyFormat(this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate))) : this.onChangeCurrencyFormat(this.onCalculateDiscount(this.onGetTotalPrice(this.state.cart), (1 - this.state.userClassDiscountRate)) + this.state.deliveryCharge)}원 )</p>
        </a>
      }
      <Snackbar open={this.state.noMoreItemsSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreItemsSnack: false})}>
        <Alert  onClose={() => this.setState({noMoreItemsSnack: false})} severity="warning">
          재고가 부족합니다!
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.changeRemainsSnack} autoHideDuration={10000} onClose={() => this.setState({changeRemainsSnack: false})}>
        <Alert  onClose={() => this.setState({changeRemainsSnack: false})} severity="info">
          {this.state.changeRemains}
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.noAddressSnack} autoHideDuration={10000} onClose={() => this.setState({noAddressSnack: false})}>
        <Alert  onClose={() => this.setState({noAddressSnack: false})} severity="warning">
          배송지 정보를 입력해주세요.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.removeSoldoutItemsSnack} autoHideDuration={10000} onClose={() => this.setState({removeSoldoutItemsSnack: false})}>
        <Alert  onClose={() => this.setState({removeSoldoutItemsSnack: false})} severity="warning">
          품절 상품이 있어 수량에 변동이 있었습니다.
        </Alert>
      </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(Cart);
