import React, {Component, useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from "@material-ui/core/Backdrop";
import TextField from '@material-ui/core/TextField';
import MainLogo from '../marketLogo.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import Modal from '@material-ui/core/Modal';
import BeatLoader from "react-spinners/BeatLoader";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';



import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import { Row, Col, Container, Image } from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';

import Sheet from 'react-modal-sheet';

import {withRouter, useHistory} from 'react-router-dom';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import * as firebase from 'firebase';

const axios = require("axios");

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Join extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      oneSecondLock: false,
      users: null,
      email: '',
      validEmail: null,
      hasJoined: null,
      password: '',
      confirmPassword: '',
      validPassword: null,
      validConfirmPassword: null,
      username: '',
      validUsername: null,
      phoneNumber: '',
      validPhoneNumber: null,
      verificationCode: '',
      receivedVerificationCode: '',
      validCode: null,
      doneEmail: false,
      donePassword: false,
      username: '',
      step: 1,
      modalOpen: false,

      agreement: false,
      privacy: false,
      marketing: false,
      allAgreements: false,
      agreementSnack: false,
      openAgreement: false,
      openPrivacy: false,

      isSentNumber: false,
      activeSendButton: false,
      timer: {
        minutes: 1,
        seconds: 59
      },
      isCounting: false,
      hasSentMeassage: false,
      isJoining: false,
    }
  }


  componentWillMount() {
    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({users: snapshot.val()})
      }
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      } else {
        this.setState({userInfo: null, isLoading: false})
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({validEmail: re.test(String(email).toLowerCase())})
  }

  validatePassword(p) {
    let pw = p;
    let num = pw.search(/[0-9]/g);
    let eng = pw.search(/[a-z]/ig);
    let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

    let result = false

    if(pw.length < 8 || pw.length > 20){
      result = false;
      return this.setState({validPassword: result})
    }

    if(pw.search(/₩s/) != -1){
      result = false;
      return this.setState({validPassword: result})
    }

    if( (num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0) ){
      result = false;
      return this.setState({validPassword: result})
    }

    result = true;
    this.setState({validPassword: result})
  }

  validateConfirmPassword(p) {
    if (this.state.confirmPassword) {
      if (this.state.password === p) {
        this.setState({validConfirmPassword: true})
      } else {
        this.setState({validConfirmPassword: false})
      }
    } else {
      this.setState({validConfirmPassword: null})
    }

  }

  validateUsername(name) {
    let reg = /^[가-힣|a-z|A-Z|]+$/;
    if (name) {
      if (reg.test(name)) {
        this.setState({validUsername: true})
      } else {
        this.setState({validUsername: false})
      }
    } else {
      this.setState({validUsername: null})
    }
  }

  validatePhoneNumber(number) {
    if (number) {
      if (/^[0-9]{10}$/g.test(number) || /^[0-9]{11}$/g.test(number)) {
        this.setState({validPhoneNumber: true})
      } else {
        this.setState({validPhoneNumber: false})
      }
    } else {
      this.setState({validPhoneNumber: null})
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onStartTimer() {
    this.setState({isCounting: true, hasSentMeassage: true})
    this.intervalID = setInterval(() => {
      let timer = this.state.timer
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(this.intervalID)
          return this.setState({timer: {
            minutes: 1,
            seconds: 59
          }, isCounting: false, verificationCode: '', validCode: null})
        } else {
          timer.minutes = timer.minutes - 1
          timer.seconds = 59
        }
      } else {
        timer.seconds = timer.seconds - 1
      }
      this.setState({timer: timer})
    }, 1000)

  }

  validateJoinedEmail(email) {
    firebase.auth().fetchSignInMethodsForEmail(email)
    .then((signInMethods) => {
      if (signInMethods.length) {
        this.setState({hasJoined: true})
        // The email already exists in the Auth database. You can check the
        // sign-in methods associated with it by checking signInMethods array.
        // Show the option to sign in with that sign-in method.
      } else {
        this.setState({hasJoined: null})
        this.setState({step: 2})
        // User does not exist. Ask user to sign up.
      }
    })
    .catch((error) => {
      this.setState({hasJoined: null})
      this.setState({step: 2})
    });
  }

  onJoin() {
    if (this.state.password === this.state.confirmPassword) {
      this.setState({isJoining: true})
      firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((res) => {

        let user = firebase.auth().currentUser
        let database = firebase.database()

        user.updateProfile({
          displayName: this.state.username,
          photoURL: 'initialPhoto.jpg'
        })
        database.ref('users/' + res.user.uid).set({
          username: this.state.username,
          uid: user.uid,
          email: this.state.email,
          signinDate: Date.now(),
          phoneNumber: this.state.phoneNumber,
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/photos%2FinitialPhoto.jpg?alt=media&token=d706bb50-3a7c-48d4-b325-4c41da7224da',
          marketing: this.state.marketing
        }).then(() => {
          this.setState({isJoining: false})
          this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
        })
      }).catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          let usersArr = Object.values(this.state.users)
          usersArr = usersArr.filter(usersArr => usersArr.email === this.state.email)
          if (usersArr[0].provider) {
            if (usersArr[0].provider === 'KAKAO') {
              alert('이미 카카오로 가입된 이메일주소입니다.')
            } else if (usersArr[0].provider === 'NAVER') {
              alert('이미 네이버로 가입된 이메일주소입니다.')
            }
          } else {
            alert('이미 가입된 이메일주소입니다.')
          }

          this.setState({isJoining: false})
          this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
        }
      })
    }
  }

  render() {

    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    if (this.state.step === 1) {
      return (
        <Grid style={{paddingTop: 70}} container className="relative-position" justify="center">
        <Grid container justify="center">
        <Grid container className="back-button-container" justify="center">
          <span style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>회원가입 ({this.state.step}/2)</span>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', left: 20, top: 20,}}
              onClick={() => {
                let currentPath = window.location.pathname
                this.props.history.goBack()

                let inervalFunction = setInterval(() => {
                  if (currentPath === window.location.pathname) {
                    this.props.history.goBack()
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  } else {
                    clearInterval(inervalFunction)
                  }
                }, 100)
              }}
            >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
          </Grid>
          { !this.state.hasJoined &&
            <Grid className="auto-width-100" container justify="center">
            { this.state.validEmail
              ?<TextField type="email" autoFocus className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({email: event.target.value, validEmail: null})
                }}
                InputProps={{style: {marginBottom: 21}, endAdornment: (
                              <InputAdornment position="start">
                                <DoneIcon style={{color: '#006042'}} />
                              </InputAdornment>
                             )}}
               onBlur={() => {
                 this.validateEmail(this.state.email)
                 if (this.state.email === '') {
                   this.setState({validEmail: null})
                 }
               }}
              InputLabelProps={{style: {fontSize: 14}}} margin={"normal"} id="standard-basic" label="이메일" value={this.state.email} />
              :<TextField type="email" autoFocus error={this.state.hasJoined === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({email: event.target.value, hasJoined: null})
                }}
                onBlur={() => {
                  this.validateEmail(this.state.email)
                  if (this.state.email === '') {
                    this.setState({validEmail: null})
                  }
                }}
                helperText={this.state.validEmail === false ? "올바르지 않은 이메일 형식입니다." : " "}
                FormHelperTextProps={{
                  style: {fontSize: 11, color: 'red'}
                }}
                InputProps={{style: {marginBottom: 0}}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"normal"} id="standard-basic" label="이메일" placeholder="example@xxx.com" value={this.state.email} />
            }
            </Grid>
          }
          { this.state.hasJoined &&
            <TextField type="email" autoFocus error={this.state.hasJoined === true} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
              onChange={(event) => {
                this.setState({email: event.target.value, hasJoined: null, validEmail: null})
              }}
              onBlur={() => {
                if (this.state.email === '') {
                  this.setState({hasJoined: null})
                }
              }}
              helperText={this.state.hasJoined === true ? "이미 가입된 이메일 주소입니다." : " "}
              FormHelperTextProps={{
                style: {fontSize: 11, color: 'red'}
              }}
              InputProps={{style: {marginBottom: 0}}}
            InputLabelProps={{style: {fontSize: 14}}} margin={"normal"} id="standard-basic" label="이메일" placeholder="example@xxx.com" value={this.state.email} />
          }

          <Grid className="auto-width-100" container justify="center">
            { this.state.validPassword
              ?<TextField type="password"  style={{marginBottom: 10}} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({password: event.target.value, validPassword: null}, () => {
                    setTimeout(() => {
                      this.validateConfirmPassword(this.state.confirmPassword)
                    }, 10)
                  })

                }}
                onBlur={() => {
                  this.validatePassword(this.state.password)
                  if (this.state.password === '') {
                    this.setState({validPassword: null})
                  }
                }}
                InputProps={{style: {marginBottom: 21}, endAdornment: (
                              <InputAdornment position="start">
                                <DoneIcon style={{color: '#006042'}} />
                              </InputAdornment>
                             )}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호" value={this.state.password} />
              :<TextField type="password" style={{marginBottom: 10}} error={this.state.validPassword === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({password: event.target.value, validPassword: null}, () => {
                    setTimeout(() => {
                      this.validateConfirmPassword(this.state.confirmPassword)
                    }, 10)
                  })
                }}
                onBlur={() => {
                  this.validatePassword(this.state.password)
                  if (this.state.password === '') {
                    this.setState({validPassword: null})
                  }
                }}
                InputProps={{style: {marginBottom: 0}}}
                helperText={this.state.validPassword === false ? "공백 없이 영문,숫자, 특수문자 중 2가지 이상을 혼합하여 입력해주세요." : " "}
                FormHelperTextProps={{
                  style: {fontSize: 11, color: 'red'}
                }}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호" placeholder="공백 없이 영문,숫자, 특수문자 중 2가지 이상을 혼합" value={this.state.password} />
            }

            { this.state.validConfirmPassword
              ?<TextField type="password" className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({confirmPassword: event.target.value, validConfirmPassword: null})
                }}
                onBlur={() => {
                  this.validateConfirmPassword(this.state.confirmPassword)
                  if (this.state.confirmPassword === '') {
                    this.setState({validConfirmPassword: null})
                  }
                }}
                InputProps={{style: {marginBottom: 21}, endAdornment: (
                              <InputAdornment position="start">
                                <DoneIcon style={{color: '#006042'}} />
                              </InputAdornment>
                             )}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호 확인" value={this.state.confirmPassword} />
              :<TextField type="password" error={this.state.validConfirmPassword === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({confirmPassword: event.target.value, validConfirmPassword: null})
                  if (this.state.password === event.target.value) {
                    this.setState({validConfirmPassword: true})
                  }
                }}
                onBlur={() => {
                  this.validateConfirmPassword(this.state.confirmPassword)
                  if (this.state.confirmPassword === '') {
                    this.setState({validConfirmPassword: null})
                  }
                }}
                InputProps={{style: {marginBottom: 0}}}
                helperText={this.state.validConfirmPassword === false ? "동일한 비밀번호를 입력해주세요." : " "}
                FormHelperTextProps={{
                  style: {fontSize: 11, color: 'red'}
                }}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="비밀번호 확인" placeholder="비밀번호 확인" value={this.state.confirmPassword} />
            }
            { this.state.validUsername
              ?<TextField className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({username: event.target.value, validUsername: null})
                }}
                onBlur={() => {
                  this.validateUsername(this.state.username)
                  if (this.state.username === '') {
                    this.setState({validUsername: null})
                  }
                }}
                InputProps={{style: {marginBottom: 21}, endAdornment: (
                              <InputAdornment position="start">
                                <DoneIcon style={{color: '#006042'}} />
                              </InputAdornment>
                             )}}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이름" value={this.state.username} />
              :<TextField error={this.state.validUsername === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({username: event.target.value, validUsername: null})
                }}
                onBlur={() => {
                  this.validateUsername(this.state.username)
                  if (this.state.username === '') {
                    this.setState({validUsername: null})
                  }
                }}
                InputProps={{style: {marginBottom: 0}}}
                helperText={this.state.validUsername === false ? "이름을 정확히 입력해주세요." : " "}
                FormHelperTextProps={{
                  style: {fontSize: 11, color: 'red'}
                }}
              InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이름" value={this.state.username} />
            }
          </Grid>
        </Grid>

        <Grid className="auto-width-80 direction-column-div" style={{marginTop: 20, marginBottom: 120}}>
          <FormGroup  style={{alignItems: 'center'}} row>
            <FormControlLabel
              control={
                <GreenCheckbox checked={this.state.allAgreements} onChange={() => {
                  if (this.state.allAgreements) {
                    this.setState({
                      allAgreements: false,
                      agreement: false,
                      privacy: false,
                      marketing: false
                    })
                  } else {
                    this.setState({
                      allAgreements: true,
                      agreement: true,
                      privacy: true,
                      marketing: true
                    })
                  }
                }} value="agreement" />
              }
              label={<span style={{ fontSize: 16, fontWeight: 'bold' }}>모든 약관 동의</span>}
            />
          </FormGroup>
          <div className="bg-gray" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 7}}>
            <div className="agreement-div">
            <FormGroup  style={{alignItems: 'center'}} row>
              <FormControlLabel
                control={
                  <GreenCheckbox checked={this.state.agreement} onChange={() => {
                    if (this.state.agreement) {
                      this.setState({agreement: false, allAgreements: false})
                    } else {
                      this.setState({agreement: true})
                      if (this.state.privacy && this.state.marketing) {
                        this.setState({allAgreements: true})
                      }
                    }
                  }} value="agreement" />
                }
                label={<span style={{fontSize: 13}}>서비스 약관 동의</span>}
              />
              <span style={{fontSize: 11, color: 'gray', paddingLeft: 0, marginBottom: 2}}>(필수)</span>
            </FormGroup>
            <p onClick={() => this.setState({openAgreement: true})} style={{fontSize: 12, marginTop: 10, textDecoration: 'underline', cursor: 'pointer', marginRight: 10}}>약관보기</p>
            </div>
            <div className="agreement-div">
            <FormGroup style={{alignItems: 'center'}} row>
              <FormControlLabel
                control={
                  <GreenCheckbox checked={this.state.privacy} onChange={() => {
                    if (this.state.privacy) {
                      this.setState({privacy: false, allAgreements: false})
                    } else {
                      this.setState({privacy: true})
                      if (this.state.agreement && this.state.marketing) {
                        this.setState({allAgreements: true})
                      }
                    }
                  }} value="privacy" />
                }
                label={<span style={{fontSize: 13}}>개인정보 처리방침 동의</span>}
              />
              <span style={{fontSize: 11, color: 'gray', paddingLeft: 0, marginBottom: 2}}>(필수)</span>
            </FormGroup>
            <p onClick={() => this.setState({openPrivacy: true})} style={{fontSize: 12, marginTop: 10, textDecoration: 'underline', cursor: 'pointer', marginRight: 10}}>약관보기</p>
            </div>
            <FormGroup  style={{alignItems: 'center'}} row>
              <FormControlLabel
                control={
                  <GreenCheckbox checked={this.state.marketing}  onChange={() => {
                    if (this.state.marketing) {
                      this.setState({marketing: false, allAgreements: false})
                    } else {
                      this.setState({marketing: true})
                      if (this.state.agreement && this.state.privacy) {
                        this.setState({allAgreements: true})
                      }
                    }
                  }} value="marketing" />
                }
                label={<span style={{fontSize: 13}}>할인 정보 등 마케팅 수신 동의</span>}
              />
              <span style={{fontSize: 11, color: 'gray', paddingLeft: 0, marginBottom: 2}}>(선택)</span>
            </FormGroup>
          </div>
        </Grid>
        <a onClick={() => {
          if (this.state.validEmail && this.state.validPassword && this.state.validConfirmPassword && this.state.validUsername && !this.state.hasJoined && this.state.agreement && this.state.privacy) {
            this.validateJoinedEmail(this.state.email)
          }
        }} className={(this.state.validEmail && this.state.validPassword && this.state.validConfirmPassword && this.state.validUsername && !this.state.hasJoined && this.state.agreement && this.state.privacy) ? "btn-very-bottom-big no-selection" : "btn-very-bottom-big-disabled no-selection"}>
          <p style={{color: 'white', fontSize: 15}}>다음</p>
        </a>
        <Sheet className="sheet-style" isOpen={this.state.openPrivacy} onClose={() => this.setState({openPrivacy: false})}>
        <Sheet.Container className="sheet-style" >
          <Sheet.Header style={{paddingLeft: 25, paddingTop: 15, paddingBottom: 15}} className="auto-width-100 center-align">
            <span>개인정보 처리방침</span>
          </Sheet.Header>
          <Sheet.Content style={{padding: 25, paddingTop: 5}} className="auto-width-100">
          <p>
          위드어나드(이하 '회사')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br/>
          위드어나드 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br/>
          ○ 본 방침은부터 2021 10월 1일부터 시행됩니다.<br/><br/>
          1. 개인정보의 처리 목적 위드어나드는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br/>
          가. 홈페이지 회원가입 및 관리<br/>
          회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리 등을 목적으로 개인정보를 처리합니다.<br/>
          나. 재화 또는 서비스 제공<br/>
          콘텐츠 제공 등을 목적으로 개인정보를 처리합니다.<br/>
          다. 마케팅 및 광고에의 활용<br/>
          신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 등을 목적으로 개인정보를 처리합니다.<br/><br/>
          2. 개인정보 파일 현황<br/>
          1. 개인정보 파일명 : 위드어나드 개인정보 파일<br/>
          - 개인정보 항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름<br/>
          - 수집방법 : 홈페이지<br/>
          - 보유근거 : 홈페이지 회원정보 수집 등에 관한 기록<br/>
          - 보유기간 : 5년<br/>
          - 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 계약 또는 청약철회 등에 관한 기록 : 5년, 표시/광고에 관한 기록 : 6개월<br/><br/>
          3. 개인정보의 처리 및 보유 기간<br/>
          ① 위드어나드는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br/>
          ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br/>
          1. 홈페이지 회원가입 및 관리<br/>
          홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터 5년까지 위 이용목적을 위하여 보유.이용됩니다.<br/>
          -보유근거 : 홈페이지 회원정보 수집 및 이용에 관한 기록<br/>
          -관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br/>
          2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/>
          3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br/>
          4) 계약 또는 청약철회 등에 관한 기록 : 5년<br/>
          5) 표시/광고에 관한 기록 : 6개월<br/><br/>
          4. 개인정보의 제3자 제공에 관한 사항<br/>
          ① 위드어나드는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br/>
          ② 위드어나드는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.<br/>
          1. 상품 제공 업체<br/>
          - 개인정보를 제공받는 자 : 상품 제공 업체<br/>
          - 제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 이름<br/>
          - 제공받는 자의 보유.이용기간: 5년<br/><br/>
          5. 개인정보처리 위탁<br/>
          ① 위드어나드는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br/>
          1. 개인정보 처리 위탁<br/>
          - 위탁받는 자 (수탁자) : 토스페이먼츠<br/>
          - 위탁하는 업무의 내용 : 구매 및 요금 결제<br/>
          - 위탁기간 : 수탁 업무 종료시<br/>
          ② 위드어나드는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br/>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br/><br/>
          6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.<br/>
          ① 정보주체는 위드어나드에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
          ② 제1항에 따른 권리 행사는위드어나드에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 위드어나드는 이에 대해 지체 없이 조치하겠습니다.<br/>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
          ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br/>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
          ⑥ 위드어나드는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/><br/>
          7. 처리하는 개인정보의 항목 작성<br/>
          ① 위드어나드는 다음의 개인정보 항목을 처리하고 있습니다.<br/>
          1. 홈페이지 회원가입 및 관리<br/>
          - 필수항목 : 이메일, 비밀번호, 로그인ID, 이름<br/>
          - 선택항목 : 휴대전화번호<br/><br/>
          8. 개인정보의 위드어나드는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/>
          -파기절차<br/>
          이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br/>
          -파기기한<br/>
          이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br/>
          -파기방법<br/>
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br/>
          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/>
          9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항<br/>
          ① 위드어나드는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/><br/>
          10. 개인정보 보호책임자 작성<br/>
          ① 위드어나드는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
          ▶ 개인정보 보호책임자<br/>
          성명 :장소영<br/>
          직책 :대표<br/>
          직급 :대표<br/>
          연락처 :01040135298, with_a_nod@naver.com<br/>
          ※ 개인정보 보호 담당부서로 연결됩니다.<br/><br/>
          11. 개인정보 처리방침 변경<br/>
          ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br/><br/>
          12. 개인정보의 안전성 확보 조치 위드어나드는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br/>
          1. 정기적인 자체 감사 실시<br/>
          개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br/>
          2. 개인정보 취급 직원의 최소화 및 교육<br/>
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br/>
          3. 해킹 등에 대비한 기술적 대책<br/>
          위드어나드는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br/>
          4. 개인정보의 암호화<br/>
          이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>
          5. 개인정보에 대한 접근 제한<br/>
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
          </p>
          </Sheet.Content>
          </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <Sheet className="sheet-style" isOpen={this.state.openAgreement} onClose={() => this.setState({openAgreement: false})}>
      <Sheet.Container className="sheet-style" >
        <Sheet.Header style={{paddingLeft: 25, paddingTop: 15, paddingBottom: 15}} className="auto-width-100 center-align">
          <span>이용 약관</span>
        </Sheet.Header>
          <Sheet.Content style={{padding: 25, paddingTop: 5}} className="auto-width-100">
          <p>
          제1조(목적)<br/>
          이 약관은 위드어나드(이하 ‘회사’)가 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 위드어나드와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.<br/><br/>
          제2조(정의)<br/>
          ① "사이트" 란 사업자가 상품을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.<br/>
          ② "이용자"란 "사이트"에 접속하여 이 약관에 따라 "사이트"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
          ③ "회원"이라 함은 "사이트"에 개인정보를 제공하여 회원등록을 한 자로서, "사이트"의 정보를 지속적으로 제공받으며, "사이트"가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
          ④ "비회원"이라 함은 회원에 가입하지 않고 "사이트"가 제공하는 서비스를 이용하는 자를 말합니다.<br/><br/>
          제3조 (약관의 명시와 개정)<br/>
          ① "회사"는 이 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처(전화, 전자우편 등) 등을 이용자가 알 수 있도록 사이트의 초기 서비스화면(전면)에 게시합니다.<br/>
          ② "회사"는 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률, 방문판매 등에 관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
          ③ "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 홈페이지의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.<br/>
          ④ "회사"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 "사이트"에 송신하여 "사이트"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br/>
          ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br/><br/>
          제4조(서비스의 제공 및 변경)<br/>
          ① "회사"는 다음과 같은 업무를 수행합니다.<br/>
          1. 상품에 대한 정보 제공 및 구매계약의 체결<br/>
          2. 구매계약이 체결된 상품 제공<br/>
          3. 기타 "사이트"이 정하는 업무<br/>
          ② "회사"는 상품의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품의 내용 및 제공일자를 명시하여 현재의상품의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.<br/>
          ③ "회사"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 상품의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 "회사"는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, "회사"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br/><br/>
          제5조(서비스의 중단)<br/>
          ① "회사"는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
          ② 제1항에 의한 서비스 중단의 경우에는 "회사"는 제8조에 정한 방법으로 이용자에게 통지합니다.<br/>
          ③ "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단 "회사"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br/><br/>
          제6조(회원가입)<br/>
          ① 이용자는 "사이트"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br/>
          ② "사이트"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br/>
          1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "사이트"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.<br/>
          2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
          3. 기타 회원으로 등록하는 것이 "사이트"의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
          ③ 회원가입계약의 성립 시기는 "사이트"의 승낙이 회원에게 도달한 시점으로 합니다.<br/>
          ④ 회원은 제15조 제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "사이트"에 대하여 그 변경사항을 알려야 합니다.<br/><br/>
          제7조(회원 탈퇴 및 자격 상실 등)<br/>
          ① 회원은 "사이트"에 언제든지 탈퇴를 요청할 수 있으며 "사이트"는 즉시 회원탈퇴를 처리합니다.<br/>
          ② 회원이 다음 각 호의 사유에 해당하는 경우, "사이트"는 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
          1. 가입 신청 시에 허위 내용을 등록한 경우<br/>
          2. "사이트"를 이용하여 구입한 상품의 대금, 기타 "사이트" 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/>
          3. 다른 사람의 "사이트" 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br/>
          4. "사이트"를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
          ③ "사이트"가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "사이트"는 회원자격을 상실시킬 수 있습니다.<br/>
          ④ "사이트"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.<br/><br/>
          제8조(회원에 대한 통지)<br/>
          ① "사이트"가 회원에 대한 통지를 하는 경우, 회원이 "사이트"에 제출한 전자우편 주소로 할 수 있습니다.<br/>
          ② "사이트"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "사이트" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.<br/><br/>
          제9조(구매신청)<br/>
          "사이트" 이용자는 "사이트"상에서 이하의 방법에 의하여 구매를 신청합니다.<br/>
          1. 성명, 비밀번호, 전화번호, 핸드폰번호, 주소, E-MAIL 입력<br/>
          2. 상품의 선택<br/>
          3. 결제방법의 선택<br/>
          4. 이 약관에 동의한다는 표시(예, 마우스 클릭)<br/><br/>
          제10조 (계약의 성립)<br/>
          ① "회사"는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하지 않는 한 승낙합니다.<br/>
          1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
          2. 기타 구매신청에 승낙하는 것이 "회사" 기술상 현저히 지장이 있다고 판단하는 경우<br/>
          ② "회사"의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/><br/>
          "사이트"에서 구매한 상품에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.<br/>
          1. 계좌이체<br/>
          2. 신용카드결제<br/>
          3. 온라인무통장입금<br/><br/>
          제12조(수신확인통지·구매신청 변경 및 취소)<br/>
          ① "회사"는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br/>
          ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.<br/>
          ③ "회사"는 배송 전 이용자의 구매신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.<br/><br/>
          제13조(환급, 반품 및 교환)<br/>
          "회사"는 이용자가 구매 신청한 상품이 품절 등의 사유로 상품의 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고, 사전에 상품의 대금을 받은 경우에는 대금을 받은 날부터 3일 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 3일 이내에 환급절차를 취합니다.<br/><br/>
          제14조(개인정보보호)<br/>
          ① "사이트"는 이용자의 정보 수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.<br/>
          다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br/>
          1. 희망ID(회원의 경우)<br/>
          2. 비밀번호(회원의 경우)<br/>
          3. 이름<br/>
          4. 휴대번호<br/>
          5. E-MAIL<br/>
          ② "사이트"가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.<br/>
          ③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 "사이트"이 집니다. 다만, 다음의 경우에는 예외로 합니다.<br/>
          1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br/>
          ④ "사이트"가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받는자, 제공목적 및 제공할 정보의 내용)등 정보통신망이용촉진 등에 관한 법률 제16조 제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.<br/>
          ⑤ 이용자는 언제든지 "사이트"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "사이트"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "사이트"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br/>
          ⑥ "사이트"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.<br/>
          ⑦ "사이트" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.<br/><br/>
          제15조("회사"의 의무)<br/>
          ① "회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품을 제공하는 데 최선을 다하여야 합니다.<br/>
          ② "회사"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
          ③ "회사"가 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br/>
          ④ "회사"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.<br/><br/>
          제16조( 회원의 ID 및 비밀번호에 대한 의무)<br/>
          ① 제14조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br/>
          ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.<br/>
          ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.<br/><br/>
          제17조(이용자의 의무)<br/>
          이용자는 다음 행위를 하여서는 안 됩니다.<br/>
          1. 신청 또는 변경 시 허위내용의 등록<br/>
          2. "사이트"에 게시된 정보의 변경<br/>
          3. "사이트"가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
          4. "사이트" 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
          5. "사이트" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
          6. 외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를 홈페이지에 공개 또는 게시하는 행위<br/><br/>
          제18조(저작권의 귀속 및 이용제한)<br/>
          ① "회사"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "회사"에 귀속합니다.<br/>
          ② 이용자는 "사이트"를 이용함으로써 얻은 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br/><br/>
          제19조(분쟁해결)<br/>
          ① "회사"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.<br/>
          ② "회사"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.<br/>
          ③ "회사"와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.<br/><br/>
          제20조(재판권 및 준거법)<br/>
          ① "회사"와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.<br/>
          ② "회사"와 이용자간에 제기된 전자거래 소송에는 한국법을 적용합니다.<br/><br/>
          부칙 (2021. 10. 01)<br/><br/>
          이 약관은 2021년 10월 01일부터 시행합니다.
          </p>
          </Sheet.Content>
          </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
        </Grid>
      )
    }
    if (this.state.step === 2) {
      return (
        <div style={{paddingTop: 70}}>
        <Grid style={this.state.isJoining ? {pointerEvents: "none", opacity: "0.4"} : {}} container className="relative-position" justify="center">
          <Grid container justify="center">
            <Grid container className="back-button-container" justify="center">
            <span style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>회원가입 ({this.state.step}/2)</span>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', left: 20, top: 20,}}
              onClick={() => {
                this.setState({step: 1})
              }}
            >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
          </Grid>
          { this.state.validPhoneNumber
            ?<TextField disabled={this.state.isCounting} onChange={(event) => {
              if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, ''), validPhoneNumber: null})
                if (/^[0-9]{10}$/g.test( event.target.value.replace(/[^0-9]/g, '')) || /^[0-9]{11}$/g.test( event.target.value.replace(/[^0-9]/g, ''))) {
                  this.setState({activeSendButton: true})
                } else {
                  this.setState({activeSendButton: false})
                }
              }

            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
            InputProps={{style: {marginBottom: 21}, endAdornment: (
                          <InputAdornment position="start">
                            <DoneIcon style={{color: '#006042'}} />
                          </InputAdornment>
                         )}}
             onBlur={() => {
               this.validatePhoneNumber(this.state.phoneNumber)
               if (this.state.phoneNumber === '') {
                 this.setState({phoneNumber: null})
               }
             }}  id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
            :<TextField disabled={this.state.isCounting} autoFocus error={this.state.validPhoneNumber === false} onChange={(event) => {
              if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, ''), validPhoneNumber: null})
                if (/^[0-9]{10}$/g.test( event.target.value.replace(/[^0-9]/g, '')) || /^[0-9]{11}$/g.test( event.target.value.replace(/[^0-9]/g, ''))) {
                  this.setState({activeSendButton: true})
                } else {
                  this.setState({activeSendButton: false})
                }
              }


            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
            InputProps={{style: {marginBottom: 0}}}
             onBlur={() => {
               this.validatePhoneNumber(this.state.phoneNumber)
               if (this.state.phoneNumber === '') {
                 this.setState({phoneNumber: null})
               }
             }}
             helperText={this.state.validPhoneNumber === false ? "10자리 이상의 숫자를 입력해주세요." : " "}
             FormHelperTextProps={{
               style: {fontSize: 11, color: 'red'}
             }} id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
          }

          </Grid>
          <a style={{marginBottom: 20}} onClick={() => {
            if (this.state.activeSendButton && !this.state.isCounting) {
              this.onStartTimer()
              let number = Math.floor(1000 + Math.random() * 9000)
              this.setState({receivedVerificationCode: number})
              firebase.database().ref(`verification/${this.state.phoneNumber}`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  firebase.database().ref(`verification/${this.state.phoneNumber}`).remove()
                  .then(() => {
                    // axios({
                    //   url: `https://sens.apigw.ntruss.com/alimtalk/v2/services/ncp:kkobizmsg:kr:2718863:withanod_kakao_message/messages`,
                    //   method: "post",
                    //   headers: {
                    //     'Content-Type': 'application/json; charset=utf-8',
                    //     'x-ncp-apigw-timestamp': Date.now(),
                    //     'x-ncp-iam-access-key': "aL3UcnVb3kn1oqNKeqQx",
                    //     'x-ncp-apigw-signature-v2': "MIfDWtCpScg8XapP3B2qQbqALfy1imudUwDTZk8o"
                    //   },
                    //   data: {
                    //     "plusFriendId":"with_a_nod",
                    //     "templateCode":"A0003",
                    //     "messages":[
                    //         {
                    //             "to":`${this.state.phoneNumber}`,
                    //             "content":`[위드어나드]
                    //             위드어나드 인증번호 #{인증번호}`,
                    //         }
                    //     ],
                    //   }
                    // })
                    firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                    axios({
                      url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                      method: "post",
                      headers: {
                        "Content-Type":"application/json"
                      },
                      data: {
                        phoneNumber: this.state.phoneNumber,
                        verificationNumber: number
                      }
                    })
                    setTimeout(() => {
                      firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                    }, 1000 * 60 * 2)
                  })
                } else {
                  firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                  axios({
                    url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                    method: "post",
                    headers: {
                      "Content-Type":"application/json"
                    },
                    data: {
                      phoneNumber: this.state.phoneNumber,
                      verificationNumber: number
                    }
                  })
                  setTimeout(() => {
                    firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                  }, 1000 * 60 * 2)
                }
              })
            }

          }} className={this.state.activeSendButton && !this.state.isCounting ? "btn-big-normal no-selection" : "btn-big-normal-disabled no-selection"}>
            { this.state.isCounting
              ?<span style={{color: 'white', fontSize: 13}}>{this.state.timer.minutes}:{this.state.timer.seconds < 10 ? '0' + this.state.timer.seconds.toString() : this.state.timer.seconds}</span>
              :<span style={{color: 'white', fontSize: 13}}>{this.state.hasSentMeassage ? '재전송' : '인증번호 전송'}</span>
            }
          </a>
          { this.state.isCounting &&
            <Grid container justify="center">
            { this.state.validCode
              ?<TextField autoFocus onChange={(event) => {
                if (event.target.value.replace(/[^0-9]/g, '').length < 5) {
                  this.setState({verificationCode: event.target.value.replace(/[^0-9]/g, '')})
                  this.setState({validCode: null})
                  if (event.target.value.replace(/[^0-9]/g, '').length === 4) {
                    if (this.state.receivedVerificationCode === Number(event.target.value.replace(/[^0-9]/g, ''))) {
                      this.setState({validCode: true})
                    } else {
                      this.setState({validCode: false})
                    }
                  }
                }


              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
              InputProps={{style: {marginBottom: 21}, endAdornment: (
                            <InputAdornment position="start">
                              <DoneIcon style={{color: '#006042'}} />
                            </InputAdornment>
                           )}}
               onBlur={() => {
                 if (this.state.receivedVerificationCode === Number(this.state.verificationCode)) {
                   this.setState({validCode: true})
                 } else {
                   this.setState({validCode: false})
                 }
               }}  id="standard-basic" label="인증번호 4자리"  value={this.state.verificationCode} />
              :<TextField error={this.state.validCode === false} onChange={(event) => {
                if (event.target.value.replace(/[^0-9]/g, '').length < 5) {
                  this.setState({verificationCode: event.target.value.replace(/[^0-9]/g, '')})
                  this.setState({validCode: null})
                  if (event.target.value.replace(/[^0-9]/g, '').length === 4) {
                    if (this.state.receivedVerificationCode === Number(event.target.value.replace(/[^0-9]/g, ''))) {
                      this.setState({validCode: true})
                    } else {
                      this.setState({validCode: false})
                    }
                  }
                }


              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
              InputProps={{style: {marginBottom: 0}}}
               onBlur={() => {
                 if (this.state.receivedVerificationCode === Number(this.state.verificationCode)) {
                   this.setState({validCode: true})
                 } else {
                   this.setState({validCode: false})
                 }
               }}
               helperText={this.state.validCode === false ? "올바른 인증번호를 입력해주세요." : " "}
               FormHelperTextProps={{
                 style: {fontSize: 11, color: 'red'}
               }} id="standard-basic" label="인증번호 4자리"  value={this.state.verificationCode} />
            }
            </Grid>
          }
          <span style={{fontSize: 12}}>인증번호는 카카오톡 메세지로 전송됩니다.</span>
          <a onClick={() => {
            if (this.state.validEmail && this.state.validPassword && this.state.validConfirmPassword && this.state.validUsername && !this.state.hasJoined && this.state.agreement && this.state.privacy && this.state.validCode) {
              this.onJoin()
            }

          }} className={this.state.validEmail && this.state.validPassword && this.state.validConfirmPassword && this.state.validUsername && !this.state.hasJoined && this.state.agreement && this.state.privacy && this.state.validCode ? "btn-very-bottom-big no-selection" : "btn-very-bottom-big-disabled no-selection"}>
            <p style={{color: 'white', fontSize: 15}}>회원가입 완료</p>
          </a>
        </Grid>
        { this.state.isJoining &&
          <div className="fixed-center">
          <div className="vertical-center-align direction-column-div auto-width-100">
          <BeatLoader  color={'#006042'} loading={true} size={20} />
          </div>
          </div>
        }

        </div>
      )
    }
  }
}

const styles = {
  helper: {
     color: 'blue',
     fontSize: '.8em',
  }
}

export default withRouter(Join);
