import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import { Image, Col, Row } from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CreateIcon from '@material-ui/icons/Create';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';
import Footer from './Footer';

class PurchasedList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo,
      isDelivered: false
    }
  }


  componentWillMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/purchased_list');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }


  render() {
    if (this.state.isLoading) {
      return <div></div>
    }

    let sconeOrders = null
    let itemOrders = null
    let orders = null

    if (this.state.userInfo.sconeOrders) {
      sconeOrders = Object.values(this.state.userInfo.sconeOrders)
      sconeOrders = sconeOrders.filter(sconeOrders => sconeOrders.paid === true)
      if (sconeOrders.length !== 0) {
        sconeOrders.sort((a, b) => {
          return b.purchasedAt - a.purchasedAt
        })
      } else {
        sconeOrders = null
      }
    }
    if (this.state.userInfo.itemOrders) {
      itemOrders = Object.values(this.state.userInfo.itemOrders)
      itemOrders = itemOrders.filter(itemOrders => itemOrders.paid === true)
      if (itemOrders.length !== 0) {
        itemOrders.sort((a, b) => {
          return b.purchasedAt - a.purchasedAt
        })
      } else {
        itemOrders = null
      }
    }

    if (sconeOrders && itemOrders) {
      orders = sconeOrders.concat(itemOrders);
    } else if (!sconeOrders && itemOrders) {
      orders = itemOrders
    } else if (sconeOrders && !itemOrders) {
      orders = sconeOrders
    } else {
      orders = null
    }


    if (orders) {
      orders.sort((a, b) => {
        return b.purchasedAt - a.purchasedAt
      })
    } else {
      orders = null
    }

    return (
      <Grid style={{paddingBottom: 60}}>
        <Grid container className="back-button-container" justify="center"  >
          <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>구매 목록</p>
          <Link
            to={'/mypage'}
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                } else {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  })
                }
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </Link>
        </Grid>
        <Grid style={{marginBottom: 20}}>
        { orders
          ?<div style={{marginTop: 70}}>
            { orders.map((order, index) => {
              if (order.hasDelivered) {
                return (
                  <div>
                  <Link className="none-underline-text"  onClick={() => {
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  }} to={order.orderSheet ? `/review/${order.orderId}` : `/item_review/${order.orderId}` }>
                  <Grid container  style={{padding: 20, paddingBottom: 10}}  direction="row">
                    <Grid>
                      <Image style={{width: 60, height: 60}} src={Object.values(order.items)[0].image} />
                    </Grid>
                    <Grid style={{marginLeft: 10}}>
                      { order.totalAmount === 1
                        ?<p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold',  marginBottom: 5, width: 220}}>{Object.values(order.items)[0].name}</p>
                        :<p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold',  marginBottom: 5, width: 220}}>{Object.values(order.items)[0].name} 외 {order.totalAmount - 1}건</p>
                      }

                      { order.orderSheet
                        ?<p style={{fontSize: 13, margin: 0, padding: 0,  marginBottom: 5, color: 'gray', marginLeft: 1}}>{this.onSheetNumberToDate(order.orderSheet)} 구매</p>
                        :<p style={{fontSize: 13, margin: 0, padding: 0,  marginBottom: 5, color: 'gray', marginLeft: 1}}>{this.onSheetNumberToDate(order.purchasedDate)} 구매</p>
                      }

                    </Grid>
                  </Grid>
                  </Link>
                  </div>
                )
              }
              if (orders.length === index + 1) {
                if (!this.state.isDelivered) {
                  return (
                    <div className="no-items-main-view">
                      <CreateIcon  style={{color: '#a1a1a1', fontSize: 80}} />
                      <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>배송 완료된 구매 내역이 없습니다.</span>
                    </div>
                  )
                }
              }
            })
            }
          </div>
          :<div className="no-items-main-view">
            <CreateIcon  style={{color: '#a1a1a1', fontSize: 80}} />
            <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>배송 완료된 구매 내역이 없습니다.</span>
          </div>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(PurchasedList);
