import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';


import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';

import * as firebase from 'firebase';


class PrintItemOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,
      itemOrders: null
    }
  }


  componentWillMount() {

  }

  componentDidMount() {
    firebase.database().ref(`itemOrders/${this.state.orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({itemOrders: snapshot.val()})
      }
    })
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }


  render() {
    if (this.state.isLoading || !this.state.itemOrders) {
      return <div></div>
    }
    let itemOrders = this.state.itemOrders
    let itemOrdersArr = Object.values(this.state.itemOrders)
    return (
      <Grid container justify="center" className="item-order-print-container">
        <div className="page">
          { itemOrdersArr.map((order, index) => {
            if (index % 4 === 0) {
              return (
                <div className="direction-row-div">
                <div className="subpage">
                 <div className="direction-column-div">
                  { itemOrdersArr[index + 2] &&
                    <div className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="8">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="5">주문자 정보</td><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th-2">연락처</th>
                            <th id="th-4">주소지</th>
                            <th id="th">딸기 콩포트</th>
                            <th id="th">클로티드 크림</th>
                            <th id="th">아쌈</th>
                            <th id="th">스콘믹스</th>
                            <th id="th">머그컵330ml (그린)</th>
                            <th id="th">머그컵330ml (오렌지)</th>
                            <th id="th">머그컵200ml (그린)</th>
                            <th id="th">머그컵200ml (오렌지)</th>
                            <th id="th">우드티코스터</th>
                            <th id="th">10종 선물세트</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 3}</td>
                            <td id="td-1">{itemOrdersArr[index + 2]['buyerName']}</td>
                            <td id="td-1">{itemOrdersArr[index + 2]['addressTo']['receiver']}</td>
                            <td id="td-2">{itemOrdersArr[index + 2]['addressTo']['phoneNumber']}</td>
                            <td id="td-3">{itemOrdersArr[index + 2]['addressTo']['fullAddress']} {itemOrdersArr[index + 2]['addressTo']['detailAddress']}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['2E3gXD-i1'] ? itemOrdersArr[index + 2]['items']['2E3gXD-i1'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['eXuir_o5k'] ? itemOrdersArr[index + 2]['items']['eXuir_o5k'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['hXo2RQU_w'] ? itemOrdersArr[index + 2]['items']['hXo2RQU_w'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['ACkU0pIH9'] ? itemOrdersArr[index + 2]['items']['ACkU0pIH9'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['BgzeeCnZd2'] ? itemOrdersArr[index + 2]['items']['BgzeeCnZd2'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['BkMuBT9_rM'] ? itemOrdersArr[index + 2]['items']['BkMuBT9_rM'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['DSZYSKZqr'] ? itemOrdersArr[index + 2]['items']['DSZYSKZqr'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['D0vZqaBEdW'] ? itemOrdersArr[index + 2]['items']['D0vZqaBEdW'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['EXXCAMXKn'] ? itemOrdersArr[index + 2]['items']['EXXCAMXKn'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['items']['3qrOlpxqu'] ? itemOrdersArr[index + 2]['items']['3qrOlpxqu'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 2]['totalAmount']}</td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                  }
                  { itemOrdersArr[index + 3] &&
                    <div  className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="8">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="5">주문자 정보</td><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th-2">연락처</th>
                            <th id="th-4">주소지</th>
                            <th id="th">딸기 콩포트</th>
                            <th id="th">클로티드 크림</th>
                            <th id="th">아쌈</th>
                            <th id="th">스콘믹스</th>
                            <th id="th">머그컵330ml (그린)</th>
                            <th id="th">머그컵330ml (오렌지)</th>
                            <th id="th">머그컵200ml (그린)</th>
                            <th id="th">머그컵200ml (오렌지)</th>
                            <th id="th">우드티코스터</th>
                            <th id="th">10종 선물세트</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 4}</td>
                            <td id="td-1">{itemOrdersArr[index + 3]['buyerName']}</td>
                            <td id="td-1">{itemOrdersArr[index + 3]['addressTo']['receiver']}</td>
                            <td id="td-2">{itemOrdersArr[index + 3]['addressTo']['phoneNumber']}</td>
                            <td id="td-3">{itemOrdersArr[index + 3]['addressTo']['fullAddress']} {itemOrdersArr[index + 3]['addressTo']['detailAddress']}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['2E3gXD-i1'] ? itemOrdersArr[index + 3]['items']['2E3gXD-i1'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['eXuir_o5k'] ? itemOrdersArr[index + 3]['items']['eXuir_o5k'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['hXo2RQU_w'] ? itemOrdersArr[index + 3]['items']['hXo2RQU_w'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['ACkU0pIH9'] ? itemOrdersArr[index + 3]['items']['ACkU0pIH9'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['BgzeeCnZd2'] ? itemOrdersArr[index + 3]['items']['BgzeeCnZd2'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['BkMuBT9_rM'] ? itemOrdersArr[index + 3]['items']['BkMuBT9_rM'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['DSZYSKZqr'] ? itemOrdersArr[index + 3]['items']['DSZYSKZqr'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['D0vZqaBEdW'] ? itemOrdersArr[index + 3]['items']['D0vZqaBEdW'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['EXXCAMXKn'] ? itemOrdersArr[index + 3]['items']['EXXCAMXKn'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['items']['3qrOlpxqu'] ? itemOrdersArr[index + 3]['items']['3qrOlpxqu'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 3]['totalAmount']}</td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                  }
                  </div>
                  </div>
                  <div className="subpage">
                  <div className="direction-column-div">
                  { itemOrdersArr[index] &&
                    <div className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="8">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="5">주문자 정보</td><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th-2">연락처</th>
                            <th id="th-4">주소지</th>
                            <th id="th">딸기 콩포트</th>
                            <th id="th">클로티드 크림</th>
                            <th id="th">아쌈</th>
                            <th id="th">스콘믹스</th>
                            <th id="th">머그컵330ml (그린)</th>
                            <th id="th">머그컵330ml (오렌지)</th>
                            <th id="th">머그컵200ml (그린)</th>
                            <th id="th">머그컵200ml (오렌지)</th>
                            <th id="th">우드티코스터</th>
                            <th id="th">10종 선물세트</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 1}</td>
                            <td id="td-1">{itemOrdersArr[index]['buyerName']}</td>
                            <td id="td-1">{itemOrdersArr[index]['addressTo']['receiver']}</td>
                            <td id="td-2">{itemOrdersArr[index]['addressTo']['phoneNumber']}</td>
                            <td id="td-3">{itemOrdersArr[index]['addressTo']['fullAddress']} {itemOrdersArr[index]['addressTo']['detailAddress']}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['2E3gXD-i1'] ? itemOrdersArr[index]['items']['2E3gXD-i1'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['eXuir_o5k'] ? itemOrdersArr[index]['items']['eXuir_o5k'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['hXo2RQU_w'] ? itemOrdersArr[index]['items']['hXo2RQU_w'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['ACkU0pIH9'] ? itemOrdersArr[index]['items']['ACkU0pIH9'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['BgzeeCnZd2'] ? itemOrdersArr[index]['items']['BgzeeCnZd2'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['BkMuBT9_rM'] ? itemOrdersArr[index]['items']['BkMuBT9_rM'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['DSZYSKZqr'] ? itemOrdersArr[index]['items']['DSZYSKZqr'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['D0vZqaBEdW'] ? itemOrdersArr[index]['items']['D0vZqaBEdW'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['EXXCAMXKn'] ? itemOrdersArr[index]['items']['EXXCAMXKn'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['items']['3qrOlpxqu'] ? itemOrdersArr[index]['items']['3qrOlpxqu'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index]['totalAmount']}</td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                  }

                  { itemOrdersArr[index + 1] &&
                    <div  className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="8">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="5">주문자 정보</td><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th-2">연락처</th>
                            <th id="th-4">주소지</th>
                            <th id="th">딸기 콩포트</th>
                            <th id="th">클로티드 크림</th>
                            <th id="th">아쌈</th>
                            <th id="th">스콘믹스</th>
                            <th id="th">머그컵330ml (그린)</th>
                            <th id="th">머그컵330ml (오렌지)</th>
                            <th id="th">머그컵200ml (그린)</th>
                            <th id="th">머그컵200ml (오렌지)</th>
                            <th id="th">우드티코스터</th>
                            <th id="th">10종 선물세트</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 2}</td>
                            <td id="td-1">{itemOrdersArr[index + 1]['buyerName']}</td>
                            <td id="td-1">{itemOrdersArr[index + 1]['addressTo']['receiver']}</td>
                            <td id="td-2">{itemOrdersArr[index + 1]['addressTo']['phoneNumber']}</td>
                            <td id="td-3">{itemOrdersArr[index + 1]['addressTo']['fullAddress']} {itemOrdersArr[index + 1]['addressTo']['detailAddress']}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['2E3gXD-i1'] ? itemOrdersArr[index + 1]['items']['2E3gXD-i1'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['eXuir_o5k'] ? itemOrdersArr[index + 1]['items']['eXuir_o5k'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['hXo2RQU_w'] ? itemOrdersArr[index + 1]['items']['hXo2RQU_w'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['ACkU0pIH9'] ? itemOrdersArr[index + 1]['items']['ACkU0pIH9'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['BgzeeCnZd2'] ? itemOrdersArr[index + 1]['items']['BgzeeCnZd2'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['BkMuBT9_rM'] ? itemOrdersArr[index + 1]['items']['BkMuBT9_rM'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['DSZYSKZqr'] ? itemOrdersArr[index + 1]['items']['DSZYSKZqr'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['D0vZqaBEdW'] ? itemOrdersArr[index + 1]['items']['D0vZqaBEdW'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['EXXCAMXKn'] ? itemOrdersArr[index + 1]['items']['EXXCAMXKn'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['items']['3qrOlpxqu'] ? itemOrdersArr[index + 1]['items']['3qrOlpxqu'].amount : ''}</td>
                            <td id="td-big-font">{itemOrdersArr[index + 1]['totalAmount']}</td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                  }
                  </div>
                  </div>



                </div>
              )
            }

          })

          }
        </div>
      </Grid>
    )
  }
}

export default PrintItemOrders;
