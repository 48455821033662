import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import Modal from '@material-ui/core/Modal';

import { Image, Col, Row, Container} from 'react-bootstrap';


import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';
import roundIcon from '../roundIcon.png';

import moment from 'moment';

import * as firebase from 'firebase';

let QRCode = require('qrcode.react');



class Coupon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      couponId: this.props.match.params.id,
      coupon: null,
      showQRImageModal: false,
      isUsedCoupon: false
    }
  }


  componentWillMount() {
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)
  }

  componentDidMount() {
    firebase.database().ref(`coupons/${this.state.couponId}`).on('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({coupon: snapshot.val()})
        if (snapshot.val().usedAt) {
          this.setState({showQRImageModal: false})
          setTimeout(() => {
            this.setState({isUsedCoupon: true})
          }, 10)
        }
      } else {
        this.setState({coupon: false})
      }
    })
  }

  componentWillUnmount() {
    firebase.database().ref(`coupons/${this.state.couponId}`).off('value')
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  render() {
    if (this.state.isLoading || this.state.coupon === null) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
          <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        </div>
      )
    }
    if (this.state.coupon === false) {
      return <Grid container className="auto-width-100" justify="center"><div style={{marginTop: 100}}><span>쿠폰이 존재하지 않습니다.</span></div></Grid>
    }
    let coupon = this.state.coupon

    return (
      <Grid container className="auto-width-100 no-selection" justify="center">
        <Grid container className="auto-width-100" justify="center" style={coupon.usedAt ? {pointerEvents: "none", opacity: "0.2"} : {}}>
          <div className="auto-width-100" style={{marginLeft: 10}}>
          <ButtonBase
            to={`/home/pickup`}
            component={Link}
            style={{ color: "black", textDecoration: 'none' }}
            onClick={() => {
              if (this.props.navIndexChanges) {
                this.props.navIndexChanges(0)
              }
              setTimeout(() => {
                this.props.routeChanges()
                window.scrollTo({
                top: 0,
                left: 0,
              })
              }, 10)
            }}
          >
          <Image
            className="logo-style"
            src={MainLogo}/>
          </ButtonBase>
          </div>
          <Grid container  justify="center">
          <div className="direction-row-div relative-position" style={{marginTop: 20}}>
            <div className="center-arrow">
            </div>
            <div className="coupon-left-box direction-column-div">
              <span style={{fontSize: 22, color: 'white', fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(coupon.amount)}원</span>
              <span style={{fontSize: 16, color: 'white', fontWeight: 'bold'}}>할인권</span>
            </div>
            <div onClick={() => {
              this.setState({showQRImageModal: true})
            }} className="coupon-right-box direction-column-div">
              <span style={{fontSize: 12, color: 'white'}}>할인 코드</span>
              <QRCode value={this.state.couponId} renderAs={'svg'} level={'H'} size={75} bgColor={'#00615c'} includeMargin={true} fgColor='white' />
              <span style={{fontSize: 10, color: 'white'}}>withanod.com</span>
            </div>
          </div>
          </Grid>
          <span style={{fontSize: 12, color: 'black', marginTop: 5}}>유효기간: {this.onStringNumberToDate(moment(coupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())}</span>
          <Grid container  justify="center">
            <div className="direction-column-div auto-width-90">
              <span style={{fontSize: 14, marginTop: 20, fontWeight: 'bold', marginLeft: 8}}>해당 할인권은</span>
              <span style={{fontSize: 12, marginTop: 5}}>- 현금 교환 및 잔액 환불이 불가합니다.</span>
              <span style={{fontSize: 12, marginTop: 5}}>- 오프라인 매장 현장 구매와 픽업 예약시 사용 가능한 쿠폰입니다.</span>
              <span style={{fontSize: 12, marginTop: 5}}>- 무상으로 제공되는 쿠폰으로 현금영수증 발급과 유효기간 연장이 불가합니다.</span>
            </div>
            <ButtonBase
              to={`/home/pickup`}
              component={Link}
              style={{ color: "black", textDecoration: 'none', marginTop: 20 }}
              onClick={() => {
                if (this.props.navIndexChanges) {
                  this.props.navIndexChanges(0)
                }
                setTimeout(() => {
                  this.props.routeChanges()
                  window.scrollTo({
                  top: 0,
                  left: 0,
                })
                }, 10)
              }}
            >
            <div className="normal-little-bit-small-button">
              <span style={{color: 'white', fontSize: 14}}>픽업 예약 하기</span>
            </div>
            </ButtonBase>
          </Grid>
        </Grid>
        { coupon.usedAt &&
          <div>
            { !this.state.isUsedCoupon
              ?<div className="center-spinner direction-column-div align-center coupon-used-big">
                <span style={{fontSize: 22, color: '#ff4040', fontWeight: 'bold'}}>사용완료</span>
              </div>
              :<div className="center-spinner direction-column-div align-center coupon-used-small">
                <span style={{fontSize: 22, color: '#ff4040', fontWeight: 'bold'}}>사용완료</span>
              </div>
            }
          </div>


        }

        <Modal
          open={this.state.showQRImageModal}
          onClose={() => this.setState({showQRImageModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.8)'}}}
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <QRCode value={this.state.couponId} renderAs={'svg'} level={'H'} size={220} bgColor={'#00615c'} includeMargin={true} fgColor='white' />
          </div>
        </Modal>
      </Grid>
    )
  }
}

export default Coupon;
