import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import PulseLoader from "react-spinners/PulseLoader";
import BeatLoader from "react-spinners/BeatLoader";

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import moment from 'moment';

import CloseIcon from '@material-ui/icons/CloseOutlined';

import Postcode from 'react-daum-postcode';

import { Row, Image } from 'react-bootstrap';

import * as firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faHandPointLeft, faClone } from '@fortawesome/free-regular-svg-icons';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';

import Footer from './Footer';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const axios = require("axios");

class ItemOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,

      showCancelOrderModal: false,
      unavailableCancelSnack: false,
      isCanceling: false,
      gotInvoiceData: false,

      showAllList: false,

      showAddModal: false,
      isChangingAddress: false,
      detailAddressInputError: false,
      receiverInputError: false,
      phoneNumberInputError: false,

      tempFullAddress: '',
      tempDetailAddress: '',
      tempPostCode: '',
      tempReceiver: '',
      tempPhoneNumber: '',
      changeDeliveryAddressSnack: false,
      unavailableChangeAddressSnack: false,

      deliveryProviders: null,
      copiedInvoiceNumberSnack: false
    }
  }


  componentWillMount() {
    firebase.database().ref(`deliveryProviders`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({deliveryProviders: snapshot.val()})
      }
    })
  }

  componentDidMount() {
    setTimeout(() => {
      if (isEdge || isIE) {
          window.scrollTo(0, 0)
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
          })
        }
    }, 10)
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.onGetDeliveryInfo(nextProps.userInfo.itemOrders[this.state.orderId])
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일 ${morningOrAfternoon} ${hours}시${minutes}분`
  }

  async onCancelDelivery(userInfo, orderData) {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    firebase.database().ref(`users/${this.state.userInfo.uid}/itemOrders/${this.state.orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        axios({
          url: `https://api.tosspayments.com/v1/payments/${orderData.paymentKey}/cancel`,
          method: "post",
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Basic " + encodingAuth
          },
          data: {
            cancelReason: '고객이 취소를 원함'
          }
        }).then((data) => {
          firebase.database().ref(`itemOrders/${orderData.purchasedDate}/${this.state.orderId}/items`).once('value', (snapshot) => {
            if (snapshot.val()) {
              let purchaseItems = Object.values(snapshot.val())

              purchaseItems.map((item, index) => {
                firebase.database().ref(`items/${item.itemId}/amount`).once('value', (snapshot) => {
                  if (typeof(snapshot.val()) === 'number') {
                    let remainAmount = snapshot.val()
                    firebase.database().ref(`items/${item.itemId}/amount`).set(remainAmount + item.amount)
                  }
                })

                if (purchaseItems.length === index + 1) {
                  firebase.database().ref(`itemOrders/${orderData.purchasedDate}/${this.state.orderId}`).remove()
                  firebase.database().ref(`users/${this.state.userInfo.uid}/itemOrders/${this.state.orderId}`).remove()
                  this.props.history.replace('/order_list');
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                  this.setState({isCanceling: false})
                  if (this.props.onCancelOrder) {
                    this.props.onCancelOrder()
                  }
                }
              })
            }
          })
        })
      }
    })
  }

  onGetDeliveryInfo(order) {
    if (order.invoiceNumber && !this.state.gotInvoiceData) {
      this.setState({gotInvoiceData: true})
      if (!order.hasDelivered) {
        if (order.trackingTime) {
          if (order.trackingTime + (1000 * 60 * 30) < Date.now()) {
            let providerCode = '06'
            if (order['providerCode']) {
              providerCode = order['providerCode']
            }
            axios({
              url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
              method: "get",
              headers: {
                "Content-Type":"application/json"
              }
            }).then((data) => {
              let deliveries = this.state.userInfo.itemOrders
              let userInfo = this.state.userInfo
              if (data.data.status !== false) {
                userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
              }
              if (data.data.level === 6) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                }

                userInfo.itemOrders[order.orderId]['hasDelivered'] = true
              } else {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())

                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                }
              }
              this.setState({userInfo: userInfo}, () => {
                setTimeout(() => {
                  this.props.onRefreshUserInfo()
                }, 10)
              })
            })
          }
        } else {
          let providerCode = '06'
          if (order['providerCode']) {
            providerCode = order['providerCode']
          }
          axios({
            url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
            method: "get",
            headers: {
              "Content-Type":"application/json"
            }
          }).then((data) => {
            let deliveries = this.state.userInfo.itemOrders
            let userInfo = this.state.userInfo
            if (data.data.status !== false) {
              userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
            }
            if (data.data.level === 6) {
              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
              }

              userInfo.itemOrders[order.orderId]['hasDelivered'] = true
            } else {
              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())
              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
              }

            }
            this.setState({userInfo: userInfo}, () => {
              setTimeout(() => {
                this.props.onRefreshUserInfo()
              }, 10)
            })
          })
        }

      }
    }
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onGetTimeDetailWithoutMin(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일 ${morningOrAfternoon} ${hours}시`
  }

  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect, from } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    let levelDesc = [
      '상품인수', '상품이동', '지점도착', '배송출발', '배송완료'
    ]
    let order = this.state.userInfo.itemOrders[this.state.orderId]
    let orderTrackingDetails = []
    if (order['deiliveryInfo']) {
      orderTrackingDetails = order['deiliveryInfo']['trackingDetails']
      orderTrackingDetails.sort((a, b) => {
        return b.time - a.time
      })
    }
    return (
      <div style={{paddingBottom: 60}}>
      <Grid style={this.state.isCanceling ? {pointerEvents: "none", opacity: "0.4"} : {}} container justify="center" >
        <Grid container className="relative-position" justify="center">
        <Grid container className="back-button-container" justify="center"  >
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>주문 상세</p>
        { from
          ?<div
            item
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              this.props.history.replace('/home/spread')
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
          :<div
            item
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              let currentPath = window.location.pathname
              this.props.history.goBack()

              let inervalFunction = setInterval(() => {
                if (currentPath === window.location.pathname) {
                  this.props.history.goBack()
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                } else {
                  clearInterval(inervalFunction)
                }
              }, 100)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
        }
        { Object.keys(order.items).length === 1 && Object.keys(order.items)[0] === 'ACkU0pIH9'
          ?<div>
          { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 5 &&
            <div>
            { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 73) < Date.now()) &&
            <div
              item
              className="no-selection horiaontal-center-align"
              style={{position: 'absolute', right: 20, top: 26, cursor: 'pointer'}}
              onClick={() => {
                this.setState({showCancelOrderModal: true})
              }}
            >
              <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
              <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>주문 취소</span>
            </div>
            }
            </div>
          }
          { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 6 &&
            <div>
            { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now()) &&
            <div
              item
              className="no-selection horiaontal-center-align"
              style={{position: 'absolute', right: 20, top: 26, cursor: 'pointer'}}
              onClick={() => {
                this.setState({showCancelOrderModal: true})
              }}
            >
              <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
              <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>주문 취소</span>
            </div>
            }
            </div>
          }
          { (new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 5 && new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 6) &&
            <div>
            { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now()) &&
            <div
              item
              className="no-selection horiaontal-center-align"
              style={{position: 'absolute', right: 20, top: 26, cursor: 'pointer'}}
              onClick={() => {
                this.setState({showCancelOrderModal: true})
              }}
            >
              <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
              <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>주문 취소</span>
            </div>
            }
            </div>
          }
          </div>
          :<div>
          { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now()) &&
          <div
            item
            className="no-selection horiaontal-center-align"
            style={{position: 'absolute', right: 20, top: 26, cursor: 'pointer'}}
            onClick={() => {
              this.setState({showCancelOrderModal: true})
            }}
          >
            <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
            <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>주문 취소</span>
          </div>
          }
          </div>
        }

        </Grid>
        <Grid container  justify="center" style={{marginTop: 70}}>

          { Object.values(order.items).map((item, index) => {
            if (this.state.showAllList === false && Object.values(order.items).length > 3) {
              if (index < 3) {
                return (
                  <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                    <Grid>
                      <Image style={{width: 40, height: 40}} src={item.image} />
                    </Grid>
                    <Grid style={{marginLeft: 10}}>
                      <p style={{fontSize: 13, margin: 0, padding: 0}}>{item.name}</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(item.price)}원 / {item.amount}개</p>
                    </Grid>
                  </Grid>
                )
              }

            } else {

                return (
                  <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                    <Grid>
                      <Image style={{width: 40, height: 40}} src={item.image} />
                    </Grid>
                    <Grid style={{marginLeft: 10}}>
                      <p style={{fontSize: 13, margin: 0, padding: 0}}>{item.name}</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(item.price)}원 / {item.amount}개</p>
                    </Grid>
                  </Grid>
                )

            }

          })
          }
          { this.state.showAllList === false && Object.values(order.items).length > 3 &&
            <div className="auto-width-100">
              <span onClick={() => {
                this.setState({showAllList: true})
              }} style={{fontSize: 13, marginLeft: 20, textDecoration: 'underline', cursor: 'pointer'}}>모두 보기</span>
            </div>
          }

          <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, fontWeight: 'bold'}}>총 {order.totalAmount}개 / {this.onChangeCurrencyFormat(order.totalPrice)}원</p>
            <Divider className="auto-width-100" style={{marginTop: 30}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>주문 정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />

              <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>주문일시</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{this.onGetTimeDetail(order.purchasedAt)}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>주문자</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{this.state.userInfo.username}</span>
              </div>
            </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <div className="auto-width-100 direction-row-div justify-between">
              <div className="direction-column-div">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>배송지 정보</span>
                { Object.keys(order.items).length === 1 && Object.keys(order.items)[0] === 'ACkU0pIH9'
                  ?<div>
                  { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 5 &&
                    <span style={{marginLeft: 20, fontSize: 11, marginTop: 4, color: '#006042'}}>{this.onGetTimeDetailWithoutMin(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 73))} 까지 변경 가능</span>
                  }
                  { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 6 &&
                    <span style={{marginLeft: 20, fontSize: 11, marginTop: 4, color: '#006042'}}>{this.onGetTimeDetailWithoutMin(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49))} 까지 변경 가능</span>
                  }
                  { (new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 5 && new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 6) &&
                    <span style={{marginLeft: 20, fontSize: 11, marginTop: 4, color: '#006042'}}>{this.onGetTimeDetailWithoutMin(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25))} 까지 변경 가능</span>
                  }
                  </div>
                  :<div>
                    <span style={{marginLeft: 20, fontSize: 11, marginTop: 4, color: '#006042'}}>{this.onGetTimeDetailWithoutMin(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49))} 까지 변경 가능</span>
                  </div>
                }
              </div>
              { Object.keys(order.items).length === 1 && Object.keys(order.items)[0] === 'ACkU0pIH9'
                ?<div>
                { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 5 &&
                <div>
                { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 73) < Date.now()) &&
                <a onClick={() => {
                  if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 73) < Date.now())) {
                    let orderInfo = this.state.userInfo.itemOrders[this.state.orderId]
                    this.setState({
                      tempFullAddress: orderInfo.addressTo.fullAddress,
                      tempDetailAddress: orderInfo.addressTo.detailAddress,
                      tempPostCode: orderInfo.addressTo.postCode,
                      tempReceiver: orderInfo.addressTo.receiver,
                      tempPhoneNumber: orderInfo.addressTo.phoneNumber,
                      showAddModal: true
                    })
                  } else {
                    this.setState({unavailableChangeAddressSnack: true})
                  }

                }} className="normal-very-small-button none-underline-text align-center" style={{width: 85, marginRight: 20}} target="_blank">
                  <span style={{fontSize: 11, color: 'white'}}>배송지 정보 변경</span>
                </a>
                }
                </div>
                }
                { new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 6 &&
                <div>
                { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now()) &&
                <a onClick={() => {
                  if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now())) {
                    let orderInfo = this.state.userInfo.itemOrders[this.state.orderId]
                    this.setState({
                      tempFullAddress: orderInfo.addressTo.fullAddress,
                      tempDetailAddress: orderInfo.addressTo.detailAddress,
                      tempPostCode: orderInfo.addressTo.postCode,
                      tempReceiver: orderInfo.addressTo.receiver,
                      tempPhoneNumber: orderInfo.addressTo.phoneNumber,
                      showAddModal: true
                    })
                  } else {
                    this.setState({unavailableChangeAddressSnack: true})
                  }

                }} className="normal-very-small-button none-underline-text align-center" style={{width: 85, marginRight: 20}} target="_blank">
                  <span style={{fontSize: 11, color: 'white'}}>배송지 정보 변경</span>
                </a>
                }
                </div>
                }
                { (new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 5 && new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() !== 6) &&
                <div>
                { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now()) &&
                <a onClick={() => {
                  if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now())) {
                    let orderInfo = this.state.userInfo.itemOrders[this.state.orderId]
                    this.setState({
                      tempFullAddress: orderInfo.addressTo.fullAddress,
                      tempDetailAddress: orderInfo.addressTo.detailAddress,
                      tempPostCode: orderInfo.addressTo.postCode,
                      tempReceiver: orderInfo.addressTo.receiver,
                      tempPhoneNumber: orderInfo.addressTo.phoneNumber,
                      showAddModal: true
                    })
                  } else {
                    this.setState({unavailableChangeAddressSnack: true})
                  }

                }} className="normal-very-small-button none-underline-text align-center" style={{width: 85, marginRight: 20}} target="_blank">
                  <span style={{fontSize: 11, color: 'white'}}>배송지 정보 변경</span>
                </a>
                }
                </div>
                }
                </div>
                :<div>
                { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now()) &&
                <a onClick={() => {
                  if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now())) {
                    let orderInfo = this.state.userInfo.itemOrders[this.state.orderId]
                    this.setState({
                      tempFullAddress: orderInfo.addressTo.fullAddress,
                      tempDetailAddress: orderInfo.addressTo.detailAddress,
                      tempPostCode: orderInfo.addressTo.postCode,
                      tempReceiver: orderInfo.addressTo.receiver,
                      tempPhoneNumber: orderInfo.addressTo.phoneNumber,
                      showAddModal: true
                    })
                  } else {
                    this.setState({unavailableChangeAddressSnack: true})
                  }

                }} className="normal-very-small-button none-underline-text align-center" style={{width: 85, marginRight: 20}} target="_blank">
                  <span style={{fontSize: 11, color: 'white'}}>배송지 정보 변경</span>
                </a>
                }
                </div>
              }

              </div>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는사람</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{order.addressTo.receiver}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>연락처</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{this.onChangePhoneNumberFormat(order.addressTo.phoneNumber)}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는주소</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>[{order.addressTo.postCode}] {order.addressTo.fullAddress} {order.addressTo.detailAddress}</span>
              </div>
            </div>
            <Divider className="auto-width-100" style={{marginTop: 20, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>배송 정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              { order.invoiceNumber && this.state.deliveryProviders &&
                <div>
                  <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                    <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>택배사</span>
                    <span className="break-word" style={{fontSize: 13, marginLeft: 58}}>{order.providerCode ?this.state.deliveryProviders[order.providerCode].provider : '로젠택배'}</span>
                  </div>
                  <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                    <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>운송장번호</span>
                    <div className="auto-width-100 direction-row-div" style={{marginBottom: 5}}>
                      <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{order.invoiceNumber}</span>
                      <FontAwesomeIcon onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(order.invoiceNumber)
                        this.setState({copiedInvoiceNumberSnack: true})
                      }} style={{color: 'black', fontSize: 12, marginTop: 3, marginLeft: 3, cursor: 'pointer'}} icon={faClone} />
                    </div>

                  </div>
                  <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                    <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송조회</span>
                  </div>
                </div>
              }
            { order['deiliveryInfo'] ?
            <div style={{marginTop: 20}}>

              <div className="align-center justify-center relative-position row-between-space-86" style={{height: 40, marginTop: 20}}>
                { order['deiliveryInfo']['level'] > 2
                  ?<Divider style={{backgroundColor: '#006042'}} className="first-delivery-process-line" />
                  :<Divider className="first-delivery-process-line" />
                }
                { order['deiliveryInfo']['level'] > 3
                  ?<Divider style={{backgroundColor: '#006042'}} className="second-delivery-process-line" />
                  :<Divider className="second-delivery-process-line" />
                }
                { order['deiliveryInfo']['level'] > 4
                  ?<Divider style={{backgroundColor: '#006042'}} className="third-delivery-process-line" />
                  :<Divider className="third-delivery-process-line" />
                }
                { order['deiliveryInfo']['level'] > 5
                  ?<Divider style={{backgroundColor: '#006042'}} className="fourth-delivery-process-line" />
                  :<Divider className="fourth-delivery-process-line" />
                }
                { order['deiliveryInfo']['level'] > 1
                  ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 14}} icon={faBox} />
                  </div>
                  :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 14}} icon={faBox} />
                  </div>
                }
                { order['deiliveryInfo']['level'] > 2
                  ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruckMoving} />
                  </div>
                  :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruckMoving} />
                  </div>
                }
                { order['deiliveryInfo']['level'] > 3
                  ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faWarehouse} />
                  </div>
                  :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faWarehouse} />
                  </div>
                }
                { order['deiliveryInfo']['level'] > 4
                  ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruck} />
                  </div>
                  :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruck} />
                  </div>
                }
                { order['deiliveryInfo']['level'] > 5
                  ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 15}} icon={faCheck} />
                  </div>
                  :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                  <FontAwesomeIcon style={{color: 'white', fontSize: 15}} icon={faCheck} />
                  </div>
                }
              </div>
              <div className="align-center justify-center relative-position row-between-space-88" style={{height: 10, marginTop: 10}}>
                <span style={{fontSize: 11}}>{levelDesc[0]}</span>
                <span style={{fontSize: 11}}>{levelDesc[1]}</span>
                <span style={{fontSize: 11}}>{levelDesc[2]}</span>
                <span style={{fontSize: 11}}>{levelDesc[3]}</span>
                <span style={{fontSize: 11}}>{levelDesc[4]}</span>
              </div>
              <div className="auto-width-90 direction-row-div align-center margin-left-5" style={{marginTop: 40}}>
                <div className="direction-column-div">
                  { order['deiliveryInfo']['trackingDetails'].map((detail, index) => {
                    return (
                      <div>
                        { index !== 0
                          ?<div className="small-line-circle">
                          </div>
                          :<div className="background-signiture-color z-index-100" style={{width: 11, height: 11, borderRadius: 10}}>
                          </div>
                        }

                        { index + 1 !== order['deiliveryInfo']['trackingDetails'].length &&
                          <Divider  style={{marginTop: 0, height: 40, width: 1, marginLeft: 5, backgroundColor: '#006042'}} />
                        }
                      </div>
                    )
                  })

                  }

                </div>
                <div className="direction-column-div" style={{marginLeft: 20}}>
                { orderTrackingDetails.map((detail, index) => {
                  return (
                    <div className="direction-column-div justify-content-center" style={{height: 52}}>
                      { index === 0
                        ?<div className="direction-row-div">
                          <span style={{fontSize: 13, fontWeight: 'bold'}}>{detail.where}</span>
                          <span style={{fontSize: 13, fontWeight: 'bold', marginLeft: 3, marginRight: 3}}>|</span>
                          <span style={{fontSize: 13, fontWeight: 'bold', color: '#006042'}}>{detail.kind}</span>
                        </div>
                        :<span style={{fontSize: 13}}>{detail.where} | {detail.kind}</span>
                      }
                      <span style={{fontSize: 11, color: 'gray'}}>{detail.timeString}</span>
                    </div>
                  )
                })
                }
                </div>

              </div>
            </div>
            :<div style={{marginTop: 30, marginLeft: 20, marginBottom: 10}}>
              { !(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now())
                ?<span style={{fontSize: 14, fontWeight: 'bold', color: '#006042'}}>발주 확인 전입니다.</span>
                :<span style={{fontSize: 14, fontWeight: 'bold', color: '#006042'}}>현재 상품을 준비하고 있습니다.</span>
              }
            </div>
            }
          </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <div className="auto-width-100 direction-row-div justify-between">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 정보</span>
                { order['card'] &&
                  <a className="normal-very-small-button none-underline-text align-center" style={{width: 60, marginRight: 20}} href={order['card'].receiptUrl} target="_blank">
                    <span style={{fontSize: 11, color: 'white'}}>영수증 출력</span>
                  </a>
                }
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품가격</span>
                <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(order.totalPrice)}원</span>
              </div>
              { order.userClassDiscountAmount &&
                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>등급할인</span>
                  <span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(order.userClassDiscountAmount)}원</span>
                </div>
              }
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
                <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(order.deliveryCharge)}원</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
              { order.userClassDiscountAmount
                ?<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
                  <span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.onChangeCurrencyFormat(order.totalPrice + order.deliveryCharge - order.userClassDiscountAmount)}원</span>
                </div>
                :<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
                  <span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.onChangeCurrencyFormat(order.totalPrice + order.deliveryCharge)}원</span>
                </div>
              }

              { from &&
                <div onClick={() => {
                  this.props.history.replace('/home/spread')
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} className="normal-small-button" style={{margin: 10}}>
                  <span style={{color: 'white'}}>홈으로 가기</span>
                </div>
              }
            </div>
          </Grid>
        </Grid>
        <Snackbar open={this.state.copiedInvoiceNumberSnack} autoHideDuration={4000} onClose={() => this.setState({copiedInvoiceNumberSnack: false})}>
          <Alert  onClose={() => this.setState({copiedInvoiceNumberSnack: false})} severity="success">
            송장번호 복사 완료
          </Alert>
        </Snackbar>
        <Modal
          open={this.state.showCancelOrderModal}
          onClose={() => this.setState({showCancelOrderModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>해당 택배 주문을 취소하시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showCancelOrderModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            if (Object.keys(order.items).length === 1 && Object.keys(order.items)[0] === 'ACkU0pIH9') {
              if (new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 5) {
                if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 73) < Date.now())) {
                  this.setState({showCancelOrderModal: false, isCanceling: true})
                  this.onCancelDelivery(this.state.userInfo, order)
                } else {
                  this.setState({unavailableCancelSnack: true, showCancelOrderModal: false})
                }
              } else if (new Date(this.onDateToTimeStamp(order['purchasedDate'].toString())).getDay() === 6) {
                if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 49) < Date.now())) {
                  this.setState({showCancelOrderModal: false, isCanceling: true})
                  this.onCancelDelivery(this.state.userInfo, order)
                } else {
                  this.setState({unavailableCancelSnack: true, showCancelOrderModal: false})
                }
              } else {
                if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now())) {
                  this.setState({showCancelOrderModal: false, isCanceling: true})
                  this.onCancelDelivery(this.state.userInfo, order)
                } else {
                  this.setState({unavailableCancelSnack: true, showCancelOrderModal: false})
                }
              }
            } else {
              if (!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now())) {
                this.setState({showCancelOrderModal: false, isCanceling: true})
                this.onCancelDelivery(this.state.userInfo, order)
              } else {
                this.setState({unavailableCancelSnack: true, showCancelOrderModal: false})
              }
            }

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>주문 취소</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showAddModal}
          onClose={() => {
            this.setState({showAddModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 변경</span>
            <div className="modal-outside-button" onClick={() => this.setState({showAddModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    this.setState({
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber,
                    })
                    let userInfo = this.state.userInfo
                    userInfo.itemOrders[this.state.orderId].addressTo = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    firebase.database().ref(`itemOrders/${userInfo.itemOrders[this.state.orderId].purchasedDate}/${this.state.orderId}/addressTo`).set(with_a_nod_delivery_address)
                    firebase.database().ref(`users/${this.state.userInfo.uid}/itemOrders/${this.state.orderId}/addressTo`).set(with_a_nod_delivery_address)
                    .then(() => {

                      setTimeout(() => {
                        this.props.onRefreshUserInfo()
                        this.setState({showAddModal: false, changeDeliveryAddressSnack: true})
                      }, 10)
                    })
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>변경</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
            }}
          />
        }

        </Modal>
        <Footer />
      </Grid>
      { this.state.isCanceling &&
        <div className="center-div">
        <div className="vertical-center-align direction-column-div auto-width-100">
        <BeatLoader  color={'#006042'} loading={true} size={20} />
        <span style={{fontSize: 14, marginTop: 10}}>주문 취소 중...</span>
        </div>
        </div>
      }
      <Snackbar open={this.state.unavailableCancelSnack} autoHideDuration={5000} onClose={() => this.setState({unavailableCancelSnack: false})}>
        <Alert  onClose={() => this.setState({unavailableCancelSnack: false})} severity="warning">
          상품준비중 이후로는 취소가 불가합니다.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.changeDeliveryAddressSnack} autoHideDuration={5000} onClose={() => this.setState({changeDeliveryAddressSnack: false})}>
        <Alert  onClose={() => this.setState({changeDeliveryAddressSnack: false})} severity="success">
          배송지 정보가 변경되었습니다.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.unavailableChangeAddressSnack} autoHideDuration={5000} onClose={() => this.setState({unavailableChangeAddressSnack: false})}>
        <Alert  onClose={() => this.setState({unavailableChangeAddressSnack: false})} severity="warning">
          배송지 변경 가능 시간이 지났습니다.
        </Alert>
      </Snackbar>
      </div>
    )
  }
}

export default withRouter(ItemOrder);
