import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PulseLoader from "react-spinners/PulseLoader";
import BeatLoader from "react-spinners/BeatLoader";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import ReactStars from 'react-star-ratings';
import {v4 as uuid} from 'uuid';

import shortid from 'shortid';

import { Row, Image } from 'react-bootstrap';

import * as firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';

import imageCompression from 'browser-image-compression';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Footer from './Footer';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class PickupReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,
      isWriting: false,
      itemId: null,
      rating: 5,
      isUploading: false,
      files: [],
      review: '',
      reviewId: null,

      isAdditionalItems: false
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일`
  }

  onUploadImage() {
      this.setState({isUploading: true})
      let photos = []

      let reviewId = null

      if (this.state.reviewId) {
        reviewId = this.state.reviewId
      } else {
        reviewId = uuid()
      }

      if (this.state.files.length !== 0) {
        Object.values(this.state.files).map(async (photoFile, index) => {
        await this.onImageProcessing(photoFile, index)
        .then((result) => {
          photos.splice(result['index'], 0, result['url'])
          if (this.state.files.length === photos.length) {
            let createdAt = Date.now()
            if (this.state.isAdditionalItems) {
              firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/additionalItems/${this.state.itemId}`).once('value').then((snapshot) => {
                if (snapshot.val()) {
                    firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}`).once('value').then((snapshot) => {
                      if (snapshot.val()) {
                        firebase.database().ref(`/items/${this.state.itemId}/reviews/${reviewId}`).set({
                          review: this.state.review,
                          createdAt: createdAt,
                          itemId: this.state.itemId,
                          rating: this.state.rating,
                          images: photos,
                          reviewId: reviewId,
                          uid: this.state.userInfo.uid
                        })
                        firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/additionalItems/${this.state.itemId}/review`).set({
                          review: this.state.review,
                          createdAt: createdAt,
                          itemId: this.state.itemId,
                          rating: this.state.rating,
                          images: photos,
                          reviewId: reviewId,
                          uid: this.state.userInfo.uid
                        }).then(() => {
                          let userInfo = this.state.userInfo
                          userInfo.sconePickups[this.state.orderId].additionalItems[this.state.itemId].review = {
                            review: this.state.review,
                            createdAt: createdAt,
                            itemId: this.state.itemId,
                            rating: this.state.rating,
                            images: photos,
                            reviewId: reviewId,
                            uid: this.state.userInfo.uid
                          }
                          this.setState({
                            isUploading: false,
                            isWriting: false,
                            itemId: null,
                            rating: 5,
                            isUploading: false,
                            files: [],
                            review: '',
                            userInfo: userInfo,
                            reviewId: null
                          })
                        })
                      }
                    })

                }
              })
            } else {
              firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/items/${this.state.itemId}`).once('value').then((snapshot) => {
                if (snapshot.val()) {
                  firebase.database().ref(`/sconeMenu/${this.state.itemId}/reviews/${reviewId}`).set({
                    review: this.state.review,
                    createdAt: createdAt,
                    itemId: this.state.itemId,
                    rating: this.state.rating,
                    images: photos,
                    reviewId: reviewId,
                    uid: this.state.userInfo.uid
                  })
                  firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/items/${this.state.itemId}/review`).set({
                    review: this.state.review,
                    createdAt: createdAt,
                    itemId: this.state.itemId,
                    rating: this.state.rating,
                    images: photos,
                    reviewId: reviewId,
                    uid: this.state.userInfo.uid
                  }).then(() => {
                    let userInfo = this.state.userInfo
                    userInfo.sconePickups[this.state.orderId].items[this.state.itemId].review = {
                      review: this.state.review,
                      createdAt: createdAt,
                      itemId: this.state.itemId,
                      rating: this.state.rating,
                      images: photos,
                      reviewId: reviewId,
                      uid: this.state.userInfo.uid
                    }
                    this.setState({
                      isUploading: false,
                      isWriting: false,
                      itemId: null,
                      rating: 5,
                      isUploading: false,
                      files: [],
                      review: '',
                      userInfo: userInfo,
                      reviewId: null
                    })
                  })
                }
              })
            }

          }
        })
      })
      } else {
        let createdAt = Date.now()
        if (this.state.isAdditionalItems) {
          firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/additionalItems/${this.state.itemId}`).once('value').then((snapshot) => {
            if (snapshot.val()) {
              firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}`).once('value').then((snapshot) => {
                if (snapshot.val()) {
                  firebase.database().ref(`/items/${this.state.itemId}/reviews/${reviewId}`).set({
                    review: this.state.review,
                    createdAt: createdAt,
                    itemId: this.state.itemId,
                    rating: this.state.rating,
                    reviewId: reviewId,
                    uid: this.state.userInfo.uid
                  })
                  firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/additionalItems/${this.state.itemId}/review`).set({
                    review: this.state.review,
                    createdAt: createdAt,
                    itemId: this.state.itemId,
                    rating: this.state.rating,
                    reviewId: reviewId,
                    uid: this.state.userInfo.uid
                  }).then(() => {
                    let userInfo = this.state.userInfo
                    userInfo.sconePickups[this.state.orderId].additionalItems[this.state.itemId].review = {
                      review: this.state.review,
                      createdAt: createdAt,
                      itemId: this.state.itemId,
                      rating: this.state.rating,
                      images: photos,
                      reviewId: reviewId,
                      uid: this.state.userInfo.uid
                    }
                    this.setState({
                      isUploading: false,
                      isWriting: false,
                      itemId: null,
                      rating: 5,
                      isUploading: false,
                      files: [],
                      review: '',
                      reviewId: null
                    })
                  })
                }
              })

            }
          })
        } else {
          firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/items/${this.state.itemId}`).once('value').then((snapshot) => {
            if (snapshot.val()) {
              firebase.database().ref(`/sconeMenu/${this.state.itemId}/reviews/${reviewId}`).set({
                review: this.state.review,
                createdAt: createdAt,
                itemId: this.state.itemId,
                rating: this.state.rating,
                reviewId: reviewId,
                uid: this.state.userInfo.uid
              })
              firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/items/${this.state.itemId}/review`).set({
                review: this.state.review,
                createdAt: createdAt,
                itemId: this.state.itemId,
                rating: this.state.rating,
                reviewId: reviewId,
                uid: this.state.userInfo.uid
              }).then(() => {
                let userInfo = this.state.userInfo
                userInfo.sconePickups[this.state.orderId].items[this.state.itemId].review = {
                  review: this.state.review,
                  createdAt: createdAt,
                  itemId: this.state.itemId,
                  rating: this.state.rating,
                  images: photos,
                  reviewId: reviewId,
                  uid: this.state.userInfo.uid
                }
                this.setState({
                  isUploading: false,
                  isWriting: false,
                  itemId: null,
                  rating: 5,
                  isUploading: false,
                  files: [],
                  review: '',
                  reviewId: null
                })
              })
            }
          })
        }

      }
    };

  async onImageProcessing(file, index) {
    if (typeof(file) === 'string') {

      return new Promise((resolve, reject) => {
        resolve({'url': file, 'index': index})
      })
    } else {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      })
      return new Promise((resolve, reject) => {
        let userInfo = this.state.userInfo
        let sessionId = new Date().getTime()
        let fileName = `${sessionId}` + `_${shortid.generate()}`

        let imageRef = firebase.storage().ref(`photos/${userInfo.uid}`).child(fileName)
        imageRef.put(compressedFile)
        .then((snapshot) => {
          imageRef.getDownloadURL()
          .then(url => {
            resolve({'url': url, 'index': index})
          });
        })
      })
    }

  }

  fileSelectedHandler = (e) => {
    let files = [...this.state.files, ...e.target.files]
    if (files.length > 4) {
      files = files.slice(0, 4)
    }
    this.setState({ files:  files})
  }

  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    let order = this.state.userInfo.sconePickups[this.state.orderId]
    if (this.state.isWriting && this.state.itemId) {
      if (this.state.isAdditionalItems) {
        return (
          <div style={{paddingBottom: 50}}>
          <Grid container justify="center" style={this.state.isUploading ? {pointerEvents: "none", opacity: "0.4", marginBottom: 70} : {marginBottom: 70}}>
            <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center"  >
            <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>후기 작성</p>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20,}}
              onClick={() => {
                this.setState({
                  isUploading: false,
                  isWriting: false,
                  itemId: null,
                  rating: 5,
                  isUploading: false,
                  files: [],
                  review: '',
                  reviewId: null
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
            </div>
            </Grid>
            <Grid style={{marginTop: 70}} className="auto-width-100 direction-row-div">
              <Grid container style={{padding: 20, paddingBottom: 10, paddingTop: 10}} alignItems="center" direction="row">
                <Image style={{width: 60, height: 60, borderRadius: 10}} src={order.additionalItems[this.state.itemId].image} />
                <span className="one-line" style={{fontSize: 14, marginLeft: 10, marginBottom: 5, width: 220}}>{order.additionalItems[this.state.itemId].name} 후기</span>
              </Grid>
            </Grid>
            <Grid className="star-rating">
              <span style={{fontWeight: 'bold', marginRight: 10}}>평점</span>
              <ReactStars
                numberOfStars={5}
                rating={this.state.rating}
                changeRating={(rating) => {
                  this.setState({rating: rating})
                }}
                starRatedColor={'#006042'}
                starHoverColor={'#006042'}
                starEmptyColor={'#bababa'}
                starDimension={'25px'}
                starSpacing={'1px'} />
                <span style={{fontWeight: 'bold', marginLeft: 7}}>{this.state.rating}</span>
            </Grid>
              <TextField
              className="review-text-input"
              style={{marginTop: 10}}
              id="outlined-multiline-static"
              label="후기를 남겨주세요."
              InputLabelProps={{style: {fontSize: 14}}}
              value={this.state.review}
              onChange={(event) => {
                this.setState({review: event.target.value})
              }}
              multiline
              rows={5}
              variant="outlined"
            />
            <Grid className="direction-column-div auto-width-100">
            <span style={{margin: 20, fontWeight: 'bold'}}>사진 등록(최대 4장)</span>
            <Grid className="direction-row-div div-wrap" style={{paddingLeft: 18}}>
            { this.state.files.length !== 0 &&
              <div className="direction-row-div div-wrap">
                {this.state.files.map((file, index) => {
                  return (
                    <div className="relative-position">
                    <CancelIcon onClick={() => {
                      let files = this.state.files
                      files.splice(index, 1)
                      this.setState({files: files})
                    }} className="cancel-icon"/>
                    { typeof(file) === 'string'
                      ?<Image className="image-object-fit-76" style={{borderRadius: 10, padding: 4}} src={file} />
                      :<Image className="image-object-fit-76" style={{borderRadius: 10, padding: 4}} src={URL.createObjectURL(file)} />
                    }

                    </div>
                  )
                })}
              </div>
            }
            { this.state.files.length < 4 &&
              <div className="input-file-button-div">
                <label className="input-file-button" for="input-file">
                  <AddIcon/>
                </label>
                <input type="file" id="input-file" onChange={this.fileSelectedHandler} multiple style={{display:"none"}}/>
              </div>
            }

            </Grid>
            </Grid>
            </Grid>
            <a onClick={() => {
              if (this.state.review) {
                this.onUploadImage()
              }
            }} className={this.state.review ? "btn-very-bottom-big no-selection" : "btn-very-bottom-big-disabled no-selection"}>
                <p style={{color: 'white', fontSize: 15}}>후기 등록</p>
            </a>

          </Grid>
          { this.state.isUploading &&
            <div className="fixed-center">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 10}}>후기 등록 중...</span>
            </div>
            </div>
          }
          </div>
        )
      } else {
        return (
          <div style={{paddingBottom: 50}}>
          <Grid container justify="center" style={this.state.isUploading ? {pointerEvents: "none", opacity: "0.4", marginBottom: 70} : {marginBottom: 70}}>
            <Grid container className="relative-position" justify="center">
            <Grid container className="back-button-container" justify="center"  >
            <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>후기 작성</p>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20,}}
              onClick={() => {
                this.setState({
                  isUploading: false,
                  isWriting: false,
                  itemId: null,
                  rating: 5,
                  isUploading: false,
                  files: [],
                  review: '',
                  reviewId: null
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
            </div>
            </Grid>
            <Grid style={{marginTop: 70}} className="auto-width-100 direction-row-div">
              <Grid container style={{padding: 20, paddingBottom: 10, paddingTop: 10}} alignItems="center" direction="row">
                <Image style={{width: 60, height: 60, borderRadius: 10}} src={order.items[this.state.itemId].image} />
                <span className="one-line" style={{fontSize: 14, marginLeft: 10, marginBottom: 5, width: 220}}>{order.items[this.state.itemId].name} 후기</span>
              </Grid>
            </Grid>
            <Grid className="star-rating">
              <span style={{fontWeight: 'bold', marginRight: 10}}>평점</span>
              <ReactStars
                numberOfStars={5}
                rating={this.state.rating}
                changeRating={(rating) => {
                  this.setState({rating: rating})
                }}
                starRatedColor={'#006042'}
                starHoverColor={'#006042'}
                starEmptyColor={'#bababa'}
                starDimension={'25px'}
                starSpacing={'1px'} />
                <span style={{fontWeight: 'bold', marginLeft: 7}}>{this.state.rating}</span>
            </Grid>
              <TextField
              className="review-text-input"
              style={{marginTop: 10}}
              id="outlined-multiline-static"
              label="후기를 남겨주세요."
              InputLabelProps={{style: {fontSize: 14}}}
              value={this.state.review}
              onChange={(event) => {
                this.setState({review: event.target.value})
              }}
              multiline
              rows={5}
              variant="outlined"
            />
            <Grid className="direction-column-div auto-width-100">
            <span style={{margin: 20, fontWeight: 'bold'}}>사진 등록(최대 4장)</span>
            <Grid className="direction-row-div div-wrap" style={{paddingLeft: 18}}>
            { this.state.files.length !== 0 &&
              <div className="direction-row-div div-wrap">
                {this.state.files.map((file, index) => {
                  return (
                    <div className="relative-position">
                    <CancelIcon onClick={() => {
                      let files = this.state.files
                      files.splice(index, 1)
                      this.setState({files: files})
                    }} className="cancel-icon"/>
                    { typeof(file) === 'string'
                      ?<Image className="image-object-fit-76" style={{borderRadius: 10, padding: 4}} src={file} />
                      :<Image className="image-object-fit-76" style={{borderRadius: 10, padding: 4}} src={URL.createObjectURL(file)} />
                    }

                    </div>
                  )
                })}
              </div>
            }
            { this.state.files.length < 4 &&
              <div className="input-file-button-div">
                <label className="input-file-button" for="input-file">
                  <AddIcon/>
                </label>
                <input type="file" id="input-file" onChange={this.fileSelectedHandler} multiple style={{display:"none"}}/>
              </div>
            }

            </Grid>
            </Grid>
            </Grid>
            <a onClick={() => {
              if (this.state.review) {
                this.onUploadImage()
              }
            }} className={this.state.review ? "btn-very-bottom-big no-selection" : "btn-very-bottom-big-disabled no-selection"}>
                <p style={{color: 'white', fontSize: 15}}>후기 등록</p>
            </a>

          </Grid>
          { this.state.isUploading &&
            <div className="fixed-center">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 10}}>후기 등록 중...</span>
            </div>
            </div>
          }
          </div>
        )
      }


    }
    return (
      <Grid style={{paddingBottom: 50}} container justify="center">
        <Grid container className="relative-position" justify="center">
        <Grid container className="back-button-container" justify="center"  >
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>상품 후기</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {
            let currentPath = window.location.pathname
            this.props.history.goBack()

            let inervalFunction = setInterval(() => {
              if (currentPath === window.location.pathname) {
                this.props.history.goBack()
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              } else {
                clearInterval(inervalFunction)
              }
            }, 100)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        <Grid style={{marginTop: 70}} container justify="center" direction="column">
          <span style={{padding: 20, fontWeight: 'bold', fontSize: 16, paddingBottom: 10}}>{this.onSheetNumberToDate(order.pickupDate)} 픽업 상품</span>
          { Object.values(order.items).map((item) => {
            return (
              <Grid onClick={() => {
                this.setState({isWriting: true, itemId: item.itemId, isAdditionalItems: false}, () => {
                  setTimeout(() => {
                    firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/items/${item.itemId}/review`).once('value').then((snapshot) => {
                      if (snapshot.val()) {
                        if (snapshot.val().images) {
                          this.setState({files: snapshot.val().images, review: snapshot.val().review, rating: snapshot.val().rating, reviewId: snapshot.val().reviewId})
                        } else {
                          this.setState({review: snapshot.val().review, rating: snapshot.val().rating, reviewId: snapshot.val().reviewId})
                        }
                      }
                    })
                  }, 10)
                })
              }} className="auto-width-100 direction-row-div justify-between" style={{cursor: 'pointer'}}>
                <Grid container style={{padding: 20, paddingBottom: 10, paddingTop: 10}} alignItems="center" direction="row">
                  <Image style={{width: 40, height: 40}} src={item.image} />
                  <p className="one-line" style={{fontSize: 12, marginLeft: 10, marginBottom: 15, width: 180}}>{item.name}</p>
                </Grid>
                { item.review
                  ?<div style={{width: 40, padding: 20}}>
                    <span style={{fontSize: 13, color: '#006042', fontWeight:'bold'}}>수정</span>
                  </div>
                  :<CreateIcon  style={{fontSize: 22, padding: 20, paddingRight: 30}} />
                }
              </Grid>
            )
          })
          }
          { order.additionalItems &&
            <div>
            { Object.values(order.additionalItems).map((item, index) => {
              return (
                <Grid onClick={() => {
                  this.setState({isWriting: true, itemId: item.itemId, isAdditionalItems: true}, () => {
                    setTimeout(() => {
                      firebase.database().ref(`/users/${this.state.userInfo.uid}/sconePickups/${this.state.orderId}/additionalItems/${item.itemId}/review`).once('value').then((snapshot) => {
                        if (snapshot.val()) {
                          if (snapshot.val().images) {
                            this.setState({files: snapshot.val().images, review: snapshot.val().review, rating: snapshot.val().rating, reviewId: snapshot.val().reviewId})
                          } else {
                            this.setState({review: snapshot.val().review, rating: snapshot.val().rating, reviewId: snapshot.val().reviewId})
                          }
                        }
                      })
                    }, 10)
                  })
                }} className="auto-width-100 direction-row-div justify-between" style={{cursor: 'pointer'}}>
                  <Grid container style={{padding: 20, paddingBottom: 10, paddingTop: 10}} alignItems="center" direction="row">
                    <Image style={{width: 40, height: 40}} src={item.image} />
                    <p className="one-line" style={{fontSize: 12, marginLeft: 10, marginBottom: 15, width: 180}}>{item.name}</p>
                  </Grid>
                  { item.review
                    ?<div style={{width: 40, padding: 20}}>
                      <span style={{fontSize: 13, color: '#006042', fontWeight:'bold'}}>수정</span>
                    </div>
                    :<CreateIcon  style={{fontSize: 22, padding: 20, paddingRight: 30}} />
                  }
                </Grid>
              )
            })
            }
            </div>
          }

          </Grid>

        </Grid>
      </Grid>
    )
  }
}

export default withRouter(PickupReview);
