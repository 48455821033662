import React, {Component, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Beforeunload } from 'react-beforeunload';
import PulseLoader from "react-spinners/PulseLoader";
import shortid from 'shortid';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import newProduct from '../newProduct.png';


import Sheet from 'react-modal-sheet';

import {withRouter} from 'react-router-dom';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import { Prompt } from 'react-router'

import Select from 'react-select'
import "video-react/dist/video-react.css";
import { Player,
         ControlBar,
         PlayToggle,
         Shortcut,
         ReplayControl,
         ForwardControl,
         CurrentTimeDisplay,
         TimeDivider,
         PlaybackRateMenuButton,
         VolumeMenuButton
} from 'video-react';

import { Image, Col, Row, Container } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!

import moment from 'moment';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ReactStars from 'react-star-ratings';
import Slider from "react-slick";

import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import pickup from '../Pickup.jpg';
import compoteAndCreamDetail from '../compoteAndCreamDetail.jpg';
import roundIcon from '../roundIcon.png';

import * as firebase from 'firebase';

import { loadTossPayments } from '@tosspayments/sdk';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class WinaGoods extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      winaGoods: null,
      winaGoodsList: [],


      items: null,
      users: null,

      reviews: [],
      showIndex: null,
      imageIndex: 0,

      selectedItems: {},
      noItemsSnack: false,

      selectedItemsTotalAmount: 0,
      selectedItemsTotalPrice: 0,

      noMoreItemsSnack: false,

      openWinaGoodsItems: false,
      getItemInCartSnack: false,

      businessRegistrationNumber: null,
      isRequestingPartnership: false,

      file: null,
      requestedSnack: false,
      waitMessage: '잠시만 기다려주세요...'
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }


  componentWillMount() {
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)

    setTimeout(() => {
      this.setState({waitMessage: '위나 굿즈들을 불러오고 있습니다...'})
    }, 5000)

    setTimeout(() => {
      this.setState({waitMessage: '거의 다 됐습니다!!!'})
    }, 10000)
  }

  componentDidMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let users = snapshot.val()
        this.setState({users: users})
      }
    })

    if (Object.values(this.props.itemsObj).length !== 0) {

        this.setState({items: this.props.itemsObj})
        let winaGoodsObj = this.props.itemsObj
        let winaGoodsList = Object.values(winaGoodsObj)
        winaGoodsList = winaGoodsList.filter(winaGoods => winaGoods.category === 'winaGoods')
        this.setState({winaGoodsList: winaGoodsList})
        winaGoodsList = winaGoodsList.filter(winaGoods => winaGoods.represent)
        let winaGoodskeys = winaGoodsList.map(a => a.id);
        let winaGoods = {}
        winaGoodskeys.map((key, index) => {
          winaGoods[key] = winaGoodsList[index]

          winaGoodsList.map((winaGoods, i) => {
            if (winaGoods.open) {
              this.setState({openWinaGoodsItems: true})
            }
          })

          if (winaGoodskeys.length === index + 1) {
            this.setState({winaGoods: winaGoods})
            let reviews = []
            let bestReviews = []
            let imageReviews = []
            let noImageReviews = []
            Object.values(winaGoods).map((item, innerIndex) => {
              if (item.reviews) {
                reviews = reviews.concat(Object.values(item.reviews))
              }
              if (Object.values(winaGoods).length === innerIndex + 1) {
                reviews.sort((a, b) => {
                  return b.createdAt - a.createdAt
                })
                bestReviews = reviews.filter(reviews => reviews.best)
                imageReviews = reviews.filter(reviews => reviews.images)
                imageReviews = imageReviews.filter(imageReviews => !imageReviews.best)
                imageReviews.sort((a, b) => {
                  return b.rating - a.rating
                })
                imageReviews.sort((a, b) => {
                  return b.review.length - a.review.length
                })
                noImageReviews = reviews.filter(reviews => !reviews.images)
                noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.best)
                noImageReviews.sort((a, b) => {
                  return b.rating - a.rating
                })
                noImageReviews.sort((a, b) => {
                  return b.review.length - a.review.length
                })
                reviews = bestReviews.concat(imageReviews)
                reviews = reviews.concat(noImageReviews)

                this.setState({reviews: reviews})
              }
            })
          }
        })
    } else {
      firebase.database().ref(`items`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({items: snapshot.val()})
          if (this.props.onSetItemsObj) {
            this.props.onSetItemsObj(snapshot.val())
          }
          let winaGoodsObj = snapshot.val()
          let winaGoodsList = Object.values(winaGoodsObj)
          winaGoodsList = winaGoodsList.filter(winaGoods => winaGoods.category === 'winaGoods')
          this.setState({winaGoodsList: winaGoodsList})
          winaGoodsList = winaGoodsList.filter(winaGoods => winaGoods.represent)
          let winaGoodskeys = winaGoodsList.map(a => a.id);
          let winaGoods = {}
          winaGoodskeys.map((key, index) => {
            winaGoods[key] = winaGoodsList[index]

            winaGoodsList.map((winaGoods, i) => {
              if (winaGoods.open) {
                this.setState({openWinaGoodsItems: true})
              }
            })

            if (winaGoodskeys.length === index + 1) {
              this.setState({winaGoods: winaGoods})
              let reviews = []
              let bestReviews = []
              let imageReviews = []
              let noImageReviews = []
              Object.values(winaGoods).map((item, innerIndex) => {
                if (item.reviews) {
                  reviews = reviews.concat(Object.values(item.reviews))
                }
                if (Object.values(winaGoods).length === innerIndex + 1) {
                  reviews.sort((a, b) => {
                    return b.createdAt - a.createdAt
                  })
                  bestReviews = reviews.filter(reviews => reviews.best)
                  imageReviews = reviews.filter(reviews => reviews.images)
                  imageReviews = imageReviews.filter(imageReviews => !imageReviews.best)
                  imageReviews.sort((a, b) => {
                    return b.rating - a.rating
                  })
                  imageReviews.sort((a, b) => {
                    return b.review.length - a.review.length
                  })
                  noImageReviews = reviews.filter(reviews => !reviews.images)
                  noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.best)
                  noImageReviews.sort((a, b) => {
                    return b.rating - a.rating
                  })
                  noImageReviews.sort((a, b) => {
                    return b.review.length - a.review.length
                  })
                  reviews = bestReviews.concat(imageReviews)
                  reviews = reviews.concat(noImageReviews)

                  this.setState({reviews: reviews})
                }
              })
            }
          })
        } else {

        }
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
  }


  componentDidUpdate() {
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('videoDiv');
    if (this.isBottom(wrappedElement)) {
      this.making.play()
      document.removeEventListener('scroll', this.trackScrolling)
    }
  };

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().top + 300 <= window.innerHeight
    }
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
}

  onGetCurrentDateWithDay(day) {
    let today = new Date()
    let dd = day.toString().padStart(2, '0')
    let mm = (today.getMonth() + 1).toString().padStart(2, '0')
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`)
    return result
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day)/7);
    return weekOfMonth
  }

  onGetMonth(time) {
    return time.getMonth()
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    return lastDay
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {

      return sconeMenu[a.id].order - sconeMenu[b.id].order
    })
    return returnValue
  }

  onGetNumberOfRemainders(itemList, itemType) {
    let result = itemList.filter(item => item.itemType === itemType)
    if (result.length === 0) {
      result = 0
    } else {
      result = result.reduce((a, b) => a + b.amount, 0)
    }

    return result
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onCountSelectedItems(selectedItems) {
    if (Object.values(selectedItems).length !== 0) {
      let selectedItemsTotalAmount = 0
      let selectedItemsTotalPrice = 0
      Object.values(selectedItems).map((item, index) => {
        selectedItemsTotalAmount = selectedItemsTotalAmount + item.amount
        if (item.discountedPrice) {
          selectedItemsTotalPrice = selectedItemsTotalPrice + (item.discountedPrice * item.amount)
        } else {
          selectedItemsTotalPrice = selectedItemsTotalPrice + (item.price * item.amount)
        }

        if (Object.values(selectedItems).length === index + 1) {
          this.setState({selectedItemsTotalPrice: selectedItemsTotalPrice, selectedItemsTotalAmount: selectedItemsTotalAmount})
        }
      })
    } else {
      this.setState({selectedItemsTotalAmount: 0, selectedItemsTotalPrice: 0})
    }
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}.${months}.${days}`
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  fileSelectedHandler = (e) => {
    this.setState({file: e.target.files[0]})
  }




  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      })
    }

    if (false) {
      return (
        <div className="center-div direction-column-div">
          <Image style={{width: 250}} src={newProduct} />
          <span className="text-center" style={{fontSize: 16, fontWeight: 'bold', marginTop: 15}}>새로운 상품을 준비 중입니다.</span>
        </div>
      )
    }

    if (this.state.isLoading || !this.state.winaGoods) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
          <Grid container alignItems="center" justify="center" direction="column">
          <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
          <span className="text-center" style={{fontSize: 11, marginTop: 5}}>{this.state.waitMessage}</span>
          </Grid>
        </div>
      )
    }

    let winaGoodsList = Object.values(this.state.winaGoods)

    return (
      <Grid className="main-view auto-width-100 no-selection" >
        { Object.values(winaGoodsList).map((item, index) => {
          if (index % 2 === 0) {
            return (
              <div className="item-card-container">
                  <div  className="item-card">
                  <Link style={{textDecoration: 'none', color: 'black'}} to={`/wina_goods_detail/${winaGoodsList[index].itemType}`}>
                  <Image className="auto-width-100 max-height-100" style={{borderRadius: 10}} src={winaGoodsList[index].representImage} fluid />
                  <div className="direction-column-div">
                  <div className="direction-row-div">
                  <span style={{fontSize: 12, marginTop: 10}}>{winaGoodsList[index]['title']}</span>
                  { this.onGetNumberOfRemainders(this.state.winaGoodsList, winaGoodsList[index]['itemType']) === 0 &&
                    <span style={{fontSize: 12, marginTop: 10, marginLeft: 7, color: 'red'}}>품절</span>
                  }

                  </div>
                  <Divider style={{marginTop: 10, marginBottom: 10}} />
                  <div className="direction-row-div">
                  { winaGoodsList[index]['discountedPrice']
                    ?<span className="strike" style={{fontSize: 12, marginTop: 3}}>{this.onChangeCurrencyFormat(winaGoodsList[index]['price'])}원</span>
                    :<span style={{fontSize: 12, marginTop: 3}}>{this.onChangeCurrencyFormat(winaGoodsList[index]['price'])}원</span>
                  }
                  { winaGoodsList[index]['discountedPrice'] &&
                    <span style={{fontSize: 12, marginTop: 3, marginLeft: 7}}>{this.onChangeCurrencyFormat(winaGoodsList[index]['discountedPrice'])}원</span>
                  }
                  </div>
                  <span style={{fontSize: 12, marginTop: 3}}>{winaGoodsList[index]['explanation']}</span>
                  </div>
                  </Link>
                  </div>
                { winaGoodsList[index + 1] &&
                  <div className="item-card">
                  <Link style={{textDecoration: 'none', color: 'black'}}  to={`/wina_goods_detail/${winaGoodsList[index + 1].itemType}`}>
                    <Image className="auto-width-100 max-height-100" style={{borderRadius: 10}} src={winaGoodsList[index + 1].representImage} fluid />
                    <div className="direction-column-div">
                    <div className="direction-row-div">
                    <span style={{fontSize: 12, marginTop: 10}}>{winaGoodsList[index + 1]['title']}</span>
                    { this.onGetNumberOfRemainders(this.state.winaGoodsList, winaGoodsList[index + 1]['itemType']) === 0 &&
                      <span style={{fontSize: 12, marginTop: 10, marginLeft: 7, color: 'red'}}>품절</span>
                    }

                    </div>
                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                    <div className="direction-row-div">
                    { winaGoodsList[index + 1]['discountedPrice']
                      ?<span className="strike" style={{fontSize: 12, marginTop: 3}}>{this.onChangeCurrencyFormat(winaGoodsList[index + 1]['price'])}원</span>
                      :<span style={{fontSize: 12, marginTop: 3}}>{this.onChangeCurrencyFormat(winaGoodsList[index + 1]['price'])}원</span>
                    }
                    { winaGoodsList[index + 1]['discountedPrice'] &&
                      <span style={{fontSize: 12, marginTop: 3, marginLeft: 7}}>{this.onChangeCurrencyFormat(winaGoodsList[index + 1]['discountedPrice'])}원</span>
                    }
                    </div>
                    <span style={{fontSize: 12, marginTop: 3}}>{winaGoodsList[index + 1]['explanation']}</span>
                    </div>
                    </Link>
                  </div>
                }

              </div>
            )
          }

        })

        }

      </Grid>
    )
  }
}

export default withRouter(WinaGoods);
