import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import BeatLoader from "react-spinners/BeatLoader";

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import queryString from 'query-string'

import Delivery from './Delivery';
import Regular from './Regular';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';

import * as firebase from 'firebase';

const axios = require("axios");

class JustPaymentResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      paymentCompleted: false,
      result: this.props.match.params.result,
      orderId: '',
      isPaying: true,
      requestError: false,
      isRequestingPayment: true
    }
  }


  componentWillMount() {
    this.onRequestPayment()
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  async onRequestPayment() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { paymentKey, orderId, amount } = queryObj;
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')

    firebase.database().ref(`justPayment/${orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {

        let paymentInfo = snapshot.val()
        if (!paymentInfo.completePayment) {
          axios({
            url: `https://api.tosspayments.com/v1/payments/${paymentKey}`,
            method: "post",
            headers: {
              "Content-Type":"application/json",
              "Authorization": "Basic " + encodingAuth
            },
            data: {
              amount: amount, orderId: orderId
            }
          }).then((data) => {
            if (data.status === 200) {
              if (data.data.status === 'DONE') {
                paymentInfo['completePayment'] = true
                paymentInfo['paidAt'] = Date.now()
                if (data.data.card) {
                  paymentInfo['card'] = data.data.card
                }
                firebase.database().ref(`justPayment/${orderId}`).set(paymentInfo)
                .then(() => {
                  this.setState({isPaying: false, paymentCompleted: true}, () => {
                    setTimeout(() => {
                      this.props.history.replace('/just_payment/' + orderId);
                      setTimeout(() => {
                        this.props.routeChanges()
                      }, 10)
                    }, 2000)
                  })
                })
              }
            } else {
              this.setState({isPaying: false, paymentCompleted: false}, () => {
                setTimeout(() => {
                  this.props.history.replace('/just_payment/' + orderId);
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }, 2000)
              })
            }
          }).catch((err) => {
            this.setState({requestError: true}, () => {
              setTimeout(() => {
                this.props.history.replace('/just_payment/' + orderId);
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              }, 2000)
            })
          })
        } else {
          this.setState({isPaying: false, paymentCompleted: true}, () => {
            setTimeout(() => {
              this.props.history.replace('/just_payment/' + orderId);
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }, 2000)
          })
        }
      }
    })
  }



  render() {
    if (this.state.isLoading) {
      return (<div></div>)
    }
    if (this.state.result === 'fail') {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>결제 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isPaying) {
      if (this.state.requestError) {
        return (
          <div>
            <div className="center-div">
              <div className="vertical-center-align direction-column-div auto-width-100">
              <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>결제 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.paymentCompleted) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>결제 정보 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default JustPaymentResult;
