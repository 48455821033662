import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import { Row, Image } from 'react-bootstrap';

import {v4 as uuid} from 'uuid';

import * as firebase from 'firebase';

import { Clear, AddCircle, RemoveCircle } from '@material-ui/icons';

class Pos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      isEdit: false,
      isAdd: false,
      selectedMenu: [],
      total: 0,
      menuCategory: [],
      editModal: false,
      addCategoryModal: false,
      addModal: false,
      removeModal: false,
      editElement: {},
      removeElement: {},
      removeMenu: '',
      editMenu: {name: '', price: ''},
      addCategoryIndex: 0,
      addCategory: {category: '', menu: [{name: '', price: ''}]},
      addMenu: {name: '', price: ''},
      orderList: {},
      orderDetailModal: false,
      orderDetail: {}
    }
  }



  componentWillMount() {

    let date = (new Date(Date.now()).getFullYear()).toString()
    if (new Date(Date.now()).getMonth() + 1 > 9) {
      date = date + (new Date(Date.now()).getMonth() + 1).toString()
    } else {
      date = date + '0' + (new Date(Date.now()).getMonth() + 1).toString()
    }
    firebase.database().ref(`menuCategory`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({menuCategory: snapshot.val()})
      }
    })
    firebase.database().ref(`orderList/${date}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({orderList: snapshot.val()})
      }
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo !== this.props.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onGetTime(now) {
  if ((new Date(Date.now()).getFullYear() == now.getFullYear()) &&
      (new Date(Date.now()).getMonth() == now.getMonth()) &&
      (new Date(Date.now()).getDate() == now.getDate())) {
        let time = ''
        if (now.getHours() < 10) {
          time = `오전 ${now.getHours()}`
        } else if (now.getHours() > 9 && now.getHours() < 12) {
          time = `오전 ${now.getHours()}`
        } else if (now.getHours() == 12) {
          time = '오후 12'
        } else if (now.getHours() > 12) {
          time = `오후 ${now.getHours() - 12}`
        }
        if (now.getMinutes() < 10) {
          time += `:0${now.getMinutes()}`
        } else {
          time += `:${now.getMinutes()}`
        }
        return time
      } else {
        return `${now.getFullYear()}.${now.getMonth() + 1}.${now.getDate()}`
      }
  }

  onGetToday(now) {
    let time = ''
    let day = ''
    if (now.getHours() < 10) {
      time = `오전 ${now.getHours()}`
    } else if (now.getHours() > 9 && now.getHours() < 12) {
      time = `오전 ${now.getHours()}`
    } else if (now.getHours() == 12) {
      time = '오후 12'
    } else if (now.getHours() > 12) {
      time = `오후 ${now.getHours() - 12}`
    }
    if (now.getMinutes() < 10) {
      time += `:0${now.getMinutes()}`
    } else {
      time += `:${now.getMinutes()}`
    }

    if (now.getDay() === 1) {
      day = '(월)'
    } else if (now.getDay() === 2) {
      day = '(화)'
    } else if (now.getDay() === 3) {
      day = '(수)'
    } else if (now.getDay() === 4) {
      day = '(목)'
    } else if (now.getDay() === 5) {
      day = '(금)'
    } else if (now.getDay() === 6) {
      day = '(토)'
    } else if (now.getDay() === 0) {
      day = '(일)'
    }

    return `${now.getFullYear()}년 ${now.getMonth() + 1}월 ${now.getDate()}일 ${day}`
  }

  render() {
    let menuCategory = this.state.menuCategory
    let selectedMenu = this.state.selectedMenu
    let total = this.state.total
    let orderList = Object.values(this.state.orderList)
    orderList.sort((a, b) => {
      return b.paidAt - a.paidAt
    })

    return (
        <Grid container justify="center">
          <NavBar/>
          <Grid item xs={12} sm={6}>
            <p style={{fontWeight: 'bold', fontSize: 18}}>POS</p>
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={4}>
                <p style={{fontWeight: 'bold'}}>주문</p>
                <div  style={{minHeight: 200}}>
                { selectedMenu.length !== 0 &&
                  <div>
                    {selectedMenu.map((selected, index) => {
                      return (
                        <Grid container direction="row">
                          <p style={{margin: 5, marginLeft: 0}}>{selected.name}</p>
                          <p style={{margin: 5, marginLeft: 0}}>{selected.price.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                          <Clear onClick={() => {
                            total -= selected.price
                            selectedMenu.splice(index, 1)
                            this.setState({selectedMenu: selectedMenu, total: total})
                          }} style={{fontSize: 20, marginTop: 5, cursor: 'pointer'}} />
                        </Grid>
                      )
                    })}
                  </div>
                }
                </div>
                <p style={{fontWeight: 'bold', fontSize: 18}}>
                  합계: {this.state.total.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </p>
              </Grid>
              <Grid item xs={12} sm={8}>
              <Grid container direction="row">
                <p style={{fontWeight: 'bold'}}>메뉴</p>
                { this.state.isEdit &&
                  <div>
                  <AddCircle onClick={() => {
                    this.setState({addCategoryModal: true, addCategory: {category: '', menu: [{name: '', price: ''}]}})
                  }} style={{fontSize: 25, marginTop: 15, cursor: 'pointer'}} />
                  </div>
                }
                </Grid>
                { menuCategory.length !== 0 &&
                  <div>
                    {menuCategory.map((category, categoryIndex) => {
                      return (
                        <div>
                          <p style={{fontSize: 18}}>{category.category}</p>
                          { category.menu.length !== 0 &&
                            <Grid container direction="row">
                              {category.menu.map((menu, menuIndex) => {
                                return (
                                  <div style={{position: 'relative'}}>
                                  <Button style={{marginRight: 10}} onClick={() => {
                                    if (this.state.isEdit) {
                                      this.setState({editModal: true, editElement: {categoryIndex: categoryIndex, menuIndex: menuIndex}, editMenu: {name: menu.name, price: menu.price}})
                                    } else {
                                      total += menu.price
                                      selectedMenu.push(menu)
                                      this.setState({selectedMenu: selectedMenu, total: total})
                                    }
                                  }} className="menu-button-style">
                                    <div style={{flexDirection: 'column'}}>
                                    <p style={{color: 'white', margin: 0}}>{menu.name}</p>
                                    <p style={{color: 'white', margin: 0}}>{menu.price.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                                    </div>
                                  </Button>
                                  { this.state.isEdit &&
                                    <RemoveCircle onClick={() => {
                                      this.setState({removeModal: true, removeElement: {categoryIndex: categoryIndex, menuIndex: menuIndex}, removeMenu: menu.name})
                                    }} style={{fontSize: 30, marginTop: 10, cursor: 'pointer', position: 'absolute', top: -26, right: 0}} />
                                  }

                                  </div>
                                )
                              })}

                            </Grid>
                          }
                          { this.state.isEdit &&
                            <div>
                            <AddCircle onClick={() => {
                              this.setState({addModal: true, addCategoryIndex: categoryIndex, addMenu: {name: '', price: ''}})
                            }} style={{fontSize: 30, marginTop: 10, cursor: 'pointer'}} />
                            </div>
                          }
                        </div>
                      )
                    })}

                  </div>
                }

              </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-end">
              { this.state.isEdit
                ?<Button onClick={() => {
                  this.setState({isEdit: false})
                }} style={{marginTop: 10}} variant="outlined" color="primary">
                  <p style={{color: '#0388fc', margin: 0}}>주문</p>
                </Button>
                :<Button onClick={() => {
                  this.setState({isEdit: true, selectedMenu: [], total: 0})
                }} style={{marginTop: 10}}>
                  <p style={{color: '#0388fc', margin: 0, textDecoration: 'underline'}}>편집</p>
                </Button>
              }
            </Grid>
            <Grid container direction="row" justify="center">
              <Button onClick={() => {
                let date = (new Date(Date.now()).getFullYear()).toString()
                if (new Date(Date.now()).getMonth() + 1 > 9) {
                  date = date + (new Date(Date.now()).getMonth() + 1).toString()
                } else {
                  date = date + '0' + (new Date(Date.now()).getMonth() + 1).toString()
                }
                let orderID = uuid()
                let selectedMenu = this.state.selectedMenu
                let orderList = this.state.orderList
                let total = this.state.total
                let order = {
                   orderID: orderID,
                   paidAt: Date.now(),
                   orderMenu: selectedMenu,
                   amountOfPayment: total
                }
                if (selectedMenu.length !== 0) {
                  firebase.database().ref(`orderList/${date}/${orderID}`).set(order)
                  .then(() => {
                    firebase.database().ref(`orderList/${date}`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        this.setState({
                          orderList: snapshot.val()
                        })
                      } else {
                        this.setState({
                          orderList: {}
                        })
                      }
                    })
                    this.setState({
                      selectedMenu: [],
                      total: 0
                    })
                  })
                }
              }} style={{marginTop: 10}} className="button-style">
                <p style={{color: 'white', margin: 0}}>결제 완료</p>
              </Button>
            </Grid>
            { orderList.length !== 0 &&
              <div style={{marginTop: 35, marginBottom: 50}}>
              <p style={{fontWeight: 'bold'}}>주문 내역</p>
                {orderList.map((item, index) => {
                  if (this.onGetToday(new Date(item.paidAt)) === this.onGetToday(new Date(Date.now()))) {
                    return (
                      <div style={{cursor:'pointer'}} onClick={() => {
                        this.setState({orderDetailModal: true, orderDetail: item})
                      }}>
                        <Grid container direction="row" justify="space-between">
                          <div>
                          <Grid container direction="row">
                          <p  style={{fontSize: 13, marginRight: 10}}>{this.onGetTime(new Date(item.paidAt))}</p>
                          { item.orderMenu.length === 1
                            ?<p style={{fontSize: 13}}>{item.orderMenu[0].name}</p>
                            :<p style={{fontSize: 13}}>{item.orderMenu[0].name} 외 {item.orderMenu.length - 1}개</p>
                          }
                          </Grid>
                          </div>
                          <p  style={{fontSize: 13, fontWeight: 'bold'}}>{item.amountOfPayment.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        </Grid>
                        <Divider/>
                      </div>
                    )
                  }
                })}
              </div>
            }
          </Grid>
          <Dialog open={this.state.editModal} onClose={() => {
            this.setState({editModal: false})
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">메뉴 수정</DialogTitle>
            <DialogContent>
              <Grid container direction="column">
              <TextField
                id="name"
                label="메뉴 이름"
                style={{width: 250, margin: 0}}
                value={this.state.editMenu.name}
                onChange={(event) => {
                  let editMenu = this.state.editMenu
                  editMenu['name'] = event.target.value
                  this.setState({editMenu: editMenu})
                }}
                fullWidth
              />
              <TextField
                id="price"
                label="가격"
                style={{width: 250, marginTop: 10}}
                value={this.state.editMenu.price}
                onChange={(event) => {
                  let editMenu = this.state.editMenu
                  editMenu['price'] = Number(event.target.value)
                  this.setState({editMenu: editMenu})
                }}
                fullWidth
              />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({editModal: false})
              }} color="primary">
                취소
              </Button>
              <Button onClick={() => {
                let editElement = this.state.editElement
                if (this.state.editMenu.name && this.state.editMenu.price) {
                  firebase.database().ref(`menuCategory`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let menuCategoryData = snapshot.val()
                      menuCategoryData[editElement.categoryIndex].menu[editElement.menuIndex] = this.state.editMenu
                      firebase.database().ref(`menuCategory`).set(menuCategoryData)
                      .then(() => {
                        this.setState({menuCategory: menuCategoryData})
                      })
                    }
                  })
                  this.setState({editModal: false})
                }

              }} color="primary">
                수정
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.addModal} onClose={() => {
            this.setState({addModal: false})
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">메뉴 추가</DialogTitle>
            <DialogContent>
              <Grid container direction="column">
              <TextField
                id="name"
                label="메뉴 이름"
                style={{width: 250, margin: 0}}
                value={this.state.addMenu.name}
                onChange={(event) => {
                  let addMenu = this.state.addMenu
                  addMenu['name'] = event.target.value
                  this.setState({addMenu: addMenu})
                }}
                fullWidth
              />
              <TextField
                id="price"
                label="가격"
                style={{width: 250, marginTop: 10}}
                value={this.state.addMenu.price}
                onChange={(event) => {
                  let addMenu = this.state.addMenu
                  addMenu['price'] = Number(event.target.value)
                  this.setState({addMenu: addMenu})
                }}
                fullWidth
              />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({addModal: false})
              }} color="primary">
                취소
              </Button>
              <Button onClick={() => {
                let addCategoryIndex = this.state.addCategoryIndex
                if (this.state.addMenu.name && this.state.addMenu.price) {
                  firebase.database().ref(`menuCategory`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let menuCategoryData = snapshot.val()
                      menuCategoryData[addCategoryIndex].menu.push(this.state.addMenu)
                      firebase.database().ref(`menuCategory`).set(menuCategoryData)
                      .then(() => {
                        this.setState({menuCategory: menuCategoryData})
                      })
                    }
                  })
                  this.setState({addModal: false})
                }

              }} color="primary">
                추가
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.removeModal} onClose={() => {
            this.setState({removeModal: false})
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">메뉴 삭제</DialogTitle>
            <DialogContent>
              <DialogContentText>
                [{this.state.removeMenu}] 메뉴를 삭제하시겠습니끼?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({removeModal: false})
              }} color="primary">
                취소
              </Button>
              <Button onClick={() => {
                let removeElement = this.state.removeElement
                firebase.database().ref(`menuCategory`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    let menuCategoryData = snapshot.val()
                    if (menuCategoryData[removeElement.categoryIndex].menu.length === 1) {
                      menuCategoryData.splice(removeElement.categoryIndex, 1)
                    } else {
                      menuCategoryData[removeElement.categoryIndex].menu.splice(removeElement.menuIndex, 1)
                    }
                    firebase.database().ref(`menuCategory`).set(menuCategoryData)
                    .then(() => {
                      this.setState({menuCategory: menuCategoryData})
                    })
                  }
                })
                this.setState({removeModal: false})
              }} color="primary">
                삭제
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.addCategoryModal} onClose={() => {
            this.setState({addCategoryModal: false})
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">카테고리 추가</DialogTitle>
            <DialogContent>
              <Grid container direction="column">
              <TextField
                id="name"
                label="카테고리 이름"
                style={{width: 250, margin: 0}}
                value={this.state.addCategory.name}
                onChange={(event) => {
                  let addCategory = this.state.addCategory
                  addCategory['category'] = event.target.value
                  this.setState({addCategory: addCategory})
                }}
                fullWidth
              />
              <TextField
                id="name"
                label="메뉴 이름"
                style={{width: 250, marginTop: 10}}
                value={this.state.addCategory.name}
                onChange={(event) => {
                  let addCategory = this.state.addCategory
                  addCategory['menu'][0]['name'] = event.target.value
                  this.setState({addCategory: addCategory})
                }}
                fullWidth
              />
              <TextField
                id="price"
                label="가격"
                style={{width: 250, marginTop: 10}}
                value={this.state.addCategory.price}
                onChange={(event) => {
                  let addCategory = this.state.addCategory
                  addCategory['menu'][0]['price'] = Number(event.target.value)
                  this.setState({addCategory: addCategory})
                }}
                fullWidth
              />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({addCategoryModal: false})
              }} color="primary">
                취소
              </Button>
              <Button onClick={() => {
                if (this.state.addCategory.category && this.state.addCategory.menu[0].name && this.state.addCategory.menu[0].price) {
                  firebase.database().ref(`menuCategory`).once('value', (snapshot) => {
                    if (snapshot.val()) {
                      let menuCategoryData = snapshot.val()
                      menuCategoryData.push(this.state.addCategory)
                      firebase.database().ref(`menuCategory`).set(menuCategoryData)
                      .then(() => {
                        this.setState({menuCategory: menuCategoryData})
                      })
                    }
                  })
                  this.setState({addCategoryModal: false})
                }

              }} color="primary">
                추가
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.orderDetailModal} onClose={() => {
            this.setState({orderDetailModal: false})
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">주문 상세정보</DialogTitle>
            <DialogContent style={{width: 300}}>
              <DialogContentText>
                {Object.keys(this.state.orderDetail).length &&
                  <div>
                    {Object.values(this.state.orderDetail.orderMenu).map((orderMenu, index) => {
                      return (
                        <div>
                          <p style={{fontSize: 14, margin: 0, color: 'black'}}>{orderMenu.name}</p>
                        </div>
                      )
                    })}
                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                    <Grid container direction="row" justify="space-between">
                    <p style={{fontSize: 16, margin: 0, color: 'black', fontWeight: 'bold'}}>총 결제 금액: {this.state.orderDetail.amountOfPayment.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    <p style={{fontSize: 12, margin: 0, color: 'black', marginTop: 5}}>{this.onGetTime(new Date(this.state.orderDetail.paidAt))}</p>
                    </Grid>
                  </div>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({orderDetailModal: false})
              }} color="primary">
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
    );
  }
}

export default Pos;
