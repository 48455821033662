import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';


import Modal from '@material-ui/core/Modal';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import DoneIcon from '@material-ui/icons/Done';
import HashLoader from "react-spinners/HashLoader";

import Sheet from 'react-modal-sheet';

import CloseIcon from '@material-ui/icons/CloseOutlined';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import ReactTooltip from 'react-tooltip';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import shortid from 'shortid';
import moment from 'moment';

import {withRouter} from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
  isMobileOnly, isIE, isEdge, isMobile
} from "react-device-detect";

import StickyBox from "react-sticky-box";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck, faChevronRight, faChevronLeft, faBell } from '@fortawesome/free-solid-svg-icons';
import ReactStars from 'react-star-ratings';
import Slider from "react-slick";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScrollContainer from 'react-indiana-drag-scroll'
import {v4 as uuid} from 'uuid';

import Footer from './Footer';

import Swiper from 'react-id-swiper';

import { Image, Col, Container, Row } from 'react-bootstrap';

import firstPurchaseFreeImage from '../popup_firstPurchaseFree.jpg';
import roundIcon from '../roundIcon.png';
import chatIcon from '../coffeeIcon.jpg';
import mainImage1 from '../mainImage1.jpg';
import mainImage2 from '../mainImage2.jpg';
import mainImage3 from '../mainImage3.jpg';
import mainImage4 from '../mainImage4.jpg';
import sconeProductInfo from '../SconeProductInfo.png';
import sconeProductInfo2 from '../SconeProductInfo2.png';
import sconeProductInfo3 from '../SconeProductInfo3.jpg';
import sconeProductInfo4 from '../SconeProductInfo4.jpg';
import SconeProductInfo5 from '../SconeProductInfo5.jpg';
import sconeDivider from '../sconeDivider.png';
import giftSetDivider from '../giftSetDivider.png';
import cookieDivider from '../cookieDivider.png';
import rollDivider from '../rollDivider.png';
import financierDivider from '../financierDivider.png';
import cakeDivider from '../cakeDivider.png';
import bunDivider from '../bunDivider.png';
import giftBoxDivider from '../giftBoxDivider.png';
import bestReviews from '../bestReviews.jpg';
import buildingWeb from '../buildingWeb.jpg';

import * as firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Delivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,

      firstLanded: false,

      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,

      reviews: [],
      bestReviews: [],
      imageReviews: [],
      showIndex: null,
      imageIndex: 0,
      users: null,
      prevItemNavIndex: 0,

      oneSecondLock: false,
      orderSheet: null,
      eventScone: null,
      itmeNavIndex: 0,
      sconeMenu: {},
      sconeMenuLength: 0,
      totalAmount: 0,
      totalPrice: 0,
      selectedScones: {},
      showNotice: false,
      showTooltip: false,
      tabsPrevScrollPosition: [],
      loaded: false,
      noMoreSconesSnack: false,
      remainScones: 0,

      noMoreGiftBoxSnack: false,

      showSconemageModal: false,
      selectedSconeImage: null,

      setAlarm: false,
      visibleAlarm: false,

      firstSetAlarm: false,

      setAlarmSnack: false,
      firstSetAlarmSnack: false,

      numberOfShowingReviews: 30,

      showFirstPurchaseFreeModal: false,

      timerCount: null,

      isOpenPhotoReviewSheet: false,
      isOpenPhotoReviewDetailSheet: false,

      selectedPhotoReviews: [],
      selectedPhotoReview: null,
      imageReviewIndex: 0,
      waitMessage: '잠시만 기다려주세요...',

      specialItem: false,

      giftBoxAmount: 0
    }
    this.tabsView = React.createRef()
    this.introRef = React.createRef()
    this.imageRef1 = React.createRef()
    this.imageRef2 = React.createRef()

    this.imageRviewRef = React.createRef()

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }


  componentWillMount() {
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)

    setTimeout(() => {
      this.setState({waitMessage: '디저트들을 불러오고 있습니다...'})
    }, 5000)

    setTimeout(() => {
      this.setState({waitMessage: '거의 다 됐습니다!!!'})
    }, 10000)

    

    if (sessionStorage.getItem('deliveryData')) {
      let deliveryData = JSON.parse(sessionStorage.getItem('deliveryData'))
      this.setState(deliveryData)
      sessionStorage.removeItem('deliveryData')
      if (this.props.onSelectScone) {
        this.props.onSelectScone(deliveryData.totalPrice, deliveryData.totalAmount, deliveryData.selectedScones, 0)
      }
    }

    if (this.props.totalAmount) {
      this.setState({totalAmount: this.props.totalAmount})
    }
    if (this.props.selectedScones) {
      this.setState({selectedScones: this.props.selectedScones})
    }
    if (this.props.giftBoxAmount) {
      this.setState({giftBoxAmount: this.props.giftBoxAmount})
    }
    if (this.props.totalPrice) {
      this.setState({totalPrice: this.props.totalPrice})
    }

    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let users = snapshot.val()
        this.setState({users: users})
      }
    })



    firebase.database().ref(`openOrderSheet`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({openOrderSheet: snapshot.val()})
        firebase.database().ref(`orderSheets/${snapshot.val()}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val().eventScone) {
              this.setState({eventScone: snapshot.val().eventScone})
            }
            if (snapshot.val().discount) {
              if (snapshot.val().discount['until']) {
                let inervalFunction = setInterval(() => {
                  if (snapshot.val().discount['until'] > Date.now()) {
                    this.setState({timerCount: snapshot.val().discount['until'] - Date.now() })
                  } else {
                    this.setState({timerCount: null})
                    clearInterval(inervalFunction)
                  }
                }, 1000)
              }
            }
            this.setState({orderSheet: snapshot.val()})
            setTimeout(() => {
              if (this.props.selectedScones) {
                this.onCheckSpecialItem(this.props.selectedScones)
              }
            }, 50)
          }
        }).then(() => {
          firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
            if (snapshot.val()) {

              this.setState({sconeMenu: snapshot.val(), sconeMenuLength: Object.keys(snapshot.val()).length})
              let reviews = []
              let bestReviews = []
              let winaPickReviews = []
              let imageReviews = []
              let noImageReviews = []
              Object.values(snapshot.val()).map((item, innerIndex) => {
                if (item.reviews) {
                  reviews = reviews.concat(Object.values(item.reviews))
                }


                if (Object.values(snapshot.val()).length === innerIndex + 1) {
                  bestReviews = reviews.filter(reviews => reviews.best)
                  winaPickReviews = reviews.filter(reviews => reviews.winaPick)
                  let recentBestReviews = bestReviews.filter(bestReviews => bestReviews.createdAt > Date.now() - (1000 * 60 * 60 * 24 * 30))
                  let pastBestReviews = bestReviews.filter(bestReviews => bestReviews.createdAt <= Date.now() - (1000 * 60 * 60 * 24 * 30))
                  bestReviews = recentBestReviews.concat(pastBestReviews)
                  this.setState({bestReviews: bestReviews})
                  imageReviews = reviews.filter(reviews => reviews.images)
                  imageReviews = imageReviews.filter(imageReviews => !imageReviews.best)
                  imageReviews = imageReviews.filter(imageReviews => !imageReviews.winaPick)
                  imageReviews.sort((a, b) => {
                    return b.rating - a.rating
                  })
                  noImageReviews = reviews.filter(reviews => !reviews.images)
                  noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.best)
                  noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.winaPick)
                  noImageReviews.sort((a, b) => {
                    return b.rating - a.rating
                  })
                  reviews = bestReviews.concat(winaPickReviews)
                  reviews = reviews.concat(imageReviews)
                  reviews = reviews.concat(noImageReviews)

                  this.setState({reviews: reviews})
                }

              })
            }
          })
        })
      } else {
        firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
          if (snapshot.val()) {

            this.setState({sconeMenu: snapshot.val(), sconeMenuLength: Object.keys(snapshot.val()).length})
            let reviews = []
            let bestReviews = []
            let winaPickReviews = []
            let imageReviews = []
            let noImageReviews = []
            Object.values(snapshot.val()).map((item, innerIndex) => {
              if (item.reviews) {
                reviews = reviews.concat(Object.values(item.reviews))
              }


              if (Object.values(snapshot.val()).length === innerIndex + 1) {
                bestReviews = reviews.filter(reviews => reviews.best)
                winaPickReviews = reviews.filter(reviews => reviews.winaPick)
                let recentBestReviews = bestReviews.filter(bestReviews => bestReviews.createdAt > Date.now() - (1000 * 60 * 60 * 24 * 30))
                let pastBestReviews = bestReviews.filter(bestReviews => bestReviews.createdAt <= Date.now() - (1000 * 60 * 60 * 24 * 30))

                bestReviews = recentBestReviews.concat(pastBestReviews)
                this.setState({bestReviews: bestReviews})
                imageReviews = reviews.filter(reviews => reviews.images)
                imageReviews = imageReviews.filter(imageReviews => !imageReviews.best)
                imageReviews = imageReviews.filter(imageReviews => !imageReviews.winaPick)
                imageReviews.sort((a, b) => {
                  return b.rating - a.rating
                })
                noImageReviews = reviews.filter(reviews => !reviews.images)
                noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.best)
                noImageReviews = noImageReviews.filter(noImageReviews => !noImageReviews.winaPick)
                noImageReviews.sort((a, b) => {
                  return b.rating - a.rating
                })
                reviews = bestReviews.concat(winaPickReviews)
                reviews = reviews.concat(imageReviews)
                reviews = reviews.concat(noImageReviews)

                this.setState({reviews: reviews})
              }

            })
          }
        })
      }
    })

    window.addEventListener('scroll', this.listenToScroll)

  }

  componentDidMount() {
    if (this.props.deliveryInnerTabIndex) {
      this.setState({itmeNavIndex: this.props.deliveryInnerTabIndex})
    }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
    if (this.props.getDeliveryTabViewPosition) {
      if (this.tabsView.current) {
        this.props.getDeliveryTabViewPosition(this.tabsView.current.offsetTop - 110)
      }

    }
  }

  async onCheckSpecialItem(selectedScones) {
    if (selectedScones && this.state.orderSheet) {
      let orderSheet = this.state.orderSheet
      let specialItem = false
      if (orderSheet['specialItems']) {
        await Object.keys(orderSheet['specialItems']).map(async(item, index) => {
          if (selectedScones[item]) {
            specialItem = true
          }
        })
      }
      this.setState({specialItem: specialItem})
    }
  }

  onGetCountDownTime(time) {
    let days = Math.floor(time / (1000 * 60 * 60 * 24));
    let hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((time % (1000 * 60)) / 1000);


    let result = ''

    if (!seconds) {
      result = '이벤트 종료'
    }

    if (seconds) {
      result = `${seconds}초 남음`
    }

    if (minutes) {
      result = `${minutes}분 ${seconds}초 남음`
    }

    if (hours) {
      result = `${hours}시간 ${minutes}분 ${seconds}초 남음`
    }

    if (days) {
      result = `${days}일 ${hours}시간 ${minutes}분 ${seconds}초 남음`
    }


    return result
  }

  onGetNumberOfPurchase(orders) {
    let ordersList = Object.values(orders)
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    return ordersList.length
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.itmeNavIndex !== this.props.itmeNavIndex) {
      this.setState({ itmeNavIndex: nextProps.itmeNavIndex });
    }
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        if (!this.state.firstLanded) {
          this.onGetCoupons(nextProps.userInfo)
          this.setState({firstLanded: true})
        }



        if (nextProps.userInfo && this.state.orderSheet) {
          if (this.state.orderSheet['firstPurchaseFree']) {
            if (nextProps.userInfo['sconeOrders']) {
              if (this.onGetNumberOfPurchase(nextProps.userInfo['sconeOrders']) === 0) {
                if (localStorage.getItem(`close_first_purchase_free_message_date`)) {
                  if (localStorage.getItem(`close_first_purchase_free_message_date`) !== moment(Date.now()).format('YYYYMMDD').toString()) {
                    this.setState({showFirstPurchaseFreeModal: true})
                  }
                } else {
                  this.setState({showFirstPurchaseFreeModal: true})
                }
              } else {
                this.setState({showFirstPurchaseFreeModal: false})
              }
            } else {
              if (localStorage.getItem(`close_first_purchase_free_message_date`)) {
                if (localStorage.getItem(`close_first_purchase_free_message_date`) !== moment(Date.now()).format('YYYYMMDD').toString()) {
                  this.setState({showFirstPurchaseFreeModal: true})
                }
              } else {
                this.setState({showFirstPurchaseFreeModal: true})
              }
            }
          } else {
            this.setState({showFirstPurchaseFreeModal: false})
          }
        }

        firebase.database().ref(`alarms/sconeDelivery/${nextProps.userInfo.uid}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val()['set']) {
              this.setState({setAlarm: true, visibleAlarm: false})
            } else {
              this.setState({setAlarm: false, visibleAlarm: true})
            }
          } else {
            this.setState({setAlarm: false, visibleAlarm: true})
            if (localStorage.getItem(`close_set_alram_message_date`)) {
              if (localStorage.getItem(`close_set_alram_message_date`) !== moment(Date.now()).format('YYYYMMDD').toString()) {
                this.setState({firstSetAlarm: true})
              }
            } else {
              this.setState({firstSetAlarm: true})
            }
          }
        })
        this.setState({userInfo: nextProps.userInfo, isLoading: false})
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      } else {
        this.setState({userInfo: null, isLoading: false})
        this.setState({setAlarm: false, visibleAlarm: true})
        if (localStorage.getItem(`close_set_alram_message_date`)) {
          if (localStorage.getItem(`close_set_alram_message_date`) !== moment(Date.now()).format('YYYYMMDD').toString()) {
            this.setState({firstSetAlarm: true})
          }
        } else {
          this.setState({firstSetAlarm: true})
        }
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  listenToScroll = () => {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop

  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

  const scrolled = winScroll / height
  if (this.tabsView.current) {
    if (winScroll - this.tabsView.current.offsetTop + 110 > 0) {
      if (!this.state.showNotice) {
        this.setState({
          showNotice: true,
        })
      }

    } else {
      if (this.state.showNotice) {
        this.setState({
          showNotice: false,
        })
      }
    }
  }
}

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {

      return sconeMenu[a.id].order - sconeMenu[b.id].order
    })
    return returnValue
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}.${months}.${days}`
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  onIssueOnlineCoupon(couponId, couponTo, category, expireDate, name, type) {
    let couponInfo = {}
    if (category === 'scone') {
      couponInfo = {
        couponId: couponId,
        issuedAt: Date.now(),
        category: category,
        expireDate: Number(expireDate),
        name: name,
        type: type,
        phoneNumber: couponTo.toString(),
        amount: 3000
      }
    }

    firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
  }

  onGetOfflineCoupons(userInfo) {
    firebase.database().ref(`coupons`).once('value', (snapshot) => {
      if (snapshot.val()) {

        let offlineCoupons = Object.values(snapshot.val())
        offlineCoupons = offlineCoupons.filter(offlineCoupon => offlineCoupon.phoneNumber === userInfo.phoneNumber)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => !offlineCoupon.usedAt)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => moment(offlineCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString() >=  moment(Date.now()).format('YYYYMMDD').toString())
        this.setState({offlineCoupons: offlineCoupons})
      }
    })
  }

  onGetCoupons(userInfo) {
    firebase.database().ref(`onlineCoupons`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let onlineCoupons = Object.values(snapshot.val())
        onlineCoupons = onlineCoupons.filter(onlineCoupon => onlineCoupon.phoneNumber === userInfo.phoneNumber)
        onlineCoupons = onlineCoupons.filter(onlineCoupon => !onlineCoupon.usedAt)
        onlineCoupons = onlineCoupons.filter(onlineCoupons => onlineCoupons.expireDate.toString() >=  moment(Date.now()).format('YYYYMMDD').toString())

        if (onlineCoupons.length && userInfo) {
          onlineCoupons.map((coupon, index) => {
            firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).once('value', (snapshot) => {
              if (!snapshot.val()) {
                firebase.database().ref(`users/${userInfo.uid}/coupons/${coupon.couponId}`).set(coupon)
                .then(() => {
                  if (this.props.onRefreshUserInfo) {
                    this.props.onRefreshUserInfo()
                  }
                })

              } else {
                if (this.props.onRefreshUserInfo) {
                  this.props.onRefreshUserInfo()
                }
              }
            })
          })
        } else {
          if (this.props.onRefreshUserInfo) {
            this.props.onRefreshUserInfo()
          }
        }

      } else {
        if (this.props.onRefreshUserInfo) {
          this.props.onRefreshUserInfo()
        }
      }
    })
  }

  onGetImageReviews(reviews) {
    let reviewsArr = Object.values(reviews)
    let imageReviews = reviewsArr.filter(reviewsArr => reviewsArr.images)
    imageReviews = imageReviews.filter(imageReviews => imageReviews.rating === 5)
    return imageReviews
  }

  onGetAverageRating(reviews) {
    let reviewsArr = Object.values(reviews)
    let average = reviewsArr.reduce((a, b) => a + b.rating, 0) / reviewsArr.length
    return Math.round(average * 10) / 10
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
        <Grid container alignItems="center" justify="center" direction="column">
        <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        <span className="text-center" style={{fontSize: 11, marginTop: 5}}>{this.state.waitMessage}</span>
        </Grid>
        </div>
      )
    }

    let settings = {
      className: "",
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false
    };


    if (false) {
      return (
        <div className="center-div direction-column-div">
          <Image style={{width: 250}} src={buildingWeb} />
          <span className="text-center" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}}>서비스를 준비 중입니다.</span>
        </div>
      )

    }

    const params = {
        slidesPerView: 'auto',
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }

    return (
      <Grid className="main-view disable-double-tabs" container justify="center">
        <Grid ref={this.introRef} container justify="center">
          <div>
            <Carousel swipeable={true}
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                showStatus={false}
                interval={5000}
                emulateTouch
                stopOnHover
                renderArrowNext={() =>  <div></div>}
                renderArrowPrev={() => <div></div>} >
                <img src={mainImage1} />
                <img src={mainImage2} />
                <img src={mainImage3} />
            </Carousel>
          </div>
          <div className="main-card">
            <p style={{fontSize: 14, fontWeight: 'bold'}}>[ 주문서 오픈 일정 ]</p>
            <p style={{fontSize: 12}}>발송일: 매주 월요일(주 1회)</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>1차 - 금요일 10:00시 ~ 금요일 24:00시 [월요일 발송]</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>2차 - 토요일 10:00시 ~ 토요일 24:00시 [월요일 발송]</p>
            <p style={{fontSize: 10, padding: 0, margin: 0, marginTop: 5, color: '#006042'}}>택배 발송일이 공휴일인 경우 발송일이 변경될 수 있습니다.</p>
          </div>
          <div className="auto-width-90" style={{position: 'relative', top: -40, paddingBottom: 10}}>
          <p style={{fontSize: 16, fontWeight: 'bold'}}>배송 정보</p>

          <div className="direction-row-div">
            <p style={{fontSize: 12, marginRight: 25, color: 'gray', marginBottom: 0, marginTop: 0}}>배송비</p>
            <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 0}}>4,500원 (50,000원 이상 구매시 배송비 무료)</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 12, marginRight: 11, color: 'gray', marginBottom: 0, marginTop: 7}}>최소구매</p>
            <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>최소 구매수량 5개</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 12, marginRight: 11, color: 'gray', marginBottom: 0, marginTop: 7}}>배송지역</p>
            <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>제주/도서산간 발송 불가</p>
          </div>
          <div className="direction-row-div">
            <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>배송안내</p>
            <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>- OPP 봉투에 개별 포장 후 에어캡과 함께 박스에 포장하여 발송<br/>- 아이스팩과 함께 동봉되어 배송<br/>- [상품준비중] 이후 상태에서는 주문 취소 불가
            <br/>- 주문일 기준 2일 후 오전 10시까지만 배송지 변경 가능</p>
          </div>
          { isMobileOnly
            ?<p className="auto-width-80" style={{fontSize: 10, marginBottom: 0, marginTop: 15, color: '#006042'}}>모든 스콘은 당일 생산 당일 배송을 원칙으로 하고 있습니다.</p>
            :<p className="auto-width-80" style={{fontSize: 12, marginBottom: 0, marginTop: 15, color: '#006042'}}>모든 스콘은 당일 생산 당일 배송을 원칙으로 하고 있습니다.</p>
          }

          </div>
        </Grid>
        <div className="delivery-main-view" ref={this.tabsView}>
        <div className={isMobile ? "sticky-mobile-view": "sticky-view"}>
          <Grid container  direction="row">
            <div onClick={() => {
              let tabsPrevScrollPosition = this.state.tabsPrevScrollPosition
              if (this.props.deliveryInnerTabChanges) {
                this.props.deliveryInnerTabChanges(0)
              }
              tabsPrevScrollPosition[this.state.itmeNavIndex] = window.pageYOffset
              let prevItemNavIndex = this.state.itmeNavIndex
              this.setState({itmeNavIndex: 0, tabsPrevScrollPosition: tabsPrevScrollPosition}, () => {
                if (prevItemNavIndex !== 0) {
                  if (isEdge || isIE) {
                      window.scrollTo(0, tabsPrevScrollPosition[0])
                    } else {
                      window.scrollTo({
                        top: tabsPrevScrollPosition[0],
                        left: 0
                      })
                    }
                } else {
                  if (isEdge || isIE) {
                      window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                    } else {
                      window.scrollTo({
                        top: this.tabsView.current.offsetTop - 110,
                        left: 0
                      })
                    }
                }
                setTimeout(() => {
                  if (prevItemNavIndex !== 0) {
                    if (isEdge || isIE) {
                        window.scrollTo(0, tabsPrevScrollPosition[0])
                      } else {
                        window.scrollTo({
                          top: tabsPrevScrollPosition[0],
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  } else {
                    if (isEdge || isIE) {
                        window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                      } else {
                        window.scrollTo({
                          top: this.tabsView.current.offsetTop - 110,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }
                }, 100)

              })

            }} className={this.state.itmeNavIndex === 0 ? "item-nav-selected-view" : "item-nav-view"}>
              <p class={this.state.itmeNavIndex === 0 ? "item-nav-selected-text" : "item-nav-text"}>
                상품정보
              </p>
            </div>
            <div onClick={() => {
              let tabsPrevScrollPosition = this.state.tabsPrevScrollPosition

              if (this.props.deliveryInnerTabChanges) {
                this.props.deliveryInnerTabChanges(1)
              }
              tabsPrevScrollPosition[this.state.itmeNavIndex] = window.pageYOffset
              let prevItemNavIndex = this.state.itmeNavIndex
              this.setState({itmeNavIndex: 1, tabsPrevScrollPosition: tabsPrevScrollPosition, loaded: false}, () => {

                if (isEdge || isIE) {
                    window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                  } else {
                    window.scrollTo({
                      top: this.tabsView.current.offsetTop - 110,
                      left: 0
                    })
                  }
                  setTimeout(() => {
                    if (isEdge || isIE) {
                        window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                      } else {
                        window.scrollTo({
                          top: this.tabsView.current.offsetTop - 110,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }, 100)

              })
            }} className={this.state.itmeNavIndex === 1 ? "item-nav-selected-view" : "item-nav-view"}>
              <p class={this.state.itmeNavIndex === 1 ? "item-nav-selected-text" : "item-nav-text"}>
                상세정보
              </p>
            </div>
            <div onClick={() => {
              let tabsPrevScrollPosition = this.state.tabsPrevScrollPosition
              if (this.props.deliveryInnerTabChanges) {
                this.props.deliveryInnerTabChanges(2)
              }
              tabsPrevScrollPosition[this.state.itmeNavIndex] = window.pageYOffset
              let prevItemNavIndex = this.state.itmeNavIndex
              this.setState({itmeNavIndex: 2, tabsPrevScrollPosition: tabsPrevScrollPosition}, () => {
                if (prevItemNavIndex !== 2) {
                  if (tabsPrevScrollPosition[2]) {
                    if (isEdge || isIE) {
                        window.scrollTo(0, tabsPrevScrollPosition[2])
                      } else {
                        window.scrollTo({
                          top: tabsPrevScrollPosition[2],
                          left: 0
                        })
                      }
                  } else {
                    if (isEdge || isIE) {
                        window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                      } else {
                        window.scrollTo({
                          top: this.tabsView.current.offsetTop - 110,
                          left: 0
                        })
                      }
                  }

                } else {
                  if (isEdge || isIE) {
                      window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                    } else {
                      window.scrollTo({
                        top: this.tabsView.current.offsetTop - 110,
                        left: 0
                      })
                    }
                }

                setTimeout(() => {
                  if (prevItemNavIndex !== 2) {
                    if (tabsPrevScrollPosition[2]) {
                      if (isEdge || isIE) {
                          window.scrollTo(0, tabsPrevScrollPosition[2])
                        } else {
                          window.scrollTo({
                            top: tabsPrevScrollPosition[2],
                            left: 0,
                            behavior: 'smooth'
                          })
                        }
                    } else {
                      if (isEdge || isIE) {
                          window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                        } else {
                          window.scrollTo({
                            top: this.tabsView.current.offsetTop - 110,
                            left: 0,
                            behavior: 'smooth'
                          })
                        }
                    }

                  } else {
                    if (isEdge || isIE) {
                        window.scrollTo(0, this.tabsView.current.offsetTop - 110)
                      } else {
                        window.scrollTo({
                          top: this.tabsView.current.offsetTop - 110,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                  }
                }, 100)
              })

            }} className={this.state.itmeNavIndex === 2 ? "item-nav-selected-view" : "item-nav-view"}>
              <p class={this.state.itmeNavIndex === 2 ? "item-nav-selected-text" : "item-nav-text"}>
                상품리뷰
              </p>
            </div>
          </Grid>
          </div>
          { this.state.itmeNavIndex === 0 &&
            <div  style={{padding: 0}}>

              <div>
                {this.state.loaded ? false :
                  <div
                    style={{
                      background: 'white',
                      height: '1550px',
                      width: '100px',
                    }}
                  />
                }
                <Image
                  ref={this.imageRef1}
                  className="auto-width-100"
                  style={this.state.loaded ? {} : {display: 'none'}}
                  src={SconeProductInfo5}
                  onLoad={() => this.setState({loaded: true})}
                />
              </div>
              <div>

              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }


              
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'giftSet') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid  item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousdPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousdPrice)}원</p>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }

                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid  item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousdPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousdPrice)}원</p>
                              <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }

                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu

                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }
                { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'giftBox') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {

                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let giftBoxAmount = this.state.giftBoxAmount

                                if (giftBoxAmount !== 0) {
                                  giftBoxAmount = giftBoxAmount - 1
                                  if (item.price !== 0) {
                                    totalPrice = totalPrice - item.price
                                    this.setState({totalPrice: totalPrice})
                                  }
                                }

                                if (this.props.onSetGiftBox) {
                                  this.props.onSetGiftBox(giftBoxAmount, item.price)
                                }

                                if (this.props.onSelectScone) {

                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }

                                this.setState({giftBoxAmount: giftBoxAmount})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.giftBoxAmount}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let giftBoxAmount = this.state.giftBoxAmount

                                if (this.state.orderSheet.menu[item.id].limit) {
                                  if (this.state.orderSheet.menu[item.id].limit > giftBoxAmount) {
                                    if (this.state.orderSheet.menu[item.id].amount > giftBoxAmount) {
                                      giftBoxAmount = giftBoxAmount + 1
                                      if (item.price !== 0) {
                                        totalPrice = totalPrice + item.price
                                        this.setState({totalPrice: totalPrice})
                                      }
                                    } else {
                                      this.setState({noMoreGiftBoxSnack: true})
                                    }
                                  }

                                } else {
                                  if (this.state.orderSheet.menu[item.id].amount > giftBoxAmount) {
                                    giftBoxAmount = giftBoxAmount + 1
                                    if (item.price !== 0) {
                                      totalPrice = totalPrice + item.price
                                      this.setState({totalPrice: totalPrice})
                                    }
                                  }
                                }


                                if (this.props.onSetGiftBox) {
                                  this.props.onSetGiftBox(giftBoxAmount, item.price)
                                }
                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }

                                this.setState({giftBoxAmount: giftBoxAmount})
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }

              

              <Image
                className="auto-width-100"
                style={this.state.loaded ? {} : {display: 'none'}}
                src={financierDivider}
                onLoad={() => this.setState({loaded: true})}
              />
              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'financier') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu


                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }


              <Image
                className="auto-width-100"
                style={this.state.loaded ? {} : {display: 'none'}}
                src={bunDivider}
                onLoad={() => this.setState({loaded: true})}
              />
              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'bun') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu


                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }
              <Image
                className="auto-width-100"
                style={this.state.loaded ? {} : {display: 'none'}}
                src={rollDivider}
                onLoad={() => this.setState({loaded: true})}
              />
              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'roll') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousPrice)}원</p>
                              <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu


                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }
              <Image
                className="auto-width-100"
                style={this.state.loaded ? {} : {display: 'none'}}
                src={cookieDivider}
                onLoad={() => this.setState({loaded: true})}
              />

              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'cookie') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu

                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }
              <Image
                className="auto-width-100"
                style={this.state.loaded ? {} : {display: 'none'}}
                src={sconeDivider}
                onLoad={() => this.setState({loaded: true})}
              />
              { Object.values(this.state.sconeMenu).length === 0 &&
                <div className="vertical-center-align direction-column-div auto-width-100">
                  <HashLoader  color={'#006042'} loading={true} size={20} />
                </div>
              }
              { this.sortByOrder(Object.values(this.state.sconeMenu)).map((item, index) => {
                if (this.state.orderSheet) {
                  if (this.state.orderSheet.menu[item.id] && item['category'] === 'scone') {
                    return (
                      <Grid   container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                        <Grid item className="direction-row-div" justify="center" xs={4}>
                          <Image onClick={() => {
                            this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                          }} style={{width: 100, height: 100, cursor: 'pointer'}} loading="eager" src={item.image} />
                        </Grid>
                        { isMobileOnly
                          ?<Grid  item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 10, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousdPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousdPrice)}원</p>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }

                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 11, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                          :<Grid  item xs={7}>
                            <div className="direction-row-div no-selection">
                            <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                            { item.popular &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: 'red'}}>[인기상품]</p>
                            }
                            { item.new &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#4583ff'}}>[신메뉴]</p>
                            }
                            { item.bestSeller &&
                              <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 5, color: '#006042'}}>[베스트셀러]</p>
                            }
                            { this.state.eventScone &&
                              <div>
                              { this.state.eventScone.id === item.id &&
                                <p style={{fontSize: 13, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                              }
                              </div>
                            }

                            </div>
                            <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                            { item.itemPreviousdPrice
                              ?<div className="direction-row-div no-selection">
                              <p className="strike" style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.itemPreviousdPrice)}원</p>
                              <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                              </div>
                              :<p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                            }

                            { item.reviews &&
                              <div>
                                { this.onGetImageReviews(item.reviews).length !== 0 &&
                                  <span onClick={() => {
                                    this.setState({isOpenPhotoReviewSheet: true, selectedPhotoReviews: this.onGetImageReviews(item.reviews)})
                                  }} style={{fontSize: 13, marginTop: 5, cursor: 'pointer'}}>포토 리뷰</span>
                                }
                              </div>
                            }

                          </Grid>
                        }

                        <Grid
                          item
                          className="direction-row-div no-selection"
                          style={{position: 'absolute', right: 20, bottom: 0}}
                        >
                        { this.state.eventScone
                          ?<div>
                          { this.state.selectedScones[item.id]
                            ?this.state.eventScone.id === item.id
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                          :<div>
                          { this.state.selectedScones[item.id]
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                          }
                          </div>
                        }

                        { this.state.orderSheet.menu[item.id].limit &&
                          <span className="max-scone-number" style={{fontSize: 10}}>최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                        }
                        { this.state.orderSheet.menu[item.id].amount === 0 || this.state.orderSheet.open === false
                          ?<div className="counter-button-div">
                            <div onClick={() => {
                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            </div>
                            <div onClick={() => {
                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                          :<div className="counter-button-div">
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let sconeMenu = this.state.sconeMenu
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice

                                if (selectedScones[item.id]) {
                                  if (totalAmount && selectedScones[item.id].amount !== 0) {
                                    totalAmount = totalAmount - 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice - this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice - item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice - item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice - item.price
                                    }

                                  }
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount - 1,
                                    price: item.price
                                  }

                                  if (selectedScones[item.id].amount === 0) {
                                    delete selectedScones[item.id]
                                  }
                                }



                                if (this.props.onSelectScone) {
                                  this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                }
                                this.onCheckSpecialItem(selectedScones)
                                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                              }

                            }} className="counter-button">
                              <RemoveIcon fontSize={"small"}/>
                            </div>
                            <div className="counter-style">
                              <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                            </div>
                            <div onClick={() => {
                              if (this.state.orderSheet.open) {
                                let selectedScones = this.state.selectedScones
                                let totalAmount = this.state.totalAmount
                                let totalPrice = this.state.totalPrice
                                let sconeMenu = this.state.sconeMenu

                                if (selectedScones[item.id]) {
                                  if (this.state.orderSheet.menu[item.id].limit) {
                                    if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                      if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                        selectedScones[item.id] = {
                                          itemId: item.id,
                                          name: sconeMenu[item.id].name,
                                          image: sconeMenu[item.id].image,
                                          amount: selectedScones[item.id].amount + 1,
                                          price: item.price
                                        }
                                        if (totalAmount) {
                                          totalAmount = totalAmount + 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = totalPrice + this.state.eventScone.price
                                              } else {
                                                totalPrice = totalPrice + item.price
                                              }
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalAmount = 1
                                          if (this.state.eventScone) {
                                            if (this.state.eventScone.id === item.id) {
                                              if (selectedScones[item.id].amount === 1) {
                                                totalPrice = this.state.eventScone.price
                                              } else {
                                                totalPrice = item.price
                                              }
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                          if (this.props.giftBoxAmount) {
                                            totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                          }
                                        }
                                        if (this.props.onSelectScone) {
                                          this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                        }
                                        this.onCheckSpecialItem(selectedScones)
                                        this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                      } else {
                                        this.setState({noMoreSconesSnack: false}, () => {
                                          setTimeout(() => {
                                            this.setState({noMoreSconesSnack: true})
                                          }, 10)
                                        })
                                      }
                                    }
                                  } else {
                                    if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                      selectedScones[item.id] = {
                                        itemId: item.id,
                                        name: sconeMenu[item.id].name,
                                        image: sconeMenu[item.id].image,
                                        amount: selectedScones[item.id].amount + 1,
                                        price: item.price
                                      }
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = totalPrice + this.state.eventScone.price
                                            } else {
                                              totalPrice = totalPrice + item.price
                                            }
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalAmount = 1
                                        if (this.state.eventScone) {
                                          if (this.state.eventScone.id === item.id) {
                                            if (selectedScones[item.id].amount === 1) {
                                              totalPrice = this.state.eventScone.price
                                            } else {
                                              totalPrice = item.price
                                            }
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                        if (this.props.giftBoxAmount) {
                                          totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                        }
                                      }
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                      }
                                      this.onCheckSpecialItem(selectedScones)
                                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                    } else {
                                      this.setState({noMoreSconesSnack: false}, () => {
                                        setTimeout(() => {
                                          this.setState({noMoreSconesSnack: true})
                                        }, 10)
                                      })
                                    }
                                  }

                                } else {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                    if (this.props.giftBoxAmount) {
                                      totalPrice = totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice)
                                    }
                                  }

                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }
                                  this.onCheckSpecialItem(selectedScones)
                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                }
                              }

                            }} className="counter-button">
                            <AddIcon fontSize={"small"}/>
                            </div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    )
                  }
                }

              })

              }

              </div>
              { this.state.reviews.length !== 0 &&
                <div>
                <Divider className="auto-width-100" style={{marginBottom: 20}} />
                <span style={{fontWeight: 'bold', marginLeft: 25}}>베스트 리뷰</span>
                <Divider className="auto-width-100"  style={{marginTop: 20}}/>
                </div>
              }
              { this.state.bestReviews.length !== 0 &&
                <Grid style={{marginBottom: 20, marginTop: 20}}>
                  <div>
                  { this.state.bestReviews.map((review, index) => {
                    if (this.state.users[review.uid]) {
                      if (review.createdAt >= Date.now() - (1000 * 60 * 60 * 24 * 30 * 12)) {
                        return (
                          <Grid style={{marginBottom: 10}} container alignItems="center" justify="center" direction="column" className="auto-width-100 relative-position">
                            <div style={{cursor: 'pointer'}} onClick={() => {
                              if (this.state.showIndex === index) {
                                this.setState({showIndex: null, imageIndex: 0})
                              } else {
                                this.setState({showIndex: index, imageIndex: 0})
                              }
                            }} className="auto-width-90">
                              <div className="direction-row-div align-center">
                              <ReactStars
                                numberOfStars={5}
                                rating={review.rating}
                                edit={false}
                                starRatedColor={'#006042'}
                                starHoverColor={'#006042'}
                                starEmptyColor={'#bababa'}
                                starDimension={'15px'}
                                starSpacing={'0.5px'} />
                                { this.state.users &&
                                  <span style={{fontSize: 13, marginLeft: 10, fontWeight: 'bold'}}>{this.state.users[review.uid].username.charAt(0) + '**'}</span>
                                }
                                <span style={{fontSize: 13, marginLeft: 10, color: 'gray'}}>{this.onGetTimeDetail(review.createdAt)}</span>


                                { review.best &&
                                  <span className="small-red-box" style={{fontSize: 11, marginLeft: 10, color: 'white', marginTop: 2}}>베스트 리뷰</span>
                                }

                                </div>
                                { review.itemId &&
                                  <span style={{fontSize: 13, color: 'gray', marginBottom: 3}}>[{this.state.sconeMenu[review.itemId].name}]</span>
                                }
                                <div className="direction-row-div">
                                { this.state.showIndex === index
                                  ?<div className="auto-width-90">
                                    <span className="no-line" style={{fontSize: 13}}>{review.review}</span>
                                  </div>
                                  :<div style={{height: 60}} className="auto-width-70">
                                    <span className="two-line" style={{fontSize: 13}}>{review.review}</span>
                                  </div>
                                }
                                { this.state.showIndex !== index && review.images &&
                                  <div className="auto-width-10" style={{position: 'absolute', top: 20, right: 20}}>
                                    <Image className="image-object-fit-50" style={{borderRadius: 5}} src={review.images[0]} />
                                  </div>
                                }

                              </div>
                            </div>
                            <div className={this.state.showIndex === index ? "direction-row-div align-center review-image-open" : "direction-row-div align-center review-image-close"}>
                            { this.state.showIndex === index &&
                              <div style={{width: 20}}>
                              { this.state.imageIndex !== 0 &&
                                <FontAwesomeIcon onClick={() =>{
                                  this.previous()
                                }} style={{color: 'black', fontSize: 20, marginRight: 10, cursor: 'pointer'}} icon={faChevronLeft} />
                              }
                              </div>
                            }

                            { this.state.showIndex === index && review.images &&
                              <div  style={{width: 280, marginTop: 30}}>
                                <Slider ref={c => (this.slider = c)}  beforeChange={(oldIndex, newIndex) => {
                                  this.setState({imageIndex: newIndex})
                                }} {...settings}>
                                  { review.images.map((image, index) => {
                                    return (
                                      <Image src={image} />
                                    )
                                  })

                                  }
                                </Slider>
                              </div>
                            }
                            { this.state.showIndex === index && review.images &&
                              <div style={{width: 20}}>
                                { this.state.imageIndex !== review.images.length - 1 &&
                                <FontAwesomeIcon onClick={() =>{
                                  this.next()
                                }} style={{color: 'black', fontSize: 20, marginLeft: 10, cursor: 'pointer'}} icon={faChevronRight} />
                                }
                              </div>

                            }

                            </div>
                            { this.state.showIndex === null
                              ?<Divider className="auto-width-100"  />
                              :this.state.showIndex === index
                              ?<Divider className="auto-width-100" style={{marginTop: 40}}  />
                              :<Divider className="auto-width-100"  />
                            }

                          </Grid>
                        )
                      }

                    }

                  })

                  }
                  </div>

                </Grid>
              }
            </div>
          }
          { this.state.itmeNavIndex === 1 &&
            <div style={{padding: 10}}>
            <p style={{fontSize: 16, fontWeight: 'bold'}}>상세 정보</p>
            <p style={{fontSize: 14, fontWeight: 'bold'}}>{`[제품 사양 및 포장]`}</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>- 무게: 80g~120g</p>
            <p style={{fontSize: 11, padding: 0, margin: 0}}>(스콘의 중량은 종류에 따라 다를 수 있습니다.)</p>
            <p style={{fontSize: 13, padding: 0, margin: 0, marginTop: 10}}>- OPP 봉투에 개별 포장 후 에어캡과 함께 박스에 포장하여 보내드립니다.</p>
            <p style={{fontSize: 11, padding: 0, margin: 0}}>(아이스팩과 함께 동봉되어 배송)</p>
            <p style={{fontSize: 14, fontWeight: 'bold'}}>{`[보관 방법 및 먹는 방법]`}</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>상온에서 1~2일 보관 가능<br/>냉동 보관의 경우 2~3주 보관 가능<br/>스콘 - 오븐 OR 에어프라이기 180도에서 3~4분<br/>쿠키 - 오븐 OR 에어프라이기 180도에서 2~3분</p>
            <p style={{fontSize: 11, padding: 0, margin: 0}}>(냉동했을 시에는 해동 후 데워주시고 전자레인지는 추천하지 않습니다.)</p>
            <p style={{fontSize: 14, fontWeight: 'bold'}}>{`[주요 원재료 정보]`}</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>버터(프랑스,뉴질랜드), 설탕(국산), 소금(국산), 밀가루(미국산),<br/>달걀(국산), 베이킹파우더(국산), 우유(국산), 생크림(국산), <br/>베이킹소다(국산) 초콜릿(독일,미국), 호두(미국산), 크림치즈(미국)<br/></p>
            <p style={{fontSize: 14, fontWeight: 'bold'}}>{`[주문 전 확인사항]`}</p>
            <div className="direction-row-div">
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 10}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>취소는 주문일 다음날 오전 10시까지만 가능합니다. 이후 취소는 거절됩니다.</p>
            </div>
            <div className="direction-row-div" style={{marginTop: 5}}>
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 10}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>제주/도서산간 지역은 배송이 불가하니 양해부탁드립니다.</p>
            </div>
            <p style={{fontSize: 14, fontWeight: 'bold'}}>{`[교환 및 환불 규정]`}</p>
            <div className="direction-row-div">
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 20}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>여름철 배송 시 온도 유지 차원에서 아이스팩을 보냉백에 함께 넣어 보내드립니다. 다만, 기온이 높아 아이스팩이 녹아서 갈 수 있지만 1~2일 정도는 상온 보관 가능한 상품으로 걱정하지 않으셔도 됩니다.</p>
            </div>
            <div className="direction-row-div" style={{marginTop: 5}}>
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 20}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>상품의 파손을 최소화하고자 개별 포장 및 전체 박스 포장을 신경 써서 하고 있습니다. 그럼에도 제품 특성상 배송 중에 약간의 파손이 있을 수 있습니다. 이 경우 교환, 환불이 불가하니 너그러운 양해 부탁드립니다.</p>
            </div>
            <div className="direction-row-div" style={{marginTop: 5}}>
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 15}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>에그 스콘의 경우 굽는 과정 중에 생크림의 지방이 커스터드 위로 떠서 고이는 현상이 발생할 수 있습니다. 제품에는 이상이 없는 것이니 안심하시고 드셔도 됩니다.</p>
            </div>
            <div className="direction-row-div" style={{marginTop: 5}}>
            <p style={{fontSize: 13, padding: 0, margin: 0, width: 10}}>-</p>
            <p style={{fontSize: 13, padding: 0, margin: 0}}>그 외에 제품 이상이 발견되시거나 궁금하신 점이 있느시다면 웹사이트 하단 [My위나] 탭에서 카톡 1:1문의로 남겨주시면 감사하겠습니다.</p>
            </div>
            </div>
          }
          { this.state.itmeNavIndex === 2 &&
            <div style={{padding: 10, paddingTop: 20}}>
              <div className="direction-column-div" style={{margin: 10, marginLeft: 20}}>
              <Grid item className="auto-width-100 direction-row-div" justify="center">
                { this.state.reviews.length !== 0 &&
                  <span className="align-center" style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5}}>평균 평점 {this.onGetAverageRating(this.state.reviews)}</span>
                }

              </Grid>
              <span style={{fontSize: 16, fontWeight: 'bold', marginTop: 10, marginBottom: 5}}>상품 리뷰</span>
              <span style={{fontSize: 10}}>베스트 리뷰: 자세한 제품 설명과 생생하고 공감가는 리뷰</span>
              <span style={{fontSize: 10}}>위나픽 리뷰: 짧지만 진심이 느껴지는 리뷰</span>
              </div>

              { this.state.reviews.length !== 0 &&
                <Grid style={{marginBottom: 20, marginTop: 20}}>
                  <div>
                  { this.state.reviews.map((review, index) => {
                    if (this.state.numberOfShowingReviews > index + 1) {
                      if (this.state.users[review.uid]) {
                        return (
                          <Grid style={{marginBottom: 10}} container alignItems="center" justify="center" direction="column" className="auto-width-100 relative-position">
                            <div style={{cursor: 'pointer'}} onClick={() => {
                              if (this.state.showIndex === index) {
                                this.setState({showIndex: null, imageIndex: 0})
                              } else {
                                this.setState({showIndex: index, imageIndex: 0})
                              }
                            }} className="auto-width-90">
                              <div className="direction-row-div align-center">
                              <ReactStars
                                numberOfStars={5}
                                rating={review.rating}
                                edit={false}
                                starRatedColor={'#006042'}
                                starHoverColor={'#006042'}
                                starEmptyColor={'#bababa'}
                                starDimension={'15px'}
                                starSpacing={'0.5px'} />
                                { this.state.users &&
                                  <span style={{fontSize: 13, marginLeft: 10, fontWeight: 'bold'}}>{this.state.users[review.uid].username.charAt(0) + '**'}</span>
                                }
                                <span style={{fontSize: 13, marginLeft: 10, color: 'gray'}}>{this.onGetTimeDetail(review.createdAt)}</span>
                                </div>
                                { review.itemId &&
                                  <span style={{fontSize: 13, color: 'gray', marginBottom: 3}}>[{this.state.sconeMenu[review.itemId].name}]</span>
                                }
                                <div className="direction-row-div">
                                { this.state.showIndex === index
                                  ?<div className="auto-width-90">
                                    <span className="no-line" style={{fontSize: 13}}>{review.review}</span>
                                  </div>
                                  :<div style={{height: 60}} className="auto-width-70">
                                    <span className="two-line" style={{fontSize: 13}}>{review.review}</span>
                                  </div>
                                }
                                { this.state.showIndex !== index && review.images &&
                                  <div className="auto-width-10" style={{position: 'absolute', top: 20, right: 20}}>
                                    <Image className="image-object-fit-50" style={{borderRadius: 5}} src={review.images[0]} />
                                  </div>
                                }

                              </div>
                            </div>
                            <div className={this.state.showIndex === index ? "direction-row-div align-center review-image-open" : "direction-row-div align-center review-image-close"}>
                            { this.state.showIndex === index &&
                              <div style={{width: 20}}>
                              { this.state.imageIndex !== 0 &&
                                <FontAwesomeIcon onClick={() =>{
                                  this.previous()
                                }} style={{color: 'black', fontSize: 20, marginRight: 10, cursor: 'pointer'}} icon={faChevronLeft} />
                              }
                              </div>
                            }

                            { this.state.showIndex === index && review.images &&
                              <div  style={{width: 280, marginTop: 30}}>
                                <Slider ref={c => (this.slider = c)}  beforeChange={(oldIndex, newIndex) => {
                                  this.setState({imageIndex: newIndex})
                                }} {...settings}>
                                  { review.images.map((image, index) => {
                                    return (
                                      <Image src={image} />
                                    )
                                  })

                                  }
                                </Slider>
                              </div>
                            }
                            { this.state.showIndex === index && review.images &&
                              <div style={{width: 20}}>
                                { this.state.imageIndex !== review.images.length - 1 &&
                                <FontAwesomeIcon onClick={() =>{
                                  this.next()
                                }} style={{color: 'black', fontSize: 20, marginLeft: 10, cursor: 'pointer'}} icon={faChevronRight} />
                                }
                              </div>

                            }

                            </div>
                            { this.state.showIndex === null
                              ?<Divider className="auto-width-100"  />
                              :this.state.showIndex === index
                              ?<Divider className="auto-width-100" style={{marginTop: 40}}  />
                              :<Divider className="auto-width-100"  />
                            }

                          </Grid>
                        )
                      }

                    }

                  })

                  }
                  { this.state.numberOfShowingReviews < this.state.reviews.length &&
                    <Grid onClick={() => {
                      this.setState({numberOfShowingReviews: this.state.numberOfShowingReviews + 30})
                    }} container alignItems="center" justify="center" direction="column" className="auto-width-100" style={{cursor: 'pointer', padding: 5}}>
                      <span style={{fontSize: 14, color: '#006042'}}>리뷰 {this.state.reviews.length - this.state.numberOfShowingReviews >= 30 ? '30개 더보기' : `${this.state.reviews.length - this.state.numberOfShowingReviews}개 더보기`}</span>

                    </Grid>
                  }
                  { this.state.numberOfShowingReviews < this.state.reviews.length &&
                    <Divider className="auto-width-100" style={{marginTop: 10}} />
                  }

                  </div>

                </Grid>
              }
            </div>
          }
        </div>
        { false &&
          <Grid item xs={12} sm={6}>
            <div>
            <Grid container justify="center">
            <ButtonBase
              href={"https://m.smartstore.naver.com/cafe_with_a_nod/products/5283997220"}
              style={{ color: "black", textDecoration: 'none' }}
            >
            <div className="big-button">
              <Image src={mainImage1} width={175} height={230} fluid style={{borderRadius: 10}} />
            <p style={{fontWeight: 'bold', fontSize: 14, margin: 0, marginTop: 5}}>스마트스토어 바로가기</p>
            </div>
            </ButtonBase>
            </Grid>
            </div>
          </Grid>
        }

        { !this.state.setAlarm ?
          this.state.visibleAlarm
          ?<div onClick={() => {
            if (this.state.userInfo) {
              firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  let data = snapshot.val()
                  data['onDate'] = Date.now()
                  data['set'] = true
                  firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
                  firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
                  this.setState({setAlarmSnack: true, setAlarm: true})
                } else {
                  if (this.state.userInfo.phoneNumber) {
                    let data = {
                      onDate: Date.now(),
                      set: true,
                      uid: this.state.userInfo.uid,
                      phoneNumber: this.state.userInfo.phoneNumber,
                    }
                    firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
                    firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
                    let couponId = shortid.generate()
                    this.onIssueOnlineCoupon(couponId, this.state.userInfo.phoneNumber, 'scone', moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString(), '스콘 택배 할인', 'discount')
                    this.setState({firstSetAlarmSnack: true, setAlarm: true})
                  }

                }
              })
            } else {
              this.props.history.push('/login?redirect=/');
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }
          }} className={"btn-bottom-alarm no-selection none-underline-text"}>
            <FontAwesomeIcon style={{color: '#3d3d3d', fontSize: 28}} icon={faBell} />
          </div>
          :<div></div>
          :this.state.visibleAlarm
          ?<div onClick={() => {
            if (this.state.userInfo) {
              firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  let data = snapshot.val()
                  data['offDate'] = Date.now()
                  data['set'] = false
                  firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
                  firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)

                  this.setState({setAlarm: false})
                } else {
                  if (this.state.userInfo.phoneNumber) {
                    let data = {
                      offDate: Date.now(),
                      set: false,
                      uid: this.state.userInfo.uid,
                      phoneNumber: this.state.userInfo.phoneNumber,
                    }
                    firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
                    firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
                    this.setState({setAlarm: false})
                  }

                }
              })
            } else {
              this.props.history.push('/login?redirect=/');
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }

          }} className={"btn-bottom-alarm-disappear no-selection none-underline-text"}>
            <FontAwesomeIcon style={{color: '#006042', fontSize: 28}} icon={faBell} />
          </div>
          :<div></div>
        }
        { !this.state.setAlarm && this.state.firstSetAlarm &&
          <div onClick={() => {
            localStorage.setItem(`close_set_alram_message_date`, moment(Date.now()).format('YYYYMMDD').toString())
            this.setState({firstSetAlarm: false})
          }} className="first-set-alarm">
            <div className="direction-column-div relative-position">
            <span style={{color: 'white', fontSize: 10, padding: 15, paddingBottom: 0, paddingRight: 30}}>택배 오픈 알림 설정시</span>
            <span style={{color: 'white', fontSize: 10, padding: 15, paddingTop: 0, paddingBottom: 0, paddingRight: 30}}>스콘 택배 3,000원</span>
            <span style={{color: 'white', fontSize: 10, padding: 15, paddingTop: 0, paddingRight: 30}}>할인 쿠폰 지급!</span>
            <CloseIcon  style={{color: 'white', fontSize: 15, position: 'absolute', right: 7, top: 7}} />
            </div>
          </div>
        }
        { this.state.itmeNavIndex === 0 && (this.state.totalAmount > 4 || this.state.specialItem) &&
          <Link to={'/delivery_cart'} className={"btn-bottom-cart-able no-selection none-underline-text"}>
            <div className="round-icon">
              { (this.state.totalAmount > 4 || this.state.specialItem)
                ?<DoneIcon style={{color: '#006042', opacity: 0.95}}/>
                :<ShoppingCartIcon style={{color: '#3d3d3d', opacity: 0.95}}/>
              }
            </div>
            <div  style={{padding: 10, paddingTop: 16, paddingRight: 20}}>
            { this.state.totalAmount && this.state.orderSheet
              ?this.state.orderSheet.discount
              ?<div>
              { (this.state.totalAmount > 4 || this.state.specialItem)
                ?<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice * (1 - this.state.orderSheet.discount.rate))}원<br/><p style={{color: 'white', fontSize: 11, padding: 0, margin: 0, fontWeight: 'bold'}}>구매하기!</p></p>
                :<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice * (1 - this.state.orderSheet.discount.rate))}원<br/>( 최소수량 5개 )</p>
              }
            </div>
              :<div>
                { (this.state.totalAmount > 4 || this.state.specialItem)
                  ?<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원<br/><p style={{color: 'white', fontSize: 11, padding: 0, margin: 0, fontWeight: 'bold'}}>구매하기!</p></p>
                  :<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원<br/>( 최소수량 5개 )</p>
                }
              </div>
              :<p style={{color: 'white', fontSize: 11, padding: 0, margin: 0}}>맛있는 스콘을<br/>한가득 담아주세요!</p>
            }
            </div>
          </Link>
        }
        { this.state.orderSheet &&
          <div className={this.state.showNotice && this.state.itmeNavIndex === 0 && !this.state.orderSheet.open ? "none-underline-text btn-bottom-delivery-notice-fixed no-selection direction-column-div" : "none-underline-text btn-bottom-delivery-notice-fixed-invisible no-selection direction-column-div"}  >
          { this.state.orderSheet.notice &&
            <span style={{color: 'white', fontSize: 11, margin: 15}}>{this.state.orderSheet.notice}</span>
          }
          </div>
        }
        { this.state.orderSheet && this.state.timerCount && this.state.itmeNavIndex === 0 && this.state.orderSheet.open && this.props.showDiscountPopup &&
          <div className={this.state.showNotice && this.state.orderSheet.discount ? (this.state.timerCount > 1000 * 60 * 30) ?  "none-underline-text btn-bottom-delivery-discount-fixed no-selection direction-column-div" : "none-underline-text btn-bottom-delivery-discount-fixed-red no-selection direction-column-div" : (this.state.timerCount > 1000 * 60 * 30) ? "none-underline-text btn-bottom-delivery-discount-fixed-upper no-selection direction-column-div" : "none-underline-text btn-bottom-delivery-discount-fixed-upper-red no-selection direction-column-div"}  >
          { this.state.orderSheet.discount && this.onGetCountDownTime(this.state.timerCount) &&
            <div>
              { this.state.orderSheet.discount['until']
                ?<div className="direction-column-div align-center" style={{padding: 15}}>
                  <div className="direction-row-div align-center">
                    <span style={{color: 'white', fontSize: 13}}>{this.state.orderSheet.discount.title}</span>
                    <span style={{color: 'white', fontSize: 13, marginLeft: 5}}>디저트 전메뉴 {this.state.orderSheet.discount.rate * 100}% 할인</span>
                  </div>
                  { this.onGetCountDownTime(this.state.timerCount) &&
                    <span style={{color: 'white', fontSize: 13, marginTop: 5}}>{this.onGetCountDownTime(this.state.timerCount)}</span>
                  }

                </div>
                :<div className="direction-column-div align-center" style={{padding: 15}}>
                  <div className="direction-row-div align-center">
                    <span style={{color: 'white', fontSize: 13}}>{this.state.orderSheet.discount.title}</span>
                    <span style={{color: 'white', fontSize: 13, marginLeft: 5}}>디저트 전메뉴 {this.state.orderSheet.discount.rate * 100}% 할인</span>
                  </div>
                </div>
              }
            </div>
          }
          <CloseIcon className="top-right-close-button" onClick={() => {
            if (this.props.onCloseDiscountPopup) {
              this.props.onCloseDiscountPopup()
            }
          }} style={{fontSize: 20, color: 'white'}} />
          </div>
        }
        { this.state.itmeNavIndex === 0 && (this.state.totalAmount < 5 && !this.state.specialItem) &&
          <div onClick={() => {
            if (this.state.totalAmount) {
              if ((this.state.totalAmount > 4 || this.state.specialItem)) {

              } else {
                setTimeout(() => {
                  if (isEdge || isIE) {
                      // window.scrollTo(0, this.introRef.current.clientHeight + this.imageRef1.current.clientHeight + this.imageRef2.current.clientHeight - 65)
                      window.scrollTo(0, this.introRef.current.clientHeight + this.imageRef1.current.clientHeight - 65)
                    } else {
                      window.scrollTo({
                        top: this.introRef.current.clientHeight + this.imageRef1.current.clientHeight - 65,
                        left: 0,
                        behavior: 'smooth'
                      })
                      // window.scrollTo({
                      //   top: this.introRef.current.clientHeight + this.imageRef1.current.clientHeight + this.imageRef2.current.clientHeight - 65,
                      //   left: 0,
                      //   behavior: 'smooth'
                      // })
                    }
                }, 10)
              }
            } else {
              setTimeout(() => {
                if (isEdge || isIE) {
                    // window.scrollTo(0, this.introRef.current.clientHeight + this.imageRef1.current.clientHeight + this.imageRef2.current.clientHeight - 65)
                    window.scrollTo(0, this.introRef.current.clientHeight + this.imageRef1.current.clientHeight - 65)
                  } else {
                    window.scrollTo({
                      top: this.introRef.current.clientHeight + this.imageRef1.current.clientHeight - 65,
                      left: 0,
                      behavior: 'smooth'
                    })
                    // window.scrollTo({
                    //   top: this.introRef.current.clientHeight + this.imageRef1.current.clientHeight + this.imageRef2.current.clientHeight - 65,
                    //   left: 0,
                    //   behavior: 'smooth'
                    // })
                  }
              }, 10)
            }

          }} className={"btn-bottom-cart no-selection none-underline-text"}>
            <div className="round-icon">
              { (this.state.totalAmount > 4 || this.state.specialItem)
                ?<DoneIcon style={{color: '#006042', opacity: 0.95}}/>
                :<ShoppingCartIcon style={{color: '#3d3d3d', opacity: 0.95}}/>
              }
            </div>
            <div style={{padding: 10, paddingTop: 16, paddingRight: 20}}>
            { this.state.totalAmount && this.state.orderSheet
              ?this.state.orderSheet.discount
              ?<div>
              { (this.state.totalAmount > 4 || this.state.specialItem)
                ?<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice * (1 - this.state.orderSheet.discount.rate))}원<br/><p style={{color: 'white', fontSize: 11, padding: 0, margin: 0, fontWeight: 'bold'}}>구매하기!</p></p>
                :<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice * (1 - this.state.orderSheet.discount.rate))}원<br/>( 최소수량 5개 )</p>
              }
            </div>
              :<div>
                { (this.state.totalAmount > 4 || this.state.specialItem)
                  ?<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원<br/><p style={{color: 'white', fontSize: 11, padding: 0, margin: 0, fontWeight: 'bold'}}>구매하기!</p></p>
                  :<p style={{color: 'white', fontSize: 11,padding: 0, margin: 0}}>총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원<br/>( 최소수량 5개 )</p>
                }
              </div>
              :<p style={{color: 'white', fontSize: 11, padding: 0, margin: 0}}>맛있는 스콘을<br/>한가득 담아주세요!</p>
            }
            </div>
          </div>
        }
        <Snackbar open={this.state.noMoreSconesSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreSconesSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreSconesSnack: false})} severity="warning">
            해당 상품의 재고가 부족합니다!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.noMoreGiftBoxSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreGiftBoxSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreGiftBoxSnack: false})} severity="warning">
            디저트 박스의 재고가 부족합니다!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.firstSetAlarmSnack} autoHideDuration={4000} onClose={() => this.setState({firstSetAlarmSnack: false})}>
          <Alert  onClose={() => this.setState({firstSetAlarmSnack: false})} severity="success">
            My위나 탭에서 온라인 쿠폰을 확인하세요.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.setAlarmSnack} autoHideDuration={4000} onClose={() => this.setState({setAlarmSnack: false})}>
          <Alert  onClose={() => this.setState({setAlarmSnack: false})} severity="success">
            알림설정 되었습니다. My위나 탭에서 취소 가능합니다.
          </Alert>
        </Snackbar>
        <Footer />
        <Modal
          open={this.state.showSconemageModal}
          onClose={() => this.setState({showSconemageModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="scone-image-modal">
            <Image className="scone-image" src={this.state.selectedSconeImage} />
          </div>
        </Modal>
        <Modal
          open={this.state.showFirstPurchaseFreeModal}
          onClose={() => {
            this.setState({showFirstPurchaseFreeModal: false})
            localStorage.setItem(`close_first_purchase_free_message_date`, moment(Date.now()).format('YYYYMMDD').toString())
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="popup-image-modal relative-position">
            <Image className="popup-image"  src={firstPurchaseFreeImage} />
            <CloseIcon className="popup-close-button" onClick={() => {
              this.setState({showFirstPurchaseFreeModal: false})
              localStorage.setItem(`close_first_purchase_free_message_date`, moment(Date.now()).format('YYYYMMDD').toString())
            }} style={{fontSize: 27}} />
          </div>
        </Modal>
        <Sheet ref={this.imageRviewRef} disableDrag={true} snapPoints={[300]} className="sheet-style disable-double-tabs"  isOpen={this.state.isOpenPhotoReviewSheet} onClose={() => this.setState({isOpenPhotoReviewSheet: false})}>
        <Sheet.Container className="sheet-style"  >
          <Sheet.Header className="sheet-header">
            <div className="text-center">
            <span style={{fontWeight: 'bold', fontSize: 18}}>포토 리뷰</span>
            </div>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20}}
              onClick={() => {
                this.setState({
                  isOpenPhotoReviewSheet: false,
                  selectedPhotoReviews: [],
                  selectedPhotoReview: null
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
            </div>
          </Sheet.Header>
          <Sheet.Content  className="auto-width-100 relative-position">
          <ScrollContainer className="review-scrollmenu-parent">
          <div className="scrollmenu">
            { this.state.selectedPhotoReviews.map((review, index) => {
              if (review['reviewId'] !== '001d1433-7c8b-4ea1-9826-2229afb2ed5c') {
                return (
                  <div style={{marginRight: 10}}>
                    <img onClick={() => {
                      this.setState({selectedPhotoReview: review, isOpenPhotoReviewDetailSheet: true})
                    }} className="photo-review"  src={review.images[0]} />
                  </div>
                )
              }
            })
            }
          </div>
          </ScrollContainer>

          </Sheet.Content>
          </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
      <Sheet ref={this.imageRviewRef2} disableDrag={true} snapPoints={[1]} className="sheet-style disable-double-tabs"  isOpen={this.state.isOpenPhotoReviewDetailSheet} onClose={() => this.setState({isOpenPhotoReviewDetailSheet: false})}>
      <Sheet.Container className="sheet-style" >
        <Sheet.Header className="sheet-header">
          <div className="text-center">
            <span style={{fontWeight: 'bold', fontSize: 18}}>포토 리뷰</span>

          </div>
          <div
            item
            className="no-selection back-button"
            style={{position: 'absolute', right: 20, top: 20}}
            onClick={() => {
              this.setState({
                isOpenPhotoReviewDetailSheet: false,
                selectedPhotoReview: null
              })
            }}
          >
            <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
          </div>
        </Sheet.Header>
        <Sheet.Content  className="auto-width-100 relative-position">
        <div className="scrollmenu align-center direction-column-div auto-width-100">
        { this.state.selectedPhotoReview &&
          <Grid item className="auto-width-100 direction-row-div" justify="center">
          <div className="photo-review-detail">
            <Slider ref={c => (this.slider = c)}  beforeChange={(oldIndex, newIndex) => {
              this.setState({imageReviewIndex: newIndex})
            }} {...settings}>
              { this.state.selectedPhotoReview.images.map((image, index) => {
                return (
                  <Image className="photo-review-detail" src={image} />
                )
              })

              }
            </Slider>
          </div>
          </Grid>
        }
        </div>
        { this.state.selectedPhotoReview &&
          <div className={this.state.selectedPhotoReview.images.length === 1 ? "photo-review-div" : "photo-reviews-div"}>
            <div style={{marginBottom: 5}} className="direction-row-div align-center">
            <ReactStars
              numberOfStars={5}
              rating={this.state.selectedPhotoReview.rating}
              edit={false}
              starRatedColor={'#006042'}
              starHoverColor={'#006042'}
              starEmptyColor={'#bababa'}
              starDimension={'15px'}
              starSpacing={'0.5px'} />
              { this.state.users &&
                <span style={{fontSize: 13, marginLeft: 10, fontWeight: 'bold'}}>{this.state.users[this.state.selectedPhotoReview.uid].username.charAt(0) + '**'}</span>
              }
              <span style={{fontSize: 13, marginLeft: 10, color: 'gray'}}>{this.onGetTimeDetail(this.state.selectedPhotoReview.createdAt)}</span>


              { this.state.selectedPhotoReview.best &&
                <span className="small-red-box" style={{fontSize: 11, marginLeft: 10, color: 'white', marginTop: 2}}>베스트 리뷰</span>
              }

              </div>
              <div className="direction-column-div auto-width-100">
              { this.state.selectedPhotoReview.itemId &&
                <span style={{fontSize: 13, color: 'gray', marginBottom: 3}}>[{this.state.sconeMenu[this.state.selectedPhotoReview.itemId].name}]</span>
              }
            <span style={{fontSize: 13}}>
              {this.state.selectedPhotoReview.review}
            </span>
            </div>
          </div>
        }

        </Sheet.Content>
        </Sheet.Container>

      <Sheet.Backdrop />
    </Sheet>
      </Grid>
    )
  }
}

export default withRouter(Delivery);
