import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import PulseLoader from "react-spinners/PulseLoader";

import Select from 'react-select'

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { Row, Image } from 'react-bootstrap';

import * as firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';

import Footer from './Footer';

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class PickUpDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      pickupId: this.props.match.params.id,
      showCancelPickupModal: false,
      isCanceling: false,
      unavailableCancelSnack: false,
      unavailableChangePickupTimeSnack: false,
      changedPickupTimeSnack: false,
      sconeMenu: null,
      giftBoxInfo: {
        image: "https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/scone%2Fmenu_giftBox.png?alt=media&token=0136ce7e-efc7-4967-85dc-3fb49cdfa886",
        explanation: '선물상자 + 리본 + 보관 방법 및 먹는 방법 카드로 구성된 디저트 박스',
        name: '디저트 박스',
        price: 1000
      },
      pickupData: {},

      showAllList: false,

      hourSelectedOption: '',
      minuteSelectedOption: '',

      hour: '',
      minute: '',
    }
  }


  componentWillMount() {
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({sconeMenu: snapshot.val()})
      }
    })

  }

  componentDidMount() {
    setTimeout(() => {
      if (isEdge || isIE) {
          window.scrollTo(0, 0)
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
          })
        }
    }, 10)
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
        firebase.database().ref(`pickupOpen/${nextProps.userInfo.sconePickups[this.state.pickupId]['pickupDate'].toString()}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({pickupData: snapshot.val()})
          }
        })
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일 ${morningOrAfternoon} ${hours}시${minutes}분`
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {
      return sconeMenu[a.itemId].order - sconeMenu[b.itemId].order
    })
    return returnValue
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  async onCancelPickup(userInfo, pickupData) {
    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')
    firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/pickups/${pickupData.pickupId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        if (snapshot.val().paidPrice === 0) {
          firebase.database().ref(`users/${userInfo.uid}/sconePickups/${pickupData.pickupId}/usingCoupon`).once('value', (snapshot) => {
            if (snapshot.val()) {
              let coupon = snapshot.val()
              delete coupon['usedAt']
              firebase.database().ref(`coupons/${coupon.couponId}`).set(coupon)
            }
          }).then(() => {
            firebase.database().ref(`users/${userInfo.uid}/sconePickups/${pickupData.pickupId}`).remove()
          })
          firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/menu`).once('value', (snapshot) => {
            if (snapshot.val()) {
              let menuList = Object.values(snapshot.val())
              let menuList1 = menuList.filter(menuList => menuList.amount)
              let menuList2 = menuList.filter(menuList => menuList.amount === 0)
              menuList = menuList1.concat(menuList2)
              menuList.map((menuItem, index) => {
                if (pickupData['items'][menuItem.id]) {
                  firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/menu/${menuItem.id}/amount`).set(menuItem.amount + pickupData['items'][menuItem.id].amount)
                }
              })
            }
          })
          if (pickupData.additionalItems) {
            Object.values(pickupData.additionalItems).map((item, index) => {
              firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  let remainAmount = snapshot.val()
                  firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).set(remainAmount + item.amount)
                }
              })
            })
          }
          firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/pickups/${pickupData.pickupId}`).remove()
          firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).once('value', (snapshot) => {
            firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).set(snapshot.val() + pickupData.totalAmount)
          })
          this.props.history.replace('/pickup_list');
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
          this.setState({isCanceling: false})
          if (this.props.onCancelPickup) {
            this.props.onCancelPickup()
          }
        } else {
          axios({
            url: `https://api.tosspayments.com/v1/payments/${pickupData.paymentKey}/cancel`,
            method: "post",
            headers: {
              "Content-Type":"application/json",
              "Authorization": "Basic " + encodingAuth
            },
            data: {
              cancelReason: '고객이 취소를 원함'
            }
          }).then((data) => {
            firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/menu`).once('value', (snapshot) => {
              if (snapshot.val()) {
                let menuList = Object.values(snapshot.val())
                let menuList1 = menuList.filter(menuList => menuList.amount)
                let menuList2 = menuList.filter(menuList => menuList.amount === 0)
                menuList = menuList1.concat(menuList2)
                menuList.map((menuItem, index) => {
                  if (pickupData['items'][menuItem.id]) {
                    firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/menu/${menuItem.id}/amount`).set(menuItem.amount + pickupData['items'][menuItem.id].amount)
                  }
                })
              }
            })
            firebase.database().ref(`users/${userInfo.uid}/sconePickups/${pickupData.pickupId}/usingCoupon`).once('value', (snapshot) => {
              if (snapshot.val()) {
                let coupon = snapshot.val()
                delete coupon['usedAt']
                firebase.database().ref(`coupons/${coupon.couponId}`).set(coupon)
              }
            }).then(() => {
              firebase.database().ref(`users/${userInfo.uid}/sconePickups/${pickupData.pickupId}`).remove()
            })
            if (pickupData.additionalItems) {
              Object.values(pickupData.additionalItems).map((item, index) => {
                firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).once('value', (snapshot) => {
                  if (snapshot.val()) {
                    let remainAmount = snapshot.val()
                    firebase.database().ref(`pickupOpen/${pickupData['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).set(remainAmount + item.amount)
                  }
                })
              })
            }
            firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/pickups/${pickupData.pickupId}`).remove()
            firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).once('value', (snapshot) => {
              firebase.database().ref(`pickupOpen/${pickupData.pickupDate.toString()}/amount`).set(snapshot.val() + pickupData.totalAmount)
            })
            this.props.history.replace('/pickup_list');
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
            this.setState({isCanceling: false})
            if (this.props.onCancelPickup) {
              this.props.onCancelPickup()
            }
          })
        }

      }
    })
  }

  render() {



    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect, from } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    if (!this.state.sconeMenu) {
      return <div></div>
    }
    let pickup = this.state.userInfo.sconePickups[this.state.pickupId]

    let hourOption = []
    let minuteOption = []
    if (pickup) {
      if (this.onGetDay(pickup['pickupDate']) === '토' || this.onGetDay(pickup['pickupDate']) === '일') {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
        ]

        if (this.state.hour === '5') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      } else {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
          { value: '6', label: '6시' }
        ]
        if (this.state.hour === '6') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      }
    }
    return (
      <div  style={{marginBottom: 70, paddingTop: 70}}>
      <Grid style={this.state.isCanceling ? {pointerEvents: "none", opacity: "0.4"} : {}} container justify="center">
        <Grid container className="relative-position" justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>예약 상세</p>
        { from
          ?<div
            item
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              this.props.history.replace('/home/pickup')
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
          :<div
            item
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              let currentPath = window.location.pathname
              this.props.history.goBack()

              let inervalFunction = setInterval(() => {
                if (currentPath === window.location.pathname) {
                  this.props.history.goBack()
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                } else {
                  clearInterval(inervalFunction)
                }
              }, 100)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </div>
        }
        { !(this.onDateToTimeStamp(pickup['pickupDate'].toString()) - (1000 * 60 * 60 * 17) < Date.now()) &&
        <div
          item
          className="no-selection horiaontal-center-align"
          style={{position: 'absolute', right: 20, top: 26, cursor: 'pointer'}}
          onClick={() => {
            this.setState({showCancelPickupModal: true})
          }}
        >
          <CancelIcon  style={{color: 'gray', fontSize: 16, marginRight: 3}} />
          <span className="underline-text" style={{fontSize: 12, color: 'gray'}}>예약 취소</span>
        </div>
        }
        </Grid>

        { pickup.giftBox &&
          <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
            <Grid>
              <Image style={{width: 40, height: 40}} src={this.state.giftBoxInfo.image} />
            </Grid>
            { this.state.pickupData &&
              <Grid style={{marginLeft: 10}}>
                <p style={{fontSize: 13, margin: 0, padding: 0}}>{this.state.giftBoxInfo.name}</p>
                { this.state.pickupData.giftBoxPrice === 0 || this.state.pickupData.giftBoxPrice
                  ?<div>
                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(this.state.pickupData.giftBoxPrice)}원 / {pickup.giftBox}개</p>
                  </div>
                  :<div>
                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(this.state.giftBoxInfo.price)}원 / {pickup.giftBox}개</p>
                  </div>
                }

              </Grid>
            }

          </Grid>
        }

        <Grid container  justify="center" style={{marginTop: 20}}>
          { this.sortByOrder(Object.values(pickup.items)).map((item, index) => {
            if (this.state.showAllList === false && Object.values(pickup.items).length > 3) {
              if (index < 3) {
                return (
                  <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                    <Grid>
                      <Image style={{width: 40, height: 40}} src={item.image} />
                    </Grid>
                    <Grid style={{marginLeft: 10}}>
                      <p style={{fontSize: 13, margin: 0, padding: 0}}>{item.name}</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(item.price)}원 / {item.amount}개</p>
                    </Grid>
                  </Grid>
                )
              }

            } else {
              return (
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={item.image} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>{item.name}</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(item.price)}원 / {item.amount}개</p>
                  </Grid>
                </Grid>
              )
            }
          })
          }
          { pickup.additionalItems &&
            <Grid container  justify="center">
            { Object.values(pickup.additionalItems).map((item, index) => {
              if (this.state.showAllList === true || Object.values(pickup.additionalItems).length + Object.values(pickup.items).length < 4) {
                return (
                  <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                    <Grid>
                      <Image style={{width: 40, height: 40}} src={item.image} />
                    </Grid>
                    <Grid style={{marginLeft: 10}}>
                      <p style={{fontSize: 13, margin: 0, padding: 0}}>{item.name}</p>
                      <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(item.price)}원 / {item.amount}개</p>
                    </Grid>
                  </Grid>
                )
              }
            })
            }
            </Grid>
          }

          { this.state.showAllList === false && Object.values(pickup.items).length > 3 &&
            <div className="auto-width-100">
              <span onClick={() => {
                this.setState({showAllList: true})
              }} style={{fontSize: 13, marginLeft: 20, textDecoration: 'underline', cursor: 'pointer'}}>모두 보기</span>
            </div>
          }
          <div className="auto-width-100" style={{marginLeft: 20}}>
          { pickup.giftBox
            ?pickup.additionalItems
            ?<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 10, fontWeight: 'bold'}}>스콘 총 {pickup.totalAmount}개 ( + 디저트 박스 {pickup.giftBox}개 + 추가 상품 {pickup.additionalItemsAmount}개) / {this.onChangeCurrencyFormat(pickup.totalPrice)}원</p>
            :<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 10, fontWeight: 'bold'}}>스콘 총 {pickup.totalAmount}개 ( + 디저트 박스 {pickup.giftBox}개) / {this.onChangeCurrencyFormat(pickup.totalPrice)}원</p>
            :pickup.additionalItems
            ?<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 10, fontWeight: 'bold'}}>스콘 총 {pickup.totalAmount}개 ( + 추가 상품 {pickup.additionalItemsAmount}개) / {this.onChangeCurrencyFormat(pickup.totalPrice)}원</p>
            :<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 10, fontWeight: 'bold'}}>스콘 총 {pickup.totalAmount}개 / {this.onChangeCurrencyFormat(pickup.totalPrice)}원</p>

          }
          </div>
            <Divider className="auto-width-100" style={{marginTop: 30}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>예약 정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
                <div className="auto-width-100 direction-row-div">
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>픽업일시</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{this.onStringNumberToDate(pickup.pickupDate)} ({this.onGetDay(pickup.pickupDate)}) 오후 {pickup.pickupTime ? pickup.pickupTime.hour + '시' : ''} {pickup.pickupTime ? pickup.pickupTime.minute + '분' : ''}</span>
                </div>
                { !(this.onDateToTimeStamp(pickup['pickupDate'].toString()) - (1000 * 60 * 60 * 17) < Date.now()) &&
                  <div onClick={() => {
                    this.setState({showChangePickupTimeModal: true})
                  }} className="normal-very-small-button none-underline-text align-center">
                    <span style={{color: 'white', fontSize: 11, marginLeft: 12, marginTop: 2, marginBottom: 2}}>시간 변경</span>
                  </div>
                }

              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>예약자</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{pickup.buyerName}</span>
              </div>
              { !(this.onDateToTimeStamp(pickup['pickupDate'].toString()) - (1000 * 60 * 60 * 17) < Date.now()) &&
                <span style={{fontSize: 11, color: 'red', marginLeft: 20}}>취소와 시간 변경은 픽업일 하루 전날 오후 4시까지만 가능합니다.</span>
              }
            </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <div className="auto-width-100 direction-row-div justify-between">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 정보</span>
                { pickup['card'] &&
                  <a className="normal-very-small-button none-underline-text align-center" style={{width: 60, marginRight: 20}} href={pickup['card'].receiptUrl} target="_blank">
                    <span style={{fontSize: 11, color: 'white'}}>영수증 출력</span>
                  </a>
                }

              </div>

              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 20}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품가격</span>
                <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(pickup.totalPrice)}원</span>
              </div>
              { pickup.discount && pickup.paidPrice !== 0 &&
                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 0, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>이벤트 할인금액</span>
                  { pickup.additionalItemsPrice
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(pickup.totalPrice - pickup.additionalItemsPrice - this.onCalculateDiscount(pickup.totalPrice - pickup.additionalItemsPrice, pickup.discount.rate))}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(pickup.totalPrice - this.onCalculateDiscount(pickup.totalPrice, pickup.discount.rate))}원</span>
                  }

                </div>
              }
              { pickup.usingCoupon &&
                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>쿠폰할인</span>
                  { pickup.paidPrice === 0
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(pickup.totalPrice)}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(pickup.usingCoupon.amount)}원</span>
                  }

                </div>
              }
              { pickup.staffDiscount && pickup.paidPrice !== 0 &&
                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>직원할인</span>
                  { pickup.usingCoupon
                    ?pickup.discount
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat((this.onCalculateDiscount(pickup.totalPrice, pickup.discount.rate) - pickup.usingCoupon.amount) * (pickup.staffDiscount * 0.01))}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat((pickup.totalPrice - pickup.usingCoupon.amount) * (pickup.staffDiscount * 0.01))}원</span>

                    :pickup.discount
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(this.onCalculateDiscount(pickup.totalPrice, pickup.discount.rate) * (pickup.staffDiscount * 0.01))}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat((pickup.totalPrice) * (pickup.staffDiscount * 0.01))}원</span>

                  }

                </div>
              }
              <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
                <span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.onChangeCurrencyFormat(pickup.paidPrice)}원</span>
              </div>
              { from &&
                <div onClick={() => {
                  this.props.history.replace('/home/pickup')
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} className="normal-small-button" style={{margin: 10}}>
                  <span style={{color: 'white'}}>홈으로 가기</span>
                </div>
              }

            </div>
          </Grid>
        </Grid>
        <Modal
          open={this.state.showChangePickupTimeModal}
          onClose={() => this.setState({showChangePickupTimeModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <div className="direction-row-div" style={{margin: 18}}>
          <span style={{marginTop: 8, marginRight: 10}}>오후</span>
          <Select isSearchable={false} value={this.state.hourSelectedOption} onChange={(selectedOption) => {
            if (this.onGetDay(pickup['pickupDate']) === '토' || this.onGetDay(pickup['pickupDate']) === '일') {
              if (selectedOption.value === '5') {
                if (this.state.minute === '40' || this.state.minute === '50') {
                  this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}})
                } else {
                  this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                }
              } else {
                this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
              }
            } else {
              if (selectedOption.value === '6') {
                if (this.state.minute === '40' || this.state.minute === '50') {
                  this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}})
                } else {
                  this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                }
              } else {
                this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
              }
            }

          }} className="select-style" styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
              borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
              "&:hover": {
                borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
              }
            }),
            menu: (base, state) => ({
               ...base,
               marginBottom: 80,
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              cursor: 'pointer',
              ':active': {
                ...styles[':active'],
              backgroundColor: 'rgba(0, 135, 117, 0.5)'},
              backgroundColor: isDisabled
              ? null
              : isSelected
              ? 'rgba(0, 135, 117, 0.95)'
              : isFocused
              ? 'rgba(0, 135, 117, 0.2)'
              : null,
          }}
          }}
          placeholder='시' options={hourOption} />
          <Select isSearchable={false} value={this.state.minuteSelectedOption} onChange={(selectedOption) => {
            this.setState({minute: selectedOption.value, minuteSelectedOption: selectedOption})
          }} className="select-style" styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
              borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
              "&:hover": {
                borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
              }
            }),
            menu: (base, state) => ({
               ...base,
               marginBottom: 80,
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              cursor: 'pointer',
              ':active': {
                ...styles[':active'],
              backgroundColor: 'rgba(0, 135, 117, 0.5)'},
              backgroundColor: isDisabled
              ? null
              : isSelected
              ? 'rgba(0, 135, 117, 0.95)'
              : isFocused
              ? 'rgba(0, 135, 117, 0.2)'
              : null,
          }}
        }} placeholder='분' options={minuteOption} />
          </div>
          <Grid container direction="row" style={{marginTop: 20}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showChangePickupTimeModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            if (this.state.hour && this.state.minute) {
              if (!(this.onDateToTimeStamp(pickup['pickupDate'].toString()) - (1000 * 60 * 60 * 17) < Date.now())) {
                firebase.database().ref(`pickupOpen/${pickup.pickupDate.toString()}/pickups/${pickup.pickupId}/pickupTime`).set({hour: this.state.hour, minute: this.state.minute})
                .then(() => {
                  firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickup.pickupId}/pickupTime`).set({hour: this.state.hour, minute: this.state.minute})
                  .then(() => {
                    let userInfo = this.state.userInfo
                    userInfo.sconePickups[pickup.pickupId].pickupTime = {hour: this.state.hour, minute: this.state.minute}
                    this.setState({
                      hourSelectedOption: '',
                      minuteSelectedOption: '',
                      hour: '',
                      minute: '',
                      changedPickupTimeSnack: true,
                      showChangePickupTimeModal: false,
                      userInfo: userInfo
                    })
                  })
                })
              } else {
                this.setState({unavailableChangePickupTimeSnack: true, showChangePickupTimeModal: false})
              }
            } else {
              alert('시간을 선택해주세요.')
            }

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>시간 변경</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showCancelPickupModal}
          onClose={() => this.setState({showCancelPickupModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>해당 픽업 예약을 취소하시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showCancelPickupModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            if (!(this.onDateToTimeStamp(pickup['pickupDate'].toString()) - (1000 * 60 * 60 * 17) < Date.now())) {
              this.setState({showCancelPickupModal: false, isCanceling: true})
              this.onCancelPickup(this.state.userInfo, pickup)
            } else {
              this.setState({unavailableCancelSnack: true, showCancelPickupModal: false})
            }

          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>예약 취소</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Footer />

      </Grid>
      { this.state.isCanceling &&
        <div className="center-div">
        <div className="vertical-center-align direction-column-div auto-width-100">
        <BeatLoader  color={'#006042'} loading={true} size={20} />
        <span style={{fontSize: 14, marginTop: 10}}>예약 취소 중...</span>
        </div>
        </div>
      }
      <Snackbar open={this.state.changedPickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({changedPickupTimeSnack: false})}>
        <Alert  onClose={() => this.setState({changedPickupTimeSnack: false})} severity="success">
          예약 시간이 변경되었습니다.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.unavailableChangePickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({unavailableChangePickupTimeSnack: false})}>
        <Alert  onClose={() => this.setState({unavailableChangePickupTimeSnack: false})} severity="warning">
          예약 시간 변경 가능 시간이 지났습니다.(전날 오후 4시까지 가능)
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.unavailableCancelSnack} autoHideDuration={5000} onClose={() => this.setState({unavailableCancelSnack: false})}>
        <Alert  onClose={() => this.setState({unavailableCancelSnack: false})} severity="warning">
          예약 취소 가능 시간이 지났습니다.(전날 오후 4시까지 가능)
        </Alert>
      </Snackbar>
      </div>
    )
  }
}

export default withRouter(PickUpDetail);
