import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Home from './Home';
import Alarm from './Alarm';
import Regular from './Regular';
import RegularStart from './RegularStart';
import Delivery from './Delivery';
import DeliveryLocation from './DeliveryLocation';

import Cart from './Cart';
import DeliveryCart from './DeliveryCart';
import NavBar from './NavBar';
import BottomNav from './BottomNav';
import MyPage from './MyPage';
import Member from './Member';
import PickUp from './PickUp';
import PickUpDetail from './PickUpDetail';
import PickUpList from './PickUpList';
import PickupReview from './PickupReview';
import OrderList from './OrderList';
import Order from './Order';
import OrderPaymentResult from './OrderPaymentResult';
import GiftSetPaymentResult from './GiftSetPaymentResult';
import GiftSetPickUpDetail from './GiftSetPickUpDetail';
import JustPayment from './JustPayment';

import ItemOrder from './ItemOrder';
import ItemReview from './ItemReview';

import PrintOrders from './PrintOrders';
import PrintItemOrders from './PrintItemOrders';

import PurchasedList from './PurchasedList';
import Review from './Review';
import Reviews from './Reviews';
import Join from './Join';
import Admin from './Admin';
import Pos from './Pos';
import Login from './Login';

import Landing from './Landing';

import Coupon from './Coupon';
import CouponList from './CouponList';

import PasswordFind from './PasswordFind';
import EmailFind from './EmailFind';
import EmailLogin from './EmailLogin';

import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

import DeliveryPaymentResult from './DeliveryPaymentResult';
import JustPaymentResult from './JustPaymentResult';
import PaymentResult from './PaymentResult';
import RegularResult from './RegularResult';
import RegularCardChangeResult from './RegularCardChangeResult';

import RegularDetail from './RegularDetail';
import RegularOrderDetail from './RegularOrderDetail';

import Spread from './Spread';
import MilkTeaBase from './MilkTeaBase';
import SconeMix from './SconeMix';
import SconeMixRedirect from './SconeMixRedirect';
import ChuseokGiftSet from './ChuseokGiftSet';
import ChuseokGiftSetDelivery from './ChuseokGiftSetDelivery';
import WinaGoods from './WinaGoods';
import WinaGoodsDetail from './WinaGoodsDetail';

import SconeClass from './SconeClass';


import * as firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let pjson = require('../../package.json')

const { Kakao } = window;

class Routes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showNavBar: true,
      showBottomNav: true,
      bottomNavIndex: 0,
      deliveryInnerTabIndex: 0,
      navIndex: 0,
      giftBoxAmount: 0,
      giftBoxPrice: 0,
      totalAmount: 0,
      totalPrice: 0,
      totalItemPrice: 0,
      totalItemAmount: 0,
      selectedScones: {},
      selectedItems: {},
      prevNavIndex: {index: 0, position: 0},
      deliveryTabViewPosition: 0,
      shoppingTabViewPosition: 0,
      userInfo: null,
      pickupSconesObj: {},
      kakaoSignInSnack: false,
      naverSignInSnack: false,

      orderWithAdditionalItems: true,
      additionalItems: {},

      pathInMyPage: '/mypage',

      cancelPickupSnack: false,
      cancelOrderSnack: false,

      deliveryAddressIndex: null,
      shoppingAddressIndex: null,

      reloadCartPage: false,
      reloadShoppingCartPage: false,

      itemsInCart: 0,
      showDiscountPopup: true,
      itemsObj: {}
    }
    this.entireViewRef = React.createRef()
  }

  componentWillMount() {
    firebase.database().ref(`version`).once('value', (snapshot) => {
      if (snapshot.val()) {
        if (snapshot.val() !== pjson.version) {
          window.location.reload()
        }
      }
    })



    firebase.database().ref(`items`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({itemsObj: snapshot.val()})
      }
    })

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.database().ref(`users/${user.uid}/cart`).on('value', (snapshot) => {
          if (snapshot.val()) {
            let itemsInCartObj = snapshot.val()
            let itemsInCart = 0
            this.setState({additionalItems: itemsInCartObj})
            Object.values(itemsInCartObj).map((item, index) => {
              itemsInCart = itemsInCart + item.amount
              if (Object.values(itemsInCartObj).length === index + 1) {
                this.setState({itemsInCart: itemsInCart})
              }
            })
          } else {
            this.setState({additionalItems: {}})
            this.setState({itemsInCart: 0})
          }
        })
        firebase.database().ref(`users/${user.uid}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({userInfo: snapshot.val(), isLoading: false})
          }
        })
      } else {
        this.setState({userInfo: null, isLoading: false})
      }
    })


    if (window.location.pathname === '/') {
      this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 1, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/home') {
      if (window.location.pathname === '/home/pickup') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 2, showBottomNav: true})
      }
      if (window.location.pathname === '/home/scone_class') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 0, showBottomNav: true})
      }
      if (window.location.pathname === '/home/spread') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 3, showBottomNav: true})
      }
      if (window.location.pathname === '/home/milk_tea_base') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 4, showBottomNav: true})
      }
      if (window.location.pathname === '/home/scone_mix') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 5, showBottomNav: true})
      }
      if (window.location.pathname === '/home/wina_goods') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 6, showBottomNav: true})
      }
    } else if (window.location.pathname.substring(0, 18) === '/wina_goods_detail') {
      this.setState({showNavBar: true, bottomNavIndex: 0, showBottomNav: true, navIndex: 5})
    } else if (window.location.pathname.substring(0, 10) === '/scone_mix') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 14) === '/regular_start') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 21) === '/regular_order_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/cart') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 14) === '/delivery_cart') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 8) === '/landing') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/mypage') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/join') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 6) === '/alarm') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/coupon_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/password_find') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 11) === '/email_find') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 6) === '/login') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/member') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 11) === '/order_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 11) === '/item_order') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/item_review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 6) === '/order') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 13) === '/print_orders') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 18) === '/print_item_orders') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 21) === '/order_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 23) === '/gift_set_pickup_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/pickup_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/pickup_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/pickup_review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 18) === '/delivery_location') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 15) === '/purchased_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 7) === '/review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    }  else if (window.location.pathname.substring(0, 8) === '/reviews') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 24) === '/gift_set_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/regular_result') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 27) === '/regular_card_change_result') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 24) === '/delivery_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 20) === '/just_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 3, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/regular_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 15) === '/privacy_policy') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 16) === '/term_of_service') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 6) === '/admin') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/coupon') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 13) === '/just_payment') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', (event) => {
      if (window.location.pathname === '/') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 1, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 5) === '/home') {
        if (window.location.pathname === '/home/pickup') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 2, showBottomNav: true})
        }
        if (window.location.pathname === '/home/scone_class') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 0, showBottomNav: true})
        }
        if (window.location.pathname === '/home/spread') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 3, showBottomNav: true})
        }
        if (window.location.pathname === '/home/milk_tea_base') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 4, showBottomNav: true})
        }
        if (window.location.pathname === '/home/scone_mix') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 5, showBottomNav: true})
        }
        if (window.location.pathname === '/home/wina_goods') {
          this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 6, showBottomNav: true})
        }
      } else if (window.location.pathname.substring(0, 18) === '/wina_goods_detail') {
        this.setState({showNavBar: true, bottomNavIndex: 0, showBottomNav: true, navIndex: 5})
      } else if (window.location.pathname.substring(0, 10) === '/scone_mix') {
        this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 14) === '/regular_start') {
        this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 21) === '/regular_order_detail') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 5) === '/cart') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 14) === '/delivery_cart') {
        this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 8) === '/landing') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 7) === '/mypage') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 5) === '/join') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 6) === '/alarm') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 12) === '/coupon_list') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 11) === '/email_find') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 14) === '/password_find') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 6) === '/login') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 7) === '/member') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 11) === '/item_order') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 12) === '/item_review') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 11) === '/order_list') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 6) === '/order') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 13) === '/print_orders') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 18) === '/print_item_orders') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 21) === '/order_payment_result') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 23) === '/gift_set_pickup_detail') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 14) === '/pickup_detail') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 12) === '/pickup_list') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 14) === '/pickup_review') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 18) === '/delivery_location') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 15) === '/purchased_list') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 7) === '/review') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 8) === '/reviews') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 24) === '/gift_set_payment_result') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 15) === '/payment_result') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 15) === '/regular_result') {
        this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 27) === '/regular_card_change_result') {
        this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 24) === '/delivery_payment_result') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 20) === '/just_payment_result') {
        this.setState({showNavBar: false, bottomNavIndex: 3, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 15) === '/regular_detail') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
      } else if (window.location.pathname.substring(0, 15) === '/privacy_policy') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 16) === '/term_of_service') {
        this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 6) === '/admin') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 7) === '/coupon') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      } else if (window.location.pathname.substring(0, 13) === '/just_payment') {
        this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
      }
    })
  }

  componentWillUnmount() {
  }

  onSetItemsObj(itemsObj) {
    this.setState({itemsObj: itemsObj})
  }

  onCloseDiscountPopup() {
    this.setState({showDiscountPopup: false})
  }


  onLoginKakao(uid) {
    if (uid) {
      firebase.database().ref(`users/${uid}`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({kakaoSignInSnack: true})
        }
      })
    }
  }
  onLoginNaver(uid) {
    if (uid) {
      firebase.database().ref(`users/${uid}`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({naverSignInSnack: true})
        }
      })
    }
  }
  onSavePickupSconesObj(pickupSconesObj) {
    this.setState({pickupSconesObj: pickupSconesObj})
  }

  onRefreshUserInfo() {
    if (this.state.userInfo) {
      firebase.database().ref(`users/${this.state.userInfo.uid}`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({userInfo: snapshot.val(), isLoading: false})
        }
      })
    }

  }

  getDeliveryTabViewPosition(position) {
    this.setState({deliveryTabViewPosition: position})
  }

  getShoppingTabViewPosition(position) {
    this.setState({shoppingTabViewPosition: position})
  }

  routeChanges() {

    if (window.location.pathname === '/') {
      this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 1, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/home') {
      if (window.location.pathname === '/home/pickup') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 2, showBottomNav: true})
      }
      if (window.location.pathname === '/home/scone_class') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 0, showBottomNav: true})
      }
      if (window.location.pathname === '/home/spread') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 3, showBottomNav: true})
      }
      if (window.location.pathname === '/home/milk_tea_base') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 4, showBottomNav: true})
      }
      if (window.location.pathname === '/home/scone_mix') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 5, showBottomNav: true})
      }
      if (window.location.pathname === '/home/wina_goods') {
        this.setState({showNavBar: true, bottomNavIndex: 0, navIndex: 6, showBottomNav: true})
      }
    } else if (window.location.pathname.substring(0, 18) === '/wina_goods_detail') {
      this.setState({showNavBar: true, bottomNavIndex: 0, showBottomNav: true, navIndex: 5})
    } else if (window.location.pathname.substring(0, 10) === '/scone_mix') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 14) === '/regular_start') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 21) === '/regular_order_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/cart') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 14) === '/delivery_cart') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 8) === '/landing') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/mypage') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 5) === '/join') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 6) === '/alarm') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/coupon_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 11) === '/email_find') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/password_find') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 6) === '/login') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/member') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 11) === '/item_order') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/item_review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 11) === '/order_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 6) === '/order') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 13) === '/print_orders') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 18) === '/print_item_orders') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 21) === '/order_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 23) === '/gift_set_pickup_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/pickup_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 12) === '/pickup_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 14) === '/pickup_review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 18) === '/delivery_location') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 15) === '/purchased_list') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 7) === '/review') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 8) === '/reviews') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 24) === '/gift_set_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/regular_result') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 27) === '/regular_card_change_result') {
      this.setState({showNavBar: false, bottomNavIndex: 0, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 24) === '/delivery_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 20) === '/just_payment_result') {
      this.setState({showNavBar: false, bottomNavIndex: 3, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 15) === '/regular_detail') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: true})
    } else if (window.location.pathname.substring(0, 15) === '/privacy_policy') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 16) === '/term_of_service') {
      this.setState({showNavBar: false, bottomNavIndex: 1, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 6) === '/admin') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 7) === '/coupon') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    } else if (window.location.pathname.substring(0, 13) === '/just_payment') {
      this.setState({showNavBar: false, bottomNavIndex: 2, showBottomNav: false})
    }
  }

  onChanegeMypagePath() {

  }

  onCancelPickup() {
    this.setState({cancelPickupSnack: true})
  }

  onCancelOrder() {
    this.setState({cancelOrderSnack: true})
  }

  deliveryInnerTabChanges(index) {
    this.setState({deliveryInnerTabIndex: index})
  }

  navIndexChanges(index) {
    this.setState({navIndex: index})
  }

  onSetGiftBox(giftBoxAmount, giftBoxPrice) {
    this.setState({giftBoxAmount: giftBoxAmount, giftBoxPrice: giftBoxPrice})
  }

  onSelectScone(totalPrice, totalAmount, selectedScones, navIndex) {
    this.setState({selectedScones: selectedScones, totalPrice: totalPrice, totalAmount: totalAmount, bottomNavIndex: navIndex})
  }

  onSelectOrderWithAdditionalItems(orderWithAdditionalItems) {
    this.setState({orderWithAdditionalItems: orderWithAdditionalItems})
  }


  onSelectDeliveryAddress(deliveryAddressIndex) {
    this.setState({deliveryAddressIndex: deliveryAddressIndex})
  }

  onSelectShoppingAddress(shoppingAddressIndex) {
    this.setState({shoppingAddressIndex: shoppingAddressIndex})
  }

  onReloadCartPage() {
    this.setState({reloadCartPage: true})
    setTimeout(() => {
      this.setState({reloadCartPage: false})
    })
  }

  onReloadShoppingCartPage() {
    this.setState({reloadShoppingCartPage: true})
    setTimeout(() => {
      this.setState({reloadShoppingCartPage: false})
    })
  }

  addItemsInCart() {
    this.setState({itemsInCart: this.state.itemsInCart + 1})
  }

  removeItemsInCart() {
    if (this.state.itemsInCart !== 0) {
      this.setState({itemsInCart: this.state.itemsInCart - 1})
    }
  }

    render() {
        return (
            <BrowserRouter>
            <Container maxWidth="sm" ref={this.entireViewRef} style={{padding: 0}}>
              <Grid>
                { this.state.showNavBar &&
                  <NavBar navIndex={this.state.navIndex} navIndexChanges = {(index) => this.navIndexChanges(index)}  />
                }
                <Switch>
                  <Route render={props => <Alarm  userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/alarm"   />

                  <Route render={props => <SconeClass {...props}  onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/scone_class"   />
                  <Route render={props => <MilkTeaBase {...props}  onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/milk_tea_base"   />
                  <Route render={props => <Spread {...props} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/spread"   />
                  <Route render={props => <ChuseokGiftSetDelivery {...props} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/chuseok_gift_set_delivery"   />

                  <Route render={props => <SconeMixRedirect {...props} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/scone_mix"   />
                  <Route render={props => <ChuseokGiftSet userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} pickupSconesObj = {this.state.pickupSconesObj} routeChanges={() => this.routeChanges()} onSavePickupSconesObj={(pickupSconesObj) => this.onSavePickupSconesObj(pickupSconesObj)} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj}  /> } exact path="/home/chuseok_gift_set"  />

                  <Route render={props => <SconeMix {...props} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/scone_mix"   />
                  <Route render={props => <WinaGoods {...props} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/home/wina_goods"   />
                  <Route render={props => <WinaGoodsDetail {...props} onSetItemsObj={(itemsObj) => this.onSetItemsObj(itemsObj)} itemsObj = {this.state.itemsObj} removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/wina_goods_detail/:category"   />
                  <Route render={props => <PickUp userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} pickupSconesObj = {this.state.pickupSconesObj} routeChanges={() => this.routeChanges()} onSavePickupSconesObj={(pickupSconesObj) => this.onSavePickupSconesObj(pickupSconesObj)}  /> } exact path="/home/pickup"  />
                  <Route render={props => <PickUpDetail {...props}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  onCancelPickup={() => this.onCancelPickup()} routeChanges={() => this.routeChanges()} /> } exact path="/pickup_detail/:id"   />
                  <Route render={props => <GiftSetPickUpDetail {...props}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  onCancelPickup={() => this.onCancelPickup()} routeChanges={() => this.routeChanges()} /> } exact path="/gift_set_pickup_detail/:id"   />

                  <Route render={props => <RegularDetail {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading}  routeChanges={() => this.routeChanges()} /> } exact path="/regular_detail"   />
                  <Route render={props => <RegularOrderDetail userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/regular_order_detail/:id"   />
                  <Route render={props => <DeliveryPaymentResult  {...props}  onReloadCartPage={() => this.onReloadCartPage()} orderWithAdditionalItems={this.state.orderWithAdditionalItems} additionalItems={this.state.additionalItems} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/delivery_payment_result/:result"   />
                  <Route render={props => <JustPaymentResult  {...props}  onReloadCartPage={() => this.onReloadCartPage()} orderWithAdditionalItems={this.state.orderWithAdditionalItems} additionalItems={this.state.additionalItems} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/just_payment_result/:paymentId/:result"   />
                  <Route render={props => <PaymentResult  {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/payment_result/:result"   />

                  <Route render={props => <GiftSetPaymentResult  {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/gift_set_payment_result/:result"   />
                  <Route render={props => <RegularResult  {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/regular_result/:result"   />
                  <Route render={props => <RegularCardChangeResult  {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/regular_card_change_result/:result"   />
                  <Route render={props => <Regular userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/home/regular"  />
                  <Route render={props => <RegularStart userInfo = {this.state.userInfo} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()}  /> }  exact path="/regular_start"  />
                  <Route render={props => <DeliveryLocation deliveryAddressIndex={this.state.deliveryAddressIndex} onSelectDeliveryAddress={(deliveryAddressIndex) => this.onSelectDeliveryAddress(deliveryAddressIndex)} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/delivery_location"  />

                  <Route render={props => <Cart  removeItemsInCart={() => this.removeItemsInCart()} addItemsInCart={() => this.addItemsInCart()} itemsInCart = {this.state.itemsInCart} reloadShoppingCartPage = {this.state.reloadShoppingCartPage} userInfo = {this.state.userInfo} shoppingAddressIndex={this.state.shoppingAddressIndex} onSelectShoppingAddress = {(shoppingAddressIndex) => this.onSelectShoppingAddress(shoppingAddressIndex)} isLoading = {this.state.isLoading} shoppingTabViewPosition={this.state.shoppingTabViewPosition} routeChanges={() => this.routeChanges()} />}  exact path="/cart" />

                  <Route render={props => <Delivery userInfo = {this.state.userInfo}  showDiscountPopup = {this.state.showDiscountPopup} onCloseDiscountPopup={() => this.onCloseDiscountPopup()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} onRefreshUserInfo={() => this.onRefreshUserInfo()} orderWithAdditionalItems={this.state.orderWithAdditionalItems} totalPrice = {this.state.totalPrice} totalAmount = {this.state.totalAmount} selectedScones = {this.state.selectedScones}
                         onSelectScone = {(totalPrice, totalAmount, selectedScones, navIndex) => this.onSelectScone(totalPrice, totalAmount, selectedScones, navIndex)} giftBoxAmount={this.state.giftBoxAmount} giftBoxPrice={this.state.giftBoxPrice} onSetGiftBox = {(giftBoxAmount, giftBoxPrice) => this.onSetGiftBox(giftBoxAmount, giftBoxPrice)}
                         deliveryInnerTabChanges = {(index) => this.deliveryInnerTabChanges(index)} getDeliveryTabViewPosition = {(position) => this.getDeliveryTabViewPosition(position)} deliveryInnerTabIndex = {this.state.deliveryInnerTabIndex} />} exact path="/" />
                  <Route render={props => <DeliveryCart reloadCartPage = {this.state.reloadCartPage} userInfo = {this.state.userInfo} deliveryAddressIndex={this.state.deliveryAddressIndex} onSelectDeliveryAddress = {(deliveryAddressIndex) => this.onSelectDeliveryAddress(deliveryAddressIndex)} isLoading = {this.state.isLoading} deliveryTabViewPosition={this.state.deliveryTabViewPosition} routeChanges={() => this.routeChanges()} totalPrice = {this.state.totalPrice} totalAmount = {this.state.totalAmount} selectedScones = {this.state.selectedScones}
                          onSelectScone = {(totalPrice, totalAmount, selectedScones, navIndex) => this.onSelectScone(totalPrice, totalAmount, selectedScones, navIndex)} giftBoxAmount={this.state.giftBoxAmount} giftBoxPrice={this.state.giftBoxPrice} onSetGiftBox = {(giftBoxAmount, giftBoxPrice) => this.onSetGiftBox(giftBoxAmount, giftBoxPrice)} additionalItems={this.state.additionalItems} onSelectOrderWithAdditionalItems={(orderWithAdditionalItems) => this.onSelectOrderWithAdditionalItems(orderWithAdditionalItems)} orderWithAdditionalItems={this.state.orderWithAdditionalItems} onRefreshUserInfo={() => this.onRefreshUserInfo()} deliveryInnerTabChanges = {(index) => this.deliveryInnerTabChanges(index)} />}  exact path="/delivery_cart" />
                  <Route render={props => <MyPage  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/mypage"   />
                  <Route render={props => <Member userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/member"   />
                  <Route render={props => <OrderList onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/order_list"   />


                  <Route render={props => <Coupon {...props} navIndexChanges = {(index) => this.navIndexChanges(index)} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/coupon/:id"   />
                  <Route render={props => <CouponList {...props} navIndexChanges = {(index) => this.navIndexChanges(index)} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/coupon_list"   />

                  <Route render={props => <JustPayment {...props} navIndexChanges = {(index) => this.navIndexChanges(index)} onRefreshUserInfo={() => this.onRefreshUserInfo()} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/just_payment/:paymentId"   />

                  <Route render={props => <Order {...props} onCancelOrder={() => this.onCancelOrder()} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/order/:id"   />
                  <Route render={props => <ItemOrder {...props} onCancelOrder={() => this.onCancelOrder()} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/item_order/:id"   />

                  <Route render={props => <PrintOrders {...props} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/print_orders/:id"  />
                  <Route render={props => <PrintItemOrders {...props} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/print_item_orders/:id"  />

                  <Route render={props => <ItemReview {...props} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} onRefreshUserInfo={() => this.onRefreshUserInfo()} /> } exact path="/item_review/:id"   />

                  <Route render={props => <OrderPaymentResult  {...props}  onReloadCartPage={() => this.onReloadCartPage()} onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }  exact path="/order_payment_result/:result"   />
                  <Route render={props => <Review {...props} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} onRefreshUserInfo={() => this.onRefreshUserInfo()} /> } exact path="/review/:id"   />
                  <Route render={props => <Reviews {...props} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} onRefreshUserInfo={() => this.onRefreshUserInfo()} /> } exact path="/reviews/:id"   />
                  <Route render={props => <PickupReview {...props} onRefreshUserInfo={() => this.onRefreshUserInfo()}  userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/pickup_review/:id"   />
                  <Route render={props => <PurchasedList onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/purchased_list"   />
                  <Route render={props => <PickUpList onRefreshUserInfo={() => this.onRefreshUserInfo()} userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> } exact path="/pickup_list"   />
                  <Route render={props => <Join userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/join"  />

                  <Route render={props => <EmailFind userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/email_find"  />
                  <Route render={props => <PasswordFind userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/password_find"  />
                  <Route render={props => <Login userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} onLoginNaver={(uid) => this.onLoginNaver(uid)} onLoginKakao={(uid) => this.onLoginKakao(uid)}  routeChanges={() => this.routeChanges()} /> }   exact path="/login"  />
                  <Route render={props => <EmailLogin userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/login/email"  />

                  <Route render={props => <Landing userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/landing"  />

                  <Route render={props => <Admin userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} /> }   exact path="/admin"  />

                  <Route exact path="/privacy_policy" userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} component={PrivacyPolicy} />
                  <Route exact path="/term_of_service" userInfo = {this.state.userInfo} isLoading = {this.state.isLoading} routeChanges={() => this.routeChanges()} component={TermsOfService} />
                </Switch>
                { this.state.showBottomNav &&
                  <BottomNav itemsInCart = {this.state.itemsInCart}  navIndex={this.state.navIndex} bottomNavIndex={this.state.bottomNavIndex} routeChanges={() => this.routeChanges()}/>
                }
              </Grid>
              <Snackbar open={this.state.kakaoSignInSnack} autoHideDuration={3000} onClose={() => this.setState({kakaoSignInSnack: false})}>
                <Alert  onClose={() => this.setState({kakaoSignInSnack: false})} severity="success">
                  가입해주셔서 감사합니다!
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.naverSignInSnack} autoHideDuration={3000} onClose={() => this.setState({naverSignInSnack: false})}>
              <Alert  onClose={() => this.setState({naverSignInSnack: false})} severity="success">
                가입해주셔서 감사합니다!
              </Alert>
          </Snackbar>
            <Snackbar open={this.state.cancelPickupSnack} autoHideDuration={3000} onClose={() => this.setState({cancelPickupSnack: false})}>
              <Alert  onClose={() => this.setState({cancelPickupSnack: false})} severity="info">
                예약 취소 완료.
              </Alert>
          </Snackbar>
          <Snackbar open={this.state.cancelOrderSnack} autoHideDuration={3000} onClose={() => this.setState({cancelOrderSnack: false})}>
            <Alert  onClose={() => this.setState({cancelOrderSnack: false})} severity="info">
              택배 취소 완료.
            </Alert>
        </Snackbar>
            </Container>
            </BrowserRouter>
        )
    }
}

export default Routes
