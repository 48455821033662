import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import BeatLoader from "react-spinners/BeatLoader";

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import queryString from 'query-string'

import Delivery from './Delivery';
import Regular from './Regular';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';

import * as firebase from 'firebase';

const axios = require("axios");

class GiftSetPaymentResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      paymentCompleted: false,
      result: this.props.match.params.result,
      pickupId: '',
      isPaying: true,
      requestError: false,
      isRequestingPayment: false
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (!this.state.isRequestingPayment && nextProps.userInfo) {
        this.setState({isRequestingPayment: true}, () => {
          setTimeout(() => {
            this.onRequestPayment(nextProps.userInfo)
          }, 10)
        })
      }
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  async onRequestPayment(userInfo) {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { paymentKey, orderId, amount } = queryObj;

    this.setState({pickupId: orderId})


    let encodingAuth = await btoa('live_sk_D4yKeq5bgrppWxaWKRprGX0lzW6Y:')

    firebase.database().ref(`users/${userInfo.uid}/giftSetPickups/${orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        axios({
          url: `https://api.tosspayments.com/v1/payments/${paymentKey}`,
          method: "post",
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Basic " + encodingAuth
          },
          data: {
            amount: amount, orderId: orderId
          }
        }).then((data) => {
          if (data.status === 200) {
            if (data.data.status === 'DONE') {
              if (sessionStorage.getItem('giftSetpickupData')) {
                sessionStorage.removeItem('giftSetpickupData')
              }
              firebase.database().ref(`users/${userInfo.uid}/giftSetPickups/${orderId}`).once('value', (snapshot) => {
                if (snapshot.val()) {
                  let purchaseInfo = snapshot.val()
                  purchaseInfo['paid'] = true
                  purchaseInfo['paidAt'] = Date.now()
                  purchaseInfo['paymentKey'] = paymentKey
                  if (data.data.card) {
                    purchaseInfo['card'] = data.data.card
                  }

                  firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/giftSetPickups/${orderId}`).set(purchaseInfo)
                  firebase.database().ref(`users/${userInfo.uid}/giftSetPickups/${orderId}`).set(purchaseInfo)
                  .then(() => {
                    axios({
                      url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendGiftSetPickupMessage`,
                      method: "post",
                      headers: {
                        "Content-Type":"application/json"
                      },
                      data: {
                        pickupId: orderId,
                        uid: userInfo.uid
                      }
                    })
                    if (this.props.onRefreshUserInfo) {
                      this.props.onRefreshUserInfo()
                    }
                    this.setState({isPaying: false, paymentCompleted: true}, () => {
                      setTimeout(() => {
                        this.props.history.replace(`/gift_set_pickup_detail/${orderId}?from=gift_set_payment_result`);
                        setTimeout(() => {
                          this.props.routeChanges()
                        }, 10)
                      }, 2000)
                    })
                  })
                }
              })
            }
          } else {
            this.setState({isPaying: false, paymentCompleted: false}, () => {
              setTimeout(() => {
                this.props.history.push('/home/chuseok_gift_set');
                setTimeout(() => {
                  firebase.database().ref(`users/${userInfo.uid}/giftSetPickups/${orderId}`).remove()
                  this.props.routeChanges()
                }, 10)
              }, 2000)
            })
          }
        }).catch((err) => {
          this.setState({requestError: true}, () => {
            setTimeout(() => {
              this.props.history.push('/home/chuseok_gift_set');
              setTimeout(() => {
                firebase.database().ref(`users/${userInfo.uid}/giftSetPickups/${orderId}`).remove()
                this.props.routeChanges()
              }, 10)
            }, 2000)
          })
        })
      }
    })


  }



  render() {
    if (this.state.isLoading) {
      return (<div></div>)
    }
    if (this.state.result === 'fail') {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isPaying) {
      if (this.state.requestError) {
        return (
          <div>
            <div className="center-div">
              <div className="vertical-center-align direction-column-div auto-width-100">
              <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제가 실패했습니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>결제가 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.paymentCompleted) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>예약 목록 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default GiftSetPaymentResult;
