import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MainLogo from '../MainLogo.png';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import HomeIcon from '@material-ui/icons/Home';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalMallIcon from '@material-ui/icons/LocalMall';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Badge from '@material-ui/core/Badge';



class BottomNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      bottomNavIndex: 0,
      itemsInCart: this.props.itemsInCart,
      showBadge: false
    }
  }


  componentWillMount() {
    if (this.props.bottomNavIndex) {
      this.setState({bottomNavIndex: this.props.bottomNavIndex})
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.itemsInCart || nextProps.itemsInCart === 0) {
      let currentProps = this.props;
      if (currentProps.itemsInCart < nextProps.itemsInCart) {
        setTimeout(() => {
          this.setState({showBadge: true})
        }, 300)
        setTimeout(() => {
          this.setState({showBadge: false})
        }, 400)
        setTimeout(() => {
          this.setState({showBadge: true})
        }, 500)
        setTimeout(() => {
          this.setState({showBadge: false})
        }, 600)
      }
      this.setState({itemsInCart: nextProps.itemsInCart})
    }
    // You don't have to do this check first, but it can help prevent an unneeded render
  }


  render() {
    let bottomNavIndex = this.props.bottomNavIndex
    return (
        <div className="bottom-nav">
          <Row className="justify-content-md-center">
            <BottomNavigation
              style={{height: 45}}
              value={bottomNavIndex}
              onChange={(event, newValue) => {
                this.setState({bottomNavIndex: newValue})
              }}
              showLabels
            >
              { bottomNavIndex === 0
                ?<BottomNavigationAction component={Link} to={'/'} onClick={ (event) => event.preventDefault() } disableRipple  label={<span className="bottom-nav-text">홈</span>} icon={<HomeIcon style={{ color: '#006042', fontSize: 20 }} />} />
                :<BottomNavigationAction component={Link} to={this.props.navIndex === 0 ? '/' : this.props.navIndex === 1 ? '/home/pickup' : this.props.navIndex === 2 ? '/home/spread' : this.props.navIndex === 3 ? '/home/milk_tea_base' : this.props.navIndex === 4 ? '/home/scone_mix' : '/home/wina_goods'} onClick={ () => {
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)

                }} disableRipple  label={<span className="bottom-nav-text">홈</span>} icon={<HomeIcon style={{ color: 'gray', fontSize: 20 }} />} />
              }
              { bottomNavIndex === 1
                ?<BottomNavigationAction component={Link} to={'/cart'} onClick={ (event) => event.preventDefault() } disableRipple  label={<span className="bottom-nav-text">장바구니</span>} icon={<LocalMallIcon style={{ color: '#006042', fontSize: 20 }} />} />
                :<BottomNavigationAction component={Link} to={'/cart'} onClick={ () => {
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} disableRipple  label={<div><span className="bottom-nav-text" >장바구니</span>{this.state.itemsInCart !== 0 && <div className={this.state.showBadge ? 'nav-badge-style-bigger' : 'nav-badge-style'}><span style={{color: 'white', fontSize: 10}}>{this.state.itemsInCart < 100 ? this.state.itemsInCart : 99}</span></div>}</div>} icon={<LocalMallIcon style={{ color: 'gray', fontSize: 20 }} />} />
              }
              { bottomNavIndex === 2
                ?<BottomNavigationAction component={Link} to={'/mypage'} onClick={ (event) => event.preventDefault() } disableRipple  label={<span className="bottom-nav-text">My위나</span>} icon={<AccountCircleIcon style={{ color: '#006042', fontSize: 20 }} />} />
                :<BottomNavigationAction component={Link} to={'/mypage'} onClick={ () => {
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} disableRipple  label={<span className="bottom-nav-text">My위나</span>} icon={<AccountCircleIcon style={{ color: 'gray', fontSize: 20 }} />} />
              }


            </BottomNavigation>
          </Row>
        </div>
    );
  }
}



export default BottomNav;
