import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import PulseLoader from "react-spinners/PulseLoader";

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";
import Stepper from 'react-simple-stepper-component';
import {
  BrowserRouter as Router, Route, NavLink, Link, Redirect
} from 'react-router-dom';
import Switch from "react-switch";
import BeatLoader from "react-spinners/BeatLoader";
import { Row, Image } from 'react-bootstrap';

import { loadTossPayments } from '@tosspayments/sdk';

import * as firebase from 'firebase';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';
import regularIcon from '../Regular.png';
import regularPauseIcon from '../RegularPause.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';

import Postcode from 'react-daum-postcode';

import Footer from './Footer';

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const GrayCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: 'gray',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class RegularDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,

      regular: null,

      firstLanded: false,

      twoEggScones: false,
      twoNunettineScones: false,
      plainSauce: false,
      twoPlainSauces: false,

      paused: false,
      totalPrice: 0,
      payPrice: 0,

      showPostModal: false,
      isChangingAddress: false,
      detailAddressInputError: false,
      receiverInputError: false,
      phoneNumberInputError: false,
      editFullAddress: '',
      editDetailAddress: '',
      editReceiver: '',
      editPhoneNumber: '',
      doneEnterAddress: false,
      editPostCode: '',
      tempFullAddress: '',
      tempPostCode: '',

      isEdditing: false,

      editedSnack: false,
      addTwoEggSconesSnack: false,
      removeTwoEggSconesSnack: false,
      addTwoNunettineSconesSnack: false,
      removeTwoNunettineSconesSnack: false,
      addPlainSauceSnack: false,
      removePlainSauceSnack: false,
      addTwoPlainSaucesSnack: false,
      changePlainSauceSnack: false,
      changeTwoPlainSaucesSnack: false,
      removeTwoPlainSaucesSnack: false,
      cancelRegularSnack: false,
      restartRegularSnack: false,

      showCancelRegularModal: false,

      gotInvoiceData: {}
    }
  }


  componentWillMount() {
    firebase.database().ref(`regular`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({regular: snapshot.val()})
      }
    })
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        if (nextProps.userInfo.regular) {

          firebase.database().ref(`regular`).once('value', (snapshot) => {
            if (snapshot.val()) {
              let regular = snapshot.val()
              let totalPrice = regular.selections.basicPackage.price
              let payPrice = regular.selections.basicPackage.price
              if (regular['subscribers'][nextProps.userInfo.uid].addition) {
                if (regular['subscribers'][nextProps.userInfo.uid].addition['twoEggScones']) {
                  this.setState({twoEggScones: true})
                  payPrice = payPrice + this.onCalculateDiscount(regular.selections.eggScone.price, regular.selections.eggScone.discount)
                  totalPrice = totalPrice + regular.selections.eggScone.price
                }
                if (regular['subscribers'][nextProps.userInfo.uid].addition['twoNunettineScones']) {
                  this.setState({twoNunettineScones: true})
                  payPrice = payPrice + this.onCalculateDiscount(regular.selections.nunettineScone.price, regular.selections.nunettineScone.discount)
                  totalPrice = totalPrice + regular.selections.nunettineScone.price
                }
                if (regular['subscribers'][nextProps.userInfo.uid].addition['plainSauce']) {
                  this.setState({plainSauce: true})
                  payPrice = payPrice + this.onCalculateDiscount(regular.selections.plainSauce1.price, regular.selections.plainSauce1.discount)
                  totalPrice = totalPrice + regular.selections.plainSauce1.price
                }
                if (regular['subscribers'][nextProps.userInfo.uid].addition['twoPlainSauces']) {
                  this.setState({twoPlainSauces: true})
                  payPrice = payPrice + this.onCalculateDiscount(regular.selections.plainSauce2.price, regular.selections.plainSauce2.discount)
                  totalPrice = totalPrice + regular.selections.plainSauce2.price
                }
              }

              if (!this.state.firstLanded) {
                this.setState({totalPrice: totalPrice, payPrice: payPrice, firstLanded: true})
              }
              this.setState({regular: regular})
            }
          })
        }
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일 ${morningOrAfternoon} ${hours}시${minutes}분`
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onStringNumberToYYYYMM(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월"
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {
      return sconeMenu[a.itemId].order - sconeMenu[b.itemId].order
    })
    return returnValue
  }

  calendarArr(today) {
    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;

    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 25}} >
                  </td>
              )
            }
            if (this.state.regular.nextPayDate.toString() === days.format('YYYYMMDD')) {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 25, borderRadius: 50}} >
                  <span style={{color: 'white', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            } else if ((this.state.regular.nextDeliveryDate).toString() === days.format('YYYYMMDD')) {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'rgba(0, 113, 212, 0.95)', height: 25, borderRadius: 50}} >
                  <span style={{color: 'white', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            } else {
              return (
                <td className="table-style" key={index} style={{backgroundColor:'wthie', height: 25}} >
                  <span style={{color: 'black', marginLeft: 7, fontSize: 12}}>{days.format('D')}</span>
                </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  onStringNumberToYYYYMMDD(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "- " + stringNumber.substring(4, 6) + "- " + stringNumber.substring(6, 8)
  }

  async onChangingRegularCard() {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)

    tossPayments.requestBillingAuth('카드', {
      customerKey: this.state.userInfo.uid,
      successUrl: window.location.origin + '/regular_card_change_result/success',
      failUrl: window.location.origin + '/regular_card_change_result/fail',
    }).catch((error) => {
    })
  }

  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect, from } = queryObj;

    let levelDesc = [
      '상품인수', '상품이동', '지점도착', '배송출발', '배송완료'
    ]

    if (this.state.isLoading) {
      return <div></div>
    }
    let regular = this.state.userInfo['regular']
    let gotInvoiceData = this.state.gotInvoiceData
    return (
      <div  style={{marginBottom: 70}}>
        <Grid style={this.state.isEdditing ? {pointerEvents: "none", opacity: "0.4"} : {}} container justify="center">
          <Grid container className="back-button-container" justify="center">
          <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>정기 구독 상세</p>
          <Link
            to={'/mypage'}
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </Link>
          </Grid>
          { this.state.userInfo['regular']
            ?<div style={{marginTop: 80}} className="vertical-center-align direction-column-div auto-width-100">
              { !regular.pause
                ?<Image style={{margin: 20}} src={regularIcon} width={150} height={150} fluid />
                :<Image style={{margin: 20}} src={regularPauseIcon} width={150} height={150} fluid />
              }
              <label>
                <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                        onChange={() => {
                          if (regular.pause) {
                            let userInfoObj = this.state.userInfo
                            userInfoObj['regular']['pause'] = false
                            this.setState({paused: false, userInfo: userInfoObj, restartRegularSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/pause`).set(false)
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/pause`).set(false)
                          } else {
                            this.setState({showCancelRegularModal: true})
                          }

                        }} checked={!regular.pause} />
              </label>
              { regular.pause
                ?<span style={{fontWeight: 'bold', fontSize: 15}}>구독 쉬는 중</span>
                :<span style={{fontWeight: 'bold', fontSize: 15}}>구독중</span>
              }
              { regular.pause &&
                <span style={{color: 'red', fontSize: 13}}>구독을 쉬는 중에는 결제되지 않습니다.</span>
              }
              <Divider className="auto-width-100" style={{marginTop: 30}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>상품 구성</span>
                <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
                <span style={{fontWeight: 'bold', marginLeft: 20}}>스콘</span>
                <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', marginLeft: 20}} row>
                    <FormControlLabel
                      control={
                        <GrayCheckbox checked={true} onChange={() => {
                        }} value="basicPlainScones" />
                      }
                      label={<span style={{ fontSize: 13 }}>플레인 스콘 6개</span>}
                    />
                  </FormGroup>
                  { this.state.regular &&
                    <span style={{fontSize: 14, marginRight: 5, marginTop: 12}}>{this.onChangeCurrencyFormat(this.state.regular.selections.basicPackage.price)}원</span>
                  }
                </div>
                <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', marginLeft: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.twoEggScones}  onChange={() => {
                          let totalPrice = this.state.totalPrice
                          let payPrice = this.state.payPrice
                          if (this.state.twoEggScones) {
                            payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount)
                            totalPrice = totalPrice - this.state.regular.selections.eggScone.price
                            this.setState({twoEggScones: false, payPrice: payPrice, totalPrice: totalPrice, removeTwoEggSconesSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoEggScones`).remove()
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoEggScones`).remove()
                          } else {
                            payPrice = payPrice + this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount)
                            totalPrice = totalPrice + this.state.regular.selections.eggScone.price
                            this.setState({twoEggScones: true, payPrice: payPrice, totalPrice: totalPrice, addTwoEggSconesSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoEggScones`).set(true)
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoEggScones`).set(true)
                          }
                        }} value="twoEggScones" />
                      }
                      label={<span style={{ fontSize: 13 }}>에그 스콘 2개</span>}
                    />
                  </FormGroup>
                  { this.state.regular &&
                    <div style={{marginTop: 10}}>
                      <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.eggScone.price, this.state.regular.selections.eggScone.discount))}원</span>
                      <span className="strike" style={{fontSize: 14, color: 'red'}}>{this.onChangeCurrencyFormat(this.state.regular.selections.eggScone.price)}원</span>
                    </div>
                  }

                </div>
                <div className="direction-row-div" style={{marginBottom: 20}}>
                  <FormGroup  style={{alignItems: 'center', marginLeft: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.twoNunettineScones}  onChange={() => {
                          let totalPrice = this.state.totalPrice
                          let payPrice = this.state.payPrice
                          if (this.state.twoNunettineScones) {
                            payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount)
                            totalPrice = totalPrice - this.state.regular.selections.nunettineScone.price
                            this.setState({twoNunettineScones: false, payPrice: payPrice, totalPrice: totalPrice, removeTwoNunettineSconesSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoNunettineScones`).remove()
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoNunettineScones`).remove()
                          } else {
                            payPrice = payPrice + this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount)
                            totalPrice = totalPrice + this.state.regular.selections.nunettineScone.price
                            this.setState({twoNunettineScones: true, payPrice: payPrice, totalPrice: totalPrice, addTwoNunettineSconesSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoNunettineScones`).set(true)
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoNunettineScones`).set(true)
                          }
                        }} value="twoNunettineScones" />
                      }
                      label={<span style={{ fontSize: 13 }}>누네띠네 스콘 2개</span>}
                    />
                  </FormGroup>
                  { this.state.regular &&
                    <div style={{marginTop: 10}}>
                      <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.nunettineScone.price, this.state.regular.selections.nunettineScone.discount))}원</span>
                      <span className="strike" style={{fontSize: 14, color: 'red'}}>{this.onChangeCurrencyFormat(this.state.regular.selections.nunettineScone.price)}원</span>
                    </div>
                  }

                </div>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>소스 세트</span>
                <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', marginLeft: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.plainSauce}  onChange={() => {
                          let totalPrice = this.state.totalPrice
                          let payPrice = this.state.payPrice
                          if (this.state.plainSauce) {
                            payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount)
                            totalPrice = totalPrice - this.state.regular.selections.plainSauce1.price
                            this.setState({plainSauce: false, payPrice: payPrice, totalPrice: totalPrice, removePlainSauceSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/plainSauce`).remove()
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/plainSauce`).remove()
                          } else {
                            payPrice = payPrice + this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount)
                            totalPrice = totalPrice + this.state.regular.selections.plainSauce1.price
                            if (this.state.twoPlainSauces) {
                              payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount)
                              totalPrice = totalPrice - this.state.regular.selections.plainSauce2.price
                              firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoPlainSauces`).remove()
                              firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoPlainSauces`).remove()
                              this.setState({twoPlainSauces: false, changePlainSauceSnack: true})
                            } else {
                              this.setState({addPlainSauceSnack: true})
                            }
                            this.setState({plainSauce: true, payPrice: payPrice, totalPrice: totalPrice})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/plainSauce`).set(true)
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/plainSauce`).set(true)
                          }
                        }} value="plainSauce" />
                      }
                      label={<span style={{ fontSize: 13 }}>클로티드 크림 + 딸기 콩포트 1세트</span>}
                    />
                  </FormGroup>
                  {  this.state.regular &&
                    <div style={{marginTop: 10}}>
                      <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount))}원</span>
                      <span className="strike" style={{fontSize: 14, color: 'red'}}>{this.onChangeCurrencyFormat(this.state.regular.selections.plainSauce1.price)}원</span>
                    </div>
                  }

                </div>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>OR</span>
                <div className="direction-row-div">
                  <FormGroup  style={{alignItems: 'center', marginLeft: 20}} row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox checked={this.state.twoPlainSauces}  onChange={() => {
                          let totalPrice = this.state.totalPrice
                          let payPrice = this.state.payPrice
                          if (this.state.twoPlainSauces) {
                            payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount)
                            totalPrice = totalPrice - this.state.regular.selections.plainSauce2.price
                            this.setState({twoPlainSauces: false, payPrice: payPrice, totalPrice: totalPrice, removeTwoPlainSaucesSnack: true})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoPlainSauces`).remove()
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoPlainSauces`).remove()
                          } else {
                            payPrice = payPrice + this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount)
                            totalPrice = totalPrice + this.state.regular.selections.plainSauce2.price
                            if (this.state.plainSauce) {
                              payPrice = payPrice - this.onCalculateDiscount(this.state.regular.selections.plainSauce1.price, this.state.regular.selections.plainSauce1.discount)
                              totalPrice = totalPrice - this.state.regular.selections.plainSauce1.price
                              firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/plainSauce`).remove()
                              firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/plainSauce`).remove()
                              this.setState({plainSauce: false, changeTwoPlainSaucesSnack: true})
                            } else {
                              this.setState({addTwoPlainSaucesSnack: true})
                            }
                            this.setState({twoPlainSauces: true, payPrice: payPrice, totalPrice: totalPrice})
                            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/addition/twoPlainSauces`).set(true)
                            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/addition/twoPlainSauces`).set(true)
                          }
                        }} value="twoPlainSauces" />
                      }
                      label={<span style={{ fontSize: 13 }}>클로티드 크림 + 딸기 콩포트 2세트</span>}
                    />
                  </FormGroup>
                  { this.state.regular &&
                    <div style={{marginTop: 10}}>
                      <span style={{fontSize: 14, marginRight: 5}}>{this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.regular.selections.plainSauce2.price, this.state.regular.selections.plainSauce2.discount))}원</span>
                      <span className="strike" style={{fontSize: 14, color: 'red'}}>{this.onChangeCurrencyFormat(this.state.regular.selections.plainSauce2.price)}원</span>
                    </div>
                  }

                </div>
              </div>
              <div className="auto-width-100">
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <div className="auto-width-100 direction-row-div  justify-between">
                  <span style={{fontWeight: 'bold', marginLeft: 20}}>배송지 정보</span>
                  <span onClick={() => {
                    this.setState({
                      showPostModal: true,
                      editFullAddress: regular.deliveryAddress.fullAddress,
                      editPostCode: regular.deliveryAddress.postCode,
                      editDetailAddress: regular.deliveryAddress.detailAddress,
                      editReceiver: regular.deliveryAddress.receiver,
                      editPhoneNumber: regular.deliveryAddress.phoneNumber
                    })
                  }} style={{fontSize: 14, marginRight: 20, textDecoration: 'underline', cursor: 'pointer'}}>배송지 수정</span>
                </div>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는사람</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{regular.deliveryAddress.receiver}</span>
                </div>
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>연락처</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{this.onChangePhoneNumberFormat(regular.deliveryAddress.phoneNumber)}</span>
                </div>
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는주소</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20, paddingRight: 10}}>[{regular.deliveryAddress.postCode}] {regular.deliveryAddress.fullAddress} {regular.deliveryAddress.detailAddress}</span>
                </div>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <div className="auto-width-100 direction-row-div  justify-between">
                  <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 카드 정보</span>
                  <span onClick={() => {
                    this.onChangingRegularCard()

                  }} style={{fontSize: 14, marginRight: 20, textDecoration: 'underline', cursor: 'pointer'}}>카드 변경</span>
                </div>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>카드회사</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{regular.paymentsInfo.cardCompany}</span>
                </div>
                <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>카드번호</span>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{regular.paymentsInfo.cardNumber}</span>
                </div>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 금액 정보</span>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
                <div className="auto-width-100 direction-row-div  justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품금액</span>
                  <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                </div>
                { this.state.totalPrice - this.state.payPrice > 0 &&
                  <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                    <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>할인금액</span>
                    <span className="break-word" style={{fontSize: 13, color: 'red', marginRight: 20}}>-{this.onChangeCurrencyFormat(this.state.totalPrice - this.state.payPrice)}원</span>
                  </div>
                }

                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
                  <span className="break-word" style={{fontSize: 13, marginRight: 20}}>무료</span>
                </div>
                <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>총 결제 예정 금액</span>
                  <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(this.state.payPrice)}원</span>
                </div>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>결제일 및 발송일</span>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
                <div className="auto-width-100 direction-row-div  justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>결제일</span>
                  { this.state.regular &&
                    <span style={{fontSize: 13, marginRight: 20}}>{this.onStringNumberToDate(this.state.regular.nextPayDate)}</span>
                  }

                </div>
                <div className="auto-width-100 direction-row-div  justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                  <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>발송일</span>
                  { this.state.regular &&
                    <span style={{fontSize: 13, marginRight: 20}}>{this.onStringNumberToDate(this.state.regular.nextDeliveryDate)}</span>
                  }

                </div>
              </div>

              <Grid container justify="center" className="auto-width-100 align-center direction-column-div">
              { this.state.regular &&
                <span style={{fontSize: 14, fontWeight: 'bold', marginBottom: 10}}>{this.onStringNumberToDate(this.state.regular.nextPayDate).substring(0, 9)}</span>
              }

              { this.state.regular
                ?<table style={{width: 230}}>
                  <tbody>
                    { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                        return (
                          <td className="day-table-style" key={index} style={{height: 25}} >
                            { index === 0
                              ?<span style={{fontSize: 12, marginLeft: 7, color: 'red'}}>{day}</span>
                              :<span style={{fontSize: 12, marginLeft: 7}}>{day}</span>
                            }

                          </td>
                        )
                      })
                    }
                    {this.calendarArr(moment(this.onStringNumberToYYYYMMDD(this.state.regular.nextPayDate.toString())))}
                  </tbody>
                </table>
                :<div style={{height: 205}}>
                </div>
              }

              </Grid>
              <Grid className="auto-width-100 center-align" container justify="center" direction="row" style={{marginBottom: 40, marginTop: 10}} >
                <div className="auto-width-100 direction-row-div" style={{width: 230, paddingLeft: 15}}>
                  <div style={{backgroundColor: 'rgba(0, 135, 117, 0.95)', width: 13, height: 13}}>
                  </div>
                  <span style={{fontSize: 10, marginLeft: 5}}>결제일</span>
                  <div style={{backgroundColor: 'rgba(0, 113, 212, 0.95)', width: 13, height: 13, marginLeft: 20}}>
                  </div>
                  <span style={{fontSize: 10, marginLeft: 5}}>택배 발송일</span>
                </div>
              </Grid>
              <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>주문/배송 정보</span>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
                { this.state.userInfo.regular['deliveries']
                  ?<div>
                    { Object.values(this.state.userInfo.regular['deliveries']).reverse().map((item, index) => {
                      if (item.invoiceNumber && !gotInvoiceData[item.payDate]) {
                        gotInvoiceData[item.payDate] = true
                        this.setState({gotInvoiceData: gotInvoiceData})
                        if (!item.hasDelivered) {
                          if (item.trackingTime) {
                            if (item.trackingTime + (1000 * 60 * 30) < Date.now()) {
                              let providerCode = '06'
                              if (item['providerCode']) {
                                providerCode = item['providerCode']
                              }
                              axios({
                                url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${item.invoiceNumber}`,
                                method: "get",
                                headers: {
                                  "Content-Type":"application/json"
                                }
                              }).then((data) => {
                                let deliveries = this.state.userInfo.regular['deliveries']
                                let userInfo = this.state.userInfo
                                if (data.data.status !== false) {
                                  userInfo.regular.deliveries[item.payDate]['deiliveryInfo'] = data.data
                                }
                                if (data.data.level === 6) {
                                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/hasDelivered`).set(true)
                                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/hasDelivered`).set(true)
                                  if (data.data.status !== false) {
                                    firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                                    firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                                  }

                                  userInfo.regular.deliveries[item.payDate]['hasDelivered'] = true
                                } else {
                                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/trackingTime`).set(Date.now())

                                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/trackingTime`).set(Date.now())
                                  if (data.data.status !== false) {
                                    firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                                    firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                                  }

                                }
                                this.setState({userInfo: userInfo}, () => {
                                  setTimeout(() => {
                                    this.props.onRefreshUserInfo()
                                  }, 10)
                                })
                              })
                            }
                          } else {
                            let providerCode = '06'
                            if (item['providerCode']) {
                              providerCode = item['providerCode']
                            }
                            axios({
                              url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${item.invoiceNumber}`,
                              method: "get",
                              headers: {
                                "Content-Type":"application/json"
                              }
                            }).then((data) => {
                              let deliveries = this.state.userInfo.regular['deliveries']
                              let userInfo = this.state.userInfo
                              if (data.data.status !== false) {
                                userInfo.regular.deliveries[item.payDate]['deiliveryInfo'] = data.data
                              }
                              if (data.data.level === 6) {
                                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/hasDelivered`).set(true)
                                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/hasDelivered`).set(true)
                                if (data.data.status !== false) {
                                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                                }

                                userInfo.regular.deliveries[item.payDate]['hasDelivered'] = true
                              } else {
                                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/trackingTime`).set(Date.now())
                                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/trackingTime`).set(Date.now())
                                if (data.data.status !== false) {
                                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                                }

                              }
                              this.setState({userInfo: userInfo}, () => {
                                setTimeout(() => {
                                  this.props.onRefreshUserInfo()
                                }, 10)
                              })
                            })
                          }

                        }
                      }


                        return (
                          <Link className="none-underline-text order-list-card relative-position"  onClick={() => {
                            setTimeout(() => {
                              this.props.routeChanges()
                            }, 10)
                          }} to={`/regular_order_detail/${item.payDate}`}>
                            <div className="auto-width-100 direction-column-div" style={{margin: 20}}>
                              <span style={{fontSize: 14, fontWeight: 'bold', marginBottom: 5}}>{this.onStringNumberToYYYYMM(item.deliveryDate)} 배송건</span>
                                <div className="auto-width-100 direction-column-div">
                                <span style={{fontSize: 13, marginRight: 5}}>기본 패키지</span>
                                  { item.addition &&
                                    <span style={{fontSize: 13, marginRight: 5}}>스콘 {Object.keys(item.addition).length * 2}개 추가</span>
                                  }
                                </div>
                              <span style={{fontSize: 13, marginTop: 5}}>{this.onChangeCurrencyFormat(item.paidPrice)}원</span>
                              { item['invoiceNumber'] && item['deiliveryInfo']
                              ?!item['hasDelivered']
                              ?<div>
                                <div className="align-center justify-center relative-position row-between-space-92" style={{height: 40}}>
                                  { item['deiliveryInfo']['level'] > 2
                                    ?<Divider style={{backgroundColor: '#006042'}} className="first-delivery-process-line" />
                                    :<Divider className="first-delivery-process-line" />
                                  }
                                  { item['deiliveryInfo']['level'] > 3
                                    ?<Divider style={{backgroundColor: '#006042'}} className="second-delivery-process-line" />
                                    :<Divider className="second-delivery-process-line" />
                                  }
                                  { item['deiliveryInfo']['level'] > 4
                                    ?<Divider style={{backgroundColor: '#006042'}} className="third-delivery-process-line" />
                                    :<Divider className="third-delivery-process-line" />
                                  }
                                  { item['deiliveryInfo']['level'] > 5
                                    ?<Divider style={{backgroundColor: '#006042'}} className="fourth-delivery-process-line" />
                                    :<Divider className="fourth-delivery-process-line" />
                                  }
                                  { item['deiliveryInfo']['level'] > 1
                                    ?<div className="background-signiture-color z-index-100" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                    :<div className="z-index-100 background-disable-color" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                  }
                                  { item['deiliveryInfo']['level'] > 2
                                    ?<div className="background-signiture-color z-index-100" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                    :<div className="z-index-100 background-disable-color" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                  }
                                  { item['deiliveryInfo']['level'] > 3
                                    ?<div className="background-signiture-color z-index-100" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                    :<div className="z-index-100 background-disable-color" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                  }
                                  { item['deiliveryInfo']['level'] > 4
                                    ?<div className="background-signiture-color z-index-100" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                    :<div className="z-index-100 background-disable-color" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                  }
                                  { item['deiliveryInfo']['level'] > 5
                                    ?<div className="background-signiture-color z-index-100" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                    :<div className="z-index-100 background-disable-color" style={{width: 12, height: 12, borderRadius: 7}}>
                                    </div>
                                  }
                                </div>
                                <div className="align-center justify-center relative-position row-between-space-100" style={{height: 10}}>
                                  <span style={{fontSize: 11}}>{levelDesc[0]}</span>
                                  <span style={{fontSize: 11}}>{levelDesc[1]}</span>
                                  <span style={{fontSize: 11}}>{levelDesc[2]}</span>
                                  <span style={{fontSize: 11}}>{levelDesc[3]}</span>
                                  <span style={{fontSize: 11}}>{levelDesc[4]}</span>
                                </div>
                              </div>
                              :<span style={{marginTop: 5, fontSize: 12, color: '#006042', fontWeight: 'bold'}}>배송 완료</span>
                              :<span style={{marginTop: 5, fontSize: 12, color: '#006042', fontWeight: 'bold'}}>상품 준비중</span>
                              }
                              </div>
                          </Link>
                        )
                      })
                    }
                  </div>
                  :<Grid className="auto-width-100 center-align" container justify="center" direction="row" style={{marginBottom: 30, marginTop: 30}} >
                  <span className="break-word" style={{fontSize: 13}}>아직 결제된 정기배송 건이 없습니다.</span>
                  </Grid>
                }
              </div>
              </div>
              <Footer />
            </div>
            :<div className="no-items-main-view">
              <FontAwesomeIcon style={{color: '#a1a1a1', fontSize: 60}} icon={faGift} />
              <span style={{color: '#a1a1a1', fontSize: 16, margin: 5}}>정기 구독 신청 내역이 없습니다.</span>
            </div>
          }
        </Grid>
        <Modal
          open={this.state.showCancelRegularModal}
          onClose={() => this.setState({showCancelRegularModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>구독을 쉬어가시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showCancelRegularModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            let userInfoObj = this.state.userInfo
            userInfoObj['regular']['pause'] = true
            this.setState({paused: false, userInfo: userInfoObj})
            firebase.database().ref(`users/${this.state.userInfo.uid}/regular/pause`).set(true)
            firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/pause`).set(true)
            .then(() => {
              if (this.props.onRefreshUserInfo) {
                this.props.onRefreshUserInfo()
              }
            })
            this.setState({showCancelRegularModal: false, cancelRegularSnack: true})
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>구독 쉬기</span>
          </div>
          </Grid>
          </div>
        </Modal>

        <Modal
          open={this.state.showPostModal}
          onClose={() => {
            this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.editFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 수정</span>
            <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.editFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({editDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.editDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({editReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.editReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({editPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.editPhoneNumber)} />
            <a onClick={() => {
              if (this.state.editDetailAddress) {
                if (this.state.editReceiver) {
                  if (this.state.editPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, phoneNumberInputError: false, receiverInputError: false,  doneEnterAddress: true, isEdditing: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.editDetailAddress,
                      fullAddress: this.state.editFullAddress,
                      postCode: this.state.editPostCode,
                      receiver: this.state.editReceiver,
                      phoneNumber: this.state.editPhoneNumber
                    }

                    firebase.database().ref(`users/${this.state.userInfo.uid}/regular/deliveryAddress`).set(with_a_nod_delivery_address)
                    .then(() => {
                      let userInfo = this.state.userInfo
                      userInfo['regular']['deliveryAddress'] = with_a_nod_delivery_address

                      setTimeout(() => {
                        this.setState({isEdditing: false, editedSnack: true})
                      }, 1000)
                    })
                    firebase.database().ref(`regular/subscribers/${this.state.userInfo.uid}/deliveryAddress`).set(with_a_nod_delivery_address)

                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>수정</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              this.setState({editFullAddress: fullAddress, isChangingAddress: false, editPostCode: data.zonecode})
            }}
          />
        }

        </Modal>
        { this.state.isEdditing &&
          <div className="fixed-center">
          <div className="vertical-center-align direction-column-div auto-width-100">
          <BeatLoader  color={'#006042'} loading={true} size={20} />
          <span style={{fontSize: 14, marginTop: 10}}>정보 수정 중...</span>
          </div>
          </div>
        }
        <Snackbar open={this.state.editedSnack} autoHideDuration={5000} onClose={() => this.setState({editedSnack: false})}>
          <Alert  onClose={() => this.setState({editedSnack: false})} severity="success">
            정기 구독 정보가 수정되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.addTwoEggSconesSnack} autoHideDuration={5000} onClose={() => this.setState({addTwoEggSconesSnack: false})}>
          <Alert  onClose={() => this.setState({addTwoEggSconesSnack: false})} severity="success">
            에그 스콘 2개가 추가되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.removeTwoEggSconesSnack} autoHideDuration={5000} onClose={() => this.setState({removeTwoEggSconesSnack: false})}>
          <Alert  onClose={() => this.setState({removeTwoEggSconesSnack: false})} severity="error">
            에그 스콘 2개가 취소되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.addTwoNunettineSconesSnack} autoHideDuration={5000} onClose={() => this.setState({addTwoNunettineSconesSnack: false})}>
          <Alert  onClose={() => this.setState({addTwoNunettineSconesSnack: false})} severity="success">
            누네띠네 스콘 2개가 추가되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.removeTwoNunettineSconesSnack} autoHideDuration={5000} onClose={() => this.setState({removeTwoNunettineSconesSnack: false})}>
          <Alert  onClose={() => this.setState({removeTwoNunettineSconesSnack: false})} severity="error">
            누네띠네 스콘 2개가 취소되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.changePlainSauceSnack} autoHideDuration={5000} onClose={() => this.setState({changePlainSauceSnack: false})}>
          <Alert  onClose={() => this.setState({changePlainSauceSnack: false})} severity="success">
            클로티드 크림 + 딸기 콩포트 1세트로 변경되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.addPlainSauceSnack} autoHideDuration={5000} onClose={() => this.setState({addPlainSauceSnack: false})}>
          <Alert  onClose={() => this.setState({addPlainSauceSnack: false})} severity="success">
            클로티드 크림 + 딸기 콩포트 1세트가 추가되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.removePlainSauceSnack} autoHideDuration={5000} onClose={() => this.setState({removePlainSauceSnack: false})}>
          <Alert  onClose={() => this.setState({removePlainSauceSnack: false})} severity="error">
            클로티드 크림 + 딸기 콩포트 1세트가 취소되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.changeTwoPlainSaucesSnack} autoHideDuration={5000} onClose={() => this.setState({changeTwoPlainSaucesSnack: false})}>
          <Alert  onClose={() => this.setState({changeTwoPlainSaucesSnack: false})} severity="success">
            클로티드 크림 + 딸기 콩포트 2세트로 변경되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.addTwoPlainSaucesSnack} autoHideDuration={5000} onClose={() => this.setState({addTwoPlainSaucesSnack: false})}>
          <Alert  onClose={() => this.setState({addTwoPlainSaucesSnack: false})} severity="success">
            클로티드 크림 + 딸기 콩포트 2세트가 추가되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.removeTwoPlainSaucesSnack} autoHideDuration={5000} onClose={() => this.setState({removeTwoPlainSaucesSnack: false})}>
          <Alert  onClose={() => this.setState({removeTwoPlainSaucesSnack: false})} severity="error">
            클로티드 크림 + 딸기 콩포트 2세트가 취소되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.cancelRegularSnack} autoHideDuration={5000} onClose={() => this.setState({cancelRegularSnack: false})}>
          <Alert  onClose={() => this.setState({cancelRegularSnack: false})} severity="info">
            기다리고 있을게요! 다음에 다시 꼭 만나요! :)
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.restartRegularSnack} autoHideDuration={5000} onClose={() => this.setState({restartRegularSnack: false})}>
          <Alert  onClose={() => this.setState({restartRegularSnack: false})} severity="success">
            돌아오셨군요! 감사합니다! 맛있게 구워 보내드릴게요. :)
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

export default withRouter(RegularDetail);
