import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink,  Link
} from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CreateIcon from '@material-ui/icons/Create';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {withRouter} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from "react-switch";
import MuiAlert from '@material-ui/lab/Alert';

import Modal from '@material-ui/core/Modal';

import shortid from 'shortid';

import { Image, Col, Row, Container} from 'react-bootstrap';


import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';
import roundIcon from '../roundIcon.png';

import moment from 'moment';

import * as firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let QRCode = require('qrcode.react');



class Alarm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo,
      alarms: {},

      firstGetData: true,

      setSconeDeliveryAlarm: false,

      firstSetSconeDeliveryAlarmSnack: false,
      firstSetSconeDeliveryAlarm: true
    }
  }


  componentWillMount() {
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    firebase.database().ref(`users/${this.state.userInfo.uid}/alarms`).off('value')
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.onGetAlarms(nextProps.userInfo)
      }
      this.setState({ userInfo: nextProps.userInfo });
    } else {
      this.setState({ isLoading: true });
    }

  }

  onGetAlarms(userInfo) {
    if (this.state.firstGetData) {
      this.setState({firstGetData: false})
      firebase.database().ref(`users/${userInfo.uid}/alarms`).on('value', (snapshot) => {
        if (snapshot.val()) {
          let alarms = snapshot.val()
          if (alarms) {
            if (alarms['sconeDelivery']) {
              this.setState({firstSetSconeDeliveryAlarm: false})
              if (alarms['sconeDelivery'].set) {
                this.setState({setSconeDeliveryAlarm: true})
              }
            }
          }
          this.setState({ isLoading: false });

        } else {
          this.setState({ isLoading: false });
        }
      })
    }

  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  onSetOnSconeDeliveryAlarm() {
    firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let data = snapshot.val()
        data['onDate'] = Date.now()
        data['set'] = true
        firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
        firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)

        this.setState({setSconeDeliveryAlarm: true})
      } else {
        if (this.state.userInfo.phoneNumber) {
          let data = {
            onDate: Date.now(),
            set: true,
            uid: this.state.userInfo.uid,
            phoneNumber: this.state.userInfo.phoneNumber,
          }
          firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
          firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
          this.setState({firstSetSconeDeliveryAlarmSnack: true, firstSetSconeDeliveryAlarm: false})
          let couponId = shortid.generate()
          if (this.state.userInfo.phoneNumber) {
            this.onIssueOnlineCoupon(couponId, this.state.userInfo.phoneNumber, 'scone', moment(Date.now() + (24 * 1000 * 60 * 60 * 30)).format('YYYYMMDD').toString(), '스콘 택배 할인', 'discount')
          }
          this.setState({setSconeDeliveryAlarm: true})
        }

      }
    })
  }

  onSetOffSconeDeliveryAlarm() {
    firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let data = snapshot.val()
        data['offDate'] = Date.now()
        data['set'] = false
        firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
        firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
        this.setState({setSconeDeliveryAlarm: false})
      } else {
        if (this.state.userInfo.phoneNumber) {
          let data = {
            offDate: Date.now(),
            set: false,
            uid: this.state.userInfo.uid,
            phoneNumber: this.state.userInfo.phoneNumber,
          }
          firebase.database().ref(`alarms/sconeDelivery/${this.state.userInfo.uid}`).set(data)
          firebase.database().ref(`users/${this.state.userInfo.uid}/alarms/sconeDelivery`).set(data)
          this.setState({setSconeDeliveryAlarm: false})
        }

      }
    })
  }

  onIssueOnlineCoupon(couponId, couponTo, category, expireDate, name, type) {
    let couponInfo = {}
    if (category === 'scone') {
      couponInfo = {
        couponId: couponId,
        issuedAt: Date.now(),
        category: category,
        expireDate: Number(expireDate),
        name: name,
        type: type,
        phoneNumber: couponTo.toString(),
        amount: 3000
      }
    }

    firebase.database().ref(`onlineCoupons/${couponId}`).set(couponInfo)
  }

  render() {

    return (
      <Grid container className="auto-width-100 no-selection">
        <Grid container className="back-button-container" justify="center">
          <p style={{fontWeight: 'bold', fontSize: 18, margin: 10, marginTop: 20}}>알림 설정</p>
          <Link
            to={'/mypage'}
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                } else {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  })
                }
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </Link>
        </Grid>
        { !this.state.isLoading &&
          <Grid className="auto-width-100" style={{margin: 20, marginTop: 70}}>
            <Grid container className="auto-width-100 no-selection relative-position">
              <div className="direction-column-div">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>스콘 택배 알림</span>
              <span style={{fontSize: 12}}>스콘 택배가 오픈되면 알림을 보내드립니다.</span>
              { this.state.firstSetSconeDeliveryAlarm &&
                <span style={{fontSize: 8, color: '#006042'}}>(첫 알림 설정시 스콘 택배 3,000원 할인쿠폰을 드립니다.)</span>
              }

              </div>
              <label style={{position: 'absolute', right: 0, top: 10}}>
                <Switch onColor="#006042" height={20} width={40} uncheckedIcon={false} checkedIcon={false}
                        onChange={() => {
                          if (this.state.setSconeDeliveryAlarm) {
                            this.onSetOffSconeDeliveryAlarm()
                          } else {
                            this.onSetOnSconeDeliveryAlarm()
                          }

                        }} checked={this.state.setSconeDeliveryAlarm} />
              </label>
            </Grid>
          </Grid>
        }

        <Snackbar open={this.state.firstSetSconeDeliveryAlarmSnack} autoHideDuration={4000} onClose={() => this.setState({firstSetSconeDeliveryAlarmSnack: false})}>
          <Alert  onClose={() => this.setState({firstSetSconeDeliveryAlarmSnack: false})} severity="success">
            My위나 탭에서 온라인 쿠폰을 확인하세요.
          </Alert>
        </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(Alarm);
