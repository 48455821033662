import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';
import * as firebase from 'firebase';
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import giftBox from '../GiftBox.png';
import cardPayment from '../CardPayment.png';
import freeShipping from '../FreeShipping.png';
import discount from '../Discount.png';
import noConcern from '../noConcern.jpg';

class Regular extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      regular: null
    }
  }


  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }




  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    if (true) {
      return (
        <div className="center-div direction-column-div">
          <Image style={{width: 250}} src={buildingWeb} />
          <span className="text-center" style={{fontSize: 16, fontWeight: 'bold', marginTop: 15}}>정기 구독 서비스를 준비 중입니다.</span>
        </div>
      )
    }
    return (
      <div style={{marginBottom: 50}}>
        <Grid className="main-view auto-width-100 no-selection" style={{paddingBottom: 0}} container justify="center">
          <Grid container alignItems="center" justify="center" direction="column">
            <p style={{fontWeight: 'bold', fontSize: 18, margin: 0}}>위나의 인생 스콘이</p>
            <p style={{fontWeight: 'bold', fontSize: 18, margin: 0}}>여러분의 하루를 특별하게 만들어드립니다.</p>
            <Image style={{marginTop: 20}} src={plainSconeIcon} width={150} height={160} fluid />
            <p style={{fontWeight: 'bold', fontSize: 15, margin: 0, marginTop: 20}}>수제 클로티드 크림, 딸기 콩포트와 함께</p>
            <p style={{fontWeight: 'bold', fontSize: 15, margin: 0}}>찐~한 고메버터 풍미를 느낄 수 있는</p>
            <p style={{fontWeight: 'bold', fontSize: 15, margin: 0}}>최고급 고메스콘을 경험해보세요.</p>
            <Divider style={{width: 50, height: 10, borderRadius: 10, margin: 35}} />
            <p style={{fontSize: 23, fontWeight: 'bold', margin: 0}}>왜 정기구독일까?</p>
            <p style={{fontSize: 14,  margin: 0, marginBottom: 0, marginBottom: 30}}>정기구독을 신청해야하는 이유.</p>
            <Grid container alignItems="center" justify="center" direction="column" className="auto-width-100">
              <Image src={cardPayment} width={80} height={80} fluid />
            </Grid>
            <span style={{fontSize: 13, fontWeight: 'bold', marginTop: 10}}>1. 매번 번거로운 주문/결제 없이도</span>
            <span style={{fontSize: 13, fontWeight: 'bold'}}>매달 맛있는 스콘이 배달돼요.</span>
            <Grid container alignItems="center" justify="center" direction="column" className="auto-width-100" style={{marginTop: 40}}>
              <Image src={discount} width={80} height={80} fluid />
            </Grid>
            <span style={{fontSize: 13, fontWeight: 'bold', marginTop: 15}}>2. 구성품 추가시 추가 할인이 있어요.</span>
            <Grid container alignItems="center" justify="center" direction="column" className="auto-width-100" style={{marginTop: 40}}>
              <Image src={giftBox} width={70} height={70} fluid />
            </Grid>
            <span style={{fontSize: 13, fontWeight: 'bold', marginTop: 15}}>3. 고급스러운 포장으로</span>
            <span style={{fontSize: 13, fontWeight: 'bold'}}>한 달에 한 번 선물 받는 기분이 들어요.</span>
          </Grid>
          <Divider style={{width: 50, height: 10, borderRadius: 10, margin: 35}} />
          <div className="auto-width-100 direction-column-div align-center">
          <p style={{fontSize: 23, fontWeight: 'bold', margin: 0, marginBottom: 0}}>걱정 마세요!</p>
          <p style={{fontSize: 14,  margin: 0, marginBottom: 0}}>구독자분들께 드리는 위나의 약속.</p>
          <Image src={noConcern} className="auto-width-80" fluid />
          </div>
        </Grid>
        <Link className="none-underline-text btn-bottom-sticky no-selection"  onClick={() => {
          setTimeout(() => {
            this.props.routeChanges()
          }, 10)
        }} to={`/regular_start`}>
          <p style={{color: 'white', fontSize: 14}}>상품 구성 알아보기</p>
        </Link>
        <Footer/>
      </div>
    )
  }
}

export default Regular;
