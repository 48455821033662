import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {withRouter} from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Postcode from 'react-daum-postcode';

import shortid from 'shortid';

import { Image, Col, Row } from 'react-bootstrap';
import Input from '@material-ui/core/Input';

import moment from 'moment';

import {v4 as uuid} from 'uuid';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import StickyBox from "react-sticky-box";

import ProgressBar from "@ramonak/react-progress-bar";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { loadTossPayments } from '@tosspayments/sdk';

import { withStyles } from '@material-ui/core/styles';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';

import * as firebase from 'firebase';

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class DeliveryCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      isLoadingProducts: false,
      oneSecondLock: false,
      initialLanding: true,
      userInfo: this.props.userInfo,
      orderSheet: null,
      eventScone: null,
      openOrderSheet: null,
      showPostModal: false,
      showRemoveModal: false,
      showAddModal: false,
      willRemoveItem: {},
      totalAmount: 0,
      totalPrice: 0,
      selectedScones: {},
      sconeMenu: {},
      fullAddress: '',
      postCode: '',
      detailAddress: '',
      receiver: '',
      phoneNumber: '',

      noAddressSnack: false,

      deliveryAddress: {},
      deliveryAddresses: [],

      tempFullAddress: '',
      tempDetailAddress: '',
      tempPostCode: '',
      tempReceiver: '',
      tempPhoneNumber: '',
      tempPhoneNumberValue: '',

      editFullAddress: '',
      editDetailAddress: '',
      editPostCode: '',
      editReceiver: '',
      editPhoneNumber: '',
      editPhoneNumberValue: '',
      editIndex: 0,

      detailAddressInputError: false,
      doneEnterAddress: false,
      phoneNumberValue: '',
      isChangingAddress: false,
      isSelectingAddress: false,
      deliveryAddressIndex: 0,

      defaultAddressIndex: null,
      tempDefaultAddressIndex: null,

      removeIndex: 0,

      isPurchasing: false,
      noMoreSconesSnack: false,
      changeRemainsSnack: false,
      changeGiftBoxRemainsSnack: false,

      changeRemains: '',
      notAbleToOrderSnack: false,

      isOpened: false,

      deliveryCoupons: null,
      firstGetCoupons: true,
      usingCoupon: null,

      couponExpireDateSnack: false,

      notEnoughMinPrice: false,

      deliveryMessage: null,
      deliveryMessages: [],
      remoingIndex: 0,

      showCoupons: false,

      freePaymentVerifying: false,
      alreadyUsed: false,
      freePayment: false,

      additionalItems: null,

      items: null,

      horizontalScrollPosition: 0,

      categoryUrl: {
        spread: 'spread',
        milkTeaBase: 'milk_tea_base',
        sconeMix: 'scone_mix'
      },

      firstPurchaseFree: false,

      discountRate: 1,
      userClass: null,
      classArr: [
        'WHITE', 'SILVER', 'GOLD', 'VIP'
      ],
      userClassDiscountRate: 1,
      hasPurchasedLastMonthAmount: 0,
      sumOfPurchaseAmount: 0,
      lastMonthUserClass: null,

      specialItem: false,

      giftBoxAmount: 0
    }
    this.couponRef = React.createRef()
  }


  componentWillMount() {
    if (this.props.totalAmount) {
      this.setState({totalAmount: this.props.totalAmount})
    }
    if (this.props.selectedScones) {
      this.setState({selectedScones: this.props.selectedScones})
    }
    if (this.props.totalPrice) {
      this.setState({totalPrice: this.props.totalPrice})
    }

    if (this.props.giftBoxAmount) {
      this.setState({giftBoxAmount: this.props.giftBoxAmount})
    }

    if (this.props.orderWithAdditionalItems) {
      this.setState({orderWithAdditionalItems: this.props.orderWithAdditionalItems})
    }




    let openOrderSheet = {}

    firebase.database().ref(`items`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({items: snapshot.val()})
      } else {
        this.setState({items: null})
      }
    })


    firebase.database().ref(`openOrderSheet`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({openOrderSheet: snapshot.val()})
        openOrderSheet = snapshot.val()
        firebase.database().ref(`orderSheets/${snapshot.val()}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val().eventScone) {
              this.setState({eventScone: snapshot.val().eventScone})

            }
          }
        }).then(() => {
          firebase.database().ref(`orderSheets/${openOrderSheet}/menu`).on('value', (snapshot) => {
            if (snapshot.val()) {
              let menu = snapshot.val()
              this.onCheckNumberOfScones(this.props.selectedScones, menu)
            }
          })
        })

        firebase.database().ref(`orderSheets/${snapshot.val()}/open`).on('value', (snapshot) => {
          let isOpened = snapshot.val()
          this.setState({isOpened: isOpened})
        })
        firebase.database().ref(`orderSheets/${snapshot.val()}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val().eventScone) {
              this.setState({eventScone: snapshot.val().eventScone})
            }
            if (snapshot.val().discount) {
              if (snapshot.val().discount['until']) {
                if (snapshot.val().discount['until'] > Date.now()) {
                  this.setState({discountRate: 1 - snapshot.val().discount['rate']})
                }
              } else {
                this.setState({discountRate: 1 - snapshot.val().discount['rate']})
              }
            }
            this.setState({orderSheet: snapshot.val()})
            setTimeout(() => {
              if (this.props.selectedScones) {
                this.onCheckSpecialItem(this.props.selectedScones)
              }
            }, 50)
          }
        }).then(() => {
          firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
            if (snapshot.val()) {
              this.setState({sconeMenu: snapshot.val()})
            }
          })
        })
      } else {
        firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({sconeMenu: snapshot.val()})
          }
        })
      }
    })
  }

  async onCheckSpecialItem(selectedScones) {
    if (selectedScones && this.state.orderSheet) {
      let orderSheet = this.state.orderSheet
      let specialItem = false
      if (orderSheet['specialItems']) {
        await Object.keys(orderSheet['specialItems']).map(async(item, index) => {
          if (selectedScones[item]) {
            specialItem = true
          }
        })
      }
      this.setState({specialItem: specialItem})
    }
  }

  onCheckNumberOfScones(selectedSconesObj, menu) {
    let orderSheet = this.state.orderSheet
    let selectedScones = selectedSconesObj
    if (orderSheet) {
      orderSheet['menu'] = menu
      this.setState({orderSheet: orderSheet})
    }
    let totalAmount = 0
    let totalPrice = 0
    let changeRemains = ''
    let locked = false
    if (this.state.giftBoxAmount !== 0) {
      let giftBoxRemainsAmount = menu['yUcEOQHrz'].amount
      if (giftBoxRemainsAmount < this.state.giftBoxAmount) {
        if (this.props.onSetGiftBox) {
          this.props.onSetGiftBox(giftBoxRemainsAmount)
        }
        this.setState({changeGiftBoxRemainsSnack: true, giftBoxAmount: giftBoxRemainsAmount})
      }
    }

    Object.values(selectedSconesObj).map((selectedScone, index) => {
      let difference = 0
      if (selectedScone.amount > menu[selectedScone.itemId].amount) {
        difference = selectedScone.amount - menu[selectedScone.itemId].amount
        if (changeRemains) {
          changeRemains = changeRemains + ', ' + selectedScone.name
        } else {
          changeRemains = changeRemains + selectedScone.name
        }

        selectedScones[selectedScone.itemId].amount = selectedScone.amount - difference
      }

      if (this.state.eventScone) {
        if (this.state.eventScone.id === selectedScone.itemId) {
          totalPrice = totalPrice + ((selectedScone.amount - 1) * selectedScone.price) + this.state.eventScone.price
        } else {
          totalPrice = totalPrice + (selectedScone.amount * selectedScone.price)
        }
      } else {
        totalPrice = totalPrice + (selectedScone.amount * selectedScone.price)
      }


      totalAmount = totalAmount + selectedScone.amount

      if (Object.values(selectedSconesObj).length === index + 1) {
        let selectedSconeLength = Object.values(selectedScones).length
        Object.values(selectedScones).map((scone, innderIndex) => {
          if (scone.amount === 0) {
            delete selectedScones[scone.itemId]
          }
          if (selectedSconeLength === innderIndex + 1) {
            if (this.props.onSelectScone) {
              if (this.props.giftBoxAmount) {
                this.props.onSelectScone(totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice), totalAmount, selectedScones, 1)
              } else {
                this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 1)
              }

              if (changeRemains) {
                changeRemains = changeRemains + ' 잔여수량에 변동이 있습니다.'
                this.setState({changeRemainsSnack: true, changeRemains: changeRemains})
              }
              if (this.props.giftBoxAmount) {
                this.setState({totalAmount: totalAmount, totalPrice: totalPrice + (this.props.giftBoxAmount * this.props.giftBoxPrice), selectedScones: selectedScones})
              } else {
                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
              }

            }
          }

        })

      }
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    firebase.database().ref(`orderSheets/${this.state.openOrderSheet}/menu`).off('value')
    firebase.database().ref(`orderSheets/${this.state.openOrderSheet}/open`).off('value')
    this.props.onSelectScone(this.state.totalPrice, this.state.totalAmount, this.state.selectedScones, 1)
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onCalculateDiscountAmount(price, rate) {
    let totalPrice = price * (1 - rate)
    return price - Number(Math.floor(totalPrice/10) * 10)
  }

  async onGetUserClass(orders, itemOrders, uid) {
    let ordersList = Object.values(orders)
    let itemOrdersList = Object.values(itemOrders)
    let userClassDiscountRate = 1
    let totalAmount = 0
    let hasPurchasedLastMonthAmount = 0
    let userClass = 0
    let lastMonthUserClass = 0

    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    if (ordersList.length > 0) {
      await ordersList.map(async(order, index) => {

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + order.paidPrice
          }
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((order['orderSheet']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((order['orderSheet']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + order.paidPrice
            }
          }

        }

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((order['orderSheet']).toString().slice(4, 6)) === 12)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((order['orderSheet']).toString().slice(4, 6)) === 1)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
          }
        } else {
          if (Number((order['orderSheet']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 1 <= Number((order['orderSheet']).toString().slice(4, 6)))) {
              hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + order.paidPrice
            }
          }

        }

      })
    }

    itemOrdersList = itemOrdersList.filter(itemOrdersList => itemOrdersList.paid === true)
    if (itemOrdersList.length > 0) {
      await itemOrdersList.map(async(itemOrder, index) => {
        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 11)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            totalAmount = totalAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 2 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6))) && (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) !== Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              totalAmount = totalAmount + itemOrder.paidPrice
            }
          }

        }

        if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 1) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)) - 1)) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 12)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
          }
        } else if (Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) === 2) {
          if ((Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) && (Number((itemOrder['purchasedDate']).toString().slice(4, 6)) === 1)) {
            hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
          }
        } else {
          if (Number((itemOrder['purchasedDate']).toString().slice(0, 4)) === (Number((moment(Date.now()).format('YYYYMMDD')).slice(0, 4)))) {
            if ((Number((moment(Date.now()).format('YYYYMMDD')).slice(4, 6)) - 1 <= Number((itemOrder['purchasedDate']).toString().slice(4, 6)))) {
              hasPurchasedLastMonthAmount = hasPurchasedLastMonthAmount + itemOrder.paidPrice
            }
          }

        }

      })
    }




    if (totalAmount >= 50000) {
      userClass = 1
      userClassDiscountRate = 0.97
    }
    if (totalAmount >= 100000) {
      userClass = 2
      userClassDiscountRate = 0.95
    }
    if (totalAmount >= 200000) {
      userClass = 3
      userClassDiscountRate = 0.90
    }


    if (hasPurchasedLastMonthAmount >= 50000) {
      lastMonthUserClass = 1
    }
    if (hasPurchasedLastMonthAmount >= 100000) {
      lastMonthUserClass = 2
    }
    if (hasPurchasedLastMonthAmount >= 200000) {
      lastMonthUserClass = 3
    }

    this.setState({userClass: userClass, userClassDiscountRate: userClassDiscountRate, hasPurchasedLastMonthAmount: hasPurchasedLastMonthAmount, lastMonthUserClass: lastMonthUserClass})
  }

  onGetNumberOfPurchase(orders) {
    let ordersList = Object.values(orders)
    ordersList = ordersList.filter(ordersList => ordersList.paid === true)
    return ordersList.length
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.reloadPage) {
      window.location.reload(false)
    }

    if (nextProps.isLoading === false) {

      if (nextProps.userInfo) {
        let sconeOrders = {}
        let itemOrders = {}
        if (nextProps.userInfo) {
          if (nextProps.userInfo['sconeOrders']) {
            sconeOrders = nextProps.userInfo['sconeOrders']
          }
          if (nextProps.userInfo['itemOrders']) {
            itemOrders = nextProps.userInfo['itemOrders']
          }
          this.onGetUserClass(sconeOrders, itemOrders, nextProps.userInfo.uid)
        }


        if (nextProps.userInfo.deliveryMessages) {
          this.setState({deliveryMessages: nextProps.userInfo.deliveryMessages, deliveryMessage: nextProps.userInfo.deliveryMessages[0]})
        }

        if (nextProps.userInfo.coupons && this.state.firstGetCoupons) {
          let coupons = Object.values(nextProps.userInfo.coupons)
          coupons = coupons.filter(coupons => coupons.category === 'scone')
          coupons = coupons.filter(coupons => !coupons.usedAt)
          coupons = coupons.filter(coupons => coupons.expireDate.toString() >= moment(Date.now()).format('YYYYMMDD').toString())
          if (coupons.length) {
            this.setState({deliveryCoupons: coupons, firstGetCoupons: false})
          } else {
            this.setState({deliveryCoupons: null, firstGetCoupons: false})
          }
        }

        if (nextProps.userInfo && this.state.orderSheet) {
          if (this.state.orderSheet['firstPurchaseFree']) {
            if (nextProps.userInfo['sconeOrders']) {
              if (this.onGetNumberOfPurchase(nextProps.userInfo['sconeOrders']) === 0) {
                this.setState({firstPurchaseFree: true})
              } else {
                this.setState({firstPurchaseFree: false})
              }
            } else {
              this.setState({firstPurchaseFree: true})
            }
          } else {
            this.setState({firstPurchaseFree: false})
          }
        }

        this.setState({userInfo: nextProps.userInfo, isLoading: false})
        if (this.state.initialLanding) {
          let deliveryAddress = null

          if (nextProps.userInfo.deliveryAddress) {
            this.setState({deliveryAddresses: nextProps.userInfo.deliveryAddress})
          }

          if (this.props.deliveryAddressIndex || this.props.deliveryAddressIndex === 0) {
            this.setState({deliveryAddressIndex: this.props.deliveryAddressIndex})
            if (nextProps.userInfo.deliveryAddress) {
              deliveryAddress = nextProps.userInfo.deliveryAddress[this.props.deliveryAddressIndex]
            }
            if (nextProps.userInfo.defaultAddressIndex || nextProps.userInfo.defaultAddressIndex === 0) {
              this.setState({defaultAddressIndex: nextProps.userInfo.defaultAddressIndex, tempDefaultAddressIndex: nextProps.userInfo.defaultAddressIndex})
            }
          } else {
            if (nextProps.userInfo.defaultAddressIndex || nextProps.userInfo.defaultAddressIndex === 0) {
              this.setState({defaultAddressIndex: nextProps.userInfo.defaultAddressIndex, tempDefaultAddressIndex: nextProps.userInfo.defaultAddressIndex, deliveryAddressIndex: nextProps.userInfo.defaultAddressIndex})
              if (nextProps.userInfo.deliveryAddress) {
                deliveryAddress = nextProps.userInfo.deliveryAddress[nextProps.userInfo.defaultAddressIndex]
              }
            } else {
              if (nextProps.userInfo.deliveryAddress) {
                deliveryAddress = nextProps.userInfo.deliveryAddress[0]
              }
            }
          }



          if (deliveryAddress) {
            this.setState({
              detailAddress: deliveryAddress.detailAddress,
              fullAddress: deliveryAddress.fullAddress,
              postCode: deliveryAddress.postCode,
              receiver: deliveryAddress.receiver,
              phoneNumber: deliveryAddress.phoneNumber,
              tempFullAddress: deliveryAddress.fullAddress,
              tempPostCode: deliveryAddress.postCode,
              tempDetailAddress: deliveryAddress.detailAddress,
              tempReceiver: deliveryAddress.receiver,
              tempPhoneNumber: deliveryAddress.phoneNumber,
              doneEnterAddress: true,
            })
          }
          this.setState({initialLanding: false})
        }
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      } else {
        this.props.history.replace('/login?redirect=/delivery_cart');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  phoneFormat(phone) {
    let value = phone.replace(/[^0-9]/g, '')
    this.setState({
      tempPhoneNumber: value.replace(/[^0-9]/g, ''),
      tempPhoneNumberValue: this.onChangePhoneNumberFormat(value)
    })
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone.substr(7,4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
    } else if (phone.substr(3, 4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
    } else {
      value = phone.substr(0, 3)
    }
    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  async onPaying(amount, orderId, orderName, customerName) {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    firebase.database().ref(`orderSheets/${this.state.openOrderSheet}/menu`).off('value')
    const tossPayments = await loadTossPayments(clientKey)

    if (this.props.totalAmount) {
      let saveSessionData = {
        totalPrice: this.props.totalPrice,
        selectedScones: this.props.selectedScones,
        totalAmount: this.props.totalAmount
      }
      sessionStorage.setItem('deliveryData', JSON.stringify(saveSessionData));
    }

    tossPayments.requestPayment('카드', {
      amount: amount,
      orderId: orderId,
      orderName: orderName,
      customerName: customerName,
      successUrl: window.location.origin + '/delivery_payment_result/success',
      failUrl: window.location.origin + '/delivery_payment_result/fail',
    }).catch((error) => {
      if (error.code === 'USER_CANCEL') {
        sessionStorage.removeItem('deliveryData')
        firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).remove()
        firebase.database().ref(`orderSheets/${this.state.openOrderSheet}/menu`).on('value', (snapshot) => {
          if (snapshot.val()) {
            let menu = snapshot.val()
            this.onCheckNumberOfScones(this.props.selectedScones, menu)
          }
        })
      }
    })
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetTotalPrice(cart) {
    let result = Object.values(cart).reduce((a, b) => a + b.price * b.amount, 0)
    return result
  }

  onGetTotalAmount(items) {
    let result = Object.values(items).reduce((a, b) => a + b.amount, 0)
    return result
  }

  onGetNextClass(hasPurchasedLastMonthAmount) {
    let sumOfPurchaseAmount = hasPurchasedLastMonthAmount
    let userClass = 0
    if (this.state.firstPurchaseFree) {
      if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
        if (this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000) {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))
          }
        } else {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))
          }
        }
      } else {
        if (this.state.totalPrice >= 50000) {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))
          }
        } else {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))
          }
        }
      }
    } else {
      if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
        if (this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000) {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))
          }
        } else {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge))
          }
        }
      } else {
        if (this.state.totalPrice >= 50000) {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))
          }
        } else {
          if (this.state.usingCoupon) {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))
          } else {
            sumOfPurchaseAmount = sumOfPurchaseAmount + parseInt((this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge))
          }
        }
      }
    }
    if (sumOfPurchaseAmount >= 50000) {
      userClass = 1
    }
    if (sumOfPurchaseAmount >= 100000) {
      userClass = 2
    }
    if (sumOfPurchaseAmount >= 200000) {
      userClass = 3
    }
    return [this.state.classArr[userClass], sumOfPurchaseAmount]
  }


  render() {
    let totalPrice = this.state.totalPrice
    let totalAmount = this.state.totalAmount
    let selectedScones = this.state.selectedScones
    let levelDesc = [
      'WHITE', 'SILVER', 'GOLD', 'VIP'
    ]
    if (this.state.isLoading) {
      return <div></div>
    }

    if (this.state.freePaymentVerifying) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>결제 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.alreadyUsed) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>이미 사용된 쿠폰입니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>택배 주문 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.freePayment) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>결제 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>주문 목록 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }

    if (this.state.isSelectingAddress) {
      return (
        <Grid container justify="center" style={{marginBottom: 50, paddingTop: 70}}>
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>배송지 목록</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', right: 20, top: 20,}}
          onClick={() => {
            this.setState({isSelectingAddress: false})
          }}
        >
          <CloseIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        <div className="direction-row-div right-top-position">
        <AddIcon  style={{color: 'black', fontSize: 20, margin: 1}} />
          <span onClick={() => {
            this.setState({
              showAddModal: true,
              tempFullAddress: '',
              tempPostCode: '',
              tempDetailAddress: '',
              tempReceiver: '',
              tempPhoneNumber: '',
            })
          }} className="underline-text">배송지 추가</span>
        </div>
          { this.state.deliveryAddresses.map((address, index) => {
            return (
              <Grid onClick={() => {
                this.setState({
                  deliveryAddressIndex: index,
                  detailAddress: address.detailAddress,
                  fullAddress: address.fullAddress,
                  postCode: address.postCode,
                  receiver: address.receiver,
                  phoneNumber: address.phoneNumber,
                  tempFullAddress: address.fullAddress,
                  tempPostCode: address.postCode,
                  tempDetailAddress: address.detailAddress,
                  tempReceiver: address.receiver,
                  tempPhoneNumber: address.phoneNumber,
                  doneEnterAddress: true,
                  isSelectingAddress: false
                })
                if (this.props.onSelectDeliveryAddress) {
                  this.props.onSelectDeliveryAddress(index)
                }
              }} container direction="column" className={this.state.deliveryAddressIndex === index ? "delivery-selection-selected" : "delivery-selection"}>
                { this.state.defaultAddressIndex === index &&
                  <span className="address-info-content badge-style" style={{fontSize: 12, marginBottom: 3, color: 'white'}}>기본 배송지</span>
                }
                <div className="address-info-wrap direction-row-div">
                  <span className="address-info-content direction-row-div" style={{fontSize: 16, fontWeight: 'bold', marginBottom: 3}}>{address.receiver} [{address.postCode}]</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-content break-word">{address.fullAddress}{' '}{address.detailAddress}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-content" style={{marginLeft: 1}}>{this.onChangePhoneNumberFormat(address.phoneNumber)}</span>
                </div>
                <div className="direction-row-div" style={{marginTop: 5}}>
                  <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      showPostModal: true,
                      editFullAddress: address.fullAddress,
                      editPostCode: address.postCode,
                      editDetailAddress: address.detailAddress,
                      editReceiver: address.receiver,
                      editPhoneNumber: address.phoneNumber,
                      editIndex: index
                    })
                  }} className="normal-small-button-outlined">
                    <span style={{fontSize: 13, color: '#006042'}}>수정</span>
                  </div>
                  <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({removeIndex: index, showRemoveAddressModal: true})

                  }} className="normal-small-button-outlined">
                    <span style={{fontSize: 13, color: '#006042'}}>삭제</span>
                  </div>
                </div>

              </Grid>
            )
          })
          }
          <Modal
            open={this.state.showRemoveAddressModal}
            onClose={() => this.setState({showRemoveAddressModal: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
            >
            <div className="remove-item-modal">
            <span>해당 배송지를 삭제하시겠습니까?</span>
            <Grid container direction="row" style={{marginTop: 40}}>
            <div className="modal-button-outlined" onClick={() => {
              this.setState({showRemoveAddressModal: false})
            }}>
            <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
            </div>
            <div className="modal-button-filled"  onClick={() => {
              let deliveryAddresses = this.state.deliveryAddresses
              let userInfo = this.state.userInfo
              deliveryAddresses.splice(this.state.removeIndex, 1);
              userInfo['deliveryAddress'] = deliveryAddresses
              this.setState({deliveryAddresses: deliveryAddresses, userInfo: userInfo})
              firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddresses)
              if (this.state.removeIndex === this.state.defaultAddressIndex) {
                this.setState({defaultAddressIndex: null, tempDefaultAddressIndex: null})
                firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).remove()
              } else {
                if (this.state.removeIndex < this.state.defaultAddressIndex) {
                  this.setState({defaultAddressIndex: this.state.defaultAddressIndex - 1, tempDefaultAddressIndex: this.state.defaultAddressIndex - 1})
                  firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.defaultAddressIndex - 1)
                }
              }

              if (deliveryAddresses.length === 0) {
                delete userInfo.deliveryAddress
                this.setState({
                  detailAddress: '',
                  fullAddress: '',
                  postCode: '',
                  receiver: '',
                  phoneNumber: '',
                  tempFullAddress: '',
                  tempPostCode: '',
                  tempDetailAddress: '',
                  tempReceiver: '',
                  tempPhoneNumber: '',
                  doneEnterAddress: false,
                  isSelectingAddress: false,
                  tempDefaultAddressIndex: null,
                  defaultAddressIndex: null,
                  userInfo: userInfo
                })
              } else {
                if (this.state.removeIndex === this.state.deliveryAddressIndex) {
                  this.setState({
                    deliveryAddressIndex: 0,
                    detailAddress: deliveryAddresses[0].detailAddress,
                    fullAddress: deliveryAddresses[0].fullAddress,
                    postCode: deliveryAddresses[0].postCode,
                    receiver: deliveryAddresses[0].receiver,
                    phoneNumber: deliveryAddresses[0].phoneNumber,
                    tempFullAddress: deliveryAddresses[0].detailAddress,
                    tempPostCode: deliveryAddresses[0].fullAddress,
                    tempDetailAddress: deliveryAddresses[0].postCode,
                    tempReceiver: deliveryAddresses[0].receiver,
                    tempPhoneNumber: deliveryAddresses[0].phoneNumber
                  })
                } else {
                  if (this.state.deliveryAddresses.length === this.state.deliveryAddressIndex) {
                    this.setState({
                      deliveryAddressIndex: this.state.deliveryAddressIndex - 1,
                      detailAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].detailAddress,
                      fullAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].fullAddress,
                      postCode: deliveryAddresses[this.state.deliveryAddressIndex - 1].postCode,
                      receiver: deliveryAddresses[this.state.deliveryAddressIndex - 1].receiver,
                      phoneNumber: deliveryAddresses[this.state.deliveryAddressIndex - 1].phoneNumber,
                      tempFullAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].detailAddress,
                      tempPostCode: deliveryAddresses[this.state.deliveryAddressIndex - 1].fullAddress,
                      tempDetailAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].postCode,
                      tempReceiver: deliveryAddresses[this.state.deliveryAddressIndex - 1].receiver,
                      tempPhoneNumber: deliveryAddresses[this.state.deliveryAddressIndex - 1].phoneNumber
                    })
                  }
                }
              }
              this.setState({showRemoveAddressModal: false})
            }}>
            <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
            </div>
            </Grid>
            </div>
          </Modal>
          <Modal
            open={this.state.showAddModal}
            onClose={() => {
              this.setState({showAddModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
          >
          { this.state.tempFullAddress && !this.state.isChangingAddress
            ?<div className="enter-detail-address">
              <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
              <div className="modal-outside-button" onClick={() => this.setState({showAddModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
              <CloseIcon />
              </div>
              <TextField onClick={() => {
                this.setState({isChangingAddress: true})
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
              <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
                this.setState({tempDetailAddress: event.target.value})
                if (this.state.detailAddressInputError) {
                  this.setState({detailAddressInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

              <TextField error={this.state.receiverInputError} onChange={(event) => {
                this.setState({tempReceiver: event.target.value})
                if (this.state.receiverInputError) {
                  this.setState({receiverInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
              <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
                this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                if (this.state.phoneNumberInputError) {
                  this.setState({phoneNumberInputError: false})
                }
              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
              <a onClick={() => {
                if (this.state.tempDetailAddress) {
                  if (this.state.tempReceiver) {
                    if (this.state.tempPhoneNumber) {
                      this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                      let with_a_nod_delivery_address = {
                        detailAddress: this.state.tempDetailAddress,
                        fullAddress: this.state.tempFullAddress,
                        postCode: this.state.tempPostCode,
                        receiver: this.state.tempReceiver,
                        phoneNumber: this.state.tempPhoneNumber
                      }

                      this.setState({
                        detailAddress: this.state.tempDetailAddress,
                        fullAddress: this.state.tempFullAddress,
                        postCode: this.state.tempPostCode,
                        receiver: this.state.tempReceiver,
                        phoneNumber: this.state.tempPhoneNumber,
                      })
                      let userInfo = this.state.userInfo

                      if (this.state.deliveryAddresses.length !== 0) {
                        let deliveryAddress = this.state.deliveryAddresses
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['deliveryAddress'] = deliveryAddress

                        this.setState({deliveryAddresses: deliveryAddress, deliveryAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                          setTimeout(() => {
                            if (isEdge || isIE) {
                                window.scrollTo(0, document.body.scrollHeight)
                              } else {
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                          }, 10)
                        })
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress.push(with_a_nod_delivery_address)
                        this.setState({deliveryAddresses: deliveryAddress, deliveryAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                          setTimeout(() => {
                            if (isEdge || isIE) {
                                window.scrollTo(0, document.body.scrollHeight)
                              } else {
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }
                          }, 10)
                        })
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                      }
                    } else {
                      this.setState({phoneNumberInputError: true})
                    }
                  } else {
                    this.setState({receiverInputError: true})
                  }

                } else {
                  this.setState({detailAddressInputError: true})
                }

              }} className="modal-btn-bottom-big">
                <p style={{color: 'white'}}>등록</p>
              </a>
            </div>
            :<Postcode
              style={{ width: 400, height: 400 }}
              jsOptions={{ animated: true, hideMapBtn: true }}
              onComplete={data => {
                let fullAddress = data.address;
                let extraAddress = '';

                if (data.addressType === 'R') {
                  if (data.bname !== '') {
                    extraAddress += data.bname;
                  }
                  if (data.buildingName !== '') {
                    extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                  }
                  fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
                }
                if (fullAddress.indexOf('제주특별자치도') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else {
                  this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
                }

              }}
            />
          }

          </Modal>
          <Modal
            open={this.state.showPostModal}
            onClose={() => {
              this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
              this.setState({tempDefaultAddressIndex: this.state.defaultAddressIndex})
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}
          >
          { this.state.editFullAddress && !this.state.isChangingAddress
            ?<div className="enter-detail-address">
              <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
              <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
              <CloseIcon />
              </div>
              <TextField onClick={() => {
                this.setState({isChangingAddress: true})
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.editFullAddress} />
              <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
                this.setState({editDetailAddress: event.target.value})
                if (this.state.detailAddressInputError) {
                  this.setState({detailAddressInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.editDetailAddress} />

              <TextField error={this.state.receiverInputError} onChange={(event) => {
                this.setState({editReceiver: event.target.value})
                if (this.state.receiverInputError) {
                  this.setState({receiverInputError: false})
                }
              }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.editReceiver} />
              <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
                this.setState({editPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                if (this.state.phoneNumberInputError) {
                  this.setState({phoneNumberInputError: false})
                }
              }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.editPhoneNumber)} />
              <div className="auto-width-90">
              <FormGroup  style={{alignItems: 'center', margin: 10}} row>
                <FormControlLabel
                  control={
                    <GreenCheckbox checked={this.state.tempDefaultAddressIndex === this.state.editIndex}  onChange={() => {
                      if (this.state.tempDefaultAddressIndex === this.state.editIndex) {
                        this.setState({tempDefaultAddressIndex: null})
                      } else {
                        this.setState({tempDefaultAddressIndex: this.state.editIndex})
                      }
                    }} value="defaultAddress" />
                  }
                  label={<span style={{ fontSize: 14 }}>기본 배송지로 선정</span>}
                />
              </FormGroup>
              </div>
              <a onClick={() => {
                if (this.state.editDetailAddress) {
                  if (this.state.editReceiver) {
                    if (this.state.editPhoneNumber) {
                      this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                      let with_a_nod_delivery_address = {
                        detailAddress: this.state.editDetailAddress,
                        fullAddress: this.state.editFullAddress,
                        postCode: this.state.editPostCode,
                        receiver: this.state.editReceiver,
                        phoneNumber: this.state.editPhoneNumber
                      }

                      if (this.state.editIndex === this.state.deliveryAddressIndex) {
                        this.setState({
                          deliveryAddressIndex: this.state.editIndex,
                          detailAddress: this.state.editDetailAddress,
                          fullAddress: this.state.editFullAddress,
                          postCode: this.state.editPostCode,
                          receiver: this.state.editReceiver,
                          phoneNumber: this.state.editPhoneNumber,
                          tempFullAddress: this.state.editFullAddress,
                          tempPostCode: this.state.editPostCode,
                          tempDetailAddress: this.state.editDetailAddress,
                          tempReceiver: this.state.editReceiver,
                          tempPhoneNumber: this.state.editPhoneNumber
                        })
                      }

                      this.setState({defaultAddressIndex: this.state.tempDefaultAddressIndex})

                      let deliveryAddresses = this.state.deliveryAddresses
                      deliveryAddresses[this.state.editIndex] = with_a_nod_delivery_address
                      this.setState({deliveryAddresses: deliveryAddresses})

                      firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                        if (snapshot.val()) {
                          let deliveryAddress = snapshot.val()
                          deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                          firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                        } else {
                          let deliveryAddress = []
                          deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                          firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                        }
                      })
                    } else {
                      this.setState({phoneNumberInputError: true})
                    }
                  } else {
                    this.setState({receiverInputError: true})
                  }

                } else {
                  this.setState({detailAddressInputError: true})
                }

              }} className="modal-btn-bottom-big">
                <p style={{color: 'white'}}>등록</p>
              </a>
            </div>
            :<Postcode
              style={{ width: 400, height: 400 }}
              jsOptions={{ animated: true, hideMapBtn: true }}
              onComplete={data => {
                let fullAddress = data.address;
                let extraAddress = '';

                if (data.addressType === 'R') {
                  if (data.bname !== '') {
                    extraAddress += data.bname;
                  }
                  if (data.buildingName !== '') {
                    extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                  }
                  fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
                }
                if (fullAddress.indexOf('제주특별자치도') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                  alert('제주/도서산간 지역은 배송이 불가합니다.')
                } else {
                  this.setState({editFullAddress: fullAddress, isChangingAddress: false, editPostCode: data.zonecode})
                }

              }}
            />
          }

          </Modal>
        </Grid>
      )
    }
    return (
      <Grid container justify="center">
      <Grid container className="relative-position" justify="center">
      <Grid container className="back-button-container" justify="center">
      <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>디저트택배</p>
      <div
        item
        className="no-selection back-button"
        style={{position: 'absolute', left: 20, top: 20,}}
        onClick={() => {
          let currentPath = window.location.pathname
          this.props.history.goBack()

          let inervalFunction = setInterval(() => {
            if (currentPath === window.location.pathname) {
              this.props.history.goBack()
              setTimeout(() => {
                this.props.routeChanges()
              }, 10)
            } else {
              clearInterval(inervalFunction)
            }
          }, 100)
        }}
      >
        <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
      </div>
      </Grid>
      { this.state.totalAmount !== 0 &&
        <div style={{marginTop: 70}} className="cart-address">
        { this.state.doneEnterAddress
          ?<div onClick={() => {
            this.setState({isSelectingAddress: true})
          }} className="delivery-address relative-position">
              <span style={{fontWeight: 'bold', fontSize: 16, marginBottom: 10}}>배송지 정보</span>
              <Grid container direction="column">
                <div className="address-info-wrap">
                  <span className="address-info-title">받는사람</span>
                  <span className="address-info-content">{this.state.receiver}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-title">배송주소</span>
                  <span className="address-info-content break-word">{this.state.fullAddress}{' '}{this.state.detailAddress}</span>
                </div>
                <div className="address-info-wrap">
                  <span className="address-info-title">전화번호</span>
                  <span className="address-info-content">{this.onChangePhoneNumberFormat(this.state.phoneNumber)}</span>
                </div>
              </Grid>
              <Grid
                item
                className="no-selection"
                style={{position: 'absolute', top: 13, right: 13}}
              >
              <EditIcon style={{fontSize: 20}} />
              </Grid>
          </div>
          :<div onClick={() => {
            this.setState({showPostModal: true})
          }} className="enter-address no-selection">
            <span style={{fontSize: 12, color: '#5e5e5e'}}>배송지를 입력해주세요.</span>
            <LocalShippingIcon style={{fontSize: 17, color: '#5e5e5e', marginLeft: 5}}/>
          </div>
        }
        </div>
      }
      { this.state.doneEnterAddress && this.state.totalAmount !== 0 &&
        <span className="flex-start" style={{fontSize: 10, color: '#006042'}}>작성하신 배송지 정보가 잘못되어 오배송된 경우 환불 및 재발송이 어려울 수 있습니다.</span>
      }
      { this.state.giftBoxAmount !== 0 &&
        <Grid container justify="center">
        <p style={{fontSize: 13, margin: 0, padding: 20, paddingBottom: 0, color: 'black', marginTop: 10, fontWeight: 'bold'}}>디저트 박스 {this.state.giftBoxAmount}개가 추가되었습니다!</p>
        <p style={{fontSize: 11, margin: 0, padding: 50, paddingBottom: 0, paddingTop: 0, color: 'red'}}>롤을 제외한 스콘 혹은 쿠키를 박스당 6~8개 주문시 가능합니다. 디저트 개수가 부족하거나 초과될 시 디저트 박스가 취소될 수 있습니다.</p>
        </Grid>
      }
      { this.state.totalAmount
        ?<div className="auto-width-100" style={{marginTop: 15}}>
        { this.state.isLoadingProducts
          ?<Grid container justify="center">
            <span style={{fontSize: 12, color: '#5e5e5e', marginTop: 10}}>상품을 불러오고 있습니다...</span>
          </Grid>
          :<div>
          { Object.values(this.state.sconeMenu).map((item, index) => {
            if (this.state.orderSheet) {
              if (this.state.orderSheet.menu[item.id]) {
                if (this.state.selectedScones[item.id]) {
                  return (
                    <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                      <Grid item className="direction-row-div" justify="center" xs={4}>
                        <Image style={{width: 100, height: 100}} src={item.image} />
                      </Grid>
                      <Grid item xs={7}>
                      <div className="direction-row-div no-selection">
                      <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                      { this.state.eventScone &&
                        <div>
                        { this.state.eventScone.id === item.id &&
                          <p style={{fontSize: 12, color: 'red', margin: 0, padding: 0, marginLeft: 5}}>(첫 1개 {this.state.eventScone.price}원)</p>
                        }
                        </div>
                      }

                      </div>
                        <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                        <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                      </Grid>
                      <Grid
                        item
                        className="direction-row-div no-selection"
                        style={{position: 'absolute', right: 20, bottom: 0}}
                      >
                      { this.state.eventScone
                        ?<div>
                        { this.state.selectedScones[item.id]
                          ?this.state.eventScone.id === item.id
                          ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat((this.state.selectedScones[item.id].amount * item.price) - item.price + this.state.eventScone.price)}원</p>
                          :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                          :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                        }
                        </div>
                        :<div>
                        { this.state.selectedScones[item.id]
                          ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedScones[item.id].amount * item.price)}원</p>
                          :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                        }
                        </div>
                      }
                      { this.state.orderSheet.menu[item.id].limit &&
                        <span className="max-scone-number">최대 {this.state.orderSheet.menu[item.id].limit}개 구매 가능</span>
                      }
                      { this.state.orderSheet.menu[item.id].amount === 0
                        ?<div className="counter-button-div">
                          <div onClick={() => {
                          }} className="counter-button">
                            <RemoveIcon fontSize={"small"}/>
                          </div>
                          <div className="counter-style">
                            <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                          </div>
                          <div onClick={() => {
                          }} className="counter-button">
                          <AddIcon fontSize={"small"}/>
                          </div>
                        </div>
                        :<div className="counter-button-div">
                          <div onClick={() => {
                            let selectedScones = this.state.selectedScones
                            let sconeMenu = this.state.sconeMenu
                            let totalAmount = this.state.totalAmount
                            let totalPrice = this.state.totalPrice

                            if (selectedScones[item.id]) {
                              selectedScones[item.id] = {
                                itemId: item.id,
                                name: sconeMenu[item.id].name,
                                image: sconeMenu[item.id].image,
                                amount: selectedScones[item.id].amount - 1,
                                price: item.price
                              }
                              if (selectedScones[item.id].amount === 0) {
                                delete selectedScones[item.id]
                              }
                            }

                            if (totalAmount) {
                              totalAmount = totalAmount - 1
                              if (this.state.eventScone) {
                                if (this.state.eventScone.id === item.id) {
                                  if (selectedScones[item.id].amount === 0) {
                                    totalPrice = totalPrice - this.state.eventScone.price
                                  } else {
                                    totalPrice = totalPrice - item.price
                                  }
                                } else {
                                  totalPrice = totalPrice - item.price
                                }
                              } else {
                                totalPrice = totalPrice - item.price
                              }

                            }
                            if (this.props.onSelectScone) {
                              this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                            }
                            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                          }} className="counter-button">
                            <RemoveIcon fontSize={"small"}/>
                          </div>
                          <div className="counter-style">
                            <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedScones[item.id] ? this.state.selectedScones[item.id].amount : 0}</p>
                          </div>
                          <div onClick={() => {
                            let selectedScones = this.state.selectedScones
                            let totalAmount = this.state.totalAmount
                            let totalPrice = this.state.totalPrice
                            let sconeMenu = this.state.sconeMenu

                            if (selectedScones[item.id]) {
                              if (this.state.orderSheet.menu[item.id].limit) {
                                if (this.state.orderSheet.menu[item.id].limit > selectedScones[item.id].amount) {
                                  if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                    selectedScones[item.id] = {
                                      itemId: item.id,
                                      name: sconeMenu[item.id].name,
                                      image: sconeMenu[item.id].image,
                                      amount: selectedScones[item.id].amount + 1,
                                      price: item.price
                                    }
                                    if (totalAmount) {
                                      totalAmount = totalAmount + 1
                                      if (this.state.eventScone) {
                                        if (this.state.eventScone.id === item.id) {
                                          if (selectedScones[item.id].amount === 1) {
                                            totalPrice = totalPrice + this.state.eventScone.price
                                          } else {
                                            totalPrice = totalPrice + item.price
                                          }
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalAmount = 1
                                      if (this.state.eventScone) {
                                        if (this.state.eventScone.id === item.id) {
                                          if (selectedScones[item.id].amount === 1) {
                                            totalPrice = this.state.eventScone.price
                                          } else {
                                            totalPrice = item.price
                                          }
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    }
                                    if (this.props.onSelectScone) {
                                      this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                    }

                                    this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                  } else {
                                    this.setState({noMoreSconesSnack: false}, () => {
                                      setTimeout(() => {
                                        this.setState({noMoreSconesSnack: true})
                                      }, 10)
                                    })
                                  }
                                }
                              } else {
                                if (this.state.orderSheet.menu[item.id].amount > selectedScones[item.id].amount) {
                                  selectedScones[item.id] = {
                                    itemId: item.id,
                                    name: sconeMenu[item.id].name,
                                    image: sconeMenu[item.id].image,
                                    amount: selectedScones[item.id].amount + 1,
                                    price: item.price
                                  }
                                  if (totalAmount) {
                                    totalAmount = totalAmount + 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = totalPrice + this.state.eventScone.price
                                        } else {
                                          totalPrice = totalPrice + item.price
                                        }
                                      } else {
                                        totalPrice = totalPrice + item.price
                                      }
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalAmount = 1
                                    if (this.state.eventScone) {
                                      if (this.state.eventScone.id === item.id) {
                                        if (selectedScones[item.id].amount === 1) {
                                          totalPrice = this.state.eventScone.price
                                        } else {
                                          totalPrice = item.price
                                        }
                                      } else {
                                        totalPrice = item.price
                                      }
                                    } else {
                                      totalPrice = item.price
                                    }
                                  }
                                  if (this.props.onSelectScone) {
                                    this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                                  }

                                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                                } else {
                                  this.setState({noMoreSconesSnack: false}, () => {
                                    setTimeout(() => {
                                      this.setState({noMoreSconesSnack: true})
                                    }, 10)
                                  })
                                }
                              }

                            } else {
                              selectedScones[item.id] = {
                                itemId: item.id,
                                name: sconeMenu[item.id].name,
                                image: sconeMenu[item.id].image,
                                amount: 1,
                                price: item.price
                              }
                              if (totalAmount) {
                                totalAmount = totalAmount + 1
                                if (this.state.eventScone) {
                                  if (this.state.eventScone.id === item.id) {
                                    if (selectedScones[item.id].amount === 1) {
                                      totalPrice = totalPrice + this.state.eventScone.price
                                    } else {
                                      totalPrice = totalPrice + item.price
                                    }
                                  } else {
                                    totalPrice = totalPrice + item.price
                                  }
                                } else {
                                  totalPrice = totalPrice + item.price
                                }
                              } else {
                                totalAmount = 1
                                if (this.state.eventScone) {
                                  if (this.state.eventScone.id === item.id) {
                                    if (selectedScones[item.id].amount === 1) {
                                      totalPrice = this.state.eventScone.price
                                    } else {
                                      totalPrice = item.price
                                    }
                                  } else {
                                    totalPrice = item.price
                                  }
                                } else {
                                  totalPrice = item.price
                                }
                              }
                              if (this.props.onSelectScone) {
                                this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 0)
                              }
                              this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones})
                            }
                          }} className="counter-button">
                          <AddIcon fontSize={"small"}/>
                          </div>
                        </div>
                      }
                      </Grid>
                    </Grid>
                  )
                }

              }
            }

          })

          }
          </div>
        }

        </div>
        :<Grid container alignItems="center" justify="center" direction="column" style={{marginTop: 100}}>
          <AddShoppingCartIcon style={{fontSize: 100, color: '#adadad'}}/>
          <span style={{fontSize: 12, color: '#adadad'}}>맛있는 스콘들을 담아주세요.</span>
          <Link to={'/'} onClick={() => {
            this.props.deliveryInnerTabChanges(0)
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.props.deliveryTabViewPosition)
                } else {
                  window.scrollTo({
                    top: this.props.deliveryTabViewPosition,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 100)
          }}>
          <p className="underline-text" style={{fontSize: 14, color: '#006042', fontWeight: 'bold'}}>맛있는 스콘 담기.</p>
          </Link>
        </Grid>
      }

      { (Object.keys(this.props.additionalItems).length !== 0 && this.state.totalAmount)
        ?<div style={{marginBottom: 10}}>
          <div className="center-align" style={{marginLeft: 10}}>
            <FormGroup  style={{alignItems: 'center', margin: 10}} row>
              <FormControlLabel
                control={
                  <GreenCheckbox checked={this.props.orderWithAdditionalItems}  onChange={() => {
                    if (this.props.orderWithAdditionalItems) {
                      this.props.onSelectOrderWithAdditionalItems(false)
                    } else {
                      this.props.onSelectOrderWithAdditionalItems(true)
                    }

                  }} />
                }
                label={<span style={{ fontSize: 14 }}>장바구니 상품 함께 주문하기</span>}
              />
            </FormGroup>
          </div>
          <div className="center-align direction-column-div" style={!this.props.orderWithAdditionalItems ? {pointerEvents: "none", opacity: "0.2"} : {}}>
            { Object.values(this.props.additionalItems).map((item, index) => {
              return (
                <div className="direction-row-div align-center" style={{marginBottom: 10}}>
                  <Image style={{width: 40, height: 40, borderRadius: 5, marginRight: 5}} src={item.image} />
                  <span style={{fontSize: 13, fontWeight: 'bold'}}>{item.name} {item.amount}개 / {this.onChangeCurrencyFormat(item.amount * item.price)}원 </span>
                </div>
              )
            })

            }
          </div>
          <Grid container alignItems="center" justify="center" style={{marginTop: 15}}>
          <Link  onClick={() => {
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
          }} to={'/cart'} className={"no-selection none-underline-text"}>
            <span style={{fontSize: 14, textDecoration: 'underline'}}>장바구니 상품 수량 수정하기</span>
          </Link>
          </Grid>
        </div>
        :<div className="auto-width-100">
          { this.state.totalAmount && this.state.items && this.onGetTotalAmount(this.state.items) > 0
            ?<div id="parent-container" className="relative-position" style={{marginBottom: 10, paddingLeft: 20, paddingRight: 20}}>
              <span style={{fontSize: 12, color: 'black', fontWeight: 'bold'}}>해당 상품을 구매한 사람들이 함께 구매한 상품</span>
              <ScrollContainer className="recommend-scrollmenu-parent">
              <div className="recommend-scrollmenu" style={{marginTop: 10}}>
                { Object.values(this.state.items).map((item, index) => {
                  if ((item.amount > 0 && item.open === true && (item.category === 'spread' || item.category === 'milkTeaBase' || item.category === 'sconeMix'))) {
                    return (
                      <div>
                        { index + 1 !== Object.values(this.state.items).length
                          ?<Link  onClick={() => {
                            setTimeout(() => {
                              this.props.routeChanges()
                            }, 10)
                          }} to={'/home/' + this.state.categoryUrl[item.category]} className={"no-selection none-underline-text"}>
                            <div className="direction-column-div align-center" style={{width: 100, marginRight: 10, padding: 5, cursor: 'pointer'}}>
                              <Image style={{width: 80, height: 80, borderRadius: 5}} src={item.image} />
                              { item.discountedPrice &&
                                <span style={{fontSize: 13, fontWeight: 'bold', color: 'red', marginTop: 5}}>{Math.round(((item.price - item.discountedPrice) / item.price) * 100)}% 할인중</span>
                              }
                              <span style={{fontSize: 10, marginTop: 5, height: 30}}>{item.name}</span>

                            </div>
                          </Link>
                          :<Link  onClick={() => {
                            setTimeout(() => {
                              this.props.routeChanges()
                            }, 10)
                          }} to={'/home/' + this.state.categoryUrl[item.category]} className={"no-selection none-underline-text"}>
                          <div className="direction-column-div align-center" style={{width: 100, padding: 5, cursor: 'pointer'}}>
                            <Image style={{width: 80, height: 80, borderRadius: 5}} src={item.image} />
                            <span style={{fontSize: 10, marginTop: 5}}>{item.name}</span>
                          </div>
                          </Link>
                        }


                      </div>
                    )
                  }
                })

                }

              </div>
              </ScrollContainer>



            </div>
            :<div></div>
          }

        </div>
      }
      { this.state.totalAmount ?
        <StickyBox  className="auto-width-100 sticky-under-header" offsetTop={60}>
        { this.state.firstPurchaseFree
          ?<div onClick={() => {
            if (isEdge || isIE) {
                window.scrollTo(0, 0)
              } else {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
          }} style={{padding: 20, paddingTop: 10, paddingBottom: 20}}>
            <div style={{marginBottom: 5}}>
              <span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>첫 구매 배송비 무료!</span>
            </div>
            <ProgressBar height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={100} />

          </div>
          :<div onClick={() => {
            if (isEdge || isIE) {
                window.scrollTo(0, 0)
              } else {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
          }} style={{padding: 20, paddingTop: 10, paddingBottom: 20}}>
            { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
              ?<div style={{marginBottom: 5}}>
              { 50000 - this.state.totalPrice - this.onGetTotalPrice(this.props.additionalItems) > 0
                ?<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(50000 - this.state.totalPrice - this.onGetTotalPrice(this.props.additionalItems))}원만 더 담으면 무료 배송!</span>
                :<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>배송비 무료!</span>
              }
              </div>
              :<div style={{marginBottom: 5}}>
              { 50000 - this.state.totalPrice > 0
                ?<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(50000 - this.state.totalPrice)}원만 더 담으면 무료 배송!</span>
                :<span style={{fontSize: 12, color: '#006042', fontWeight: 'bold'}}>배송비 무료!</span>
              }
              </div>
            }
            { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
              ?<ProgressBar height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)) / 50000) * 100} />
              :<ProgressBar height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={(this.state.totalPrice / 50000) * 100} />
            }

          </div>
        }

        <Divider className="auto-width-100" />
        </StickyBox>
        :<div></div>
      }



      { this.state.deliveryCoupons && this.state.orderSheet && this.state.totalAmount ?
        <div ref={this.couponRef} className="auto-width-100">
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>쿠폰 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20}} />
            <Grid onClick={() => {
              if (this.state.orderSheet.discount) {
                alert('할인 이벤트 기간에는 쿠폰을 중복 사용할 수 없습니다.')
              } else {
                this.setState({showCoupons: !this.state.showCoupons})
              }
              
            }} style={{cursor: 'pointer', marginTop: 10}} className="auto-width-100 center-align" container justify="center" direction="column">
            { this.state.usingCoupon && !this.state.showCoupons &&
              <Grid container alignItems="center" justify="center" direction="column" className="auto-width-100 center-align">
                <span style={{fontSize: 15, marginTop: 5, fontWeight: 'bold'}}>사용할 쿠폰</span>
                <span style={{fontSize: 13, marginTop: 5}}>{this.state.usingCoupon.name} {this.onChangeCurrencyFormat(this.state.usingCoupon.amount)}원 쿠폰</span>
                { this.state.usingCoupon.minPrice &&
                  <span style={{fontSize: 15, marginTop: 5, color: 'red'}}>최소주문금액: {this.onChangeCurrencyFormat(this.state.usingCoupon.minPrice)}원</span>
                }
                
                { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                  ?<div>
                  { this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000
                    ?this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.state.firstPurchaseFree
                    ?this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.state.firstPurchaseFree
                    ?this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>

                  }
                  </div>
                  :<div>
                  { this.state.totalPrice >= 50000
                    ?this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.state.firstPurchaseFree
                    ?this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.state.firstPurchaseFree
                    ?this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                    :this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount < 0 &&
                    <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>


                  }
                  </div>
                }

                <Divider style={{width: 20, height: 5, marginTop: 10, borderRadius: 10, color: 'rgba(0, 135, 117, 0.95)'}} />
              </Grid>

            }
            { this.state.showCoupons
              ?<span className="auto-width-100 justify-content-center" style={{fontSize: 12, marginTop: 10}}>사용할 쿠폰을 선택해주세요. (중복 사용 불가)</span>
              :<span className="auto-width-100 justify-content-center" style={{fontSize: 12, marginTop: 10}}>사용 가능 쿠폰 {this.state.deliveryCoupons.length}개</span>
            }
            <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            { this.state.showCoupons
              ?<KeyboardArrowUpIcon  style={{marginTop: 5}}/>
              :<KeyboardArrowDownIcon style={{marginTop: 5}}/>
            }
            </Grid>
            </Grid>
            { this.state.showCoupons &&
              <Grid container alignItems="center" justify="center" direction="column">
              { this.state.deliveryCoupons.map((coupon, index) => {
                return (
                  <div onClick={() => {
                    if (this.state.usingCoupon) {
                      if (this.state.usingCoupon.couponId === coupon.couponId) {
                        this.setState({usingCoupon: null})
                      } else {
                        this.setState({usingCoupon: coupon, showCoupons: false})
                        if (isEdge || isIE) {
                          window.scrollTo(this.couponRef.current.offsetTop - 140, 0)
                        } else {
                          window.scrollTo({
                            top: this.couponRef.current.offsetTop - 140,
                            left: 0,
                            behavior: 'smooth'
                          })
                        }
                      }

                    } else {
                      this.setState({usingCoupon: coupon, showCoupons: false})
                      if (isEdge || isIE) {
                        window.scrollTo(this.couponRef.current.offsetTop - 120, 0)
                      } else {
                        window.scrollTo({
                          top: this.couponRef.current.offsetTop - 120,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }
                    }


                  }} className={this.state.usingCoupon === coupon ? "coupon-add-btn-selected direction-row-div align-center" : "coupon-add-btn direction-row-div align-center"} style={{padding: 10, paddingLeft: 20, paddingRight: 20}}>
                  <Grid container style={{marginTop: 5, marginBottom: 5}} direction="column">
                    <span style={{fontSize: 12}}>금액: {this.onChangeCurrencyFormat(coupon.amount)}원</span>
                    { coupon.eventName
                      ?<span style={{fontSize: 12}}>쿠폰명: {coupon.name} [{coupon.eventName}]</span>
                      :<span style={{fontSize: 12}}>쿠폰명: {coupon.name}</span>
                    }

                    <span style={{fontSize: 12}}>유효기간: {this.onStringNumberToDate(coupon.expireDate.toString())}</span>
                    { coupon.minPrice &&
                      <span style={{fontSize: 12, color: 'red'}}>최소주문금액: {this.onChangeCurrencyFormat(coupon.minPrice)}원</span>
                    }
                  </Grid>
                  </div>
                )
              })
              }
              </Grid>
            }



          </div>
        </div>
        :<div></div>
      }
      { this.state.orderSheet && this.state.totalAmount ?
        <div className="auto-width-100" >
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>배송 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20}} />
          </div>
          <div style={{marginBottom: 10}} className="auto-width-100" >
            <span style={{fontWeight: 'bold', fontSize: 14, marginLeft: 20}}>배송메세지</span>
            <Input style={{marginTop: 10, marginLeft: 20}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
              onChange={(event) => {
                if (event.target.value) {
                  this.setState({deliveryMessage: event.target.value})
                } else {
                  this.setState({deliveryMessage: null})
                }

              }}
            InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic" multiline={true} placeholder={"배송시 참고할 사항이 있으면 적어주세요."} label="배송메세지" value={this.state.deliveryMessage} />
          </div>
          <div onClick={() => {
            if (this.state.deliveryMessage) {
              let deliveryMessages = []
              if (this.state.deliveryMessages) {
                deliveryMessages = this.state.deliveryMessages
              }
              deliveryMessages.unshift(this.state.deliveryMessage)
              this.setState({deliveryMessages: deliveryMessages})
              firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)
            }
          }} style={{marginTop: 0, marginLeft: 20}} className="normal-very-small-button direction-column-div">
            <span style={{color: 'white', fontSize: 13}}>메세지 저장</span>

          </div>
          <div style={{margin: 20}} className="direction-column-div">
          { this.state.deliveryMessages.length ?
            this.state.deliveryMessages.map((message, index) => {
              return (
                <div style={{marginBottom: 15}} className="direction-row-div relative-position">
                  <span style={{marginRight: 5, marginBottom: 1, fontSize: 13}}>{index + 1}.</span>
                  <span onClick={() => {
                    let deliveryMessages = this.state.deliveryMessages
                    let deliveryMessage = deliveryMessages[index]
                    deliveryMessages.splice(index, 1)
                    deliveryMessages.unshift(deliveryMessage)
                    this.setState({deliveryMessage: deliveryMessage, deliveryMessages: deliveryMessages})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)
                  }} style={{fontSize: 13, cursor: 'pointer', paddingRight: 30}}>{message}</span>
                  <CloseIcon onClick={() => {
                    this.setState({showRemoveDeliveryMessageModal: true, remoingIndex: index})
                  }} style={{position: 'absolute', top: 0, right: 10, fontSize: 16, marginLeft: 5, marginTop: 1, cursor: 'pointer'}} />
                </div>
              )
            })
            :<div></div>
          }
          </div>

        </div>
        :<div></div>
      }
      { this.state.orderSheet && this.state.totalAmount && this.state.userClass !== null ?
        <div className="auto-width-100" >
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>등급 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20}} />
          </div>
          <div>
            <div className="direction-row-div align-center auto-width-100 justify-content-center">
              <span  style={{fontSize: 14, fontWeight: 'bold', color: '#006042'}}>현재 등급: {this.state.classArr[this.state.userClass]}</span>
              <a  data-event='click focus' data-tip data-for='userClass'>
              <HelpOutlineIcon  style={{fontSize: 15, marginLeft: 2, marginTop: 6, cursor: 'pointer'}} />
              </a>
              <ReactTooltip  id='userClass' globalEventOff='click'>
              <div className="direction-column-div align-center">
              <span style={{color: 'white', fontSize: 12}}>회원등급기준</span>
              <span style={{color: 'white', fontSize: 10, marginTop: 3}}>(지난 2개월 간의 택배 주문 결제금액 기준)</span>
              <div className="direction-column-div auto-width-100">
              <span style={{color: 'white', fontSize: 10, marginTop: 3}}>white: 5만원 미만</span>
              <span style={{color: 'white', fontSize: 10}}>silver: 5만원 이상 ~ 10만원 미만 (택배 3%할인)</span>
              <span style={{color: 'white', fontSize: 10}}>gold: 10만원 이상 ~ 20만원 미만 (택배 5%할인, 5천원 쿠폰 1장 지급)</span>
              <span style={{color: 'white', fontSize: 10}}>vip: 20만원 이상 (택배 10%할인, 5천원 쿠폰 2장 지급)</span>
              </div>
              </div>
              </ReactTooltip>
            </div>
          </div>
          <div>
            <div className="align-center justify-center relative-position row-between-space-86" style={{height: 40, marginTop: 20}}>
              { this.state.userClass > 0
                ?<Divider style={{backgroundColor: '#006042'}} className="first-class-process-line" />
                :<Divider className="first-class-process-line" />
              }
              { this.state.userClass > 1
                ?<Divider style={{backgroundColor: '#006042'}} className="second-class-process-line" />
                :<Divider className="second-class-process-line" />
              }
              { this.state.userClass > 2
                ?<Divider style={{backgroundColor: '#006042'}} className="third-class-process-line" />
                :<Divider className="third-class-process-line" />
              }
              { this.state.userClass <= 0 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 50000 &&
                <Divider style={{backgroundColor: 'black'}} className="first-class-process-line" />
              }
              { this.state.userClass <= 1 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 100000 &&
                <Divider style={{backgroundColor: 'black'}} className="second-class-process-line" />
              }
              { this.state.userClass <= 2 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 200000 &&
                <Divider style={{backgroundColor: 'black'}} className="third-class-process-line" />
              }
              { this.state.userClass >= 0
                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>WHITE</span>
                </div>
                :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>WHITE</span>
                </div>
              }
              { this.state.userClass > 0
                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>SILVER</span>
                </div>
                :<div className={this.state.userClass <= 0 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 50000 ? "z-index-100 background-disable-color-with-border align-center justify-content-center" : "z-index-100 background-disable-color align-center justify-content-center"} style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>SILVER</span>
                </div>
              }
              { this.state.userClass > 1
                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 38, height: 38, borderRadius: 35}}>
                <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>GOLD</span>
                </div>
                :<div className={this.state.userClass <= 1 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 100000 ? "z-index-100 background-disable-color-with-border align-center justify-content-center" : "z-index-100 background-disable-color align-center justify-content-center"} style={{width: 38, height: 38, borderRadius: 35}}>
                <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>GOLD</span>
                </div>
              }
              { this.state.userClass > 2
                ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>VIP</span>
                </div>
                :<div className={this.state.userClass <= 2 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 200000 ? "z-index-100 background-disable-color-with-border align-center justify-content-center" : "z-index-100 background-disable-color align-center justify-content-center"} style={{width: 38, height: 38, borderRadius: 35}}>
                  <span style={{color: 'white', fontSize: 10, fontWeight: 'bold'}}>VIP</span>
                </div>
              }
            </div>
            <div className="align-center justify-center relative-position row-between-space-89" style={{height: 10, marginTop: 10}}>
              <span style={{fontSize: 11}}>5만원 미만</span>
              <span style={{fontSize: 11}}>5만원 이상</span>
              <span style={{fontSize: 11}}>10만원 이상</span>
              <span style={{fontSize: 11}}>20만원 이상</span>
            </div>
          </div>
          <div style={{marginBottom: 20}}>

          { this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] < 50000 &&
            <div>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: 'black', marginTop: 30}}>다음 달 예정 등급: {this.state.classArr[0]}</span>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: '#006042', marginTop: 2}}>{this.onChangeCurrencyFormat(50000 - this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1])}원만 더 담으면 {this.state.classArr[1]}등급 예정</span>
            </div>
          }
          { this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 50000 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] < 100000 &&
            <div>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: 'black', marginTop: 30}}>다음 달 예정 등급: {this.state.classArr[1]}</span>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: '#006042', marginTop: 2}}>{this.onChangeCurrencyFormat(100000 - this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1])}원만 더 담으면 {this.state.classArr[2]}등급 예정</span>
            </div>
          }
          { this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 100000 && this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] < 200000 &&
            <div>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: 'black', marginTop: 30}}>다음 달 예정 등급: {this.state.classArr[2]}</span>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: '#006042', marginTop: 2}}>{this.onChangeCurrencyFormat(200000 - this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1])}원만 더 담으면 {this.state.classArr[3]}등급 예정</span>
            </div>
          }
          { this.onGetNextClass(this.state.hasPurchasedLastMonthAmount)[1] >= 200000 &&
            <div>
              <span className="auto-width-100 justify-content-center" style={{fontSize: 14, fontWeight: 'bold', color: 'black', marginTop: 30}}>다음 달 예정 등급: {this.state.classArr[3]}</span>
            </div>
          }
          <span className="auto-width-100 justify-content-center" style={{fontSize: 10, marginTop: 2}}>(결제금액 기준)</span>
          </div>
        </div>
        :<div></div>
      }
      { this.state.orderSheet && this.state.totalAmount
        ?<div className="auto-width-100" style={{marginBottom: 80}}>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 정보</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />

          <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
            <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품가격</span>
            { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
              ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems))}원</span>
              :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
            }

          </div>
          { this.state.userClassDiscountRate !== 1 &&
            <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>등급할인</span>
              { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(this.onCalculateDiscountAmount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))}원</span>
                :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.onChangeCurrencyFormat(this.onCalculateDiscountAmount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)))}원</span>
              }

            </div>
          }
          { this.state.firstPurchaseFree
            ?<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
              <span className="break-word" style={{fontSize: 13, marginRight: 20}}>무료</span>
            </div>
            :<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
              { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? 0 : this.onChangeCurrencyFormat(this.state.orderSheet.deliveryCharge) }원</span>
                :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.state.totalPrice >= 50000 ? 0 : this.onChangeCurrencyFormat(this.state.orderSheet.deliveryCharge) }원</span>
              }

            </div>
          }


          { this.state.usingCoupon &&
            <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>쿠폰할인</span>
              { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                ?this.state.firstPurchaseFree
                ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) : this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) }원</span>
                :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) : this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)) }원</span>

                :this.state.firstPurchaseFree
                ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice >= 50000 ? this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) : this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) }원</span>
                :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice >= 50000 ? this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))) : this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)) }원</span>

              }

            </div>
          }
          { this.state.discountRate !== 1 &&
            <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>

              { this.state.firstPurchaseFree
                ?<div className="auto-width-100 direction-row-div justify-between" style={{ paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20, width: 150}}>{this.state.orderSheet.discount['title']}</span>
                  { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))) - (this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))) :
                      this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))) - (this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))))}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0))))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                      this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate))))}원</span>
                  }
                </div>
                :<div className="auto-width-100 direction-row-div justify-between" style={{paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20, width: 150}}>{this.state.orderSheet.discount['title']}</span>
                  { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                    ?<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))) - (this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))) :
                      this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge) - (this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원</span>
                    :<span className="break-word" style={{fontSize: 13, marginRight: 20}}>-{this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                      this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)) - (Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge) - (this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원</span>
                  }
                </div>
              }

            </div>
          }
          <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
          { this.state.firstPurchaseFree
            ?<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
              { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                ?<span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))) :
                  this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))))}원</span>
                :<span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                  this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate))))}원</span>
              }
            </div>
            :<div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
              <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
              { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                ?<span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))) :
                  this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원</span>
                :<span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                  this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원</span>
              }
            </div>
          }

        </div>
        </div>
        :<div></div>
      }

      </Grid>
      <Modal
        open={this.state.showRemoveDeliveryMessageModal}
        onClose={() => this.setState({showRemoveDeliveryMessageModal: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}
        >
        <div className="remove-item-modal">
        <div className="direction-column-div">
        <span>[{this.state.deliveryMessages[this.state.remoingIndex]}]</span>
        <span>해당 메세지를 삭제하시겠습니까?</span>
        </div>
        <Grid container direction="row" style={{marginTop: 40}}>
        <div className="modal-button-outlined" onClick={() => {
          this.setState({showRemoveDeliveryMessageModal: false})
        }}>
        <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
        </div>
        <div className="modal-button-filled"  onClick={() => {
          let deliveryMessages = this.state.deliveryMessages
          deliveryMessages.splice(this.state.remoingIndex, 1)
          this.setState({deliveryMessages: deliveryMessages})
          firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryMessages`).set(deliveryMessages)

          this.setState({showRemoveDeliveryMessageModal: false})
        }}>
        <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
        </div>
        </Grid>
        </div>
      </Modal>
        <Modal
          open={this.state.showPostModal}
          onClose={() => {
            this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.tempFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
            <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    this.setState({
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber,
                    })
                    let userInfo = this.state.userInfo

                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        let deliveryAddress = snapshot.val()
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['deliveryAddress'] = deliveryAddress
                        userInfo['defaultAddressIndex'] = 0

                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['defaultAddressIndex'] = 0
                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      }
                    })
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              if (fullAddress.indexOf('제주특별자치도') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else if (fullAddress.indexOf('경북 울릉군') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else {
                this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
              }

            }}
          />
        }

        </Modal>
        <Modal
          open={this.state.showRemoveModal}
          onClose={() => this.setState({showRemoveModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        <div className="remove-item-modal">
        <span>해당 상품을 삭제하시겠습니까?</span>
        <Grid container direction="row" style={{marginTop: 40}}>
        <div className="modal-button-outlined" onClick={() => {
          this.setState({showRemoveModal: false})
        }}>
        <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
        </div>
        <div className="modal-button-filled"  onClick={() => {
          let selectedScones = this.state.selectedScones
          let willRemoveItem = this.state.willRemoveItem

          let totalAmount = this.state.totalAmount
          let totalPrice = this.state.totalPrice

          totalAmount = totalAmount - selectedScones[willRemoveItem.id].amount
          totalPrice = totalPrice - (willRemoveItem.price * selectedScones[willRemoveItem.id].amount)

          delete selectedScones[willRemoveItem.id]

          if (this.props.onSelectScone) {
            this.props.onSelectScone(totalPrice, totalAmount, selectedScones, 1)
          }
          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedScones: selectedScones, showRemoveModal: false})
        }}>
        <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
        </div>
        </Grid>
        </div>
      </Modal>
      
      { this.state.totalAmount !== 0 && this.state.orderSheet &&
        <a onClick={() => {
          if (this.state.usingCoupon) {
            if (this.state.usingCoupon.expireDate.toString() < moment(Date.now()).format('YYYYMMDD').toString()) {
              return this.setState({couponExpireDateSnack: true})
            }
            if (this.state.usingCoupon.minPrice) {
              if (Object.keys(this.props.additionalItems).length !== 0) {
                if ( this.props.orderWithAdditionalItems ) {
                  if (this.state.usingCoupon.minPrice > Number(this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems))) {
                    return this.setState({notEnoughMinPrice: true})
                  }
                } else {
                  if (this.state.usingCoupon.minPrice > Number(this.state.totalPrice)) {
                    return this.setState({notEnoughMinPrice: true})
                  }
                }
                
              } else {
                if (this.state.usingCoupon.minPrice > Number(this.state.totalPrice)) {
                  return this.setState({notEnoughMinPrice: true})
                }
              }
            }
            
          }
          

          let free = null

          if (this.state.usingCoupon) {
            if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
              if (this.state.firstPurchaseFree) {
                if (this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount <= 0) {
                  free = true
                }
              } else {
                if (this.onCalculateDiscount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount <= 0) {
                  free = true
                }
              }

            } else {
              if (this.state.firstPurchaseFree) {
                if (this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount <= 0) {
                  free = true
                }
              } else {
                if (this.onCalculateDiscount(this.state.totalPrice, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount <= 0) {
                  free = true
                }
              }

            }

          }

          if (this.state.isOpened) {
            if (this.state.fullAddress && this.state.detailAddress && this.state.postCode && this.state.receiver && this.state.phoneNumber) {
              let orderId = shortid.generate()
              let selectedScones = this.state.selectedScones
              if ((this.state.totalAmount > 4 || this.state.specialItem)) {
                this.setState({isPurchasing: true})
                let deliveryCharge = this.state.orderSheet.deliveryCharge
                let couponDiscount = 0
                let usingCoupon = null
                let discount = null
                let eventScone = null
                if (this.state.firstPurchaseFree) {
                  deliveryCharge = 0
                } else {
                  if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
                    if (this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000) {
                      deliveryCharge = 0
                    }
                  } else {
                    if (this.state.totalPrice >= 50000) {
                      deliveryCharge = 0
                    }
                  }
                }

                if (this.state.discountRate !== 1) {
                  discount = this.state.orderSheet.discount
                }


                if (this.state.eventScone) {
                  eventScone = this.state.eventScone
                }
                if (this.state.usingCoupon) {
                  couponDiscount = this.state.usingCoupon.amount
                  usingCoupon = this.state.usingCoupon
                  usingCoupon['usedAt'] = Date.now()
                }
                let userClassDiscountAmount = null

                let giftBoxAmount = null

                if (this.state.giftBoxAmount !== 0) {
                  giftBoxAmount = this.state.giftBoxAmount
                }

                let orderInfo = {}
                if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
                  if (this.state.userClassDiscountRate !== 1) {
                    userClassDiscountAmount = this.onCalculateDiscountAmount((this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))
                  }
                  orderInfo = {
                    orderId: orderId,
                    buyerUid: this.state.userInfo.uid,
                    items: this.state.selectedScones,
                    totalAmount: this.state.totalAmount,
                    totalPrice: this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems),
                    additionalItemsPrice: this.onGetTotalPrice(this.props.additionalItems),
                    sconePrice: this.state.totalPrice,
                    additionalItems: this.props.additionalItems,
                    deliveryMessage: this.state.deliveryMessage,
                    deliveryCharge: deliveryCharge,
                    usingCoupon: usingCoupon,
                    discount: discount,
                    giftBoxAmount: giftBoxAmount,
                    eventScone: eventScone,
                    orderSheet: this.state.openOrderSheet,
                    buyerName: this.state.userInfo.username,
                    paidPrice: parseInt(Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + deliveryCharge - couponDiscount, 0)),
                    userClassDiscountAmount: userClassDiscountAmount,
                    addressTo: {
                      detailAddress: this.state.detailAddress,
                      fullAddress: this.state.fullAddress,
                      postCode: this.state.postCode,
                      receiver: this.state.receiver,
                      phoneNumber: this.state.phoneNumber
                    },
                    purchasedAt: Date.now()
                  }
                } else {
                  if (this.state.userClassDiscountRate !== 1) {
                    userClassDiscountAmount = this.onCalculateDiscountAmount(this.state.totalPrice, (1 - this.state.userClassDiscountRate))
                  }
                  orderInfo = {
                    orderId: orderId,
                    buyerUid: this.state.userInfo.uid,
                    items: this.state.selectedScones,
                    totalAmount: this.state.totalAmount,
                    totalPrice: this.state.totalPrice,
                    deliveryMessage: this.state.deliveryMessage,
                    deliveryCharge: deliveryCharge,
                    usingCoupon: usingCoupon,
                    discount: discount,
                    giftBoxAmount: giftBoxAmount,
                    eventScone: eventScone,
                    orderSheet: this.state.openOrderSheet,
                    buyerName: this.state.userInfo.username,
                    paidPrice: parseInt(Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + deliveryCharge - couponDiscount, 0)),
                    userClassDiscountAmount: userClassDiscountAmount,
                    addressTo: {
                      detailAddress: this.state.detailAddress,
                      fullAddress: this.state.fullAddress,
                      postCode: this.state.postCode,
                      receiver: this.state.receiver,
                      phoneNumber: this.state.phoneNumber
                    },
                    purchasedAt: Date.now()
                  }
                }

                firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).set(orderInfo)
                .then(() => {
                  if (!free) {
                    firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
                          this.onPaying(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + deliveryCharge - couponDiscount)), orderId, '스콘', this.state.userInfo.username)
                        } else {
                          this.onPaying(parseInt((this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + deliveryCharge - couponDiscount)), orderId, '스콘', this.state.userInfo.username)
                        }

                      }
                    })
                  } else {
                    this.setState({ freePaymentVerifying: true})
                    firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}/usingCoupon`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            let coupon = snapshot.val()
                            firebase.database().ref(`onlineCoupons/${coupon.couponId}/usedAt`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                this.setState({alreadyUsed: true, freePaymentVerifying: false}, () => {
                                  setTimeout(() => {
                                    this.setState({alreadyUsed: false})
                                  }, 2000)
                                })
                              } else {
                                firebase.database().ref(`onlineCoupons/${coupon.couponId}`).set(coupon)
                                if (this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0) {
                                  Object.values(this.props.additionalItems).map((item, index) => {
                                    firebase.database().ref(`items/${item.itemId}`).once('value', (snapshot) => {
                                      if (snapshot.val()) {
                                        firebase.database().ref(`items/${item.itemId}/amount`).set(Math.max(snapshot.val()['amount'] - item.amount, 0))
                                      }
                                    })
                                  })

                                  firebase.database().ref(`users/${this.state.userInfo.uid}/cart`).remove()
                                }
                                firebase.database().ref(`users/${this.state.userInfo.uid}/coupons/${coupon.couponId}`).set(coupon)
                                firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    let purchaseInfo = snapshot.val()
                                    purchaseInfo['paid'] = true
                                    purchaseInfo['paidAt'] = Date.now()

                                    if (giftBoxAmount) {

                                      firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/yUcEOQHrz/amount`).once('value', (snapshot) => {
                                        if (snapshot.val()) {
                                          if ((snapshot.val() - giftBoxAmount) < 0) {
                                            firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/yUcEOQHrz/amount`).set(0)
                                          } else {
                                            firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/yUcEOQHrz/amount`).set(snapshot.val() - giftBoxAmount)
                                          }

                                        }
                                      })

                                    }


                                    firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/orders/${orderId}/items`).once('value', (snapshot) => {
                                      if (snapshot.val()) {
                                        let purchaseItems = Object.values(snapshot.val())
                                        purchaseItems.map((item, index) => {
                                          firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/${item.itemId}/amount`).once('value', (snapshot) => {
                                            if (typeof(snapshot.val()) === 'number') {
                                              let remainAmount = snapshot.val()
                                              if ((remainAmount - item.amount) > 0) {
                                                firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/${item.itemId}/amount`).set(remainAmount - item.amount)
                                              } else {
                                                firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/menu/${item.itemId}/amount`).set(0)
                                              }
                                            }
                                          })

                                        })
                                      }
                                    })
                                    firebase.database().ref(`orderSheets/${purchaseInfo.orderSheet}/orders/${orderId}`).set(purchaseInfo)
                                    firebase.database().ref(`users/${this.state.userInfo.uid}/sconeOrders/${orderId}`).set(purchaseInfo)
                                    .then(() => {
                                      if (this.props.onSelectScone) {
                                        this.props.onSelectScone(0, 0, {}, 0)
                                      }
                                      this.setState({totalAmount: 0, totalPrice: 0, selectedScones: {}})
                                      axios({
                                        url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendDeliveryMessage`,
                                        method: "post",
                                        headers: {
                                          "Content-Type":"application/json"
                                        },
                                        data: {
                                          orderId: orderId,
                                          uid: this.state.userInfo.uid,
                                          path: 'order'
                                        }
                                      })
                                      if (this.props.onRefreshUserInfo) {
                                        this.props.onRefreshUserInfo()
                                      }
                                      if (this.props.onReloadCartPage) {
                                        this.props.onReloadCartPage()
                                      }
                                      this.setState({isPaying: false, paymentCompleted: true}, () => {
                                        setTimeout(() => {
                                          this.props.history.replace(`/order/${orderId}?from=delivery_payment_result`);
                                          setTimeout(() => {
                                            this.props.routeChanges()
                                          }, 10)
                                        }, 2000)
                                      })
                                    })
                                  }
                                })
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })

              }
            } else {
              this.setState({noAddressSnack: true})
            }
          } else {
            this.setState({notAbleToOrderSnack: true})
          }


        }} className={(this.state.totalAmount > 4 || this.state.specialItem) ? "btn-very-bottom-big no-selection z-index-600" : "btn-very-bottom-big-disabled no-selection z-index-600"}>
            { this.state.firstPurchaseFree
              ?<div>
                { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                  ?<p style={{color: 'white', fontSize: 15}}>{(this.state.totalAmount > 4 || this.state.specialItem) ? '결제하기' :'최소주문 5개 중' } {(this.state.totalAmount > 4 || this.state.specialItem) ? `` : `/ ${this.state.totalAmount}개 선택`  } ( {this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)))) :
                    this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0 )))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)))))}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>{(this.state.totalAmount > 4 || this.state.specialItem) ? '결제하기' :'최소주문 5개 중' } {(this.state.totalAmount > 4 || this.state.specialItem) ? `` : `/ ${this.state.totalAmount}개 선택`  } ( {this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                    this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0 )))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate))))}원 )</p>
                }
              </div>
              :<div>
                { this.props.orderWithAdditionalItems && Object.keys(this.props.additionalItems).length !== 0
                  ?<p style={{color: 'white', fontSize: 15}}>{(this.state.totalAmount > 4 || this.state.specialItem) ? '결제하기' :'최소주문 5개 중' } {(this.state.totalAmount > 4 || this.state.specialItem) ? `` : `/ ${this.state.totalAmount}개 선택`  } ( {this.state.totalPrice + this.onGetTotalPrice(this.props.additionalItems) >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate))))) :
                    this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0 )))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount((this.state.totalPrice * this.state.discountRate + this.onGetTotalPrice(this.props.additionalItems)), (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>{(this.state.totalAmount > 4 || this.state.specialItem) ? '결제하기' :'최소주문 5개 중' } {(this.state.totalAmount > 4 || this.state.specialItem) ? `` : `/ ${this.state.totalAmount}개 선택`  } ( {this.state.totalPrice >= 50000 ? this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) - this.state.usingCoupon.amount, 0)))) : this.onChangeCurrencyFormat(parseInt(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)))) :
                    this.state.usingCoupon ? this.onChangeCurrencyFormat(parseInt((Math.max(this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge - this.state.usingCoupon.amount, 0 )))) : this.onChangeCurrencyFormat(parseInt((this.onCalculateDiscount(this.state.totalPrice * this.state.discountRate, (1 - this.state.userClassDiscountRate)) + this.state.orderSheet.deliveryCharge)))}원 )</p>
                }
              </div>
            }


        </a>
      }
      <Snackbar open={this.state.noMoreSconesSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreSconesSnack: false})}>
        <Alert  onClose={() => this.setState({noMoreSconesSnack: false})} severity="warning">
          재고가 부족합니다!
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.couponExpireDateSnack} autoHideDuration={4000} onClose={() => this.setState({couponExpireDateSnack: false})}>
        <Alert  onClose={() => this.setState({couponExpireDateSnack: false})} severity="warning">
          유효기간이 지난 쿠폰입니다.
        </Alert>
      </Snackbar>
      
      <Snackbar open={this.state.notEnoughMinPrice} autoHideDuration={4000} onClose={() => this.setState({notEnoughMinPrice: false})}>
        <Alert  onClose={() => this.setState({notEnoughMinPrice: false})} severity="warning">
          해당 쿠폰은 {this.state.usingCoupon?.minPrice && this.onChangeCurrencyFormat(this.state.usingCoupon.minPrice)}원이 최소주문금액입니다(배송비제외).
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.changeRemainsSnack} autoHideDuration={10000} onClose={() => this.setState({changeRemainsSnack: false})}>
        <Alert  onClose={() => this.setState({changeRemainsSnack: false})} severity="info">
          {this.state.changeRemains}
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.changeGiftBoxRemainsSnack} autoHideDuration={10000} onClose={() => this.setState({changeGiftBoxRemainsSnack: false})}>
        <Alert  onClose={() => this.setState({changeGiftBoxRemainsSnack: false})} severity="info">
          디저트 박스 잔여 수량에 변동이 있습니다.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.notAbleToOrderSnack} autoHideDuration={10000} onClose={() => this.setState({notAbleToOrderSnack: false})}>
        <Alert  onClose={() => this.setState({notAbleToOrderSnack: false})} severity="warning">
          주문 가능 시간이 아닙니다.
        </Alert>
      </Snackbar>
      <Snackbar open={this.state.noAddressSnack} autoHideDuration={10000} onClose={() => this.setState({noAddressSnack: false})}>
        <Alert  onClose={() => this.setState({noAddressSnack: false})} severity="warning">
          배송지 정보를 입력해주세요.
        </Alert>
      </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(DeliveryCart);
