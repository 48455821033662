import React, {Component} from 'react';

class SconeMixRedirect extends Component {
 componentDidMount(){
     window.location.href = "https://withanod.com/home/scone_mix";
  }

  render() {
    return (
      <div>
      </div>
    )
  }
}

export default SconeMixRedirect;
