import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import MainLogo from '../marketLogo.jpg';
import PulseLoader from "react-spinners/PulseLoader";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class EmailLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      oneSecondLock: false,
      email: '',
      password: '',
      isLogingIn: false,
      wrongLoginEmail: false,
      loginEmailError: false,
      wrongLoginPassword: false
    }
  }


  componentWillMount() {
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      } else {
        this.setState({userInfo: null, isLoading: false})
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onLogin() {
      this.setState({isLogingIn: true})
      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(res => {
      })
      .catch((err) => {
        if (err.code == 'auth/user-not-found') {
          this.setState({
            wrongLoginEmail: true,
            isLogingIn: false
          })
        }
        if (err.code == 'auth/invalid-email') {
          this.setState({
            loginEmailError: true,
            isLogingIn: false
          })
        }
        if (err.code == 'auth/wrong-password') {
          this.setState({
            wrongLoginPassword: true,
            isLogingIn: false
          })
        }
      })
    }


  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <Grid container justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>로그인</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {
            let currentPath = window.location.pathname
            this.props.history.goBack()

            let inervalFunction = setInterval(() => {
              if (currentPath === window.location.pathname) {
                this.props.history.goBack()
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              } else {
                clearInterval(inervalFunction)
              }
            }, 100)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        { !this.state.userInfo &&
          <Grid style={{marginTop: 70}} container justify="center">
            <div className="login-main-view auto-width-100">
              <img
                className="logo-style"
                src={MainLogo}></img>
                <span style={{fontSize: 18, marginTop: 10}}>간편하게 로그인하고</span>
                <span style={{fontWeight: 'bold', fontSize: 18, marginTop: 5}}>최고의 스콘을 만나보세요.</span>
                <Container justify="center" maxWidth="sm" >
                  <Grid container justify="center"  style={{marginTop: 20}}>
                    { this.state.wrongLoginEmail || this.state.loginEmailError
                      ?<Grid container justify="center" >
                      <TextField error={this.state.wrongLoginEmail || this.state.loginEmailError} type="email" onChange={(event) => {
                        this.setState({email: event.target.value, wrongLoginEmail: false, loginEmailError: false})
                      }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                      helperText={this.state.wrongLoginEmail === true ? "가입되지 않은 이메일입니다." : "올바르지 않은 이메일 형식입니다."}
                      InputProps={{style: {marginBottom: 0}}}  id="standard-basic" label="이메일"  value={this.state.email} />
                      </Grid>
                      :<Grid container justify="center" >
                      <TextField type="email" onChange={(event) => {
                        this.setState({email: event.target.value, wrongLoginEmail: false, loginEmailError: false})
                      }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                      InputProps={{style: {marginBottom: 21}}}  id="standard-basic" label="이메일"  value={this.state.email} />
                      </Grid>
                    }
                    { this.state.wrongLoginPassword
                      ?<Grid container justify="center" >
                        <TextField error={this.state.wrongLoginPassword} type="password" onChange={(event) => {
                          this.setState({password: event.target.value, wrongLoginPassword: false})
                        }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                        helperText={this.state.wrongLoginPassword ? "잘못된 비밀번호입니다." : " "}
                        InputProps={{style: {marginBottom: 0}}}  id="standard-basic" label="비밀번호"  value={this.state.password} />
                      </Grid>
                      :<Grid container justify="center" >
                        <TextField type="password" onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.onLogin()
                          }
                        }} onChange={(event) => {
                          this.setState({password: event.target.value, wrongLoginPassword: false})
                        }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                        InputProps={{style: {marginBottom: 21}}}  id="standard-basic" label="비밀번호"  value={this.state.password} />
                      </Grid>
                    }

                  </Grid>
                  <div onClick={() => this.onLogin()} className="normal-button" >
                    { this.state.isLogingIn
                      ?<PulseLoader  color={'white'} loading={true} size={5} />
                      :<span style={{color: 'white'}}>로그인</span>
                    }

                  </div>
                  <Grid container justify="center" style={{marginTop: 20}} >
                  <div className="direction-row-div">
                    <Link className="none-underline-text"  onClick={() => {
                      setTimeout(() => {
                        this.props.routeChanges()
                      }, 10)
                    }} to={'/email_find?redirect=' + redirect}>
                    <span style={{fontSize: 13, cursor: 'pointer'}}>아이디 찾기</span>
                    </Link>
                    <span style={{fontSize: 13, marginLeft: 5, marginRight: 5}}>|</span>
                    <Link className="none-underline-text"  onClick={() => {
                      setTimeout(() => {
                        this.props.routeChanges()
                      }, 10)
                    }} to={'/password_find?redirect=' + redirect}>
                    <span style={{fontSize: 13, cursor: 'pointer'}}>비밀번호 찾기</span>
                    </Link>
                    <span style={{fontSize: 13, marginLeft: 5, marginRight: 5}}>|</span>
                    <Link className="none-underline-text"  onClick={() => {
                      setTimeout(() => {
                        this.props.routeChanges()
                      }, 10)
                    }} to={'/join?redirect=' + redirect}>
                    <span  style={{fontSize: 13, cursor: 'pointer'}}>회원가입</span>
                    </Link>
                  </div>
                  </Grid>
                </Container>
            </div>
          </Grid>
        }

      </Grid>
    )
  }
}

export default withRouter(EmailLogin);
