import React, {Component, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Beforeunload } from 'react-beforeunload';
import HashLoader from "react-spinners/HashLoader";
import shortid from 'shortid';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import cookieDivider from '../cookieDivider.png';
import sconeDivider from '../sconeDivider.png';

import Sheet from 'react-modal-sheet';

import {withRouter} from 'react-router-dom';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import { Prompt } from 'react-router'

import Select from 'react-select'

import { Image, Col, Row, Container } from 'react-bootstrap';
import ProgressBar from "@ramonak/react-progress-bar";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!

import moment from 'moment';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import giftSetProductInfo1_1 from '../GiftSetProductInfo1_1.jpg';
import giftSetProductInfo1_2 from '../GiftSetProductInfo1_2.jpg';
import roundIcon from '../roundIcon.png';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import * as firebase from 'firebase';

import { loadTossPayments } from '@tosspayments/sdk';

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ChuseokGiftSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      date: new Date(Date.now()),
      sconeMenu: {},
      pickupOpen: {},

      showPickupScones: false,

      noMoreSconesSnack: false,
      noMoreItemsSnack: false,
      overTimeItemsSnack: false,

      today: moment(),
      pickupDate: null,

      hourSelectedOption: '',
      minuteSelectedOption: '',

      hour: '',
      minute: '',
      totalAmount: 0,
      totalPrice: 0,
      selectedPickupScones: {},

      isModalOpen: false,
      isOpenSheet: false,

      name: '',
      phoneNumber: '',

      notEnoughInfoSnack: false,

      errorMessage: '',

      overTimeSnack: false,
      pickupId: '',

      weekdaysLastPickupTimeSnack: false,
      lastPickupTimeSnack: false,

      request: '',

      selectedSconeImage: null,

      usingCoupon: null,
      offlineCoupons: [],
      couponExpireDateSnack: false,

      noRemainAmount: false,
      freePayment: false,
      alreadyUsed: false,
      freePaymentVerifying: false,

      showCoupons: false,
      waitMessage: '잠시만 기다려주세요...',

      giftSet: null,
      items: null,
      selectedItems: {},
      selectedItemsTotalAmount: 0,
      selectedItemsTotalPrice: 0,
      showScrollDownBtn: true,

      numberOfCoolings: '',
      numberOfBSetsSelectedOption: '',

      showPickupOrDeliveryButton: true
    }
    this.bookingView = React.createRef()
    this.myRef = React.createRef()
    this.introRef = React.createRef()
  }


  componentWillMount() {
    // let id = shortid.generate()
    // firebase.database().ref(`sconeMenu/${id}`).set({
    //   explanation: '선물상자 + 리본 + 먹는 방법 카드, ',
    //   id: id,
    //   image: 'https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/scone%2Fmenu_giftBox.png?alt=media&token=0136ce7e-efc7-4967-85dc-3fb49cdfa886',
    //   name: '디저트 박1스',
    //   open: true,
    //   order: 0,
    //   price: 4000
    // })
    window.addEventListener('scroll', this.listenToScroll)
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)

    setTimeout(() => {
      this.setState({waitMessage: '디저트들을 불러오고 있습니다...'})
    }, 5000)

    setTimeout(() => {
      this.setState({waitMessage: '거의 다 됐습니다!!!'})
    }, 10000)
    if (Object.values(this.props.itemsObj).length !== 0) {
      this.setState({items: this.props.itemsObj})
      let giftSetList = Object.values(this.props.itemsObj)
      giftSetList = giftSetList.filter(giftSet => giftSet.category === 'giftSet')
      let giftSetkeys = giftSetList.map(a => a.id);
      let giftSet = {}
      giftSetkeys.map((key, index) => {
        giftSet[key] = giftSetList[index]
        this.setState({giftSet: giftSet})
      })
    } else {
      firebase.database().ref(`items`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({items: snapshot.val()})
          if (this.props.onSetItemsObj) {
            this.props.onSetItemsObj(snapshot.val())
          }

          let giftSetList = Object.values(snapshot.val())
          giftSetList = giftSetList.filter(giftSet => giftSet.category === 'giftSet')
          let giftSetkeys = giftSetList.map(a => a.id);
          let giftSet = {}
          giftSetkeys.map((key, index) => {
            giftSet[key] = giftSetList[index]
            this.setState({giftSet: giftSet})
          })
        }
      })
    }
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({sconeMenu: snapshot.val()})
      }
    }).then(() => {
      firebase.database().ref(`pickupOpen`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({pickupOpen: snapshot.val()})
          // let data = snapshot.val()['20210915']
          // data.date = 20210918
          // firebase.database().ref(`pickupOpen/20210918`).set(data)
        }
      })
    })
  }

  componentDidMount() {
    if (sessionStorage.getItem('giftSetpickupData')) {
      let giftSetpickupData = JSON.parse(sessionStorage.getItem('giftSetpickupData'))
      this.setState(giftSetpickupData)
      sessionStorage.removeItem('giftSetpickupData')
    }

    if (Object.keys(this.props.pickupSconesObj).length !== 0) {
      let pickupSconesObj = this.props.pickupSconesObj

      this.setState(pickupSconesObj)
    }
  }

  componentWillUnmount() {

    if (this.props.onSavePickupSconesObj) {
      this.props.onSavePickupSconesObj({
        date: this.state.date,
        sconeMenu: this.state.sconeMenu,
        pickupOpen: this.state.pickupOpen,

        pickupDate: this.state.pickupDate,

        hourSelectedOption: this.state.hourSelectedOption,
        minuteSelectedOption: this.state.minuteSelectedOption,

        hour: this.state.hour,
        minute: this.state.minute,
        totalAmount: this.state.totalAmount,
        totalPrice: this.state.totalPrice,
        selectedItems: this.state.selectedItems,

        isModalOpen: this.state.isModalOpen,
        isOpenSheet: this.state.isOpenSheet,

        name: this.state.name,
        phoneNumber: this.state.phoneNumber
      })
    }
    window.removeEventListener('scroll', this.listenToScroll)
  }


  componentDidUpdate() {
  }


  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {

        if (!this.state.name) {
          this.setState({name: nextProps.userInfo.username });
        }
        if (!this.state.phoneNumber) {
          this.setState({phoneNumber: nextProps.userInfo.phoneNumber });
        }
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });

      } else {
        this.setState({
          date: new Date(Date.now()),
          isLoading: false,
          sconeMenu: {},
          pickupOpen: {},

          today: moment(),
          pickupDate: null,

          hourSelectedOption: '',
          minuteSelectedOption: '',

          hour: '',
          minute: '',
          totalAmount: 0,
          totalPrice: 0,
          selectedItems: {},

          name: '',
          phoneNumber: '',

          isModalOpen: false,
          isOpenSheet: false,

        })
      }

    } else {
      this.setState({ isLoading: true });
    }
  }

  listenToScroll = () => {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop

  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

  const scrolled = winScroll / height

  if (this.bookingView.current) {
    if (winScroll - this.bookingView.current.offsetTop + document.documentElement.clientHeight - 200 < 0) {
      if (!this.state.showScrollDownBtn) {
        this.setState({
          showScrollDownBtn: true,
        })
      }

    } else {
      if (this.state.showScrollDownBtn) {
        this.setState({
          showScrollDownBtn: false,
        })
      }
    }
  }
}

  onCountSelectedItems(selectedItems) {
    if (Object.values(selectedItems).length !== 0) {
      let selectedItemsTotalAmount = 0
      let selectedItemsTotalPrice = 0
      Object.values(selectedItems).map((item, index) => {
        selectedItemsTotalAmount = selectedItemsTotalAmount + item.amount
        selectedItemsTotalPrice = selectedItemsTotalPrice + (item.price * item.amount)

        if (Object.values(selectedItems).length === index + 1) {
          this.setState({selectedItemsTotalPrice: selectedItemsTotalPrice, selectedItemsTotalAmount: selectedItemsTotalAmount})
        }
      })
    } else {
      this.setState({selectedItemsTotalAmount: 0, selectedItemsTotalPrice: 0})
    }
  }

  onGetOfflineCoupons(userInfo) {

    firebase.database().ref(`coupons`).once('value', (snapshot) => {
      if (snapshot.val()) {

        let offlineCoupons = Object.values(snapshot.val())
        offlineCoupons = offlineCoupons.filter(offlineCoupon => offlineCoupon.phoneNumber === userInfo.phoneNumber)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => !offlineCoupon.usedAt)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => moment(offlineCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString() >=  moment(Date.now()).format('YYYYMMDD').toString())
        this.setState({offlineCoupons: offlineCoupons, isOpenSheet: true})
      }
    })
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
}

  onGetCurrentDateWithDay(day) {
    let today = new Date()
    let dd = day.toString().padStart(2, '0')
    let mm = (today.getMonth() + 1).toString().padStart(2, '0')
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`)
    return result
  }


  calendarArr() {
    const today = moment('20220901')
    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;

    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (Number(days.format('YYYYMMDD')) >= 20220828 && Number(days.format('YYYYMMDD')) <= 20220910) {
              if (this.state.pickupOpen[days.format('YYYYMMDD')]) {
                if (this.state.pickupOpen[days.format('YYYYMMDD')].amount <= 2) {
                  if (days.format('MM') !== today.format('MM')) {
                    return (
                        <td className="table-style" key={index} style={{height: 45}} >
                        </td>
                    )
                  } else {
                    return (
                        <td className="table-style" key={index} style={{backgroundColor:'gray', height: 45}} >
                          <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        </td>
                    )
                  }

                } else {

                  if (this.onDateToTimeStamp(days.format('YYYYMMDD')) - (1000 * 60 * 60 * 18) < Date.now()) {
                    if (days.format('MM') !== today.format('MM')) {
                      return (
                          <td className="table-style" key={index} style={{height: 45}} >
                          </td>
                      )
                    } else {
                      return (
                        <td className="table-style" key={index} style={{backgroundColor:'gray', height: 45}} >
                          <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        </td>
                      )
                    }

                  } else {
                    if (days.format('MM') !== today.format('MM')) {
                      return (
                        <td className="table-style" key={index} style={{height: 45}} >
                        </td>
                      )
                    }
                    if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                      if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) {
                        return (
                            <td onClick={() => {
                              if (this.state.userInfo) {
                                if (this.state.userInfo.phoneNumber) {
                                  this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                }
                                if (this.state.userInfo.username) {
                                  this.setState({name: this.state.userInfo.username})
                                }
                                this.setState({totalAmount: 0, totalPrice: 0, selectedItems: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                                this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                  setTimeout(() => {
                                    if (isEdge || isIE) {
                                        window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                      } else {
                                        window.scrollTo({
                                          top: this.myRef.current.offsetTop - 110,
                                          left: 0,
                                          behavior: 'smooth'
                                        })
                                      }
                                  }, 50)
                                })
                              } else {
                                this.props.history.push('/login?redirect=/home/chuseok_gift_set');
                                setTimeout(() => {
                                  this.props.routeChanges()
                                }, 10)
                              }

                            }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1 || this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 2) {
                          return (
                              <td onClick={() => {
                                if (this.state.userInfo) {
                                  if (this.state.userInfo.phoneNumber) {
                                    this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                  }
                                  if (this.state.userInfo.username) {
                                    this.setState({name: this.state.userInfo.username})
                                  }
                                  this.setState({totalAmount: 0, totalPrice: 0, selectedItems: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                                  this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                    setTimeout(() => {
                                      if (isEdge || isIE) {
                                          window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                        } else {
                                          window.scrollTo({
                                            top: this.myRef.current.offsetTop - 110,
                                            left: 0,
                                            behavior: 'smooth'
                                          })
                                        }
                                    }, 50)
                                  })
                                } else {
                                  this.props.history.push('/login?redirect=/home/chuseok_gift_set');
                                  setTimeout(() => {
                                    this.props.routeChanges()
                                  }, 10)
                                }

                              }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                                <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                              </td>
                          )
                        } else {
                          return (
                              <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                                <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                              </td>
                          )
                        }
                      }
                    } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                      if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                        return (
                            <td onClick={() => {
                              if (this.state.userInfo) {
                                if (this.state.userInfo.phoneNumber) {
                                  this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                }
                                if (this.state.userInfo.username) {
                                  this.setState({name: this.state.userInfo.username})
                                }
                                this.setState({totalAmount: 0, totalPrice: 0, selectedItems: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                                this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                  setTimeout(() => {
                                    if (isEdge || isIE) {
                                        window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                      } else {
                                        window.scrollTo({
                                          top: this.myRef.current.offsetTop - 110,
                                          left: 0,
                                          behavior: 'smooth'
                                        })
                                      }
                                  }, 50)
                                })
                              } else {
                                this.props.history.push('/login?redirect=/home/chuseok_gift_set');
                                setTimeout(() => {
                                  this.props.routeChanges()
                                }, 10)
                              }

                            }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1) {
                          return (
                              <td onClick={() => {
                                if (this.state.userInfo) {
                                  if (this.state.userInfo.phoneNumber) {
                                    this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                  }
                                  if (this.state.userInfo.username) {
                                    this.setState({name: this.state.userInfo.username})
                                  }
                                  this.setState({totalAmount: 0, totalPrice: 0, selectedItems: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                                  this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                    setTimeout(() => {
                                      if (isEdge || isIE) {
                                          window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                        } else {
                                          window.scrollTo({
                                            top: this.myRef.current.offsetTop - 110,
                                            left: 0,
                                            behavior: 'smooth'
                                          })
                                        }
                                    }, 50)
                                  })
                                } else {
                                  this.props.history.push('/login?redirect=/home/chuseok_gift_set');
                                  setTimeout(() => {
                                    this.props.routeChanges()
                                  }, 10)
                                }

                              }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                                <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                              </td>
                          )
                        } else {
                          return (
                              <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                                <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                              </td>
                          )
                        }
                      }
                    } else {
                      return (
                          <td onClick={() => {
                            if (this.state.userInfo) {
                              if (this.state.userInfo.phoneNumber) {
                                this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                              }
                              if (this.state.userInfo.username) {
                                this.setState({name: this.state.userInfo.username})
                              }
                              this.setState({totalAmount: 0, totalPrice: 0, selectedItems: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                              this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                      window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                    } else {
                                      window.scrollTo({
                                        top: this.myRef.current.offsetTop - 110,
                                        left: 0,
                                        behavior: 'smooth'
                                      })
                                    }
                                }, 50)
                              })
                            } else {
                              this.props.history.push('/login?redirect=/home/chuseok_gift_set');
                              setTimeout(() => {
                                this.props.routeChanges()
                              }, 10)
                            }

                          }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                          </td>
                      )
                    }

                  }
                }
              } else if (days.format('MM') !== today.format('MM')) {
                return (
                    <td className="table-style" key={index} style={{height: 45}} >
                    </td>
                )
              } else {
                return (
                    <td className="table-style" key={index} style={{height: 45}} >
                      <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                    </td>
                )
              }
            }

          })
        }
        </tr>);
    }
    return result;
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  async onPaying(amount, orderId, orderName, customerName) {
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)

    let saveSessionData = {
      date: this.state.date,
      pickupOpen: this.state.pickupOpen,

      pickupDate: this.state.pickupDate,

      hourSelectedOption: this.state.hourSelectedOption,
      minuteSelectedOption: this.state.minuteSelectedOption,

      hour: this.state.hour,
      minute: this.state.minute,
      totalAmount: this.state.totalAmount,
      totalPrice: this.state.totalPrice,
      selectedItems: this.state.selectedItems,

      isModalOpen: this.state.isModalOpen,
      isOpenSheet: this.state.isOpenSheet,

      name: this.state.name,
      phoneNumber: this.state.phoneNumber
    }

    sessionStorage.setItem('giftSetpickupData', JSON.stringify(saveSessionData));

    tossPayments.requestPayment('카드', {
      amount: amount,
      orderId: orderId,
      orderName: orderName,
      customerName: customerName,
      successUrl: window.location.origin + '/gift_set_payment_result/success',
      failUrl: window.location.origin + '/gift_set_payment_result/fail',
    }).catch((error) => {
      if (error.code === 'USER_CANCEL') {
        sessionStorage.removeItem('giftSetpickupData');
        firebase.database().ref(`users/${this.state.userInfo.uid}/giftSetPickups/${this.state.pickupId}`).remove()
      }
    })
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day)/7);
    return weekOfMonth
  }

  onGetMonth(time) {
    return time.getMonth()
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    return lastDay
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {

      return sconeMenu[a.id].order - sconeMenu[b.id].order
    })
    return returnValue
  }

  onResetData() {
    this.setState({
      date: new Date(Date.now()),

      today: moment(),
      pickupDate: null,

      hourSelectedOption: '',
      minuteSelectedOption: '',

      hour: '',
      minute: '',
      totalAmount: 0,
      totalPrice: 0,
      selectedItems: {},

      isModalOpen: false,
      isOpenSheet: false,
      notEnoughInfoSnack: false,

      errorMessage: '',

      overTimeSnack: true,
      numberOfCoolings: '',
      numberOfBSetsSelectedOption: ''
    })
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({sconeMenu: snapshot.val()})
      }
    }).then(() => {
      firebase.database().ref(`pickupOpen`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({pickupOpen: snapshot.val()})
        }
      })
    })
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onRemoveScone(scone) {
    let selectedPickupScones = this.state.selectedPickupScones
    let sconeMenu = this.state.sconeMenu
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice

    if (selectedPickupScones[scone.id]) {
      if (totalAmount && selectedPickupScones[scone.id].amount !== 0) {
        totalAmount = totalAmount - 1
        totalPrice = totalPrice - scone.price
      }
      selectedPickupScones[scone.id] = {
        itemId: scone.id,
        name: sconeMenu[scone.id].name,
        image: sconeMenu[scone.id].image,
        amount: selectedPickupScones[scone.id].amount - 1,
        price: scone.price
      }
      if (selectedPickupScones[scone.id].amount === 0) {
        delete selectedPickupScones[scone.id]
      }
    }
    if (totalAmount < 1) {
      this.setState({showPickupScones: false})
    }
    this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
  }

  onAddScone(scone, item) {
    let selectedPickupScones = this.state.selectedPickupScones
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice
    let sconeMenu = this.state.sconeMenu

    if (item.amount || item.amount === 0) {
      if (item.amount !== 0) {
        if (selectedPickupScones[scone.id]) {
          if (item.amount > selectedPickupScones[scone.id].amount) {
            if (item.limit) {
              if (item.limit > selectedPickupScones[scone.id].amount) {
                if (this.state.pickupDate.amount > totalAmount) {
                  selectedPickupScones[scone.id] = {
                    itemId: scone.id,
                    name: sconeMenu[scone.id].name,
                    image: sconeMenu[scone.id].image,
                    amount: selectedPickupScones[scone.id].amount + 1,
                    price: scone.price
                  }
                  if (totalAmount) {
                    totalAmount = totalAmount + 1
                    totalPrice = totalPrice + scone.price
                  } else {
                    totalAmount = 1
                    totalPrice = scone.price
                  }

                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
                } else {
                  this.setState({noMoreSconesSnack: false}, () => {
                    setTimeout(() => {
                      this.setState({noMoreSconesSnack: true})
                    }, 10)
                  })
                }

              }
            } else {
              if (this.state.pickupDate.amount > totalAmount) {
                selectedPickupScones[scone.id] = {
                  itemId: scone.id,
                  name: sconeMenu[scone.id].name,
                  image: sconeMenu[scone.id].image,
                  amount: selectedPickupScones[scone.id].amount + 1,
                  price: scone.price
                }
                if (totalAmount) {
                  totalAmount = totalAmount + 1
                  totalPrice = totalPrice + scone.price
                } else {
                  totalAmount = 1
                  totalPrice = scone.price
                }

                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
              } else {
                this.setState({noMoreSconesSnack: false}, () => {
                  setTimeout(() => {
                    this.setState({noMoreSconesSnack: true})
                  }, 10)
                })
              }
            }
          } else {
            this.setState({noMoreItemsSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreItemsSnack: true})
              }, 10)
            })
          }
        } else {
          if (this.state.pickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              totalPrice = scone.price
            }
            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        this.setState({noMoreItemsSnack: false}, () => {
          setTimeout(() => {
            this.setState({noMoreItemsSnack: true})
          }, 10)
        })
      }
    } else {
      if (selectedPickupScones[scone.id]) {
        if (item.limit) {
          if (item.limit > selectedPickupScones[scone.id].amount) {
            if (this.state.pickupDate.amount > totalAmount) {
              selectedPickupScones[scone.id] = {
                itemId: scone.id,
                name: sconeMenu[scone.id].name,
                image: sconeMenu[scone.id].image,
                amount: selectedPickupScones[scone.id].amount + 1,
                price: scone.price
              }
              if (totalAmount) {
                totalAmount = totalAmount + 1
                totalPrice = totalPrice + scone.price
              } else {
                totalAmount = 1
                totalPrice = scone.price
              }

              this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
            } else {
              this.setState({noMoreSconesSnack: false}, () => {
                setTimeout(() => {
                  this.setState({noMoreSconesSnack: true})
                }, 10)
              })
            }

          }
        } else {
          if (this.state.pickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: selectedPickupScones[scone.id].amount + 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              totalPrice = scone.price
            }

            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        if (this.state.pickupDate.amount > totalAmount) {
          selectedPickupScones[scone.id] = {
            itemId: scone.id,
            name: sconeMenu[scone.id].name,
            image: sconeMenu[scone.id].image,
            amount: 1,
            price: scone.price
          }
          if (totalAmount) {
            totalAmount = totalAmount + 1
            totalPrice = totalPrice + scone.price
          } else {
            totalAmount = 1
            totalPrice = scone.price
          }
          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
        } else {
          this.setState({noMoreSconesSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreSconesSnack: true})
            }, 10)
          })
        }

      }
    }
  }

  onSortingByLetter(arr) {
    let returnValue = []
    returnValue = arr.sort((a, b) => {
      return a.order - b.order
    })
    return returnValue
  }


  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      })
    }
    let numberOfBSetsOption = [
      { value: '0', label: '추가 안함' }
    ]

    if (this.state.selectedItems['3teQ6S9hf']) {
      if (this.state.selectedItems['3teQ6S9hf'].amount !== 0) {
        Array.from(Array(this.state.selectedItems['3teQ6S9hf'].amount).keys()).map((value, index) => {
          numberOfBSetsOption[index + 1] = {
            value: (index + 1).toString(), label: (index + 1).toString() + '개'
          }
        })
      }
    } else {
      numberOfBSetsOption = [
        { value: '0', label: '추가 안함' }
      ]
    }

    let hourOption = []
    let minuteOption = []
    if (this.state.pickupDate) {
      if (this.onGetDay(this.state.pickupDate['date']) === '토' || this.onGetDay(this.state.pickupDate['date']) === '일') {
        hourOption = [
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' }
        ]

        if (this.state.hour === '5') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      } else {
        hourOption = [
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
          { value: '6', label: '6시' }
        ]
        if (this.state.hour === '6') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      }
    }
    if (!this.state.showPickupOrDeliveryButton) {
      return (
        <div className="center-div">
        </div>
      )
    }

    if (this.state.isLoading) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
        <Grid container alignItems="center" justify="center" direction="column">
        <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        <span className="text-center" style={{fontSize: 11, marginTop: 5}}>{this.state.waitMessage}</span>
        </Grid>
        </div>
      )
    }
    if (this.state.freePaymentVerifying) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>예약이 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.alreadyUsed) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>이미 사용된 쿠폰입니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.freePayment) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>예약 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>예약 목록 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
    return (
      <Grid className="main-view disable-double-tabs auto-width-100 no-selection" container justify="center">
        <div ref={this.introRef} className="max-height-100">
          <Image className="auto-width-100 max-height-100" src={giftSetProductInfo1_1} fluid />
          <Image className="auto-width-100 max-height-100" src={giftSetProductInfo1_2} fluid />
        </div>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div ref={this.bookingView} className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>예약 안내</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <div style={{margin: 20}}>
            <p className="auto-width-90" style={{fontSize: 11, marginBottom: 0, marginTop: 7, color: 'red', fontWeight: 'bold'}}>반드시 읽어주세요!</p>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>예약취소</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>취소는 픽업일 하루 전 16시까지만 가능 (픽업 전날 16시)</p>
            </div>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>픽업시간</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>평일 픽업은 6시 30분,<br/>토요일 픽업은 5시 30분이 마지막 예약 가능한 시간입니다.</p>
            </div>
          </div>
        </div>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>픽업예약 날짜 선택</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
          <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            { isMobileOnly && isSafari
              ?<table style={{width: window.innerWidth - 40, marginLeft: 20}}>
                <tbody>
                  { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                      return (
                        <td className="day-table-style" key={index} style={{height: 35}} >
                          { index === 0
                            ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                            :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                          }
                        </td>
                      )
                    })
                  }
                  { Object.keys(this.state.pickupOpen).length !== 0 &&
                    this.calendarArr()
                  }
                </tbody>
              </table>
              :<table className="auto-width-90">
                <tbody>
                  { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                      return (
                        <td className="day-table-style" key={index} style={{height: 35}} >
                          { index === 0
                            ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                            :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                          }

                        </td>
                      )
                    })
                  }
                  { Object.keys(this.state.pickupOpen).length !== 0 &&
                    this.calendarArr()
                  }
                </tbody>
              </table>
            }

          </Grid>
          </div>
        </div>
        { Object.keys(this.state.pickupOpen).length === 0 &&
          <div style={{paddingBottom: 20}} className="vertical-center-align direction-column-div auto-width-100">
            <HashLoader  color={'#006042'}  loading={true} size={20} />
          </div>
        }
        { this.state.pickupDate &&
          <div ref={this.myRef} className="auto-width-100">
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>픽업 시간 선택</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <div className="direction-column-div">
              <span style={{marginLeft: 20}}>
              {this.onStringNumberToDate(this.state.pickupDate['date'])} 픽업예약
              </span>
              <div className="direction-row-div" style={{margin: 18}}>
              <span style={{marginTop: 8, marginRight: 10}}>오후</span>
              <Select isSearchable={false} value={this.state.hourSelectedOption} onChange={(selectedOption) => {
                if (this.onGetDay(this.state.pickupDate['date']) === '토' || this.onGetDay(this.state.pickupDate['date']) === '일') {
                  if (selectedOption.value === '5') {
                    if (this.state.minute === '40' || this.state.minute === '50') {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, lastPickupTimeSnack: true})
                    } else {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                    }
                  } else {
                    this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                  }
                } else {
                  if (selectedOption.value === '6') {
                    if (this.state.minute === '40' || this.state.minute === '50') {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, weekdaysLastPickupTimeSnack: true})
                    } else {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                    }
                  } else {
                    this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                  }
                }

              }} className="select-style" styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                  borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                  }
                }),
                menu: (base, state) => ({
                   ...base,
                   marginBottom: 80,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  cursor: 'pointer',
                  ':active': {
                    ...styles[':active'],
                  backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                  backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? 'rgba(0, 135, 117, 0.95)'
                  : isFocused
                  ? 'rgba(0, 135, 117, 0.2)'
                  : null,
              }}
              }}
              placeholder='시' options={hourOption} />
              <Select isSearchable={false} value={this.state.minuteSelectedOption} onChange={(selectedOption) => {
                this.setState({minute: selectedOption.value, minuteSelectedOption: selectedOption})
              }} className="select-style" styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                  borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                  }
                }),
                menu: (base, state) => ({
                   ...base,
                   marginBottom: 80,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  cursor: 'pointer',
                  ':active': {
                    ...styles[':active'],
                  backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                  backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? 'rgba(0, 135, 117, 0.95)'
                  : isFocused
                  ? 'rgba(0, 135, 117, 0.2)'
                  : null,
              }}
            }} placeholder='분' options={minuteOption} />
              </div>
          </div>
          </div>
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>예약자 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <Grid className="auto-width-100" container justify="center">
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 10}} className="auto-width-100" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({name: event.target.value})
                }}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="예약자 이름" value={this.state.name} />
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 20, marginBottom: 20}} onChange={(event) => {
                if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                  this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                }
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
            </Grid>
          </div>
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>상품 선택</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <div>
            </div>
          </div>
          </div>
        }
        { this.state.pickupDate &&
          <div  className="auto-width-100">
          { this.onSortingByLetter(Object.values(this.state.giftSet)).map((item, index) => {
            return (
              <Grid container style={{marginTop: 0, marginBottom: 30, position: 'relative'}}  direction="row">
                <Grid item className="direction-row-div" justify="center" xs={4}>
                  <Image style={{width: 100, height: 100}} src={item.image} />
                </Grid>
                <Grid item xs={7}>
                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                </Grid>
                <Grid
                  item
                  className="direction-row-div no-selection"
                  style={{position: 'absolute', right: 20, bottom: 0}}
                >
                { this.state.selectedItems[item.id]
                  ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedItems[item.id].amount * item.price)}원</p>
                  :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                }
                { item.open === false || item.amount < 1
                  ?<div className="counter-button-div">
                    <div onClick={() => {
                    }} className="counter-button">
                      <RemoveIcon fontSize={"small"}/>
                    </div>
                    <div className="counter-style">
                      <p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                    </div>
                    <div onClick={() => {
                    }} className="counter-button">
                    <AddIcon fontSize={"small"}/>
                    </div>
                  </div>
                  :<div className="counter-button-div">
                    <div onClick={() => {
                      let selectedItems = this.state.selectedItems
                      let giftSet = this.state.giftSet
                      let totalAmount = this.state.totalAmount
                      let totalPrice = this.state.totalPrice

                      if (selectedItems[item.id]) {
                        if (totalAmount && selectedItems[item.id].amount !== 0) {
                          totalAmount = totalAmount - 1
                          totalPrice = totalPrice - item.price

                        }
                        selectedItems[item.id] = {
                          itemId: item.id,
                          name: giftSet[item.id].name,
                          image: giftSet[item.id].image,
                          amount: selectedItems[item.id].amount - 1,
                          price: item.price
                        }


                        if (selectedItems[item.id].amount === 0) {
                          delete selectedItems[item.id]
                        }
                      }

                      if (!selectedItems['3teQ6S9hf']) {
                        this.setState({numberOfCoolings: '', numberOfBSetsSelectedOption: ''})
                      } else {
                        if (Number(this.state.numberOfCoolings) > selectedItems['3teQ6S9hf'].amount) {
                          this.setState({numberOfCoolings: '', numberOfBSetsSelectedOption: ''})
                        }
                      }

                      this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedItems: selectedItems})
                      this.onCountSelectedItems(selectedItems)

                    }} className="counter-button">
                      <RemoveIcon fontSize={"small"}/>
                    </div>
                    <div className="counter-style">
                      <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedItems[item.id] ? this.state.selectedItems[item.id].amount : 0}</p>
                    </div>
                    <div onClick={() => {
                      let selectedItems = this.state.selectedItems
                      let totalAmount = this.state.totalAmount
                      let totalPrice = this.state.totalPrice
                      let giftSet = this.state.giftSet

                      let userInfo = this.state.userInfo

                      let amountInCart = 0

                      if (userInfo.cart) {
                        if (userInfo.cart[item.id]) {
                          amountInCart = userInfo.cart[item.id].amount
                        }
                      }


                      if (selectedItems[item.id]) {
                        if (this.state.items[item.id].amount - amountInCart > selectedItems[item.id].amount) {
                          selectedItems[item.id] = {
                            itemId: item.id,
                            name: giftSet[item.id].name,
                            image: giftSet[item.id].image,
                            amount: selectedItems[item.id].amount + 1,
                            price: item.price
                          }
                          if (totalAmount) {
                            totalAmount = totalAmount + 1
                            totalPrice = totalPrice + item.price
                          } else {
                            totalAmount = 1
                            totalPrice = item.price
                          }


                          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedItems: selectedItems})
                          this.onCountSelectedItems(selectedItems)
                        } else {
                          alert('재고가 부족합니다!')
                        }


                      } else {
                        if (this.state.items[item.id].amount - amountInCart > 0) {
                          selectedItems[item.id] = {
                            itemId: item.id,
                            name: giftSet[item.id].name,
                            image: giftSet[item.id].image,
                            amount: 1,
                            price: item.price
                          }

                          if (totalAmount) {
                            totalAmount = totalAmount + 1
                            totalPrice = totalPrice + item.price
                          } else {
                            totalAmount = 1
                            totalPrice = item.price
                          }


                          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedItems: selectedItems})
                          this.onCountSelectedItems(selectedItems)
                        } else {
                          alert('재고가 부족합니다!')
                        }

                      }





                    }} className="counter-button">
                    <AddIcon fontSize={"small"}/>
                    </div>
                  </div>
                }
                </Grid>
              </Grid>
            )

          })

          }
          </div>
        }
        { (this.state.showScrollDownBtn && this.state.totalAmount === 0) &&
          <div onClick={() => {
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.introRef.current.clientHeight)
                } else {
                  window.scrollTo({
                    top: this.introRef.current.clientHeight,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 10)
          }} className={"btn-bottom-cart-able no-selection none-underline-text"}>
            <div className="round-icon">
              <ScheduleIcon style={{color: '#006042', opacity: 0.95}}/>
            </div>
            <div style={{padding: 10, paddingTop: 16, paddingRight: 20}}>
            <p style={{color: 'white', fontSize: 11, padding: 0, margin: 0}}>지금 바로<br/>예약하기!</p>
            </div>
          </div>
        }

        { this.state.pickupDate &&
          <div onClick={() => {
            let {name, phoneNumber, hour, minute, pickupDate} = this.state

            this.setState({showPickupScones: false})

            if (name && phoneNumber && hour && minute && pickupDate['date']) {
              if (this.state.totalAmount > 0) {
                this.setState({isOpenSheet: true})
              }

            } else {
              if (!hour || !minute) {
                return this.setState({errorMessage: '픽업 시간을 선택해주세요.', notEnoughInfoSnack: true})
              }
              if (!name || !phoneNumber) {
                return this.setState({errorMessage: '예약자 정보를 입력해주세요.', notEnoughInfoSnack: true})
              }
            }
          }} className={this.state.totalAmount < 1 ? "bottom-pickup-button-active direction-column-div" : "bottom-pickup-button direction-column-div"} style={{backgroundColor: 'rgba(0, 135, 117, 0.95)', color: 'white', fontSize: 14, cursor: 'pointer'}}>
            예약하기
          </div>
        }
        <Footer/>
        <Modal
          open={this.state.isModalOpen}
          onClose={() => this.setState({isModalOpen: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="scone-image-modal">
          <span>진행 중인 내용이 모두 사라집니다. 나가시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({isModalOpen: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>나가기</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({isModalOpen: false})
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>머물기</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showSconemageModal}
          onClose={() => this.setState({showSconemageModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="scone-image-modal">
            <Image className="scone-image" src={this.state.selectedSconeImage} />
          </div>
        </Modal>

        { this.state.pickupDate &&
          <Sheet disableDrag={true} className="sheet-style" isOpen={this.state.isOpenSheet} onClose={() => this.setState({isOpenSheet: false})}>
          <Sheet.Container className="sheet-style" >
          <Sheet.Header className="sheet-header">
            <div className="text-center">
              <span style={{fontWeight: 'bold', fontSize: 18}}>선물세트 픽업예약</span>
            </div>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20}}
              onClick={() => {
                this.setState({
                  isOpenSheet: false
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
            </div>
          </Sheet.Header>
            <Sheet.Content className="auto-width-100 relative-position">

              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약시간</span>
                <span style={{fontSize: 14}}>{this.onStringNumberToDate(this.state.pickupDate['date'])} ({this.onGetDay(this.state.pickupDate['date'])}) 오후 {(this.state.hour && this.state.minute) ? this.state.hour + '시' : ''} {(this.state.hour && this.state.minute) ? this.state.minute + '분' : ''}</span>
                { !(this.state.hour && this.state.minute) &&
                  <span style={{fontSize: 13, color: 'red'}}>픽업 시간을 입력해주세요.</span>
                }
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약자</span>
                <span style={{fontSize: 14}}>{this.state.name}</span>
                <span style={{fontSize: 14}}>{this.onChangePhoneNumberFormat(this.state.phoneNumber)}</span>
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold'}}>예약상품</span>
                <div>
                  {Object.values(this.state.selectedItems).map((scone, index) => {
                    return (
                      <div className="direction-row-div" style={{marginTop: 10}}>
                        <Image style={{width: 50, height: 50, borderRadius: 5}} src={scone.image} />
                        <div className="direction-column-div" style={{marginLeft: 10, marginTop: 2}}>
                          <span style={{fontSize: 14}}>{scone.name}</span>
                          <span style={{fontSize: 16}}>{scone.amount}개 / {this.onChangeCurrencyFormat(scone.amount * scone.price)}원</span>
                        </div>
                      </div>
                    )
                  })}
                  </div>
              </div>
              { this.state.selectedItems['3teQ6S9hf'] &&
              <div style={{marginLeft: 20}}>
              <div style={{marginTop: 10, paddingRight: 20, marginBottom: 10}}>
              <span style={{fontSize: 13, fontWeight: 'bold'}}>보냉백 추가 옵션 (B 세트만 해당)</span>
              </div>
              <Select isSearchable={false} value={this.state.numberOfBSetsSelectedOption} onChange={(selectedOption) => {
                this.setState({numberOfBSetsSelectedOption: selectedOption, numberOfCoolings: selectedOption.value})
              }} className="select-style" styles={{
                control: (base, state) => ({
                  ...base,
                  width: 130,
                  boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                  borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                  }
                }),
                menu: (base, state) => ({
                   ...base,
                   marginBottom: 80,
                   width: 130
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  cursor: 'pointer',
                  ':active': {
                    ...styles[':active'],
                  backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                  backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? 'rgba(0, 135, 117, 0.95)'
                  : isFocused
                  ? 'rgba(0, 135, 117, 0.2)'
                  : null,
              }}
              }}
              placeholder='옵션 선택' options={numberOfBSetsOption} />
              <div style={{marginTop: 10, paddingRight: 20, marginBottom: 10}}>
              <span className="one-point-three-line-height" style={{fontSize: 12, color: 'red', fontWeight: 'bold', marginBottom: 3}}>반드시 읽어주세요!</span>
              <span className="one-point-three-line-height" style={{fontSize: 11}}>B세트의 딸기콩포트와 클로티드크림은 반드시 냉장보관해야하는 제품으로 되도록 당일 선물하시는 분들께 추천드리며, 다음날 선물하실 분들에 한해 아이스팩과 보냉백을 함께 넣어 드립니다. 사전 예약시 아이스팩,보냉백 옵션 추가 부탁드립니다.</span>
              </div>
              </div>
              }
              <div className="direction-column-div" style={{padding: 20}}>
              <span style={{fontWeight: 'bold'}}>요청사항</span>
              <Input style={{marginTop: 10}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({request: event.target.value})
                }}
              InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic" multiline={true} placeholder={"추가 요청사항을 적어주세요."} label="요청사항" value={this.state.request} />
              </div>
              <div className="direction-column-div" style={{padding: 20, marginBottom: 100}}>
                <span style={{fontWeight: 'bold'}}>결제정보</span>
                <div>
                <span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 10, marginBottom: 70}}>총 결제 금액 {this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                </div>

              </div>
              <a onClick={() => {
                if (this.onDateToTimeStamp(this.state.pickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {
                  this.setState({isOpenSheet: false})
                  this.onResetData()
                } else {
                  let pickupId = shortid.generate()
                  this.setState({isOpenSheet: false, pickupId: pickupId})
                  let pickupData = {}
                  let request = null
                  let numberOfCoolings = null
                  if (this.state.request) {
                    request = this.state.request
                  }
                  if (this.state.numberOfCoolings !== '') {
                    numberOfCoolings = this.state.numberOfCoolings
                  }
                  pickupData = {
                    giftSet: true,
                    pickupId: pickupId,
                    buyerUid: this.state.userInfo.uid,
                    buyerName: this.state.name,
                    phoneNumber: this.state.phoneNumber,
                    paidPrice: Math.max(this.state.totalPrice, 0),
                    items: this.state.selectedItems,
                    totalAmount: this.state.totalAmount,
                    totalPrice: this.state.totalPrice,
                    request: request,
                    numberOfCoolings: numberOfCoolings,
                    pickupDate: this.state.pickupDate['date'],
                    pickupTime: {
                      hour: this.state.hour,
                      minute: this.state.minute
                    },
                    purchasedAt: Date.now()
                  }

                  if (this.state.selectedItems['3teQ6S9hf']) {
                    if (numberOfCoolings === null) {
                      this.setState({isOpenSheet: true})
                      alert('세트B 구매시 보냉백 옵션을 반드시 선택해주세요.')
                    } else {
                      firebase.database().ref(`users/${this.state.userInfo.uid}/giftSetPickups/${pickupId}`).set(pickupData)
                      .then(() => {
                        this.onPaying((this.state.totalPrice), pickupId, `선물세트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                      })
                    }
                  } else {
                    firebase.database().ref(`users/${this.state.userInfo.uid}/giftSetPickups/${pickupId}`).set(pickupData)
                    .then(() => {
                      this.onPaying((this.state.totalPrice), pickupId, `선물세트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                    })
                  }




                }

              }} className="btn-very-bottom-big no-selection">
              <div>
              <p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.onChangeCurrencyFormat(this.state.totalPrice)}원 )</p>
              </div>


              </a>
            </Sheet.Content>
            </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
        }
        <Snackbar onClick={() => {
          if (this.state.errorMessage === '픽업 시간을 선택해주세요.') {
            this.setState({notEnoughInfoSnack: false,  errorMessage: ''})
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.myRef.current.offsetTop - 110)
                } else {
                  window.scrollTo({
                    top: this.myRef.current.offsetTop - 110,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 50)
          } else if (this.state.errorMessage === '예약자 정보를 입력해주세요.') {
            this.setState({notEnoughInfoSnack: false,  errorMessage: ''})
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.myRef.current.offsetTop + 95)
                } else {
                  window.scrollTo({
                    top: this.myRef.current.offsetTop + 95,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 50)
          }

        }} open={this.state.notEnoughInfoSnack} autoHideDuration={4000} onClose={() => this.setState({notEnoughInfoSnack: false})}>
          <Alert  onClose={() => this.setState({notEnoughInfoSnack: false,  errorMessage: ''})} severity="warning">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.overTimeSnack} autoHideDuration={4000} onClose={() => this.setState({overTimeSnack: false})}>
          <Alert  onClose={() => this.setState({overTimeSnack: false})} severity="warning">
            예약 가능시간이 지났습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.noMoreSconesSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreSconesSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreSconesSnack: false})} severity="warning">
            해당일에 예약가능한 모든 수량이 소진되었습니다!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.noMoreItemsSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreItemsSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreItemsSnack: false})} severity="warning">
            해당 상품의 재고가 모두 소진되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.overTimeItemsSnack} autoHideDuration={4000} onClose={() => this.setState({overTimeItemsSnack: false})}>
          <Alert  onClose={() => this.setState({overTimeItemsSnack: false})} severity="warning">
            예약 가능시간이 지났습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.lastPickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({lastPickupTimeSnack: false})}>
          <Alert  onClose={() => this.setState({lastPickupTimeSnack: false})} severity="warning">
            토요일 마지막 픽업 예약 가능 시간은 5시 30분입니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.couponExpireDateSnack} autoHideDuration={4000} onClose={() => this.setState({couponExpireDateSnack: false})}>
          <Alert  onClose={() => this.setState({couponExpireDateSnack: false})} severity="warning">
            유효기간이 지난 쿠폰입니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.weekdaysLastPickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({weekdaysLastPickupTimeSnack: false})}>
          <Alert  onClose={() => this.setState({weekdaysLastPickupTimeSnack: false})} severity="warning">
            평일 마지막 픽업 예약 가능 시간은 6시 30분입니다.
          </Alert>
        </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(ChuseokGiftSet);
