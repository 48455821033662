import React, {Component, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Beforeunload } from 'react-beforeunload';
import HashLoader from "react-spinners/HashLoader";
import shortid from 'shortid';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import cookieDivider from '../cookieDivider.png';
import rollDivider from '../rollDivider.png';
import sconeDivider from '../sconeDivider.png';
import bunDivider from '../bunDivider.png';
import financierDivider from '../financierDivider.png';
import giftSetDivider from '../giftSetDivider.png';
import cakeDivider from '../cakeDivider.png';

import Sheet from 'react-modal-sheet';

import {withRouter} from 'react-router-dom';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import { Prompt } from 'react-router'

import Select from 'react-select'

import { Image, Col, Row, Container } from 'react-bootstrap';
import ProgressBar from "@ramonak/react-progress-bar";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!

import moment from 'moment';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import pickup from '../Pickup.jpg';
import newYearGiftSet from '../newYearGiftSet.jpg';
import roundIcon from '../roundIcon.png';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import * as firebase from 'firebase';

import { loadTossPayments } from '@tosspayments/sdk';

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PickUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      date: new Date(Date.now()),
      sconeMenu: {},
      pickupOpen: {},

      showPickupScones: false,

      noMoreSconesSnack: false,
      noMoreItemsSnack: false,
      overTimeItemsSnack: false,

      today: moment(),
      pickupDate: null,

      hourSelectedOption: '',
      minuteSelectedOption: '',

      hour: '',
      minute: '',
      totalAmount: 0,
      totalPrice: 0,
      selectedPickupScones: {},

      isModalOpen: false,
      isOpenSheet: false,

      additionalPickups: null,
      selectedAdditiionalItems: {},
      additionalItemsPrice: 0,
      additionalItemsAmount: 0,

      name: '',
      phoneNumber: '',

      notEnoughInfoSnack: false,

      errorMessage: '',

      overTimeSnack: false,
      pickupId: '',

      weekdaysLastPickupTimeSnack: false,
      lastPickupTimeSnack: false,

      request: '',

      giftBox: 0,
      giftBoxInfo: {
        image: "https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/scone%2Fmenu_new_giftBox.png?alt=media&token=1c1c17b6-bc80-491d-a6cd-22a14615f8e1",
        explanation: '귀여운 디저트 박스! 선물할 때 이용해보세요! (롤과 크림번은 제외됨)',
        name: '위나 디저트 박스  (박스당 5~6개 주문시)',
        price: 1000
      },

      selectedSconeImage: null,

      usingCoupon: null,
      offlineCoupons: [],
      couponExpireDateSnack: false,

      noRemainAmount: false,
      freePayment: false,
      alreadyUsed: false,
      freePaymentVerifying: false,

      showCoupons: false,

      staffDiscount: null,
      waitMessage: '잠시만 기다려주세요...',
      pickupData: {},

      specialItem: false
    }
    this.myRef = React.createRef()
  }


  componentWillMount() {
    // let id = shortid.generate()
    // firebase.database().ref(`sconeMenu/${id}`).set({
    //   explanation: '선물상자 + 리본 + 먹는 방법 카드, ',
    //   id: id,
    //   image: 'https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/scone%2Fmenu_giftBox.png?alt=media&token=0136ce7e-efc7-4967-85dc-3fb49cdfa886',
    //   name: '디저트 박1스',
    //   open: true,
    //   order: 0,
    //   price: 4000
    // })
    setTimeout(() => {
      this.setState({loadingStart: true})
    }, 100)

    setTimeout(() => {
      this.setState({waitMessage: '디저트들을 불러오고 있습니다...'})
    }, 5000)

    setTimeout(() => {
      this.setState({waitMessage: '거의 다 됐습니다!!!'})
    }, 10000)

    firebase.database().ref(`additionalPickups`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({additionalPickups: snapshot.val()})
      }
    })
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({sconeMenu: snapshot.val()})
      }
    }).then(() => {
      firebase.database().ref(`pickupOpen`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({pickupOpen: snapshot.val()})

          // let data = snapshot.val()['20210915']
          // data.date = 20210918
          // firebase.database().ref(`pickupOpen/20210918`).set(data)
        }
      })
    })
  }

  componentDidMount() {

    if (sessionStorage.getItem('pickupData')) {
      let pickupData = JSON.parse(sessionStorage.getItem('pickupData'))

      sessionStorage.removeItem('pickupData')
    }

    if (Object.keys(this.props.pickupSconesObj).length !== 0) {
      let pickupSconesObj = this.props.pickupSconesObj

      this.setState(pickupSconesObj)
    }
  }

  componentWillUnmount() {

    if (this.props.onSavePickupSconesObj) {
      this.props.onSavePickupSconesObj({
        date: this.state.date,
        sconeMenu: this.state.sconeMenu,
        pickupOpen: this.state.pickupOpen,

        pickupDate: this.state.pickupDate,

        hourSelectedOption: this.state.hourSelectedOption,
        minuteSelectedOption: this.state.minuteSelectedOption,

        hour: this.state.hour,
        minute: this.state.minute,
        totalAmount: this.state.totalAmount,
        totalPrice: this.state.totalPrice,
        selectedPickupScones: this.state.selectedPickupScones,

        additionalPickups: this.state.additionalPickups,
        selectedAdditiionalItems: this.state.selectedAdditiionalItems,
        additionalItemsPrice: this.state.additionalItemsPrice,
        additionalItemsAmount: this.state.additionalItemsAmount,

        isModalOpen: this.state.isModalOpen,
        isOpenSheet: this.state.isOpenSheet,

        name: this.state.name,
        phoneNumber: this.state.phoneNumber,

        usingCoupon: this.state.usingCoupon
      })
    }
  }


  componentDidUpdate() {
  }


  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {

        if (!this.state.name) {
          this.setState({name: nextProps.userInfo.username });
        }
        if (!this.state.phoneNumber) {
          this.setState({phoneNumber: nextProps.userInfo.phoneNumber });
        }
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });

        if (nextProps.userInfo.staffDiscount) {
          this.setState({staffDiscount: nextProps.userInfo.staffDiscount})
        } else {
          this.setState({staffDiscount: null})
        }
      } else {
        this.setState({
          date: new Date(Date.now()),
          isLoading: false,
          sconeMenu: {},
          pickupOpen: {},

          today: moment(),
          pickupDate: null,

          hourSelectedOption: '',
          minuteSelectedOption: '',

          hour: '',
          minute: '',
          totalAmount: 0,
          totalPrice: 0,
          giftBox: 0,
          selectedPickupScones: {},

          name: '',
          phoneNumber: '',

          isModalOpen: false,
          isOpenSheet: false,
          additionalPickups: null
        })
      }

    } else {
      this.setState({ isLoading: true });
    }
  }

  onGetOfflineCoupons(userInfo) {

    firebase.database().ref(`coupons`).once('value', (snapshot) => {
      if (snapshot.val()) {

        let offlineCoupons = Object.values(snapshot.val())
        offlineCoupons = offlineCoupons.filter(offlineCoupon => offlineCoupon.phoneNumber === userInfo.phoneNumber)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => !offlineCoupon.usedAt)
        offlineCoupons = offlineCoupons.filter(offlineCoupon => moment(offlineCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString() >=  moment(Date.now()).format('YYYYMMDD').toString())
        this.setState({offlineCoupons: offlineCoupons, isOpenSheet: true})
      }
    })
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString()
    return stringNumber.substring(0, 4) + "년 " + stringNumber.substring(4, 6) + "월 " + stringNumber.substring(6, 8) + '일'
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
}

  onGetCurrentDateWithDay(day) {
    let today = new Date()
    let dd = day.toString().padStart(2, '0')
    let mm = (today.getMonth() + 1).toString().padStart(2, '0')
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`)
    return result
  }


  calendarArr(today) {

    const firstWeek = today.clone().startOf('month').week();
    const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();
    let result = [];
    let week = firstWeek;

    for ( week; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
        {
          Array(7).fill(0).map((data, index) => {
            let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
            if (this.state.pickupOpen[days.format('YYYYMMDD')]) {
              if (this.state.pickupOpen[days.format('YYYYMMDD')].amount <= 2) {
                return (
                    <td className="table-style" key={index} style={{backgroundColor:'gray', height: 45}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                    </td>
                )
              } else {

                if (this.onDateToTimeStamp(days.format('YYYYMMDD')) - (1000 * 60 * 60 * 18) < Date.now()) {
                  return (
                    <td className="table-style" key={index} style={{backgroundColor:'gray', height: 45}} >
                      <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                    </td>
                  )

                } else {
                  if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) === this.onGetWeek(new Date)) { // 마지막 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) {
                      return (
                          <td onClick={() => {
                            if (this.state.userInfo) {
                              if (this.state.userInfo.phoneNumber) {
                                this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                              }
                              if (this.state.userInfo.username) {
                                this.setState({name: this.state.userInfo.username})
                              }
                              this.setState({totalAmount: 0, totalPrice: 0, giftBox: 0, selectedAdditiionalItems: {}, additionalItemsPrice: 0, additionalItemsAmount: 0, selectedPickupScones: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: ''})
                              this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                      window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                    } else {
                                      window.scrollTo({
                                        top: this.myRef.current.offsetTop - 110,
                                        left: 0,
                                        behavior: 'smooth'
                                      })
                                    }
                                }, 50)
                              })
                            } else {
                              this.props.history.push('/login?redirect=/home/pickup');
                              setTimeout(() => {
                                this.props.routeChanges()
                              }, 10)
                            }

                          }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1 || this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 2) {
                        return (
                            <td onClick={() => {
                              if (this.state.userInfo) {
                                if (this.state.userInfo.phoneNumber) {
                                  this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                }
                                if (this.state.userInfo.username) {
                                  this.setState({name: this.state.userInfo.username})
                                }
                                this.setState({totalAmount: 0, totalPrice: 0, selectedAdditiionalItems: {}, additionalItemsPrice: 0, additionalItemsAmount: 0, giftBox: 0, selectedPickupScones: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: '', specialItem: false})
                                this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                  setTimeout(() => {
                                    if (isEdge || isIE) {
                                        window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                      } else {
                                        window.scrollTo({
                                          top: this.myRef.current.offsetTop - 110,
                                          left: 0,
                                          behavior: 'smooth'
                                        })
                                      }
                                  }, 50)
                                })
                              } else {
                                this.props.history.push('/login?redirect=/home/pickup');
                                setTimeout(() => {
                                  this.props.routeChanges()
                                }, 10)
                              }

                            }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else if (this.onGetWeek(this.onGetCurrentDateWithDay(this.onGetLastDayOfMonth())) - 1 === this.onGetWeek(new Date)) { // 마지막 전 주
                    if (this.onGetMonth(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === this.onGetMonth(new Date)) { // 현재와 같은 달
                      return (
                          <td onClick={() => {
                            if (this.state.userInfo) {
                              if (this.state.userInfo.phoneNumber) {
                                this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                              }
                              if (this.state.userInfo.username) {
                                this.setState({name: this.state.userInfo.username})
                              }
                              this.setState({totalAmount: 0, totalPrice: 0, selectedAdditiionalItems: {}, additionalItemsPrice: 0, additionalItemsAmount: 0, giftBox: 0, selectedPickupScones: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: '', specialItem: false})
                              this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                setTimeout(() => {
                                  if (isEdge || isIE) {
                                      window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                    } else {
                                      window.scrollTo({
                                        top: this.myRef.current.offsetTop - 110,
                                        left: 0,
                                        behavior: 'smooth'
                                      })
                                    }
                                }, 50)
                              })
                            } else {
                              this.props.history.push('/login?redirect=/home/pickup');
                              setTimeout(() => {
                                this.props.routeChanges()
                              }, 10)
                            }

                          }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                            <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                          </td>
                      )
                    } else {
                      if (this.onGetWeek(new Date(this.onDateToTimeStamp(days.format('YYYYMMDD')))) === 1) {
                        return (
                            <td onClick={() => {
                              if (this.state.userInfo) {
                                if (this.state.userInfo.phoneNumber) {
                                  this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                                }
                                if (this.state.userInfo.username) {
                                  this.setState({name: this.state.userInfo.username})
                                }
                                this.setState({totalAmount: 0, totalPrice: 0, selectedAdditiionalItems: {}, additionalItemsPrice: 0, additionalItemsAmount: 0, giftBox: 0, selectedPickupScones: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: '', specialItem: false})
                                this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                                  setTimeout(() => {
                                    if (isEdge || isIE) {
                                        window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                      } else {
                                        window.scrollTo({
                                          top: this.myRef.current.offsetTop - 110,
                                          left: 0,
                                          behavior: 'smooth'
                                        })
                                      }
                                  }, 50)
                                })
                              } else {
                                this.props.history.push('/login?redirect=/home/pickup');
                                setTimeout(() => {
                                  this.props.routeChanges()
                                }, 10)
                              }

                            }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      } else {
                        return (
                            <td className={"table-style"} key={index} style={{backgroundColor:'rgba(255, 148, 0, 0.95)', height: 45}} >
                              <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                            </td>
                        )
                      }
                    }
                  } else {
                    return (
                        <td onClick={() => {
                          if (this.state.userInfo) {
                            if (this.state.userInfo.phoneNumber) {
                              this.setState({phoneNumber: this.state.userInfo.phoneNumber})
                            }
                            if (this.state.userInfo.username) {
                              this.setState({name: this.state.userInfo.username})
                            }
                            this.setState({totalAmount: 0, totalPrice: 0, selectedAdditiionalItems: {}, additionalItemsPrice: 0, additionalItemsAmount: 0, giftBox: 0, selectedPickupScones: {}, hour: '', minute: '', minuteSelectedOption: '', hourSelectedOption: '', specialItem: false})
                            this.setState({pickupDate: this.state.pickupOpen[days.format('YYYYMMDD')]}, () => {
                              setTimeout(() => {
                                if (isEdge || isIE) {
                                    window.scrollTo(0, this.myRef.current.offsetTop - 110)
                                  } else {
                                    window.scrollTo({
                                      top: this.myRef.current.offsetTop - 110,
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }
                              }, 50)
                            })
                          } else {
                            this.props.history.push('/login?redirect=/home/pickup');
                            setTimeout(() => {
                              this.props.routeChanges()
                            }, 10)
                          }

                        }} className={this.state.pickupDate && this.state.pickupDate['date'].toString() === days.format('YYYYMMDD').toString() ? "table-style selected-table-style" : "table-style"} key={index} style={{backgroundColor:'rgba(0, 135, 117, 0.95)', height: 45, cursor: 'pointer'}} >
                          <span style={{color: 'white', marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                        </td>
                    )
                  }

                }
              }
            } else if (days.format('MM') !== today.format('MM')) {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            } else {
              return (
                  <td className="table-style" key={index} style={{height: 45}} >
                    <span style={{marginLeft: 4, fontSize: 14}}>{days.format('D')}</span>
                  </td>
              )
            }
          })
        }
        </tr>);
    }
    return result;
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  async onPaying(amount, orderId, orderName, customerName) {
    
    const clientKey = 'live_ck_4vZnjEJeQVx6aXj1adO8PmOoBN0k'
    const tossPayments = await loadTossPayments(clientKey)

    

    let saveSessionData = {
      date: this.state.date,
      sconeMenu: this.state.sconeMenu,
      pickupOpen: this.state.pickupOpen,

      pickupDate: this.state.pickupDate,

      hourSelectedOption: this.state.hourSelectedOption,
      minuteSelectedOption: this.state.minuteSelectedOption,

      hour: this.state.hour,
      minute: this.state.minute,
      totalAmount: this.state.totalAmount,
      totalPrice: this.state.totalPrice,
      selectedPickupScones: this.state.selectedPickupScones,

      additionalPickups: this.state.additionalPickups,
      selectedAdditiionalItems: this.state.selectedAdditiionalItems,
      additionalItemsPrice: this.state.additionalItemsPrice,
      additionalItemsAmount: this.state.additionalItemsAmount,

      isModalOpen: this.state.isModalOpen,
      isOpenSheet: this.state.isOpenSheet,

      name: this.state.name,
      phoneNumber: this.state.phoneNumber,

      usingCoupon: this.state.usingCoupon
    }

    // sessionStorage.setItem('pickupData', JSON.stringify(saveSessionData));

    tossPayments.requestPayment('카드', {
      amount: amount,
      orderId: orderId,
      orderName: orderName,
      customerName: customerName,
      successUrl: window.location.origin + '/payment_result/success',
      failUrl: window.location.origin + '/payment_result/fail',
    }).catch((error) => {
      
      if (error.code === 'USER_CANCEL') {
        sessionStorage.removeItem('pickupData');
        
        firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${this.state.pickupId}`).remove()
      }
    })
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day)/7);
    return weekOfMonth
  }

  onGetMonth(time) {
    return time.getMonth()
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    return lastDay
  }

  onGetDay(date) {
    let day = ''
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = '일'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1) {
      day = '월'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2) {
      day = '화'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3) {
      day = '수'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4) {
      day = '목'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5) {
      day = '금'
    } else if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6) {
      day = '토'
    }
    return day
  }

  sortByOrder(array) {
    let returnValue = array
    let sconeMenu = this.state.sconeMenu
    returnValue.sort((a, b) => {

      return sconeMenu[a.id].order - sconeMenu[b.id].order
    })
    return returnValue
  }

  onResetData() {
    this.setState({
      date: new Date(Date.now()),

      today: moment(),
      pickupDate: null,

      hourSelectedOption: '',
      minuteSelectedOption: '',

      hour: '',
      minute: '',
      totalAmount: 0,
      totalPrice: 0,
      giftBox: 0,
      selectedPickupScones: {},

      additionalPickups: null,
      selectedAdditiionalItems: {},
      additionalItemsPrice: 0,
      additionalItemsAmount: 0,

      isModalOpen: false,
      isOpenSheet: false,
      notEnoughInfoSnack: false,

      errorMessage: '',

      overTimeSnack: true
    })
    firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({sconeMenu: snapshot.val()})
      }
    }).then(() => {
      firebase.database().ref(`pickupOpen`).once('value', (snapshot) => {
        if (snapshot.val()) {
          this.setState({pickupOpen: snapshot.val()})
        }
      })
    })
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate)
    return Number(Math.floor(totalPrice/10) * 10)
  }

  onRemoveAdditionalItem(item) {
    if (this.onDateToTimeStamp(this.state.pickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {

    } else {
      let selectedAdditiionalItems = this.state.selectedAdditiionalItems
      let additionalItemsPrice = this.state.additionalItemsPrice
      let additionalItemsAmount = this.state.additionalItemsAmount

      if (selectedAdditiionalItems[item.id]) {
        if (selectedAdditiionalItems[item.id].amount === 1) {
          delete selectedAdditiionalItems[item.id]
          if (item.discountedPrice) {
            additionalItemsPrice = additionalItemsPrice - item.discountedPrice
          } else {
            additionalItemsPrice = additionalItemsPrice - item.price
          }

          additionalItemsAmount = additionalItemsAmount - 1
        } else {
          selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount - 1
          if (item.discountedPrice) {
            additionalItemsPrice = additionalItemsPrice - item.discountedPrice
          } else {
            additionalItemsPrice = additionalItemsPrice - item.price
          }

          additionalItemsAmount = additionalItemsAmount - 1
        }
      }


      this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
    }
  }

  onAddAdditionalItem(item) {
    if (this.onDateToTimeStamp(this.state.pickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {

      this.setState({overTimeItemsSnack: false}, () => {
        setTimeout(() => {
          this.setState({overTimeItemsSnack: true})
        }, 10)
      })
    } else {
      let selectedAdditiionalItems = this.state.selectedAdditiionalItems
      let additionalItemsPrice = this.state.additionalItemsPrice
      let additionalItemsAmount = this.state.additionalItemsAmount
      let additionalPickups = this.state.additionalPickups


      if (!selectedAdditiionalItems[item.id]) {
        if (this.state.pickupDate.additionalPickups[item.id].amount === 0) {
          this.setState({noMoreItemsSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreItemsSnack: true})
            }, 10)
          })
        } else {
          if (selectedAdditiionalItems[item.id]) {
            selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount + 1
            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          } else {
            if (item.discountedPrice) {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.discountedPrice
              }
            } else {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.price
              }
            }

            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }


            additionalItemsAmount = additionalItemsAmount + 1
          }


          this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
        }
      } else {
        if (this.state.pickupDate.additionalPickups[item.id].amount <= selectedAdditiionalItems[item.id].amount) {
          this.setState({noMoreItemsSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreItemsSnack: true})
            }, 10)
          })
        } else {
          if (selectedAdditiionalItems[item.id]) {
            selectedAdditiionalItems[item.id].amount = selectedAdditiionalItems[item.id].amount + 1
            if (item.discountedPrice) {
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          } else {
            if (item.discountedPrice) {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.discountedPrice
              }
              additionalItemsPrice = additionalItemsPrice + item.discountedPrice
            } else {
              selectedAdditiionalItems[item.id] = {
                itemId: item.id,
                name: item.name,
                image: item.image,
                amount: 1,
                price: item.price
              }
              additionalItemsPrice = additionalItemsPrice + item.price
            }

            additionalItemsAmount = additionalItemsAmount + 1
          }


          this.setState({selectedAdditiionalItems: selectedAdditiionalItems, additionalItemsPrice: additionalItemsPrice, additionalItemsAmount: additionalItemsAmount})
        }
      }
    }
  }

  async onCheckSpecialItem(selectedScones) {
    if (selectedScones && this.state.sconeMenu) {
      let sconeMenu = this.state.sconeMenu
      let specialItem = false
      if (sconeMenu) {
        await Object.keys(selectedScones).map(async(menuId, index) => {
          if (sconeMenu[menuId]['specialItem']) {
            specialItem = true
          }
        })
      }
      this.setState({specialItem: specialItem})
    }
  }

  onRemoveScone(scone) {
    let selectedPickupScones = this.state.selectedPickupScones
    let sconeMenu = this.state.sconeMenu
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice

    if (selectedPickupScones[scone.id]) {
      if (totalAmount && selectedPickupScones[scone.id].amount !== 0) {
        totalAmount = totalAmount - 1
        totalPrice = totalPrice - scone.price
      }
      selectedPickupScones[scone.id] = {
        itemId: scone.id,
        name: sconeMenu[scone.id].name,
        image: sconeMenu[scone.id].image,
        amount: selectedPickupScones[scone.id].amount - 1,
        price: scone.price
      }
      if (selectedPickupScones[scone.id].amount === 0) {
        delete selectedPickupScones[scone.id]
      }
    }
    if (totalAmount < 1) {
      this.setState({showPickupScones: false})
    }
    this.onCheckSpecialItem(selectedPickupScones)
    this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
  }

  onAddScone(scone, item) {
    let selectedPickupScones = this.state.selectedPickupScones
    let totalAmount = this.state.totalAmount
    let totalPrice = this.state.totalPrice
    let sconeMenu = this.state.sconeMenu

    if (item.amount || item.amount === 0) {
      if (item.amount !== 0) {
        if (selectedPickupScones[scone.id]) {
          if (item.amount > selectedPickupScones[scone.id].amount) {
            if (item.limit) {
              if (item.limit > selectedPickupScones[scone.id].amount) {
                if (this.state.pickupDate.amount > totalAmount) {
                  selectedPickupScones[scone.id] = {
                    itemId: scone.id,
                    name: sconeMenu[scone.id].name,
                    image: sconeMenu[scone.id].image,
                    amount: selectedPickupScones[scone.id].amount + 1,
                    price: scone.price
                  }
                  if (totalAmount) {
                    totalAmount = totalAmount + 1
                    totalPrice = totalPrice + scone.price
                  } else {
                    totalAmount = 1
                    if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                      totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
                    } else {
                      totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
                    }

                  }
                  this.onCheckSpecialItem(selectedPickupScones)
                  this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
                } else {
                  this.setState({noMoreSconesSnack: false}, () => {
                    setTimeout(() => {
                      this.setState({noMoreSconesSnack: true})
                    }, 10)
                  })
                }

              }
            } else {
              if (this.state.pickupDate.amount > totalAmount) {
                selectedPickupScones[scone.id] = {
                  itemId: scone.id,
                  name: sconeMenu[scone.id].name,
                  image: sconeMenu[scone.id].image,
                  amount: selectedPickupScones[scone.id].amount + 1,
                  price: scone.price
                }
                if (totalAmount) {
                  totalAmount = totalAmount + 1
                  totalPrice = totalPrice + scone.price
                } else {
                  totalAmount = 1
                  if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                    totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
                  } else {
                    totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
                  }
                }
                this.onCheckSpecialItem(selectedPickupScones)
                this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
              } else {
                this.setState({noMoreSconesSnack: false}, () => {
                  setTimeout(() => {
                    this.setState({noMoreSconesSnack: true})
                  }, 10)
                })
              }
            }
          } else {
            this.setState({noMoreItemsSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreItemsSnack: true})
              }, 10)
            })
          }
        } else {
          if (this.state.pickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
              } else {
                totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
              }
            }
            this.onCheckSpecialItem(selectedPickupScones)
            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        this.setState({noMoreItemsSnack: false}, () => {
          setTimeout(() => {
            this.setState({noMoreItemsSnack: true})
          }, 10)
        })
      }
    } else {
      if (selectedPickupScones[scone.id]) {
        if (item.limit) {
          if (item.limit > selectedPickupScones[scone.id].amount) {
            if (this.state.pickupDate.amount > totalAmount) {
              selectedPickupScones[scone.id] = {
                itemId: scone.id,
                name: sconeMenu[scone.id].name,
                image: sconeMenu[scone.id].image,
                amount: selectedPickupScones[scone.id].amount + 1,
                price: scone.price
              }
              if (totalAmount) {
                totalAmount = totalAmount + 1
                totalPrice = totalPrice + scone.price
              } else {
                totalAmount = 1
                if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                  totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
                } else {
                  totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
                }
              }
              this.onCheckSpecialItem(selectedPickupScones)
              this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
            } else {
              this.setState({noMoreSconesSnack: false}, () => {
                setTimeout(() => {
                  this.setState({noMoreSconesSnack: true})
                }, 10)
              })
            }

          }
        } else {
          if (this.state.pickupDate.amount > totalAmount) {
            selectedPickupScones[scone.id] = {
              itemId: scone.id,
              name: sconeMenu[scone.id].name,
              image: sconeMenu[scone.id].image,
              amount: selectedPickupScones[scone.id].amount + 1,
              price: scone.price
            }
            if (totalAmount) {
              totalAmount = totalAmount + 1
              totalPrice = totalPrice + scone.price
            } else {
              totalAmount = 1
              if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
              } else {
                totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
              }
            }
            this.onCheckSpecialItem(selectedPickupScones)
            this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
          } else {
            this.setState({noMoreSconesSnack: false}, () => {
              setTimeout(() => {
                this.setState({noMoreSconesSnack: true})
              }, 10)
            })
          }
        }
      } else {
        if (this.state.pickupDate.amount > totalAmount) {
          selectedPickupScones[scone.id] = {
            itemId: scone.id,
            name: sconeMenu[scone.id].name,
            image: sconeMenu[scone.id].image,
            amount: 1,
            price: scone.price
          }
          if (totalAmount) {
            totalAmount = totalAmount + 1
            totalPrice = totalPrice + scone.price
          } else {
            totalAmount = 1
            if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
              totalPrice = scone.price + (this.state.giftBox * this.state.pickupDate.giftBoxPrice)
            } else {
              totalPrice = scone.price + (this.state.giftBox * this.state.giftBoxInfo.price)
            }
          }
          this.onCheckSpecialItem(selectedPickupScones)
          this.setState({totalAmount: totalAmount, totalPrice: totalPrice, selectedPickupScones: selectedPickupScones})
        } else {
          this.setState({noMoreSconesSnack: false}, () => {
            setTimeout(() => {
              this.setState({noMoreSconesSnack: true})
            }, 10)
          })
        }

      }
    }
  }


  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      })
    }
    let hourOption = []
    let minuteOption = []
    if (this.state.pickupDate) {
      if (this.onGetDay(this.state.pickupDate['date']) === '토' || this.onGetDay(this.state.pickupDate['date']) === '일') {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
        ]

        if (this.state.hour === '5') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      } else {
        hourOption = [
          { value: '12', label: '12시' },
          { value: '1', label: '1시' },
          { value: '2', label: '2시' },
          { value: '3', label: '3시' },
          { value: '4', label: '4시' },
          { value: '5', label: '5시' },
          { value: '6', label: '6시' }
        ]
        if (this.state.hour === '6') {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' }
          ]
        } else {
          minuteOption = [
            { value: '00', label: '00분' },
            { value: '10', label: '10분' },
            { value: '20', label: '20분' },
            { value: '30', label: '30분' },
            { value: '40', label: '40분' },
            { value: '50', label: '50분' }
          ]
        }
      }
    }

    if (this.state.isLoading) {
      return (
        <div  className={this.state.loadingStart ? "center-div direction-column-div loading-animation1" : "center-div direction-column-div loading-animation2"}>
        <Grid container alignItems="center" justify="center" direction="column">
        <Image src={roundIcon} style={{width: 60, height: 60}} fluid />
        <span className="text-center" style={{fontSize: 11, marginTop: 5}}>{this.state.waitMessage}</span>
        </Grid>
        </div>
      )
    }
    if (this.state.freePaymentVerifying) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <BeatLoader  color={'#006042'} loading={true} size={20} />
            <span style={{fontSize: 14, marginTop: 15}}>예약이 진행 중입니다...</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.alreadyUsed) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
            <ErrorOutlineIcon  style={{fontSize: 100, color: '#fcd200'}} />
            <span style={{fontSize: 14, marginTop: 15}}>이미 사용된 쿠폰입니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>픽업 예약 페이지로 이동합니다.</span>
            <span style={{fontSize: 14, marginTop: 5}}>다시 시도해주세요.</span>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.freePayment) {
      return (
        <div>
          <div className="center-div">
            <div className="vertical-center-align direction-column-div auto-width-100">
              <CheckCircleOutlineIcon  style={{fontSize: 100, color: '#006042'}} />
              <span style={{fontSize: 14, marginTop: 15}}>예약 성공!</span>
              <span style={{fontSize: 14, marginTop: 5}}>예약 목록 페이지로 이동합니다.</span>
              <span style={{fontSize: 14, marginTop: 5}}>잠시만 기다려주세요...</span>
            </div>
          </div>
        </div>
      )
    }
    return (
      <Grid className="main-view disable-double-tabs auto-width-100 no-selection" container justify="center">
        <div className="max-height-100">
          <Image className="auto-width-100 max-height-100" src={pickup} fluid />
        </div>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>예약 안내</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <div style={{margin: 20}}>
            <p className="auto-width-90" style={{fontSize: 11, marginBottom: 0, marginTop: 7, color: 'red', fontWeight: 'bold'}}>반드시 읽어주세요!</p>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>최소수량</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>최소 디저트 3개 이상 부터 예약 가능</p>
            </div>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>구매제한</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>에그스콘과 누네띠네스콘은 최대 구매갯수 제한이 있을 수 있음</p>
            </div>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>예약날짜</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>예약은 픽업일 하루 전 15시까지 가능 (2주 전 오픈)<br/>(예약이 많을 시 조기 마감될 수 있습니다.)</p>
            </div>

            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>예약취소</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>취소는 픽업일 하루 전 16시까지만 가능 (픽업 전날 16시)</p>
            </div>
            <div className="direction-row-div">
              <p style={{fontSize: 12, marginRight: 12, color: 'gray', marginBottom: 0, marginTop: 7}}>픽업시간</p>
              <p className="auto-width-80" style={{fontSize: 11, marginBottom: 0, marginTop: 7}}>평일 픽업은 6시 30분,<br/>토요일 픽업은 5시 30분이 마지막 예약 가능한 시간입니다.</p>
            </div>
          </div>
        </div>
        <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
        <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
          <span style={{fontWeight: 'bold', marginLeft: 20}}>픽업예약 날짜 선택</span>
          <Divider className="auto-width-100" style={{marginTop: 20}} />
          <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
          <Grid className="auto-width-100 center-align" container justify="center" direction="column">
            { isMobileOnly && isSafari
              ?<div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
                <div onClick={()=>{ this.setState({today: this.state.today.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 80, marginLeft: 20}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
                <span style={{fontWeight: 'bold'}}>{this.state.today.format('YYYY년 MM월')}</span>
                <div onClick={()=>{ this.setState({today: this.state.today.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 80, marginRight: 0, marginRight: 20}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
              </div>
              :<div className="direction-row-div align-space-between align-center no-selection" style={{marginBottom: 10}}>
                <div onClick={()=>{ this.setState({today: this.state.today.clone().subtract(1, 'month')})  }} className="normal-very-small-button" style={{width: 60}}><ArrowBackIosIcon style={{fontSize: 10, color: 'white'}}/><span style={{fontSize: 12, color: 'white', marginRight: 1}}>이전달</span></div>
                <span style={{fontWeight: 'bold'}}>{this.state.today.format('YYYY년 MM월')}</span>
                <div onClick={()=>{ this.setState({today: this.state.today.clone().add(1, 'month')})  }} className="normal-very-small-button" style={{width: 60, marginRight: 0}}><span style={{fontSize: 12, color: 'white'}}>다음달</span><ArrowForwardIosIcon style={{fontSize: 10, color: 'white', marginLeft: 3}}/></div>
              </div>
            }

            { isMobileOnly && isSafari
              ?<table style={{width: window.innerWidth - 40, marginLeft: 20}}>
                <tbody>
                  { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                      return (
                        <td className="day-table-style" key={index} style={{height: 35}} >
                          { index === 0
                            ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                            :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                          }
                        </td>
                      )
                    })
                  }
                  { Object.keys(this.state.pickupOpen).length !== 0 &&
                    this.calendarArr(this.state.today)
                  }
                </tbody>
              </table>
              :<table className="auto-width-90">
                <tbody>
                  { ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
                      return (
                        <td className="day-table-style" key={index} style={{height: 35}} >
                          { index === 0
                            ?<span style={{fontSize: 12, marginLeft: 4, color: 'red'}}>{day}</span>
                            :<span style={{fontSize: 12, marginLeft: 4}}>{day}</span>
                          }

                        </td>
                      )
                    })
                  }
                  { Object.keys(this.state.pickupOpen).length !== 0 &&
                    this.calendarArr(this.state.today)
                  }
                </tbody>
              </table>
            }

          </Grid>
          </div>
          <Grid className="auto-width-100 center-align" container justify="center" direction="row" style={{marginBottom: 20, marginTop: 5}} >
            <div className="auto-width-90 direction-row-div">
              <div style={{backgroundColor: 'rgba(0, 135, 117, 0.95)', width: 13, height: 13}}>
              </div>
              <span style={{fontSize: 10, marginLeft: 5}}>예약가능(2주전~1일전 오후 3시)</span>
              <div style={{backgroundColor: 'rgba(255, 148, 0, 0.95)', width: 13, height: 13, marginLeft: 20}}>
              </div>
              <span style={{fontSize: 10, marginLeft: 5}}>예약오픈예정</span>
              <div style={{backgroundColor: 'gray', width: 13, height: 13, marginLeft: 20}}>
              </div>
              <span style={{fontSize: 10, marginLeft: 5}}>예약마감</span>
            </div>
          </Grid>
        </div>
        { Object.keys(this.state.pickupOpen).length === 0 &&
          <div style={{paddingBottom: 20}} className="vertical-center-align direction-column-div auto-width-100">
            <HashLoader  color={'#006042'}  loading={true} size={20} />
          </div>
        }
        { this.state.pickupDate &&
          <div ref={this.myRef} className="auto-width-100">
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>픽업 시간 선택</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <div className="direction-column-div">
              <span style={{marginLeft: 20}}>
              {this.onStringNumberToDate(this.state.pickupDate['date'])} 픽업예약
              </span>
              <div className="direction-row-div" style={{margin: 18}}>
              <span style={{marginTop: 8, marginRight: 10}}>오후</span>
              <Select isSearchable={false} value={this.state.hourSelectedOption} onChange={(selectedOption) => {
                if (this.onGetDay(this.state.pickupDate['date']) === '토' || this.onGetDay(this.state.pickupDate['date']) === '일') {
                  if (selectedOption.value === '5') {
                    if (this.state.minute === '40' || this.state.minute === '50') {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, lastPickupTimeSnack: true})
                    } else {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                    }
                  } else {
                    this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                  }
                } else {
                  if (selectedOption.value === '6') {
                    if (this.state.minute === '40' || this.state.minute === '50') {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption, minute: '30', minuteSelectedOption: {value: '30', label: '30분'}, weekdaysLastPickupTimeSnack: true})
                    } else {
                      this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                    }
                  } else {
                    this.setState({hour: selectedOption.value, hourSelectedOption: selectedOption})
                  }
                }

              }} className="select-style" styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                  borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                  }
                }),
                menu: (base, state) => ({
                   ...base,
                   marginBottom: 80,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  cursor: 'pointer',
                  ':active': {
                    ...styles[':active'],
                  backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                  backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? 'rgba(0, 135, 117, 0.95)'
                  : isFocused
                  ? 'rgba(0, 135, 117, 0.2)'
                  : null,
              }}
              }}
              placeholder='시' options={hourOption} />
              <Select isSearchable={false} value={this.state.minuteSelectedOption} onChange={(selectedOption) => {
                this.setState({minute: selectedOption.value, minuteSelectedOption: selectedOption})
              }} className="select-style" styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused ? "0 0 0 1px rgba(0, 135, 117, 0.9)" : 0,
                  borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "rgba(0, 135, 117, 0.9)" : "#787878"
                  }
                }),
                menu: (base, state) => ({
                   ...base,
                   marginBottom: 80,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  cursor: 'pointer',
                  ':active': {
                    ...styles[':active'],
                  backgroundColor: 'rgba(0, 135, 117, 0.5)'},
                  backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? 'rgba(0, 135, 117, 0.95)'
                  : isFocused
                  ? 'rgba(0, 135, 117, 0.2)'
                  : null,
              }}
            }} placeholder='분' options={minuteOption} />
              </div>
          </div>
          </div>
          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>예약자 정보</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <Grid className="auto-width-100" container justify="center">
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 10}} className="auto-width-100" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({name: event.target.value})
                }}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="예약자 이름" value={this.state.name} />
              <TextField style={{paddingLeft: 23, paddingRight: 23, marginTop: 20, marginBottom: 20}} onChange={(event) => {
                if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                  this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, '')})
                }
              }} className="auto-width-100"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}
              InputLabelProps={{style: {fontSize: 14, paddingLeft: 30}}} margin={"none"} id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
            </Grid>
          </div>




          <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
          <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
            <span style={{fontWeight: 'bold', marginLeft: 20}}>상품 선택</span>
            <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
            <div>
            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

              <Grid item className="direction-row-div" justify="center" xs={4}>
                <Image onClick={() => {
                  this.setState({selectedSconeImage: this.state.giftBoxInfo.image, showSconemageModal: true})
                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={this.state.giftBoxInfo.image} />
              </Grid>
              { isMobileOnly
                ?<Grid item xs={7}>
                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{this.state.giftBoxInfo.name}</p>
                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{this.state.giftBoxInfo.explanation}</p>
                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice ? this.onChangeCurrencyFormat(this.state.pickupDate.giftBoxPrice) : this.onChangeCurrencyFormat(this.state.giftBoxInfo.price)}원</p>
                </Grid>
                :<Grid item xs={7}>
                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{this.state.giftBoxInfo.name}</p>
                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{this.state.giftBoxInfo.explanation}</p>
                  <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice ? this.onChangeCurrencyFormat(this.state.pickupDate.giftBoxPrice) : this.onChangeCurrencyFormat(this.state.giftBoxInfo.price)}원</p>
                </Grid>
              }

              <Grid
                item
                className="direction-row-div no-selection"
                style={{position: 'absolute', right: 20, bottom: 0}}
              >
              { this.state.giftBox
                ?<p style={{fontSize: 14, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice ? this.onChangeCurrencyFormat(this.state.giftBox * this.state.pickupDate.giftBoxPrice) : this.onChangeCurrencyFormat(this.state.giftBox * this.state.giftBoxInfo.price)}원</p>
                :<p style={{fontSize: 14, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
              }
              <div className="counter-button-div">
                <div onClick={() => {
                  let giftBox = this.state.giftBox
                  let totalPrice = this.state.totalPrice
                  if (giftBox !== 0) {
                    giftBox = giftBox - 1
                    if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                      totalPrice = totalPrice - this.state.pickupDate.giftBoxPrice
                    } else {
                      totalPrice = totalPrice - 1000
                    }
                  }
                  this.setState({giftBox: giftBox, totalPrice: totalPrice})
                }} className="counter-button">
                  <RemoveIcon fontSize={"small"}/>
                </div>
                <div className="counter-style">
                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.giftBox}</p>
                </div>
                <div onClick={() => {
                  let giftBox = this.state.giftBox
                  let totalPrice = this.state.totalPrice
                  giftBox = giftBox + 1
                  if (this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice) {
                    totalPrice = totalPrice + this.state.pickupDate.giftBoxPrice
                  } else {
                    totalPrice = totalPrice + 1000
                  }
                  this.setState({giftBox: giftBox, totalPrice: totalPrice})
                }} className="counter-button">
                <AddIcon fontSize={"small"}/>
                </div>
              </div>
              </Grid>
            </Grid>
            </div>
            <Image
              className="auto-width-100"
              style={this.state.loaded ? {} : {display: 'none'}}
              src={cakeDivider}
              onLoad={() => this.setState({loaded: true})}
            />
            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'cake') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { scone.itemPreviousPrice
                                    ?<div className="direction-row-div no-selection">
                                    <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.itemPreviousPrice)}원</p>
                                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                    </div>
                                    :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  }
                                  { this.state.sconeMenu[scone.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  { scone.itemPreviousPrice
                                    ?<div className="direction-row-div no-selection">
                                    <p className="strike" style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.itemPreviousPrice)}원</p>
                                    <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                    </div>
                                    :<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  }
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }


                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
            <Image
              className="auto-width-100"
              style={this.state.loaded ? {} : {display: 'none'}}
              src={financierDivider}
              onLoad={() => this.setState({loaded: true})}
            />
            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'financier') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }


                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
            <Image
              className="auto-width-100"
              style={this.state.loaded ? {} : {display: 'none'}}
              src={bunDivider}
              onLoad={() => this.setState({loaded: true})}
            />
            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'bun') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }


                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
            { this.state.pickupDate &&
              <div>
              { this.onGetDay(this.state.pickupDate['date']) !== '화' &&
                <Image
                  className="auto-width-100"
                  style={this.state.loaded ? {} : {display: 'none'}}
                  src={rollDivider}
                  onLoad={() => this.setState({loaded: true})}
                />
              }
              </div>
            }

            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'roll') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }


                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
            <Image
              className="auto-width-100"
              style={this.state.loaded ? {} : {display: 'none'}}
              src={cookieDivider}
              onLoad={() => this.setState({loaded: true})}
            />
            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'cookie') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }

                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
            <Image
              className="auto-width-100"
              style={this.state.loaded ? {} : {display: 'none'}}
              src={sconeDivider}
              onLoad={() => this.setState({loaded: true})}
            />
            { this.state.pickupDate &&
              <div>
                { this.sortByOrder(Object.values(this.state.pickupDate.menu)).map((item, index) => {
                    if (Object.keys(this.state.sconeMenu).length !== 0) {

                      if (this.state.sconeMenu[item.id]) {
                        let scone = this.state.sconeMenu[item.id]
                        if (scone['category'] === 'scone') {
                          return (
                            <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">

                              <Grid item className="direction-row-div" justify="center" xs={4}>
                                <Image onClick={() => {
                                  this.setState({selectedSconeImage: scone.image, showSconemageModal: true})
                                }} style={{width: 100, height: 100, cursor: 'pointer'}} src={scone.image} />
                              </Grid>
                              { isMobileOnly
                                ?<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  { scone.itemPreviousdPrice
                                    ?<div className="direction-row-div no-selection">
                                    <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.itemPreviousdPrice)}원</p>
                                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                    </div>
                                    :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  }
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 13, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                                :<Grid style={{position: 'relative'}} item xs={7}>
                                  <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{scone.name}</p>
                                  <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{scone.explanation}</p>
                                  { scone.itemPreviousdPrice
                                    ?<div className="direction-row-div no-selection">
                                    <p className="strike" style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.itemPreviousdPrice)}원</p>
                                    <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                    </div>
                                    :<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(scone.price)}원</p>
                                  }
                                  { this.state.sconeMenu[item.id]['reviews'] && false &&
                                    <Grid
                                      item
                                      className="direction-row-div no-selection"
                                      style={{position: 'absolute', left: 0, bottom: 2, color: '#006042'}}
                                      onClick={() => {
                                        this.props.history.push(`/reviews/${item.id}`);
                                        setTimeout(() => {
                                          this.props.routeChanges()
                                        }, 10)
                                      }}
                                    >
                                      <span style={{fontSize: 14, cursor: 'pointer'}}>후기보기 ({Object.keys(this.state.sconeMenu[item.id]['reviews']).length})</span>
                                    </Grid>
                                  }
                                </Grid>
                              }


                              <Grid
                                item
                                className="direction-row-div no-selection"
                                style={{position: 'absolute', right: 20, bottom: 0}}
                              >
                              { this.state.selectedPickupScones[scone.id]
                                ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedPickupScones[scone.id].amount * scone.price)}원</p>
                                :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                              }
                              { item.limit &&
                                <span className="max-scone-number">최대 {item.limit}개 구매 가능</span>
                              }
                              <div className="counter-button-div">
                                <div onClick={() => {
                                  this.onRemoveScone(scone)
                                }} className="counter-button">
                                  <RemoveIcon fontSize={"small"}/>
                                </div>
                                <div className="counter-style">
                                  <p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedPickupScones[scone.id] ? this.state.selectedPickupScones[scone.id].amount : 0}</p>
                                </div>
                                <div onClick={() => {
                                  this.onAddScone(scone, item)
                                }} className="counter-button">
                                <AddIcon fontSize={"small"}/>
                                </div>
                              </div>
                              </Grid>
                            </Grid>
                          )
                        }

                      }
                    }
                  })
                }
              </div>
            }
          </div>
          </div>
        }
        { this.state.pickupDate &&
          <div  className="auto-width-100">
          { typeof(this.state.pickupDate.additionalPickups) === 'object' && this.state.additionalPickups &&
            <div className="auto-width-100">
              { this.state.pickupDate['date'].toString() &&
                <div className="auto-width-100">
                <Divider className="auto-width-100" style={{marginTop: 0, marginBottom: 20}} />
                <span style={{fontWeight: 'bold', marginLeft: 20}}>함께 하면 좋은 상품</span>
                <Divider className="auto-width-100" style={{marginTop: 20, marginBottom: 20}} />
                <span className="auto-width-100 justify-content-center" style={{marginBottom: 5, color: 'red', fontSize: 16, fontWeight: 'bold'}}>주문전 확인 사항!</span>
                <span className="auto-width-100 justify-content-center" style={{marginBottom: 0, fontSize: 12, fontWeight: 'bold'}}>스콘 할인 이벤트 적용 제외 (별도 할인 적용 상품)</span>
                { Object.values(this.state.pickupDate.additionalPickups).map((item, index) => {
                  return (
                    <div>
                    <Grid container style={{marginTop: 30, marginBottom: 30, position: 'relative'}}  direction="row">
                      <Grid item className="direction-row-div" justify="center" xs={4}>
                        <Image onClick={() => {
                          this.setState({selectedSconeImage: item.image, showSconemageModal: true})
                        }} style={{width: 100, height: 100, cursor: 'pointer'}} src={item.image} />
                      </Grid>
                      { isMobileOnly
                        ?<Grid item xs={7}>
                          <p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                          <p className="break-word" style={{fontSize: 11, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                          { item.discountedPrice
                            ?<div className="direction-column-div">
                              <div className="direction-row-div">
                                <p className="strike" style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                                <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.discountedPrice)}원</p>
                              </div>
                              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 3, fontWeight: 'bold', color: 'red'}}>{Math.round((1 - (item.discountedPrice / item.price)) * 100)}% 할인</p>
                            </div>
                            :<p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                          }
                        </Grid>
                        :<Grid item xs={7}>
                          <p style={{fontSize: 16, fontWeight: 'bold', margin: 0, padding: 0}}>{item.name}</p>
                          <p className="break-word" style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, color: 'gray'}}>{item.explanation}</p>
                          { item.discountedPrice
                            ?<div className="direction-column-div">
                              <div className="direction-row-div">
                                <p className="strike" style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5, marginRight: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                                <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.discountedPrice)}원</p>
                              </div>
                              <p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 3, fontWeight: 'bold', color: 'red'}}>{Math.round((1 - (item.discountedPrice / item.price)) * 100)}% 할인</p>
                            </div>
                            :<p style={{fontSize: 14, margin: 0, padding: 0, marginTop: 5}}>{this.onChangeCurrencyFormat(item.price)}원</p>
                          }
                        </Grid>
                      }

                      <Grid
                        item
                        className="direction-row-div no-selection"
                        style={{position: 'absolute', right: 20, bottom: 0}}
                      >
                      { Object.keys(this.state.selectedAdditiionalItems).length
                        ?this.state.selectedAdditiionalItems[item.id] &&
                        <div>
                          { item.discountedPrice
                            ?<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedAdditiionalItems[item.id].amount * item.discountedPrice)}원</p>
                            :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}>{this.onChangeCurrencyFormat(this.state.selectedAdditiionalItems[item.id].amount * item.price)}원</p>
                          }
                        </div>
                        :<p style={{fontSize: 13, fontWeight: 'bold', margin: 0, padding: 0, marginTop: 16, marginRight: 8}}></p>
                      }
                      <div className="counter-button-div">
                        <div onClick={() => {
                          this.onRemoveAdditionalItem(item)
                        }} className="counter-button">
                          <RemoveIcon fontSize={"small"}/>
                        </div>
                        <div className="counter-style">
                          { !(this.onDateToTimeStamp(this.state.pickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now())
                            ?this.state.pickupDate.additionalPickups[item.id].amount === 0
                            ?<p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                            :<p style={{fontSize: 12, margin: 0, padding: 0}}>{this.state.selectedAdditiionalItems[item.id] ? this.state.selectedAdditiionalItems[item.id].amount : 0}</p>
                            :<p style={{fontSize: 10, margin: 0, padding: 0}}>품절</p>
                          }

                        </div>
                        <div onClick={() => {
                          this.onAddAdditionalItem(item)
                        }} className="counter-button">
                        <AddIcon fontSize={"small"}/>
                        </div>
                      </div>
                      </Grid>
                    </Grid>
                    </div>
                  )
                })

                }
                </div>
              }
            </div>

          }
          </div>
        }



        { this.state.pickupDate
          ?(this.state.totalAmount > 2 || this.state.specialItem)
          ?<div onClick={() => {
            if (this.state.totalAmount >= 3) {
              this.setState({showPickupScones: !this.state.showPickupScones})
            }
          }} className={(this.state.totalAmount > 2 || this.state.specialItem) ? !this.state.showPickupScones ? "bottom-sheet-tip-with-items direction-column-div" : "bottom-sheet-tip-with-items-active direction-column-div" : "bottom-sheet-tip direction-column-div"} style={{backgroundColor: '#f0f0f0'}}>
            { this.state.showPickupScones
              ?<KeyboardArrowDownIcon  style={{fontSize: 15, color: 'black'}}/>
              :<KeyboardArrowUpIcon  style={{fontSize: 15, color: 'black'}}/>
            }

            { this.state.totalAmount !== 0 && this.state.additionalItemsAmount !== 0 &&
              <span style={{color: 'black', fontSize: 11, fontWeight: 'bold'}}>총 {this.state.totalAmount + this.state.additionalItemsAmount}개 상품 선택</span>
            }
            { this.state.totalAmount === 0 && this.state.additionalItemsAmount !== 0 &&
              <span style={{color: 'black', fontSize: 11, fontWeight: 'bold'}}>총 {this.state.additionalItemsAmount}개 상품 선택</span>
            }
            { this.state.totalAmount !== 0 && this.state.additionalItemsAmount === 0 &&
              <span style={{color: 'black', fontSize: 11, fontWeight: 'bold'}}>총 {this.state.totalAmount}개 상품 선택</span>
            }
          </div>
          :<div onClick={() => {
            if (this.state.totalAmount >= 1) {
              this.setState({showPickupScones: !this.state.showPickupScones})
            }
          }} className={this.state.showPickupScones ? "bottom-sheet-tip-with-only-pickups direction-column-div" : "bottom-sheet-tip direction-column-div"} style={{backgroundColor: '#f0f0f0'}}>
            <span style={{fontSize: 12, color: 'black', fontWeight: 'bold'}}>{this.state.totalAmount === 0 ? '픽업예약' : `디저트 ${this.state.totalAmount}개`}</span>
            <span style={{fontSize: 10, color: 'black'}}>(최소 3개)</span>
          </div>
          :<div></div>
        }
        { this.state.pickupDate && (this.state.totalAmount !== 0 || this.state.additionalItemsAmount !== 0) &&
          <div className="bottom-sheet-menu-tip-with-items-visible-container">
            <div className={this.state.totalAmount || this.state.additionalItemsAmount ? this.state.showPickupScones ?  (this.state.totalAmount > 2 || this.state.specialItem) ? "bottom-sheet-menu-tip-with-items-visible direction-column-div" : "bottom-sheet-menu-tip-with-only-pickups direction-column-div" : "bottom-sheet-menu-tip-with-items-invisible direction-column-div" : "bottom-sheet-menu-tip direction-column-div"} style={{backgroundColor: 'white'}}>

              { Object.values(this.state.selectedPickupScones).length
                ?<div className="direction-column-div  align-center">
                { Object.values(this.state.selectedPickupScones).map((scone, index) => {
                    return (
                      <div style={{marginBottom: 6}}  className="direction-row-div">
                        <RemoveIcon onClick={() => {
                          let sconeObj = scone
                          sconeObj['id'] = scone.itemId
                          this.onRemoveScone(sconeObj)
                        }} className="add-remove-round-button" style={{fontSize: 11, color: 'white'}} />
                        <span style={{fontSize: 12, color: 'black'}}>{scone.name} </span>
                        <span style={{fontSize: 12, color: 'black', fontWeight: 'bold', marginLeft: 3}}>{scone.amount}개</span>
                        <AddIcon onClick={() => {
                          let sconeObj = scone
                          sconeObj['id'] = scone.itemId
                          this.onAddScone(sconeObj, this.state.pickupDate.menu[scone.id])
                        }} className="add-remove-round-button" style={{fontSize: 11, color: 'white'}} />
                      </div>
                    )
                  })
                }
                </div>
                :<span></span>
              }
              { Object.values(this.state.selectedAdditiionalItems).length
                ?<div className="direction-column-div align-center">
                { Object.values(this.state.selectedAdditiionalItems).map((item, index) => {
                    return (
                      <div style={{marginBottom: 6}} className="direction-row-div">
                      <RemoveIcon onClick={() => {
                        let itemObj = item
                        itemObj['id'] = item.itemId
                        this.onRemoveAdditionalItem(itemObj)}
                      } className="add-remove-round-button" style={{fontSize: 11, color: 'white'}} />
                      <span style={{fontSize: 12, color: 'black'}}>{item.name} </span>
                      <span style={{fontSize: 12, color: 'black', fontWeight: 'bold', marginLeft: 3}}>{item.amount}개</span>
                      <AddIcon onClick={() => {
                        let itemObj = item
                        itemObj['id'] = item.itemId
                        this.onAddAdditionalItem(itemObj)}
                      } className="add-remove-round-button" style={{fontSize: 11, color: 'white'}} />
                      </div>
                    )
                  })
                }
                </div>
                :<span></span>
              }

            </div>
          </div>
        }
        { this.state.pickupDate &&
          <div onClick={() => {
            let {name, phoneNumber, hour, minute, pickupDate} = this.state

            this.setState({showPickupScones: false})

            if (name && phoneNumber && hour && minute && pickupDate['date']) {
              if ((this.state.totalAmount > 2 || this.state.specialItem)) {
                this.onGetOfflineCoupons(this.state.userInfo)
              }



            } else {
              if (!hour || !minute) {
                return this.setState({errorMessage: '픽업 시간을 선택해주세요.', notEnoughInfoSnack: true})
              }
              if (!name || !phoneNumber) {
                return this.setState({errorMessage: '예약자 정보를 입력해주세요.', notEnoughInfoSnack: true})
              }
            }
          }} className={(this.state.totalAmount > 2 || this.state.specialItem) ? "bottom-pickup-button direction-column-div" : "bottom-pickup-button-active direction-column-div" } style={{backgroundColor: 'rgba(0, 135, 117, 0.95)', color: 'white', fontSize: 14, cursor: 'pointer'}}>
            예약하기
          </div>
        }


        <Footer/>
        <Modal
          open={this.state.isModalOpen}
          onClose={() => this.setState({isModalOpen: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="scone-image-modal">
          <span>진행 중인 내용이 모두 사라집니다. 나가시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({isModalOpen: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>나가기</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            this.setState({isModalOpen: false})
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>머물기</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showSconemageModal}
          onClose={() => this.setState({showSconemageModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="scone-image-modal">
            <Image className="scone-image" src={this.state.selectedSconeImage} />
          </div>
        </Modal>
        { this.state.pickupDate &&
          <Sheet disableDrag={true} className="sheet-style" isOpen={this.state.isOpenSheet} onClose={() => this.setState({isOpenSheet: false})}>
          <Sheet.Container className="sheet-style" >
          <Sheet.Header className="sheet-header">
            <div className="text-center">
              <span style={{fontWeight: 'bold', fontSize: 18}}>픽업예약</span>
            </div>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', right: 20, top: 20}}
              onClick={() => {
                this.setState({
                  isOpenSheet: false
                })
              }}
            >
              <CloseIcon  style={{color: '#5e5e5e', fontSize: 26}} />
            </div>
          </Sheet.Header>
            <Sheet.Content className="auto-width-100 relative-position">

              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약시간</span>
                <span style={{fontSize: 14}}>{this.onStringNumberToDate(this.state.pickupDate['date'])} ({this.onGetDay(this.state.pickupDate['date'])}) 오후 {(this.state.hour && this.state.minute) ? this.state.hour + '시' : ''} {(this.state.hour && this.state.minute) ? this.state.minute + '분' : ''}</span>
                { !(this.state.hour && this.state.minute) &&
                  <span style={{fontSize: 13, color: 'red'}}>픽업 시간을 입력해주세요.</span>
                }
              </div>
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold', marginBottom: 5}}>예약자</span>
                <span style={{fontSize: 14}}>{this.state.name}</span>
                <span style={{fontSize: 14}}>{this.onChangePhoneNumberFormat(this.state.phoneNumber)}</span>
              </div>
              { this.state.giftBox !== 0 &&
                <div className="direction-column-div" style={{padding: 20}}>
                  <span style={{fontWeight: 'bold'}}>포장</span>
                    <div>
                      <div className="direction-row-div" style={{marginTop: 10}}>
                        <Image style={{width: 50, height: 50, borderRadius: 5}} src={this.state.giftBoxInfo.image} />
                        <div className="direction-column-div" style={{marginLeft: 10, marginTop: 2}}>
                          <span style={{fontSize: 14}}>{this.state.giftBoxInfo.name}</span>
                          { this.state.pickupDate.giftBoxPrice === 0 || this.state.pickupDate.giftBoxPrice
                            ?<span style={{fontSize: 16}}>{this.state.giftBox}개 / {this.onChangeCurrencyFormat(this.state.giftBox * this.state.pickupDate.giftBoxPrice)}원</span>
                            :<span style={{fontSize: 16}}>{this.state.giftBox}개 / {this.onChangeCurrencyFormat(this.state.giftBox * this.state.giftBoxInfo.price)}원</span>
                          }

                        </div>
                      </div>
                    </div>
                </div>
              }
              <div className="direction-column-div" style={{padding: 20}}>
                <span style={{fontWeight: 'bold'}}>예약상품</span>
                <div>
                  {Object.values(this.state.selectedPickupScones).map((scone, index) => {
                    return (
                      <div className="direction-row-div" style={{marginTop: 10}}>
                        <Image style={{width: 50, height: 50, borderRadius: 5}} src={scone.image} />
                        <div className="direction-column-div" style={{marginLeft: 10, marginTop: 2}}>
                          <span style={{fontSize: 14}}>{scone.name}</span>
                          <span style={{fontSize: 16}}>{scone.amount}개 / {this.onChangeCurrencyFormat(scone.amount * scone.price)}원</span>
                        </div>
                      </div>
                    )
                  })}
                  </div>
                  <div>
                    {Object.values(this.state.selectedAdditiionalItems).map((item, index) => {
                      return (
                        <div className="direction-row-div" style={{marginTop: 10}}>
                          <Image style={{width: 50, height: 50, borderRadius: 5}} src={item.image} />
                          <div className="direction-column-div" style={{marginLeft: 10, marginTop: 2}}>
                            <span style={{fontSize: 14}}>{item.name}</span>
                            { item.discountedPrice
                              ?<span style={{fontSize: 16}}>{item.amount}개 / {this.onChangeCurrencyFormat(item.amount * item.discountedPrice)}원</span>
                              :<span style={{fontSize: 16}}>{item.amount}개 / {this.onChangeCurrencyFormat(item.amount * item.price)}원</span>
                            }

                          </div>
                        </div>
                      )
                    })}
                    </div>
              </div>
              { this.state.offlineCoupons.length ?
                <div style={{marginBottom: 10}} className="auto-width-100">
                    <span style={{fontWeight: 'bold', margin: 20}}>쿠폰 정보</span>
                    <div className="auto-width-100  justify-content-center">
                    <Divider style={{marginTop: 25}} className="auto-width-90" />
                    </div>
                    <Grid onClick={() => {
                      this.setState({showCoupons: !this.state.showCoupons})
                    }} style={{cursor: 'pointer', marginTop: 10}} className="auto-width-100 center-align" container justify="center" direction="column">
                    { this.state.usingCoupon && !this.state.showCoupons &&
                      <Grid alignItems="center" justify="center" direction="column" className="auto-width-100 center-align">
                        <span style={{fontSize: 15, marginTop: 5, fontWeight: 'bold'}}>사용할 쿠폰</span>
                        <span style={{fontSize: 13, marginTop: 5}}>{this.state.usingCoupon.name} {this.onChangeCurrencyFormat(this.state.usingCoupon.amount)}원 쿠폰</span>
                        { this.state.noRemainAmount &&
                          <span style={{fontSize: 12, color: 'red', marginTop: 5}}>쿠폰 잔액은 환불되지 않습니다.</span>
                        }
                        <Divider style={{width: 20, height: 5, marginTop: 10, borderRadius: 10, color: 'rgba(0, 135, 117, 0.95)'}} />
                      </Grid>
                    }
                    { this.state.showCoupons
                      ?<span className="auto-width-100 justify-content-center" style={{fontSize: 12, marginTop: 10}}>사용할 쿠폰을 선택해주세요. (중복 사용 불가)</span>
                      :<span className="auto-width-100 justify-content-center" style={{fontSize: 12, marginTop: 10}}>사용 가능 쿠폰 {this.state.offlineCoupons.length}개</span>
                    }
                    <Grid className="auto-width-100 center-align" container justify="center" direction="column">
                    { this.state.showCoupons
                      ?<KeyboardArrowUpIcon  style={{marginTop: 5}}/>
                      :<KeyboardArrowDownIcon style={{marginTop: 5}}/>
                    }
                    </Grid>
                    </Grid>
                    { this.state.showCoupons &&
                      <Grid container alignItems="center" justify="center" direction="column">
                      { this.state.offlineCoupons.map((coupon, index) => {
                        return (
                          <div onClick={() => {
                            if (this.state.usingCoupon) {
                              if (this.state.usingCoupon.couponId === coupon.couponId) {
                                this.setState({usingCoupon: null, noRemainAmount: false})
                              } else {
                                this.setState({usingCoupon: coupon, noRemainAmount: false, showCoupons: false})
                                if (this.state.pickupDate.discount) {
                                  if (this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice) {
                                    if (this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - coupon.amount < 0) {
                                      this.setState({noRemainAmount: true})
                                    } else {
                                      if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                        this.setState({noRemainAmount: true})
                                      }
                                    }
                                  } else {
                                    if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                      this.setState({noRemainAmount: true})
                                    }
                                  }
                                } else {
                                  if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                    this.setState({noRemainAmount: true})
                                  }
                                }
                              }

                            } else {
                              this.setState({usingCoupon: coupon, noRemainAmount: false, showCoupons: false})
                              if (this.state.pickupDate.discount) {
                                if (this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice) {
                                  if (this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - coupon.amount < 0) {
                                    this.setState({noRemainAmount: true})
                                  } else {
                                    if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                      this.setState({noRemainAmount: true})
                                    }
                                  }
                                } else {
                                  if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                    this.setState({noRemainAmount: true})
                                  }
                                }

                              } else {
                                if (this.state.totalPrice + this.state.additionalItemsPrice - coupon.amount < 0) {
                                  this.setState({noRemainAmount: true})
                                }
                              }
                            }
                          }} className={this.state.usingCoupon ? this.state.usingCoupon.couponId === coupon.couponId ? "coupon-add-btn-selected direction-row-div align-center" : "coupon-add-btn direction-row-div align-center" : "coupon-add-btn direction-row-div align-center"} style={{padding: 20}}>
                          <Grid container style={{marginTop: 5, marginBottom: 10}} direction="column">
                            <span style={{fontSize: 12}}>금액: {this.onChangeCurrencyFormat(coupon.amount)}원</span>
                            <span style={{fontSize: 12}}>유효기간: {this.onStringNumberToDate(moment(coupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString())}</span>
                          </Grid>
                          </div>
                        )
                      })
                      }
                      </Grid>
                    }
                    <div className="auto-width-100  justify-content-center">
                    <Divider style={{marginTop: 10, marginBottom: 5}} className="auto-width-90" />
                    </div>
                </div>
                :<div></div>
              }

              <div className="direction-column-div" style={{padding: 20}}>
              <span style={{fontWeight: 'bold'}}>요청사항</span>
              <Input style={{marginTop: 10}} className="auto-width-90" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                onChange={(event) => {
                  this.setState({request: event.target.value})
                }}
              InputLabelProps={{style: {fontSize: 14}}} hiddenLabel margin={"none"} id="standard-basic" multiline={true} placeholder={"예) 플레인스콘 4개는 디저트박스 포장 부탁드립니다."} label="요청사항" value={this.state.request} />
              </div>
              <div className="direction-column-div" style={{padding: 20, paddingBottom: 50}}>
                <span style={{fontWeight: 'bold'}}>결제정보</span>
                { this.state.pickupDate.discount
                  ?this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice
                  ?<div className="direction-column-div">

                    <span style={{fontSize: 12, marginTop: 5, color: '#006042', marginBottom: 5, fontWeight: 'bold'}}>{this.state.pickupDate.discount.title} {Math.round(this.state.pickupDate.discount.rate * 100)}% 적용!</span>
                    <ProgressBar  height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={(this.state.totalPrice / this.state.pickupDate.discount.minDiscountPrice) * 100} />
                    { this.state.giftBox
                      ?<span style={{fontSize: 14, marginTop: 10}}>디저트 총 {this.state.totalAmount}개 ( + 디저트 박스 {this.state.giftBox}개 ) / {this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                      :<span style={{fontSize: 14, marginTop: 10}}>디저트 총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                    }

                    <span style={{fontSize: 14, marginTop: 5}}>{this.state.pickupDate.discount.title} -{this.onChangeCurrencyFormat(Math.round(this.state.pickupDate.discount.rate * this.state.totalPrice))}원</span>

                    { this.state.additionalItemsAmount > 0 &&
                      <div className="direction-column-div">
                        <span style={{fontSize: 14, marginTop: 6}}>추가상품 총 {this.state.additionalItemsAmount}개 / {this.onChangeCurrencyFormat(this.state.additionalItemsPrice)}원</span>
                      </div>
                    }

                    { this.state.usingCoupon &&
                      <span style={{fontSize: 14, marginTop: 5}}>쿠폰 할인 -{this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.state.totalPrice + this.state.additionalItemsPrice))}원</span>
                    }

                    { this.state.staffDiscount &&
                      <span style={{fontSize: 14, marginTop: 5}}>직원 할인 (추가 {this.state.staffDiscount.discountRate}% 할인)</span>
                    }

                    { this.state.staffDiscount
                      ?<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 5, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) * (1 - (this.state.staffDiscount.discountRate * 0.01)))  : this.onChangeCurrencyFormat((this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원</span>
                      :<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 5, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0))  : this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice)}원</span>
                    }


                  </div>
                  :
                  <div className="direction-column-div">

                    <span style={{fontSize: 13, marginTop: 5, color: 'red'}}>{this.state.pickupDate.discount.title} {Math.round(this.state.pickupDate.discount.rate * 100)}% ( 추가 상품 제외 {this.onChangeCurrencyFormat(this.state.pickupDate.discount.minDiscountPrice)}원 이상 주문시 )</span>
                    <span style={{fontSize: 12, marginTop: 5, color: '#006042', marginBottom: 5, fontWeight: 'bold'}}>{this.onChangeCurrencyFormat(this.state.pickupDate.discount.minDiscountPrice - this.state.totalPrice)}원만 더 담으면 할인 적용!</span>
                    <ProgressBar  height={10} bgColor={'rgba(0, 135, 117, 0.8)'} isLabelVisible={false} completed={(this.state.totalPrice / this.state.pickupDate.discount.minDiscountPrice) * 100} />
                    { this.state.giftBox
                      ?<span style={{fontSize: 14, marginTop: 10}}>디저트 총 {this.state.totalAmount}개 ( + 디저트 박스 {this.state.giftBox}개 ) / {this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                      :<span style={{fontSize: 14, marginTop: 10}}>디저트 총 {this.state.totalAmount}개 / {this.onChangeCurrencyFormat(this.state.totalPrice)}원</span>
                    }


                    { this.state.additionalItemsAmount > 0 &&
                      <div className="direction-column-div">
                        <span style={{fontSize: 14, marginTop: 6}}>추가상품 총 {this.state.additionalItemsAmount}개 / {this.onChangeCurrencyFormat(this.state.additionalItemsPrice)}원</span>
                      </div>
                    }
                    { this.state.usingCoupon &&
                      <span style={{fontSize: 14, marginTop: 5}}>쿠폰 할인 -{this.onChangeCurrencyFormat(Math.min(this.state.usingCoupon.amount, this.state.totalPrice + this.state.additionalItemsPrice))}원</span>
                    }

                    { this.state.staffDiscount &&
                      <span style={{fontSize: 14, marginTop: 5}}>직원 할인 (추가 {this.state.staffDiscount.discountRate}% 할인)</span>
                    }

                    { this.state.staffDiscount
                      ?<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 10, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) * (1 - (this.state.staffDiscount.discountRate * 0.01))) : this.onChangeCurrencyFormat((this.state.totalPrice + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원</span>
                      :<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 10, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) : this.onChangeCurrencyFormat(this.state.totalPrice + this.state.additionalItemsPrice)}원</span>
                    }



                  </div>


                  :<div>
                  { this.state.staffDiscount &&
                    <span style={{fontSize: 14, marginTop: 5}}>직원 할인 (추가 {this.state.staffDiscount.discountRate}% 할인)</span>
                  }
                  { this.state.staffDiscount
                    ?<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 10, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) * (1 - (this.state.staffDiscount.discountRate * 0.01))) : this.onChangeCurrencyFormat((this.state.totalPrice + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원</span>
                    :<span style={{fontSize: 15, color: '#006042', fontWeight: 'bold', marginTop: 10, marginBottom: 70}}>총 결제 금액 {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) : this.onChangeCurrencyFormat(this.state.totalPrice + this.state.additionalItemsPrice)}원</span>
                  }

                  </div>

                }

              </div>
              <a onClick={() => {
                if (this.state.usingCoupon) {
                  if (moment(this.state.usingCoupon.issuedAt + (24 * 1000 * 60 * 60 * 180)).format('YYYYMMDD').toString() <  moment(Date.now()).format('YYYYMMDD').toString()) {
                    return alert('유효기간이 지난 쿠폰입니다.')
                  }
                }



                let free = null

                if (this.state.pickupDate.discount) {
                  if (this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice) {
                    if (this.state.usingCoupon) {
                      if (this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount <= 0) {
                        free = true
                      }
                    }
                  } else {
                    if (this.state.usingCoupon) {
                      if (this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount <= 0) {
                        free = true
                      }
                    }
                  }
                } else {
                  if (this.state.usingCoupon) {
                    if (this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount <= 0) {
                      free = true
                    }
                  }
                }

                let couponDiscount = 0
                let usingCoupon = null
                if (this.state.usingCoupon) {
                  couponDiscount = this.state.usingCoupon.amount
                  usingCoupon = this.state.usingCoupon
                  usingCoupon['usedAt'] = Date.now()
                }

                if (this.onDateToTimeStamp(this.state.pickupDate['date'].toString()) - (1000 * 60 * 60 * 18) < Date.now()) {
                  this.setState({isOpenSheet: false})
                  this.onResetData()
                } else {
                  let pickupId = shortid.generate()
                  this.setState({isOpenSheet: false, pickupId: pickupId})
                  let pickupData = {}
                  let request = null
                  let giftBox = null
                  let staffDiscount = null
                  let staffDiscountRate = 1
                  if (this.state.staffDiscount) {
                    staffDiscountRate = 1 - (this.state.staffDiscount.discountRate * 0.01)
                    staffDiscount = this.state.staffDiscount.discountRate
                  }
                  if (this.state.request) {
                    request = this.state.request
                  }
                  if (this.state.giftBox) {
                    giftBox = this.state.giftBox
                  }
                  if (this.state.pickupDate.discount) {
                    if (this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice) {
                      pickupData = {
                        pickupId: pickupId,
                        buyerUid: this.state.userInfo.uid,
                        buyerName: this.state.name,
                        phoneNumber: this.state.phoneNumber,
                        paidPrice: (Math.max(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - couponDiscount, 0)) * staffDiscountRate,
                        items: this.state.selectedPickupScones,
                        additionalItems: this.state.selectedAdditiionalItems,
                        additionalItemsAmount: this.state.additionalItemsAmount,
                        additionalItemsPrice: this.state.additionalItemsPrice,
                        totalAmount: this.state.totalAmount,
                        totalPrice: this.state.totalPrice + this.state.additionalItemsPrice,
                        staffDiscount: staffDiscount,
                        usingCoupon: usingCoupon,
                        request: request,
                        giftBox: giftBox,
                        pickupDate: this.state.pickupDate['date'],
                        discount: this.state.pickupDate.discount,
                        pickupTime: {
                          hour: this.state.hour,
                          minute: this.state.minute
                        },
                        purchasedAt: Date.now()
                      }
                    } else {
                      pickupData = {
                        pickupId: pickupId,
                        buyerUid: this.state.userInfo.uid,
                        buyerName: this.state.name,
                        phoneNumber: this.state.phoneNumber,
                        paidPrice: (Math.max(this.state.totalPrice + this.state.additionalItemsPrice - couponDiscount, 0)) * staffDiscountRate,
                        items: this.state.selectedPickupScones,
                        additionalItems: this.state.selectedAdditiionalItems,
                        additionalItemsAmount: this.state.additionalItemsAmount,
                        additionalItemsPrice: this.state.additionalItemsPrice,
                        totalAmount: this.state.totalAmount,
                        totalPrice: this.state.totalPrice + this.state.additionalItemsPrice,
                        staffDiscount: staffDiscount,
                        usingCoupon: usingCoupon,
                        request: request,
                        giftBox: giftBox,
                        pickupDate: this.state.pickupDate['date'],
                        pickupTime: {
                          hour: this.state.hour,
                          minute: this.state.minute
                        },
                        purchasedAt: Date.now()
                      }
                    }


                  } else {
                    pickupData = {
                      pickupId: pickupId,
                      buyerUid: this.state.userInfo.uid,
                      buyerName: this.state.name,
                      phoneNumber: this.state.phoneNumber,
                      paidPrice: (Math.max(this.state.totalPrice + this.state.additionalItemsPrice - couponDiscount, 0)) * staffDiscountRate,
                      items: this.state.selectedPickupScones,
                      additionalItems: this.state.selectedAdditiionalItems,
                      additionalItemsAmount: this.state.additionalItemsAmount,
                      additionalItemsPrice: this.state.additionalItemsPrice,
                      totalAmount: this.state.totalAmount,
                      totalPrice: this.state.totalPrice + this.state.additionalItemsPrice,
                      staffDiscount: staffDiscount,
                      usingCoupon: usingCoupon,
                      request: request,
                      giftBox: giftBox,
                      pickupDate: this.state.pickupDate['date'],
                      pickupTime: {
                        hour: this.state.hour,
                        minute: this.state.minute
                      },
                      purchasedAt: Date.now()
                    }
                  }

                  firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickupId}`).set(pickupData)
                  .then(() => {
                    if (!free) {
                      if (this.state.pickupDate.discount) {
                        if (this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice) {
                          if (this.state.usingCoupon) {
                            this.onPaying((this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                          } else {
                            this.onPaying((this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                          }

                        } else {
                          if (this.state.usingCoupon) {
                            this.onPaying((this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                          } else {
                            this.onPaying((this.state.totalPrice + this.state.additionalItemsPrice) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                          }

                        }

                      } else {
                        if (this.state.usingCoupon) {
                          this.onPaying((this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                        } else {
                          this.onPaying((this.state.totalPrice + this.state.additionalItemsPrice) * staffDiscountRate, pickupId, `디저트 ${this.state.totalAmount}개`, this.state.userInfo.username)
                        }

                      }
                    } else {
                      this.setState({freePaymentVerifying: true})
                      firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickupId}`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickupId}/usingCoupon`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            let coupon = snapshot.val()
                            firebase.database().ref(`coupons/${coupon.couponId}/usedAt`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                this.setState({alreadyUsed: true, freePaymentVerifying: false}, () => {
                                  setTimeout(() => {
                                    this.setState({alreadyUsed: false})
                                  }, 2000)
                                })
                              } else {
                                firebase.database().ref(`coupons/${coupon.couponId}`).set(coupon)
                                firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickupId}`).once('value', (snapshot) => {
                                  if (snapshot.val()) {
                                    let purchaseInfo = snapshot.val()
                                    purchaseInfo['paid'] = true
                                    purchaseInfo['paidAt'] = Date.now()

                                    firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/pickups/${pickupId}`).set(purchaseInfo)
                                    firebase.database().ref(`users/${this.state.userInfo.uid}/sconePickups/${pickupId}`).set(purchaseInfo)
                                    .then(() => {
                                      axios({
                                        url: `https://us-central1-withanod-c20f8.cloudfunctions.net/sendPickupMessage`,
                                        method: "post",
                                        headers: {
                                          "Content-Type":"application/json"
                                        },
                                        data: {
                                          pickupId: pickupId,
                                          uid: this.state.userInfo.uid
                                        }
                                      })
                                      if (this.props.onRefreshUserInfo) {
                                        this.props.onRefreshUserInfo()
                                      }
                                      if (purchaseInfo.additionalItems) {
                                        Object.values(purchaseInfo.additionalItems).map((item, index) => {
                                          firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).once('value', (snapshot) => {
                                            if (snapshot.val()) {
                                              let remainAmount = snapshot.val()
                                              firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/additionalPickups/${item.itemId}/amount`).set(Math.max(remainAmount - item.amount, 0))
                                            }
                                          })

                                        })
                                      }
                                      firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/menu`).once('value', (snapshot) => {
                                        if (snapshot.val()) {
                                          let menuList = Object.values(snapshot.val())
                                          menuList = menuList.filter(menuList => menuList.amount)
                                          menuList.map((menuItem, index) => {
                                            if (purchaseInfo['items'][menuItem.id]) {
                                              firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/menu/${menuItem.id}/amount`).set(Math.max(menuItem.amount - purchaseInfo['items'][menuItem.id].amount, 0))
                                            }
                                          })
                                        }
                                      })
                                      firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).once('value', (snapshot) => {
                                        if (snapshot.val()) {
                                          let remainAmount = snapshot.val() - purchaseInfo['totalAmount']
                                          if (remainAmount < 0) {
                                            remainAmount = 0
                                          }
                                          firebase.database().ref(`pickupOpen/${purchaseInfo['pickupDate'].toString()}/amount`).set(remainAmount)
                                        }
                                      })
                                      this.setState({freePayment: true, freePaymentVerifying: false}, () => {
                                        setTimeout(() => {
                                          this.props.history.push(`/pickup_detail/${pickupId}?from=payment_result`);
                                          setTimeout(() => {
                                            this.props.routeChanges()
                                            window.location.reload()
                                          }, 10)
                                          this.setState({freePayment: false})
                                        }, 2000)
                                      })
                                    })
                                  }
                                })
                              }
                            })
                          }
                        })
                      }
                    })

                    }
                  })
                }

              }} className="btn-very-bottom-big no-selection">
              { this.state.staffDiscount
                ?<div>
                { this.state.pickupDate.discount
                  ?this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice
                  ?<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) * (1 - (this.state.staffDiscount.discountRate * 0.01))) : this.onChangeCurrencyFormat((this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) * (1 - (this.state.staffDiscount.discountRate * 0.01))) : this.onChangeCurrencyFormat((this.state.totalPrice + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat((Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount), 0) * (1 - (this.state.staffDiscount.discountRate * 0.01))) : this.onChangeCurrencyFormat((this.state.totalPrice + this.state.additionalItemsPrice) * (1 - (this.state.staffDiscount.discountRate * 0.01)))}원 )</p>
                }
                </div>
                :<div>
                { this.state.pickupDate.discount
                  ?this.state.pickupDate.discount.minDiscountPrice <= this.state.totalPrice
                  ?<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) : this.onChangeCurrencyFormat(this.onCalculateDiscount(this.state.totalPrice, this.state.pickupDate.discount.rate) + this.state.additionalItemsPrice)}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount, 0)) : this.onChangeCurrencyFormat(this.state.totalPrice + this.state.additionalItemsPrice)}원 )</p>
                  :<p style={{color: 'white', fontSize: 15}}>결제하기 ( {this.state.usingCoupon ? this.onChangeCurrencyFormat(Math.max(this.state.totalPrice + this.state.additionalItemsPrice - this.state.usingCoupon.amount), 0) : this.onChangeCurrencyFormat(this.state.totalPrice + this.state.additionalItemsPrice)}원 )</p>
                }
                </div>
              }


              </a>
            </Sheet.Content>
            </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
        }
        <Snackbar onClick={() => {
          if (this.state.errorMessage === '픽업 시간을 선택해주세요.') {
            this.setState({notEnoughInfoSnack: false,  errorMessage: ''})
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.myRef.current.offsetTop - 110)
                } else {
                  window.scrollTo({
                    top: this.myRef.current.offsetTop - 110,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 50)
          } else if (this.state.errorMessage === '예약자 정보를 입력해주세요.') {
            this.setState({notEnoughInfoSnack: false,  errorMessage: ''})
            setTimeout(() => {
              if (isEdge || isIE) {
                  window.scrollTo(0, this.myRef.current.offsetTop + 95)
                } else {
                  window.scrollTo({
                    top: this.myRef.current.offsetTop + 95,
                    left: 0,
                    behavior: 'smooth'
                  })
                }
            }, 50)
          }

        }} open={this.state.notEnoughInfoSnack} autoHideDuration={4000} onClose={() => this.setState({notEnoughInfoSnack: false})}>
          <Alert  onClose={() => this.setState({notEnoughInfoSnack: false,  errorMessage: ''})} severity="warning">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.overTimeSnack} autoHideDuration={4000} onClose={() => this.setState({overTimeSnack: false})}>
          <Alert  onClose={() => this.setState({overTimeSnack: false})} severity="warning">
            예약 가능시간이 지났습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.noMoreSconesSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreSconesSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreSconesSnack: false})} severity="warning">
            해당일에 예약가능한 모든 수량이 소진되었습니다!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.noMoreItemsSnack} autoHideDuration={4000} onClose={() => this.setState({noMoreItemsSnack: false})}>
          <Alert  onClose={() => this.setState({noMoreItemsSnack: false})} severity="warning">
            해당 상품의 재고가 모두 소진되었습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.overTimeItemsSnack} autoHideDuration={4000} onClose={() => this.setState({overTimeItemsSnack: false})}>
          <Alert  onClose={() => this.setState({overTimeItemsSnack: false})} severity="warning">
            예약 가능시간이 지났습니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.lastPickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({lastPickupTimeSnack: false})}>
          <Alert  onClose={() => this.setState({lastPickupTimeSnack: false})} severity="warning">
            토요일 마지막 픽업 예약 가능 시간은 5시 30분입니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.couponExpireDateSnack} autoHideDuration={4000} onClose={() => this.setState({couponExpireDateSnack: false})}>
          <Alert  onClose={() => this.setState({couponExpireDateSnack: false})} severity="warning">
            유효기간이 지난 쿠폰입니다.
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.weekdaysLastPickupTimeSnack} autoHideDuration={5000} onClose={() => this.setState({weekdaysLastPickupTimeSnack: false})}>
          <Alert  onClose={() => this.setState({weekdaysLastPickupTimeSnack: false})} severity="warning">
            평일 마지막 픽업 예약 가능 시간은 6시 30분입니다.
          </Alert>
        </Snackbar>
      </Grid>
    )
  }
}

export default withRouter(PickUp);
