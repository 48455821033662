import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import MainLogo from '../marketLogo.jpg';
import PulseLoader from "react-spinners/PulseLoader";
import InputAdornment from '@material-ui/core/InputAdornment';
import DoneIcon from '@material-ui/icons/Done';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const axios = require("axios");

class EmailFind extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      oneSecondLock: false,

      users: null,
      email: '',
      password: '',

      username: '',
      validUsername: null,

      phoneNumber: '',
      validPhoneNumber: null,
      verificationCode: '',
      receivedVerificationCode: '',
      validCode: null,
      isCounting: false,
      activeSendButton: false,
      hasSentMeassage: false,
      timer: {
        minutes: 1,
        seconds: 59
      },

      foundUsers: []
    }
  }


  componentWillMount() {
    firebase.database().ref(`users`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({users: snapshot.val()})
      }
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.props.history.replace(new URLSearchParams(this.props.location.search).get('redirect'));
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      } else {
        this.setState({userInfo: null, isLoading: false})
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  validatePhoneNumber(number) {
    if (number) {
      if (/^[0-9]{10}$/g.test(number) || /^[0-9]{11}$/g.test(number)) {
        this.setState({validPhoneNumber: true})
      } else {
        this.setState({validPhoneNumber: false})
      }
    } else {
      this.setState({validPhoneNumber: null})
    }
  }

  onStartTimer() {
    this.setState({isCounting: true, hasSentMeassage: true})
    this.intervalID = setInterval(() => {
      let timer = this.state.timer
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(this.intervalID)
          return this.setState({timer: {
            minutes: 1,
            seconds: 59
          }, isCounting: false, verificationCode: '', validCode: null})
        } else {
          timer.minutes = timer.minutes - 1
          timer.seconds = 59
        }
      } else {
        timer.seconds = timer.seconds - 1
      }
      this.setState({timer: timer})
    }, 1000)

  }

  validateUsername(name) {
    let reg = /^[가-힣|a-z|A-Z|]+$/;
    if (name) {
      if (reg.test(name)) {
        this.setState({validUsername: true})
      } else {
        this.setState({validUsername: false})
      }
    } else {
      this.setState({validUsername: null})
    }
  }

  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    if (this.state.validCode) {
      if (this.state.foundUsers.length) {
        return (
          <Grid container justify="center">
            <Grid container className="back-button-container" justify="center">
            <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>아이디 찾기</p>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', left: 20, top: 20,}}
              onClick={() => {
                let currentPath = window.location.pathname
                this.props.history.goBack()

                let inervalFunction = setInterval(() => {
                  if (currentPath === window.location.pathname) {
                    this.props.history.goBack()
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  } else {
                    clearInterval(inervalFunction)
                  }
                }, 100)
              }}
            >
              <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
            </div>
            </Grid>
            <Grid style={{marginTop: 70}} container justify="center">
              <div className="login-main-view auto-width-100">
                <img
                  className="logo-style"
                  src={MainLogo}></img>
                  <span style={{fontSize: 16, marginTop: 10}}>입력한 정보와 일치하는 아이디입니다.</span>
                  <div style={{marginTop: 20}}>
                  { this.state.foundUsers.map((user, index) => {
                    return (
                      <Grid container justify="center" className="direction-row-div" style={{marginTop: 10}}>
                        <MailOutlineIcon style={{fontSize: 18, margin: 3}} />
                        <span style={{fontSize: 16}}>{user.email}</span>
                      </Grid>
                    )
                  })
                  }

                  </div>
                  <Link className="none-underline-text auto-width-100 center-align"  onClick={() => {
                    setTimeout(() => {
                      this.props.routeChanges()
                    }, 10)
                  }} to={'/login/email?redirect=' + redirect}>
                  <a style={{marginBottom: 20, marginTop: 20}} onClick={() => {
                    clearInterval(this.intervalID)
                    this.setState({
                      email: '',
                      password: '',

                      username: '',
                      validUsername: null,

                      phoneNumber: '',
                      validPhoneNumber: null,
                      verificationCode: '',
                      receivedVerificationCode: '',
                      validCode: null,
                      isCounting: false,
                      activeSendButton: false,
                      hasSentMeassage: false,
                      timer: {
                        minutes: 1,
                        seconds: 59
                      },

                      foundUsers: []
                    })
                  }} className="btn-big-normal no-selection ">
                    <span style={{color: 'white', fontSize: 13}}>로그인 하기</span>
                  </a>
                  </Link>
              </div>

            </Grid>

          </Grid>
        )
      } else {
        return (
          <Grid container justify="center">
            <Grid container className="back-button-container" justify="center">
            <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>아이디 찾기</p>
            <div
              item
              className="no-selection back-button"
              style={{position: 'absolute', left: 20, top: 20,}}
              onClick={() => {
                this.props.history.goBack()
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              }}
            >
              <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
            </div>
            </Grid>
            <Grid style={{marginTop: 70}} container justify="center">
              <div className="login-main-view auto-width-100">
                <img
                  className="logo-style"
                  src={MainLogo}></img>
                  <span style={{fontSize: 16, marginTop: 10, marginBottom: 20}}>입력한 정보와 일치하는 아이디가 없습니다.</span>
                  <a style={{marginBottom: 20}} onClick={() => {
                    clearInterval(this.intervalID)
                    this.setState({
                      email: '',
                      password: '',

                      username: '',
                      validUsername: null,

                      phoneNumber: '',
                      validPhoneNumber: null,
                      verificationCode: '',
                      receivedVerificationCode: '',
                      validCode: null,
                      isCounting: false,
                      activeSendButton: false,
                      hasSentMeassage: false,
                      timer: {
                        minutes: 1,
                        seconds: 59
                      },

                      foundUsers: []
                    })
                  }} className="btn-big-normal no-selection">
                    <span style={{color: 'white', fontSize: 13}}>다시 찾기</span>
                  </a>
              </div>

            </Grid>
          </Grid>
        )
      }

    }
    return (
      <Grid container justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>아이디 찾기</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {
            this.props.history.goBack()
            setTimeout(() => {
              this.props.routeChanges()
            }, 10)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        { !this.state.userInfo &&
          <Grid style={{marginTop: 70}} container justify="center">
            <div className="login-main-view auto-width-100">
              <img
                className="logo-style"
                src={MainLogo}></img>
                <span style={{fontSize: 16, marginTop: 10}}>가입한 휴대폰 번호로 아이디를 찾을 수 있습니다.</span>
                <Container justify="center" maxWidth="sm" >
                  <Grid container justify="center"  style={{marginTop: 20}}>
                    { this.state.validUsername
                      ?<TextField disabled={this.state.isCounting} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                        onChange={(event) => {
                          this.setState({username: event.target.value, validUsername: null})
                        }}
                        onBlur={() => {
                          this.validateUsername(this.state.username)
                          if (this.state.username === '') {
                            this.setState({validUsername: null})
                          }
                        }}
                        InputProps={{style: {marginBottom: 21}, endAdornment: (
                                      <InputAdornment position="start">
                                        <DoneIcon style={{color: '#006042'}} />
                                      </InputAdornment>
                                     )}}
                      InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이름" value={this.state.username} />
                      :<TextField disabled={this.state.isCounting} error={this.state.validUsername === false} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}}
                        onChange={(event) => {
                          this.setState({username: event.target.value, validUsername: null})
                        }}
                        onBlur={() => {
                          this.validateUsername(this.state.username)
                          if (this.state.username === '') {
                            this.setState({validUsername: null})
                          }
                        }}
                        InputProps={{style: {marginBottom: 0}}}
                        helperText={this.state.validUsername === false ? "이름을 정확히 입력해주세요." : " "}
                        FormHelperTextProps={{
                          style: {fontSize: 11, color: 'red'}
                        }}
                      InputLabelProps={{style: {fontSize: 14}}} margin={"none"} id="standard-basic" label="이름" value={this.state.username} />
                    }
                    { this.state.validPhoneNumber
                      ?<TextField disabled={this.state.isCounting} onChange={(event) => {
                        if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                          this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, ''), validPhoneNumber: null})
                          if (/^[0-9]{10}$/g.test( event.target.value.replace(/[^0-9]/g, '')) || /^[0-9]{11}$/g.test( event.target.value.replace(/[^0-9]/g, ''))) {
                            this.setState({activeSendButton: true})
                          } else {
                            this.setState({activeSendButton: false})
                          }
                        }

                      }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                      InputProps={{style: {marginBottom: 21}, endAdornment: (
                                    <InputAdornment position="start">
                                      <DoneIcon style={{color: '#006042'}} />
                                    </InputAdornment>
                                   )}}
                       onBlur={() => {
                         this.validatePhoneNumber(this.state.phoneNumber)
                         if (this.state.phoneNumber === '') {
                           this.setState({phoneNumber: null})
                         }
                       }}  id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
                      :<TextField onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          if (this.state.activeSendButton && !this.state.isCounting) {
                            this.onStartTimer()
                            let number = Math.floor(1000 + Math.random() * 9000)
                            this.setState({receivedVerificationCode: number})
                            firebase.database().ref(`verification/${this.state.phoneNumber}`).once('value', (snapshot) => {
                              if (snapshot.val()) {
                                firebase.database().ref(`verification/${this.state.phoneNumber}`).remove()
                                .then(() => {
                                  firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                                  axios({
                                    url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                                    method: "post",
                                    headers: {
                                      "Content-Type":"application/json"
                                    },
                                    data: {
                                      phoneNumber: this.state.phoneNumber,
                                      verificationNumber: number
                                    }
                                  })
                                  setTimeout(() => {
                                    firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                                  }, 1000 * 60 * 2)
                                })
                              } else {
                                firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                                axios({
                                  url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                                  method: "post",
                                  headers: {
                                    "Content-Type":"application/json"
                                  },
                                  data: {
                                    phoneNumber: this.state.phoneNumber,
                                    verificationNumber: number
                                  }
                                })
                                setTimeout(() => {
                                  firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                                }, 1000 * 60 * 2)
                              }
                            })
                          }
                        }
                      }} disabled={this.state.isCounting}  error={this.state.validPhoneNumber === false} onChange={(event) => {
                        if (event.target.value.replace(/[^0-9]/g, '').length < 12) {
                          this.setState({phoneNumber: event.target.value.replace(/[^0-9]/g, ''), validPhoneNumber: null})
                          if (/^[0-9]{10}$/g.test( event.target.value.replace(/[^0-9]/g, '')) || /^[0-9]{11}$/g.test( event.target.value.replace(/[^0-9]/g, ''))) {
                            this.setState({activeSendButton: true})
                          } else {
                            this.setState({activeSendButton: false})
                          }
                        }


                      }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                      InputProps={{style: {marginBottom: 0}}}
                       onBlur={() => {
                         this.validatePhoneNumber(this.state.phoneNumber)
                         if (this.state.phoneNumber === '') {
                           this.setState({phoneNumber: null})
                         }
                       }}
                       helperText={this.state.validPhoneNumber === false ? "10자리 이상의 숫자를 입력해주세요." : " "}
                       FormHelperTextProps={{
                         style: {fontSize: 11, color: 'red'}
                       }} id="standard-basic" label="휴대폰 번호"  value={this.onChangePhoneNumberFormat(this.state.phoneNumber)} />
                    }
                    <a style={{marginBottom: 20}} onClick={() => {
                      if (this.state.activeSendButton && !this.state.isCounting) {
                        this.onStartTimer()
                        let number = Math.floor(1000 + Math.random() * 9000)
                        this.setState({receivedVerificationCode: number})
                        firebase.database().ref(`verification/${this.state.phoneNumber}`).once('value', (snapshot) => {
                          if (snapshot.val()) {
                            firebase.database().ref(`verification/${this.state.phoneNumber}`).remove()
                            .then(() => {
                              firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                              axios({
                                url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                                method: "post",
                                headers: {
                                  "Content-Type":"application/json"
                                },
                                data: {
                                  phoneNumber: this.state.phoneNumber,
                                  verificationNumber: number
                                }
                              })
                              setTimeout(() => {
                                firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                              }, 1000 * 60 * 2)
                            })
                          } else {
                            firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).set(true)
                            axios({
                              url: `https://us-central1-withanod-c20f8.cloudfunctions.net/verificationNumber`,
                              method: "post",
                              headers: {
                                "Content-Type":"application/json"
                              },
                              data: {
                                phoneNumber: this.state.phoneNumber,
                                verificationNumber: number
                              }
                            })
                            setTimeout(() => {
                              firebase.database().ref(`verification/${this.state.phoneNumber}/${number}`).remove()
                            }, 1000 * 60 * 2)
                          }
                        })
                      }

                    }} className={this.state.activeSendButton && !this.state.isCounting ? "btn-big-normal no-selection" : "btn-big-normal-disabled no-selection"}>
                      { this.state.isCounting
                        ?<span style={{color: 'white', fontSize: 13}}>{this.state.timer.minutes}:{this.state.timer.seconds < 10 ? '0' + this.state.timer.seconds.toString() : this.state.timer.seconds}</span>
                        :<span style={{color: 'white', fontSize: 13}}>{this.state.hasSentMeassage ? '재전송' : '인증번호 전송'}</span>
                      }
                    </a>
                    { this.state.isCounting &&
                      <Grid container justify="center">
                      { this.state.validCode
                        ?<TextField  onChange={(event) => {
                          if (event.target.value.replace(/[^0-9]/g, '').length < 5) {
                            this.setState({verificationCode: event.target.value.replace(/[^0-9]/g, '')})
                            this.setState({validCode: null})
                            if (event.target.value.replace(/[^0-9]/g, '').length === 4) {
                              if (this.state.receivedVerificationCode === Number(event.target.value.replace(/[^0-9]/g, ''))) {
                                let usersArr = Object.values(this.state.users)
                                usersArr = usersArr.filter(usersArr => usersArr.username === this.state.username)
                                usersArr = usersArr.filter(usersArr => usersArr.phoneNumber === this.state.phoneNumber)
                                this.setState({foundUsers: usersArr})
                                this.setState({validCode: true})
                              } else {
                                this.setState({validCode: false})
                              }
                            }
                          }


                        }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                        InputProps={{style: {marginBottom: 21}, endAdornment: (
                                      <InputAdornment position="start">
                                        <DoneIcon style={{color: '#006042'}} />
                                      </InputAdornment>
                                     )}}
                         onBlur={() => {
                           if (this.state.receivedVerificationCode === Number(this.state.verificationCode)) {
                             let usersArr = Object.values(this.state.users)
                             usersArr = usersArr.filter(usersArr => usersArr.username === this.state.username)
                             usersArr = usersArr.filter(usersArr => usersArr.phoneNumber === this.state.phoneNumber)
                             this.setState({foundUsers: usersArr})
                             this.setState({validCode: true})
                           } else {
                             this.setState({validCode: false})
                           }
                         }}  id="standard-basic" label="인증번호 4자리"  value={this.state.verificationCode} />
                        :<TextField error={this.state.validCode === false} onChange={(event) => {
                          if (event.target.value.replace(/[^0-9]/g, '').length < 5) {
                            this.setState({verificationCode: event.target.value.replace(/[^0-9]/g, '')})
                            this.setState({validCode: null})
                            if (event.target.value.replace(/[^0-9]/g, '').length === 4) {
                              if (this.state.receivedVerificationCode === Number(event.target.value.replace(/[^0-9]/g, ''))) {
                                let usersArr = Object.values(this.state.users)
                                usersArr = usersArr.filter(usersArr => usersArr.username === this.state.username)
                                usersArr = usersArr.filter(usersArr => usersArr.phoneNumber === this.state.phoneNumber)
                                this.setState({foundUsers: usersArr})

                                this.setState({validCode: true})
                              } else {
                                this.setState({validCode: false})
                              }
                            }
                          }


                        }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}
                        InputProps={{style: {marginBottom: 0}}}
                         onBlur={() => {
                           if (this.state.receivedVerificationCode === Number(this.state.verificationCode)) {
                             let usersArr = Object.values(this.state.users)
                             usersArr = usersArr.filter(usersArr => usersArr.username === this.state.username)
                             usersArr = usersArr.filter(usersArr => usersArr.phoneNumber === this.state.phoneNumber)
                             this.setState({foundUsers: usersArr})
                             this.setState({validCode: true})
                           } else {
                             this.setState({validCode: false})
                           }
                         }}
                         helperText={this.state.validCode === false ? "올바른 인증번호를 입력해주세요." : " "}
                         FormHelperTextProps={{
                           style: {fontSize: 11, color: 'red'}
                         }} id="standard-basic" label="인증번호 4자리"  value={this.state.verificationCode} />
                      }
                      </Grid>
                    }
                  </Grid>
                </Container>
            </div>
          </Grid>
        }

      </Grid>
    )
  }
}

export default withRouter(EmailFind);
