import React, {Component, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../marketLogo.jpg';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';
import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

import * as firebase from 'firebase';

import ScrollContainer from 'react-indiana-drag-scroll'

import { useLastLocation } from 'react-router-last-location';

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      navIndex: 0,
      prevIndexInfo: [],
      pathHistory: []
    }
  }


  componentWillMount() {
    if (this.props.navIndex) {
      this.setState({navIndex: this.props.navIndex})
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(){

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.navIndex !== this.props.navIndex) {
      this.setState({ navIndex: nextProps.navIndex });
    }
  }




  render() {

    return (
      <Grid className="top-nav" container direction="column" justifyContent="center" style={{paddingLeft: 10, paddingRight: 10}}>
        <Grid item>
          <ButtonBase
            to={`/`}
            component={Link}
            style={{ color: "black", textDecoration: 'none' }}
            onClick={() => {
              let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(1)
            }
            if (this.state.prevIndexInfo[1]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[1].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[1].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }
            }}
          >
          <Image
            className="logo-style"
            src={MainLogo}/>
          </ButtonBase>
        </Grid>
        <ScrollContainer className="scrollmenu-parent">
        <div className="scrollmenu">
        <Link
          to={`/home/scone_class`}
          className="scrollmenu-child"
          style={{ color: "black", textDecoration: 'none' }}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(0)
            }
            if (this.state.prevIndexInfo[0]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[0].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[0].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }
          }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 0 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          스콘클래스
        </p>
        </Link>
        <Link
          to={`/`}
          className="scrollmenu-child"
          style={{ color: "black", textDecoration: 'none' }}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(1)
            }
            if (this.state.prevIndexInfo[1]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[1].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[1].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }
          }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 1 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          디저트택배
        </p>
        </Link>

        <Link
          className="scrollmenu-child"
          to={`/home/pickup`}
          style={{ color: "black", textDecoration: 'none' }}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(2)
            }
            if (this.state.prevIndexInfo[2]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[2].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[2].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }
          }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 2 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          픽업예약
        </p>
        </Link>

        <Link
          className="scrollmenu-child"
          to={`/home/spread`}
          style={{ color: "black", textDecoration: 'none' }}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(3)
            }
            if (this.state.prevIndexInfo[3]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[3].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[3].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }
          }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 3 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          콩포트&잼
        </p>
        </Link>
        <Link
          className="scrollmenu-child"
          to={`/home/milk_tea_base`}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(4)
            }
            if (this.state.prevIndexInfo[4]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[4].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[4].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)

              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }

          }}
          style={{ color: "black", textDecoration: 'none' }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 4 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          밀크티 베이스
        </p>
        </Link>
        <Link
          className="scrollmenu-child"
          to={`/home/scone_mix`}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(5)
            }
            if (this.state.prevIndexInfo[5]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[5].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[5].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)

              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }

          }}
          style={{ color: "black", textDecoration: 'none' }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 5 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          스콘믹스
        </p>
        </Link>
        <Link
          className="scrollmenu-child"
          to={`/home/wina_goods`}
          onClick={() => {
            let prevIndexInfo = this.state.prevIndexInfo
            prevIndexInfo[this.state.navIndex] = {
              preIndex: this.state.navIndex,
              scrollPosition: document.documentElement.scrollTop
            }
            this.setState({prevIndexInfo: prevIndexInfo})
            if (this.props.navIndexChanges) {
              this.props.navIndexChanges(6)
            }
            if (this.state.prevIndexInfo[6]) {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, this.state.prevIndexInfo[6].scrollPosition)
                }, 10)
              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: this.state.prevIndexInfo[6].scrollPosition,
                    left: 0
                  })
                }, 10)
              }
            } else {
              if (isEdge || isIE) {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)

              } else {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0
                  })
                }, 10)
              }
            }

          }}
          style={{ color: "black", textDecoration: 'none' }}
        >
        <p style={{fontSize: 11, height: 22}} class={this.state.navIndex === 6 ? "nav-selected-text vertical-center-align" : "nav-text vertical-center-align"}>
          위나굿즈
        </p>
        </Link>
        </div>
        </ScrollContainer>
      </Grid>
    );
  }
}

export default NavBar;
