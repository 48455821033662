import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PulseLoader from "react-spinners/PulseLoader";

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';

import { Row, Image } from 'react-bootstrap';

import * as firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';


import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faTruckMoving, faCheck, faWarehouse, faTruck } from '@fortawesome/free-solid-svg-icons';

import nunettineSconeIcon from '../NunettineScone.png';
import eggSconeIcon from '../EggScone.png';
import compoteAndCreamJars from '../compoteAndCreamJars.png';

import Footer from './Footer';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const axios = require("axios");

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

class RegularOrderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,

      showDeliveryDetail: false,

      gotInvoiceData: false,

      regular: null,

    }
  }


  componentWillMount() {
    firebase.database().ref(`regular`).once('value', (snapshot) => {
      if (snapshot.val()) {
        let regular = snapshot.val()
        this.setState({regular: regular})
      }
    })
  }

  componentDidMount() {

    setTimeout(() => {
      if (isEdge || isIE) {
          window.scrollTo(0, 0)
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
          })
        }
    }, 10)
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.onGetDeliveryInfo(nextProps.userInfo.regular['deliveries'][this.state.orderId])
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/mypage');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone) {
      if (phone.substr(0, 3) === '010') {
        if (phone.substr(7,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
        } else {
          value = phone.substr(0, 3)
        }
      } else {
        if (phone.substr(6,4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6,4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3)
        } else {
          value = phone.substr(0, 3)
        }
      }
    }


    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear()
    let months = new Date(time).getMonth() + 1
    let days = new Date(time).getDate()
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    let seconds = new Date(time).getSeconds()


    let morningOrAfternoon = '오전'

    if (Number(hours) > 11) {
      morningOrAfternoon = '오후'
    }

    if (Number(hours) > 12) {
      hours = hours - 12

    }

    if (hours === 0) {
      hours = 12
    }

    if (Number(minutes) < 10) {
      minutes = '0' + minutes
    }

    return `${years}년 ${months}월 ${days}일 ${morningOrAfternoon} ${hours}시${minutes}분`
  }

  onGetDeliveryInfo(item) {
    if (item.invoiceNumber && !this.state.gotInvoiceData) {
      this.setState({gotInvoiceData: true})
      if (!item.hasDelivered) {
        if (item.trackingTime) {
          if (item.trackingTime + (1000 * 60 * 30) < Date.now()) {
            let providerCode = '06'
            if (item['providerCode']) {
              providerCode = item['providerCode']
            }
            axios({
              url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${item.invoiceNumber}`,
              method: "get",
              headers: {
                "Content-Type":"application/json"
              }
            }).then((data) => {

              let deliveries = this.state.userInfo.regular['deliveries']
              let userInfo = this.state.userInfo
              if (data.data.status !== false) {
                userInfo.regular.deliveries[item.payDate]['deiliveryInfo'] = data.data
              }

              if (data.data.level === 6) {
                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/hasDelivered`).set(true)
                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/hasDelivered`).set(true)

                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                }
                userInfo.regular.deliveries[item.payDate]['hasDelivered'] = true
              } else {
                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/trackingTime`).set(Date.now())

                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/trackingTime`).set(Date.now())
                if (data.data.status !== false) {
                  firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                  firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
                }

              }
              this.setState({userInfo: userInfo}, () => {
                setTimeout(() => {
                  this.props.onRefreshUserInfo()
                }, 10)
              })
            })
          }
        } else {
          let providerCode = '06'
          if (item['providerCode']) {
            providerCode = item['providerCode']
          }
          axios({
            url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=lbjPY5emNMpfmUJWDF5EhA&t_code=${providerCode}&t_invoice=${item.invoiceNumber}`,
            method: "get",
            headers: {
              "Content-Type":"application/json"
            }
          }).then((data) => {
            let deliveries = this.state.userInfo.regular['deliveries']
            let userInfo = this.state.userInfo
            if (data.data.status !== false) {
              userInfo.regular.deliveries[item.payDate]['deiliveryInfo'] = data.data
            }

            if (data.data.level === 6) {
              firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/hasDelivered`).set(true)
              firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/hasDelivered`).set(true)
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
              }
              userInfo.regular.deliveries[item.payDate]['hasDelivered'] = true
            } else {
              firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/trackingTime`).set(Date.now())

              firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/trackingTime`).set(Date.now())
              if (data.data.status !== false) {
                firebase.database().ref(`users/${userInfo.uid}/regular/deliveries/${item.payDate}/deiliveryInfo`).set(data.data)
                firebase.database().ref(`regular/deliveries/${item.payDate}/${this.state.userInfo.uid}/deiliveryInfo`).set(data.data)
              }

            }
            this.setState({userInfo: userInfo}, () => {
              setTimeout(() => {
                this.props.onRefreshUserInfo()
              }, 10)
            })
          })
        }

      }
    }
  }

  render() {
    let search = window.location.search;
    const queryObj = queryString.parse(search);
    const { redirect } = queryObj;
    if (this.state.isLoading) {
      return <div></div>
    }
    if (!this.state.userInfo) {
      return <div></div>
    }
    let levelDesc = [
      '상품인수', '상품이동', '지점도착', '배송출발', '배송완료'
    ]
    let order = this.state.userInfo.regular['deliveries'][this.state.orderId]

    return (
      <Grid container justify="center" style={{paddingBottom: 60}}>
        <Grid container className="relative-position" justify="center">
        <Grid container className="back-button-container" justify="center">
        <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>주문 상세</p>
        <div
          item
          className="no-selection back-button"
          style={{position: 'absolute', left: 20, top: 20,}}
          onClick={() => {
            let currentPath = window.location.pathname
            this.props.history.goBack()

            let inervalFunction = setInterval(() => {
              if (currentPath === window.location.pathname) {
                this.props.history.goBack()
                setTimeout(() => {
                  this.props.routeChanges()
                }, 10)
              } else {
                clearInterval(inervalFunction)
              }
            }, 100)
          }}
        >
          <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
        </div>
        </Grid>
        <Grid container  justify="center" style={{marginTop: 80}}>
          <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
            <Grid>
              <Image style={{width: 40, height: 40}} src={'https://firebasestorage.googleapis.com/v0/b/withanod-c20f8.appspot.com/o/photos%2FinitialPhoto.jpg?alt=media&token=d706bb50-3a7c-48d4-b325-4c41da7224da'} />
            </Grid>
            <Grid style={{marginLeft: 10}}>
              <p style={{fontSize: 13, margin: 0, padding: 0}}>기본 패키지</p>
              <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.basicPackage.price)}원 / 1개</p>
            </Grid>
          </Grid>
          { order.addition &&
            <Grid container  direction="row">
              { order.addition.twoEggScones &&
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={eggSconeIcon} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>에그 스콘</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.addition.twoEggScones.price)}원 / 2개</p>
                  </Grid>
                </Grid>
              }
            </Grid>
          }
          { order.addition &&
            <Grid container  direction="row">
              { order.addition.twoNunettineScones &&
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={nunettineSconeIcon} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>누네띠네 스콘</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.addition.twoNunettineScones.price)}원 / 2개</p>
                  </Grid>
                </Grid>
              }
            </Grid>
          }
          { order.addition &&
            <Grid container  direction="row">
              { order.addition.twoNunettineScones &&
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={nunettineSconeIcon} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>누네띠네 스콘</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.addition.twoNunettineScones.price)}원 / 2개</p>
                  </Grid>
                </Grid>
              }
            </Grid>
          }
          { order.addition &&
            <Grid container  direction="row">
              { order.addition.plainSauce &&
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={compoteAndCreamJars} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>클로티드 크림 & 딸기 콩포트</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.addition.plainSauce.price)}원 / 1세트</p>
                  </Grid>
                </Grid>
              }
            </Grid>
          }
          { order.addition &&
            <Grid container  direction="row">
              { order.addition.twoPlainSauces &&
                <Grid container  style={{padding: 20, paddingBottom: 5, paddingTop: 5}}  direction="row">
                  <Grid>
                    <Image style={{width: 40, height: 40}} src={compoteAndCreamJars} />
                  </Grid>
                  <Grid style={{marginLeft: 10}}>
                    <p style={{fontSize: 13, margin: 0, padding: 0}}>클로티드 크림 & 딸기 콩포트</p>
                    <p style={{fontSize: 13, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.addition.twoPlainSauces.price)}원 / 2세트</p>
                  </Grid>
                </Grid>
              }
            </Grid>
          }
          <p style={{fontSize: 15, margin: 0, padding: 0, marginTop: 5, fontWeight: 'bold'}}>총 {this.onChangeCurrencyFormat(order.totalPrice)}원</p>
            <Divider className="auto-width-100" style={{marginTop: 30}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>주문 정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>결제일시</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{this.onGetTimeDetail(order.paidAt)}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>주문자</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{this.state.userInfo.username}</span>
              </div>
            </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <span style={{fontWeight: 'bold', marginLeft: 20}}>배송지 정보</span>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는사람</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>{order.deliveryAddress.receiver}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>연락처</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 33}}>{this.onChangePhoneNumberFormat(order.deliveryAddress.phoneNumber)}</span>
              </div>
              <div className="auto-width-100 direction-row-div" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>받는주소</span>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>[{order.deliveryAddress.postCode}] {order.deliveryAddress.fullAddress} {order.deliveryAddress.detailAddress}</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 20, height: 10}} />
              <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
                <span style={{fontWeight: 'bold', marginLeft: 20}}>배송 정보</span>
                <Divider className="auto-width-100" style={{marginTop: 20}} />
              { order['deiliveryInfo'] ?
              <div style={{marginTop: 20}}>

                <div className="align-center justify-center relative-position row-between-space-86" style={{height: 40, marginTop: 20}}>
                  { order['deiliveryInfo']['level'] > 2
                    ?<Divider style={{backgroundColor: '#006042'}} className="first-delivery-process-line" />
                    :<Divider className="first-delivery-process-line" />
                  }
                  { order['deiliveryInfo']['level'] > 3
                    ?<Divider style={{backgroundColor: '#006042'}} className="second-delivery-process-line" />
                    :<Divider className="second-delivery-process-line" />
                  }
                  { order['deiliveryInfo']['level'] > 4
                    ?<Divider style={{backgroundColor: '#006042'}} className="third-delivery-process-line" />
                    :<Divider className="third-delivery-process-line" />
                  }
                  { order['deiliveryInfo']['level'] > 5
                    ?<Divider style={{backgroundColor: '#006042'}} className="fourth-delivery-process-line" />
                    :<Divider className="fourth-delivery-process-line" />
                  }
                  { order['deiliveryInfo']['level'] > 1
                    ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                      <FontAwesomeIcon style={{color: 'white', fontSize: 14}} icon={faBox} />
                    </div>
                    :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                      <FontAwesomeIcon style={{color: 'white', fontSize: 14}} icon={faBox} />
                    </div>
                  }
                  { order['deiliveryInfo']['level'] > 2
                    ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                      <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruckMoving} />
                    </div>
                    :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                      <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruckMoving} />
                    </div>
                  }
                  { order['deiliveryInfo']['level'] > 3
                    ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faWarehouse} />
                    </div>
                    :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faWarehouse} />
                    </div>
                  }
                  { order['deiliveryInfo']['level'] > 4
                    ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruck} />
                    </div>
                    :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 13}} icon={faTruck} />
                    </div>
                  }
                  { order['deiliveryInfo']['level'] > 5
                    ?<div className="background-signiture-color z-index-100 align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 15}} icon={faCheck} />
                    </div>
                    :<div className="z-index-100 background-disable-color align-center justify-content-center" style={{width: 35, height: 35, borderRadius: 35}}>
                    <FontAwesomeIcon style={{color: 'white', fontSize: 15}} icon={faCheck} />
                    </div>
                  }
                </div>
                <div className="align-center justify-center relative-position row-between-space-88" style={{height: 10, marginTop: 10}}>
                  <span style={{fontSize: 11}}>{levelDesc[0]}</span>
                  <span style={{fontSize: 11}}>{levelDesc[1]}</span>
                  <span style={{fontSize: 11}}>{levelDesc[2]}</span>
                  <span style={{fontSize: 11}}>{levelDesc[3]}</span>
                  <span style={{fontSize: 11}}>{levelDesc[4]}</span>
                </div>
                <div className="auto-width-90 direction-row-div align-center margin-left-5" style={{marginTop: 40}}>
                  <div className="direction-column-div">
                    { order['deiliveryInfo']['trackingDetails'].map((detail, index) => {
                      return (
                        <div>
                          { index !== 0
                            ?<div className="small-line-circle">
                            </div>
                            :<div className="background-signiture-color z-index-100" style={{width: 11, height: 11, borderRadius: 10}}>
                            </div>
                          }

                          { index + 1 !== order['deiliveryInfo']['trackingDetails'].length &&
                            <Divider  style={{marginTop: 0, height: 40, width: 1, marginLeft: 5, backgroundColor: '#006042'}} />
                          }
                        </div>
                      )
                    })

                    }

                  </div>
                  <div className="direction-column-div" style={{marginLeft: 20}}>
                  { order['deiliveryInfo']['trackingDetails'].reverse().map((detail, index) => {
                    return (
                      <div className="direction-column-div justify-content-center" style={{height: 52}}>
                        { index === 0
                          ?<div className="direction-row-div">
                            <span style={{fontSize: 13, fontWeight: 'bold'}}>{detail.where}</span>
                            <span style={{fontSize: 13, fontWeight: 'bold', marginLeft: 3, marginRight: 3}}>|</span>
                            <span style={{fontSize: 13, fontWeight: 'bold', color: '#006042'}}>{detail.kind}</span>
                          </div>
                          :<span style={{fontSize: 13}}>{detail.where} | {detail.kind}</span>
                        }
                        <span style={{fontSize: 11, color: 'gray'}}>{detail.timeString}</span>
                      </div>
                    )
                  })
                  }
                  </div>

                </div>
              </div>
              :<div style={{marginTop: 30, marginLeft: 20}}>
                <span style={{fontSize: 14, fontWeight: 'bold', color: '#006042'}}>현재 상품을 준비하고 있습니다.</span>
              </div>
              }
            </div>


            </div>
            <Divider className="auto-width-100" style={{marginTop: 0, height: 10}} />
            <div className="auto-width-100" style={{paddingTop: 20, paddingBottom: 20}}>
              <div className="auto-width-100 direction-row-div justify-between">
                <span style={{fontWeight: 'bold', marginLeft: 20}}>결제 정보</span>
                { order['card'] &&
                  <a className="normal-very-small-button none-underline-text align-center" style={{width: 60, marginRight: 20}} href={order['card'].receiptUrl} target="_blank">
                    <span style={{fontSize: 11, color: 'white'}}>영수증 출력</span>
                  </a>
                }

              </div>
              <Divider className="auto-width-100" style={{marginTop: 20}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 30, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>상품금액</span>
                <span className="break-word" style={{fontSize: 13, marginRight: 20}}>{this.onChangeCurrencyFormat(order.totalPrice)}원</span>
              </div>
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>할인금액</span>
                <span className="break-word" style={{fontSize: 13, color: 'red', marginRight: 20}}>-{this.onChangeCurrencyFormat(order.totalPrice - order.paidPrice)}원</span>
              </div>
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 13, marginLeft: 20}}>배송비</span>
                <span className="break-word" style={{fontSize: 13, marginRight: 20}}>무료</span>
              </div>
              <Divider className="auto-width-100" style={{marginTop: 10, marginBottom: 10}} />
              <div className="auto-width-100 direction-row-div justify-between" style={{paddingTop: 10, paddingBottom: 10}}>
                <span className="break-word" style={{fontSize: 15, marginLeft: 20, fontWeight: 'bold'}}>총 결제금액</span>
                <span className="break-word" style={{fontSize: 15, marginRight: 20, fontWeight: 'bold', color: '#006042'}}>{this.onChangeCurrencyFormat(order.paidPrice)}원</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    )
  }
}

export default withRouter(RegularOrderDetail);
