import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import Postcode from 'react-daum-postcode';

import shortid from 'shortid';

import { Image, Col, Row } from 'react-bootstrap';

import {v4 as uuid} from 'uuid';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PinDropIcon from '@material-ui/icons/PinDrop';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import {
  isMobileOnly, isIE, isEdge
} from "react-device-detect";

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';

import * as firebase from 'firebase';

const GreenCheckbox = withStyles({
  root: {
    color: 'gray',
    '&$checked': {
      color: '#006042',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class DeliveryLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      isLoadingProdects: false,
      oneSecondLock: false,
      initialLanding: true,
      userInfo: this.props.userInfo,
      orderSheet: null,
      openOrderSheet: null,
      showPostModal: false,
      showRemoveModal: false,
      showAddModal: false,
      willRemoveItem: {},
      totalAmount: 0,
      totalPrice: 0,
      selectedScones: {},
      sconeMenu: {},
      fullAddress: '',
      postCode: '',
      detailAddress: '',
      receiver: '',
      phoneNumber: '',

      deliveryAddress: {},
      deliveryAddresses: [],

      tempFullAddress: '',
      tempDetailAddress: '',
      tempPostCode: '',
      tempReceiver: '',
      tempPhoneNumber: '',
      tempPhoneNumberValue: '',

      editFullAddress: '',
      editDetailAddress: '',
      editPostCode: '',
      editReceiver: '',
      editPhoneNumber: '',
      editPhoneNumberValue: '',
      editIndex: 0,

      detailAddressInputError: false,
      doneEnterAddress: false,
      phoneNumberValue: '',
      isChangingAddress: false,
      isSelectingAddress: false,
      deliveryAddressIndex: 0,

      defaultAddressIndex: null,
      tempDefaultAddressIndex: null,

      removeIndex: 0,

      isPurchasing: false,
      noMoreSconesSnack: false
    }
  }


  componentWillMount() {
    if (this.props.totalAmount) {
      this.setState({totalAmount: this.props.totalAmount})
    }
    if (this.props.selectedScones) {
      this.setState({selectedScones: this.props.selectedScones})
    }
    if (this.props.totalPrice) {
      this.setState({totalPrice: this.props.totalPrice})
    }
    firebase.database().ref(`openOrderSheet`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({openOrderSheet: snapshot.val()})
        firebase.database().ref(`orderSheets/${snapshot.val()}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({orderSheet: snapshot.val()})
          }
        }).then(() => {
          firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
            if (snapshot.val()) {
              this.setState({sconeMenu: snapshot.val()})
            }
          })
        })
      } else {
        firebase.database().ref(`sconeMenu`).once('value', (snapshot) => {
          if (snapshot.val()) {
            this.setState({sconeMenu: snapshot.val()})
          }
        })
      }
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({userInfo: nextProps.userInfo, isLoading: false})
        if (this.state.initialLanding) {
          let deliveryAddress = {}

          if (nextProps.userInfo.deliveryAddress) {
            this.setState({deliveryAddresses: nextProps.userInfo.deliveryAddress})
          }

          if (nextProps.userInfo.defaultAddressIndex || nextProps.userInfo.defaultAddressIndex === 0) {

            this.setState({defaultAddressIndex: nextProps.userInfo.defaultAddressIndex, tempDefaultAddressIndex: nextProps.userInfo.defaultAddressIndex, deliveryAddressIndex: nextProps.userInfo.defaultAddressIndex})
            if (nextProps.userInfo.deliveryAddress) {
              deliveryAddress = nextProps.userInfo.deliveryAddress[nextProps.userInfo.defaultAddressIndex]
            }
          } else {
            if (nextProps.userInfo.deliveryAddress) {
              deliveryAddress = nextProps.userInfo.deliveryAddress[0]
            }
          }
          if (Object.keys(deliveryAddress).length !== 0) {
            this.setState({
              detailAddress: deliveryAddress.detailAddress,
              fullAddress: deliveryAddress.fullAddress,
              postCode: deliveryAddress.postCode,
              receiver: deliveryAddress.receiver,
              phoneNumber: deliveryAddress.phoneNumber,
              tempFullAddress: deliveryAddress.fullAddress,
              tempPostCode: deliveryAddress.postCode,
              tempDetailAddress: deliveryAddress.detailAddress,
              tempReceiver: deliveryAddress.receiver,
              tempPhoneNumber: deliveryAddress.phoneNumber,
              doneEnterAddress: true,
            })
          }
          this.setState({initialLanding: false})
        }
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      } else {
        this.props.history.replace('/login?redirect=/delivery_cart');
        if (!this.state.oneSecondLock) {
          setTimeout(() => {
            this.props.routeChanges()
            this.setState({oneSecondLock: true})
          }, 10)
        }

        setTimeout(() => {
          this.setState({oneSecondLock: false})
        }, 1000)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  phoneFormat(phone) {
    let value = phone.replace(/[^0-9]/g, '')
    this.setState({
      tempPhoneNumber: value.replace(/[^0-9]/g, ''),
      tempPhoneNumberValue: this.onChangePhoneNumberFormat(value)
    })
  }

  onChangePhoneNumberFormat(phone) {
    let value = ''
    if (phone.substr(7,4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7,4);
    } else if (phone.substr(3, 4)) {
      value = phone.substr(0, 3) + "-" + phone.substr(3, 4)
    } else {
      value = phone.substr(0, 3)
    }
    return value
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  render() {
    let totalPrice = this.state.totalPrice
    let totalAmount = this.state.totalAmount
    let selectedScones = this.state.selectedScones
    if (this.state.isLoading) {
      return <div></div>
    }
    return (
      <Grid container justify="center" style={{paddingBottom: 60, paddingTop: 60}}>
      <Grid container className="back-button-container" justify="center">
      <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>배송지 목록</p>
      <Link
        to={'/mypage'}
        className="no-selection back-button"
        style={{position: 'absolute', left: 20, top: 20,}}
        onClick={() => {
          setTimeout(() => {
            this.props.routeChanges()
            if (isEdge || isIE) {
                window.scrollTo(0, 0)
            } else {
              window.scrollTo({
                top: 0,
                left: 0,
              })
            }
          }, 10)
        }}
      >
        <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
      </Link>
      </Grid>
      { this.state.deliveryAddresses.length !== 0 &&
        <div className="direction-row-div right-top-position">
        <AddIcon  style={{color: 'black', fontSize: 20, margin: 1}} />
          <span onClick={() => {
            this.setState({
              showAddModal: true,
              tempFullAddress: '',
              tempPostCode: '',
              tempDetailAddress: '',
              tempReceiver: '',
              tempPhoneNumber: '',
            })
          }} className="underline-text">배송지 추가</span>
        </div>
      }
      { this.state.deliveryAddresses.length !== 0
        ?this.state.deliveryAddresses.map((address, index) => {
          return (
            <Grid onClick={() => {
              this.setState({
                deliveryAddressIndex: index,
                detailAddress: address.detailAddress,
                fullAddress: address.fullAddress,
                postCode: address.postCode,
                receiver: address.receiver,
                phoneNumber: address.phoneNumber,
                tempFullAddress: address.fullAddress,
                tempPostCode: address.postCode,
                tempDetailAddress: address.detailAddress,
                tempReceiver: address.receiver,
                tempPhoneNumber: address.phoneNumber,
                doneEnterAddress: true,
                isSelectingAddress: false
              })
            }} container direction="column" className="delivery-location">
              { this.state.defaultAddressIndex === index &&
                <span className="address-info-content badge-style" style={{fontSize: 12, marginBottom: 3, color: 'white'}}>기본 배송지</span>
              }
              <div className="address-info-wrap direction-row-div">
                <span className="address-info-content direction-row-div" style={{fontSize: 16, fontWeight: 'bold', marginBottom: 3}}>{address.receiver} [{address.postCode}]</span>
              </div>
              <div className="address-info-wrap">
                <span className="address-info-content break-word">{address.fullAddress}{' '}{address.detailAddress}</span>
              </div>
              <div className="address-info-wrap">
                <span className="address-info-content" style={{marginLeft: 1}}>{this.onChangePhoneNumberFormat(address.phoneNumber)}</span>
              </div>
              <div className="direction-row-div" style={{marginTop: 5}}>
                <div onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    showPostModal: true,
                    editFullAddress: address.fullAddress,
                    editPostCode: address.postCode,
                    editDetailAddress: address.detailAddress,
                    editReceiver: address.receiver,
                    editPhoneNumber: address.phoneNumber,
                    editIndex: index
                  })
                }} className="normal-small-button-outlined">
                  <span style={{fontSize: 13, color: '#006042'}}>수정</span>
                </div>
                <div onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({removeIndex: index, showRemoveAddressModal: true})

                }} className="normal-small-button-outlined">
                  <span style={{fontSize: 13, color: '#006042'}}>삭제</span>
                </div>
              </div>

            </Grid>
          )
        })
        :<div className="no-items-main-view">
          <PinDropIcon  style={{color: '#a1a1a1', fontSize: 80}} />
          <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>배송지 목록이 없습니다.</span>
          <div className="direction-row-div" style={{marginTop: 10}}>
            <span onClick={() => {
              this.setState({
                showFirstPostModal: true,
                tempFullAddress: '',
                tempPostCode: '',
                tempDetailAddress: '',
                tempReceiver: '',
                tempPhoneNumber: '',
              })
            }} className="underline-text">배송지 추가</span>
          </div>
        </div>
      }
        <Modal
          open={this.state.showRemoveAddressModal}
          onClose={() => this.setState({showRemoveAddressModal: false})}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
          >
          <div className="remove-item-modal">
          <span>해당 배송지를 삭제하시겠습니까?</span>
          <Grid container direction="row" style={{marginTop: 40}}>
          <div className="modal-button-outlined" onClick={() => {
            this.setState({showRemoveAddressModal: false})
          }}>
          <span style={{color: 'black', fontWeight: 'bold'}}>아니오</span>
          </div>
          <div className="modal-button-filled"  onClick={() => {
            let deliveryAddresses = this.state.deliveryAddresses
            let userInfo = this.state.userInfo
            if (this.props.onSelectDeliveryAddress) {
              if (this.state.removeIndex === this.props.deliveryAddressIndex) {
                this.props.onSelectDeliveryAddress(null)
              }

            }
            deliveryAddresses.splice(this.state.removeIndex, 1);
            userInfo['deliveryAddress'] = deliveryAddresses
            this.setState({deliveryAddresses: deliveryAddresses, userInfo: userInfo})
            firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddresses)
            if (this.state.removeIndex === this.state.defaultAddressIndex) {
              this.setState({defaultAddressIndex: null, tempDefaultAddressIndex: null})
              firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).remove()
            } else {
              if (this.state.removeIndex < this.state.defaultAddressIndex) {
                this.setState({defaultAddressIndex: this.state.defaultAddressIndex - 1, tempDefaultAddressIndex: this.state.defaultAddressIndex - 1})
                firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.defaultAddressIndex - 1)
              }
            }

            if (deliveryAddresses.length === 0) {
              delete userInfo.deliveryAddress
              this.setState({
                detailAddress: '',
                fullAddress: '',
                postCode: '',
                receiver: '',
                phoneNumber: '',
                tempFullAddress: '',
                tempPostCode: '',
                tempDetailAddress: '',
                tempReceiver: '',
                tempPhoneNumber: '',
                doneEnterAddress: false,
                isSelectingAddress: false,
                defaultAddressIndex: null,
                tempDefaultAddressIndex: null,
                userInfo: userInfo
              })
            } else {
              if (this.state.removeIndex === this.state.deliveryAddressIndex) {
                this.setState({
                  deliveryAddressIndex: 0,
                  detailAddress: deliveryAddresses[0].detailAddress,
                  fullAddress: deliveryAddresses[0].fullAddress,
                  postCode: deliveryAddresses[0].postCode,
                  receiver: deliveryAddresses[0].receiver,
                  phoneNumber: deliveryAddresses[0].phoneNumber,
                  tempFullAddress: deliveryAddresses[0].detailAddress,
                  tempPostCode: deliveryAddresses[0].fullAddress,
                  tempDetailAddress: deliveryAddresses[0].postCode,
                  tempReceiver: deliveryAddresses[0].receiver,
                  tempPhoneNumber: deliveryAddresses[0].phoneNumber
                })
              } else {
                if (this.state.deliveryAddresses.length === this.state.deliveryAddressIndex) {
                  this.setState({
                    deliveryAddressIndex: this.state.deliveryAddressIndex - 1,
                    detailAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].detailAddress,
                    fullAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].fullAddress,
                    postCode: deliveryAddresses[this.state.deliveryAddressIndex - 1].postCode,
                    receiver: deliveryAddresses[this.state.deliveryAddressIndex - 1].receiver,
                    phoneNumber: deliveryAddresses[this.state.deliveryAddressIndex - 1].phoneNumber,
                    tempFullAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].detailAddress,
                    tempPostCode: deliveryAddresses[this.state.deliveryAddressIndex - 1].fullAddress,
                    tempDetailAddress: deliveryAddresses[this.state.deliveryAddressIndex - 1].postCode,
                    tempReceiver: deliveryAddresses[this.state.deliveryAddressIndex - 1].receiver,
                    tempPhoneNumber: deliveryAddresses[this.state.deliveryAddressIndex - 1].phoneNumber
                  })
                }
              }
            }
            this.setState({showRemoveAddressModal: false})
          }}>
          <span style={{color: 'white', fontWeight: 'bold'}}>삭제</span>
          </div>
          </Grid>
          </div>
        </Modal>
        <Modal
          open={this.state.showAddModal}
          onClose={() => {
            this.setState({showAddModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.tempFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
            <div className="modal-outside-button" onClick={() => this.setState({showAddModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false, doneEnterAddress: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    this.setState({
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber,
                    })
                    let userInfo = this.state.userInfo

                    if (this.state.deliveryAddresses.length !== 0) {
                      let deliveryAddress = this.state.deliveryAddresses
                      deliveryAddress.push(with_a_nod_delivery_address)
                      userInfo['deliveryAddress'] = deliveryAddress

                      this.setState({deliveryAddresses: deliveryAddress, deliveryAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                        setTimeout(() => {
                          if (isEdge || isIE) {
                              window.scrollTo(0, document.body.scrollHeight)
                            } else {
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                        }, 10)
                      })
                      firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                    } else {
                      let deliveryAddress = []
                      deliveryAddress.push(with_a_nod_delivery_address)
                      this.setState({deliveryAddresses: deliveryAddress, deliveryAddressIndex: deliveryAddress.length - 1, showAddModal: false}, () => {
                        setTimeout(() => {
                          if (isEdge || isIE) {
                              window.scrollTo(0, document.body.scrollHeight)
                            } else {
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                        }, 10)
                      })
                      firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                    }
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              if (fullAddress.indexOf('제주특별자치도') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else {
                this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
              }

            }}
          />
        }

        </Modal>
        <Modal
          open={this.state.showPostModal}
          onClose={() => {
            this.setState({showPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
            this.setState({tempDefaultAddressIndex: this.state.defaultAddressIndex})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.editFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
            <div className="modal-outside-button" onClick={() => this.setState({showPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.editFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({editDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.editDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({editReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.editReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({editPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.editPhoneNumber)} />
            <div className="auto-width-90">
            <FormGroup  style={{alignItems: 'center', margin: 10}} row>
              <FormControlLabel
                control={
                  <GreenCheckbox checked={this.state.tempDefaultAddressIndex === this.state.editIndex}  onChange={() => {
                    if (this.state.tempDefaultAddressIndex === this.state.editIndex) {
                      this.setState({tempDefaultAddressIndex: null})
                    } else {
                      this.setState({tempDefaultAddressIndex: this.state.editIndex})
                    }
                  }} value="defaultAddress" />
                }
                label={<span style={{ fontSize: 14 }}>기본 배송지로 선정</span>}
              />
            </FormGroup>
            </div>
            <a onClick={() => {
              if (this.state.editDetailAddress) {
                if (this.state.editReceiver) {
                  if (this.state.editPhoneNumber) {
                    this.setState({showPostModal: false, detailAddressInputError: false, phoneNumberInputError: false, receiverInputError: false,  doneEnterAddress: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.editDetailAddress,
                      fullAddress: this.state.editFullAddress,
                      postCode: this.state.editPostCode,
                      receiver: this.state.editReceiver,
                      phoneNumber: this.state.editPhoneNumber
                    }

                    if (this.state.editIndex === this.state.deliveryAddressIndex) {
                      this.setState({
                        deliveryAddressIndex: this.state.editIndex,
                        detailAddress: this.state.editDetailAddress,
                        fullAddress: this.state.editFullAddress,
                        postCode: this.state.editPostCode,
                        receiver: this.state.editReceiver,
                        phoneNumber: this.state.editPhoneNumber,
                        tempFullAddress: this.state.editFullAddress,
                        tempPostCode: this.state.editPostCode,
                        tempDetailAddress: this.state.editDetailAddress,
                        tempReceiver: this.state.editReceiver,
                        tempPhoneNumber: this.state.editPhoneNumber
                      })
                    }
                    this.setState({defaultAddressIndex: this.state.tempDefaultAddressIndex})

                    let deliveryAddresses = this.state.deliveryAddresses
                    deliveryAddresses[this.state.editIndex] = with_a_nod_delivery_address
                    this.setState({deliveryAddresses: deliveryAddresses})

                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        let deliveryAddress = snapshot.val()
                        deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress[this.state.editIndex] = with_a_nod_delivery_address
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(this.state.tempDefaultAddressIndex)
                      }
                    })
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              if (fullAddress.indexOf('제주특별자치도') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else {
                this.setState({editFullAddress: fullAddress, isChangingAddress: false, editPostCode: data.zonecode})
              }

            }}
          />
        }

        </Modal>
        <Modal
          open={this.state.showFirstPostModal}
          onClose={() => {
            this.setState({showFirstPostModal: false, isChangingAddress: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
        >
        { this.state.tempFullAddress && !this.state.isChangingAddress
          ?<div className="enter-detail-address">
            <span style={{fontSize: 22, fontWeight: 'bold', margin: 30}}>배송지 입력</span>
            <div className="modal-outside-button" onClick={() => this.setState({showFirstPostModal: false, detailAddressInputError: false, receiverInputError: false, phoneNumberInputError: false})} style={{position: 'absolute', right: 30, top: 30, cursor: 'pointer'}}>
            <CloseIcon />
            </div>
            <TextField onClick={() => {
              this.setState({isChangingAddress: true})
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} disabled margin={"none"} multiline id="standard-basic" label="주소"  value={this.state.tempFullAddress} />
            <TextField  error={this.state.detailAddressInputError} onChange={(event) => {
              this.setState({tempDetailAddress: event.target.value})
              if (this.state.detailAddressInputError) {
                this.setState({detailAddressInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="상세주소(아파트 동, 호수 등)"  value={this.state.tempDetailAddress} />

            <TextField error={this.state.receiverInputError} onChange={(event) => {
              this.setState({tempReceiver: event.target.value})
              if (this.state.receiverInputError) {
                this.setState({receiverInputError: false})
              }
            }} className="input-style" inputProps={{style: {fontSize: 14, color: '#6b6b6b'}}} InputProps={{style: {marginBottom: 20}}} InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="받는 사람"  value={this.state.tempReceiver} />
            <TextField error={this.state.phoneNumberInputError} onChange={(event) => {
              this.setState({tempPhoneNumber: event.target.value.replace(/[^0-9]/g, '')})
              if (this.state.phoneNumberInputError) {
                this.setState({phoneNumberInputError: false})
              }
            }} className="input-style"  inputProps={{style: {fontSize: 14, color: '#6b6b6b'}, inputMode: 'numeric'}}  InputLabelProps={{style: {fontSize: 14}}} margin={"none"}  id="standard-basic" label="연락처"  value={this.onChangePhoneNumberFormat(this.state.tempPhoneNumber)} />
            <a onClick={() => {
              if (this.state.tempDetailAddress) {
                if (this.state.tempReceiver) {
                  if (this.state.tempPhoneNumber) {
                    this.setState({showFirstPostModal: false, detailAddressInputError: false, phoneNumberInputError: false, receiverInputError: false,  doneEnterAddress: true})
                    let with_a_nod_delivery_address = {
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber
                    }

                    this.setState({
                      detailAddress: this.state.tempDetailAddress,
                      fullAddress: this.state.tempFullAddress,
                      postCode: this.state.tempPostCode,
                      receiver: this.state.tempReceiver,
                      phoneNumber: this.state.tempPhoneNumber,
                    })
                    let userInfo = this.state.userInfo

                    firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).once('value', (snapshot) => {
                      if (snapshot.val()) {
                        let deliveryAddress = snapshot.val()
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['deliveryAddress'] = deliveryAddress
                        userInfo['defaultAddressIndex'] = 0

                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      } else {
                        let deliveryAddress = []
                        deliveryAddress.push(with_a_nod_delivery_address)
                        userInfo['defaultAddressIndex'] = 0
                        this.setState({deliveryAddresses: deliveryAddress, defaultAddressIndex: 0, tempDefaultAddressIndex: 0})
                        firebase.database().ref(`users/${this.state.userInfo.uid}/deliveryAddress`).set(deliveryAddress)
                        firebase.database().ref(`users/${this.state.userInfo.uid}/defaultAddressIndex`).set(0)
                      }
                    })
                  } else {
                    this.setState({phoneNumberInputError: true})
                  }
                } else {
                  this.setState({receiverInputError: true})
                }

              } else {
                this.setState({detailAddressInputError: true})
              }

            }} className="modal-btn-bottom-big">
              <p style={{color: 'white'}}>등록</p>
            </a>
          </div>
          :<Postcode
            style={{ width: 400, height: 400 }}
            jsOptions={{ animated: true, hideMapBtn: true }}
            onComplete={data => {
              let fullAddress = data.address;
              let extraAddress = '';

              if (data.addressType === 'R') {
                if (data.bname !== '') {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                  extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }
                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
              }
              if (fullAddress.indexOf('제주특별자치도') > -1) {
                alert('제주/도서산간 지역은 배송이 불가합니다.')
              } else {
                this.setState({tempFullAddress: fullAddress, isChangingAddress: false, tempPostCode: data.zonecode})
              }

            }}
          />
        }

        </Modal>
      </Grid>
    )
  }
}

export default withRouter(DeliveryLocation);
